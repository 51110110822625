import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_COMPANY'),
  ...asyncTypes('GET_COMPANY_ACCOUNT'),
  ...asyncTypes('UPDATE_COMPANY_ACCOUNT'),
  ...asyncTypes('GET_AGENT_HIRES'),
  ...asyncTypes('GET_CONNECTED_COMPANIES'),
  ...asyncTypes('GET_COMPANY_EMPLOYEES'),
  ...asyncTypes('CREATE_AGENT_HIRE'),
  ...asyncTypes('CREATE_CONNECTED_COMPANIES'),
  ...asyncTypes('CREATE_COMPANY_TYPE'),
  ...asyncTypes('UPDATE_AGENT_HIRE'),
  ...asyncTypes('UPDATE_COMPANY'),
  ...asyncTypes('UPDATE_PERSON'),
  ...asyncTypes('UPDATE_COMPANY_TYPE'),
  ...asyncTypes('DELETE_AGENT_HIRE'),
  ...asyncTypes('DELETE_COMPANY_TYPE'),
  RESET_PROFILE: 'RESET_PROFILE'
};

export default TYPES;
