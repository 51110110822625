import { deleteRequest, get, post, put } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import TYPES from './types';
import TABLE_TYPES from 'common/components/table/store/types';
import { successHandler } from 'common/utils/notifications';
import {
  selectItemPriceZoneFiedValue,
  selectPriceZoneDiscount,
  selectPricelistActiveId,
  selectPricelistIsInitialized
} from './selectors';
import { parsePurchasingPricelistItems } from './parser';
import {
  initializePricelistItems,
  setExpandedPriceZoneDiscounts,
  setPricelistItems
} from './slice';
import { strToNumber } from 'common/utils/numbers';
import { staticColumns } from 'views/purchasing/pricelist/form/items/table/tableConfig';

export const createPurchasingPricelist = createAsyncThunk(
  TYPES.CREATE_PURCHASING_PRICELIST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await post(`/price-lists`, params);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const editPurchasingPricelist = createAsyncThunk(
  TYPES.EDIT_PURCHASING_PRICELIST,
  async ({ id, ...params }, { rejectWithValue }) => {
    try {
      const res = await put(`/price-lists/${id}`, params);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getPurchasingPricelist = createAsyncThunk(
  TYPES.GET_PURCHASING_PRICELIST,
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await get(`/price-lists/${id}`);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deletePurchasingPricelist = createAsyncThunk(
  TYPES.DELETE_PURCHASING_PRICELIST,
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const res = await deleteRequest(`/price-lists/${id}`);

      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updatePurchasingPricelistGlobalDiscount = createAsyncThunk(
  TYPES.UPDATE_PURCHASING_PRICELIST_GLOBAL_DISCOUNT,
  async ({ id, ...rest }, { rejectWithValue, dispatch }) => {
    try {
      const res = await put(`/price-lists/${id}/global-discount`, rest);

      dispatch(
        successHandler({ title: 'Success!', message: 'Global discount was applied to all items' })
      );

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

// Price Zones
export const getPurchasingPricelistPriceZones = createAsyncThunk(
  TYPES.GET_PURCHASING_PRICELIST_PRICE_ZONES,
  async ({ id, table, ...params }, { rejectWithValue, dispatch }) => {
    try {
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.START, payload: { params, table } });
      const response = await get(`/price-lists/${id}/price-zones`, params);

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: { data: response.data, table }
      });

      return response?.data;
    } catch (error) {
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } });
      return rejectWithValue(error);
    }
  }
);

export const getPurchasingPricelistPriceZone = createAsyncThunk(
  TYPES.GET_PURCHASING_PRICELIST_PRICE_ZONE,
  async ({ id, ...params }, { rejectWithValue }) => {
    try {
      const res = await get(`/price-zones/${id}`, params);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createPurchasingPricelistPriceZone = createAsyncThunk(
  TYPES.CREATE_PURCHASING_PRICELIST_PRICE_ZONE,
  async ({ id, ...params }, { rejectWithValue, dispatch }) => {
    try {
      const res = await post(`/price-lists/${id}/price-zones`, params);

      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const editPurchasingPricelistPriceZone = createAsyncThunk(
  TYPES.EDIT_PURCHASING_PRICELIST_PRICE_ZONE,
  async ({ id, ...params }, { rejectWithValue, dispatch }) => {
    try {
      const res = await put(`/price-zones/${id}`, params);

      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deletePurchasingPricelistPriceZone = createAsyncThunk(
  TYPES.DELETE_PURCHASING_PRICELIST_PRICE_ZONE,
  async ({ id, ...params }, { rejectWithValue, dispatch }) => {
    try {
      const res = await deleteRequest(`/price-zones/${id}`, params);

      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
// --

// Items
export const getPurchasingPricelistItems = createAsyncThunk(
  TYPES.GET_PURCHASING_PRICELIST_ITEMS,
  async ({ id, table, ...params }, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.START, payload: { params, table } });

      const response = await get(`/price-lists/${id}/items`, params);
      const { data, columns, items, expandedPriceZoneDiscounts } = parsePurchasingPricelistItems(
        response?.data.data
      );

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: {
          data: { ...response.data, data, columns: [...staticColumns, ...columns] },
          table
        }
      });
      dispatch(setPricelistItems(items));

      if (!selectPricelistIsInitialized(getState())) {
        // set expanded state only in the first request
        dispatch(initializePricelistItems());
        dispatch(setExpandedPriceZoneDiscounts(expandedPriceZoneDiscounts));
      }

      return response?.data;
    } catch (error) {
      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.ERROR,
        payload: { error, table }
      });
      return rejectWithValue(error);
    }
  }
);

export const addPurchasingPricelistItem = createAsyncThunk(
  TYPES.ADD_PURCHASING_PRICELIST_ITEM,
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const id = selectPricelistActiveId(getState());
      const res = await post(`/price-lists/${id}/items`, params);

      dispatch(successHandler({ title: 'Success!', message: 'Item was added successfully' }));

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deletePurchasingPricelistItem = createAsyncThunk(
  TYPES.DELETE_PURCHASING_PRICELIST_ITEM,
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const res = await deleteRequest(`/price-list-items/${params.id}`);

      dispatch(successHandler({ title: 'Success!', message: 'Item was removed successfully' }));

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updatePurchasingPricelistItemPriceZone = createAsyncThunk(
  TYPES.UPDATE_PURCHASING_PRICELIST_ITEM,
  async (params, { rejectWithValue, getState }) => {
    try {
      const { itemId, priceZoneId, key } = params;
      const value = selectItemPriceZoneFiedValue(getState(), itemId, priceZoneId, key);
      const itemParams = { [key]: strToNumber(value) };

      const res = await put(`/price-list-items/${itemId}/price-zones/${priceZoneId}`, itemParams);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const applyPurchasingPricelistPriceZoneDiscount = createAsyncThunk(
  TYPES.APPLY_PURCHASING_PRICELIST_PRICE_ZONE_DISCOUNT,
  async (params, { rejectWithValue, getState }) => {
    try {
      const { priceZoneId } = params;
      const value = selectPriceZoneDiscount(getState(), priceZoneId);
      const itemParams = { discount: strToNumber(value) };

      const res = await put(`/price-zones/${priceZoneId}/discount`, itemParams);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
// --
