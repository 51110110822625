import { numberToStr } from 'common/utils/numbers';

const InstructedFieldSpeedConsumptions = ({
  instructedFor,
  instructedSpeed,
  instructedFOCons,
  instructedGOCons,
  isWeatherRoutingInstruction
}) => {
  const hasValue = value => value || value === 0;

  const hasConsValue = hasValue(instructedFOCons) || hasValue(instructedGOCons);

  return isWeatherRoutingInstruction ? (
    <span>Various - WOG</span>
  ) : (
    <>
      <span className={instructedFor === 'speed' ? 'fw-bold' : ''}>
        {hasValue(instructedSpeed) ? `${numberToStr(instructedSpeed)}kt` : '-'}
      </span>

      {hasConsValue ? (
        <span className="text-violet the-fleet-position__report--small-seperator">|</span>
      ) : null}
      <span className={instructedFor === 'consumption' ? 'fw-bold' : ''}>
        {`${hasValue(instructedFOCons) ? numberToStr(instructedFOCons) : ''}${
          hasValue(instructedFOCons) && hasValue(instructedGOCons) ? '+' : ''
        }${hasValue(instructedGOCons) ? numberToStr(instructedGOCons) : ''}${
          hasConsValue ? ' mt/d' : ''
        }`}
      </span>
    </>
  );
};

export default InstructedFieldSpeedConsumptions;
