import pick from 'lodash/pick';
import omit from 'lodash/omit';

const portAttributes = [
  'port',
  'cost',
  'days_tt',
  'days_extra',
  'days_idle',
  'days_off_hire',
  'max_draft',
  'load_line',
  'salinity',
  'dwt',
  'etd',
  'eta',
  'draft'
];

const actionAttributes = [
  'id',
  'action',
  'time_bunkering',
  'time_bunkering_unit_id',
  'laden',
  'cargo_rob_arrival_mtn',
  'cargo_rob_arrival_m3',
  'cargo_rob_departure_mtn',
  'cargo_rob_departure_m3',
  'cargos',
  'bunkering',
  'consumptions',
  'estimation_cp_id'
];

export const groupPorts = (ports, misc) =>
  ports.reduce((groupedPorts, portAction) => {
    const lastPort = groupedPorts.length > 0 ? groupedPorts[groupedPorts.length - 1] : null;
    const currentPortId = portAction.port_id;

    const action = {
      ...pick(portAction, actionAttributes),
      ...getPortsMisc(portAction.id, misc)
    };

    // Insert new port
    if (
      !lastPort ||
      lastPort.port_id !== currentPortId ||
      (portAction.action === 'rdl' && lastPort.actions.some(({ action }) => action === 'dlv'))
    ) {
      return [
        ...groupedPorts,
        {
          ...pick(portAction, portAttributes),
          id: portAction.id,
          port_id: portAction.port_id,
          sort_index: groupedPorts.length,
          actions: [action]
        }
      ];
    } else {
      return groupedPorts.map((port, index) => {
        // not the last port
        if (index !== groupedPorts.length - 1) return port;

        return {
          ...port,
          // Calc additional cost or days
          cost: portAction.cost ? parseFloat(port.cost) + parseFloat(portAction.cost) : port.cost,
          days_tt: portAction.days_tt
            ? parseFloat(port.days_tt) + parseFloat(portAction.days_tt)
            : port.days_tt,
          days_extra: portAction.days_extra
            ? parseFloat(port.days_extra) + parseFloat(portAction.days_extra)
            : port.days_extra,
          days_idle: portAction.days_idle
            ? parseFloat(port.days_idle) + parseFloat(portAction.days_idle)
            : port.days_idle,
          days_off_hire: portAction.days_off_hire
            ? parseFloat(port.days_off_hire) + parseFloat(portAction.days_off_hire)
            : port.days_off_hire,

          actions: [...port.actions, action]
        };
      });
    }
  }, []);

const getPortsMisc = (portId, misc) => {
  const cost = misc.find(cost => cost.estimation_port_id === portId);
  return cost
    ? {
        cost_id: cost.id,
        rate: cost.rate,
        rate_unit_id: cost.rate_unit_id,
        description: cost.description,
        description_id: cost.description_id,
        comments: cost.comments
      }
    : {};
};

export const ungroupPorts = ports =>
  ports.reduce((portActions, port) => {
    const portAttributes = omit(port, 'actions', 'id');
    const actions = port.actions.map((action, index) =>
      index === 0
        ? { ...portAttributes, ...action }
        : {
            ...action,
            port_id: portAttributes.port_id,
            cost: 0,
            days_tt: 0,
            days_extra: 0,
            days_idle: 0,
            days_off_hire: 0,
            max_draft: 0,
            load_line: 0,
            salinity: 0
          }
    );
    return [...portActions, ...actions];
  }, []);

export const getTripAfterPort = (port, trips) => {
  const actionIds = port.actions.map(action => action.id);
  return trips.find(trip => actionIds.includes(trip.start_estimation_port_id));
};

export const arrayMove = (arr, old_index, new_index) => {
  arr = [...arr];

  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};
