import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import Tooltip from 'common/components/general/Tooltip';
import { Button } from 'reactstrap';

import star from 'common/assets/svg/common/star.svg';
import starSolid from 'common/assets/svg/common/star-solid.svg';

const ToggleFavourite = ({ isFavourite, toggle }) => {
  return (
    <>
      <Button
        color="link"
        id="favourite-toggle-button"
        onClick={toggle}
        className={`p-0 toggle-favourite ${isFavourite ? 'is-favourite' : ''}`}
      >
        <SvgRender src={isFavourite ? starSolid : star} style={{ height: 19, width: 19 }} />
      </Button>
      <Tooltip target="favourite-toggle-button">{`${
        isFavourite ? 'Remove from' : 'Save as'
      } Starred`}</Tooltip>
    </>
  );
};

export default ToggleFavourite;
