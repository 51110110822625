import React, { Component } from 'react';
import PointTarget from 'react-point';

import { Button } from 'reactstrap';

class CalculatorKey extends Component {
  render() {
    const { onPress, className, ...props } = this.props;

    return (
      <PointTarget onPoint={onPress}>
        <Button type="button" color="link" className={`calculator-key ${className}`} {...props} />
      </PointTarget>
    );
  }
}

export default CalculatorKey;
