import TYPES from './types';

const INITIAL_STATE = {
  entityLogs: [],
  isLoading: false
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.CLEAR_ERRORS:
      return {
        ...state,
        errors: {}
      };

    case TYPES.GET_ENTITY_LOGS.START:
      return {
        ...state,
        isLoading: true
      };

    case TYPES.GET_ENTITY_LOGS.ERROR:
      return {
        ...state,
        isLoading: false
      };

    case TYPES.GET_ENTITY_LOGS.SUCCESS:
      return {
        ...state,
        entityLogs: payload,
        isLoading: false
      };

    default:
      return state;
  }
};

export default reducer;
