import React, { useCallback, useEffect } from 'react';
import WidgetSettings from 'views/dashboard/components/WidgetSettings';
import Radio from 'common/components/form/inputs/Radio';
import { selectWidgetPreferences } from 'store/dashboards/selectors';
import { useSelector } from 'react-redux';

import { Row, Col } from 'reactstrap';
import { useForm, useFormState } from 'utils/hooks';
import { stringField, optionField } from 'common/utils/form/fieldTypes';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import * as listActions from 'store/lists/actions';
import { useActions } from 'utils/hooks';

const Preferences = ({ id, onSave, onClose, isSubmitting }) => {
  const { formState, collectValues, loadValues } = useForm({
    type: stringField({ required: true }),
    ranks: optionField(),
    statuses: optionField()
  });
  const { fields, selectField } = useFormState(formState);

  const preferences = useSelector(state => selectWidgetPreferences(state, id));
  const ranks = useSelector(state => selectListOptionsFromStore(state, 'crew-ranks'));
  const statuses = useSelector(state => selectListOptionsFromStore(state, 'crew-statuses'));

  const [fetchListOptions] = useActions([listActions.fetchListOptions]);

  const init = useCallback(() => {
    if (!ranks?.length || !statuses?.length) return;

    const valuesToLoad = {
      type: preferences.type || 'person',
      ranks: preferences?.rank_ids?.length
        ? preferences.rank_ids.map(rankId => ranks.find(r => r.id === rankId))
        : null,
      statuses: preferences?.status_ids?.length
        ? preferences.status_ids.map(statusId => statuses.find(s => s.id === statusId))
        : null
    };

    loadValues(valuesToLoad);
  }, [preferences, ranks?.length, statuses?.length]);

  useEffect(() => {
    init();
  }, [id, init]);

  useEffect(() => {
    fetchListOptions('crew-ranks');
    fetchListOptions('crew-statuses');
  }, []);

  const handleSubmitForm = async ({ custom_title }) => {
    const values = collectValues();

    if (!values) return;

    const params = {
      custom_title,
      type: values.type,
      rank_ids: fields.ranks.value?.length ? fields.ranks.value.map(r => r.id) : undefined,
      status_ids: fields.statuses.value?.length ? fields.statuses.value.map(s => s.id) : undefined
    };

    onSave(params);
  };

  return (
    <WidgetSettings id={id} isSubmitting={isSubmitting} onClose={onClose} onSave={handleSubmitForm}>
      <Row className="g-0 mt-3">
        <Col xs={2}>
          <Radio
            label="Office"
            name="radio"
            value="person"
            checked={fields.type.value === 'person'}
            onChange={() => {
              selectField('type')('person');
              selectField('ranks')(null);
              selectField('statuses')(null);
            }}
          />
        </Col>
        <Col xs={10}>
          <Radio
            label="Crew"
            value="crew"
            name="radio"
            checked={fields.type.value === 'crew'}
            onChange={() => selectField('type')('crew')}
          />
        </Col>
      </Row>
      {fields.type.value === 'crew' ? (
        <>
          <AsyncSelector
            label="RANKS"
            onChange={e => selectField('ranks')(e)}
            type="crew-ranks"
            placeholder="Select rank(s)"
            className="mb-0 mt-3"
            isMulti
            {...fields.ranks}
          />
          <AsyncSelector
            label="STATUSES"
            onChange={e => selectField('statuses')(e)}
            type="crew-statuses"
            placeholder="Select status(es)"
            className="mb-0 mt-2"
            isMulti
            {...fields.statuses}
          />
        </>
      ) : null}
    </WidgetSettings>
  );
};

export default Preferences;
