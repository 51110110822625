import { get } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { putFileUrlsInHtml } from 'common/utils/post-images';
import { selectMenus } from 'common/components/beacon/store/selectors';
import { expandeMenuAction, setActivePostIdAction } from 'common/components/beacon/store/slice';
import { getAscendanceTree } from 'common/components/tree-layout/utils/helpers';

import TYPES from './types';
import _pickBy from 'lodash/pickBy';

export const getBeaconPost = createAsyncThunk(
  TYPES.GET_BEACON_POST,
  async (params, { rejectWithValue }) => {
    try {
      const res = await get(`/beacon/posts/${params.id}`);
      const post = { ...res.data };

      return { ...post, content: putFileUrlsInHtml(post.content, post.attachments) };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const searchBeaconPosts = createAsyncThunk(
  TYPES.SEARCH_BEACON_POSTS,
  async (params, { rejectWithValue }) => {
    try {
      const res = await get(`/beacon/posts`, params);

      return res.data?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const findActiveBeaconPost = createAsyncThunk(
  TYPES.FIND_ACTIVE_BEACON_POST,
  async (params, { getState, dispatch, rejectWithValue }) => {
    const { path, url } = params;
    const menus = selectMenus(getState());

    const matchingPost = _pickBy(
      menus,
      item =>
        item.post_id && item.pages?.find(page => page?.page_url === url || page?.page_url === path)
    );

    if (matchingPost) {
      const post = Object.values(matchingPost)[0];
      dispatch(setActivePostIdAction(post?.post_id));

      const tree = getAscendanceTree(post.id, menus); // expand parent menus
      dispatch(
        expandeMenuAction({
          ids: tree.reduce((obj, curP) => {
            obj[curP.id] = true;

            return obj;
          }, {})
        })
      );

      return post;
    }

    return rejectWithValue(null);
  }
);
