import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import PropTypes from 'prop-types';
import paths from 'routing/routes/_paths';
import { useSelector } from 'react-redux';
import { selectWidgetState } from 'store/dashboards/selectors';
import eyeSvg from 'common/assets/svg/common/eye.svg';

import { getRows, getColumns } from 'common/components/findings/widget/tableConfig';

const Findings = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));
  const rows = getRows();
  const columns = getColumns();

  return (
    <div>
      <WidgetTitle id={id} linkTo={paths.findings} />
      <WidgetDefaultTable
        id={id}
        columns={columns}
        rows={{
          ...rows,
          actions: data => ({
            options: [
              {
                label: 'View',
                icon: eyeSvg,
                onClick: () => window.open(`${paths.findings}/${data.id}`)
              }
            ]
          })
        }}
        label={label}
        state={state}
      />
    </div>
  );
};

Findings.propTypes = {
  id: PropTypes.number
};

export default Findings;
