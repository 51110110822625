import { useSelector } from 'react-redux';

import WidgetDefaultCard from 'views/dashboard/components/WidgetDefaultCard';
import paths from 'routing/routes/_paths';
import PropTypes from 'prop-types';

import { selectWidgetStateData } from 'store/dashboards/selectors';

const InventorySparePartsCardWidget = ({ id }) => {
  const data = useSelector(state => selectWidgetStateData(state, id));
  return <WidgetDefaultCard id={id} count={data.spare_parts_count} linkTo={paths.pms_inventory} />;
};

InventorySparePartsCardWidget.propTypes = {
  id: PropTypes.number
};

export default InventorySparePartsCardWidget;
