import TYPES from './types';
import { get } from 'utils/api';

export const getOrganizationInfo = () => dispatch => {
  dispatch({ type: TYPES.GET_ORGANIZATION_INFO.START });

  return get('/account/organization-info')
    .then(res => {
      dispatch({ type: TYPES.GET_ORGANIZATION_INFO.SUCCESS, payload: res.data });
      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_ORGANIZATION_INFO.ERROR, payload: error }));
};
