import { createSlice } from '@reduxjs/toolkit';
import { editParticipant, deleteParticipant, createParticipant, getParticipants } from './actions';
import { LOCATION_CHANGE } from 'connected-react-router';

const INITIAL_STATE = {
  data: [],
  loading: false
};

const slice = createSlice({
  name: 'eventParticipants',
  initialState: INITIAL_STATE,
  reducers: {
    setParticipantsData: (state, { payload }) => {
      state.data = payload.map((participant, index) => ({ ...participant, index }));
      return state;
    },

    addOrEditParticipant: (state, { payload }) => {
      let newParticipants = [];

      if (state.data.findIndex(participant => participant.index === payload.index) !== -1) {
        newParticipants = state.data.map(participant => {
          if (participant.index === payload.index) return payload;
          return participant;
        });
      } else {
        newParticipants = [...state.data, payload];
      }

      state.data = newParticipants;
      return state;
    },

    removeParticipant: (state, { payload }) => {
      state.data = state.data
        .filter(participant => participant.index !== payload)
        .map((participant, index) => ({ ...participant, index }));
      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(editParticipant.fulfilled, (state, { payload }) => {
        const index = state.data.findIndex(p => p.id === payload.id);
        if (index !== -1) state.data[index] = payload;

        state.loading = false;
        return state;
      })

      .addCase(deleteParticipant.fulfilled, (state, { payload }) => {
        state.data = state.data.filter(el => el.id !== payload.id);
        return state;
      })

      .addCase(createParticipant.fulfilled, (state, { payload }) => {
        state.data.push(payload);
        return state;
      })

      .addCase(getParticipants.pending, state => {
        state.loading = true;
        return state;
      })

      .addCase(getParticipants.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
        return state;
      })

      .addCase(getParticipants.rejected, state => {
        state.loading = false;
        return state;
      })

      .addCase(LOCATION_CHANGE, () => {
        return INITIAL_STATE;
      })

      .addDefaultCase(state => {
        return state;
      });
  }
});

export const { setParticipantsData, addOrEditParticipant, removeParticipant } = slice.actions;

export default slice.reducer;
