import { formatDate } from 'common/utils/dates';
import moment from 'moment';

const parseMarketIndexes = (data, params) => {
  let frequency = params.filters ? params.filters.find(f => f.name === 'frequency') : null;
  frequency = frequency ? frequency.value : 'daily';

  const formatted = Object.keys(data.data).reduce((acc, key) => {
    const date = data.data[key];
    const row = {
      date: ''
    };

    if (frequency === 'daily') {
      row.date = formatDate(key, { time: true });
    } else if (frequency === 'monthly') {
      row.date = moment(key).format('MMM YYYY');
    } else if (frequency === 'quarterly') {
      const p = key.split(':');
      row.date = `${p[1].toUpperCase()} ${p[0]}`;
    } else {
      row.date = key;
    }

    date.forEach(index => {
      row[`${index.market_index_id}_value`] = index.value;
    });

    acc.push(row);

    return acc;
  }, []);

  return {
    data: formatted,
    meta: data.meta
  };
};

export default parseMarketIndexes;
