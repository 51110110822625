import WeatherClausesInfoTooltip from 'views/the-fleet/components/position-list/card/components/body/info/detailed-info/weather-card/captain-reports/WeatherClausesInfoTooltip';
import ReportLink from 'captain-reports/components/ReportLink';
import VesselCiiReferenceYearlyTooltip from 'common/components/general/VesselCiiReferenceYearlyTooltip';

import { numberToStr } from 'common/utils/numbers';
import { getTimeValue } from 'captain-reports/templates/inputs/TimeInput';
import {
  seaFields,
  weatherFields
} from 'views/the-fleet/components/position-list/card/components/body/info/detailed-info/fields-list/configs/fields';
import {
  isInstructedSpeedWog,
  isInstructedSpeedEta
} from 'captain-reports/templates/components/custom-fields/SpeedOG';
import { displayInstructedValue } from 'captain-reports/templates/components/helpers';
import { transformNumber } from 'views/the-fleet/components/position-list/card/components/body/info/detailed-info/fields-list/configs/transformations';
import { validateConsumptions } from 'views/the-fleet/components/position-list/card/components/body/info/detailed-info/fields-list/configs/validations';
import { hasValue } from 'views/the-fleet/components/position-list/card/components/body/info/detailed-info/fields-list/helpers';
import { displayDate } from 'common/utils/dates';

import _get from 'lodash/get';

const getGoodWeatherClauses = data => ({
  weather: {
    good_weather_clauses: data?.good_weather_clauses ? data?.good_weather_clauses : {}
  }
});

export const filters = [
  {
    name: 'period',
    operation: 'between',
    value: null,
    initialValue: null
  },
  {
    name: 'vessel_id',
    operation: '=',
    value: null,
    initialValue: null
  },
  {
    name: 'charter_party_id',
    operation: '=',
    value: null,
    initialValue: null
  },
  {
    name: 'draft',
    operation: '=',
    value: null,
    initialValue: null
  },
  {
    name: 'cargo_onboard',
    operation: '=',
    value: null,
    initialValue: null
  },
  {
    name: 'wind_speed',
    operation: 'between',
    value: null,
    initialValue: null
  },
  {
    name: 'wind_relative_direction',
    operation: 'between',
    value: null,
    initialValue: null
  },
  {
    name: 'combined_wind_waves_and_swell_significant_height',
    operation: 'between',
    value: null,
    initialValue: null
  },
  {
    name: 'ocean_current_speed',
    operation: 'between',
    value: null,
    initialValue: null
  },
  {
    name: 'ocean_current_relative_direction',
    operation: 'between',
    value: null,
    initialValue: null
  },
  {
    name: 'good_weather',
    operation: 'is',
    value: null,
    initialValue: null
  },
  {
    name: 'speed_og',
    operation: 'between',
    value: null,
    initialValue: null
  },
  {
    name: 'total_consumption_rate',
    operation: 'between',
    value: null,
    initialValue: null
  },
  {
    name: 'apparent_slip',
    operation: 'between',
    value: null,
    initialValue: null
  }
];

export const columns = [
  {
    header: 'DATE',
    subLabel: '(UTC)',
    type: 'datetime',
    key: 'timestamp',
    width: 180,
    footer_total_label: 'TOTALS / AVERAGES',
    second_footer_total_label: 'GOOD WEATHER DAYS TOTALS',
    second_footer_total_label_color: 'green',
    third_footer_total_label: 'BAD WEATHER DAYS TOTALS',
    third_footer_total_label_color: 'red'
  },
  {
    header: 'STEAMING TIME',
    width: 112,
    text_align: 'right',
    key: 'steaming_time',
    footer_total_key: 'totals.steaming_time_display',
    second_footer_total_key: 'good_weather_totals.steaming_time_display',
    third_footer_total_key: 'bad_weather_totals.steaming_time_display'
  },
  {
    header: 'CONDITION',
    key: 'condition',
    width: 80,
    bodyClassName: 'text-capitalize'
  },
  {
    header: 'SPEED OG',
    subLabel: 'kt',
    type: 'number',
    width: 88,
    key: 'speed_og',
    footer_total_key: 'averages.speed_og',
    second_footer_total_key: 'good_weather_averages.speed_og_display',
    third_footer_total_key: 'bad_weather_averages.speed_og_display'
  },
  {
    header: 'INSTRUCTED SPEED',
    subLabel: 'kt',
    width: 148,
    text_align: 'right',
    key: 'average_instructed_speed',
    footer_total_key: 'averages.average_instructed_speed_display',
    second_footer_total_key: 'good_weather_averages.average_instructed_speed_display',
    third_footer_total_key: 'bad_weather_averages.average_instructed_speed_display'
  },
  {
    header: 'SLIP',
    subLabel: '%',
    key: 'apparent_slip',
    type: 'number',
    width: 72
  },
  {
    header: 'TOTAL CONSUMPTION',
    subLabel: 'mt/d',
    type: 'number',
    width: 124,
    headerClassName: 'pe-0',
    key: 'total_consumption_rate',
    footer_total_key: 'averages.total_consumption_rate',
    second_footer_total_key: 'good_weather_averages.total_consumption_rate_display',
    third_footer_total_key: 'bad_weather_averages.total_consumption_rate_display'
  },
  {
    header: 'INSTRUCTED CONSUMPTION',
    subLabel: 'mt/d',
    width: 172,
    text_align: 'right',
    key: 'average_instructed_consumption',
    footer_total_key: 'averages.average_instructed_consumption_display',
    second_footer_total_key: 'good_weather_averages.average_instructed_consumption_display',
    third_footer_total_key: 'bad_weather_averages.average_instructed_consumption_display'
  },
  {
    header: 'AER (CII)',
    info: 'Year up to report date',
    subLabel: 'grCO₂ / tDWTmile',
    key: 'cii',
    width: 132,
    headerClassName: 'pe-0',
    text_align: 'right',
    footer_total_key: 'averages.cii_display',
    second_footer_total_key: 'good_weather_averages.cii_display',
    third_footer_total_key: 'bad_weather_averages.cii_display'
  },
  {
    header: 'GOOD/BAD WEATHER DAY',
    key: 'good_weather',
    width: 102
  },
  {
    header: 'WIND SPEED',
    subLabel: 'bft',
    key: 'wind_speed',
    type: 'number',
    width: 112
  },
  {
    header: 'SEA HEIGHT',
    subLabel: 'm',
    key: 'sea_height',
    type: 'number',
    width: 102
  },
  {
    header: 'SWELL HEIGHT',
    subLabel: 'm',
    key: 'swell_height',
    type: 'number',
    width: 112
  },
  {
    header: 'COMBINED SEA & SWELL',
    info: 'Combined Sea & Swell Wave height = √(sea² + swell²)',
    subLabel: 'm',
    key: 'sigwaves',
    type: 'number',
    width: 120,
    headerClassName: 'pe-0'
  },
  {
    header: 'OCEAN CURRENT SPEED & DIRECTIONS',
    key: 'ocean_current',
    width: 128
  }
];

export const rows = {
  timestamp: data => (
    <ReportLink reportId={data.id} target="_blank">
      {displayDate(data.timestamp, 'DD/MM/YYYY HH:mm')}
    </ReportLink>
  ),
  steaming_time: data => getTimeValue(data?.steaming_time),
  speed_og: data => {
    const field = seaFields.speedOG('speed_og', 'instructed_speed_minimum_allowance');
    const value = data.speed_og;

    return (
      <span className={field.validationClassName(value, data)}>
        {hasValue(value) ? numberToStr(value, 2, 2) : '-'}
      </span>
    );
  },
  average_instructed_speed: data => {
    const { average_instructed_speed, instructed_speed_minimum_allowance } = data;
    const isWog = isInstructedSpeedWog(data.instructed_speed_consumption_target);
    const isEta = isInstructedSpeedEta(data.instructed_speed_consumption_target);

    if (isWog) return 'WOG';
    else if (isEta) return 'ETA';

    return (
      <div>
        {displayInstructedValue(
          average_instructed_speed,
          instructed_speed_minimum_allowance,
          'text-violet',
          2
        )}
      </div>
    );
  },
  total_consumption_rate: data => {
    const value = data?.total_consumption_rate;
    const comparedWith = data?.instructed_consumption_maximum_allowance;
    const className = validateConsumptions(
      value,
      { instructed_consumption_maximum_allowance: comparedWith },
      'instructed_consumption_maximum_allowance'
    );

    return <div className={className}>{numberToStr(value, 2, 2)}</div>;
  },
  average_instructed_consumption: data => {
    const { average_instructed_consumption, instructed_consumption_maximum_allowance } = data;
    const isWog = isInstructedSpeedWog(data.instructed_speed_consumption_target);
    const isEta = isInstructedSpeedEta(data.instructed_speed_consumption_target);

    if (isWog) return 'WOG';
    else if (isEta) return 'ETA';

    return (
      <div>
        {displayInstructedValue(
          average_instructed_consumption,
          instructed_consumption_maximum_allowance,
          'text-violet',
          2
        )}
      </div>
    );
  },
  apparent_slip: data => {
    const value = data.apparent_slip;

    return <span>{hasValue(value) ? numberToStr(value, 2, 2) : '-'}</span>;
  },
  good_weather: data => {
    const value = data?.good_weather_by_captain_report;

    const isGood = value === 'good';
    const isBad = value === 'bad';
    const isInsufficient = value === 'insufficient';

    if (isInsufficient) return '-';

    return (
      <div className="d-flex align-items-center">
        <WeatherClausesInfoTooltip
          svgClassname={`text-${isGood ? 'green' : isBad ? 'red' : 'primary'}`}
          goodWeatherClauses={getGoodWeatherClauses(data)?.weather?.good_weather_clauses}
          voyage={{ good_weather_default_clauses: data?.good_weather_default_clauses }}
        />
        <span className="ps-1">{isGood ? 'Good' : isBad ? 'Bad' : ''}</span>
      </div>
    );
  },
  wind_speed: data => {
    const field = weatherFields.wind('wind_speed');
    const value = data.wind_speed;

    return (
      <span className={field.validationClassName(value, getGoodWeatherClauses(data))}>
        {hasValue(value) ? field.transformation(value) : '-'}
      </span>
    );
  },
  sigwaves: data => {
    const field = weatherFields.sigwaves('combined_wind_waves_and_swell_significant_height');
    const value = data.combined_wind_waves_and_swell_significant_height;

    return (
      <span className={field.validationClassName(value, getGoodWeatherClauses(data))}>
        {hasValue(value) ? numberToStr(value, 2, 2) : '-'}
      </span>
    );
  },
  sea_height: data => numberToStr(data.sea_height, 2, 2),
  swell_height: data => numberToStr(data.swell_height, 2, 2),
  ocean_current: data => {
    const oceanCurrentRelativeDirection = data.ocean_current_relative_direction;
    const oceanCurrentRelativeDirectionField = {
      source: 'captain_report',
      noonValue: oceanCurrentRelativeDirection
    };

    const oceanCurrentSpeed = data.ocean_current_speed;
    const oceanCurrentSpeedField = weatherFields.current(
      'ocean_current_speed',
      null,
      oceanCurrentRelativeDirectionField
    );
    const transformed = transformNumber(oceanCurrentSpeed);

    if (transformed === null) return '-';

    const className = oceanCurrentSpeedField.validationClassName(oceanCurrentSpeed, {
      previous_report: {
        ocean_current_relative_direction: oceanCurrentRelativeDirection
      }
    });
    const isFavorable = className?.includes('green');

    return (
      <div className={className}>
        {transformed}kt&nbsp;
        {transformed !== 0 ? <span> {isFavorable ? 'Favourable' : 'Adverse'}</span> : null}
      </div>
    );
  },
  cii: data => {
    const cii = _get(data, 'cii');
    const rating = _get(data, 'rating');
    const boundaries = _get(data, 'reference.boundaries', []);
    const year = _get(data, 'reference.year');
    const refenceCii = _get(data, 'reference.cii');

    return (
      <VesselCiiReferenceYearlyTooltip
        boundaries={boundaries}
        year={year}
        cii={refenceCii}
        placement="right"
        renderComponent={() => (
          <div className="d-flex align-items-center">
            <strong className={`cii-rating-${rating}`}>{rating}</strong>
            {rating ? <div className="cms-4 cme-4">-</div> : ''}
            <div>{cii === 0 ? 0 : numberToStr(cii, 2, 2)}</div>
          </div>
        )}
      />
    );
  }
};
