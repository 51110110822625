import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectNotifications,
  selectCount,
  selectActiveCategoryLabel,
  selectNotificationsFetching
} from 'store/notifications/selectors';
import {
  getNotifications,
  markNotificationAsSeen,
  getNotificationsSummary
} from 'store/notifications/actions';
import { setActiveCategoryLabel } from 'store/notifications/actions';
import { selectIsPanelOpen } from 'store/sidebar/selectors';

import Loader from './Loader';
import EmptyState from './EmptyState';
import NotificationsContainer from './NotificationsContainer';
import Header from './Header';
import Summary from './Summary';

const Notifications = () => {
  const dispatch = useDispatch();
  const activeCategoryLabel = useSelector(selectActiveCategoryLabel);
  const notifications = useSelector(selectNotifications);
  const counter = useSelector(selectCount);
  const isFetching = useSelector(selectNotificationsFetching);
  const isPanelOpen = useSelector(selectIsPanelOpen);

  const getInitialData = useCallback(async () => {
    try {
      await dispatch(getNotifications({ current_page: 1 }));
      await dispatch(getNotificationsSummary());
    } catch (e) {
      console.error(e);
    }
  }, [dispatch]);

  useEffect(() => {
    if (counter > 0) {
      dispatch(markNotificationAsSeen());
    }
  }, [counter, dispatch]);

  useEffect(() => {
    if (isPanelOpen) getInitialData();

    return () => {
      dispatch(setActiveCategoryLabel('all'));
    };
  }, [getInitialData, isPanelOpen, dispatch]);

  return (
    <div className="sidebar-notifications-wrapper d-flex flex-column pt-5 ">
      <div className="fs-14 fw-light ps-2 pb-4">
        NOTIFICATIONS{' '}
        <span className="fs-10 text-violet">- You see all notifications for the last 30 days</span>
      </div>
      <Header />
      {isFetching && !notifications.length ? (
        <div className="flex-1 w-100p">
          <Loader />
        </div>
      ) : notifications.length === 0 ? (
        <EmptyState />
      ) : activeCategoryLabel === 'summary' ? (
        <Summary />
      ) : (
        <NotificationsContainer notifications={notifications} />
      )}
    </div>
  );
};

export default Notifications;
