import { useSelector } from 'react-redux';

import WidgetDefaultCard from 'views/dashboard/components/WidgetDefaultCard';
import paths from 'routing/routes/_paths';

import { selectWidgetStateData } from 'store/dashboards/selectors';

const PmsJobsCardWidget = ({ id }) => {
  const data = useSelector(state => selectWidgetStateData(state, id));

  return <WidgetDefaultCard id={id} count={data.pms_jobs_count} linkTo={paths.pms_jobs} />;
};

export default PmsJobsCardWidget;
