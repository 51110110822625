import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import SvgRender from 'common/components/general/SvgRender';
import ReportLink, { reportURL } from 'captain-reports/components/ReportLink';
import ReportStatus from 'captain-reports/components/ReportStatus';
import Date from 'captain-reports/components/Date';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import paths from 'routing/routes/_paths';
import { useSelector } from 'react-redux';
import { selectWidgetState } from 'store/dashboards/selectors';
import MessagesCountBubble from '@/common/components/general/MessagesCountBubble';
import { getCaptainReportTypeName } from 'common/utils/labels';
import { captainReportIcon } from 'captain-reports/utils/helpers';
import ballast from 'captain-reports/assets/svg/ballast.svg';
import laden from 'captain-reports/assets/svg/laden.svg';

const CaptainReports = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));

  return (
    <div>
      <WidgetTitle id={id} linkTo={paths.captain_reports} />
      <WidgetDefaultTable
        id={id}
        columns={[
          {
            header: 'vessel',
            key: 'vessel',
            type: 'string',
            headerClassName: 'text-violet',
            width: 3
          },
          {
            header: 'type',
            key: 'type',
            type: 'string',
            width: 2,
            headerClassName: 'text-violet'
          },
          {
            header: 'next / current port',
            key: 'port',
            type: 'string',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'local date & time',
            key: 'local_timestamp',
            type: 'datetime',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'utc date & time',
            key: 'timestamp',
            type: 'datetime',
            headerClassName: 'text-violet'
          },
          {
            header: 'condition',
            key: 'loading_condition',
            type: 'string',
            headerClassName: 'text-violet'
          },
          {
            header: 'Actions',
            key: 'actions',
            field: 'actions'
          }
        ]}
        rows={{
          vessel: data => (
            <div className="d-flex align-items-center">
              <span>{data.vessel.name}</span>
              <ReportStatus
                target={`a${data?.status?.id}-${data?.status}`}
                status={data?.status}
                className="ms-auto"
              />
            </div>
          ),
          type: data =>
            data.template ? (
              <div className="d-flex align-items-center position-relative">
                <SvgRender
                  src={captainReportIcon[data.template.type]}
                  style={{ width: 16, height: 16 }}
                  className="me-1"
                />
                <ReportLink templateType={data.template.type} reportId={data.id} target="_blank">
                  <div className="fs-12 lh-1 text-capitalize">
                    {getCaptainReportTypeName(data.template.type)}
                  </div>
                </ReportLink>

                {data.status === 'approved' ||
                data.template.type !== 'port_statement' ||
                data?.chat_messages_count === 0 ? null : (
                  <MessagesCountBubble
                    className="width-16 min-width-16 height-16 ms-1"
                    count={data?.chat_messages_count}
                  />
                )}
              </div>
            ) : null,
          port: data => <div>{data.current_port || data.next_port}</div>,
          local_timestamp: ({ local_timestamp }) => <Date value={local_timestamp} withTime />,
          timestamp: ({ timestamp }) => <Date value={timestamp} withTime />,
          loading_condition: ({ loading_condition }) => (
            <div className="d-flex ps-3">
              <SvgRender
                src={loading_condition === 'laden' ? laden : ballast}
                style={{ width: 13, height: 13 }}
                className={'text-primary'}
              />
            </div>
          ),
          actions: data => ({
            options: [
              {
                label: 'View',
                icon: eyeSvg,
                onClick: () =>
                  window.open(
                    `${reportURL[data.template.type] || paths.captain_reports}/${data.id}`
                  )
              }
            ]
          })
        }}
        label={label}
        state={state}
      />
    </div>
  );
};

export default CaptainReports;
