import React from 'react';
import { Link } from 'react-router-dom';

import SvgRender from 'common/components/general/SvgRender';
import arrowFilters from 'common/assets/svg/common/arrows/arrow-filters.svg';
import Footer from './Footer';
import { formatNumber } from 'common/utils/numbers';
import loader from 'common/assets/svg/common/loader.svg';
import ModularTable from 'common/components/ModularTable';
import moment from 'moment';
import Difference from './Difference';

const TableContainer = ({
  id,
  table,
  tableProps,
  setActiveResult,
  view,
  tceResultsAverages,
  showEdit = false,
  tableIsEmpty,
  name,
  vesselId,
  widget
}) => {
  return (
    <div className="table-container d-flex flex-column">
      {tableIsEmpty ? (
        <div className="tce-results-empty-table">
          <span className="text-primary">Go to &nbsp;</span>{' '}
          <span className="text-violet">{name}</span>
          <span className="text-primary">&nbsp;TCE Results</span>
          <Link to={`/vessels/${vesselId}/commercial`} className="tce-results-arrow">
            <SvgRender src={arrowFilters} style={{ width: 13, height: 16 }} />
          </Link>
        </div>
      ) : (
        <>
          <ModularTable
            className="overflow-hidden flex-1"
            hideTableFilters
            hideTableSearch
            style="transparent"
            highlightColumnOnHover={false}
            handleTableSort={() => {}} // not implemented yet
            defaultRequestParams={{ visible: false, paging: false, sorting: true }}
            rows={{
              date_from: data => (
                <span className="fs-12">{moment(data.date_from).format('DD/MM/YYYY')}</span>
              ),
              market_tce_gross: data => formatNumber(data.market_tce_gross),
              market_tce_net: data => formatNumber(data.market_tce_net),
              market_tce_adjusted_net: data => formatNumber(data.market_tce_adjusted_net),
              tce_net: data => formatNumber(data.tce_net),
              difference: data => (
                <Difference
                  data={data}
                  showEdit={showEdit}
                  view={view}
                  setActiveResult={setActiveResult}
                />
              )
            }}
            {...table}
            {...tableProps}
          />
          <Footer tceResultsAverages={tceResultsAverages} widget={widget} />
        </>
      )}

      {table.fetching ? (
        <div className="table-container__loader d-flex justify-content-center align-items-center">
          <img alt="loader" src={loader} width={48} height={48} />
        </div>
      ) : null}
    </div>
  );
};

export default TableContainer;
