import React from 'react';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import paths from '@/routing/routes/_paths.js';

const MarketIndexes = React.lazy(() => retry(() => import('@/views/market-indexes/index.jsx')));

const marketIndexes = [
  {
    path: `${paths.market_indexes}`,
    component: MarketIndexes,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.voyages.market_indexes.view],
    documentTitle: 'Market Indexes'
  }
];

export default marketIndexes;
