import { put } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getPurchasingDeliveryReportStatus,
  getPurchasingRequisitionDeliveryReport,
  updatePurchasingRequisitionDeliveryReport,
  GetPurchasingRequisitionDeliveryReportParams,
  UpdatePurchasingRequisitionDeliveryReportParams
} from '@/api/purchasing/api';
import { successHandler } from '@/common/utils/notifications';

export const updateDelivery = createAsyncThunk(
  'UPDATE_DELIVERY',
  async (
    params: { id: number | string; status: 'draft' | 'submitted' },
    { rejectWithValue, dispatch }
  ) => {
    const { id, status, ...rest } = params;

    try {
      const res = await put(`/deliveries/${id}`, { ...rest, status });

      if (status === 'draft') {
        dispatch(successHandler({ title: 'Success!', message: 'Saved successfully' }));
      }

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDeliveryReport = createAsyncThunk(
  'GET_DELIVERY_REPORT',
  async (params: GetPurchasingRequisitionDeliveryReportParams, { rejectWithValue }) => {
    try {
      const res = await getPurchasingRequisitionDeliveryReport(params);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateDeliveryReport = createAsyncThunk(
  'UPDATE_DELIVERY_REPORT',
  async (
    params: UpdatePurchasingRequisitionDeliveryReportParams,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await updatePurchasingRequisitionDeliveryReport(params);

      await dispatch(getDeliveryReportStatus(params));

      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDeliveryReportStatus = createAsyncThunk(
  'GET_DELIVERY_REPORT_STATUS',
  async (params: GetPurchasingRequisitionDeliveryReportParams, { rejectWithValue }) => {
    try {
      const res = await getPurchasingDeliveryReportStatus(params);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
