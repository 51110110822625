import SvgRender from 'common/components/general/SvgRender';
import BoxShadowButton from 'common/components/buttons/BoxShadowButton';
import PropTypes from 'prop-types';
import sms from 'common/assets/svg/common/sms.svg';

import { useState, useCallback } from 'react';
import { useActions } from 'utils/hooks';

import * as authActions from 'store/auth/actions';

const SendSms = ({ onSendCallback }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sendTwoFactorOtp] = useActions([authActions.sendTwoFactorOtp]);

  const onSend = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await sendTwoFactorOtp().unwrap();
      setIsSubmitting(false);
      onSendCallback();
    } catch (error) {
      setIsSubmitting(false);
    }
  }, [sendTwoFactorOtp, onSendCallback]);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center h-100p">
      <SvgRender src={sms} style={{ width: 48, height: 48 }} />
      <div className="fs-20 fw-medium text-dark mb-4 mt-4">Authentication code</div>

      <div className="fs-16 fw-medium text-violet text-center mb-4 mb-hd-8">
        When your phone is ready, click the
        <br />
        button below to receive a code via SMS.
      </div>

      <BoxShadowButton
        color="yellow"
        type="submit"
        className="w-100p py-1 fs-14 height-40"
        size="sm"
        onClick={onSend}
        disabled={isSubmitting}
      >
        Send SMS
      </BoxShadowButton>
    </div>
  );
};

SendSms.propTypes = {
  onSendCallback: PropTypes.func
};
export default SendSms;
