import { useEffect, useCallback } from 'react';
import { useAppSelector, useAppDispatch } from '@/store/hooks';
import { useFormState } from 'utils/hooks';

import { useForm } from 'common/utils/hooks/useForm';

import ShadowBox from 'common/components/general/ShadowBox';
import {
  selectActiveRequisitionCode,
  selectActiveRequisitionID,
  isPurchasingCommunicationDrawerOpen
} from 'common/components/purchasing/requisition/store/selectors';
import config from './config';
import Drawer, { DrawerHeader, FormBody, FormDrawer } from 'common/components/drawer';
import SentEmails from './SentEmails';
import Actions from './Actions';
import EmailForm from './EmailForm';
import { selectAccountExternalEmail } from '@/store/account/selectors-ts.ts';

import { fetchListOptions } from 'store/lists/actions';
import {
  getPurchasingRequisitionEmails,
  getPurchasingRequisitionSuppliers
} from 'common/components/purchasing/requisition/store/actions';
import { togglePurchasingCommunicationsDrawer } from 'common/components/purchasing/requisition/store/actions';
import { useGetExternalEmail } from '@/api/external-email/queries';
import { selectListDefaultOptions } from '@/store/lists/selectors-ts';
import LinkEmails from './link-emails';
import { PurchasingRequisitionStatusBase } from '@/common/types/purchasing';

interface CommunicationsDrawerProps {
  hideEmailForm: boolean;
}

const CommunicationsDrawer: React.FC<CommunicationsDrawerProps> = ({ hideEmailForm }) => {
  const { formState, collectValues, resetForm, hasErrors } = useForm(config);
  const { selectField } = useFormState(formState);

  const dispatch = useAppDispatch();

  const activeID = useAppSelector(selectActiveRequisitionID);
  const activeCode = useAppSelector(selectActiveRequisitionCode);

  const isOpen = useAppSelector(isPurchasingCommunicationDrawerOpen);
  const getExternalEmail = useGetExternalEmail({ enabled: isOpen });
  const statuses: PurchasingRequisitionStatusBase[] = useAppSelector(state =>
    selectListDefaultOptions(state, 'purchasing-requisition-statuses')
  );
  const externalEmail = useAppSelector(selectAccountExternalEmail);

  const emailStatuses = statuses
    .filter(status => status.label === 'qtn' || status.label === 'po')
    .map(status => (status.label === 'po' ? { ...status, name: 'PO' } : status));

  const onInit = useCallback(async () => {
    const params = {
      filters: [
        {
          name: 'requisition_id',
          operation: 'oneOf',
          value: [activeID]
        }
      ]
    };
    dispatch(fetchListOptions('email-templates', '', { type: 'purchasing_requisition' }));
    await dispatch(getPurchasingRequisitionSuppliers({ id: activeID }));
    await dispatch(getPurchasingRequisitionEmails(params));
    resetForm();
    if (externalEmail) {
      selectField('from')(externalEmail);
    }
  }, [activeID, dispatch, externalEmail, resetForm, selectField]);

  useEffect(() => {
    if (isOpen) {
      onInit();
    }
  }, [isOpen, onInit]);

  return (
    <Drawer
      className="purchasing-communications"
      isOpen={isOpen}
      close={() => dispatch(togglePurchasingCommunicationsDrawer(false))}
      size="md"
    >
      <DrawerHeader>Communication for {activeCode}</DrawerHeader>

      {isOpen ? (
        <FormDrawer>
          <FormBody className={`d-flex flex-column px-0 overflow-hidden`}>
            <div className="overflow-auto gray-scrollbar pt-1 position-relative flex-1">
              <LinkEmails emailStatuses={emailStatuses} />
              <div className="flex-1 px-8 overflow-hidden mb-5">
                <SentEmails />
              </div>
              <div className="purchasing-communications__overflow-gradient mt-4"></div>
            </div>

            {hideEmailForm ? null : (
              <ShadowBox
                className="purchasing-communications__auto-email-container mt-auto px-8 py-2"
                color="light-1"
                flat
              >
                <EmailForm
                  emailStatuses={emailStatuses}
                  formState={formState}
                  externalEmail={getExternalEmail.data}
                />
                <Actions
                  formState={formState}
                  collectValues={collectValues}
                  hasErrors={hasErrors}
                  resetForm={resetForm}
                />
              </ShadowBox>
            )}
          </FormBody>
        </FormDrawer>
      ) : null}
    </Drawer>
  );
};

export default CommunicationsDrawer;
