import { FORM_FIELD_TYPES } from 'common/components/forms/digital/constants';
import {
  getFieldTypeLabel,
  getFormFieldHelpText,
  getFormFieldIsRequired,
  getFormFieldParams,
  getFieldID,
  getFormFieldID,
  getFormFieldUID,
  getFormFieldSortIndex,
  getFormFieldColumnIndex,
  getFormFieldOldColumnIndex,
  initFormFieldsStructure,
  getFieldCanShowOnFormListAsType
} from 'common/components/forms/digital/state-utils';

export const parseFormFields = fields => {
  return fields.map(formField => {
    const fieldTypeLabel = getFieldTypeLabel(formField);
    const sort_index = getFormFieldSortIndex(formField);
    const column_index = getFormFieldColumnIndex(formField);
    const old_column_index = getFormFieldOldColumnIndex(formField);
    const canShowOnFormListAsTypeCheckbox = getFieldCanShowOnFormListAsType(formField);

    const updatedField = {
      form_field_id: getFieldID(formField),
      id: getFormFieldID(formField),
      uid: getFormFieldUID(formField),
      help_text: getFormFieldHelpText(formField) || undefined,
      required: getFormFieldIsRequired(formField),
      form_field_params: parseFormFieldParams(formField)
    };

    // if (sort_index !== undefined && column_index !== undefined) {
    updatedField.sort_index = sort_index;
    updatedField.column_index = column_index;
    updatedField.old_column_index = old_column_index;
    // }

    switch (fieldTypeLabel) {
      case FORM_FIELD_TYPES.number.label:
        return {
          ...updatedField,
          rules: formField.rules,
          formula: formField.formula,
          formula_variables: formField.formula_variables,
          decimal_digit_precision: formField.decimal_digit_precision,
          is_calculated: formField.is_calculated
        };

      case FORM_FIELD_TYPES.risk_assessment.label:
        return {
          ...updatedField,
          show_on_form_list: formField.show_on_form_list,
          required: true
        };

      default:
        return {
          ...updatedField,
          show_on_form_list: canShowOnFormListAsTypeCheckbox
            ? formField.show_on_form_list
            : undefined
        };
    }
  });
};

export const parseFormFieldParams = formField => {
  const fieldTypeLabel = getFieldTypeLabel(formField);
  const formFieldParams = getFormFieldParams(formField);
  const options = formFieldParams?.options || [];

  switch (fieldTypeLabel) {
    case FORM_FIELD_TYPES.table_with_fixed_rows.label:
      if (formFieldParams) {
        const { columns, fields, rows, table_title } = formFieldParams;

        const parsedFields = Object.keys(fields).reduce((acc, key) => {
          const indexes = key.split('_');
          const fieldRowIndex = parseInt(indexes[0]);
          const fieldColumnIndex = parseInt(indexes[1]);

          const field = { ...fields[key] };

          field.sort_index = fieldRowIndex;
          field.column_index = fieldColumnIndex;

          acc.push(field);
          return acc;
        }, []);

        return {
          table_title,
          columns,
          row_count: rows?.length,
          fields: parseFormFields(parsedFields.filter(({ field }) => field))
        };
      }
      return;

    case FORM_FIELD_TYPES.table_with_dynamic_rows.label:
      if (formFieldParams) {
        const { columns, fields, max_row_count, table_title } = formFieldParams;

        const parsedFields = Object.keys(fields)
          .filter(key => key.startsWith(0))
          .reduce((acc, key) => {
            const indexes = key.split('_');
            const fieldColumnIndex = parseInt(indexes[1]);

            const field = { ...fields[key], column_index: fieldColumnIndex };

            acc.push(field);
            return acc;
          }, []);

        return {
          table_title,
          columns,
          max_row_count,
          fields: parseFormFields(parsedFields.filter(({ field }) => field))
        };
      }
      return;

    case FORM_FIELD_TYPES.manual_chapter.label:
      return {
        manual_id: formFieldParams?.manual_id,
        chapter_uid: formFieldParams?.chapter_uid,
        version_id: formFieldParams?.version_id
      };

    case FORM_FIELD_TYPES.crew_participants.label:
      return {
        notes_label: formFieldParams?.notes_label
      };

    case FORM_FIELD_TYPES.inventory_survey_rob.label:
      return {
        item_type: formFieldParams?.item_type,
        item_id: formFieldParams?.item_id
      };

    case FORM_FIELD_TYPES.title.label:
    case FORM_FIELD_TYPES.subtitle.label:
    case FORM_FIELD_TYPES.instructions.label:
      return { text: formFieldParams?.text };

    case FORM_FIELD_TYPES.form_instructions_image.label:
      return { file_id: formFieldParams?.file_id, width: formFieldParams?.width };

    case FORM_FIELD_TYPES.root_cause_analysis.label:
      return { root_cause_category_id: formFieldParams?.root_cause_category_id };

    case FORM_FIELD_TYPES.vessel_system_attribute.label:
      return {
        vessel_system_attribute_group_id: formFieldParams?.vessel_system_attribute?.group_id,
        vessel_system_attribute_id: formFieldParams?.vessel_system_attribute_id
      };

    case FORM_FIELD_TYPES.risk_assessment.label:
      return formFieldParams;

    default:
      return {
        options: options?.filter(option => option) || []
      };
  }
};

export const getModeledFormFields = (formFields = []) => {
  return initFormFieldsStructure(formFields);
};
