import { createSelector } from 'reselect';
import { selectIsPms } from 'common/components/pms/jobs/store/selectors';
import { selectListOptionsFromStore } from 'store/lists/selectors';

export const selectRestrictedFields = state =>
  selectListOptionsFromStore(state, 'job-restricted-fields') || [];

export const selectIfFieldIsVisible = field =>
  createSelector([selectRestrictedFields, selectIsPms], (fields, isPms) => {
    if (isPms) return true;

    const found = fields.find(el => el.visible && el.field === field);

    if (found) {
      return found.visible;
    } else {
      return false;
    }
  });
