import { hiddenField, booleanField, optionField, stringField } from 'common/utils/form/fieldTypes';

const config = {
  id: hiddenField(),
  type: stringField({ initialValue: 'company' }),
  company_types: {
    company_subtype_ids: optionField(),
    company_type_id: optionField()
  },
  company_name: stringField({ required: true }),

  vat_registration: stringField(),
  website: stringField(),
  email: stringField(),
  emails: {
    type: optionField({ initialValue: 'personal' }),
    value: stringField()
  },
  contact_info: {
    type: optionField({ initialValue: 'phone' }),
    value: stringField()
  },
  serving_ports: {
    port: optionField()
  },
  phone: stringField(),
  is_iso_certified: booleanField({ initialValue: false }),
  iso_attachments: optionField({ initialValue: [] }),
  address: stringField(),
  city: stringField(),
  postal_code: stringField(),
  country: optionField(),
  state: stringField(),
  connected_companies: {
    company: optionField()
  }
};

export default config;
