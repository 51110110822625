import React, { useEffect, useState } from 'react';
import NavigationArrows from 'common/components/general/NavigationArrows';
import { useSelector } from 'react-redux';
import { selectTableListDataIds } from 'common/components/table/store/selectors';
import PropTypes from 'prop-types';

const NavigationButtons = ({ id, setSummaryRequisitionId }) => {
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);
  const isOnboard = useSelector(state => state.isOnBoard);

  const requisitionId = isOnboard ? id : parseInt(id);

  const tableData = useSelector(state =>
    selectTableListDataIds(state, `purchasing_requisitions_approvals`)
  );

  const onArrowClick = id => {
    setSummaryRequisitionId(id);
  };

  useEffect(() => {
    const requisitionIndex = tableData?.findIndex(field => field === requisitionId);

    if (requisitionIndex !== -1) {
      setNext(tableData?.[requisitionIndex + 1]);
      setPrevious(tableData?.[requisitionIndex - 1]);
    } else {
      setNext(null);
      setPrevious(null);
    }
  }, [requisitionId, tableData.length, tableData]);

  return (
    <NavigationArrows
      disabledNext={!next}
      disabledPrevious={!previous}
      onNextClick={() => onArrowClick(next)}
      onPreviousClick={() => onArrowClick(previous)}
      className={'m-0 px-2'}
    />
  );
};

NavigationButtons.propTypes = {
  setSummaryRequisitionId: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
};

export default NavigationButtons;
