import TYPES from './types';
import { get, put } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAccount = () => dispatch => {
  dispatch({ type: TYPES.GET_ACCOUNT.START });

  return get('/account')
    .then(res => {
      dispatch({ type: TYPES.GET_ACCOUNT.SUCCESS, payload: res.data });
      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_ACCOUNT.ERROR, payload: error }));
};

export const getAccountPermissions = () => dispatch => {
  dispatch({ type: TYPES.GET_ACCOUNT_PERMISSIONS.START });

  return get('/account/permissions')
    .then(res => {
      dispatch({ type: TYPES.GET_ACCOUNT_PERMISSIONS.SUCCESS, payload: res.data });

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_ACCOUNT_PERMISSIONS.ERROR, payload: error }));
};

export const getAccountWebpushConfig = () => dispatch => {
  dispatch({ type: TYPES.GET_ACCOUNT_PERMISSIONS.START });

  return get('/account/webpush')
    .then(res => {
      dispatch({ type: TYPES.GET_ACCOUNT_WEBPUSH_CONFIG.SUCCESS, payload: res.data });

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_ACCOUNT_WEBPUSH_CONFIG.ERROR, payload: error }));
};

export const updateAccount = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_ACCOUNT.START });

  return put('/account', params)
    .then(res => {
      dispatch({ type: TYPES.UPDATE_ACCOUNT.SUCCESS, payload: res.data });

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.UPDATE_ACCOUNT.ERROR, payload: error }));
};

export const updateLastDashboardId = dashboardId => dispatch => {
  dispatch({ type: TYPES.UPDATE_LAST_DASHBOARD_ID.START });

  return put('/account', { last_dashboard_id: dashboardId })
    .then(res => {
      dispatch({
        type: TYPES.UPDATE_LAST_DASHBOARD_ID.SUCCESS,
        payload: { last_dashboard_id: dashboardId }
      });

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.UPDATE_LAST_DASHBOARD_ID.ERROR, payload: error }));
};

// Notification Preferences

//current user
export const getNotificationPreferences = () => dispatch => {
  dispatch({ type: TYPES.GET_NOTIFICATION_PREFERENCES.START });

  return get('/account/notifications/settings')
    .then(res => {
      dispatch({ type: TYPES.GET_NOTIFICATION_PREFERENCES.SUCCESS, payload: res.data });
      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_NOTIFICATION_PREFERENCES.ERROR, payload: error });
      throw error;
    });
};

//other users
export const getPartyNotificationPreferences = params => dispatch => {
  dispatch({ type: TYPES.GET_PARTY_NOTIFICATION_PREFERENCES.START, payload: { params } });

  return get(`/parties/${params.party_id}/notification-settings`)
    .then(res => {
      dispatch({ type: TYPES.GET_PARTY_NOTIFICATION_PREFERENCES.SUCCESS, payload: res.data });
      return res.data;
    })

    .catch(error => {
      dispatch({ type: TYPES.GET_PARTY_NOTIFICATION_PREFERENCES.ERROR, payload: error });
      throw error;
    });
};

export const updateNotificationPreferences = createAsyncThunk(
  'UPDATE_NOTIFICATION_PREFERENCES',
  async (params, { rejectWithValue }) => {
    try {
      const res = put('/account/notifications/settings', params);

      return { data: res.data, is_saving: false };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateBulkNotificationPreferences = createAsyncThunk(
  'UPDATE_BULK_NOTIFICATION_PREFERENCES',
  async (params, { rejectWithValue }) => {
    try {
      const res = put('/account/notifications/settings/bulk', params);

      return { data: res.data, is_saving: false };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
