import { AccountType } from '@/common/types/account';
import {
  PermisionEnvironments,
  PermisionModeType,
  PermissionType,
  PermissionsType
} from '@/common/types/permissions';
import { authorizeAccount, getPermissionSettings } from 'common/utils/permissions/helpers';
import _intersection from 'lodash/intersection';
import { Department } from '@/common/types/enums';

export const isAuthorized = (
  account: AccountType,
  permissions: PermissionsType = [],
  mode: PermisionModeType = 'or',
  environments: PermisionEnvironments = []
) => {
  if (account?.is_ocean_admin) return true;

  return authorizeAccount({ account, permissions, mode, environments }, false);
};

export const getAuthorizedPermissionSettings = (
  account: AccountType,
  permission: PermissionType,
  mode: PermisionModeType = 'or',
  environments: PermisionEnvironments = []
) => {
  return getPermissionSettings({ account, permission, mode, environments }, false);
};

export const entityExistsInPermissionSettings = (
  account: AccountType,
  permission: PermissionType,
  entityKey: string,
  entityId: string | number
): boolean => {
  if (!permission) return false;

  const isAllowed = isAuthorized(account, [permission]);

  if (!isAllowed || !entityKey) return false;

  const entitySettings = getAuthorizedPermissionSettings(account, permission)?.[entityKey];

  // In case that entity settings are empty array return true
  // for example, in this case office.vessels.view: { vessels: [] } we return true because it applies for all vessels
  if (Array.isArray(entitySettings) && !entitySettings?.length) {
    return true;
  } else if (!Array.isArray(entitySettings)) {
    return false;
  }

  return entitySettings.includes(entityId);
};

export const checkIsAuthorizedByAccess = (
  account: AccountType,
  permission: PermissionType,
  entityKey: string,
  departments: Department[] | null,
  ownCaseId: number
) => {
  const selectedPermissionAccessType = getPermissionSettings({ account, permission }, false)?.[
    `${entityKey}`
  ]?.value;

  const isHavingTheSameDepartmentsAsLoggedUser =
    _intersection(
      account.departments?.map(dept => dept?.id),
      departments?.map(dept => dept?.id)
    ).length > 0;

  const isTheSameWithLoggedUser = ownCaseId === account.id;

  switch (selectedPermissionAccessType) {
    case 'all':
      return true;
    case 'department':
      return isHavingTheSameDepartmentsAsLoggedUser;
    case 'own':
      return isTheSameWithLoggedUser;
    default:
      return isAuthorized(account, [permission]);
  }
};
