import ShadowBox from 'common/components/general/ShadowBox';
import styled from '@emotion/styled';
import Spinner from 'common/components/general/Spinner';

import * as sidebarActions from 'store/beacon/sidebar/actions';
import { useActions } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectActivePostId } from 'common/components/beacon/store/selectors';
import { setActivePostIdAction } from 'common/components/beacon/store/slice';
import { useCallback, useEffect, useState } from 'react';
import { getBeaconInternalUrlPostId } from 'common/utils/urls';

const PostContent = () => {
  const [getBeaconPost] = useActions([sidebarActions.getBeaconPost]);
  const activePostId = useSelector(selectActivePostId);
  const [isLoading, setIsLoading] = useState(false);
  const [post, setPost] = useState(null);
  const dispatch = useDispatch();

  const initPost = useCallback(
    async id => {
      try {
        setIsLoading(true);

        const data = await getBeaconPost({ id }).unwrap();

        setIsLoading(false);
        setPost(data);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    },
    [getBeaconPost]
  );

  const onClickContentListener = useCallback(
    event => {
      if (event.target.tagName.toLowerCase() === 'a') {
        const internalPostId = getBeaconInternalUrlPostId(event);

        if (internalPostId) {
          event.preventDefault();
          dispatch(setActivePostIdAction(internalPostId));
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (activePostId) initPost(activePostId);
  }, [activePostId, initPost]);

  if (!activePostId) return null;

  return (
    <ShadowBox className="mb-2 px-8 py-4 overflow-hidden" color="light-2" flat>
      <ScrollableContainer className="gray-scrollbar cpe-4">
        {isLoading ? (
          <div className="d-flex w-100p h-100p align-items-center justify-content-center">
            <Spinner size={48} />
          </div>
        ) : (
          <div
            className="ck-content"
            dangerouslySetInnerHTML={{ __html: post?.content }}
            onClick={onClickContentListener}
          ></div>
        )}
      </ScrollableContainer>
    </ShadowBox>
  );
};

const ScrollableContainer = styled.div`
  height: 87vh;
  overflow-y: auto;
`;

export default PostContent;
