import moment from 'moment';
import { createSelector } from 'reselect';
import { strToNumber } from '../utils/format';
import { findById, reduceListProperty } from 'store/_helpers';
import warningConfig from '../utils/warnings';

export const selectEstimation = state => state.estimator;

export const selectWarnings = createSelector(selectEstimation, ({ messages }) => {
  const combinedMessages = messages
    ? [...(messages.errors || []), ...(messages.warnings || [])]
    : [];
  return Object.keys(warningConfig)
    .map(code => combinedMessages.find(message => message.code === code) || null)
    .filter(message => !!message);
});

export const selectMarketIndex = createSelector(
  selectEstimation,
  ({ market_index_id }) => market_index_id
);

export const selectStatus = createSelector(selectEstimation, ({ loading, error, title }) => ({
  loading,
  error,
  hasEstimation: !!title
}));

export const selectOutput = createSelector(selectEstimation, ({ output }) => output);

export const selectCps = createSelector(selectEstimation, estimator => {
  return estimator ? estimator.cps : [];
});
export const selectCp = id => createSelector(selectCps, findById(id));

export const selectPortActions = createSelector(selectEstimation, ({ ports }) => ports);
export const selectPortAction = id => createSelector(selectPortActions, findById(id));

export const selectSimplePorts = createSelector(selectEstimation, ({ ports }) => ports);

export const selectPorts = createSelector(selectEstimation, ({ groupedPorts }) => groupedPorts);
export const selectPort = id => createSelector(selectPorts, findById(id));

export const selectCpCargos = createSelector(selectCps, reduceListProperty('cargos'));
export const selectCpCargo = id => createSelector(selectCpCargos, findById(id));

export const selectTrips = createSelector(selectEstimation, ({ trips }) => trips);
export const selectTrip = id => createSelector(selectTrips, findById(id));

export const selectVessel = state => state.estimator.vessel;

export const selectVesselTypeId = createSelector(selectVessel, ({ type_id }) => type_id);

export const selectIsEditing = createSelector(
  selectCps,
  selectPorts,
  selectTrips,
  (cps, ports, trips) => cps.concat(ports, trips).some(el => el.isEditting === true)
);

export const selectMarketIndexes = createSelector(
  selectVessel,
  ({ market_indexes }) => market_indexes
);

export const selectVesselDwtGraph = createSelector(
  [selectEstimation, selectVessel],
  ({ ports }, vessel) => {
    const cargoLoadValues = [];

    if (vessel) {
      ports.forEach(port => {
        const dwtValue = port.load_line ? vessel['dwt_' + port.load_line] : null;

        if (port.eta) {
          cargoLoadValues.push({
            date: port.eta,
            value: dwtValue ? parseFloat(dwtValue) : null
          });
        }

        if (port.etd) {
          cargoLoadValues.push({
            date: port.etd,
            value: dwtValue ? parseFloat(dwtValue) : null
          });
        }
      });
    }
    return {
      label: 'Vessel DWT',
      data: cargoLoadValues
    };
  }
);

export const selectVesselDwt = createSelector(
  selectVessel,
  ({ dwt_summer, dwt_winter, dwt_tropical, dwt_tropical_fresh, dwt_fresh }) => ({
    summer: dwt_summer,
    winter: dwt_winter,
    tropical: dwt_tropical,
    tropical_fresh: dwt_tropical_fresh,
    fresh: dwt_fresh
  })
);

export const selectVesselDraft = createSelector(
  selectVessel,
  ({ draft_summer, draft_winter, draft_tropical, draft_tropical_fresh, draft_fresh }) => ({
    summer: draft_summer,
    winter: draft_winter,
    tropical: draft_tropical,
    tropical_fresh: draft_tropical_fresh,
    fresh: draft_fresh
  })
);

export const selectSpeedOptions = laden =>
  createSelector(selectVessel, ({ consumptions }) =>
    consumptions
      .filter(
        c =>
          c.speed !== null &&
          (laden ? c.situation.label === 'laden' : c.situation.label === 'ballast')
      )
      .map(consumption => ({
        id: strToNumber(consumption.speed),
        label: `${consumption.speed} knots`
      }))
  );

export const selectEditting = createSelector(
  [selectCps, selectTrips, selectPorts],
  (cps, trips, ports) => {
    const edittingCP = findEdittingItem(cps);
    if (edittingCP) return { id: edittingCP.id, type: 'cp', item: edittingCP };

    const edittingTrip = findEdittingItem(trips);
    if (edittingTrip) return { id: edittingTrip.id, type: 'trip', item: edittingTrip };

    const edittingPort = findEdittingItem(ports);
    if (edittingPort) return { id: edittingPort.id, type: 'port', item: edittingPort };

    return null;
  }
);

const findEdittingItem = items => items.find(item => item.isEditting === true);

export const selectCargoPortOptions = action =>
  createSelector([selectCps, selectPorts], (cps, ports) =>
    cps
      .filter(cp => cp.id !== 'new')
      .reduce(
        (options, cp) => [
          ...options,
          ...cp.cargos.map(cargo => ({
            id: cargo.id,
            label: `${cargo.cargo_grade ? cargo.cargo_grade.name : cargo.cargo_grade.id} ${
              cp.party ? `- ${cp.party.full_name}` : ''
            }`,
            cargoLabel: cargo.cargo_grade ? cargo.cargo_grade.name : '',
            quantity: parseFloat(cargo.quantity),
            quantity_unit_id: cargo.quantity_unit_id,
            deviation: parseFloat(cargo.deviation),
            deviation_type: cargo.deviation_type
          }))
        ],
        []
      )
      .map(cargo => {
        const a = getUsedQuantity(ports, cargo.id, action);
        return {
          ...cargo,
          quantity: cargo.quantity - a
        };
      })
  );

const getUsedQuantity = (ports, cargoId, actionType) =>
  ports.reduce(
    (totalQuantity, port) =>
      port.isEditting
        ? totalQuantity
        : totalQuantity +
          port.actions.reduce(
            (totalQuantity, action) =>
              action.action === actionType
                ? totalQuantity +
                  action.cargos.reduce(
                    (totalQuantity, cargo) =>
                      cargo.estimation_cp_cargo_id === cargoId
                        ? totalQuantity + cargo.quantity
                        : totalQuantity,
                    0
                  )
                : totalQuantity,
            0
          ),
    0
  );

export const selectMisc = createSelector([selectEstimation, selectPorts], (estimation, ports) =>
  estimation.misc
    ? estimation.misc.filter(cost =>
        cost.estimation_port_id
          ? //exclude costs that are shown in "own" port row
            !ports.some(port => {
              return port.actions.some(
                action => action.id === cost.estimation_port_id && action.action === 'own'
              );
            })
          : true
      )
    : []
);

// REFACTOR WITH CREATE_SELECTOR
export const selectDays = state => {
  const {
    output,
    days_ballast,
    days_laden,
    days_port,
    days_repo,
    days_total,
    days_hire
  } = selectEstimation(state);

  return {
    total: days_total,
    chartValues:
      output === 'voyage'
        ? [
            { label: 'Laden Sea', value: days_laden },
            { label: 'Laden Port', value: days_port },
            { label: 'Ballast', value: days_ballast },
            { label: 'Reposition', value: days_repo }
          ]
        : [
            { label: 'Hire', value: days_hire },
            { label: 'Ballast', value: days_ballast },
            { label: 'Reposition', value: days_repo }
          ]
  };
};

export const selectCosts = state => {
  const {
    voyage_cost_canal,
    voyage_cost_diesel,
    voyage_cost_fuel,
    voyage_cost_misc,
    voyage_cost_port,
    voyage_cost_total
  } = selectEstimation(state);

  return {
    fuel: voyage_cost_fuel,
    diesel: voyage_cost_diesel,
    port: voyage_cost_port,
    canal: voyage_cost_canal,
    misc: voyage_cost_misc,
    total: voyage_cost_total
  };
};

export const selectVesselCosts = state => {
  const {
    vessel_cost_ballast_bonus,
    vessel_cost_commission,
    vessel_cost_hire,
    vessel_cost_misc,
    vessel_cost_tax,
    vessel_cost_total
  } = selectEstimation(state);

  return {
    ballast_bonus: vessel_cost_ballast_bonus,
    commission: vessel_cost_commission,
    hire: vessel_cost_hire,
    misc: vessel_cost_misc,
    tax: vessel_cost_tax,
    total: vessel_cost_total
  };
};
export const selectRevenues = state => {
  const {
    revenue_ballast_bonus,
    revenue_commission,
    revenue_demurrage,
    revenue_despatch,
    revenue_freight,
    revenue_hire,
    revenue_misc,
    revenue_tax,
    revenue_total
  } = selectEstimation(state);

  return {
    total: revenue_total,
    freight: revenue_freight,
    demurrage: revenue_demurrage,
    despatch: revenue_despatch,
    misc: revenue_misc,
    commission: revenue_commission,
    tax: revenue_tax,
    ballastBonus: revenue_ballast_bonus,
    hire: revenue_hire
  };
};

export const selectConsumptions = state => {
  const { fuels } = selectEstimation(state);
  const grades = state.lists['fuel-grades'].options;

  return fuels
    ? fuels
        .filter(fuel => fuel.fuel_action === 'con')
        .map(fuel => {
          const grade = grades.find(grade => grade.id === fuel.fuel_grade_id);
          return {
            fuel_grade: grade ? grade.name : null,
            fuel_grade_id: grade ? grade.id : null,
            cost: parseFloat(fuel.price) * parseFloat(fuel.quantity)
          };
        })
        .sort((a, b) => a.cost <= b.cost)
    : [];
};

const getTotalBunkering = (grade_id, bunkerings) =>
  bunkerings.reduce(
    (bunkering, c) =>
      c.fuel_grade_id === grade_id ? bunkering + (parseFloat(c.quantity) || 0.0) : bunkering,
    0
  );

export const selectCargoDataset = (volume = true) => state => {
  const { ports } = selectEstimation(state);
  const cargoLoadValues = [];
  ports.forEach(port => {
    if (port.eta) {
      cargoLoadValues.push({
        date: port.eta,
        value: parseFloat(volume ? port.cargo_rob_arrival_m3 : port.cargo_rob_arrival_mtn)
      });
    }

    if (port.etd) {
      cargoLoadValues.push({
        date: port.etd,
        value: parseFloat(volume ? port.cargo_rob_departure_m3 : port.cargo_rob_departure_mtn)
      });
    }
  });
  return {
    label: 'Onboard',
    data: cargoLoadValues
  };
};

export const selectDwtDataset = state => {
  const cargoDataset = selectCargoDataset(false)(state);
  const fuelDatasets = selectFuelValues(state);

  return {
    label: 'Onboard',
    data: cargoDataset.data.map((point, index) => ({
      date: point.date,
      value:
        point.value +
        fuelDatasets.reduce((sum, fuelGradeSet) => sum + fuelGradeSet.data[index].value, 0)
    }))
  };
};

export const selectPriceDataset = state => {
  const startingFuels = selectStartingFuels(state);
  const { ports, trips } = selectEstimation(state);

  return startingFuels.map(fuel => ({
    id: fuel.id,
    label: fuel.name,
    data: calcPrices(fuel.id, ports, trips)
  }));
};

export const selectRob = date =>
  createSelector([selectFuelValues, selectPriceDataset], (fuelDataset, priceDataset) =>
    fuelDataset
      .map(fuel => {
        const robQuantityOnArrival = fuel.data.find(d => d.date === date);
        const priceOnArrival = priceDataset
          .find(p => p.id === fuel.id)
          .data.find(d => d.date === date);
        return robQuantityOnArrival
          ? {
              label: fuel.label,
              quantity: robQuantityOnArrival ? robQuantityOnArrival.value : null,
              price: priceOnArrival ? priceOnArrival.value : null
            }
          : null;
      })
      .filter(fuel => !!fuel)
  );

const calcPrices = (grade_id, ports, trips) => {
  let prices = [];
  ports.forEach(port => {
    prices = [...prices, ...ccPrices(port.consumptions, port.eta, port.etd, grade_id)];

    const trip = trips.find(trip => trip.start_estimation_port_id === port.id);
    if (trip) {
      const endPort = ports.find(port => port.id === trip.end_estimation_port_id);
      prices = [...prices, ...ccPrices(trip.consumptions, port.etd, endPort.eta, grade_id)];
    }
  });
  return prices;
};

const ccPrices = (consumptions, start, end, grade_id) => {
  const prices = [];
  if (start && end) {
    const gradeConsumptions = consumptions.filter(con => con.fuel_grade_id === grade_id);
    const totalConsumption = getTotalConsumption(grade_id, consumptions);
    const duration = moment.duration(moment(end).diff(moment(start))).asSeconds();

    let currentStart = start;
    if (gradeConsumptions.length > 0) {
      gradeConsumptions.forEach(consumption => {
        const consumptionPerc = getTotalConsumption(grade_id, [consumption]) / totalConsumption;
        const consumptionDuration = duration * consumptionPerc;

        prices.push({
          date: currentStart,
          value: parseFloat(consumption.cost)
        });
        const end = moment(currentStart)
          .add(consumptionDuration, 'seconds')
          .format('YYYY-MM-DD HH:mm:ss');

        prices.push({
          date: end,
          value: parseFloat(consumption.cost)
        });
        currentStart = end;
      });
    } else {
      prices.push({
        date: start,
        value: null
      });
      prices.push({
        date: end,
        value: null
      });
    }
  }
  return prices;
};

const getTotalConsumption = (grade_id, consumptions) =>
  consumptions.reduce(
    (consumption, c) =>
      c.fuel_grade_id === grade_id
        ? consumption +
          (parseFloat(c.port_default_consumption) || 0.0) +
          (parseFloat(c.port_gw_consumption) || 0.0) +
          (parseFloat(c.trip_slow_speed_consumption) || 0.0) +
          (parseFloat(c.trip_eca_speed_consumption) || 0.0) +
          (parseFloat(c.trip_eca_slow_speed_consumption) || 0.0) +
          (parseFloat(c.trip_speed_consumption) || 0.0)
        : consumption,
    0
  );

export const selectEcaZones = state => {
  const { eca_zones } = selectEstimation(state);
  return eca_zones;
};

export const selectFuelValues = state => {
  const startingFuels = selectStartingFuels(state);
  const { ports, trips } = selectEstimation(state);

  return startingFuels.map(fuel => ({
    id: fuel.id,
    label: fuel.name,
    data: calcConsumptions(fuel.id, fuel.quantity, ports, trips)
  }));
};

const calcConsumptions = (grade_id, deposit, ports, trips) => {
  const consumptions = [];
  ports.forEach(port => {
    if (port.eta) {
      consumptions.push({
        date: port.eta,
        value: deposit
      });
    }
    deposit = deposit - getTotalConsumption(grade_id, port.consumptions);
    deposit = deposit + getTotalBunkering(grade_id, port.bunkering);

    if (port.etd) {
      consumptions.push({
        date: port.etd,
        value: deposit
      });
    }

    const trip = trips.find(trip => trip.start_estimation_port_id === port.id);
    if (trip) {
      deposit = deposit - getTotalConsumption(grade_id, trip.consumptions);
    }
  });
  return consumptions;
};

export const selectPortDates = state => {
  const { groupedPorts } = selectEstimation(state);
  return groupedPorts.map(port => ({
    label: port.eta || port.etd ? port.actions[0].action.toUpperCase() : '',
    value: port.eta ? port.eta : port.etd
  }));
};

export const selectRoute = state => state.estimator.route;

export const selectStartingFuels = state => {
  const { fuels } = selectEstimation(state);
  const fuelGradesInUse = state.lists['fuel-grades'].options.filter(grade =>
    fuels.find(fuel => fuel.fuel_grade_id === grade.id)
  );

  return fuelGradesInUse.map(grade => {
    const startingValue = fuels.find(
      fuel => fuel.fuel_grade_id === grade.id && fuel.fuel_action === 'obq'
    );
    return {
      id: grade.id,
      name: grade.name,
      quantity: startingValue ? parseFloat(startingValue.quantity) : 0.0
    };
  });
};

export const selectUnits = state => state.lists.units;

export const selectUnitByLabel = label =>
  createSelector(
    selectUnits,
    units => units.options.find(unit => unit.label === label) || units.options[0]
  );
