import {
  createEventAudit,
  deleteEventAudit,
  getEventAudits,
  getEventAuditsEvaluationCriteria,
  getEventSubmissionFindings,
  updateEventAudit
} from './actions';
import { createSlice } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';

const INITIAL_STATE = {
  loading: false,
  data: {
    audits: [],
    finding_submissions: []
  },
  evaluation_criteria: []
};

const slice = createSlice({
  name: 'eventAudits',
  initialState: INITIAL_STATE,
  reducers: {
    setAuditsData: (state, { payload }) => {
      state.data.audits = payload.map((audit, index) => ({ ...audit, index }));
      return state;
    },

    addOrEditAudit: (state, { payload }) => {
      let newAudits = [];

      if (state.data.audits.findIndex(audit => audit.index === payload.index) !== -1) {
        newAudits = state.data.audits.map(audit => {
          if (audit.index === payload.index) return payload;
          return audit;
        });
      } else {
        newAudits = [...state.data.audits, payload];
      }

      state.data.audits = newAudits;
      return state;
    },

    removeAudit: (state, { payload }) => {
      state.data.audits = state.data.audits
        .filter(audit => audit.index !== payload)
        .map((audit, index) => ({ ...audit, index }));
      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getEventSubmissionFindings.fulfilled, (state, { payload }) => {
        state.data.finding_submissions = payload;
        state.loading = false;
        return state;
      })

      .addCase(getEventAudits.pending, state => {
        state.data.audits = [];
        // state.data.findings = [];
        state.loading = true;
        return state;
      })

      .addCase(getEventAudits.fulfilled, (state, { payload }) => {
        state.loading = false;

        payload.forEach(audit => {
          state.data.audits.push(audit);
        });

        return state;
      })

      .addCase(getEventAudits.rejected, state => {
        state.loading = false;
        return state;
      })

      .addCase(createEventAudit.fulfilled, (state, { payload }) => {
        state.data.audits.push(payload);
        state.loading = false;
        return state;
      })

      .addCase(updateEventAudit.fulfilled, (state, { payload }) => {
        const index = state.data.audits.findIndex(a => a.id === payload.id);
        if (index !== -1) state.data.audits[index] = payload;

        state.loading = false;
        return state;
      })

      .addCase(deleteEventAudit.fulfilled, (state, { payload }) => {
        state.data.audits = state.data.audits.filter(e => e.id !== payload.id);
        // state.data.findings = state.data.findings.filter(f => f.auditId !== payload.id);
        return state;
      })

      .addCase(getEventAuditsEvaluationCriteria.pending, state => {
        state.loading = true;
        return state;
      })

      .addCase(getEventAuditsEvaluationCriteria.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.evaluation_criteria = payload;
        return state;
      })

      .addCase(getEventAuditsEvaluationCriteria.rejected, state => {
        state.loading = false;
        return state;
      })

      .addCase(LOCATION_CHANGE, () => {
        return INITIAL_STATE;
      })

      .addDefaultCase(state => {
        return state;
      });
  }
});

export const { addOrEditAudit, removeAudit, setAuditsData } = slice.actions;

export default slice.reducer;
