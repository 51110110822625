import React from 'react';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import paths from '@/routing/routes/_paths.js';
const Projects = React.lazy(() => retry(() => import('@/views/projects/index.jsx')));
const Project = React.lazy(() => retry(() => import('@/views/projects/new-project/index.jsx')));

const projects = [
  {
    path: `${paths.projects}`,
    component: Projects,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.projects.list],
    documentTitle: 'Projects'
  },
  {
    path: `${paths.projects}/create`,
    component: Project,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.projects.create],
    documentTitle: 'Create Project'
  },
  {
    path: `${paths.projects}/:id`,
    component: Project,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.projects.view, permissions.office.projects.edit],
    permissionsMode: 'and',
    documentTitle: 'Project'
  }
];

export default projects;
