import { useSelector } from 'react-redux';

import WidgetDefaultCard from 'views/dashboard/components/WidgetDefaultCard';
import paths from 'routing/routes/_paths';

import { selectWidgetStateData } from 'store/dashboards/selectors';

const FormsCardWidget = ({ id }) => {
  const data = useSelector(state => selectWidgetStateData(state, id));

  return <WidgetDefaultCard id={id} count={data.forms_count} linkTo={paths.forms} />;
};

export default FormsCardWidget;
