import TYPES from './types';
import { get, deleteRequest } from 'utils/api';

export const togglePanel = panel => dispatch => {
  dispatch({ type: TYPES.TOGGLE_PANEL, payload: panel });
};

export const collapseMenu = () => dispatch => {
  dispatch({ type: TYPES.COLLAPSE_MENU });
};

export const getSavedItems = params => dispatch => {
  dispatch({ type: TYPES.GET_SAVED_ITEMS.START, payload: { params } });

  return get(`/items`, params)
    .then(res => {
      dispatch({ type: TYPES.GET_SAVED_ITEMS.SUCCESS, payload: res.data });

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_SAVED_ITEMS.ERROR, payload: error }));
};

export const getRecentItems = () => dispatch => {
  dispatch({ type: TYPES.GET_RECENT_ITEMS.START });

  return get(`/items/recent`)
    .then(response => {
      dispatch({ type: TYPES.GET_RECENT_ITEMS.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_RECENT_ITEMS.ERROR, payload: error }));
};

export const deleteSavedItem = params => (dispatch, getState) => {
  dispatch({ type: TYPES.DELETE_SAVED_ITEM.START, payload: { id: params.id } });

  return deleteRequest(`/items/${params.id}`)
    .then(res => {
      dispatch({ type: TYPES.DELETE_SAVED_ITEM.SUCCESS, payload: { id: params.id } });
      dispatch(
        getSavedItems({
          per_page: getState().sidebar.savedItems.paging.per_page,
          current_page: getState().sidebar.savedItems.paging.current_page
        })
      );

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_SAVED_ITEM.ERROR, payload: error });
      dispatch(
        getSavedItems({
          per_page: getState().sidebar.savedItems.paging.per_page,
          current_page: getState().sidebar.savedItems.paging.current_page
        })
      );
    });
};
