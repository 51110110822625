import TYPES from './types';
import { get, put } from 'utils/api';
import { successHandler } from 'common/utils/notifications';

export const getFavorites = params => dispatch => {
  dispatch({ type: TYPES.GET_FAVORITES.START, payload: { params } });

  return get(`/favourites`, params)
    .then(res => {
      dispatch({ type: TYPES.GET_FAVORITES.SUCCESS, payload: res.data });

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_FAVORITES.ERROR, payload: error }));
};

export const toggleFavorite = params => (dispatch, getState) => {
  dispatch({ type: TYPES.TOGGLE_FAVORITE.START, payload: params });

  return put(`/favourites/toggle`, params)
    .then(res => {
      dispatch({ type: TYPES.TOGGLE_FAVORITE.SUCCESS, payload: res.data });
      dispatch(
        successHandler({
          title: 'Success!',
          message: 'Favorites updated'
        })
      );

      dispatch(
        getFavorites({
          current_page: getState().favorites.paging.current_page,
          per_page: getState().favorites.paging.per_page
        })
      );

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.TOGGLE_FAVORITE.ERROR, payload: error }));
};

export const reorderFavorites = params => dispatch => {
  dispatch({ type: TYPES.FAVORITES_REORDER.START, payload: { params } });
  const { id, oldIndex, ...rest } = params;

  return put(`/favourites/${id}/reorder`, rest)
    .then(res => {
      dispatch({ type: TYPES.FAVORITES_REORDER.SUCCESS, payload: res.data });
      dispatch(
        successHandler({
          title: 'Success!',
          message: 'Favorites updated'
        })
      );

      return res.data;
    })
    .catch(error =>
      dispatch({
        type: TYPES.FAVORITES_REORDER.ERROR,
        payload: { response: error, params: { oldIndex: rest.target, target: oldIndex, id } }
      })
    );
};
