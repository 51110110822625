import React from 'react';
import { components } from 'react-select';

import ListSelect from 'common/components/selectors/ListSelect';
import pmsJobs from 'common/assets/svg/common/setup.svg';
import purchasing from 'common/assets/svg/common/purchasing.svg';
import documents from 'common/assets/svg/common/documents.svg';
import manuals from 'common/assets/svg/common/manuals.svg';
import contracts from 'common/assets/svg/crew/contracts.svg';
import forwarding from 'common/assets/svg/common/forwarding.svg';
import invoices from 'common/assets/svg/common/invoices.svg';
import accountingContracts from '@/common/assets/svg/common/accounting-contracts.svg';

import SvgRender from 'common/components/general/SvgRender';
import entityActions from 'common/components/review-process/entity-actions';

export const iconByValue = label => {
  if (label === entityActions.formsApproval.label) return documents;
  if (label === entityActions.manualApproval.label) return manuals;
  if (label === entityActions.purchasingRequisitionApproval.label) return purchasing;
  if (label === entityActions.pmsJobsApproval.label) return pmsJobs;
  if (label === entityActions.contractsApproval.label) return contracts;
  if (label === entityActions.purchasingForwardingApproval.label) return forwarding;
  if (label === entityActions.invoice.label) return invoices;
  if (label === entityActions.contracts.label) return accountingContracts;
};

const CallToActionSelector = ({ filter, onChange, customIconEntities, styled, ...rest }) => {
  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <div className="d-flex align-items-center">
        <SvgRender
          src={
            customIconEntities
              ? customIconEntities(props.data.label)
              : iconByValue(props.data.label)
          }
          style={{ width: '16px', height: '16px' }}
          className="cme-12"
        />
        {props.data.name}
      </div>
    </components.SingleValue>
  );

  const Option = ({ children, ...props }) => (
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <SvgRender
          src={
            customIconEntities
              ? customIconEntities(props.data.label)
              : iconByValue(props.data.label)
          }
          style={{ width: '16px', height: '16px' }}
          className="cme-12"
        />
        {props.data.name}
      </div>
    </components.Option>
  );

  return (
    <ListSelect
      placeholder="Select value"
      list="review-process-actions"
      components={{ Option, SingleValue }}
      value={filter?.value}
      isMulti={false}
      isAsync={false}
      customIconEntities={customIconEntities}
      isSearchable={false}
      onChange={(value, opt) => onChange(value, opt)}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      {...rest}
    />
  );
};

export default CallToActionSelector;
