import React, { useState } from 'react';
import { Form } from 'reactstrap';
import BoxShadowButton from 'common/components/buttons/BoxShadowButton';
import FloatingLabelInput from 'common/components/form/styled-inputs/FloatingLabelInput';

import { useForm, useFormState } from 'utils/hooks';
import config from './config';

import { useActions } from 'utils/hooks';
import * as authActions from 'store/auth/actions';

const CredentialsLogin = () => {
  const [login] = useActions([authActions.login]);
  const [loading, setLoading] = useState(false);
  const { formState, collectValues } = useForm(config);
  const { fields, changeField } = useFormState(formState);

  const onSubmit = async e => {
    e.preventDefault();
    const values = collectValues();

    if (!values) return;

    try {
      setLoading(true);
      await login(values);

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div className="login-page__credentials">
      <h1 className="fw-light">Login to your account</h1>

      <Form>
        <FloatingLabelInput
          label="Username"
          placeholder="Username"
          className="mb-0"
          wrapperClassName="login-input login-input__username mb-10"
          onChange={changeField('username')}
          {...fields.username}
        />

        <FloatingLabelInput
          label="Password"
          placeholder="Password"
          className="mb-2"
          wrapperClassName="login-input login-input__password mb-2"
          onChange={changeField('password')}
          type="password"
          hideValidation
          {...fields.password}
        />

        <div className="text-end mt-4 mt-mdlaptop-10">
          <BoxShadowButton color="yellow" type="submit" onClick={onSubmit} disabled={loading}>
            Login
          </BoxShadowButton>
        </div>
      </Form>
    </div>
  );
};

export default CredentialsLogin;
