const TYPES = {
  SET_ERRORS: 'SET_ERRORS',
  CLEAR_ERRORS: 'CLEAR_ERRORS',
  SET_CALCULATIONS: 'SET_CALCULATIONS',
  CLEAR_CALCULATIONS: 'CLEAR_CALCULATIONS',
  SET_NUMBER_FIELD_VALIDATIONS: 'SET_NUMBER_FIELD_VALIDATIONS',
  CLEAR_NUMBER_FIELD_VALIDATIONS: 'CLEAR_NUMBER_FIELD_VALIDATIONS',
  SET_CALCULATIONS_FORM_FIELD: 'SET_FORM_CALCULATIONS_FIELD',
  SET_CALCULATIONS_FORM_FIELD_INDEX: 'SET_FORM_CALCULATIONS_FIELD_INDEX',
  SET_CALCULATIONS_FORM_FIELD_ROW_INDEX: 'SET_CALCULATIONS_FORM_FIELD_ROW_INDEX',
  TOGGLE_CALCULATIONS_FORM_DRAWER: 'TOGGLE_CALCULATIONS_FORM_DRAWER',
  SET_VALIDATIONS_FORM_FIELD: 'SET_VALIDATIONS_FORM_FIELD',
  SET_VALIDATIONS_FORM_FIELD_INDEX: 'SET_VALIDATIONS_FORM_FIELD_INDEX',
  SET_FORM_FIELD_INDEXES: 'SET_FORM_FIELD_INDEXES',
  SET_IS_FORM_TABLE_FIELD: 'SET_IS_FORM_TABLE_FIELD',
  TOGGLE_VALIDATIONS_MODAL: 'TOGGLE_VALIDATIONS_MODAL',
  SET_FORM_DRAWER_TYPE: 'SET_FORM_DRAWER_TYPE',
  CLEAR_FORM_DATA: 'CLEAR_FORM_DATA',
  SET_ACTIVE_FORM_FIELD: 'SET_ACTIVE_FORM_FIELD'
};

export default TYPES;
