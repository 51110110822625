import 'tools/assets/scss/_forex-rates.scss';
import React, { useState, useEffect } from 'react';

import View from './settings/View';
import Edit from './settings/Edit';

import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';

const ForexRates = ({ tool, settings, isSettingsOpen, setIsSettingsOpen, systemCurrency }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [rates, setRates] = useState([]);
  const [selectedCurrencies, setSelectedCurrencies] = useState([]);

  const getRates = async values => {
    const res = await settings.getRates({ currencies: values.map(c => c.label) });

    if (res && res.data) {
      setRates(res.data);
    } else {
      setRates([]);
    }
  };

  const init = async () => {
    const cur = await settings.getCurrencies();
    const res = await settings.getSettings();

    if (cur && cur.data) {
      setCurrencies(cur.data);

      if (
        res &&
        res.data &&
        res.data.settings &&
        res.data.settings.currencies &&
        res.data.settings.currencies.length
      ) {
        setSelectedCurrencies(
          res.data.settings.currencies
            .map(c => cur.data.find(i => i.label === c.label))
            .filter(i => i)
        );
      } else {
        setSelectedCurrencies([{ name: 'United States Dollar', label: 'USD', symbol: '$' }]);
      }
    } else {
      setSelectedCurrencies([{ name: 'United States Dollar', label: 'USD', symbol: '$' }]);
    }
  };

  const update = async values => {
    setIsSaving(true);
    const params = {
      settings: {
        currencies: [{ label: 'USD', sort_index: 0 }]
      }
    };

    if (values) {
      params.settings.currencies = [
        ...params.settings.currencies,
        ...values.map((v, i) => ({
          label: v.label,
          sort_index: i + 1
        }))
      ];
    }

    const res = await settings.updateSettings(params);

    if (res && res.data) {
      setSelectedCurrencies(
        params.settings.currencies.map(c => currencies.find(i => i.label === c.label))
      );
    }

    setIsSettingsOpen(false);
    setIsSaving(false);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (
      selectedCurrencies.length &&
      !_isEqual(
        _sortBy(
          rates.map(r => ({ label: r.currency_label })),
          ['label']
        ),
        _sortBy(
          selectedCurrencies.map(r => ({ label: r.label })),
          ['label']
        )
      )
    ) {
      getRates(selectedCurrencies);
    }
  }, [selectedCurrencies]);

  const getLastUpdate = () => {
    const usdRate = rates.find(r => r.currency_label === 'USD');

    if (usdRate) {
      return (
        <div className="tool-last-update">
          Last Update: {moment.utc(usdRate.last_update).local().format('DD/MM/YYYY HH:mm')}
        </div>
      );
    }

    return null;
  };

  if (!systemCurrency) return;

  return (
    <div className="forex-rates-tool">
      {isSettingsOpen ? (
        <Edit
          selectedCurrencies={selectedCurrencies}
          setIsSettingsOpen={setIsSettingsOpen}
          update={update}
          currencies={currencies}
          isSaving={isSaving}
        />
      ) : (
        <View
          settings={settings}
          selectedCurrencies={selectedCurrencies}
          rates={rates}
          update={update}
          isSaving={isSaving}
          systemCurrency={systemCurrency}
        />
      )}

      {getLastUpdate()}
    </div>
  );
};

export default ForexRates;
