import React from 'react';

const VesselPerfomance = () => {
  return (
    <div className="bg-light-gray p-2 rounded rounded-xl text-violet fs-12">
      Vessel performance widget coming Soon
    </div>
  );
};

export default VesselPerfomance;
