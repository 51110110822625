import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_VESSELS'),
  ...asyncTypes('GET_VESSEL_PROFILE'),
  ...asyncTypes('GET_VESSEL_CONSUMPTIONS'),
  ...asyncTypes('GET_VESSEL_HIRES'),
  ...asyncTypes('UPDATE_VESSEL_HIRES'),
  ...asyncTypes('UPDATE_VESSEL'),
  ...asyncTypes('CREATE_VESSEL'),
  ...asyncTypes('ARCHIVE_VESSEL'),
  ...asyncTypes('UNARCHIVE_VESSEL'),
  ...asyncTypes('GET_TCE_RESULTS'),
  ...asyncTypes('GET_TCE_RESULTS_CHART'),
  ...asyncTypes('GET_TCE_RESULTS_AVERAGES'),
  ...asyncTypes('CREATE_TCE_RESULT'),
  ...asyncTypes('UPDATE_TCE_RESULT'),
  ...asyncTypes('GET_POOLS'),
  ...asyncTypes('CREATE_POOL'),
  ...asyncTypes('UPDATE_POOL'),
  ...asyncTypes('DELETE_POOL'),
  ...asyncTypes('CREATE_POOL_POINT'),
  ...asyncTypes('UPDATE_POOL_POINT'),
  ...asyncTypes('DELETE_POOL_POINT'),
  ...asyncTypes('CREATE_VESSEL_CREW'),
  ...asyncTypes('UPDATE_VESSEL_CREW'),
  ...asyncTypes('DELETE_VESSEL_CREW'),
  ...asyncTypes('GET_VESSEL_POSITION'),
  ...asyncTypes('CREATE_POOL_POINTS'),
  ...asyncTypes('UPDATE_POOL_POINTS'),
  ...asyncTypes('DELETE_POOL_POINTS'),
  ...asyncTypes('CREATE_POOL_MONTHLY_RESULT'),
  ...asyncTypes('UPDATE_POOL_MONTHLY_RESULT'),
  ...asyncTypes('DELETE_POOL_MONTHLY_RESULT'),
  ...asyncTypes('GET_DEFAULT_SOURCES'),
  ...asyncTypes('SET_DEFAULT_SOURCES'),
  ...asyncTypes('GET_LABEL_PARAMETERS'),
  ...asyncTypes('GET_INTEGRATION_SOURCES'),
  ...asyncTypes('SET_LABEL_PARAMETERS'),
  ...asyncTypes('CREATE_VESSEL_CAPTAIN_REPORT'),
  ...asyncTypes('CREATE_VESSEL_BENCHMARKS'),
  ...asyncTypes('UPDATE_VESSEL_BENCHMARK'),
  ...asyncTypes('DELETE_VESSEL_BENCHMARK'),
  ...asyncTypes('GET_VESSEL_BENCHMARKS'),
  ...asyncTypes('GET_VESSEL_BENCHMARK_TYPES'),
  ...asyncTypes('GET_VESSEL_BENCHMARK_CONDITIONS'),
  ...asyncTypes('GET_FORMULA_LOGS'),
  ...asyncTypes('REORDER_BENCHMARK_FORMULAS'),
  ...asyncTypes('GET_VESSEL_CII_REFERENCE_YEARLY'),
  ...asyncTypes('RECALCULATE_FORMULAS'),
  SET_ACTIVE_POOL_POINT: 'SET_ACTIVE_POOL_POINT',
  SET_ACTIVE_POOL: 'SET_ACTIVE_POOL',
  TOGGLE_CREW_FORM: 'TOGGLE_CREW_FORM',
  SET_ACTIVE_CREW: 'SET_ACTIVE_CREW',
  CREATE_MAKER_MODAL: 'CREATE_MAKER_MODAL',
  CHANGE_POOL_POINTS_DRAWER_STATUS: 'CHANGE_POOL_POINTS_DRAWER_STATUS',
  CHANGE_POOL_DRAWER_STATUS: 'CHANGE_POOL_DRAWER_STATUS',
  SET_BENCHMARKS_INPUT_HOVER_INDEX: 'SET_BENCHMARKS_INPUT_HOVER_INDEX',
  SET_BENCHMARKS_LOGS_DRAWER_OPEN: 'SET_BENCHMARKS_LOGS_DRAWER_OPEN',
  SET_BENCHMARKS_ACTIVE_LOGS_FORMULA: 'SET_BENCHMARKS_ACTIVE_LOGS_FORMULA',
  SET_EDITTING_BENCHMARK_ID: 'SET_EDITTING_BENCHMARK_ID',
  SET_VESSEL_PROFILE: 'SET_VESSEL_PROFILE'
};

export default TYPES;
