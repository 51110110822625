import React, { useState, Fragment, useEffect } from 'react';

import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Label } from 'reactstrap';
import BaseStatusLabel from './BaseStatusLabel';
import _debounce from 'lodash/debounce';

import { useSelector } from 'react-redux';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

const BaseStatusDropdown = ({
  value,
  statuses,
  className = '',
  onChange,
  hideLabel = false,
  isLocked = false,
  getOptionValue = undefined,
  getOptionLabel = undefined,
  showLockIcon = false,
  statusLabelClassName = '',
  dropdownClassName = '',
  menuProps = {},
  lockedTooltip = false,
  valueKey = 'id'
}) => {
  const isOnboard = useSelector(state => state.isOnBoard);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = e => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const initDefaultStatus = _debounce(onChange, 400);

  const changeToDefault = () => {
    const filteredStatuses = statuses.filter(s =>
      isOnboard ? s.is_for_vessel && s.can_select_from_onboard : s.is_for_office
    );
    const defaultStatus = filteredStatuses.length ? filteredStatuses[0] : null;
    if (defaultStatus) initDefaultStatus(defaultStatus);
  };

  useEffect(() => {
    if (value === null && statuses?.length) changeToDefault();
  }, [value, statuses?.length]);

  const { tooltipID, avoidRender } = useTooltipID('status-locked-tooltip');

  if (avoidRender) return null;

  return !statuses?.length ? null : (
    <div className={`job-input job-input--status status ${className ? className : ''}`}>
      {!hideLabel ? <Label className="form-label ">STATUS</Label> : null}

      <div>
        <ButtonDropdown
          className={`${showLockIcon ? 'hide-dropdown-toggle' : ''}`}
          isOpen={dropdownOpen}
          toggle={toggle}
          disabled={isLocked}
          id={tooltipID}
        >
          <DropdownToggle caret color="link" disabled={isLocked || showLockIcon}>
            {value ? (
              <BaseStatusLabel
                value={value ? (getOptionValue ? getOptionValue(value) : value.name) : null}
                color={value ? value.color : null}
                label={value ? (getOptionLabel ? getOptionLabel(value) : value.label) : null}
                showLockIcon={showLockIcon}
                className={statusLabelClassName}
              />
            ) : (
              <BaseStatusLabel value={'Select Status'}></BaseStatusLabel>
            )}
          </DropdownToggle>
          <DropdownMenu
            className={`${
              dropdownOpen ? 'd-flex flex-column' : ''
            } status-dropdown mt-1 ${dropdownClassName}`}
            {...menuProps}
          >
            {statuses &&
              statuses.map(st => {
                if (st?.[valueKey] === value?.[valueKey]) return null;

                return (
                  <Fragment key={st.id}>
                    <DropdownItem
                      key={st.id}
                      tag="div"
                      onClick={e => {
                        e.stopPropagation();
                        onChange(st);
                      }}
                      className={`status-item`}
                      disabled={
                        isOnboard
                          ? !st.can_select_from_onboard || st.disabled
                          : st?.isLocked || false
                      }
                    >
                      <BaseStatusLabel
                        className="w-100p no-border-radius cursor-pointer"
                        color={st.color}
                        value={getOptionValue ? getOptionValue(st) : st.name}
                        label={getOptionLabel ? getOptionLabel(st) : st.label}
                        isLocked={st?.isLocked}
                        lockedTooltipText={st?.lockedTooltipText}
                        svgStyle={{ width: 14, height: 14 }}
                      />
                    </DropdownItem>
                  </Fragment>
                );
              })}
          </DropdownMenu>
          {lockedTooltip ? (
            <Tooltip
              target={tooltipID}
              innerClassName="min-width-fit max-width-fit"
              boundariesElement="viewport"
              placement={'top-start'}
            >
              {lockedTooltip}
            </Tooltip>
          ) : null}
        </ButtonDropdown>
      </div>
    </div>
  );
};

export default BaseStatusDropdown;
