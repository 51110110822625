import React from 'react';

const Switch = ({ active, disabled, onClick }) => {
  return (
    <div
      className={`tool-switch ${active ? 'is-active' : 'is-inactive'} ${
        disabled ? 'disabled' : ''
      }`}
      onClick={onClick}
    >
      <div></div>
    </div>
  );
};

export default Switch;
