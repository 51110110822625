export { refetchAsyncOptions, getAsyncOptions, getInitialAsyncValues } from 'common/utils/lists';

export const selectMenuType = (settingsItems, location) => {
  const inSettings = settingsItems
    .filter(i => i)
    .some(i => {
      if (!i.submenu) {
        return location.pathname.startsWith(i.path);
      } else {
        return i.submenu.some(i => {
          return location.pathname.startsWith(i.path);
        });
      }
    });

  return inSettings ? 'settings' : 'menu';
};
