import CodeDigitsInput from 'common/components/form/inputs/CodeDigitsInput';
import PropTypes from 'prop-types';
import QrCode from './QrCode';

const Step = ({ index, info, children }) => {
  return (
    <div className="d-flex flex-column fw-medium fs-14 text-dark">
      <div className="d-flex flex-nowrap align-items-center">
        <div className="width-32 height-32 bg-dark text-white rounded-lg shadow-lg gotham-bold fs-16 d-inline-flex align-items-center justify-content-center me-2">
          {index}
        </div>
        <div className="flex-1">
          <div>{info}</div>
        </div>
      </div>
      <div className="ps-6">{children}</div>
    </div>
  );
};

Step.propTypes = {
  index: PropTypes.number.isRequired,
  info: PropTypes.node
};

const SetupAuthenticatorApp = ({ onCodeChange, codeError, qrToken }) => {
  return (
    <div>
      <div className="text-violet fs-14 fw-medium mb-5">
        A virtual MFA device is an application running on your device that you can configure by
        scanning a QR code.
      </div>

      <Step
        index={1}
        info={
          <div>
            <strong className="fw-black">Install</strong> a compatible application such as{' '}
            <strong className="fw-black">Google Authenticator</strong>, Microsoft Authenticator, Duo
            Mobile, or Authy app on your mobile device or computer.
          </div>
        }
      >
        <div className="mb-7" />
      </Step>

      <Step index={2} info={<div>Open your authenticator app and scan the code.</div>}>
        <div className="mt-3 mb-6">
          <QrCode token={qrToken} />
        </div>
      </Step>

      <Step index={3} info={<div>Fill in the code from Authenticator App.</div>}>
        <div className="mt-3 mb-4 d-flex ms-n1">
          <CodeDigitsInput digits={6} onChange={onCodeChange} error={codeError} />
        </div>
      </Step>
    </div>
  );
};

SetupAuthenticatorApp.propTypes = {
  onCodeChange: PropTypes.func,
  onClose: PropTypes.func,
  codeError: PropTypes.string,
  qrToken: PropTypes.string.isRequired
};

export default SetupAuthenticatorApp;
