import { asyncTypes } from '../_helpers';

const TYPES = {
  ...asyncTypes('CREATE_REVIEW_PROCESS_TEMPLATE'),
  ...asyncTypes('DELETE_REVIEW_PROCESS_TEMPLATE'),
  ...asyncTypes('GET_REVIEW_PROCESS_TEMPLATE'),
  ...asyncTypes('UPDATE_REVIEW_PROCESS_TEMPLATE')
};

export default TYPES;
