import { hasValue } from 'common/utils/numbers';
import { numberToStr } from 'common/utils/numbers';

export const constructCiiValues = (upperBound, lowerBound) => {
  return hasValue(upperBound) && !hasValue(lowerBound)
    ? ` CII ≤ ${numberToStr(upperBound, 2, 2)}`
    : hasValue(lowerBound) && !hasValue(upperBound)
    ? ` ${numberToStr(lowerBound, 2, 2)} < CII `
    : hasValue(lowerBound) && hasValue(upperBound)
    ? ` ${numberToStr(lowerBound, 2, 2)} < CII ≤ ${numberToStr(upperBound, 2, 2)}`
    : '';
};
