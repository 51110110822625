import TYPES from './types';

import { get, post } from 'utils/api';

export const getVapidKey = params => dispatch => {
  dispatch({ type: TYPES.GET_VAPID_KEY.START, payload: { params } });

  return get('/account/notifications/webpush/vapid', params)
    .then(response => {
      dispatch({ type: TYPES.GET_VAPID_KEY.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_VAPID_KEY.ERROR, payload: error }));
};

export const subscibeUser = params => dispatch => {
  dispatch({ type: TYPES.SUBSCRIBE_USER.START, payload: { params } });

  return post('/account/notifications/webpush/subscribe', params)
    .then(response => {
      dispatch({ type: TYPES.SUBSCRIBE_USER.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.SUBSCRIBE_USER.ERROR, payload: error }));
};
