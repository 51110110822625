import React from 'react';
import retry from 'common/utils/lazy-retry';
import permissions from 'common/utils/permissions/constants';
import paths from 'routing/routes/_paths';
import { selectListDefaultOptions } from 'store/lists/selectors';

const Itinerary = React.lazy(() => retry(() => import('@/views/voyages/itinerary/index.jsx')));
const CharterParties = React.lazy(() =>
  retry(() => import('@/views/voyages/charter-parties/index.jsx'))
);
// const Accounts = React.lazy(() => retry(() => import('views/voyages/accounts')));
// const LaytimeCalculator = React.lazy(() => retry(() => import('views/voyages/laytime-calculator')));

const selectMenuPath = (state, path) => {
  const vessels = selectListDefaultOptions(state, 'vessels');

  if (vessels?.[0]?.id) {
    return `${paths.voyages}/${vessels[0]?.id}/${path}`;
  }

  return paths.voyages;
};

const tabs = [
  {
    path: 'itinerary',
    selectMenuPath: () => paths.voyages,
    label: 'itinerary',
    component: Itinerary,
    permissions: [permissions.office.voyages.itinerary.view]
  },
  {
    path: 'charter-parties',
    selectMenuPath: state => selectMenuPath(state, 'charter-parties'),
    label: 'charter parties',
    component: CharterParties,
    permissions: [permissions.office.voyages.charter_parties.view]
  }
  // { path: 'accounts', label: 'accounts', component: Accounts  },
  // {
  //   path: 'laytime-calculator',
  //   label: 'laytime calculator',
  //   component: LaytimeCalculator,
  // }
];

export default tabs;
