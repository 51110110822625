import { getNoonReportsTechnicalOverview } from './actions';
import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isFetching: false,
  data: []
};

const slice = createSlice({
  name: 'performance',
  initialState: INITIAL_STATE,
  extraReducers: {
    [getNoonReportsTechnicalOverview.pending]: state => {
      state.isFetching = true;

      return state;
    },
    [getNoonReportsTechnicalOverview.rejected]: state => {
      state.isFetching = false;

      return state;
    },
    [getNoonReportsTechnicalOverview.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.data = payload?.data;

      return state;
    }
  }
});

export default slice.reducer;
