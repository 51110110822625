import React from 'react';

const SingleVesselConsumption = ({ speed, fo_consumption, go_consumption, vessel_name }) => {
  return (
    <div className="vessel-consumptions-list--vessel ">
      <div className="vessel-consumptions-list--vessel-name">{vessel_name}</div>
      <div className="d-flex flex-column">
        <div className="vessel-consumptions-list--vessel-info">
          <div className="info-number">{speed}</div>
          <div className="info-label">knots</div>
        </div>
        <div className="vessel-consumptions-list--vessel-info">
          <div className="info-number">{fo_consumption}</div>
          <div className="info-label">mt/d FO</div>
        </div>
        <div className="vessel-consumptions-list--vessel-info">
          <div className="info-number">{go_consumption}</div>
          <div className="info-label">mt/d GO</div>
        </div>
      </div>
    </div>
  );
};

const ConsumptionsList = ({ consumption, name, color }) => {
  return (
    <div className="vessel-consumptions-list mt-3">
      <div className="vessel-consumptions-list--label">{name}</div>
      <div className="vessel-consumptions-list--card mt-1">
        {consumption.map((el, i) => (
          <SingleVesselConsumption
            key={i}
            vessel_name={el.vessel_name}
            fo_consumption={el.fo_consumption}
            go_consumption={el.go_consumption}
            speed={el.speed}
          />
        ))}

        <div className={`vessel-consumptions-list--line vessel-consumption-${color} `} />
        <div className="vessel-consumptions-list--border" />
      </div>
    </div>
  );
};

export default ConsumptionsList;
