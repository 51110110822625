import React from 'react';

import ButtonIcon from './ButtonIcon';

import config from '../tool/types/_config';

const AddTool = ({ tool, onAdd }) => {
  const renderIcon = () => {
    const icon = config[tool.label] ? config[tool.label].icon : null;

    if (!icon) return;

    switch (tool.label) {
      case 'forex_rates':
        return <img src={icon} width={18} height={18} alt="forex rates icon" />;
      case 'calculator':
        return <img src={icon} width={18} height={18} alt="calculator icon" />;
      case 'vessel_consumptions':
        return <img src={icon} width={18} height={18} alt="consumptions icon" />;
      case 'dwt_draft_calculator':
        return <img src={icon} width={18} height={18} alt="draft dwt icon" />;
      default:
        return null;
    }
  };

  return (
    <div className="add-tool-row">
      {renderIcon()}
      <span className="ps-1">{tool.name}</span>
      <ButtonIcon type={'add'} onClick={() => onAdd(tool)} />
    </div>
  );
};

export default AddTool;
