import { Row, Col } from 'reactstrap';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import SvgRender from 'common/components/general/SvgRender';
import binIcon from 'common/assets/svg/actions/delete.svg';
import AsyncSelector from 'common/components/selectors/AsyncSelector';

import { useFormState } from 'utils/hooks';

const ConnectedCompanyField = ({ connectedCompaniesState = [], state, onRemove, hideRemove }) => {
  const { fields, selectField } = useFormState(state);
  const selectedCompanies = connectedCompaniesState?.map(e => e?.state?.company?.value?.id);

  return (
    <Row className="w-100p position-relative" css={style} noGutters>
      <Col xs={12} className="d-flex align-items-center mb-1">
        <AsyncSelector
          className="mb-0 w-100p"
          label=""
          placeholder="Select Company"
          type="parties"
          onChange={selectField('company')}
          listParams={{ type: 'company', excluded_ids: selectedCompanies }}
          isMulti={false}
          isClearable={false}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.full_name}
          menuPlacement="top"
          {...fields.company}
        />
      </Col>

      {hideRemove ? null : (
        <SvgRender
          style={{ width: 14, height: 16 }}
          onClick={onRemove}
          src={binIcon}
          className="text-red remove-bin"
        />
      )}
    </Row>
  );
};

export default ConnectedCompanyField;

ConnectedCompanyField.propTypes = {
  state: PropTypes.object.isRequired,
  connectedCompaniesState: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
  hideRemove: PropTypes.bool
};

const style = css`
  .remove-bin {
    position: absolute;
    right: -20px;
    top: 4px;
  }
`;
