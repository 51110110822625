export const TYPES = {
  CAPTAIN_REPORT_SUBMITTED_FROM_VESSEL: 'cpt_report.submitted_from_vessel',
  CAPTAIN_REPORT_APPROVED: 'cpt_report.approved',
  CAPTAIN_REPORT_CHAT_MESSAGE_CREATED: 'cpt_report.chat_message_created',

  EVENT_CREATED: 'events.created',
  EVENT_EDITED: 'events.edited',

  FORM_SUBMITTED: 'forms.submitted',
  FORM_REVIEWED: 'forms.reviewed',
  FORM_CHAT_MESSAGE_CREATED: 'forms.chat_message_created',

  EVALUATION_OBLIGATION_CREATED: 'evaluation_obligations.created',

  SEAMAN_PROFILE_CREATED: 'seamen.profile_created',
  SEAMAN_PROFILE_APPROVED: 'seamen.profile_approved',
  SEAMAN_TERMINATED: 'seamen.terminated',

  TICKETING_EVENT_CASE_CREATED: 'ticketing.event_case_created',
  TICKETING_CREW_SWITCH_CASE_CREATED: 'ticketing.crew_switch_case_created',

  PURCHASING_REQUISITION_FOR_VESSEL_SUBMITTED_FROM_VESSEL:
    'vessel_purchasing_requisitions.submitted_from_vessel',
  PURCHASING_REQUISITION_FOR_VESSEL_SUBMITTED_FROM_OFFICE:
    'vessel_purchasing_requisitions.submitted_from_office',
  PURCHASING_REQUISITION_DELIVERY_REPORT_SUBMITTED_FROM_VESSEL:
    'vessel_purchasing_requisitions.delivery_report_submitted_from_vessel',
  PURCHASING_REQUISITION_CASE_FOR_VESSEL_APPROVED: 'vessel_purchasing_requisitions.approved',

  PURCHASING_REQUISITION_FOR_OFFICE_SUBMITTED_FROM_OFFICE:
    'office_purchasing_requisitions.submitted',
  PURCHASING_REQUISITION_DELIVERY_REPORT_SUBMITTED_FROM_OFFICE:
    'office_purchasing_requisitions.delivery_report_submitted',
  PURCHASING_REQUISITION_CASE_FOR_OFFICE_APPROVED: 'office_purchasing_requisitions.approved',

  PURCHASING_REVIEW_PROCESS_CREATED: 'purchasing.review_process_created',
  PURCHASING_REVIEW_PROCESS_ACTION_REQUIRED: 'purchasing.review_process_required',

  MGA_PERIOD_SUBMITTED: 'mga.period_submitted',

  NEWS_AND_ANNOUNCEMENTS_POST_PUBLISHED: 'posts.post_published',
  NEWS_AND_ANNOUNCEMENTS_ANNOUNCEMENT_PUBLISHED: 'posts.announcement_published',

  CHARTER_PARTY_CREATED: 'charter_parties.created',
  CHARTER_PARTY_EDITED: 'charter_parties.edited',
  CHARTER_PARTY_SUBCHARTERER_CREATED: 'charter_parties.subcharterer_created',
  VESSEL_DOCUMENTS_REMINDER: 'vessel_documents.reminder'
};

export const COMPONENT_TYPES = {
  CAPTAIN_REPORTS: 'cpt_report',
  EVENTS: 'events',
  FORMS: 'forms',
  EVALUATION_OBLIGATIONS: 'evaluation_obligations',
  SEAMEN: 'seamen',
  TICKETING: 'ticketing',
  PURCHASING: 'purchasing',
  OFFICE_PURCHASING_REQUISITIONS: 'office_purchasing_requisitions',
  VESSEL_PURCHASING_REQUISITIONS: 'vessel_purchasing_requisitions',
  MGA: 'mga',
  NEWS_AND_ANNOUNCEMENTS: 'posts',
  CHARTER_PARTY: 'charter_parties',
  VESSEL_DOCUMENTS: 'vessel_documents'
};

export const SUMMARY_TYPES = {
  ALL: 'all',
  SUMMARY: 'summary'
};

export const summaryTypeAlias = {
  [SUMMARY_TYPES.ALL]: 'All',
  [SUMMARY_TYPES.SUMMARY]: 'Per Category'
};

export const typesAliases = {
  [COMPONENT_TYPES.CAPTAIN_REPORTS]: 'Captain Reports',
  [COMPONENT_TYPES.EVENTS]: 'Events',
  [COMPONENT_TYPES.FORMS]: 'Forms',
  [COMPONENT_TYPES.EVALUATION_OBLIGATIONS]: 'Evaluation Obligations',
  [COMPONENT_TYPES.SEAMEN]: 'Crew',
  [COMPONENT_TYPES.TICKETING]: 'Ticketing',
  [COMPONENT_TYPES.PURCHASING]: 'Purchasing',
  [COMPONENT_TYPES.OFFICE_PURCHASING_REQUISITIONS]: 'Purchasing - Office Requisition',
  [COMPONENT_TYPES.VESSEL_PURCHASING_REQUISITIONS]: 'Purchasing - Vessel Requisition',
  [COMPONENT_TYPES.MGA]: 'MGA',
  [COMPONENT_TYPES.NEWS_AND_ANNOUNCEMENTS]: 'News & Announcements',
  [COMPONENT_TYPES.CHARTER_PARTY]: 'Charter Party',
  [COMPONENT_TYPES.VESSEL_DOCUMENTS]: 'Vessel Documents',

  [TYPES.VESSEL_DOCUMENTS_REMINDER]: 'Vessel Documents - Reminders',
  [TYPES.PURCHASING_REVIEW_PROCESS_CREATED]: 'Purchasing | Review Process',
  [TYPES.PURCHASING_REVIEW_PROCESS_ACTION_REQUIRED]: 'Purchasing | Review Process'
};
