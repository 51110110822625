import { get, put } from 'utils/api';

import { createAsyncThunk } from '@reduxjs/toolkit';
import TABLE_TYPES from 'common/components/table/store/types';
import { vesselCiiProfileColumns } from './tableConfig';

import {
  getVesselVoyageForecastParams,
  parseVesselVoyageForecastPorts,
  recalculateVesselVoyageForecastPortFields
} from './helpers';
import { selectTableListData } from 'common/components/table/store/selectors';

export const getVesselCiiProfile = createAsyncThunk(
  'GET_VESSEL_CII_PROFILE',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { filters } = params;

      const columns = vesselCiiProfileColumns;

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.START,
        payload: {
          params,
          filters,
          table: 'reports_vessel_cii_profile_ballast',
          columns,
          data: []
        }
      });

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.START,
        payload: {
          params,
          filters,
          table: 'reports_vessel_cii_profile_laden',
          columns,
          data: []
        }
      });

      const res = await get('/reports/vessel-cii-profile', params);

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: {
          data: {
            ...res?.data,
            data: res?.data?.data?.ballast || [],
            columns
          },
          table: 'reports_vessel_cii_profile_ballast'
        }
      });

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: {
          data: { ...res?.data, data: res?.data?.data?.laden || [], columns },
          table: 'reports_vessel_cii_profile_laden'
        }
      });

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getVesselCiiProfileGraphs = createAsyncThunk(
  'GET_VESSEL_CII_PROFILE_GRAPHS',
  async (params, { rejectWithValue }) => {
    try {
      const res = await get('/reports/vessel-cii-profile/graph', params);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

// Voyage Forecast
export const getVesselVoyageForecast = createAsyncThunk(
  'GET_VESSEL_VOYAGE_FORECAST',
  async (params, { dispatch, rejectWithValue }) => {
    dispatch({
      type: TABLE_TYPES.GET_TABLE_LIST.START,
      payload: {
        params,
        table: 'vessel_voyage_forecast',
        data: []
      }
    });

    try {
      const res = await get(`/vessels/${params.id}/voyage-forecast`);

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: {
          data: parseVesselVoyageForecastPorts(res?.data?.voyage_forecast_ports || []),
          table: 'vessel_voyage_forecast'
        }
      });

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateVesselVoyageForecast = createAsyncThunk(
  'UPDATE_VESSEL_VOYAGE_FORECAST',
  async (_, { rejectWithValue, dispatch }) => {
    const params = dispatch(getVesselVoyageForecastParams());

    try {
      const res = await put(`/vessels/${params.vessel_id}/voyage-forecast`, params);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const calculateVesselVoyageForecast = createAsyncThunk(
  'CALCULATE_VESSEL_VOYAGE_FORECAST',
  async (params = {}, { rejectWithValue, dispatch, getState }) => {
    try {
      const { update_only_calculated_values } = params;

      const requestParams = dispatch(getVesselVoyageForecastParams(params));

      const res = await put(
        `/vessels/${requestParams.vessel_id}/voyage-forecast/calculate`,
        requestParams
      );

      const voyage_forecast_ports = res?.data?.voyage_forecast_ports || [];

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: {
          data: update_only_calculated_values
            ? recalculateVesselVoyageForecastPortFields(
                voyage_forecast_ports,
                selectTableListData(getState(), 'vessel_voyage_forecast')
              )
            : parseVesselVoyageForecastPorts(voyage_forecast_ports),
          table: 'vessel_voyage_forecast'
        }
      });

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const removeLastVesselVoyageForecastPort = createAsyncThunk(
  'REMOVE_LAST_VESSEL_VOYAGE_FORECAST_PORT',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { voyage_forecast_ports, vessel_id, ...rest } = dispatch(
        getVesselVoyageForecastParams(params)
      );
      const totalPorts = voyage_forecast_ports?.length;

      const updatedVoyageForecastPorts = voyage_forecast_ports.filter(
        (_, index) => index !== totalPorts - 1 && index !== totalPorts - 2
      ); // Remove the last port and its trip

      const requestParams = {
        ...rest,
        vessel_id,
        voyage_forecast_ports: updatedVoyageForecastPorts
      };

      const res = await put(`/vessels/${vessel_id}/voyage-forecast/calculate`, requestParams);

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: {
          data: parseVesselVoyageForecastPorts(res?.data?.voyage_forecast_ports || []),
          table: 'vessel_voyage_forecast'
        }
      });

      dispatch(updateVesselVoyageForecast());

      return requestParams;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
