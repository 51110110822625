import React, { useState } from 'react';
import { Button } from 'reactstrap';

import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

import '../assets/scss/_button.scss';
import tools from '../assets/svg/tools-diamond.svg';

const Toggle = ({ onClick, className = '' }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const onBtnClick = () => {
    onClick();
    setTooltipOpen(false);
  };

  return (
    <>
      <Button
        type="button"
        className={`tools-toggle-button ${className}`}
        id="tools-toggle-button"
        onClick={onBtnClick}
      >
        <img alt="tools-icon" src={tools} width={16} height={24} />
      </Button>
      <Tooltip placement="bottom" target="tools-toggle-button" isOpen={tooltipOpen} toggle={toggle}>
        Fine Tools
      </Tooltip>
    </>
  );
};

export default Toggle;

Toggle.propTypes = {
  onClick: PropTypes.func.isRequired
};
