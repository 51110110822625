import React, { useCallback } from 'react';
import Drawer, { DrawerHeader } from 'common/components/drawer';

import { FormDrawer } from 'common/components/drawer';
import { selectDashboardForm } from 'store/dashboards/selectors';
import { useSelector } from 'react-redux';

import { useActions } from 'utils/hooks';

import * as dashboardsActions from 'store/dashboards/actions';

import CreateForm from './CreateForm';
import EditForm from './EditForm';

const Form = () => {
  const [setDashboardForm] = useActions([dashboardsActions.setDashboardForm]);
  const { isOpen, active } = useSelector(selectDashboardForm);

  const onClose = useCallback(() => setDashboardForm({ isOpen: false, active: null }), []);

  return (
    <Drawer isOpen={isOpen} close={onClose}>
      <DrawerHeader>{active ? 'Edit Dashboard' : 'Create new Dashboard'}</DrawerHeader>

      <FormDrawer>
        {active ? (
          <EditForm active={active} isOpen={isOpen} onClose={onClose} />
        ) : (
          <CreateForm isOpen={isOpen} onClose={onClose} />
        )}
      </FormDrawer>
    </Drawer>
  );
};

export default Form;
