import TYPES from './types';

// Initialized in utils/store/initial load
export const INITIAL_STATE = {
  channelPrefix: '',
  notificationChannel: ''
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_SOCKET_INFO.SUCCESS:
      return {
        ...state,
        channelPrefix: payload['channel-prefix'],
        notificationChannel: payload['notifications-channel']
      };

    case TYPES.GET_SOCKET_INFO.SUCCESS:
      return {
        ...state,
        channelPrefix: '',
        notificationChannel: ''
      };

    default:
      return state;
  }
};

export default reducer;
