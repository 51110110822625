import PersonBasicInfo from 'common/components/person/components/PersonBasicInfo';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectActiveDashboardHasSidebar } from 'store/dashboards/selectors';
import paths from 'routing/routes/_paths';

const WidgetPersonsList = ({ data }) => {
  const hasSidebar = useSelector(selectActiveDashboardHasSidebar);

  return (
    <Row>
      {data.map(person => (
        <Col xs={hasSidebar ? 10 : 5} key={person.id}>
          <PersonBasicInfo
            person={person}
            bottomInfo={person.active_vessel?.name || 'No Vessel'}
            isLinkEnabled
            linkPath={paths[person.type === 'crew' ? 'crew' : 'persons']}
            className="cps-6 pt-1 pb-1"
            profileImageProps={{ size: 'xxs', className: 'me-1', rounded: true }}
          />
        </Col>
      ))}
    </Row>
  );
};

export default WidgetPersonsList;
