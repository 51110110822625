import PropTypes from 'prop-types';
import { useState } from 'react';
import LinkTo from 'common/components/general/LinkTo';
import { getInitialSingleValue } from 'common/utils/lists';
import { createTableLink } from '../widgets/helper';

import { useSelector } from 'react-redux';
import { selectWidgetPreferences } from 'store/dashboards/selectors';
import AuthCheck from 'components/permissions/AuthCheck';

const WidgetLinkTo = ({ id, linkTo, linkToPermissions, size }) => {
  const preferences = useSelector(state => selectWidgetPreferences(state, id));

  const [isLoading, setIsLoading] = useState(null);

  const navigateHandler = async () => {
    try {
      setIsLoading(true);
      const res = preferences?.saved_table_id
        ? await getInitialSingleValue(`/tables/${preferences?.saved_table_id}`)
        : null;

      setIsLoading(false);

      window.open(res ? createTableLink(linkTo, res) : linkTo, '_blank');
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  return linkTo ? (
    <AuthCheck permissions={linkToPermissions}>
      <LinkTo onClick={navigateHandler} disabled={isLoading} size={size} />
    </AuthCheck>
  ) : null;
};

WidgetLinkTo.propTypes = {
  id: PropTypes.number.isRequired, // widget id
  linkTo: PropTypes.string,
  linkToPermissions: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.oneOf(['sm', 'md'])
};

export default WidgetLinkTo;
