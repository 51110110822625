import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import paths from 'routing/routes/_paths';

const VesselRibbon = ({ vessel, className }) => {
  return (
    <Link to={`${paths.vessels}/${vessel.id}`} className={`vessel-ribbon ${className || ''}`}>
      {vessel.name}
    </Link>
  );
};

export default VesselRibbon;

VesselRibbon.propTypes = {
  vessel: PropTypes.object.isRequired,
  className: PropTypes.string
};
