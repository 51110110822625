import React, { useState, useCallback } from 'react';
import Drawer, { DrawerHeader } from 'common/components/drawer';
import WidgetSettings from 'views/dashboard/components/WidgetSettings';
import WidgetTitle from 'views/dashboard/components/WidgetTitle';

import { FormDrawer } from 'common/components/drawer';
import { selectWidgetSettingsForm, selectWidgetTypeLabel } from 'store/dashboards/selectors';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import widgetsDetails from 'views/dashboard/widgets/widgets-details';

import * as dashboardsActions from 'store/dashboards/actions';

const PrefencesForm = () => {
  const { isOpen, activeID } = useSelector(selectWidgetSettingsForm);
  const label = useSelector(state => selectWidgetTypeLabel(state, activeID));

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [setWidgetSettingsForm, updateWidgetPreferences, updateWidget] = useActions([
    dashboardsActions.setWidgetSettingsForm,
    dashboardsActions.updateWidgetPreferences,
    dashboardsActions.updateWidget
  ]);

  const onClose = useCallback(() => setWidgetSettingsForm({ isOpen: false }), []);

  const onSave = async params => {
    const { updateWidgetData, ...rest } = params;

    try {
      setIsSubmitting(true);

      const save = updateWidgetData ? updateWidget : updateWidgetPreferences;

      await save({ ...rest, widgetId: activeID });

      onClose();
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);

      setIsSubmitting(false);
    }
  };

  const Component = widgetsDetails?.[label]?.PreferencesComponent || WidgetSettings;

  return (
    <Drawer isOpen={isOpen} close={onClose}>
      {activeID ? (
        <DrawerHeader>
          <div>Edit Widget</div>
          <WidgetTitle
            id={activeID}
            withActions={false}
            className="mb-0 cmt-4 fs-12"
            titleClassName="text-dark"
            withIcon={false}
          />
        </DrawerHeader>
      ) : null}

      <FormDrawer>
        {activeID ? (
          <Component
            key={activeID}
            id={activeID}
            label={label}
            isSubmitting={isSubmitting}
            onSave={onSave}
            onClose={onClose}
          />
        ) : null}
      </FormDrawer>
    </Drawer>
  );
};

export default PrefencesForm;
