import React, { useState, useEffect, useRef, useMemo } from 'react';
import NumberInput from 'common/components/form/inputs/NumberInput';
import SvgRender from 'common/components/general/SvgRender';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import warning from 'common/assets/svg/common/warning-icon.svg';
import { useCallback } from 'react';

const Input = ({ index, value, onChange, error }) => {
  return (
    <InputWrapper hasValue={!!value} hasError={!!error} data-index={index}>
      <NumberInput
        className="mb-0"
        value={value}
        allowNegative={false}
        decimalScale={0}
        maxLength={1}
        onChange={e => onChange(e.target.value, index)}
        dataCy="code_digit"
      />
    </InputWrapper>
  );
};

Input.propTypes = {
  index: PropTypes.number,
  value: PropTypes.number,
  error: PropTypes.string,
  onChange: PropTypes.func
};

const CodeDigitsInput = ({ digits, error, onChange }) => {
  const containerRef = useRef(null);
  const indexes = useMemo(() => Array.from(Array(digits).keys()), [digits]);
  const [value, setValue] = useState({});

  useEffect(() => {
    if (indexes?.length && containerRef.current) {
      setTimeout(() => {
        const firstInput = containerRef.current.querySelector('[data-index="0"] .form-control');
        if (firstInput) firstInput.focus();
      }, [1000]);
    }
  }, [indexes?.length, containerRef.current]);

  useEffect(() => {
    const values = Object.values(value);

    onChange({
      value: values.join(''),
      isValid: indexes?.length && values?.length === indexes?.length && values.every(v => v)
    });
  }, [value, indexes?.length]);

  const changeInputValue = (value, index) => {
    setValue(prev => ({ ...prev, [index]: value }));

    const nextInput = containerRef.current.querySelector(
      `[data-index="${index + 1}"] .form-control`
    );

    if (nextInput && value) {
      nextInput.focus();
      nextInput.select();
    }
  };

  useKeyPessNavigation(containerRef?.current);

  return (
    <div ref={containerRef} className="d-flex flex-column position-relative">
      <div className="d-flex align-items-center justify-content-center">
        {indexes.map(index => (
          <Input
            key={index}
            value={value[index]}
            index={index}
            onChange={changeInputValue}
            error={error}
          />
        ))}
      </div>

      {error && (
        <Error className="text-red text-center fs-12 lh-1 d-flex align-items-center justify-content-center fw-medium position-absolute w-100p">
          <SvgRender src={warning} style={{ width: 12, height: 12 }} className="me-1" />
          {error}
        </Error>
      )}
    </div>
  );
};

CodeDigitsInput.propTypes = {
  digits: PropTypes.number.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired // Function to be called when all digits are filled
};

const Error = styled.div`
  bottom: -${variables.size20};
`;

const InputWrapper = styled.div`
  position: relative;

  .form-control {
    width: ${variables.size44};
    height: ${variables.size44};
    margin: 0 ${variables.size6};
    border-radius: 7px;
    font-size: ${variables.size20};
    color: ${variables.squidInk};
    font-weight: bold;
    text-align: center;
    border-color: ${({ hasValue, hasError }) =>
      hasError ? variables.red : hasValue ? variables.butterYellow : ''};

    &:focus {
      border-color: ${variables.squidInk};
    }
  }
`;

const useKeyPessNavigation = containerRef => {
  const getCurrentInputIndex = useCallback(target => {
    const currentFocusedInput = target;
    const parentContainer = currentFocusedInput.parentNode.parentNode;
    const index = parentContainer.dataset.index;

    return parseInt(index);
  }, []);

  const focusNextInput = useCallback(
    target => {
      const index = getCurrentInputIndex(target);
      const nextInput = containerRef.querySelector(`[data-index="${index + 1}"] .form-control`);

      if (nextInput) {
        nextInput.focus();
        setTimeout(() => nextInput.select(), 10);
      }
    },
    [containerRef, getCurrentInputIndex]
  );

  const focusPreviousInput = useCallback(
    target => {
      const index = getCurrentInputIndex(target);
      const previousInput = containerRef.querySelector(`[data-index="${index - 1}"] .form-control`);

      if (previousInput) {
        previousInput.focus();
        setTimeout(() => previousInput.select(), 10);
      }
    },
    [containerRef, getCurrentInputIndex]
  );

  const handleKeyPress = useCallback(
    ({ code, target }) => {
      if (target?.dataset?.cy !== 'code_digit') return;

      switch (code) {
        case 'Backspace':
          if (!target.value) focusPreviousInput(target);

          break;
        case 'ArrowLeft':
          focusPreviousInput(target);
          break;
        case 'ArrowRight':
          focusNextInput(target);
          break;
        default:
          break;
      }
    },
    [focusPreviousInput, focusNextInput]
  );

  useEffect(() => {
    if (containerRef) document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [containerRef, handleKeyPress]);
};

export default CodeDigitsInput;
