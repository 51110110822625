import TYPES from './types';

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_ORGANIZATION_INFO.SUCCESS:
      return {
        ...payload
      };

    default:
      return state;
  }
};

export default reducer;
