import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Information from 'common/components/general/Information';
import SvgRender from 'common/components/general/SvgRender';
import styled from '@emotion/styled';
import arrow from 'common/assets/svg/common/arrows/arrow-filters.svg';
import info from 'common/assets/svg/common/info.svg';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsPriceZoneDiscountExpanded } from 'store/purchasing/pricelist/selectors';
import { togglePriceZoneDiscount } from 'store/purchasing/pricelist/slice';
import { getPurchasingPricelistPriceZone } from 'store/purchasing/pricelist/actions';
import Spinner from 'common/components/general/Spinner';
import { PriceZone } from 'common/entities/pricelist/PricelistTypes';

const PriceZoneInfo = ({ isHovering, hasData, onFetch }) => {
  useEffect(() => {
    if (isHovering && !hasData) {
      onFetch();
    }
  }, [hasData, isHovering, onFetch]);

  return (
    <SvgRender
      src={info}
      style={{ width: 10, height: 10 }}
      className={`text-white pointer-events-none`}
    />
  );
};

const PriceZoneTooltip = ({ data }) => {
  return (
    <div className="fs-12 fw-bold text-start">
      <div className="cmb-12">{data?.name}</div>
      <div className="mb-1 pb-1 border-bottom border-light-gray">
        Countries:{' '}
        <span className="fw-normal">
          {data?.countries?.length ? data?.countries.map(({ name }) => name).join(', ') : '-'}
        </span>
      </div>
      <div>
        Ports:{' '}
        <span className="fw-normal">
          {data?.ports?.length ? data?.ports.map(({ name }) => name).join(', ') : '-'}
        </span>
      </div>
    </div>
  );
};

const PriceZoneGroupHeader = ({ priceZone }) => {
  const isExpanded = useSelector(state => selectIsPriceZoneDiscountExpanded(state, priceZone?.id));
  const dispatch = useDispatch();

  const [priceZoneInfo, setPriceZone] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPriceZoneData = useCallback(async () => {
    try {
      setIsLoading(true);

      const res = await dispatch(getPurchasingPricelistPriceZone({ id: priceZone?.id })).unwrap();

      setPriceZone(res);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setPriceZone(null);
      setIsLoading(false);
    }
  }, [dispatch, priceZone?.id]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Information
        tooltipClassname="max-width-580"
        key={isLoading}
        message={
          isLoading || !priceZoneInfo ? (
            <Spinner size={16} />
          ) : (
            <PriceZoneTooltip data={priceZoneInfo} />
          )
        }
        renderComponent={isHovering => (
          <PriceZoneInfo
            isHovering={isHovering}
            hasData={!!priceZoneInfo}
            onFetch={fetchPriceZoneData}
          />
        )}
      />
      <div className="overflow-hidden fw-bold text-truncate ms-1">{priceZone?.name}</div>

      <ExpandButtonContainer>
        <Button
          color="link"
          className={`p-0 d-flex align-items-center justify-content-center rounded-xl fs-12 fw-medium text-violet border border-violet`}
          onClick={() => dispatch(togglePriceZoneDiscount({ priceZoneId: priceZone?.id }))}
        >
          <SvgRender
            src={arrow}
            style={{
              width: 8,
              height: 8,
              transform: isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'
            }}
          />
        </Button>
      </ExpandButtonContainer>
    </div>
  );
};

const ExpandButtonContainer = styled.span`
  position: absolute;
  right: ${variables.size8};
  top: ${variables.size24};

  > .btn {
    width: ${variables.size14};
    height: ${variables.size14};
  }
`;

PriceZoneInfo.propTypes = {
  hasData: PropTypes.bool,
  isHovering: PropTypes.bool,
  onFetch: PropTypes.func
};

PriceZoneTooltip.propTypes = {
  data: PriceZone.isRequired
};

PriceZoneGroupHeader.propTypes = {
  priceZone: PriceZone.isRequired
};

export default PriceZoneGroupHeader;
