import TYPES from './types';

import _get from 'lodash/get';
import { normalizeShifts } from 'crew/utils/working-schedule/helpers';

const INITIAL_STATE = {
  activeWorkingSchedule: null,
  isLoading: false
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_LATEST_PUBLISHED_WORKING_SCHEDULE.START:
      return {
        ...state,
        activeWorkingSchedule: null,
        isLoading: true
      };

    case TYPES.GET_LATEST_PUBLISHED_WORKING_SCHEDULE.ERROR:
      return {
        ...state,
        activeWorkingSchedule: null,
        isLoading: false
      };

    case TYPES.GET_LATEST_PUBLISHED_WORKING_SCHEDULE.SUCCESS:
      return {
        ...state,
        activeWorkingSchedule: {
          ...payload,
          crew_members: (_get(payload, 'crew_members') || []).map(crewMember => {
            const shifts = normalizeShifts(_get(crewMember, 'shifts') || []);

            return {
              ...crewMember,
              shifts
            };
          })
        },
        isLoading: false
      };

    default:
      return state;
  }
};

export default reducer;
