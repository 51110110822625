import React from 'react';
import Select from 'common/components/form/inputs/Select';

const ToolsSelect = ({
  isMulti,
  label,
  value,
  options,
  className,
  onChange,
  placeholder = 'Select',
  loadOptions,
  isAsync,
  defaultOptions,
  ...rest
}) => {
  return (
    <div className="tools-select">
      <div className="fs-10 mb-1">{label}</div>
      <Select
        className={`${className ? className : ''}`}
        placeholder={placeholder}
        autoFocus
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        value={value || ''}
        options={options || []}
        isMulti={isMulti}
        isClearable
        isAsync={isAsync}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        menuPortalTarget={isMulti ? document.body : null}
        // menuIsOpen
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};

export default ToolsSelect;
