import React, { useState, useEffect } from 'react';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import CurrencyPreview from './CurrencyPreview';

const SortableItem = SortableElement(({ cur, ...rest }) => <CurrencyPreview {...rest} />);

const SortableList = SortableContainer(
  ({
    items,
    settings,
    rates,
    baseCurrencyAmount,
    systemCurrency,
    setBaseCurrencyAmount,
    disabled
  }) => (
    <div>
      {items.map((cur, i) => {
        const currentCurrency = rates.find(r => r.currency_label === cur.label) || {};

        if (!currentCurrency?.currency_label) return null;

        const rest = {
          key: `item-${cur.label}`,
          settings,
          currentCurrency: { ...currentCurrency, ...(cur || {}) },
          baseCurrencyAmount,
          systemCurrency,
          setBaseCurrencyAmount
        };

        return <SortableItem index={i} disabled={disabled} {...rest} />;
      })}
    </div>
  )
);

const View = ({ selectedCurrencies, settings, rates, update, isSaving, systemCurrency }) => {
  const [items, setItems] = useState([]);
  const [baseCurrencyAmount, setBaseCurrencyAmount] = useState(null);

  useEffect(() => {
    setItems(selectedCurrencies.filter(o => o.label !== systemCurrency?.label));
  }, [selectedCurrencies]);

  return (
    <div className={`forex-rates-view pt-2${isSaving ? ' is-updating' : ''}`}>
      <CurrencyPreview
        systemCurrency={systemCurrency}
        settings={settings}
        currentCurrency={{ ...systemCurrency, currency_label: systemCurrency?.label, rate: 1 }}
        baseCurrencyAmount={baseCurrencyAmount}
        setBaseCurrencyAmount={setBaseCurrencyAmount}
      />

      {rates.length ? (
        <SortableList
          helperClass="tools-rearrange-sortable"
          useDragHandle={true}
          lockAxis="y"
          items={items}
          settings={settings}
          rates={rates}
          baseCurrencyAmount={baseCurrencyAmount}
          setBaseCurrencyAmount={setBaseCurrencyAmount}
          systemCurrency={systemCurrency}
          disabled={isSaving}
          onSortEnd={({ oldIndex, newIndex }) => {
            const updated = arrayMove(items, oldIndex, newIndex);

            setItems(updated);
            update(updated);
          }}
        />
      ) : null}
    </div>
  );
};

export default View;
