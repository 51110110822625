import { strToNumber } from 'common/utils/numbers';
import {
  hasValue,
  getValue,
  getLastUpdatedAutoValue,
  getCptValue,
  getSum
} from 'views/the-fleet/components/position-list/card/components/body/info/detailed-info/fields-list/helpers';
import { portFields, consumptions } from './fields';
import numeral from 'numeral';

import _get from 'lodash/get';

export const validateSpeedOG = (fieldValue, values, comparisonKey) => {
  if (comparisonKey) {
    const comparedWith = strToNumber(_get(values, comparisonKey));
    const value = strToNumber(fieldValue);

    if ((comparedWith || comparedWith === 0) && (value || value === 0)) {
      return `text-${value >= comparedWith ? 'green' : 'red'}`;
    }
  }
};

export const validateConsumptions = (fieldValue, values, comparisonKey) => {
  if (comparisonKey) {
    const comparedWith = strToNumber(_get(values, comparisonKey));
    const value = strToNumber(fieldValue);

    if ((comparedWith || comparedWith === 0) && (value || value === 0)) {
      return `text-${strToNumber(value) > strToNumber(comparedWith) ? 'red' : 'green'}`;
    }
  }
};

export const validateAllFuelConsumptions = (
  valueKey,
  { allValues, configName },
  comparisonKeys,
  parentKey
) => {
  const isLive = configName.includes('live');

  if (comparisonKeys) {
    const foComparisonValue = _get(allValues, comparisonKeys.fo);
    const goComparisonValue = _get(allValues, comparisonKeys.go);

    if (
      (!foComparisonValue && foComparisonValue !== 0) ||
      (!goComparisonValue && goComparisonValue !== 0)
    )
      return;

    const val = getSum(_get(allValues, `${parentKey}.consumptions`), valueKey, isLive);
    const comparedWith = strToNumber(foComparisonValue) + strToNumber(goComparisonValue);

    if ((comparedWith || comparedWith === 0) && (val || val === 0)) {
      return `text-${strToNumber(val) > strToNumber(comparedWith) ? 'red' : 'green'}`;
    }
  }
};

export const validateAtPortPortTotalsConsumptions = (valueKey, { allValues }, parentKey) => {
  const val = getSum(_get(allValues, `${parentKey}.consumptions`), valueKey);

  if (val || val === 0) {
    let comparedType = null;

    const cranes = portFields.cranes('live_actions').transformation(allValues['live_actions']);
    if (cranes === 'Port') {
      comparedType = 'idle';
    } else {
      comparedType = 'work';
    }

    const fo = _get(allValues, `vessel.consumptions.${comparedType}.fo_consumption`);
    const go = _get(allValues, `vessel.consumptions.${comparedType}.go_consumption`);

    if ((!fo && fo !== 0) || (!go && go !== 0)) return;

    const comparedWith = strToNumber(fo) + strToNumber(go);

    return `text-${val > strToNumber(comparedWith) ? 'red' : 'green'}`;
  }
};

export const validateAtPortConsumptions = (value, _, { allValues, rowKey, configName }) => {
  const val = strToNumber(getValue(value));
  const isLive = configName.includes('live');

  if (val || val === 0) {
    let comparedType = null;

    const cranes = portFields.cranes('live_actions').transformation(allValues['live_actions']);
    if (cranes === 'Port') {
      comparedType = 'idle';
    } else {
      comparedType = 'work';
    }

    const instructedCons =
      strToNumber(_get(allValues, `vessel.consumptions.${comparedType}.fo_consumption`)) +
      strToNumber(_get(allValues, `vessel.consumptions.${comparedType}.go_consumption`));

    const consumptionsConfig = consumptions.simpleAtPort('previous_report');
    const consumptionsValues = _get(allValues, consumptionsConfig?.key);

    const consumptionRowsSum = consumptionsValues.reduce((previousValue, currentValue) => {
      const fieldValue = isLive
        ? getLastUpdatedAutoValue(currentValue[rowKey])
        : getCptValue(currentValue[rowKey]);

      const value = numeral(previousValue).add(strToNumber(fieldValue) || 0)._value;

      return value;
    }, 0);

    if (hasValue(consumptionRowsSum) && hasValue(instructedCons)) {
      return `text-${consumptionRowsSum > instructedCons ? 'red' : 'green'}`;
    }
  }
};

export const validateOceanCurrentSpeed = (value, oceanCurrentRelativeDirection) => {
  if (hasValue(value) && hasValue(oceanCurrentRelativeDirection)) {
    const oceanCurrentSpeedValue = strToNumber(getValue(value));
    const oceanCurrentRelativeDirectionValue = strToNumber(getValue(oceanCurrentRelativeDirection));

    return `text-${
      Math.abs(oceanCurrentSpeedValue) <= 0.1 ||
      oceanCurrentRelativeDirectionValue <= 90 ||
      oceanCurrentRelativeDirectionValue >= 270
        ? 'green'
        : 'red'
    } `;
  }
};

export const validateWindSpeed = (value, goodWeatherClauses) => {
  if (hasValue(value)) {
    const wind =
      goodWeatherClauses?.wind_speed || goodWeatherClauses?.wind_speed === 0
        ? strToNumber(goodWeatherClauses?.wind_speed)
        : null;
    const val = strToNumber(getValue(value));

    return `text-${wind === null ? 'primary' : val > wind ? 'red' : 'green'}`;
  }
};

export const validateSignificantWaveHeight = (value, goodWeatherClauses) => {
  if (hasValue(value)) {
    const significantWaveHeight =
      goodWeatherClauses?.significant_wave_height ||
      goodWeatherClauses?.significant_wave_height === 0
        ? strToNumber(goodWeatherClauses?.significant_wave_height)
        : null;
    const val = strToNumber(getValue(value));

    return `text-${
      significantWaveHeight === null ? 'primary' : val > significantWaveHeight ? 'red' : 'green'
    }`;
  }
};
