import jwt_decode from 'jwt-decode';

export const loadState = () => {
  let state = {
    auth: {
      isAuthenticated: false
    }
  };

  try {
    if (window.localStorage.getItem('accessToken')) {
      state.auth.isAuthenticated = true;
      window.localStorage.setItem(
        'tokenExpiresAt',
        jwt_decode(window.localStorage.getItem('accessToken')).exp
      );
    } else {
      state.account = {};
    }

    return state;
  } catch (err) {
    return state;
  }
};

export const saveState = state => {
  try {
  } catch (err) {
    // Handle errors
  }
};

// Helpers for createSelector

// Takes an array of object (items) returns the object with id itemId
export const findById = itemId => items => items.find(({ id }) => id === itemId);

// Takes an array of objects (items) with a property of type array (propName)
// returns an array combining all the items
// ex. [{ cargos: [c1,c2]}, {cargos: [c3]}, {cargos: [c4,c5]}] => [c1,c2,c3,c4,c5] ,
export const reduceListProperty = propName => items =>
  items.reduce(
    (acc, item) =>
      item[propName] && item[propName].length > 0 ? [...acc, ...item[propName]] : acc,
    []
  );

export { default as asyncTypes } from 'common/utils/asyncTypes';
