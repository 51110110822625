import { useSelector } from 'react-redux';
import { selectRootMenus } from 'common/components/beacon/store/selectors';
import Header from './Header';
import Menu from './menu';
import Footer from './Footer';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const MenusTree = ({
  showHeader = true,
  showFooter = true,
  showMenuActions = true,
  maxHeight = '77vh'
}) => {
  const rootMenus = useSelector(selectRootMenus);

  return (
    <div className="chapters-tree">
      {showHeader && <Header />}

      <div>
        <ScrollableContainer className="gray-scrollbar cpe-4" maxHeight={maxHeight}>
          {rootMenus.map(id => (
            <Menu key={id} id={id} showMenuActions={showMenuActions} />
          ))}
        </ScrollableContainer>
        {showFooter && <Footer />}
      </div>
    </div>
  );
};

MenusTree.propTypes = {
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  showMenuActions: PropTypes.bool,
  maxHeight: PropTypes.string
};

const ScrollableContainer = styled.div`
  min-height: ${({ maxHeight }) => maxHeight};
  overflow-y: auto;
`;

export default MenusTree;
