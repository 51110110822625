import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import communication from 'common/assets/svg/common/communication.svg';
import ToggleButton from 'common/components/general/ToggleButton';
import SvgRender from 'common/components/general/SvgRender';
import * as purchasingActions from 'common/components/purchasing/requisition/store/actions';
import { isPurchasingCommunicationDrawerOpen } from 'common/components/purchasing/requisition/store/selectors';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';

const ToggleButtonWrap = () => {
  const isOpen = useSelector(isPurchasingCommunicationDrawerOpen);

  const [togglePurchasingCommunicationsDrawer] = useActions([
    purchasingActions.togglePurchasingCommunicationsDrawer
  ]);

  const { tooltipID, avoidRender } = useTooltipID('purchasing-communication-button');

  if (avoidRender) return null;

  return (
    <ToggleButton
      className="position-relative"
      onClick={() => togglePurchasingCommunicationsDrawer(!isOpen)}
      size="sm"
      id={tooltipID}
    >
      <SvgRender src={communication} style={{ width: 16, height: 16 }} />
      {isOpen ? null : <Tooltip target={tooltipID}>Case Communication</Tooltip>}
    </ToggleButton>
  );
};

export default ToggleButtonWrap;
