import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_MGA_ACCOUNTS'),
  ...asyncTypes('CREATE_MGA_ACCOUNT'),
  ...asyncTypes('EDIT_MGA_ACCOUNT'),
  ...asyncTypes('DELETE_MGA_ACCOUNT'),
  ...asyncTypes('GET_MGA_PARENT_ACCOUNT'),
  ...asyncTypes('EDIT_MGA_PARENT_ACCOUNT'),
  ...asyncTypes('GET_MGA_MASTER_ACCOUNT'),
  ...asyncTypes('EDIT_MGA_MASTER_ACCOUNT'),
  SET_MGA_ACCOUNT_FORM: 'SET_MGA_ACCOUNT_FORM',
  SET_MGA_ACCOUNT_PREVENTION_MODAL: 'SET_MGA_ACCOUNT_PREVENTION_MODAL'
};

export default TYPES;
