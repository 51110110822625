import { css } from '@emotion/react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Select from 'common/components/form/inputs/Select';
import { Suppliers } from 'common/entities/purchasing/PurchasingRequisitionTypes';

import { components } from 'react-select';
import Modal from './Modal';

const EmailField = ({ options, value, type, onChange, ...rest }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const loadOptions = search => {
    return new Promise(resolve => {
      setTimeout(() => {
        const filteredOptions = (options || []).filter(
          e => e?.supplier_email?.includes(search) || e?.supplier_full_name?.includes(search)
        );
        resolve(filteredOptions);
      }, 500);
    });
  };

  const toggleModal = () => {
    setIsModalOpen(e => !e);
  };

  return (
    <>
      <Select
        css={type === 'to' ? toStyle : type === 'bcc' ? bccStyle : defaultStyle}
        placeholder={''}
        className="mb-0"
        components={{ MultiValueLabel }}
        isCreatable
        onCreateOption={toggleModal}
        createOptionText="Enter a different email address"
        isClearable
        isAsync
        defaultOptions={options}
        loadOptions={search => loadOptions(search)}
        isMulti
        getOptionValue={option => option.id}
        getOptionLabel={option => option.supplier_full_name || option.supplier_email}
        value={value}
        onChange={onChange}
        {...rest}
      />

      <Modal
        value={value}
        onChange={onChange}
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
      />
    </>
  );
};

const toStyle = css`
  .react-select__control {
    padding-right: 2.688rem !important;
    padding-left: 1.563rem !important;
  }
`;

const defaultStyle = css`
  .react-select__control {
    padding-left: 1.563rem !important;
  }
`;

const bccStyle = css`
  .react-select__control {
    padding-left: 2rem !important;
  }
`;

const MultiValueLabel = ({ children, ...props }) => {
  return (
    <components.MultiValueLabel {...props}>
      {props?.data?.supplier_email}
    </components.MultiValueLabel>
  );
};

export default EmailField;

EmailField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['bcc', 'cc', 'to']),
  options: PropTypes.arrayOf(Suppliers)
};
