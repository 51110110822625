import { FC } from 'react';
import { PurchasingRequisitionStatusBase, Supplier } from '@/common/types/purchasing.ts';
import { checkIfActionIsVisible } from '@/common/components/purchasing/requisition/suppliers/supplier/layout/right/helpers.ts';
import { useAppSelector } from '@/store/hooks';
import { hasValidItemBaseQuantities } from '@/common/components/purchasing/requisition/store/selectors';
import { supplierHasValidApprovedQuantities } from '@/common/components/purchasing/requisition/store/selectors-ts';
import ExportExcel from 'common/components/general/ExportExcel';
import ExportPdf from 'common/components/general/ExportPdf';

type EmailSupplierExportsProps = {
  supplier: Supplier;
  emailStatuses: PurchasingRequisitionStatusBase[];
  isQtn: boolean;
  isPo: boolean;
  isDownloading: boolean;
  onExcelDownload: (id: number) => void;
  onPdfDownload: (id: number) => void;
};

const EmailSupplierExports: FC<EmailSupplierExportsProps> = ({
  supplier,
  emailStatuses,
  isQtn,
  isPo,
  isDownloading,
  onExcelDownload,
  onPdfDownload
}) => {
  const hasValidRevisedQuantity = useAppSelector(state =>
    hasValidItemBaseQuantities(state, 'revised_quantity')
  );

  const hasValidApprovedQuantity = useAppSelector(state =>
    supplierHasValidApprovedQuantities(state, supplier.id)
  );

  return (
    <>
      {checkIfActionIsVisible(supplier.status, emailStatuses, 'qtn') &&
      isQtn &&
      hasValidRevisedQuantity ? (
        <ExportExcel
          className="me-1"
          showLabel={false}
          onClick={() => onExcelDownload(supplier.id)}
          svgStyle={{ width: 13, height: 13 }}
          disabled={isDownloading}
        />
      ) : checkIfActionIsVisible(supplier.status, emailStatuses, 'po') &&
        isPo &&
        hasValidApprovedQuantity ? (
        <ExportPdf
          className="h-24 me-1"
          showLabel={false}
          onClick={() => onPdfDownload(supplier.id)}
          type="pdfSquare"
          svgStyle={{ width: 13, height: 13 }}
          disabled={isDownloading}
        />
      ) : null}
    </>
  );
};

export default EmailSupplierExports;
