import TYPES from './types';
import MAP_TYPES from 'store/the-fleet/map/types';
import POSITION_LIST_TYPES from 'store/the-fleet/position-list/types';
import { dateToLocal } from 'common/utils/dates';

// import _isArray from 'lodash/isArray';
import _uniq from 'lodash/uniq';
import _cloneDeep from 'lodash/cloneDeep';

import { getItineraryPortActions } from 'common/utils/voyages/helpers';
import { getItineraryPortPair } from './helpers';

const VESSEL_ITINERARY_INITIAL_STATE = {
  itineraries: [], // [ITINERARY_ID_1, ITINERARY_ID_2]
  leftItineraryId: null,
  rightItineraryId: null,
  requestedBeforeItineraryId: null,
  requestedAfterItineraryId: null
};

const INITIAL_STATE = {
  // per vessel state - // { VESSEL_ID_1: ..., }
  vesselInfo: {},
  voyageInfo: {},
  itineraries: {},
  charterParty: {}, // selected charter party
  activeSubCharterer: {}, // active SubCharterer of the selected Charter party
  weather: {},
  liveWeather: {},
  integrationWeather: {},
  integrationReports: {},
  liveActions: {},
  previousInstructed: {},
  previousReport: {},
  legTotals: {},
  consumptions: {},
  crew: {},
  superintendents: {},
  parties: {}, // {VESSEL_ID: {vesselParties: [...], isLoading: false} }}
  // common state
  _itinerary: {}, // { ITINERARY_ID_1: ..., }
  _itineraryActions: {}, // { ITINERARY_ID_1: ..., }
  _itineraryPurchaseOrders: {}, // { ITINERARY_ID_1: ..., }
  _itineraryEvents: {}, // { ITINERARY_ID_1: ..., }
  _visibleVessels: []
};

const createLoadingState = (state, vesselId, isLoading = true) => {
  const loadingVesselIds = state._loadingVesselIds || [];

  return {
    ...state,
    _loadingVesselIds: isLoading
      ? _uniq([...loadingVesselIds, vesselId])
      : loadingVesselIds.filter(v => v !== vesselId)
  };
};

const createVesselState = (state, vesselId, data) => {
  const loadingVesselIds = state._loadingVesselIds || [];

  return {
    ...state,
    _loadingVesselIds: loadingVesselIds.includes(vesselId)
      ? loadingVesselIds.filter(v => v !== vesselId)
      : [],
    [vesselId]: data
    // [vesselId]: _isArray(data)
    //   ? [...(state?.[vesselId] || []), ...data]
    //   : data
    //   ? { ...(state?.[vesselId] || {}), ...data }
    //   : null
  };
};

const constructIntergrationReports = (state, id, data) => {
  const vesselId = id;
  const weatherData = data?.weather_data || {};
  const weatherKeys = Object.keys(weatherData);
  const prevIntegrationReportsData = state.integrationReports?.[vesselId] || {};

  const constructedIntegrationReportsData = weatherKeys.reduce((fieldAcc, fieldKey) => {
    const previousField = state.integrationReports?.[vesselId]?.[fieldKey] || {};
    const lastUpdated = weatherData[fieldKey]?.last_updated || {};
    const lastUpdatedAt = lastUpdated?.last_updated_at;

    const field = {
      ...previousField,
      ...weatherData[fieldKey],
      last_updated: {
        ...lastUpdated,
        last_updated_at: lastUpdatedAt ? dateToLocal(lastUpdatedAt) : null
      }
    };

    return {
      ...fieldAcc,
      [fieldKey]: field
    };
  }, {});

  const combinedIntegrationReports = {
    ...prevIntegrationReportsData,
    ...constructedIntegrationReportsData
  };

  return combinedIntegrationReports;
};

const createLiveWeatherState = (state, matchImosWithWeatherData, vesselIds) => {
  return matchImosWithWeatherData.reduce((acc, curr) => {
    const vesselId = curr.id;

    const prevLiveWeatherVesselIds = state.liveWeather?._loadingVesselIds || [];

    const constructedIntergrationReports = constructIntergrationReports(
      state,
      vesselId,
      curr?.liveWeather
    );

    if (curr?.liveWeather?.location_data && curr?.liveWeather?.source)
      curr.liveWeather.location_data = {
        ...curr?.liveWeather?.location_data,
        source: curr?.liveWeather?.source
      };

    return {
      ...acc,
      integrationReports: {
        ...state.integrationReports,
        ...acc.integrationReports,
        [vesselId]: constructedIntergrationReports
      },
      liveWeather: {
        ...state.liveWeather,
        ...acc.liveWeather,
        [vesselId]: curr?.liveWeather || {},
        _loadingVesselIds: prevLiveWeatherVesselIds.filter(id => !vesselIds.includes(id))
      }
    };
  }, {});
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.GET_VESSEL_ITINERARIES.START:
      if (payload && payload.params.updateStore !== false) {
        const vesselId = payload.vesselId;
        const { _loadingVesselIds } = createLoadingState(state.itineraries, vesselId);
        const previousVesselState = state.itineraries[vesselId];
        const newVesselState = !previousVesselState
          ? { ..._cloneDeep(VESSEL_ITINERARY_INITIAL_STATE) }
          : { ...previousVesselState };

        newVesselState.requestedBeforeItineraryId = payload.params?.before_id;
        newVesselState.requestedAfterItineraryId = payload.params?.after_id;

        return {
          ...state,
          itineraries: {
            ...createVesselState(state.itineraries, vesselId, newVesselState),
            _loadingVesselIds
          }
        };
      }

      return state;

    case TYPES.GET_VESSEL_ITINERARIES.SUCCESS:
      if (payload) {
        const vesselId = payload.vesselId;

        const newItineraryState = { ...state._itinerary };
        const newItineraryActionsState = { ...state._itineraryActions };
        const previousItineraries = [...(state.itineraries?.[vesselId]?.itineraries || [])];

        const itinerariesData = payload?.data?.itinerary_ports;
        const newVesselState = {};
        const newItineraries = [];
        const newItineraryPurchaseOrdersState = { ...state._itineraryPurchaseOrders };
        const newItineraryEventsState = { ...state._itineraryEvents };

        itinerariesData.forEach(cur => {
          const {
            actions,
            port_statement,
            delivery_report,
            redelivery_report,
            id,
            purchasing_requisition_suppliers,
            events,
            ...rest
          } = cur;

          if (!previousItineraries.includes(id)) newItineraries.push(id);

          newItineraryState[id] = {
            ...rest,
            id,
            port_statement_id: port_statement?.id,
            port_statement,
            delivery_report,
            redelivery_report
          };

          if (actions?.length || port_statement)
            newItineraryActionsState[id] = getItineraryPortActions(actions, {
              ...rest,
              port_statement,
              delivery_report,
              redelivery_report
            });

          newItineraryPurchaseOrdersState[id] = purchasing_requisition_suppliers;
          newItineraryEventsState[id] = events;
        });

        if (payload.params.updateStore !== false) {
          const { leftId, rightId } = getItineraryPortPair(
            itinerariesData,
            payload.params.before_id,
            payload.params.after_id
          );

          newVesselState.leftItineraryId = leftId;
          newVesselState.rightItineraryId = rightId;

          if (payload.params.after_id) {
            newVesselState.itineraries = [...newItineraries, ...previousItineraries];
          } else {
            newVesselState.itineraries = [...previousItineraries, ...newItineraries];
          }

          newVesselState.firstItineraryId = payload.data?.first_id;
          newVesselState.lastItineraryId = payload.data?.last_id;

          return {
            ...state,
            _itinerary: newItineraryState,
            _itineraryActions: newItineraryActionsState,
            _itineraryPurchaseOrders: newItineraryPurchaseOrdersState,
            _itineraryEvents: newItineraryEventsState,
            itineraries: createVesselState(state.itineraries, vesselId, newVesselState)
          };
        } else {
          return {
            ...state,
            _itinerary: newItineraryState,
            _itineraryActions: newItineraryActionsState,
            _itineraryPurchaseOrders: newItineraryPurchaseOrdersState,
            _itineraryEvents: newItineraryEventsState
          };
        }
      }

      return state;

    case TYPES.GET_VESSEL_ITINERARIES.ERROR:
      return {
        ...state,
        itineraries: createLoadingState(state.itineraries, payload.vesselId, false)
      };

    case TYPES.SET_VESSEL_ITINERARIES_NAVIGATION:
      if (payload) {
        const vesselId = payload.vesselId;
        const previousVesselState = state.itineraries[vesselId];

        return {
          ...state,
          itineraries: createVesselState(state.itineraries, vesselId, {
            ...previousVesselState,
            leftItineraryId: payload.leftId,
            rightItineraryId: payload.rightId
          })
        };
      }

      return state;

    case TYPES.GET_VESSEL_VOYAGE_INFO.START:
    case TYPES.GET_VESSEL_VOYAGE_INFO.ERROR:
      return {
        ...state,
        voyageInfo: createLoadingState(
          state.voyageInfo,
          payload.vesselId,
          type === TYPES.GET_VESSEL_VOYAGE_INFO.START
        )
      };

    case TYPES.GET_VESSEL_VOYAGE_INFO.SUCCESS:
      const vesselId = payload.vesselId;
      const voyageInfoData = payload?.data;

      const {
        vessel,
        integration_weather,
        integration_reports,
        previous_instructed,
        previous_report,
        good_weather_by_captain_report,
        good_weather_by_captain_report_insufficiency_reason,
        good_weather_by_weather_models,
        good_weather_by_weather_models_insufficiency_reason,
        good_weather_clauses,
        good_weather_default_clauses,
        ...rest
      } = voyageInfoData;
      let liveActions = null;

      if (voyageInfoData?.last_real_port) {
        const { actions, port_statement, ...restPort } = voyageInfoData?.last_real_port;

        liveActions = getItineraryPortActions(actions, { ...restPort, port_statement });
      }

      return {
        ...state,
        voyageInfo: createVesselState(state.voyageInfo, vesselId, rest),
        vesselInfo: createVesselState(state.vesselInfo, vesselId, vessel),
        integrationWeather: createVesselState(
          state.integrationWeather,
          vesselId,
          integration_weather
        ),
        integrationReports: createVesselState(
          state.integrationReports,
          vesselId,
          integration_reports
        ),
        liveActions: createVesselState(state.liveActions, vesselId, liveActions),
        previousInstructed: createVesselState(
          state.previousInstructed,
          vesselId,
          previous_instructed
        ),
        previousReport: createVesselState(state.previousReport, vesselId, previous_report),
        weather: createVesselState(state.weather, vesselId, {
          good_weather_by_captain_report,
          good_weather_by_captain_report_insufficiency_reason,
          good_weather_by_weather_models,
          good_weather_by_weather_models_insufficiency_reason,
          good_weather_clauses,
          good_weather_default_clauses
        })
      };

    case TYPES.GET_VESSEL_VOYAGE_LEG_TOTALS.START:
    case TYPES.GET_VESSEL_VOYAGE_LEG_TOTALS.ERROR:
      return {
        ...state,
        legTotals: createLoadingState(
          state.legTotals,
          payload.params.id,
          type === TYPES.GET_VESSEL_VOYAGE_LEG_TOTALS.START
        )
      };

    case TYPES.GET_VESSEL_VOYAGE_LEG_TOTALS.SUCCESS:
      return {
        ...state,
        legTotals: createVesselState(state.legTotals, payload.id, {
          ...payload.data,
          filter_good_weather_days: payload.filter_good_weather_days
        })
      };

    case TYPES.GET_VESSEL_CHARTER_PARTY.START:
      if (payload) {
        const vesselId = payload.vesselId;
        const { _loadingVesselIds } = createLoadingState(state.charterParty, vesselId);
        const updatedState = {
          activeSubCharterer: createVesselState(state.activeSubCharterer, vesselId, null),
          charterParty: {
            ...createVesselState(state.charterParty, vesselId, payload.params.charterParty),
            _loadingVesselIds
          }
        };

        if (payload.params.charterParty?.charter_party_id !== state.charterParty?.[vesselId]?.id) {
          updatedState.itineraries = {
            ...createVesselState(
              state.itineraries,
              vesselId,
              _cloneDeep(VESSEL_ITINERARY_INITIAL_STATE)
            ),
            _loadingVesselIds
          };

          updatedState.voyageInfo = { ...state.voyageInfo, _loadingVesselIds };
        }

        return {
          ...state,
          ...updatedState
        };
      }

      return state;

    case TYPES.GET_VESSEL_CHARTER_PARTY.ERROR:
      return {
        ...state,
        activeSubCharterer: createVesselState(state.activeSubCharterer, payload.vesselId, null),
        charterParty: createLoadingState(state.charterParty, payload.vesselId, false)
      };

    case TYPES.GET_VESSEL_CHARTER_PARTY.SUCCESS:
      const charterPartyData =
        payload?.data?.charter_party !== undefined ? payload?.data?.charter_party : payload?.data;
      const activeSubCharterer = charterPartyData?.current_subcharterer;

      return {
        ...state,
        activeSubCharterer: createVesselState(
          state.activeSubCharterer,
          payload.vesselId,
          activeSubCharterer
        ),
        charterParty: createVesselState(state.charterParty, payload.vesselId, charterPartyData)
      };

    case TYPES.TOGGLE_GOOD_WEATHE_DAYS_PER_VOYAGE:
      return {
        ...state,
        legTotals: createVesselState(state.legTotals, payload.id, {
          ...payload.data,
          filter_good_weather_days: payload.filter
        })
      };

    case TYPES.GET_VESSEL_VOYAGE_CONSUMPTIONS.START:
    case TYPES.GET_VESSEL_VOYAGE_CONSUMPTIONS.ERROR:
      return {
        ...state,
        consumptions: createLoadingState(
          state.consumptions,
          payload.params.id,
          type === TYPES.GET_VESSEL_VOYAGE_CONSUMPTIONS.START
        )
      };

    case TYPES.GET_VESSEL_VOYAGE_CONSUMPTIONS.SUCCESS:
      return {
        ...state,
        consumptions: createVesselState(state.consumptions, payload.params.id, payload.data)
      };

    case TYPES.GET_VESSEL_CREW.START:
      return {
        ...state,
        crew: createLoadingState(state.crew, payload?.vesselId)
      };

    case TYPES.GET_VESSEL_CREW.ERROR:
      return {
        ...state,
        crew: createLoadingState(state.crew, payload?.vesselId, false)
      };

    case TYPES.GET_VESSEL_CREW.SUCCESS:
      return {
        ...state,
        crew: createVesselState(state.crew, payload?.vesselId, payload.data?.crew_members)
      };

    case TYPES.GET_VESSEL_PARTIES.START:
      return {
        ...state,
        parties: createLoadingState(state.parties, payload?.vesselId)
      };

    case TYPES.GET_VESSEL_PARTIES.ERROR:
      return {
        ...state,
        parties: createLoadingState(state.parties, payload?.vesselId, false)
      };

    case TYPES.GET_VESSEL_PARTIES.SUCCESS:
      return {
        ...state,
        parties: createVesselState(state.parties, payload?.vesselId, payload.data)
      };

    case TYPES.GET_VESSEL_SUPERINTENDENTS.START:
      return {
        ...state,
        superintendents: createLoadingState(state.superintendents, payload?.vesselId)
      };

    case TYPES.GET_VESSEL_SUPERINTENDENTS.ERROR:
      return {
        ...state,
        superintendents: createLoadingState(state.superintendents, payload?.vesselId, false)
      };

    case TYPES.GET_VESSEL_SUPERINTENDENTS.SUCCESS:
      return {
        ...state,
        superintendents: createVesselState(
          state.superintendents,
          payload?.vesselId,
          payload.data?.superintendents
        )
      };

    case TYPES.SET_VESSEL_VISIBILITY:
      return {
        ...state,
        _visibleVessels: [...payload.ids]
      };

    case POSITION_LIST_TYPES.GET_FLEET_VESSELS.START:
      return {
        ...state,
        _visibleVessels: []
      };

    case MAP_TYPES.GET_FLEET.SUCCESS:
    case POSITION_LIST_TYPES.GET_FLEET_VESSELS.SUCCESS:
      const vesselInfo = payload.reduce((acc, cur) => {
        acc = {
          ...acc,
          ...createVesselState(state.vesselInfo, cur?.id, cur)
        };

        return acc;
      }, state.vesselInfo);

      return {
        ...state,
        vesselInfo
      };

    case TYPES.GET_VESSEL_LIVE_WEATHER.START: {
      return {
        ...state,
        liveWeather: createLoadingState(state.liveWeather, payload.vesselId, true)
      };
    }

    case TYPES.GET_VESSEL_LIVE_WEATHER.ERROR:
      return {
        ...state,
        liveWeather: createLoadingState(state.liveWeather, payload.vesselId, false)
      };

    case TYPES.GET_VESSEL_LIVE_WEATHER.SUCCESS: {
      const data = payload?.data || [];
      const { vessel_ids } = payload?.params;

      const matchImosWithWeatherData = data?.flatMap(data => {
        const vesselKeys = Object.keys(state?.vesselInfo || {}) || [];

        return vesselKeys
          .map(key =>
            state?.vesselInfo[key]?.imo_no === data.imo
              ? { ...state.vesselInfo[key], liveWeather: data }
              : {}
          )
          ?.filter(e => e?.imo_no);
      });

      const liveWeatherState = createLiveWeatherState(state, matchImosWithWeatherData, vessel_ids);

      return {
        ...state,
        ...liveWeatherState
      };
    }

    case MAP_TYPES.SET_VESSEL:
      if (payload?.id)
        return {
          ...state,
          _visibleVessels: [payload?.id],
          activeSubCharterer: createVesselState(state.activeSubCharterer, payload.id, null),
          charterParty: createVesselState(state.charterParty, payload.id, null)
        };

      return {
        ...state,
        _visibleVessels: []
      };

    case MAP_TYPES.CLEAR_STATE:
      return {
        ...state,
        _visibleVessels: [],
        itineraries: {}
      };

    default:
      return state;
  }
};

export default reducer;
