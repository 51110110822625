import React from 'react';

import NoWidgets from 'views/dashboard/components/NoWidgets';
import WidgetPreventionModal from 'views/dashboard/components/WidgetPreventionModal';
import Form from './form';
import PreferencesForm from './prefences-form';
import WidgetsLayout from './WidgetsLayout';

import { useSelector } from 'react-redux';
import {
  selectActiveDashboard,
  selectActiveDashboardHasWidgets,
  selectActiveDashboardHasSidebar
} from 'store/dashboards/selectors';

const WidgetsContainer = () => {
  const activeDashboard = useSelector(selectActiveDashboard);
  const hasWidgets = useSelector(selectActiveDashboardHasWidgets);
  const hasSidebar = useSelector(selectActiveDashboardHasSidebar);

  if (!activeDashboard) return null;

  return (
    <>
      {hasWidgets ? (
        <div className="flex-fill pt-4 pb-5 dashbord-page__layout">
          <div className="dashbord-page__layout-main">
            <WidgetsLayout layout="card" />
            <WidgetsLayout layout="table" />
          </div>
          {hasSidebar ? (
            <div className="dashbord-page__layout-sidebar">
              <WidgetsLayout layout="sidebar" />
            </div>
          ) : null}
        </div>
      ) : (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <NoWidgets />
        </div>
      )}

      <Form />
      <PreferencesForm />
      <WidgetPreventionModal />
    </>
  );
};

export default WidgetsContainer;
