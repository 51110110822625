import TYPES from './types';
import { LOCATION_CHANGE } from 'connected-react-router';
import paths from 'routing/routes/_paths';
import {
  parseTemplateVisibleFields,
  filterTemplateValidatedFields
} from 'captain-reports/templates/config/_helpers';

const INITIAL_STATE = {
  templateId: null,
  templateName: null,
  templateType: null,
  templateVisibleFields: {},
  templateFieldValidations: {},
  fieldErrors: {},
  defaultTemplateFields: {},
  bunkeringFuelGrades: []
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.CHANGE_FIELD_VISIBILITY:
      return {
        ...state,
        templateVisibleFields: {
          ...state.templateVisibleFields,
          ...payload
        }
      };

    case TYPES.CHANGE_TEMPLATE_FIELD:
      return {
        ...state,
        [payload.templateField]: payload.value
      };

    case TYPES.GET_CAPTAIN_REPORT_TEMPLATE.SUCCESS:
      const { id, name, type: templateType, fields, fuel_grades, report_group } = payload;

      return {
        ...state,
        templateId: id,
        templateName: name,
        templateType: templateType,
        templateGroup: report_group,
        templateVisibleFields: parseTemplateVisibleFields(fields),
        templateFieldValidations: {
          ...filterTemplateValidatedFields(fields),
          ...filterTemplateValidatedFields(fields, true)
        },
        bunkeringFuelGrades: fuel_grades || []
      };

    case TYPES.GET_CAPTAIN_REPORT_TEMPLATE_DEFAULT_FIELDS.START:
      return {
        ...state,
        templateVisibleFields: {}
      };

    case TYPES.GET_CAPTAIN_REPORT_TEMPLATE_DEFAULT_FIELDS.SUCCESS:
      return {
        ...state,
        defaultTemplateFields: {
          ...state.defaultTemplateFields,
          [payload.params.type]: payload.data
        },
        templateVisibleFields: parseTemplateVisibleFields(payload.data)
      };

    case TYPES.CLEAR_CAPTAIN_REPORT_TEMPLATE_VALUES:
      return {
        ...state,
        templateVisibleFields: parseTemplateVisibleFields(
          state.defaultTemplateFields[payload.params.type]
        )
      };

    case LOCATION_CHANGE:
      if (payload.location.pathname === `${paths.captain_report_templates}/create`) {
        return {
          ...state,
          ...INITIAL_STATE
        };
      }
      return state;

    case TYPES.SET_BUNKERING_FUEL_GRADES:
      return { ...state, bunkeringFuelGrades: payload };

    case TYPES.UPDATE_CAPTAIN_REPORT_TEMPLATE_VALIDATION:
      return {
        ...state,
        templateFieldValidations: {
          ...state.templateFieldValidations,
          [payload.field_label]: payload
        }
      };

    case TYPES.DELETE_CAPTAIN_REPORT_TEMPLATE_VALIDATION:
      return {
        ...state,
        templateFieldValidations: {
          ...state.templateFieldValidations,
          [payload.field_label]: null
        }
      };

    default:
      return state;
  }
};

export default reducer;
