import { useState, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Row, Col } from 'reactstrap';
import Select from '@/ts-common/components/form/inputs/select';
import CircledButton from 'common/components/buttons/CircledButton';
import { selectEventId, selectIsTemplate } from 'events/store/events/selectors';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import { Option, SingleValue } from 'common/components/selectors/_partySelectComponents';
import CreatePersonDrawer from 'views/persons/drawer/create-person';
import { useDrawer } from 'common/components/drawer';
import {
  selectParticipantsData,
  selectParticipantsDataLength
} from 'events/store/event-modules/participants/selectors';
import {
  createParticipant,
  deleteParticipant,
  editParticipant,
  getParticipants
} from 'events/store/event-modules/participants/actions';
import { getEvent } from 'events/store/events/actions';
import {
  addOrEditParticipant,
  removeParticipant
} from '@/events/store/event-modules/participants/slice';

const ParticipantForm = ({ type, onClose, participant, index }) => {
  const [value, setValue] = useState(null);
  const [submiting, setSubmiting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const dispatch = useAppDispatch();
  const drawer = useDrawer(false);

  const isOnBoard = useAppSelector(state => state.isOnBoard);
  const eventId = useAppSelector(selectEventId);
  const participants = useAppSelector(selectParticipantsData);
  const participantsLength = useAppSelector(selectParticipantsDataLength);
  const isTemplate = useAppSelector(selectIsTemplate);

  const showTemplateView = isTemplate || !eventId;

  const participantsIds = useMemo(() => participants.map(el => el.party?.id), [participants]);

  const openModal = type => setShowModal(type);
  const closeModal = type => setShowModal(type);

  useEffect(() => {
    if (type === 'edit' && participant) {
      const { party } = participant;
      setValue(party);
    }
  }, [participant, type]);

  const onSubmit = async () => {
    setSubmiting(true);

    if (showTemplateView) {
      dispatch(
        addOrEditParticipant({
          index: index ?? participantsLength,
          party: value
        })
      );
      setSubmiting(false);
      onClose();
      return;
    }

    try {
      if (type === 'add') {
        await dispatch(createParticipant({ id: eventId, party_id: value.id })).unwrap();

        if (!participants?.length) dispatch(getEvent({ id: eventId })); // Refetch event to get the newly created ticketing case
      } else {
        await dispatch(
          editParticipant({
            id: eventId,
            participant_id: participant.id,
            party_id: value.id
          })
        ).unwrap();
        dispatch(getParticipants({ id: eventId })).unwrap();
      }
      setSubmiting(false);
      onClose();
    } catch (e) {
      setSubmiting(false);
    }
  };

  const onDelete = async () => {
    if (deleting) return;

    if (showTemplateView) {
      dispatch(removeParticipant(index));
      setDeleting(false);
      closeModal();
      onClose();
      return;
    }

    let params = {
      id: eventId,
      participant_id: participant.id
    };
    try {
      setDeleting(true);
      await dispatch(deleteParticipant(params)).unwrap();
      setDeleting(false);
      closeModal();
      onClose();

      dispatch(getEvent({ id: eventId })); // Refetch event to get the updated ticketing case
    } catch (e) {
      setDeleting(false);
      onClose();
    }
  };

  const modalBodyDescription = type => {
    return type === 'remove' ? (
      <div>
        Are you sure you want to remove {participant.party?.full_name || '-'}?
        <div className="mt-1">
          {participant?.trip_id
            ? '\n By confirming this action you delete the participant’s tickets too.'
            : null}
        </div>
      </div>
    ) : (
      'By confirming this action you delete the participant’s tickets.'
    );
  };

  return (
    <div className="event-participant-form mb-2 position-relative mt-1">
      <Row>
        <Col xs={6}>
          <Select
            invisible
            dataCy="participants-party-select"
            className={`w-100p`}
            placeholder="Select participant"
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            isAsync
            isCreatable={!isOnBoard}
            onCreateOption={() => drawer.open()}
            components={{ Option, SingleValue }}
            value={value}
            memoizedRequestParams={{
              path: '/lists',
              params: {
                list: 'parties',
                exclude_ids: participantsIds,
                relations: ['photo', 'departments'],
                types: ['person', 'crew']
              }
            }}
            onChange={opt => setValue(opt)}
          />
        </Col>
      </Row>
      <div className="form-buttons d-flex align-items-center">
        <CircledButton
          onClick={onClose}
          type={'close'}
          svgStyle={{ width: 12, height: 12 }}
          className="me-1"
        />
        {type !== 'add' ? (
          <CircledButton onClick={() => openModal('remove')} type={'remove'} className="me-1" />
        ) : null}
        <CircledButton
          disabled={submiting || !value}
          data-cy="participants-save-button"
          onClick={() =>
            participant?.trip_id && participant?.party_id !== value?.id
              ? openModal('save')
              : onSubmit()
          }
          type={'save'}
          svgStyle={{ width: 12, height: 12 }}
        />
      </div>
      {type !== 'add' ? (
        <DangerousActionModal
          transparent
          action={'Remove'}
          onAccept={showModal === 'save' ? onSubmit : onDelete}
          closeModal={() => closeModal()}
          isOpen={showModal === 'remove' || showModal === 'save'}
          actionText={'REMOVE'}
          header={showModal === 'save' ? 'Remove Tickets' : 'Remove'}
          body={modalBodyDescription(showModal)}
        />
      ) : null}

      <CreatePersonDrawer drawer={drawer} />
    </div>
  );
};

export default ParticipantForm;
