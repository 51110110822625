import { login, logout } from 'store/auth/actions';
import { createSlice } from '@reduxjs/toolkit';

// Initialized in utils/store/initial load
export const INITIAL_STATE = {
  token: null, // The two_factor_authentication_token (returned as 'token')
  two_factor_authentication_method: null,
  two_factor_authentication_setup_required: null
};

const slice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    authorizeLogin: (state, { payload }) => {
      state.token = null;
      state.two_factor_authentication_method = null;
      state.two_factor_authentication_setup_required = null;
      state.isAuthenticated = true;

      return state;
    },
    logoutCleanup: state => {
      state.token = null;
      state.two_factor_authentication_method = null;
      state.two_factor_authentication_setup_required = null;
      state.isAuthenticated = false;

      return state;
    }
  },
  extraReducers: {
    [login?.fulfilled]: (state, { payload }) => {
      if (!payload?.access_token) {
        return {
          ...state,
          ...payload
        };
      }

      return state;
    },
    [logout?.fulfilled]: state => {
      state.isAuthenticated = false;

      return state;
    }
  }
});

export const { authorizeLogin, logoutCleanup } = slice.actions;

export default slice.reducer;
