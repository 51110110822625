import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboards } from 'store/dashboards/actions';
import MenuItem from '../menu/MenuItem';
import add from 'assets/svg/actions/add.svg';
import { selectHasFetchedUserDashboards, selectUserDashboards } from 'store/dashboards/selectors';
import permissions from 'common/utils/permissions/constants';

const Dashboard = () => {
  const dispatch = useDispatch();
  const list = useSelector(selectUserDashboards);
  const listFetched = useSelector(selectHasFetchedUserDashboards);

  useEffect(() => {
    if (!listFetched) dispatch(fetchDashboards());
  }, [dispatch, listFetched]);

  return (
    <div className={`sidebar-menu d-flex flex-column h-100p`}>
      <div className="ps-2 text-uppercase">DASHBOARD</div>
      <div className={`sidebar-menu--inner flex-1 cmt-12`}>
        <div className="d-flex flex-column sidebar-menu--section sidebar-menu--top pt-1 gray-scrollbar overflow-y">
          <div className="d-flex flex-column">
            {[
              {
                icon: add,
                iconStyle: { width: 12, height: 12 },
                name: 'Create new Dashboard',
                label: 'create-new-dashboard',
                className: 'mb-1',
                permissions: [permissions.office.dashboard.view]
              },
              ...list
            ].map((item, index) => (
              <MenuItem
                key={index}
                item={{ ...item, permissions: [permissions.office.dashboard.view] }}
                isDashboard
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
