import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import {
  selectHasSelectedRequisitionSuppliers,
  selectActiveRequisitionSupplierIds,
  selectActiveRequisitionSupplierQuantityColumnLabel,
  selectActiveRequisitionSupplierTotalPriceColumnLabel,
  getSupplierPrice,
  selectActiveSupplierIndex
} from 'store/purchasing/requisition-summary/selectors';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { numberToStr } from 'common/utils/numbers';

const SupplierColumn = ({ children, isFirst, isLast, renderFooter, index }) => {
  const supplierColumnClassNames = `requisition-supplier-item supplier-index-${index + 1}`;

  return (
    <Col className={`${isFirst ? 'first' : isLast ? 'last' : ''}`} css={supplierColumnStyles}>
      <div className={`${supplierColumnClassNames} header-field pt-1 w-100p text-end cmb-4 cpe-12`}>
        {children}
      </div>
      <div
        className={`${supplierColumnClassNames} footer-field w-100p mt-auto text-white height-20 d-flex align-items-center justify-content-end fs-12 cpe-12`}
      >
        {renderFooter ? renderFooter() : <span />}
      </div>
    </Col>
  );
};

const SupplierColumns = ({ supplierId }) => {
  const quantityColumnLabel = useSelector(state =>
    selectActiveRequisitionSupplierQuantityColumnLabel(state, supplierId)
  );
  const totalPriceColumnLabel = useSelector(state =>
    selectActiveRequisitionSupplierTotalPriceColumnLabel(state, supplierId)
  );

  const baseCurrencyEquivalentPrice = useSelector(state =>
    getSupplierPrice(state, supplierId, true)
  );

  const supplierIndex = useSelector(state => selectActiveSupplierIndex(state, supplierId));

  return (
    <Col className="d-flex align-items-end cps-2 cpe-2">
      <SupplierColumn isFirst index={supplierIndex}>
        {quantityColumnLabel}
      </SupplierColumn>
      <SupplierColumn index={supplierIndex}>DISCOUNT (%)</SupplierColumn>
      <SupplierColumn
        renderFooter={() => (
          <span className="position-relative text-white">
            {numberToStr(baseCurrencyEquivalentPrice, 2, 2)}
          </span>
        )}
        index={supplierIndex}
        isLast
      >
        {totalPriceColumnLabel}

        <br />

        <span className="text-violet">USD</span>
      </SupplierColumn>
    </Col>
  );
};

SupplierColumns.propTypes = {
  supplierId: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
};

const Header = ({ isPms }) => {
  const hasSelectedRequisitionSuppliers = useSelector(selectHasSelectedRequisitionSuppliers);
  const activeRequisitionSupplierIds = useSelector(selectActiveRequisitionSupplierIds);

  const itemWitdth = activeRequisitionSupplierIds.length === 1 ? 8 : 5;

  return (
    <div className="text-primary fs-10 fw-bold mb-1">
      <Row noGutters>
        <Col xs={hasSelectedRequisitionSuppliers ? itemWitdth : 12} className="cme-4 ps-3">
          {isPms ? 'SPARE PARTS' : 'ITEMS'}
        </Col>
        {hasSelectedRequisitionSuppliers &&
          activeRequisitionSupplierIds.map(supplierId => (
            <SupplierColumns supplierId={supplierId} />
          ))}
      </Row>
    </div>
  );
};

const supplierColumnStyles = css`
  text-transform: uppercase;
  text-aligh: right;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 0;
  padding-right: 0;

  &.first {
    .header-field {
      &:before {
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
      }
    }

    .footer-field {
      &:before {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
  }

  &.last {
    .header-field {
      &:before {
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
      }
    }

    .footer-field {
      &:before {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
`;

Header.propTypes = {
  isPms: PropTypes.bool
};

export default Header;
