import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBudgetingReport } from '@/api/budgeting-reports/api.ts';
import TYPES from './types.ts';
import TABLE_TYPES from 'common/components/table/store/types';
import { BudgetingReportType } from '@/common/types/report-bulder.ts';

type getBudgetingReportActionParams = {
  id: string;
  from: string;
  to: string;
  budget_scenario_id?: number;
  company_ids?: number[];
};

export const getBudgetingReportAction = createAsyncThunk(
  TYPES.GET_BUDGETING_REPORT,
  async (
    params: getBudgetingReportActionParams,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    const { id, ...rest } = params;

    dispatch({
      type: TABLE_TYPES.GET_TABLE_LIST.START,
      payload: {
        params,
        table: 'reports_budgeting'
      }
    });

    try {
      const res = (await getBudgetingReport({ id, ...rest })) as BudgetingReportType;

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: {
          data: {
            data: res.data,
            columns: []
          },
          table: 'reports_budgeting'
        }
      });

      return fulfillWithValue(res);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
