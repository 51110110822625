import TYPES from './types';
import { get, put } from 'utils/api';

export const getToolsList = () => dispatch => {
  dispatch({ type: TYPES.GET_TOOLS_LIST.START });

  return get(`/lists?list=toolbar-tools`)
    .then(res => {
      dispatch({ type: TYPES.GET_TOOLS_LIST.SUCCESS, payload: res.data });

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_TOOLS_LIST.ERROR, payload: error }));
};

export const getUserTools = params => dispatch => {
  dispatch({ type: TYPES.GET_TOOLS.START, payload: { params } });

  return get(`/toolbar`, params)
    .then(res => {
      dispatch({ type: TYPES.GET_TOOLS.SUCCESS, payload: res.data });

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_TOOLS.ERROR, payload: error }));
};

export const updateUserTools = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_TOOLS.START, payload: { params } });

  return put(`/toolbar`, params)
    .then(res => {
      dispatch({ type: TYPES.UPDATE_TOOLS.SUCCESS, payload: res.data });

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.UPDATE_TOOLS.ERROR, payload: error }));
};
