export const getDefaultSorting = widget => {
  switch (widget.type.label) {
    case 'pms_jobs_card':
      return { due_date: 'asc' };
    case 'jobs':
    case 'jobs_card':
      return { due_date: 'asc' };

    case 'birthday':
      return { next_birthday: 'asc' };

    default:
      return {};
  }
};

export const getDefaultPageSize = widget => {
  switch (widget?.type?.layout) {
    case 'sidebar':
      return 4;
    case 'card':
      return 6;
    default:
      return 8;
  }
};

export const loadMore = label => {
  switch (label) {
    case 'persons_table':
      return true;
    default:
      return false;
  }
};
