import React from 'react';

const Card = ({ value, unit = 'm3', title = 'Draft', yellow }) => {
  return (
    <div className={`tool-card  fs-10 ${yellow ? 'tool-card--yellow' : ''}`}>
      <div className="fw-bold">
        {value ? <span className="fs-14">{value}</span> : '-'} {unit}
      </div>
      <div>{title}</div>
    </div>
  );
};

export default Card;
