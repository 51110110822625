import useTooltipID from 'common/utils/hooks/useTooltipID';
import Information from 'common/components/general/Information';

const WeatherClausesInfoTooltip = ({ goodWeatherClauses, voyage, svgClassname = '' }) => {
  const { avoidRender, tooltipID } = useTooltipID('weather-cp-terms');

  if (avoidRender || !goodWeatherClauses) return null;

  return (
    <Information
      svgClassname={svgClassname}
      tooltipClassname="max-width-none"
      target={`the-fleet-position__weather-heading-${tooltipID}`}
      message={
        <div className="text-start text-white">
          <div className="text-green">Good Weather Conditions</div>
          <div className="mb-1">
            <strong>
              {voyage?.good_weather_default_clauses === true
                ? 'Default Clauses'
                : 'From Charter Party'}
            </strong>
          </div>
          {goodWeatherClauses.min_coverage_for_good_weather && (
            <div>
              For at least: {goodWeatherClauses.min_coverage_for_good_weather}{' '}
              {goodWeatherClauses.min_coverage_for_good_weather_unit || 'hours'}
            </div>
          )}
          {goodWeatherClauses.wind_speed && <div>Wind ≤ {goodWeatherClauses.wind_speed} bft</div>}
          {goodWeatherClauses.significant_wave_height && (
            <div>Significant Wave Height ≤ {goodWeatherClauses.significant_wave_height} m</div>
          )}
          <div>Adverse Current: {goodWeatherClauses.adverse_current ? 'Yes' : 'No'}</div>
          {/* <div>
                  Favourable current counts against:{' '}
                  {goodWeatherClauses.favourable_current ? (
                    <span>
                      {goodWeatherClauses.favourable_current_max || '-'}
                      knots
                    </span>
                  ) : (
                    'No'
                  )}
                </div> */}
        </div>
      }
      svgStyle={{ width: 12, height: 12 }}
    />
  );
};

export default WeatherClausesInfoTooltip;
