import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_CREW_RANKS'),
  ...asyncTypes('UPDATE_CREW_RANK_VISIBILITY'),
  ...asyncTypes('REORDER_CREW_RANK'),
  ...asyncTypes('UPDATE_RANK_TOP_4'),
  GET_CREW_RANK: 'GET_CREW_RANK'
};

export default TYPES;
