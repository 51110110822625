import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_VESSEL_BUNKERS'),
  ...asyncTypes('CHANGE_BUNKERS_CALCULATE_METHOD'),
  ...asyncTypes('RESET_BUNKERS'),
  CHANGE_BUNKER_DRAWER_STATUS: 'CHANGE_BUNKER_DRAWER_STATUS',
  CHANGE_BUNKER_MODAL_STATUS: 'CHANGE_BUNKER_MODAL_STATUS'
};

export default TYPES;
