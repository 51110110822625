import TYPES from './types';

export const showModal = params => dispatch => {
  dispatch({ type: TYPES.SHOW_MODAL, payload: params });
};

export const hideModal = () => dispatch => {
  dispatch({ type: TYPES.HIDE_MODAL });
};

export const togleDisabled = () => dispatch => {
  dispatch({ type: TYPES.TOGLE_DISABLE });
};
