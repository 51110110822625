const TYPES = {
  CREATE_PURCHASING_PRICELIST: 'CREATE_PURCHASING_PRICELIST',
  EDIT_PURCHASING_PRICELIST: 'EDIT_PURCHASING_PRICELIST',
  GET_PURCHASING_PRICELIST: 'GET_PURCHASING_PRICELIST',
  DELETE_PURCHASING_PRICELIST: 'DELETE_PURCHASING_PRICELIST',
  UPDATE_PURCHASING_PRICELIST_GLOBAL_DISCOUNT: 'UPDATE_PURCHASING_PRICELIST_GLOBAL_DISCOUNT',
  GET_PURCHASING_PRICELIST_PRICE_ZONE: 'GET_PURCHASING_PRICELIST_PRICE_ZONE',
  CREATE_PURCHASING_PRICELIST_PRICE_ZONE: 'CREATE_PURCHASING_PRICELIST_PRICE_ZONE',
  EDIT_PURCHASING_PRICELIST_PRICE_ZONE: 'EDIT_PURCHASING_PRICELIST_PRICE_ZONE',
  DELETE_PURCHASING_PRICELIST_PRICE_ZONE: 'DELETE_PURCHASING_PRICELIST_PRICE_ZONE',
  ADD_PURCHASING_PRICELIST_ITEM: 'ADD_PURCHASING_PRICELIST_ITEM',
  UPDATE_PURCHASING_PRICELIST_ITEM: 'UPDATE_PURCHASING_PRICELIST_ITEM',
  DELETE_PURCHASING_PRICELIST_ITEM: 'DELETE_PURCHASING_PRICELIST_ITEM',
  APPLY_PURCHASING_PRICELIST_PRICE_ZONE_DISCOUNT: 'APPLY_PURCHASING_PRICELIST_PRICE_ZONE_DISCOUNT',
  GET_PURCHASING_PRICELIST_PRICE_ZONES: 'GET_PURCHASING_PRICELIST_PRICE_ZONES',
  GET_PURCHASING_PRICELIST_ITEMS: 'GET_PURCHASING_PRICELIST_ITEMS'
};

export default TYPES;
