import React from 'react';
import WidgetTitle from 'views/dashboard/components/WidgetTitle';

const FleetTce = ({ id }) => {
  return (
    <div>
      <WidgetTitle id={id} />
      <div className="bg-white p-2 rounded rounded-xl text-violet fs-12">
        Fleet TCE widget coming Soon
      </div>
    </div>
  );
};

export default FleetTce;
