import React from 'react';
import { Col, Row } from 'reactstrap';
import Preferences from './Preferences';
import _get from 'lodash/get';
import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import Current from './Current';
import Upcoming from './Upcoming';

const BirthdayWidget = ({ id }) => {
  return (
    <div>
      <WidgetTitle id={id} />
      <Row>
        <Col xs={6}>
          <Current id={id} />
        </Col>

        <Col xs={6} className="cpt-4 cpb-4">
          <Upcoming id={id} />
        </Col>
      </Row>
    </div>
  );
};
export const BirthdayPreferences = Preferences;

export default BirthdayWidget;
