import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectIsMenuExpanded, selectMenuSubMenus } from 'common/components/beacon/store/selectors';

import Menu from 'common/components/beacon/components/tree/menu';

const SubMenus = ({ id, showMenuActions }) => {
  const isOpen = useSelector(state => selectIsMenuExpanded(state, id));
  const subMenus = useSelector(state => selectMenuSubMenus(state, id));

  return subMenus?.length ? (
    <Collapse isOpen={isOpen}>
      <div className={`chapters-tree__view-submenu`}>
        {subMenus.map(sub => (
          <Menu key={sub.toString()} id={sub} showMenuActions={showMenuActions} />
        ))}
      </div>
    </Collapse>
  ) : null;
};

SubMenus.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showMenuActions: PropTypes.bool
};

export default SubMenus;
