import ShadowBox from 'common/components/general/ShadowBox';
import comments from 'common/assets/svg/common/comments.svg';
import attachmentsIcon from 'common/assets/svg/common/attachment-no-rotation.svg';
import Textarea from 'common/components/form/inputs/Textarea';
import UploadFiles from 'common/components/form/inputs/upload-files';
import SectionHeader from 'common/components/purchasing/requisition/remarks-attachments/SectionHeader';

import { useSelector } from 'react-redux';
import {
  selectRequisitionRemarks,
  selectRequisitionAttachments
} from 'store/purchasing/requisition-summary/selectors';
import { Row, Col } from 'reactstrap';

const Container = () => {
  const remarks = useSelector(selectRequisitionRemarks);
  const attachments = useSelector(selectRequisitionAttachments);

  if (!remarks && !attachments?.length) return null;

  return (
    <div className="pt-3 pb-3">
      <Row className="flex-nowrap" noGutters>
        {remarks ? (
          <Col>
            <ShadowBox className="cpt-12 pb-3 px-2 h-100p" color="light-1" flat>
              <div className="border-bottom pb-1 cmb-12 text-purple d-flex align-items-center fs-12 fw-medium">
                <SectionHeader header={{ icon: comments, label: 'Remarks' }} />
              </div>
              <Textarea value={remarks} name="remarks" rows={4} invisible autoResize disabled />
            </ShadowBox>
          </Col>
        ) : null}
        {attachments?.length ? (
          <Col className={remarks ? 'ms-1' : ''}>
            <ShadowBox className="cpt-12 pb-3 px-2 h-100p" color="light-1" flat>
              <div className="border-bottom pb-1 cmb-12 text-purple d-flex align-items-center fs-12 fw-medium">
                <SectionHeader header={{ icon: attachmentsIcon, label: 'Attachment(s)' }} />
              </div>
              <UploadFiles
                group="purchasing.attachments"
                files={attachments || []}
                size="lg"
                viewOnly
                className="mb-0"
              />
            </ShadowBox>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default Container;
