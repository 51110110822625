import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  selectMenu,
  selectMenuSubMenus,
  selectIsMenuExpanded,
  isMenusInSearch,
  selectActiveMenuId,
  selectActivePostId
} from 'common/components/beacon/store/selectors';

import TreeTitle from 'common/components/tree-layout/main/TreeTitle';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import styled from '@emotion/styled';

import { useActions } from 'utils/hooks';
import {
  expandeMenuAction,
  setActiveMenuIdAction,
  setActivePostIdAction,
  toggleMenuFormAction
} from 'common/components/beacon/store/slice';
import * as beaconActions from 'common/components/beacon/store/actions';

const Title = ({ id, showMenuActions = true }) => {
  const [expandMenu, setActiveMenuId, setActivePostId, toggleMenuForm, deleteBeaconMenu] =
    useActions([
      expandeMenuAction,
      setActiveMenuIdAction,
      setActivePostIdAction,
      toggleMenuFormAction,
      beaconActions.deleteBeaconMenu
    ]);
  const [preventionModalIsOpen, setPreventionModalIsOpen] = useState(false);

  const activeMenuId = useSelector(selectActiveMenuId);
  const activePostId = useSelector(selectActivePostId);

  const subMenus = useSelector(state => selectMenuSubMenus(state, id));
  const menu = useSelector(state => selectMenu(state, id));
  const isOpen = useSelector(state => selectIsMenuExpanded(state, id));
  const isVisible = useSelector(state => isMenusInSearch(state, id));

  const onExpand = () => expandMenu({ id, isOpen: !isOpen });

  const onDelete = async () => {
    try {
      await deleteBeaconMenu({ id }).unwrap();

      if (activeMenuId === id) {
        setActiveMenuIdAction(null);
        toggleMenuFormAction(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAction = useCallback(
    (type, id) => {
      if (type === 'edit') {
        setActiveMenuId(id);
        toggleMenuForm(true);
      } else {
        setPreventionModalIsOpen(true);
      }
    },
    [setActiveMenuId, toggleMenuForm]
  );

  const actions = useMemo(
    () => [
      { name: 'Edit', onClick: () => handleAction('edit', id) },
      { name: 'Delete', onClick: () => handleAction('delete', id) }
    ],
    [id, handleAction]
  );

  return isVisible ? (
    <Container className="chapters-tree__view">
      <TreeTitle
        isActive={(menu?.post_id && menu?.post_id === activePostId) || id === activeMenuId}
        hasChildren={!!subMenus}
        parent_id={menu.parent_id}
        title={menu.title}
        onExpand={onExpand}
        isOpen={isOpen}
        actions={showMenuActions ? actions : null}
        withMenu
        customIconComponent={<div className="me-2" />}
        onClick={
          menu?.post_id ? () => setActivePostId(menu?.post_id) : !!subMenus ? onExpand : null
        }
      />

      <DangerousActionModal
        transparent
        action={'Delete'}
        onAccept={onDelete}
        closeModal={setPreventionModalIsOpen}
        isOpen={preventionModalIsOpen}
        actionText={'DELETE'}
        header={'Delete'}
        body={
          <>
            Are you sure you want to delete the menu <strong>{menu?.title}</strong>? <br /> All of
            its content will be automatically removed.
          </>
        }
      />
    </Container>
  ) : null;
};

Title.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showMenuActions: PropTypes.bool
};

const Container = styled.div`
  .chapters-tree__view-title.active {
    background-color: white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
  }
`;

export default Title;
