import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import SvgRender from 'common/components/general/SvgRender';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import hideSvg from 'common/assets/svg/common/hide.svg';

import { selectShowRemarksAndAttachments } from 'common/components/purchasing/requisition/store/selectors';

import { toggleRemarksAndAttachments } from 'common/components/purchasing/requisition/store/actions';

const ShowRemarksAndAttachmentsButton = () => {
  const showRemarksAndAttachments = useSelector(selectShowRemarksAndAttachments);
  const dispatch = useDispatch();

  return (
    <Button
      color="white"
      className="d-flex align-items-center text-primary fs-12 fw-medium px-1 py-0 cms-4 mt-auto text-nowrap"
      onClick={() => dispatch(toggleRemarksAndAttachments(!showRemarksAndAttachments))}
    >
      <SvgRender
        className="me-1"
        src={showRemarksAndAttachments ? hideSvg : eyeSvg}
        style={{ width: 18, height: 18 }}
      />
      {showRemarksAndAttachments ? 'Hide' : 'Show'} remarks & attachments
    </Button>
  );
};

export default ShowRemarksAndAttachmentsButton;
