import TYPES from './types';

const INITIAL_STATE = {
  vessel: null,
  charterParty: null
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.SET_ACTIVE_VESSEL_VOYAGE:
      return {
        ...state,
        vessel: payload,
        charterParty: null
      };

    case TYPES.SET_ACTIVE_VESSEL_CHARTER_PARTY:
      return {
        ...state,
        charterParty: payload
      };

    default:
      return state;
  }
};

export default reducer;
