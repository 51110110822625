import React, { useState } from 'react';
import ButtonIcon from 'tools/components/general/ButtonIcon';
import Switch from 'tools/components/general/Switch';
import _get from 'lodash/get';
import SvgRender from 'common/components/general/SvgRender';

import arrow from 'tools/assets/svg/arrow_right.svg';
import { Row, Col } from 'reactstrap';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

const Edit = ({
  setIsSettingsOpen,
  vessels,
  userSettings,
  onSubmit,
  isSaving,
  consumptionTypes,
  vesselSituations
}) => {
  const [selectedVessels, setSelectedVessels] = useState(
    _get(userSettings, 'settings.vessel_ids', [])
  );
  const [selectedConsumptions, setSelectedConsumptions] = useState(
    _get(userSettings, 'settings.consumption_ids', [])
  );
  const [selectedSituations, setSelectedSituations] = useState(
    _get(userSettings, 'settings.situation_ids', [])
  );
  const [activeScreen, setActiveScreen] = useState(1);

  const onSwitchClick = id => {
    if (selectedVessels.includes(id)) {
      setSelectedVessels(selectedVessels.filter(el => el !== id));
    } else {
      setSelectedVessels([...selectedVessels, id]);
    }
  };

  const VesselRow = ({ vessel, isActive, isDisabled }) => {
    if (!vessel) return null;

    return (
      <div className={`vessel-consumptions-edit--row-handler ${isActive ? '' : 'inactive'}`}>
        <strong>{vessel.name}</strong>
        <Switch active={isActive} disabled={isDisabled} onClick={() => onSwitchClick(vessel.id)} />
      </div>
    );
  };

  const RenderRow = ({ data, isActive, isDisabled, type }) => {
    return (
      <Row className={`vessel-consumptions-edit--row-handler ${isActive ? '' : 'inactive'}`}>
        <Col xs={10}>
          <TextWithTooltip>
            <strong>{data.name}</strong>
          </TextWithTooltip>
        </Col>
        <Col xs={2}>
          <Switch
            active={isActive}
            disabled={isDisabled}
            onClick={() => handleClick(data.id, type)}
          />
        </Col>
      </Row>
    );
  };

  const handleClick = (id, type) => {
    if (type === 'situation') {
      if (selectedSituations.includes(id)) {
        setSelectedSituations(selectedSituations.filter(con => con !== id));
      } else {
        setSelectedSituations([...selectedSituations, id]);
      }
    } else {
      if (selectedConsumptions.includes(id)) {
        setSelectedConsumptions(selectedConsumptions.filter(con => con !== id));
      } else {
        setSelectedConsumptions([...selectedConsumptions, id]);
      }
    }
  };

  return (
    <div className="vessel-consumptions-edit ">
      <div className="d-flex justify-content-between mt-3 mb-1">
        <div className="vessel-consumptions-edit--label">
          {activeScreen}/2 {activeScreen === 1 ? 'Choose vessels' : 'Choose consumption speed'}
        </div>
        {activeScreen === 1 ? (
          <div
            className="vessel-consumptions-edit--label cursor-pointer d-flex align-items-center"
            onClick={() => setActiveScreen(2)}
          >
            <div>next</div>
            <SvgRender src={arrow} style={{ width: 16, height: 16 }} className="ms-1" />
          </div>
        ) : (
          <div
            className="vessel-consumptions-edit--label cursor-pointer d-flex align-items-center"
            onClick={() => setActiveScreen(1)}
          >
            <SvgRender
              src={arrow}
              style={{ width: 16, height: 16 }}
              className="me-1 rotate-arrow"
            />{' '}
            previous
          </div>
        )}
      </div>
      {activeScreen === 1 ? (
        <div className="vessel-consumptions-edit--rows">
          {vessels.map(vessel => (
            <VesselRow
              key={vessel.id}
              isActive={selectedVessels.includes(vessel.id)}
              vessel={vessel}
              isDisabled={isSaving}
            />
          ))}
        </div>
      ) : (
        <div className="vessel-consumptions-edit--rows">
          {consumptionTypes.map(consumption => (
            <RenderRow
              key={consumption.id}
              isActive={selectedConsumptions.includes(consumption.id)}
              data={consumption}
              isDisabled={isSaving}
            />
          ))}
          <div className="vessel-consumptions-edit--label mb-1 mt-1">Choose situation</div>
          {vesselSituations.map(situation => (
            <RenderRow
              key={situation.id}
              isActive={selectedSituations.includes(situation.id)}
              data={situation}
              isDisabled={isSaving}
              type="situation"
            />
          ))}
        </div>
      )}
      <div className="border-top-gray"></div>
      {activeScreen === 2 && (
        <div className="text-end">
          <ButtonIcon
            type="x"
            disabled={isSaving}
            onClick={() => setIsSettingsOpen(false)}
            className="me-1"
          ></ButtonIcon>
          <ButtonIcon
            type="done"
            onClick={() => onSubmit(selectedVessels, selectedConsumptions, selectedSituations)}
            disabled={isSaving}
          ></ButtonIcon>
        </div>
      )}
    </div>
  );
};

export default Edit;
