import { combineReducers } from 'redux';

import map from './map/reducer';
import mapPopup from './map-popup/reducer';
import positionList from './position-list/reducer';
import vessels from './vessels/reducer';

export default combineReducers({
  map,
  mapPopup,
  positionList,
  vessels
});
