import PropTypes from 'prop-types';

export const NoteState = PropTypes.shape({
  author: PropTypes.shape({
    id: PropTypes.number,
    full_name: PropTypes.string
  }),
  author_id: PropTypes.number,
  comments: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string
    })
  )
});

export const NoteEntityTypes = PropTypes.oneOf(['crew', 'ports']);
