import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_SAVED_ITEMS'),
  ...asyncTypes('DELETE_SAVED_ITEM'),
  ...asyncTypes('GET_RECENT_ITEMS'),
  TOGGLE_PANEL: 'TOGGLE_PANEL',
  COLLAPSE_MENU: 'COLLAPSE_MENU'
};

export default TYPES;
