import React from 'react';
import { Col, Row } from 'reactstrap';

import Select from 'common/components/form/inputs/Select';
import DateInput, { mode } from 'common/components/form/inputs/date';
import VesselSelector from 'common/components/selectors/VesselSelector';
import { dateRangeDefaultOptions } from 'common/components/form/inputs/date/_constants';
import MarketIndexesButton from './MarketIndexesButton';
import Views from './Views';

const Filters = ({
  dates,
  onDateChange,
  marketIndex,
  indexOptions,
  onMarketIndexSelectChange,
  dateOptions = dateRangeDefaultOptions,
  vessel,
  onVesselChange,
  view,
  onViewChange,
  showAllFilters,
  widths
}) => {
  const shouldExpandFilters = !showAllFilters && widths[0] === 6 && widths[1] === 6;

  return (
    <Row className="align-items-center w-100p flex-nowrap" noGutters>
      {showAllFilters ? (
        <Col xs={2} className="cme-12">
          <VesselSelector
            onChange={v => onVesselChange(v)}
            value={vessel}
            placeholder="Select vessel"
            label=""
            showIcon
            isMulti={false}
            className="mb-0"
            autoFocus={false}
          />
        </Col>
      ) : null}

      <Col xs="auto" className="pe-1">
        <DateInput
          activeMode={mode.range.date}
          onChange={({ from, to }) => {
            if (!from || !to) {
              return;
            } else {
              onDateChange(from, 'date_from');
              onDateChange(to, 'date_to');
            }
          }}
          from={dates.from}
          to={dates.to}
          options={dateOptions}
        />
      </Col>

      <Col
        xs={shouldExpandFilters ? undefined : 3}
        className={`market-index-select ${shouldExpandFilters ? 'pe-3' : 'pe-1'}`}
      >
        <Select
          className="mb-0"
          placeholder="Select market index"
          value={marketIndex}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
          options={indexOptions}
          onChange={onMarketIndexSelectChange}
        />
      </Col>

      {showAllFilters ? (
        <Col xs="auto">
          <MarketIndexesButton vessel={vessel} />
        </Col>
      ) : null}

      <Col xs={shouldExpandFilters ? 6 : undefined}>
        <Views
          activeView={view}
          onViewChange={onViewChange}
          reversed={true}
          showAllFilters={showAllFilters}
        />
      </Col>
    </Row>
  );
};

export default Filters;
