import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import Lottie from 'react-lottie';
import * as animationData from 'assets/lottie/OrganizeCargoes.json';
import logo from 'assets/svg/logos/logo.svg';

import { useSelector } from 'react-redux';
import useRouter from 'use-react-router';
import paths from 'routing/routes/_paths';

const DarkContainer = ({ children, className }) => {
  const authenticated = useSelector(state => state.auth.isAuthenticated);
  const { history } = useRouter();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className={`dark-container ${className || ''}`}>
      <div className="dark-container__inner">
        <div className="dark-container__inner-left">
          <SvgRender
            onClick={() => history.push(authenticated ? paths.landing : paths.login)}
            className="dark-container__logo pointer"
            src={logo}
            style={{ width: 180, height: 76 }}
          />

          <div className="dark-container__animation">
            <Lottie options={defaultOptions} />
          </div>
        </div>
        <div className="dark-container__inner-right">{children}</div>
      </div>
    </div>
  );
};

export default DarkContainer;
