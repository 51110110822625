import TYPES from './types';
import JOB_PROFILE_TYPE from 'common/components/jobs/_base/store/types';
import { REQUIRED_FIELD_MESSAGE } from 'common/utils/form/validation';

const INITIAL_STATE = {
  activeStep: 0,
  isLoading: false,
  id: null,
  due_date: { value: null, interval: 'days', error: null },
  triggers: [],
  isDraft: false,
  isArchived: false
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: payload
      };

    case TYPES.SET_DUE_DATE:
      return {
        ...state,
        due_date: { value: payload.value, interval: payload.interval }
      };

    case TYPES.INVALID_DUE_DATE:
      return {
        ...state,
        due_date: {
          ...state.due_date,
          error: !state.due_date.value ? REQUIRED_FIELD_MESSAGE : null
        }
      };

    case TYPES.CREATE_JOB_TRIGGER.SUCCESS:
      return {
        ...state,
        id: payload.id,
        triggers: []
      };

    case TYPES.GET_JOB_TRIGGER.START:
      return {
        ...state,
        isLoading: true
      };

    case TYPES.GET_JOB_TRIGGER.SUCCESS:
      const { id, due_date, triggers, draft, is_archived } = payload;

      return {
        ...state,
        id,
        due_date,
        triggers,
        isLoading: false,
        isDraft: draft,
        isArchived: is_archived
      };

    case TYPES.GET_JOB_TRIGGER.ERROR:
      return {
        ...state,
        isLoading: false
      };

    case JOB_PROFILE_TYPE.RESET_JOB_PROFILE:
      return {
        ...state,
        id: null,
        due_date: { value: null, interval: 'days' },
        triggers: []
      };

    default:
      return state;
  }
};

export default reducer;
