import TYPES from './types';
import { configureNationalitiesData } from 'store/reports/crew/helpers';

const INITIAL_STATE = {
  report_type: {},
  data: {},
  isFetching: false
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_CREW_NATIONALITIES.START:
      return {
        ...state,
        isFetching: true
      };

    case TYPES.GET_CREW_NATIONALITIES.SUCCESS:
      return {
        ...state,
        report_type: payload.report_type,
        data: configureNationalitiesData(payload.data),
        isFetching: false
      };

    default:
      return state;
  }
};

export default reducer;
