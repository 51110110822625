import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_FLEET'),
  ...asyncTypes('GET_VOYAGE_ROUTE'),
  ...asyncTypes('GET_LOAD_LINES'),
  ...asyncTypes('GET_ESTIMATED_ROUTE'),
  ...asyncTypes('GET_AUTOMATED_ROUTE'),
  ...asyncTypes('CREATE_CUSTOM_AREA'),
  ...asyncTypes('GET_CUSTOM_AREAS'),
  ...asyncTypes('DELETE_CUSTOM_AREA'),
  SET_WEATHER: 'SET_WEATHER',
  CLEAR_WEATHER: 'CLEAR_WEATHER',
  SET_IS_WEATHER_LOADING: 'SET_IS_WEATHER_LOADING',
  SET_LAYERS: 'SET_LAYERS',
  SET_WEATHER_DATE: 'SET_WEATHER_DATE',
  SET_VESSEL: 'SET_VESSEL',
  SET_VESSEL_GROUP: 'SET_VESSEL_GROUP',
  SET_ACTIVE_VIEW: 'SET_ACTIVE_VIEW',
  SET_IS_FLEET_LOADING: 'SET_IS_FLEET_LOADING',
  SET_IS_BOTTOM_TAB_OPEN: 'SET_IS_BOTTOM_TAB_OPEN',
  SET_IS_FULL_ROUTE_ENABLED: 'SET_IS_FULL_ROUTE_ENABLED',
  SET_ACTIVE_BOTTOM_TAB: 'SET_ACTIVE_BOTTOM_TAB',
  CLEAR_ESTIMATED_ROUTE: 'CLEAR_ESTIMATED_ROUTE',
  SET_IS_MENU_PORTAL_OPEN: 'SET_IS_MENU_PORTAL_OPEN',
  CLEAR_STATE: 'CLEAR_STATE',
  CLEAR_AUTOMATED_ROUTE: 'CLEAR_AUTOMATED_ROUTE',
  CLEAR_REPORT_ROUTE: 'CLEAR_REPORT_ROUTE',
  SET_BOTTOM_TAB_POSITION: 'SET_BOTTOM_TAB_POSITION',
  SET_CARD_VIEW: 'SET_CARD_VIEW',
  SET_TOOL: 'SET_TOOL',
  RESET_TOOLS: 'RESET_TOOLS',
  SET_SELECTED_CUSTOM_AREA: 'SET_SELECTED_CUSTOM_AREA',
  SET_CURRENT_CUSTOM_AREA: 'SET_CURRENT_CUSTOM_AREA',
  SET_CUSTOM_AREA_DATA: 'SET_CUSTOM_AREA_DATA',
  SET_CUSTOM_AREA_WARNING: 'SET_CUSTOM_AREA_WARNING',
  TOGGLE_VESSEL: 'TOGGLE_VESSEL',
  ADD_VESSEL: 'ADD_VESSEL',
  REMOVE_VESSEL: 'REMOVE_VESSEL',
  CLEAR_VESSELS: 'CLEAR_VESSELS'
};

export default TYPES;
