import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectCategory } from 'common/components/purchasing/requisition/store/selectors';
import BudgetedCategory from './BudgetedCategory';

export const CategoryDivElementType = 'requisition-category';

const Category = ({ categoryID, inline = true }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);
  const category = useSelector(state => selectCategory(state, categoryID));

  return (
    <Row className="w-100p g-0">
      <Col
        xs="auto"
        className={`bg-primary text-white rounded d-inline-flex align-items-center fs-12 lh-1 px-1 h-20 ${
          isOnBoard ? 'mt-5' : ''
        }`}
        data-type={CategoryDivElementType}
      >
        Category:&nbsp;<strong>{category?.name}</strong>
      </Col>
      {!isOnBoard ? (
        <Col className="ps-1" xs={inline ? '' : 12}>
          <BudgetedCategory categoryID={categoryID} inline={inline} />
        </Col>
      ) : null}
    </Row>
  );
};

Category.propTypes = {
  categoryID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inline: PropTypes.bool
};

export default Category;
