import 'tools/assets/scss/_vessel-consumptions.scss';
import React, { useState, useEffect } from 'react';

import Edit from './settings/Edit';
import View from './settings/view';
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';
import { transformConsumptions } from 'tools/utils/consumptions';
import Loader from './Loader';

const Consumptions = ({ tool, settings, isSettingsOpen, setIsSettingsOpen, setHideSettings }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [vessels, setVessels] = useState([]);
  const [consumptionTypes, setConsumptionTypes] = useState([]);
  const [vesselSituations, setVesselSituations] = useState([]);
  const [userSettings, setUserSettings] = useState({});

  const [consumptions, setConsumptions] = useState({});
  const [isFetchingConsumptions, setIsFetchingConsumptions] = useState(true);
  const [typeColorMapping, setTypeColorMapping] = useState({});

  const init = async () => {
    const vessels = await settings.getVessels();

    const sett = await settings.getSettings();

    const consumptionTypes = await settings.getConsumptionTypes();

    const vesselSituations = await settings.getVesselSituations();

    setUserSettings(sett.data);
    setVessels(vessels.data);
    setConsumptionTypes(consumptionTypes.data);
    setVesselSituations(vesselSituations.data);
    const vesselIds = _get(sett.data, 'settings.vessel_ids', []);
    const consumptionIds = _get(sett.data, 'settings.consumption_ids', []);
    const situationIds = _get(sett.data, 'settings.situation_ids', []);
    let consumptionParams = {
      vessel_ids: vesselIds,
      situation_ids: situationIds,
      consumption_type_ids: consumptionIds
    };
    await getConsumptions(consumptionParams);
  };

  const getConsumptions = async params => {
    const { vessel_ids } = params;
    if (!vessel_ids || !Array.isArray(vessel_ids) || vessel_ids.length === 0) {
      setIsFetchingConsumptions(false);
      setConsumptions({});
      return;
    }
    setIsFetchingConsumptions(true);
    const res = await settings.getConsumptions(params);
    let newTypeColorMapping = {};
    _uniqBy(res.data, 'type_label')
      .map(t => t.type_label)
      .sort()
      .forEach((type, i) => {
        if (type) {
          newTypeColorMapping[type] = `color_${i + 1}`;
        }
      });
    setTypeColorMapping(newTypeColorMapping);

    setConsumptions(transformConsumptions(res.data));
    setIsFetchingConsumptions(false);
  };

  useEffect(() => {
    init();
  }, []);

  const onSubmit = async (vesselIds, consumptions, situations) => {
    setIsSaving(true);
    const params = {
      settings: {
        vessel_ids: vesselIds,
        consumption_ids: consumptions,
        situation_ids: situations
      }
    };

    const res = await settings.updateSettings(params);
    if (res && res.data) {
      setUserSettings(res.data);
      setIsSettingsOpen(false);
    }

    setIsSaving(false);
    let consumptionParams = {
      vessel_ids: vesselIds,
      situation_ids: situations,
      consumption_type_ids: consumptions
    };
    await getConsumptions(consumptionParams);
  };

  return (
    <div className="vessel-consumptions-tool">
      {isSettingsOpen ? (
        <Edit
          onSubmit={onSubmit}
          userSettings={userSettings}
          setIsSettingsOpen={setIsSettingsOpen}
          vessels={vessels}
          consumptionTypes={consumptionTypes}
          vesselSituations={vesselSituations}
          isSaving={isSaving}
        />
      ) : !isFetchingConsumptions ? (
        <View
          typeColorMapping={typeColorMapping}
          setIsSettingsOpen={setIsSettingsOpen}
          setHideSettings={setHideSettings}
          consumptions={consumptions}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Consumptions;
