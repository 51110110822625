import TYPES from './types';
import arrayMove from 'array-move';

const INITIAL_STATE = {
  loaded: false,
  data: [],
  fetched: false,
  paging: {
    total: 0,
    per_page: 20,
    current_page: 1,
    last_page: 1
  }
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_FAVORITES.START:
      return {
        ...state,
        fetched: true,
        loaded: true,
        paging: {
          ...state.paging,
          current_page: payload.params.current_page
        }
      };

    case TYPES.GET_FAVORITES.SUCCESS:
      const { meta, data } = payload;

      return {
        ...state,
        data: data
          .filter(d => d.favourite)
          .map(d => {
            const { item_type, type, title, is_owned } = d.favourite;
            const rest = {};

            if (d.favourite.item) {
              rest.item = d.favourite.item;
            } else {
              rest.item = d.favourite;
            }

            return {
              favourite_type: d.favourite_type,
              item_type: item_type ? item_type : d.favourite_type,
              type: type ? type : d.favourite_type,
              title,
              id: d.favourite.id,
              favourites_count: 1,
              canDelete: false,
              canReorder: true,
              is_owned,
              ...rest
            };
          }),
        paging: {
          ...meta
        }
      };

    case TYPES.FAVORITES_REORDER.START:
    case TYPES.FAVORITES_REORDER.ERROR:
      return {
        ...state,
        data: arrayMove(
          state.data,
          payload.params.oldIndex - (state.paging.current_page !== 1 ? state.paging.per_page : 0),
          payload.params.target - (state.paging.current_page !== 1 ? state.paging.per_page : 0)
        )
      };

    // case SIDEBAR_TYPES.DELETE_SAVED_ITEM.SUCCESS:
    //   return {
    //     ...state,
    //     data: state.data.filter(({ id }) => id !== payload.id)
    //   };

    case TYPES.TOGGLE_FAVORITE.START: {
      return {
        ...state,
        data: state.data.filter(d => d.id !== payload.favourite_id)
      };
    }

    default:
      return state;
  }
};

export default reducer;
