import React from 'react';
import paths from 'routing/routes/_paths';
import PageTitleWithTabs from 'common/components/general/PageTitleWithTabs';
import permissions from 'common/utils/permissions/constants';

export const contactsNavigationTabs = [
  {
    label: 'Person',
    permissions: [permissions.office.contacts.persons.view],
    to: paths.persons
  },
  {
    label: 'Company',
    permissions: [permissions.office.contacts.companies.view],
    to: paths.companies
  }
];

const NavTabs = () => {
  return <PageTitleWithTabs title="Contacts" tabs={contactsNavigationTabs} />;
};

export default NavTabs;
