import React from 'react';
import { getFileType } from '@/ts-common/utils/files';
import Image from './thumbnails/Image';
import UnknownType from './thumbnails/UnknownType';

const getComponentType = type => {
  switch (type) {
    case 'image':
      return Image;
    default:
      return UnknownType;
  }
};

const GalleryFooter = ({ files = [], selectedFile = {}, onClickItem }) => {
  const leftOffset = files.findIndex(file => file.id === selectedFile.id) * (64 + 12); // Index * Width + Margin
  return (
    <div style={{ transform: `translateX(-${leftOffset}px)` }} className="gallery__footer">
      {files.map((file, i) => (
        <GalleryFooterItem
          file={file}
          key={i.toString()}
          isActive={file.id === selectedFile.id}
          onClickItem={onClickItem}
          index={i}
        />
      ))}
    </div>
  );
};

const GalleryFooterItem = ({ file = {}, isActive, onClickItem, index }) => {
  const file_type = getFileType(file.mime_type, file.extension);
  const FilePreview = getComponentType(file_type.type);

  return (
    <div
      onClick={() => onClickItem(index)}
      className={`gallery__footer--item ${isActive ? 'gallery__footer--item-active' : ''}`}
    >
      <FilePreview file={file} fileType={file_type} />
    </div>
  );
};

export default GalleryFooter;
