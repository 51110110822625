import React, { useEffect } from 'react';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { RenderItem, renderPagination } from '../helpers';

import * as sidebarActions from 'store/sidebar/actions';
import * as favoritesActions from 'store/favorites/actions';

const Favorites = () => {
  const [togglePanel] = useActions([sidebarActions.togglePanel]);

  const [getFavorites, reorderFavorites] = useActions([
    favoritesActions.getFavorites,
    favoritesActions.reorderFavorites
  ]);
  const favorites = useSelector(state => state.favorites);

  useEffect(() => {
    if (!favorites.fetched) getFavorites({ current_page: 1, per_page: favorites.paging.per_page });
  }, []);

  const SortableItem = SortableElement(({ item }) => (
    <RenderItem key={item.id} item={item} saved={true} togglePanel={togglePanel} />
  ));

  const SortableList = SortableContainer(({ favorites }) => {
    return (
      <div className="side-search-sections--part-items">
        {favorites.data.map((item, index) => (
          <SortableItem key={`item-${item.id}`} index={index} item={item} />
        ))}
      </div>
    );
  });

  const handleReorder = (oldIndex, newIndex) => {
    const target =
      favorites.paging.current_page !== 1
        ? parseInt(newIndex) + parseInt(favorites.paging.per_page)
        : newIndex;

    const params = { target, oldIndex, id: favorites.data[oldIndex].id };

    reorderFavorites(params);
  };

  return (
    <div
      className={`side-favorites-wrapper side-search-wrapper d-flex flex-column pt-5 ${
        favorites.data.length ? '' : 'bg-empty-favorites'
      }`}
    >
      <div className="side-search-sections flex-fill gray-scrollbar" id="search-sections">
        <div className="side-search-sections--part pe-2 h-100p">
          <div
            className={`${
              favorites.data.length ? 'side-search-sections--part-head mb-7' : ''
            } ps-2 mb-7`}
          >
            STARRED
          </div>

          {favorites.data.length ? (
            <SortableList
              helperClass="side-search-sections--item-sortable"
              useDragHandle={true}
              favorites={favorites}
              onSortEnd={({ oldIndex, newIndex }) => handleReorder(oldIndex, newIndex)}
            />
          ) : (
            <div className="empty-favorites">No starred items yet.</div>
          )}
        </div>

        {favorites.paging.last_page > 1 ? (
          <div className="side-search-sections--pagination d-flex align-items-center justify-content-end ps-2 pe-2 mt-5">
            {renderPagination(favorites, getFavorites)}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Favorites;
