import { get, put } from 'utils/api';

const settings = {
  forex_rates: {
    getCurrencies: search => get(`/lists?list=currencies`, { search }),
    getRates: currencies => get(`/forex-rates`, currencies),
    getSettings: () => get(`/forex-rates/settings`),
    updateSettings: params => put(`/forex-rates/settings`, params)
  },
  vessel_consumptions: {
    getVessels: search => get(`/lists?list=vessels`, { page_size: 500 }),
    getConsumptionTypes: () => get('/lists?list=vessel-consumption-types'),
    getVesselSituations: () => get('/lists?list=vessel-situations'),
    getConsumptions: params => get(`/vessels/consumptions-tool`, params),
    getSettings: () => get(`/vessels/consumptions-tool/settings`),
    updateSettings: params => put(`/vessels/consumptions-tool/settings`, params)
  },
  dwt_draft_calculator: {
    getVessels: search => get(`/lists?list=vessels-dwt-draft`, { search }),
    getInitialVessels: () => get(`/lists?list=vessels-dwt-draft`)
  }
};

export default settings;
