import TYPES from './types';

const INITIAL_STATE = {
  data: {}
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_INTERTANKO_RETENTION_RATE.SUCCESS:
      return {
        ...state,
        data: payload.data
      };

    default:
      return state;
  }
};

export default reducer;
