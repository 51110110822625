import _get from 'lodash/get';
import { isContractExtendedPeriodReduced } from 'crew/profile/main/contracts/preview/parts/helpers';

export const getOffSignerFirstContract = rotation =>
  _get(
    rotation,
    `off_signer_contracts_ordered[${(rotation?.off_signer_contracts_ordered?.length || 0) - 1}]`,
    null
  );

export const getOffSignerLatestContract = rotation =>
  _get(rotation, 'off_signer_contracts_ordered[0]', null);

const parseRotations = data => {
  const parsed = { ...data };

  parsed.data = (data?.data || []).map(rotation => {
    const firstContract = getOffSignerFirstContract(rotation);
    const lastContract = getOffSignerLatestContract(rotation);

    const offSignerContractFromDate = _get(firstContract, `contract_from_date`, null);
    const offSignerUpdatedContractToDate = _get(lastContract, `contract_to_date`, null);
    const offSignerdContractToDate = _get(lastContract, `initial_contract_to_date`, null);

    return {
      ...rotation,
      off_signer_contract_from_date: offSignerContractFromDate,
      off_signer_updated_contract_to_date: offSignerUpdatedContractToDate,
      off_signer_requested_sign_off_date: _get(lastContract, `requested_sign_off_date`, null),
      off_signer_last_contract_id: _get(lastContract, `id`, null),
      ext_date_before_contract: isContractExtendedPeriodReduced(
        offSignerUpdatedContractToDate,
        offSignerdContractToDate
      )
    };
  });

  return parsed;
};

export default parseRotations;
