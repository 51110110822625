import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import Input from 'common/components/form/inputs/Input';
import ShadowBox from 'common/components/general/ShadowBox';
import DateInput from 'common/components/form/inputs/date';
import UploadFiles from 'common/components/form/inputs/upload-files';
import Textarea from 'common/components/form/inputs/Textarea';

const Documents = ({ fields, selectField, changeField }) => {
  return (
    <ShadowBox color="light-1" className="pt-1 cpb-2 cpx-12 cmb-20" flat>
      <div className="fw-bold fs-12 text-violet border-bottom cpb-4 mb-1">Documents</div>

      {/* PASSPORT SECTION */}

      <ShadowBox color="light-4" className="py-1 px-2 cmb-12" flat>
        <Row>
          <Col xs={3} className="mb-1 d-flex align-items-center justify-content-end">
            <div className="border-radius-3 bg-primary fs-10 text-white fw-bold d-flex align-items-center px-1 me-1">
              PASSPORT
            </div>
          </Col>

          <Col xs={12}> </Col>

          <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
            <div className="form-label fs-12 text-blue mb-0 pe-1">ID</div>
          </Col>

          <Col xs={8} className="mb-1 px-0">
            <Input
              placeholder="Add number"
              className="mb-0"
              onChange={changeField('passport_number')}
              {...fields.passport_number}
            />
          </Col>

          <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
            <div className="form-label fs-12 text-blue mb-0 pe-1">ISSUE DATE</div>
          </Col>

          <Col xs={8} className="mb-1 px-0">
            <DateInput
              dataCy="passport_issued_at"
              onChange={selectField('passport_issued_at')}
              className="mb-0 me-5"
              {...fields.passport_issued_at}
            />
          </Col>

          <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
            <div className="form-label fs-12 text-blue mb-0 pe-1">EXPIRATION DATE</div>
          </Col>

          <Col xs={8} className="mb-1 px-0">
            <DateInput
              dataCy="passport_expires_at"
              onChange={selectField('passport_expires_at')}
              className="mb-0 me-5"
              {...fields.passport_expires_at}
            />
          </Col>

          <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
            <div className="form-label fs-12 text-blue mb-0 pe-1">ATTACHMENT</div>
          </Col>

          <Col xs={8} className="mb-1 px-0">
            <UploadFiles
              group="parties.photos"
              files={fields.passport_attachment?.value ? [fields.passport_attachment?.value] : []}
              onChange={files =>
                selectField('passport_attachment')(files && files.length ? files[0] : null)
              }
              singleUpload
              size="sm"
            />
          </Col>
        </Row>
      </ShadowBox>

      {/* SEAMAN BOOK SECTION */}

      <ShadowBox color="light-4" className="py-1 px-2 cmb-12" flat>
        <Row>
          <Col xs={3} className="mb-1 d-flex align-items-center justify-content-end">
            <div className="border-radius-3 bg-primary fs-10 text-white fw-bold d-flex align-items-center px-1 me-1">
              SEAMAN BOOK
            </div>
          </Col>

          <Col xs={12}> </Col>

          <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
            <div className="form-label fs-12 text-blue mb-0 pe-1">ID</div>
          </Col>

          <Col xs={8} className="mb-1 px-0">
            <Input
              placeholder="Add number"
              className="mb-0"
              onChange={changeField('seaman_book_number')}
              {...fields.seaman_book_number}
            />
          </Col>

          <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
            <div className="form-label fs-12 text-blue mb-0 pe-1">REMARKS</div>
          </Col>

          <Col xs={8} className="mb-1 px-0">
            <DateInput
              dataCy="seaman_book_issued_at"
              onChange={selectField('seaman_book_issued_at')}
              className="mb-0 me-5"
              {...fields.seaman_book_issued_at}
            />
          </Col>

          <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
            <div className="form-label fs-12 text-blue mb-0 pe-1">EXPIRATION DATE</div>
          </Col>

          <Col xs={8} className="mb-1 px-0">
            <DateInput
              dataCy="seaman_book_expires_at"
              onChange={selectField('seaman_book_expires_at')}
              className="mb-0 me-5"
              {...fields.seaman_book_expires_at}
            />
          </Col>

          <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
            <div className="form-label fs-12 text-blue mb-0 pe-1">ATTACHMENT</div>
          </Col>

          <Col xs={8} className="mb-1 px-0">
            <UploadFiles
              group="parties.photos"
              files={
                fields.seaman_book_attachment?.value ? [fields.seaman_book_attachment?.value] : []
              }
              onChange={files =>
                selectField('seaman_book_attachment')(files && files.length ? files[0] : null)
              }
              singleUpload
              size="sm"
            />
          </Col>
        </Row>
      </ShadowBox>

      {/* COVID VACCINATION SECTION */}

      <ShadowBox color="light-4" className="py-1 px-2 cmb-12" flat>
        <Row>
          <Col xs={3} className="mb-1 d-flex align-items-center justify-content-end">
            <div className="border-radius-3 bg-primary fs-10 text-white fw-bold d-flex align-items-center px-1 me-1">
              COVID VACCINATION
            </div>
          </Col>

          <Col xs={12}> </Col>

          <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
            <div className="form-label fs-12 text-blue mb-0 pe-1">DATE</div>
          </Col>

          <Col xs={8} className="mb-1 px-0">
            <DateInput
              dataCy="covid_vaccination_date"
              onChange={selectField('covid_vaccination_date')}
              className="mb-0 me-5"
              {...fields.covid_vaccination_date}
            />
          </Col>

          <Col xs={3} className="d-flex align-items-start justify-content-end mb-1">
            <div className="form-label fs-12 text-blue mb-0 pe-1 cmt-2">REMARKS</div>
          </Col>

          <Col xs={8} className="mb-1 px-0">
            <Textarea
              rows={4}
              className="mb-0"
              placeholder="Add remarks"
              onChange={e => selectField('covid_vaccination_remarks')(e.target.value)}
              value={fields.covid_vaccination_remarks.value}
            />
          </Col>
        </Row>
      </ShadowBox>

      {/* INTERNATIONAL VACCINATION SECTION */}

      <ShadowBox color="light-4" className="py-1 px-2 cmb-12" flat>
        <Row>
          <Col xs={3} className="mb-1 px-0 d-flex align-items-center justify-content-end">
            <div className="border-radius-3 bg-primary fs-10 text-white fw-bold d-flex align-items-center px-1 text-nowrap me-1">
              INTERNATIONAL VACCINATION
            </div>
          </Col>

          <Col xs={12}> </Col>

          <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
            <div className="form-label fs-12 text-blue mb-0 pe-1">DATE</div>
          </Col>

          <Col xs={8} className="mb-1 px-0">
            <DateInput
              dataCy="international_vaccination_date"
              onChange={selectField('international_vaccination_date')}
              className="mb-0 me-5"
              {...fields.international_vaccination_date}
            />
          </Col>

          <Col xs={3} className="d-flex align-items-start justify-content-end mb-1">
            <div className="form-label fs-12 text-blue mb-0 pe-1 cmt-2">REMARKS</div>
          </Col>

          <Col xs={8} className="mb-1 px-0">
            <Textarea
              rows={4}
              className="mb-0"
              placeholder="Add remarks"
              onChange={e => selectField('international_vaccination_remarks')(e.target.value)}
              value={fields.international_vaccination_remarks.value}
            />
          </Col>
        </Row>
      </ShadowBox>
    </ShadowBox>
  );
};

export default Documents;

Documents.propTypes = {
  fields: PropTypes.object.isRequired,
  selectField: PropTypes.func.isRequired,
  changeField: PropTypes.func.isRequired
};
