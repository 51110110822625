import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectWidgetState, selectWidgetPreferences } from 'store/dashboards/selectors';
import Preferences from './Preferences';
import _get from 'lodash/get';
import avatar from 'common/assets/svg/avatars/crew.svg';
import ProfileImage from 'common/components/general/ProfileImage';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import Departments from 'common/components/general/Departments';
import EmptyState from './EmptyState';
import paths from 'routing/routes/_paths';

import moment from 'moment';

const Upcoming = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));
  const preferences = useSelector(state => selectWidgetPreferences(state, id));
  const { type } = preferences;

  return (
    <div className={`widget-birthday__upcoming widget-birthday__upcoming--${type}`}>
      <div className="text-violet fw-medium cms-10">Upcoming</div>
      {state.paging?.total > 0 ? (
        <WidgetDefaultTable
          id={id}
          columns={[
            {
              key: 'full_name',
              width: 5
            },
            {
              key: 'photo',
              minWidth: 30,
              maxWidth: 30
            },
            { key: 'active_vessel', width: 4 },
            {
              headerClassName: 'opacity-0',
              key: 'birthday',
              type: 'date'
            }
          ]}
          rows={{
            full_name: data => (
              <Link to={`${data.type === 'person' ? paths.persons : paths.crew}/${data.id}`}>
                {data.full_name}
              </Link>
            ),
            photo: data => (
              <ProfileImage
                image={_get(data, 'photo.url', null)}
                fallback={avatar}
                rounded={true}
                style={{ width: 22, height: 22 }}
                imgProps={{ width: 22, height: 22 }}
              />
            ),
            active_vessel: data => {
              if (type === 'crew') {
                return data.active_vessel?.name;
              } else {
                const departments = _get(data, 'departments', []);
                return <Departments values={departments} />;
              }
            },
            birthday: data => {
              const next_birthday = _get(data, 'next_birthday');

              if (next_birthday === moment().add(1, 'day').format('YYYY-MM-DD')) {
                return 'Tomorrow';
              } else {
                const format = moment(next_birthday).fromNow();

                return format.charAt(0).toUpperCase() + format.slice(1);
              }
            }
          }}
          label={type === 'crew' ? 'crew' : 'person'}
          state={state}
        />
      ) : (
        <EmptyState label={'No Upcoming Birthdays'} />
      )}
    </div>
  );
};
export const BirthdayPreferences = Preferences;

export default Upcoming;
