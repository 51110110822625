import React from 'react';

import Select from 'common/components/form/inputs/Select';
import StyledSelect from 'common/components/form/styled-inputs/Select';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

const VesselOrVesselGroupSelector = ({
  disabled,
  error,
  value,
  onChange,
  styled,
  gray,
  ...rest
}) => {
  const fetchOptions = async search => {
    let vessel_groups = [];
    let vessels = [];

    const vesselGroupsPromise = search
      ? getAsyncOptions(search, 'vessel-groups')
      : getInitialAsyncValues('vessel-groups');

    const vesselsPromise = search
      ? getAsyncOptions(search, 'vessels')
      : getInitialAsyncValues('vessels');

    const [vesselGroupsResult, vesselsResult] = await Promise.all([
      vesselGroupsPromise,
      vesselsPromise
    ]);

    vessel_groups = vesselGroupsResult ?? [];
    vessels = vesselsResult ?? [];

    const vesselGroupOptions = vessel_groups.map(group => ({
      ...group,
      value: { id: group.id, type: 'vessel_group' }
    }));

    const vesselOptions = vessels.map(vessel => ({
      ...vessel,
      value: { id: vessel.id, type: 'vessel' }
    }));

    const options = [...vesselGroupOptions, ...vesselOptions];

    return options;
  };

  const SelectTag = styled ? StyledSelect : Select;

  return (
    <SelectTag
      isAsync
      isMulti
      openMenuOnClick={true}
      closeMenuOnSelect={false}
      disabled={disabled}
      loadOptions={fetchOptions}
      defaultOptions={fetchOptions}
      getOptionLabel={o => o.name}
      getOptionValue={({ id, name }) => id + '-' + name}
      value={value}
      error={error ? 'Required field' : ''}
      label="Select Vessels"
      placeholder="Select Vessel(s)"
      onChange={onChange}
      noOptionsMessage={({ inputValue }) =>
        !inputValue.length ? `Search for Vessels` : `No Vessels found`
      }
      {...rest}
    />
  );
};
export default VesselOrVesselGroupSelector;
