import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('CREATE_TRIP'),
  ...asyncTypes('GET_TRIP'),
  ...asyncTypes('EDIT_TRIP'),
  ...asyncTypes('DELETE_TRIP'),
  ...asyncTypes('ADD_NEW_TICKET_TO_TRIP'),
  ...asyncTypes('UPDATE_TRIP_TICKET'),
  ...asyncTypes('ACCEPT_TRIP_TICKET'),
  ...asyncTypes('DELETE_TRIP_TICKET'),
  ...asyncTypes('EDIT_TICKETING_CASE'),
  ...asyncTypes('GET_TICKET_OFFER_TOTALS'),
  ...asyncTypes('DOWNLOAD_TICKET_OFFER_ATTACHMENTS'),
  ...asyncTypes('DELETE_TRIP_PARTICIPANT'),
  ...asyncTypes('ADD_TRIP_PARTICIPANT'),
  ...asyncTypes('GET_TICKETS_TOTALS'),
  //
  TICKETING_CASE_COLLAPSE: 'TICKETING_CASE_COLLAPSE',
  TICKETING_CASE_ALL_COLLAPSE: 'TICKETING_CASE_ALL_COLLAPSE',
  TOGGLE_TICKETING_ROW_BULK_MODE: 'TOGGLE_TICKETING_ROW_BULK_MODE',

  BULK_SELECT_TICKETING_ROTATION: 'BULK_SELECT_TICKETING_ROTATION',
  CLEAR_SELECTED_TICKETING_ROTATIONS: 'CLEAR_SELECTED_TICKETING_ROTATIONS',

  BULK_SELECT_TICKETING_PARTICIPANT: 'BULK_SELECT_TICKETING_PARTICIPANT',
  CLEAR_SELECTED_TICKETING_PARTICIPANTS: 'CLEAR_SELECTED_TICKETING_PARTICIPANTS',

  //
  OPEN_TRIP_DRAWER: 'OPEN_TRIP_DRAWER',
  CLOSE_TRIP_DRAWER: 'CLOSE_TRIP_DRAWER',
  SET_TRIP_PREVENTION_MODAL: 'SET_TRIP_PREVENTION_MODAL',
  UPDATE_TRIP_PARTY: 'UPDATE_TRIP_PARTY',
  UPDATE_TRIP_PARTIES: 'UPDATE_TRIP_PARTIES',
  UPDATE_TRIP_DATA: 'UPDATE_TRIP_DATA',
  SET_TRIP_HAS_VALIDATION_ERRORS: 'SET_TRIP_HAS_VALIDATION_ERRORS',
  COLLAPSE_TRIP: 'COLLAPSE_TRIP'
};

export default TYPES;
