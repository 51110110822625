import React from 'react';
import PropTypes from 'prop-types';
import { strToNumber } from 'common/utils/numbers';

export const getBudgetPercentageColor = percentageAmount => {
  if (strToNumber(percentageAmount) >= 80 && strToNumber(percentageAmount) < 100) {
    return 'text-orange';
  } else if (strToNumber(percentageAmount) >= 100) {
    return 'text-red';
  } else {
    return '';
  }
};

const BudgetPercentage = ({ value, children }) => {
  const colorClassName = getBudgetPercentageColor(value);

  return <span className={`d-inline-flex align-items-center ${colorClassName}`}>{children}</span>;
};

export default BudgetPercentage;

BudgetPercentage.propTypes = {
  valueToCompare: PropTypes.number
};
