export const columns = [
  {
    header: 'Date',
    key: 'date_from',
    type: 'string',
    sort: true,
    headerClassName: 'd-flex align-items-center justify-content-start text-violet',
    width: 2
  },
  {
    header: (
      <div className="text-white">
        <strong>Market</strong> <span className="fw-medium">baltic exchange</span>
      </div>
    ),
    key: 'market',
    groupId: 'market',
    columns: [
      {
        header: 'Gross',
        key: 'market_tce_gross',
        sort: true,
        className: 'd-flex align-items-center justify-content-center',
        type: 'number'
      },
      {
        header: 'Net',
        key: 'market_tce_net',
        type: 'number',
        className: 'd-flex align-items-center justify-content-center',
        sort: true
      },
      {
        header: 'Adjust Net',
        key: 'market_tce_adjusted_net',
        type: 'number',
        className: 'd-flex align-items-center justify-content-center',
        sort: true
      }
    ]
  },
  {
    header: 'Actual Net',
    key: 'tce_net',
    headerClassName: 'd-flex align-items-center justify-content-end text-violet',
    type: 'number',
    sort: true,
    width: 2
  },
  {
    header: 'Difference ($)',
    key: 'difference',
    headerClassName: 'd-flex align-items-center justify-content-center text-violet',
    type: 'string',
    sort: true,
    width: 3
  }
];
