import { useSelector } from 'react-redux';

import WidgetDefaultCard from 'views/dashboard/components/WidgetDefaultCard';
import paths from 'routing/routes/_paths';

import { selectWidgetStateData } from 'store/dashboards/selectors';

const CaptainReportsCardWidget = ({ id }) => {
  const data = useSelector(state => selectWidgetStateData(state, id));

  return (
    <WidgetDefaultCard id={id} count={data.cpt_reports_count} linkTo={paths.captain_reports} />
  );
};

export default CaptainReportsCardWidget;
