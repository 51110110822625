const selectAuthReducer = state => state.auth;

export const selectIsAuthenticated = state =>
  selectAuthReducer(state)?.isAuthenticated ? true : false;

export const selectAuthenticationMethod = state =>
  selectAuthReducer(state)?.two_factor_authentication_method || null;

export const selectAuthenticationToken = state => selectAuthReducer(state)?.token;

export const select2faSetupIsRequired = state =>
  selectAuthReducer(state)?.two_factor_authentication_setup_required;
