import React from 'react';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import paths from '@/routing/routes/_paths.js';

const PerformanceSetups = React.lazy(() =>
  retry(() => import('@/views/performance/setups/index.jsx'))
);

const performance = [
  {
    path: [
      `${paths.performance_setups}`,
      `${paths.performance_setups}/:report(benchmarks|formulas|engine-load-diagram|comparison|ams-configuration)/:vesselID?`,
      `${paths.performance_setups}/:report(tags)/:tab(system|generated|default-sources|mappings)?`
    ],
    component: PerformanceSetups,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'Performance Setups',
    permissions: [permissions.office.performance.setups.view]
  }
];

export default performance;
