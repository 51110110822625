import '../assets/scss/_sidebar.scss';
import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'reactstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import moment from 'moment';
import arrayMove from 'array-move';

import PropTypes from 'prop-types';
import ButtonIcon from '../components/general/ButtonIcon';
import AddTool from '../components/general/AddTool';
import Tool from './tool';
import EmptyState from './EmptyState';

import x from '../assets/svg/x.svg';
import _isEqual from 'lodash/isEqual';

const SortableItem = SortableElement(
  ({ tool, isEditing, isSidebarOpen, removeTool, settings, systemCurrency }) => (
    <Tool
      tool={tool}
      removeTool={removeTool}
      isEditing={isEditing}
      isSidebarOpen={isSidebarOpen}
      settings={settings}
      systemCurrency={systemCurrency}
    />
  )
);

const SortableList = SortableContainer(
  ({ items, isEditing, isSidebarOpen, removeTool, settings, systemCurrency }) => (
    <div className="tools-sidebar--body">
      {items.map((tool, i) => (
        <SortableItem
          key={`item-${tool.label}`}
          index={i}
          tool={tool}
          isEditing={isEditing}
          isSidebarOpen={isSidebarOpen}
          removeTool={removeTool}
          settings={settings[tool.label] || null}
          systemCurrency={systemCurrency}
        />
      ))}
    </div>
  )
);

const Sidebar = ({
  toggle,
  close,
  isOpen,
  list,
  userTools,
  updateUserTools,
  settings,
  systemCurrency
}) => {
  const ref = useRef(null);
  const [isSorting, setIsSorting] = useState(false);
  const [editing, setEditing] = useState(false);
  const [selectedTools, setSelectedTools] = useState([]);

  useEffect(() => {
    if (!_isEqual(userTools, selectedTools)) {
      setSelectedTools(userTools);
    }
  }, [userTools]);

  const saveTools = async () => {
    const params = { tools: selectedTools.map((t, i) => ({ tool_id: t.id, sort_index: i })) };
    updateUserTools(params);
    setEditing(false);
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <div
      className={`tools-sidebar${isOpen ? ' is-open' : ''}${isSorting ? ' is-sorting' : ''}`}
      ref={ref}
    >
      <div
        className={`tools-sidebar--inner tools-sidebar--inner-active${editing ? ' edit-open' : ''}`}
      >
        <div className="tools-sidebar--header">
          <Button type="button" color="link" className="p-0 me-6" onClick={toggle}>
            <img src={x} alt="close icon" width={16} height={16} />
          </Button>
          {moment().format('dddd, MMMM D')}
        </div>

        {selectedTools.length || editing ? (
          <SortableList
            helperClass="tools-rearrange-sortable"
            useDragHandle={true}
            lockAxis="y"
            items={selectedTools}
            removeTool={r => setSelectedTools(selectedTools.filter(t => t.label !== r.label))}
            isEditing={editing}
            systemCurrency={systemCurrency}
            isSidebarOpen={isOpen}
            onSortEnd={({ oldIndex, newIndex }) => {
              setSelectedTools(arrayMove(selectedTools, oldIndex, newIndex));
              setTimeout(() => setIsSorting(false), 1000);
            }}
            onSortStart={() => setIsSorting(true)}
            settings={settings}
          />
        ) : (
          <EmptyState setEditing={setEditing} />
        )}
        <div className="tools-sidebar--footer">
          {editing ? (
            <ButtonIcon type="done" text="Done" onClick={() => saveTools()} />
          ) : (
            <ButtonIcon type="add" text="Add Tool" onClick={() => setEditing(true)} />
          )}
        </div>
      </div>
      <div
        className={`tools-sidebar--inner tools-sidebar--inner-lists${editing ? ' is-editing' : ''}`}
      >
        <div className="tools-sidebar--header"></div>
        <div className="tools-sidebar--body">
          {list
            .filter(l => !selectedTools.some(s => s.label === l.label))
            .map(tool => (
              <AddTool
                key={tool.id}
                tool={tool}
                onAdd={t => setSelectedTools([...selectedTools, t])}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  list: PropTypes.array.isRequired, // List of tools
  userTools: PropTypes.array.isRequired, // User's selected tools
  updateUserTools: PropTypes.func.isRequired, // Update user's tools
  config: PropTypes.object // Object with the configuration of each tool
};
