import { useDispatch, useSelector } from 'react-redux';

import {
  selectActiveCategoryLabel,
  selectShouldShowMarkAllAsRead,
  selectSummaryTypeUnreadCount
} from 'store/notifications/selectors';
import {
  markNotificationAsRead,
  resetUnreadCount,
  setIsUnreadTabActive
} from 'store/notifications/actions';

import Tab from './components/Tab';
import UnreadTab from 'components/layouts/sidebar/panel/notifications/components/unread-tab';
import { useEffect } from 'react';

const Header = () => {
  const activeLabel = useSelector(selectActiveCategoryLabel);
  const unreadCount = useSelector(state => selectSummaryTypeUnreadCount(state, activeLabel));
  const shouldShowMarkAllAsRead = useSelector(state =>
    selectShouldShowMarkAllAsRead(state, activeLabel)
  );
  const dispatch = useDispatch();

  const onMarkAllAsRead = async () => {
    if (!activeLabel) return;
    const type_prefix = activeLabel === 'all' ? undefined : activeLabel;

    try {
      await dispatch(
        markNotificationAsRead({
          type_prefix,
          all: true
        })
      );

      dispatch(resetUnreadCount(type_prefix));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!unreadCount) dispatch(setIsUnreadTabActive(false));
  }, [dispatch, unreadCount]);

  return (
    <div className="d-flex flex-column px-2">
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center flex-1">
          {activeLabel === 'all' || activeLabel === 'summary' ? (
            <>
              <Tab label="all" />
              <Tab label="summary" />
            </>
          ) : (
            <>
              <Tab label="back" />
              <Tab label={activeLabel} />
            </>
          )}

          {unreadCount ? <UnreadTab /> : null}
        </div>

        {shouldShowMarkAllAsRead ? (
          <div
            className="mark-all-as-read fw-medium fs-12 text-violet cursor-pointer"
            onClick={onMarkAllAsRead}
          >
            Mark all as read
          </div>
        ) : null}
      </div>

      <div className="border-bottom border-platinum pb-2 cmb-4 w-100p"></div>
    </div>
  );
};

export default Header;
