import TYPES from './types';

const INITIAL_STATE = {
  tabs: [],
  tags: [],
  filterTags: [],
  table: {},
  benchmarks: {},
  scatterTable: {},
  engineLoadDiagram: null,
  isEngineLoadDiagramLoading: false,
  interval: null,
  loading: false,
  areTabsFetching: false,
  isGraphFullscreen: false,
  defaultGraphs: {
    engine_load_diagram: {
      data: {},
      values: [],
      isFetching: false
    }
  }
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    // GET TYPES
    case TYPES.TOGGLE_IS_FULLSCREEN_GRAPH:
      return {
        ...state,
        isGraphFullscreen: !state.isGraphFullscreen
      };

    case TYPES.GET_DEFAULT_GRAPH.START:
    case TYPES.GET_DEFAULT_GRAPH_VALUES.START:
      return {
        ...state,
        defaultGraphs: {
          ...state.defaultGraphs,
          [payload?.type]: {
            ...state.defaultGraphs[payload?.type],
            isFetching: true
          }
        }
      };

    case TYPES.GET_DEFAULT_GRAPH.ERROR:
    case TYPES.GET_DEFAULT_GRAPH_VALUES.ERROR:
      return {
        ...state,
        defaultGraphs: {
          ...state.defaultGraphs,
          [payload?.type]: {
            ...state.defaultGraphs[payload?.type],
            isFetching: false
          }
        }
      };

    case TYPES.GET_DEFAULT_GRAPH.SUCCESS:
      return {
        ...state,
        defaultGraphs: {
          ...state.defaultGraphs,
          [payload.type]: {
            data: payload?.data,
            isFetching: false
          }
        }
      };

    case TYPES.GET_DEFAULT_GRAPH_VALUES.SUCCESS:
      return {
        ...state,
        defaultGraphs: {
          ...state.defaultGraphs,
          [payload.type]: {
            ...state.defaultGraphs[payload?.type],
            values: payload?.data || [],
            isFetching: false
          }
        }
      };

    case TYPES.GET_TABS.START:
      return {
        ...state,
        areTabsFetching: true,
        tabs: []
      };
    case TYPES.GET_TABS.SUCCESS:
      return {
        ...state,
        areTabsFetching: false,
        tabs: payload
      };
    case TYPES.GET_TAGS.START:
      return {
        ...state,
        tags: []
      };
    case TYPES.GET_TAGS.SUCCESS:
      return {
        ...state,
        tags: payload
      };
    case TYPES.GET_FILTER_TAGS.START:
      return {
        ...state,
        filterTags: []
      };
    case TYPES.GET_FILTER_TAGS.SUCCESS:
      return {
        ...state,
        filterTags: payload
      };
    case TYPES.GET_GRAPH_VALUES.START:
      return {
        ...state,
        loading: true
      };
    case TYPES.GET_GRAPH_VALUES.SUCCESS:
      return {
        ...state,
        loading: false,
        table:
          payload.type === 'table'
            ? {
                ...state.table,
                [payload.id]: {
                  data: payload.data
                }
              }
            : { ...state.table },
        scatterTable:
          payload.type === 'scatter'
            ? {
                ...state.scatterTable,
                [payload.id]: {
                  data: payload.data.table || { ...state.scatterTable }
                }
              }
            : { ...state.scatterTable }
      };

    case TYPES.GET_GRAPH_BENCHMARKS.SUCCESS:
      return {
        ...state,
        benchmarks: {
          ...state.benchmarks,
          [payload.id]: payload.data
        }
      };

    // POST TYPES
    case TYPES.CREATE_TAB.SUCCESS:
      return {
        ...state,
        tabs: [...state.tabs, payload]
      };
    case TYPES.CREATE_GRAPH.SUCCESS:
      return {
        ...state,
        tabs: state.tabs.map(tab =>
          tab.id === payload.vessel_graph_tab_id
            ? { ...tab, graphs: [...tab.graphs, payload] }
            : tab
        )
      };

    // PUT TYPES
    case TYPES.UPDATE_TAB.SUCCESS:
      return {
        ...state,
        tabs: state.tabs.map(tab => (tab.id === payload.id ? { ...tab, ...payload } : tab))
      };
    case TYPES.UPDATE_GRAPH.SUCCESS:
    case TYPES.UPDATE_BENCHMARKS.SUCCESS:
      return {
        ...state,
        tabs: state.tabs.map(tab =>
          tab.id === payload.vessel_graph_tab_id
            ? {
                ...tab,
                graphs: tab.graphs.map(graph => (graph.id === payload.id ? { ...payload } : graph))
              }
            : tab
        )
      };

    // DELETE TYPES
    case TYPES.DELETE_TAB.SUCCESS:
      return {
        ...state,
        tabs: state.tabs.filter(tab => tab.id !== payload.id)
      };
    case TYPES.DELETE_GRAPH.SUCCESS:
      return {
        ...state,
        tabs: state.tabs.map(tab =>
          tab.id === payload.vessel_graph_tab_id
            ? {
                ...tab,
                graphs: tab.graphs.filter(graph => graph.id !== payload.id)
              }
            : tab
        )
      };

    // HELPER TYPES
    case TYPES.SET_INTERVAL:
      return {
        ...state,
        interval: {
          id: payload.id,
          value: payload.interval
        }
      };

    case TYPES.GET_ENGINE_LOAD_DIAGRAM.START:
      return {
        ...state,
        isEngineLoadDiagramLoading: true
      };

    case TYPES.GET_ENGINE_LOAD_DIAGRAM.SUCCESS:
      return {
        ...state,
        engineLoadDiagram: payload,
        isEngineLoadDiagramLoading: false
      };

    case TYPES.GET_ENGINE_LOAD_DIAGRAM.ERROR:
      return {
        ...state,
        isEngineLoadDiagramLoading: false
      };

    default:
      return state;
  }
};

export default reducer;
