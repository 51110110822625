import { useEffect, useState } from 'react';
import moment from 'moment';

import { selectEventId } from 'events/store/events/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { selectEventUnderwaterServicesData } from 'events/store/event-modules/underwater-services/selectors';
import {
  deleteUnderwaterService,
  getUnderwaterServices,
  addUnderwaterService,
  updateUnderwaterService
} from 'events/store/event-modules/underwater-services/actions';
import { useForm } from '@/utils/hooks';
import config from './services/form/config';
import { useTable } from 'common/components/table';
import { useDrawer } from '@/ts-common/components/drawer';
import edit from 'common/assets/svg/actions/edit.svg';
import bin from 'common/assets/svg/common/bin.svg';

import TableView from './services/TableView';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import UnderwaterServicesInfo from 'events/event/modules/module-components/underwater-services/UnderwaterServicesInfo';
import { displayDate } from '@/ts-common/utils/dates';

const UnderwaterServicesWrapper = () => {
  const [active, setActive] = useState(null);
  const drawer = useDrawer();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isOnBoard = useSelector(state => state.isOnBoard);

  const eventId = useSelector(selectEventId);
  const servicesData = useSelector(selectEventUnderwaterServicesData);
  const { formState, collectValues, loadValues, resetForm, handleSubmitError } = useForm(
    config,
    {}
  );

  const onEditUnderwaterService = data => {
    setActive(data);
    drawer.open();
  };

  const fetchData = async params => {
    if (!eventId) return;
    try {
      await dispatch(
        getUnderwaterServices({
          event_id: eventId,
          ...params
        })
      ).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  const table = useTable({
    label: 'events_underwater_services',
    requestTableListFrom: fetchData,
    defaultRequestParams: { paging: false, filters: false, sorting: true },
    columns: [
      {
        header: 'Type',
        key: 'type_name',
        minWidth: 200,
        maxWidth: 200,
        headerClassName: 'bg-transparent',
        sort: true
      },
      {
        header: 'Location',
        key: 'location',
        minWidth: 200,
        maxWidth: 200,
        headerClassName: 'bg-transparent',
        sort: true
      },
      {
        header: 'Date',
        key: 'date',
        minWidth: 120,
        maxWidth: 120,
        headerClassName: 'bg-transparent',
        sort: true
      },
      {
        header: 'Company',
        key: 'company_name',
        minWidth: 310,
        maxWidth: 310,
        headerClassName: 'bg-transparent',
        sort: true
      },
      {
        header: 'Divers',
        key: 'divers',
        minWidth: 200,
        headerClassName: 'bg-transparent',
        maxWidth: 200
      },
      {
        key: 'actions',
        field: 'actions'
      }
    ]
  });

  const onDeleteUnderwaterService = async data => {
    setActive(data);

    await dispatch(
      deleteUnderwaterService({ event_id: eventId, underwater_service_id: data.id })
    ).unwrap();

    table.refetchData();
  };

  const handleSave = async () => {
    const values = collectValues();

    if (!values) return;

    try {
      setIsSubmitting(true);

      const { company, divers, underwater_service_type, ...rest } = values;

      const params = {
        event_id: eventId,
        underwater_service_type_id: underwater_service_type.id || null,
        company_id: company?.id || null,
        diver_ids: divers || [],
        ...rest
      };

      if (active) {
        await dispatch(
          updateUnderwaterService({ ...params, underwater_service_id: active?.id })
        ).unwrap();
      } else {
        await dispatch(addUnderwaterService(params)).unwrap();
      }

      drawer.close();

      table.refetchData();

      setIsSubmitting(false);
    } catch (error) {
      handleSubmitError(error);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (drawer.isOpen && active) {
      loadValues({
        ...active,
        underwater_service_type: active.type || null,
        divers: active.divers?.length ? active.divers.map(d => d.id) : []
      });
    } else {
      resetForm();
    }
  }, [drawer.isOpen, resetForm, loadValues, active]);

  return (
    <>
      <TableView
        services={servicesData}
        onSaveUnderwaterService={handleSave}
        table={table}
        rows={{
          type_name: data => data.type?.name || '-',
          company_name: data => data.company?.full_name || '-',
          divers: data => <NamesInTooltip names={data.divers.map(d => d.full_name)} />,
          date: data => (data?.date ? displayDate(data.date, { time: true }) : '-'),
          actions: data => ({
            options: isOnBoard
              ? []
              : [
                  {
                    label: 'Edit',
                    icon: edit,
                    onClick: () => onEditUnderwaterService(data)
                  },
                  {
                    label: 'Delete',
                    icon: bin,
                    preventionModal: true,
                    modalProps: {
                      onAccept: () => onDeleteUnderwaterService(data),
                      header: 'Delete',
                      actionText: 'Delete',
                      action: 'delete',
                      actionHoverColor: 'delete',
                      body: (
                        <div className="text-center">
                          You are about to delete this underwater service. <br />
                          Are you sure you want to continue?
                        </div>
                      )
                    }
                  }
                ]
          })
        }}
        drawer={drawer}
        active={active}
        setActive={setActive}
        formState={formState}
        isTemplate={false}
        isSubmitting={isSubmitting}
      />
      <div className="mt-3">
        <UnderwaterServicesInfo />
      </div>
    </>
  );
};

export default UnderwaterServicesWrapper;
