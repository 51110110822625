import { AsyncSystemTask, SystemTask } from '@/types/system-tasks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SYSTEM_TASKS } from './query-keys';

export const useUpdateAsyncSystemTasks = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (task: AsyncSystemTask) => Promise.resolve(task),
    retry: false,
    onSuccess: task => {
      if (!task.event) return;

      queryClient.setQueryData([SYSTEM_TASKS], (oldTaks?: SystemTask[]) => {
        return (oldTaks || []).map(old =>
          old.id === task.data.action_id
            ? { ...old, progress: task.data.progress, error: task.data.error, event: task.event }
            : old
        );
      });
    }
  });
};
