import { getUnderwaterServicesInfo, getUnderwaterServices } from './actions';
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  info: [],
  // todo: make sure the state does not have normal module data when you visit settings (we use the same store)
  services: []
};

const underwaterServicesModuleSlice = createSlice({
  name: 'eventUnderwaterServices',
  initialState: INITIAL_STATE,
  reducers: {
    addService: (state, { payload }) => {
      state.services.push(payload);
      return state;
    },
    deleteService: (state, { payload }) => {
      state.services = state.services.filter(service => service.index !== payload);
      return state;
    },

    setServices: (state, { payload }) => {
      state.services = payload.map((service, index) => ({ ...service, index }));
      return state;
    }
  },
  extraReducers: builder => {
    builder

      .addCase(getUnderwaterServicesInfo.fulfilled, (state, { payload }) => {
        state.info = payload;
        return state;
      })

      //services

      .addCase(getUnderwaterServices.fulfilled, (state, { payload }) => {
        state.services = payload;
        return state;
      })

      .addDefaultCase(state => {
        return state;
      });
  }
});

export const { addService, deleteService, setServices } = underwaterServicesModuleSlice.actions;

export default underwaterServicesModuleSlice.reducer;
