import { Row, Col } from 'reactstrap';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import Input from 'common/components/form/inputs/Input';
import SvgRender from 'common/components/general/SvgRender';
import binIcon from 'common/assets/svg/actions/delete.svg';
import ListSelect from 'common/components/selectors/ListSelect';

import { useFormState } from 'utils/hooks';

const MultipleInfoFields = ({ state, onRemove, isEmail = false }) => {
  const { fields, selectField, changeField } = useFormState(state);

  return (
    <Row className="w-100p position-relative" css={style} noGutters>
      <Col xs={6} className="d-flex align-items-center justify-content-end mb-1 cpe-6">
        <ListSelect
          placeholder="Select type"
          list={isEmail ? 'email-types' : 'phone-types'}
          isMulti={false}
          isAsync={false}
          isSearchable={false}
          className="mb-0 w-100p"
          onChange={selectField('type')}
          getOptionValue={option => option.label}
          getOptionLabel={option => option.name}
          {...fields.type}
        />
      </Col>

      <Col xs={6} className="mb-1">
        <Input
          placeholder={`Add ${isEmail ? 'email' : 'number'}`}
          className="mb-0"
          onChange={changeField('value')}
          {...fields.value}
        />
      </Col>

      <SvgRender
        style={{ width: 14, height: 16 }}
        onClick={onRemove}
        src={binIcon}
        className="text-red remove-bin"
      />
    </Row>
  );
};

export default MultipleInfoFields;

MultipleInfoFields.propTypes = {
  state: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  isEmail: PropTypes.bool
};

const style = css`
  .remove-bin {
    position: absolute;
    right: -20px;
    top: 4px;
  }
`;
