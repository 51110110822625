import 'assets/scss/main.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { setupResponseInterceptors, setupRequestInterceptors } from '@/api';
import * as serviceWorker from './registerServiceWorker';

import store, { persistor } from 'store';
import AppRouter from 'routing';
import Bugsnag from 'components/layouts/Bugsnag';
import moment from 'moment';
import { history } from 'store/rootReducer';
// import UnderMaintenance from 'views/UnderMaintenance';
import queryClient from '@/utils/query';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

setupRequestInterceptors(store);
setupResponseInterceptors(store);

moment.locale('en', {
  week: {
    dow: 1, // First day of week is Monday
    doy: 7 // First week of year must contain 1 January (7 + 1 - 1)
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Bugsnag history={history}>
        <QueryClientProvider client={queryClient}>
          <AppRouter history={history} />
          {import.meta.env.MODE === 'development' ? (
            <ReactQueryDevtools initialIsOpen={false} />
          ) : null}
        </QueryClientProvider>
        {/* <UnderMaintenance /> */}
      </Bugsnag>
    </PersistGate>
  </Provider>
);

serviceWorker.register();
