import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import SvgRender from 'common/components/general/SvgRender';
import useRouter from 'use-react-router';

import bodymovin from 'lottie-web';
import arrow from 'assets/svg/common/arrow-404.svg';

const NotFound = () => {
  const { history } = useRouter();
  const lottieRef = useRef(null);

  useEffect(() => {
    const compass = document.getElementById('compass');
    const anim = bodymovin.loadAnimation({
      loop: true,
      container: compass,
      path: `/404.json`,
      renderer: 'svg' // Required
    });

    anim.addEventListener('DOMLoaded', () => {
      // Gets children of <defs> tag generated by the svg generator in lottie
      const svg = lottieRef.current.children[0];
      const animationDefChildren = Array.from(svg.children).filter(
        elem => elem.tagName === 'defs'
      )[0];

      // Gets the <filter ... /> elements responsible for the shadows
      const filters = Array.from(animationDefChildren.children).filter(
        filter => filter.tagName === 'filter'
      );

      // Sets the filterUnits attr of all the <filter ... /> elements so that the shadows work as intended
      filters.forEach(filter => {
        const filterInDom = ReactDOM.findDOMNode(filter);
        filterInDom.setAttribute('filterUnits', 'userSpaceOnUse');
      });
    });
  }, []);

  return (
    <div className="d-flex align-items-center justify-content-center flex-column p-2 not-found-wrapper w-100p">
      <div className="d-flex align-items-center justify-content-center mb-10">
        <div ref={lottieRef} id="compass" className="mx-2 mx-lg-6" />
      </div>

      <div className="fw-semibold fs-28 mb-5 gotham-400 text-primary">
        Something went wrong, we can't find your page.
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <div className="fw-semibold fs-28 me-2 gotham-400 text-primary">
          Let's get you home safely
        </div>
        <SvgRender
          src={arrow}
          style={{ height: 32, width: 32 }}
          className="animated-arrow cursor-pointer text-primary"
          onClick={() => history.push('/')}
        />
      </div>
    </div>
  );
};

export default NotFound;
