import React, { useCallback } from 'react';
import TreeHeader from 'common/components/tree-layout/main/Header';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectMenusTreeSearch,
  hasNestedContent,
  areAllMenusExpanded
} from 'common/components/beacon/store/selectors';
import { setTreeSearchAction, expandAllMenusAction } from 'common/components/beacon/store/slice';
import * as beaconActions from 'common/components/beacon/store/actions';

const Header = () => {
  const search = useSelector(selectMenusTreeSearch);
  const hasCollapsedContent = useSelector(hasNestedContent);
  const allExpanded = useSelector(areAllMenusExpanded);

  const [setTreeSearch, expandAllMenus, getBeaconMenus] = useActions([
    setTreeSearchAction,
    expandAllMenusAction,
    beaconActions.getBeaconMenus
  ]);

  const onSearchChange = useCallback(async s => {
    setTreeSearch(s);
    await getBeaconMenus({ search: s || undefined });
  }, []);

  const onSearchClear = useCallback(async () => {
    setTreeSearch('');
  }, []);

  return (
    <TreeHeader
      onSearchChange={onSearchChange}
      onSearchClear={onSearchClear}
      search={search}
      allExpanded={allExpanded}
      placeholder="Search menus"
      hasCollapsedContent={hasCollapsedContent}
      toggleCollapse={expandAllMenus}
    />
  );
};

export default Header;
