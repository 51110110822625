export const parseMainEngineReportData = data => {
  let results = [];

  data.forEach(group => {
    const { vessel, ...rest } = group;

    if (!results.find(v => v.isHeader && v.id === vessel?.id)) {
      results.push({ ...vessel, isHeader: true });
    }

    results.push(rest);
  });

  return results;
};
