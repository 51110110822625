import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import useRouter from 'use-react-router';

import arrow from 'assets/svg/common/arrow-404.svg';

const Unauthorized = () => {
  const { history } = useRouter();

  return (
    <div className="d-flex w-100p flex-column unauthorized-wrapper align-items-center justify-content-center">
      <div className="unauthorized-image" />
      <div className="d-flex align-items-center justify-content-center flex-column p-2 w-100p">
        <span className="unauthorized-text">You are not authorized to view this page.</span>
        <div className="d-flex mt-5 align-items-center justify-content-center">
          <span className="unauthorized-text">Let’s get you home safely</span>
          <SvgRender
            src={arrow}
            style={{ height: 32, width: 32 }}
            className="animated-arrow cursor-pointer unauthorized-arrow"
            onClick={() => history.push('/')}
          />
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
