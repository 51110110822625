import React from 'react';

import SvgRender from 'common/components/general/SvgRender';
import solidRibbon from 'common/assets/svg/common/ribbon-solid.svg';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import goToPage from 'common/assets/svg/actions/go-to-page.svg';
import PropTypes from 'prop-types';

const BookmarkLine = ({ bookmark, className }) => {
  return (
    <div
      className={`bookmark-line d-flex align-items-center cpy-6 cpx-12 height-28 cmb-4 ${className}`}
    >
      <SvgRender className="text-yellow me-1" src={solidRibbon} style={{ width: 11, height: 11 }} />

      <TextWithTooltip className="w-auto fs-12 text-primary fw-medium">
        {bookmark.title}
      </TextWithTooltip>

      <a href={bookmark.url} target="_blank" rel="noreferrer">
        <SvgRender className="text-primary ms-1" src={goToPage} style={{ width: 12, height: 12 }} />
      </a>
    </div>
  );
};

BookmarkLine.propTypes = {
  className: PropTypes.string,
  bookmark: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string
  })
};

export default BookmarkLine;
