import moment from 'moment';
import paths from 'routing/routes/_paths';

export const parseFixtureData = (data, params) => {
  const startDate = params.filters[0]?.value?.[0];
  const endDate = params.filters[0]?.value?.[1];

  return data.map(
    ({
      previous_charter_party,
      delivery_date,
      redelivery_date,
      estimated_earliest_redelivery_date,
      estimated_latest_redelivery_date,
      vessel,
      estimated_redelivery_date,
      ending_date,
      id,
      ...rest
    }) => {
      let events = [];

      if (previous_charter_party?.delivery_date || previous_charter_party?.redelivery_date) {
        events.push({
          start_date: previous_charter_party.delivery_date
            ? moment(previous_charter_party.delivery_date)
            : moment(startDate),
          end_date: previous_charter_party.redelivery_date
            ? moment(previous_charter_party.redelivery_date)
            : delivery_date
            ? moment(delivery_date)
            : moment(endDate),
          color: 'deep-sea'
        });
      }

      if (delivery_date || redelivery_date) {
        events.push({
          start_date: delivery_date ? moment(delivery_date) : moment(startDate),
          end_date: redelivery_date
            ? moment(redelivery_date)
            : estimated_redelivery_date
            ? moment(estimated_redelivery_date)
            : estimated_earliest_redelivery_date
            ? moment(estimated_earliest_redelivery_date)
            : ending_date
            ? moment(ending_date)
            : moment(endDate),
          color: 'dark-turquoise'
        });
      }

      if (estimated_earliest_redelivery_date || estimated_latest_redelivery_date) {
        events.push({
          start_date: estimated_earliest_redelivery_date
            ? moment(estimated_earliest_redelivery_date)
            : moment(startDate),
          end_date: estimated_latest_redelivery_date
            ? moment(estimated_latest_redelivery_date)
            : moment(endDate),
          color: 'lavender'
        });
      }

      if (vessel?.events.length > 0) {
        vessel.events.map(({ started_at, ended_at, type }) =>
          events.push({
            start_date: started_at ? moment(started_at) : null,
            end_date: ended_at ? moment(ended_at) : null,
            color: type.label === 'off_hire' ? 'coral' : 'orange-web'
          })
        );
      }

      return {
        previous_charter_party,
        vessel,
        delivery_date,
        redelivery_date,
        estimated_earliest_redelivery_date,
        estimated_latest_redelivery_date,
        events,
        onClick: params.history
          ? () => params.history.push(`${paths.voyages}/${vessel.id}/charter-parties/edit/${id}`)
          : null,
        ...rest
      };
    }
  );
};
