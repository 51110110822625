import { get } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getUnderwaterServicesFleetReportData = createAsyncThunk(
  'GET_UNDERWATER_SERVICES_FLEET_REPORT_DATA',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await get(`reports/underwater-services-fleet-report`, params);

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
