import { Button } from 'reactstrap';
import { useState, useEffect } from 'react';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Timer = ({ interval, countDown, setCountDown }) => {
  useEffect(() => {
    const timerInterval = setInterval(() => setCountDown(prev => prev - 1), 1000);

    return () => clearInterval(timerInterval);
  }, [setCountDown]);

  return (
    <Clock className="d-flex align-items-center" interval={interval}>
      <div className="d-flex align-items-center justify-content-center">
        <svg>
          <circle cx="50%" cy="50%" r="75" />
          <circle
            cx="50%"
            cy="50%"
            r="75"
            css={css`
              animation: ${clockAnimation} ${interval}s linear infinite;
            `}
          />
        </svg>
      </div>
      <div className="fs-10 fw-bold cms-4 text-gray-400">{countDown}’’</div>
    </Clock>
  );
};

Timer.propTypes = {
  interval: PropTypes.number.isRequired,
  countDown: PropTypes.number.isRequired,
  setCountDown: PropTypes.func.isRequired
};

const ResendCode = ({ interval, onResendCode }) => {
  const [countDown, setCountDown] = useState(interval);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setCountDown(interval);
  }, [interval]);

  const onResend = async () => {
    try {
      setIsSubmitting(true);

      await onResendCode().unwrap();

      setIsSubmitting(false);
      setCountDown(interval);
    } catch (data) {
      setIsSubmitting(false);
    }
  };

  return (
    <Container className="d-inline-flex align-items-center justify-content-center text-nowrap">
      <Button
        color="link"
        className="p-0 fw-medium fs-12"
        disabled={countDown > 0 || isSubmitting}
        onClick={onResend}
      >
        Resend code
      </Button>
      {countDown > 0 ? (
        <Timer interval={interval} countDown={countDown} setCountDown={setCountDown} />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: min-content;
`;

const clockAnimation = keyframes`
  0% {
    stroke-dashoffset: 471;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const Clock = styled.div`
  position: absolute;
  right: -3rem;

  > div {
    &:first-of-type {
      border-radius: 100%;
      border: 1px solid #cacaca;
      width: 20px;
      height: 20px;
      position: relative;
      overflow: hidden;
    }
  }

  svg {
    width: 150px;
    height: 150px;
    transform: scale(0.1);
    position: absolute;

    circle {
      fill: #cacaca;
      stroke-width: 150px;
      stroke-dasharray: 471;
      stroke-dashoffset: 471;
      transform: rotate(-90deg);
      transform-origin: center;

      + circle {
        fill: transparent;
        stroke: #fff;
      }
    }
  }
`;

ResendCode.propTypes = {
  interval: PropTypes.number.isRequired, // timer interval in seconds
  onResendCode: PropTypes.func.isRequired
};

export default ResendCode;
