import PropTypes from 'prop-types';
import { useState } from 'react';
import { useCallback } from 'react';
import { Button } from 'reactstrap';

import { useFormState } from 'utils/hooks';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { getCompanyEmployeesAction } from 'store/companies/actions';

import Drawer, { DrawerHeader, FormDrawer, FormFooter, FormBody } from 'common/components/drawer';
import DateInput from 'common/components/form/inputs/date';
import Input from 'common/components/form/inputs/Input';
import Select from 'common/components/form/inputs/Select';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import { useAppDispatch } from '@/store/hooks';

const Form = ({ drawer, active, onSave, formState, isTemplate, isSubmitting }) => {
  const [divers, setDivers] = useState([]);

  const dispatch = useAppDispatch();

  const { fields, selectField, changeField } = useFormState(formState);

  const getDivers = useCallback(
    async id => {
      if (id) {
        try {
          const employees = await dispatch(getCompanyEmployeesAction({ id }));

          setDivers(employees);
        } catch (error) {
          console.error(error);
          setDivers([]);
        }
      } else {
        setDivers([]);
      }
    },
    [getCompanyEmployeesAction]
  );

  const onCompanyChange = e => {
    selectField('company')(e);

    if (e?.id) {
      getDivers(e?.id);
    } else if (!e.id) {
      selectField('divers')(null);
      getDivers(null);
    }
  };

  return (
    <Drawer className="underwater-service-drawer" {...drawer}>
      <DrawerHeader>{active ? 'Edit' : 'Add'} Underwater Service</DrawerHeader>
      <FormDrawer>
        {drawer?.isOpen ? (
          <FormBody className={'py-2'}>
            <AsyncSelector
              dataCy="underwater-service-form-select"
              label="TYPE"
              onChange={selectField('underwater_service_type')}
              placeholder="Select Type"
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              className="mb-2"
              loadOptions={async search =>
                await getAsyncOptions(search, 'underwater-services-types')
              }
              defaultOptions={() => getInitialAsyncValues('underwater-services-types')}
              {...fields.underwater_service_type}
            />

            {!isTemplate && (
              <>
                <div>
                  <div className="d-flex">
                    <div className="form-label">LOCATION</div>{' '}
                    <div className="text-violet fs-10 lh-12 fw-bold cmb-4">&nbsp;If Shipyard</div>
                  </div>
                </div>
                <Input
                  type="text"
                  onChange={changeField('location')}
                  placeholder="Add location"
                  {...fields.location}
                />

                <DateInput
                  label="Date"
                  value={fields.date.value}
                  from={fields.date.value}
                  onChange={selected => selectField('date')(selected)}
                  {...fields.date}
                />

                <AsyncSelector
                  label="SERVICE COMPANY"
                  onChange={onCompanyChange}
                  placeholder="Select company"
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.full_name}
                  className="mb-2"
                  loadOptions={async search =>
                    await getAsyncOptions(search, 'parties', {
                      type: 'company',
                      role: 'underwater_services'
                    })
                  }
                  defaultOptions={() =>
                    getInitialAsyncValues('parties', null, false, {
                      type: 'company',
                      role: 'underwater_services'
                    })
                  }
                  value
                  {...fields.company}
                />
                <Select
                  label="DIVERS"
                  onChange={selectField('divers')}
                  placeholder="Select divers"
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.full_name}
                  className="mb-0"
                  isMulti
                  disabled={!fields.company.value?.id}
                  options={divers || []}
                  defaultOptionsTriggerChange={fields.company.value?.id}
                  {...fields.divers}
                />
              </>
            )}
          </FormBody>
        ) : null}
      </FormDrawer>
      <FormFooter>
        <Button color="cancel" className="px-0 py-1 me-4" onClick={drawer.close}>
          CANCEL
        </Button>
        <Button
          data-cy="underwater-service-form-save"
          onClick={onSave}
          color="primary"
          className="px-4"
          disabled={isSubmitting}
        >
          SAVE
        </Button>
      </FormFooter>
    </Drawer>
  );
};

Form.propTypes = {
  drawer: DrawerState,
  active: PropTypes.shape({
    id: PropTypes.number
  })
};

export default Form;
