import { useSelector, useDispatch } from 'react-redux';
import _get from 'lodash/get';
import { shape, number, string } from 'prop-types';

import SvgRender from 'common/components/general/SvgRender';
import arrow from 'common/assets/svg/common/arrows/arrow-filters.svg';

import { getNotifications } from 'store/notifications/actions';
import {
  selectNotificationsSummary,
  selectNotificationsIsSummaryFetching
} from 'store/notifications/selectors';
import { setActiveCategoryLabel } from 'store/notifications/actions';
import getIcon from 'components/layouts/sidebar/panel/notifications/_icons';

import Value from 'components/layouts/sidebar/panel/notifications/components/unread-tab/Value';
import { getSummaryTitlePerType } from './_helpers';
import Loader from './Loader';

const Item = ({ summary }) => {
  const dispatch = useDispatch();
  const unreadCount = _get(summary, 'unread_count');
  const total = _get(summary, 'total');
  const type = _get(summary, 'type_prefix');

  const title = getSummaryTitlePerType(type);
  const { icon } = getIcon(type);

  const onClick = async () => {
    if (!total) return;

    try {
      dispatch(setActiveCategoryLabel(type));
      await dispatch(getNotifications({ current_page: 1, type_prefix: type }));
    } catch (err) {
      console.error(err);
    }
  };

  if (!title) return;

  return (
    <div
      onClick={onClick}
      className={`single-notification d-flex align-items-center position-relative ${
        !total ? 'single-notification--disabled' : ''
      }`}
    >
      <div className="d-flex align-items-center justify-content-center border-radius-11 bg-light-gray max-height-40 min-height-40 max-width-40 w-100p">
        <SvgRender src={icon} style={{ width: 17, height: 17 }} />
      </div>

      <div className="d-flex flex-column ms-3 flex-1">
        <div className="text-capitalize fs-14 fw-medium">{title}</div>
        <div className="text-violet fw-medium fs-12">{total} notifications</div>
      </div>

      <div className="d-flex align-items-center me-3">
        <Value count={unreadCount} />

        <SvgRender
          src={arrow}
          className={`ms-3 ${!total ? 'text-violet' : 'text-dark'}`}
          style={{ width: 12, height: 12, transform: 'rotate(90deg)' }}
        />
      </div>
    </div>
  );
};

const Summary = () => {
  const notificationsSummary = useSelector(selectNotificationsSummary);
  const isFetching = useSelector(selectNotificationsIsSummaryFetching);

  return (
    <div className="notifications-container gray-scrollbar">
      {isFetching ? (
        <Loader />
      ) : (
        notificationsSummary?.map(summary => <Item key={summary?.type_prefix} summary={summary} />)
      )}
    </div>
  );
};

Item.propTypes = {
  summary: shape({
    read_count: number,
    total: number,
    type_prefix: string.isRequired
  })
};

export default Summary;
