import TYPES from './types';
import { get, put, post, deleteRequest } from 'utils/api';

/* RISK ASSESSMENT CATEGORY ACTIONS */

export const createRiskAssessmentCategory = params => dispatch => {
  dispatch({ type: TYPES.CREATE_RISK_ASSESSMENT_CATEGORY.START, payload: { params } });

  return post(`/risk-assessments/categories`, params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_RISK_ASSESSMENT_CATEGORY.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.CREATE_RISK_ASSESSMENT_CATEGORY.ERROR,
        payload: { response: error }
      });

      throw error;
    });
};

export const editRiskAssessmentCategory = params => dispatch => {
  const { id, ...rest } = params;

  dispatch({ type: TYPES.EDIT_RISK_ASSESSMENT_CATEGORY.START, payload: { rest } });

  return put(`/risk-assessments/categories/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.EDIT_RISK_ASSESSMENT_CATEGORY.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.EDIT_RISK_ASSESSMENT_CATEGORY.ERROR,
        payload: { response: error }
      });

      throw error;
    });
};

export const deleteRiskAssessmentCategory = id => dispatch => {
  dispatch({ type: TYPES.DELETE_RISK_ASSESSMENT_CATEGORY.START, payload: { id } });

  return deleteRequest(`/risk-assessments/categories/${id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_RISK_ASSESSMENT_CATEGORY.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.DELETE_RISK_ASSESSMENT_CATEGORY.ERROR,
        payload: { response: error }
      });

      throw error;
    });
};

/* RISK ASSESSMENT OPERATIONS ACTIONS */

export const getRiskAssessmentOperation = id => dispatch => {
  dispatch({ type: TYPES.GET_RISK_ASSESSMENT_OPERATION.START, payload: { id } });

  return get(`/risk-assessments/operations/${id}`)
    .then(response => {
      dispatch({ type: TYPES.GET_RISK_ASSESSMENT_OPERATION.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_RISK_ASSESSMENT_OPERATION.ERROR,
        payload: { response: error }
      });

      throw error;
    });
};

export const createRiskAssessmentOperation = params => dispatch => {
  dispatch({ type: TYPES.CREATE_RISK_ASSESSMENT_OPERATION.START, payload: { params } });

  return post(`/risk-assessments/operations`, params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_RISK_ASSESSMENT_OPERATION.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.CREATE_RISK_ASSESSMENT_OPERATION.ERROR,
        payload: { response: error }
      });

      throw error;
    });
};

export const editRiskAssessmentOperation = params => dispatch => {
  const { id, ...rest } = params;

  dispatch({ type: TYPES.EDIT_RISK_ASSESSMENT_OPERATION.START, payload: { rest } });

  return put(`/risk-assessments/operations/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.EDIT_RISK_ASSESSMENT_OPERATION.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.EDIT_RISK_ASSESSMENT_OPERATION.ERROR,
        payload: { response: error }
      });

      throw error;
    });
};

export const editRiskAssessmentOperationStatus = params => dispatch => {
  const { id, active } = params;

  dispatch({ type: TYPES.EDIT_RISK_ASSESSMENT_OPERATION_STATUS.START, payload: params });

  return put(`/risk-assessments/operations/${id}/${active ? 'activate' : 'deactivate'}`)
    .then(response => {
      dispatch({
        type: TYPES.EDIT_RISK_ASSESSMENT_OPERATION_STATUS.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.EDIT_RISK_ASSESSMENT_OPERATION_STATUS.ERROR,
        payload: { response: error }
      });

      throw error;
    });
};

export const deleteRiskAssessmentOperation = id => dispatch => {
  dispatch({ type: TYPES.DELETE_RISK_ASSESSMENT_OPERATION.START, payload: { id } });

  return deleteRequest(`/risk-assessments/operations/${id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_RISK_ASSESSMENT_OPERATION.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.DELETE_RISK_ASSESSMENT_OPERATION.ERROR,
        payload: { response: error }
      });

      throw error;
    });
};
