import { asyncTypes } from 'store/_helpers';

const config = {
  ...asyncTypes('CREATE', 'ESTIMATOR'),
  ...asyncTypes('FETCH', 'ESTIMATOR'),
  ...asyncTypes('CALCULATE', 'ESTIMATOR'),
  ...asyncTypes('FETCH_ROUTES', 'ESTIMATOR'),
  ...asyncTypes('FETCH_ECA_ZONES', 'ESTIMATOR'),
  ...asyncTypes('CHANGE_MARKET_INDEX', 'ESTIMATOR'),
  ...asyncTypes('UPDATE_SENSITIVITY_ANALYSIS', 'ESTIMATOR'),

  REORDER: 'ESTIMATOR_REORDER_PORTS',

  CREATE_NEW: 'ESTIMATOR_CREATE_NEW',
  START_EDITTING: 'ESTIMATOR_START_EDITTING',
  STOP_EDITTING: 'ESTIMATOR_STOP_EDITTING',

  CLEAR_ROUTES: 'ESTIMATOR_CLEAR_ROUTES',
  CLEAR_ECA_ZONES: 'ESTIMATOR_CLEAR_ECA_ZONES',

  SET_LOADING: 'ESTIMATOR_SET_LOADING',
  ERROR: 'ESTIMATOR_ERROR',

  SAVE_STATE: 'ESTIMATOR_SAVE_STATE',
  RESTORE_STATE: 'ESTIMATOR_RESTORE_STATE',

  CLEAR: 'ESTIMATOR_CLEAR'
};

export default config;
