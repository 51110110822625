import React from 'react';
import { Store } from 'common/entities/purchasing/PurchasingItemTypes';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import ColorViewer from 'common/components/general/ColorViewer';

const StoreDescription = ({ data }) => {
  return (
    <div className="d-flex flex-nowrap overflow-hidden">
      <div className="flex-1 overflow-hidden">
        <TextWithTooltip>
          {data?.description}
          {data?.color_name ? (
            <span className="fw-medium text-violet"> - {data.color_name}</span>
          ) : (
            ''
          )}
        </TextWithTooltip>
      </div>
      {data?.color_hex_code ? (
        <ColorViewer className="ps-2" color={`#${data?.color_hex_code}`} hideLabel />
      ) : null}
    </div>
  );
};

StoreDescription.propTypes = {
  data: Store
};

export default StoreDescription;
