import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import PropTypes from 'prop-types';
import paths from 'routing/routes/_paths';
import { useSelector } from 'react-redux';
import { selectWidgetState } from 'store/dashboards/selectors';

import TextWithTooltip from 'common/components/general/TextWithTooltip';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import isoIcon from 'assets/svg/contacts/iso-certified.svg';
import SvgRender from 'common/components/general/SvgRender';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import CompanyTypesLabel from '@/views/companies/profile/configs/components/CompanyTypesLabel.tsx';

const Companies = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));

  return (
    <div>
      <WidgetTitle id={id} linkTo={paths.companies} />
      <WidgetDefaultTable
        id={id}
        columns={[
          {
            header: 'Name',
            key: 'full_name',
            type: 'string',
            headerClassName: 'text-violet',
            bodyClassName: 'd-flex justify-content-start cmt-4',
            width: 3
          },
          {
            header: 'Type',
            key: 'company_type_id',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Email',
            key: 'email',
            type: 'string',
            headerClassName: 'text-violet',
            bodyClassName: 'd-flex justify-content-start cmt-4',
            width: 2
          },
          {
            header: 'Country',
            key: 'country_id',
            headerClassName: 'text-violet',
            bodyClassName: 'd-flex justify-content-start cmt-4',
            width: 2
          },
          {
            header: 'Telephone',
            key: 'phone',
            type: 'string',
            headerClassName: 'text-violet',
            bodyClassName: 'd-flex justify-content-start cmt-4',
            width: 2
          },
          {
            header: 'Rating',
            key: 'supplier_rating',
            headerClassName: 'text-violet',
            bodyClassName: 'd-flex justify-content-start cmt-4',
            width: 1
          },
          {
            header: 'Actions',
            key: 'actions',
            field: 'actions'
          }
        ]}
        rows={{
          full_name: data => (
            <div className="d-flex align-items-center">
              <TextWithTooltip className="flex-1">{data.company_name}</TextWithTooltip>

              {data?.is_iso_certified ? (
                <SvgRender src={isoIcon} className="cms-4" style={{ width: 12, height: 12 }} />
              ) : null}
            </div>
          ),
          email: data => (
            <TextWithTooltip className="d-flex align-items-center">
              {data.email || '-'}
            </TextWithTooltip>
          ),
          company_type_id: data => (
            <CompanyTypesLabel types={data?.company_types} subTypes={data?.company_subtypes} />
          ),
          vat_registration: data => data?.vat_registration || '-',
          country_id: data => (data.country ? data.country?.name : '-'),
          phone: data =>
            data?.phone || data?.extra_phones?.length ? (
              <NamesInTooltip
                target={`companies-phones-${data.id}`}
                names={[data.phone, ...data.extra_phones.map(phone => phone?.value)]
                  .filter(phone => !!phone)
                  .map(value => (
                    <TextWithTooltip>{value}</TextWithTooltip>
                  ))}
              />
            ) : (
              '-'
            ),
          supplier_rating: data =>
            data?.supplier_rating?.combined_rating ||
            data?.agent_rating?.combined_rating ||
            data?.charterer_rating?.rating ||
            '-',
          actions: data => ({
            options: [
              {
                label: 'View',
                icon: eyeSvg,
                onClick: () => window.open(`${paths.companies}/${data.id}`)
              }
            ]
          })
        }}
        label={label}
        state={state}
      />
    </div>
  );
};

Companies.propTypes = {
  id: PropTypes.number
};

export default Companies;
