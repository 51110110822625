import { useEffect, useState } from 'react';
import Input from 'common/components/form/inputs/Input';
import useRouter from 'use-react-router';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm, useFormState } from 'utils/hooks';
import { stringField } from 'common/utils/form/fieldTypes';

import { stringifyObj } from 'utils/urls';
import { parseQueryParams } from '@/ts-common/utils/urls';
import { useNotification } from 'utils/hooks';
import { useActions } from 'utils/hooks';

import * as tablesActions from 'store/tables/actions';
import { createOnboardSetupFilterPreset } from '@/api/setup-onboard/api.ts';

const SaveSearchModal = ({ modalOpened, setModalOpened, label, isSetupOnboard }) => {
  const { history, location } = useRouter();
  const errorNotification = useNotification('error');
  const [createTableSearch] = useActions([tablesActions.createTableSearch]);
  const [isSubmitting, setSubmitting] = useState(false);

  const { formState, collectValues, resetForm } = useForm({
    name: stringField({ required: true })
  });
  const { fields, changeField } = useFormState(formState);

  useEffect(() => {
    if (modalOpened) resetForm();
  }, [modalOpened]);

  const handleSubmit = async () => {
    const values = collectValues();

    if (!values) return;

    setSubmitting(true);

    const valid_params = [
      'table_label',
      'name',
      'visible',
      'filters',
      'sorting',
      'page_size',
      'entity_type',
      'compact',
      'sizing'
    ];
    const { searchId, searchText, search, ...rest } = parseQueryParams(history.location.search);
    const labelParam = isSetupOnboard
      ? {
          entity_type: label
        }
      : { table_label: label };

    const params = {
      ...rest,
      ...values,
      ...labelParam
    };

    if (Object.keys(params).filter(key => valid_params.indexOf(key) === -1).length) {
      errorNotification({
        title: 'Error',
        message: 'Invalid data'
      });

      setSubmitting(false);
    } else {
      let res = null;

      if (isSetupOnboard) {
        res = await createOnboardSetupFilterPreset(params);
      } else {
        res = await createTableSearch(params);
      }
      setSubmitting(false);

      if (res) {
        resetForm();
        setModalOpened(false);
        history.push({
          pathname: location.pathname,
          search: `${stringifyObj({ ...rest })}&searchId=${res.id}`
        });
      }
    }
  };

  return (
    <Modal centered isOpen={modalOpened} toggle={() => setModalOpened(!modalOpened)}>
      <ModalHeader toggle={() => setModalOpened(!modalOpened)}>Save as</ModalHeader>

      <ModalBody>
        <Input name="name" label="Name*" onChange={changeField('name')} {...fields.name} />
      </ModalBody>
      <ModalFooter>
        <Button color="cancel" onClick={() => setModalOpened(false)}>
          CANCEL
        </Button>
        <Button color="primary" disabled={isSubmitting} type="submit" onClick={handleSubmit}>
          SAVE
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SaveSearchModal;
