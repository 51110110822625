import { createSelector } from 'reselect';

const selectAccountReducer = state => state.account;

export const selectAccount = createSelector(selectAccountReducer, account => account);

export const selectAccountId = createSelector(selectAccountReducer, account => account.id);

export const selectAccountPermissions = createSelector(
  selectAccount,
  account => account?.permissions
);

export const selectAccountFullname = createSelector(
  selectAccountReducer,
  account => account.full_name || account.name
);

export const selectOrganizationType = createSelector(selectAccountReducer, account =>
  account.organization && account.organization.type ? account.organization.type : null
);

export const selectAccountRank = createSelector(
  selectAccountReducer,
  account => account?.crew_member?.active_contract?.rank
);

export const selectAccountDepartments = createSelector(
  selectAccountReducer,
  account => account?.departments || []
);

export const selectAccountDepartmentRoles = createSelector(
  selectAccountReducer,
  account => account?.departments_roles || []
);

// Notification Preferences
export const selectAccountNotificationPreferences = createSelector(
  selectAccountReducer,
  account => account?.notification_preferences || []
);

export const selectIsSavingAccountNotificationPreferences = createSelector(
  selectAccountReducer,
  account => account?.is_saving_notification_preferences
);

export const selectIsOceanAdmin = state => selectAccount(state)?.is_ocean_admin;
