import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_CREW_ROTATION'),
  ...asyncTypes('CREATE_CREW_ROTATION'),
  ...asyncTypes('UPDATE_CREW_ROTATION'),
  ...asyncTypes('CREATE_CREW_ROTATION_TICKET'),
  ...asyncTypes('UPDATE_CREW_ROTATION_TICKET'),
  ...asyncTypes('DELETE_CREW_ROTATION_TICKET'),
  ...asyncTypes('GET_CREW_ROTATION_TICKETS'),
  ...asyncTypes('ACCEPT_CREW_ROTATION_TICKET'),
  ...asyncTypes('FINALIZE_CREW_ROTATION'),
  ...asyncTypes('CHECK_IF_ROTATION_CAN_BE_FINALIZED'),
  ...asyncTypes('SETTLE_TICKETING'),
  PREVIEW_ROTATION_TICKETS: 'PREVIEW_ROTATION_TICKETS',
  SET_OFFER_FORM_TICKET_USAGE: 'SET_OFFER_FORM_TICKET_USAGE',
  SET_OFFER_FORM_ADDING_NEW: 'SET_OFFER_FORM_ADDING_NEW',
  RESET_SELECTED_ROTATION: 'RESET_SELECTED_ROTATION',
  TRIGGER_ALERT_MODAL: 'TRIGGER_ALERT_MODAL',
  TRIGGER_ON_SIGNER_CONTRACT_MODAL: 'TRIGGER_ON_SIGNER_CONTRACT_MODAL',
  SET_ON_SIGNER_FORM_ACTIVE: 'SET_ON_SIGNER_FORM_ACTIVE',
  TRIGGER_OFF_SIGNER_CONTRACT_MODAL: 'TRIGGER_OFF_SIGNER_CONTRACT_MODAL',
  SET_CREW_PLANNING_PREVENTION_MODAL: 'SET_CREW_PLANNING_PREVENTION_MODAL',
  SET_SELECTED_TAB: 'SET_SELECTED_TAB',
  TRIGGER_CREATE_EVENT_MODAL: 'TRIGGER_CREATE_EVENT_MODAL',
  ENABLE_BULK_SELECT_ROTATIONS: 'ENABLE_BULK_SELECT_ROTATIONS'
};

export default TYPES;
