import PropTypes from 'prop-types';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import TableTopFilter from 'common/components/table/TableTopFilter';
import SelectLoaderIndicator from 'common/components/form/helpers/SelectLoaderIndicator';
import { DropdownIndicator } from 'common/components/form/inputs/Select';

import { useCallback } from 'react';
import { refetchAsyncOptions } from 'utils/helpers';
import { createLink } from 'components/layouts/sidebar/panel/helpers';
import { selectSearch, selectSearchLoading } from 'store/tables/selectors';
import { selectHasTableFilters } from '@/common/components/table/store/selector-ts.ts';

import useRouter from 'use-react-router';
import { setTablePageSearch } from 'common/components/table/store/actions';
import { setHasSavedTableFilters } from '@/store/tables/actions-ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getOnboardSetupFilterPresets } from '@/api/setup-onboard/api.ts';

const SavedSearchesSelector = ({ label, isSetupOnboard }) => {
  const { history, location } = useRouter();
  const savedSearch = useAppSelector(selectSearch);
  const isLoading = useAppSelector(selectSearchLoading);
  const hasSavedTableFilters = useAppSelector(selectHasTableFilters);
  const dispatch = useAppDispatch();

  const getOptions = useCallback(
    async search => {
      const params = { current_page: 1, per_page: 20, types: [label] };
      const onboardSetupParams = {
        filters: [{ name: 'entity_type', operation: '=', value: label }]
      };
      let res = null;

      if (search) {
        if (isSetupOnboard) {
          res = await getOnboardSetupFilterPresets({ ...params, search });
        } else {
          res = await refetchAsyncOptions(
            'saved-table-filters',
            { ...onboardSetupParams, search },
            false
          );
        }
        const data = res?.data || [];

        return data;
      } else {
        if (isSetupOnboard) {
          res = await getOnboardSetupFilterPresets(onboardSetupParams);
        } else {
          res = await refetchAsyncOptions('saved-table-filters', params, false);
        }
        const data = (isSetupOnboard ? res : res?.data) || [];

        if (data?.length) {
          dispatch(setHasSavedTableFilters(true));
        } else {
          dispatch(setHasSavedTableFilters(false));
        }

        return data;
      }
    },
    [dispatch, isSetupOnboard, label]
  );

  const onChange = useCallback(
    item => {
      if (!item) {
        history.replace(location.pathname);
      } else {
        const parseItem = isSetupOnboard ? { item, item_type: 'table' } : item;
        const location = createLink(parseItem, true, isSetupOnboard);
        if (item?.type) {
          dispatch(setTablePageSearch({ text: '', table: item.type }));
        }
        history.replace(location);
      }
    },
    [dispatch, history, isSetupOnboard, location.pathname]
  );

  const getSelectedValue = useCallback(search => {
    // Parse the savedSearch to be in the same format as the listing options
    if (search) {
      const { item, ...rest } = search;

      return {
        ...item,
        item: { ...rest }
      };
    }

    return null;
  }, []);

  return (
    <div className={`ps-3 ms-3 border-start ${!hasSavedTableFilters ? 'd-none' : ''}`}>
      <TableTopFilter>
        <AsyncSelector
          placeholder="Select saved filter"
          getOptionValue={option => option.id}
          getOptionLabel={option => (isSetupOnboard ? option.name : option?.item?.name)}
          isAsync
          isClearable
          value={isSetupOnboard ? savedSearch : getSelectedValue(savedSearch)}
          autoFocues={false}
          defaultOptions={getOptions}
          loadOptions={getOptions}
          onChange={onChange}
          defaultOptionsTriggerChange={savedSearch}
          size="lg"
          key={label}
          hideSelectedOptions
          components={{
            DropdownIndicator: isLoading ? SelectLoaderIndicator : DropdownIndicator
          }}
        />
      </TableTopFilter>
    </div>
  );
};

SavedSearchesSelector.propTypes = {
  label: PropTypes.string.isRequired // The table label
};

export default SavedSearchesSelector;
