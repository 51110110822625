import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('CREATE_PROJECT'),
  ...asyncTypes('GET_PROJECTS'),
  ...asyncTypes('EDIT_PROJECT'),
  ...asyncTypes('ARCHIVE_PROJECT'),
  ...asyncTypes('UNARCHIVE_PROJECT'),
  ...asyncTypes('GET_PROJECT'),
  SET_PROJECT: 'SET_PROJECT',
  SET_PROJECT_VALUE: 'SET_PROJECT_VALUE',
  ADD_SUB_PROJECT: 'ADD_SUB_PROJECT',
  REMOVE_SUB_PROJECT: 'REMOVE_SUB_PROJECT',
  SET_SUB_PROJECT_VALUE: 'SET_SUB_PROJECT_VALUE',
  SET_PROJECT_ERRORS: 'SET_PROJECT_ERRORS',
  RESET_PROJECT_STATE: 'RESET_PROJECT_STATE'
};

export default TYPES;
