import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import avatar from 'common/assets/svg/common/crew_planning.svg';
import company from 'common/assets/svg/common/company.svg';

const WidgetPersonType = ({ type, className = '' }) => {
  return (
    <SvgRender
      src={type === 'crew' ? avatar : company}
      style={{ width: 14, height: 14 }}
      className={className}
    />
  );
};

export default WidgetPersonType;
