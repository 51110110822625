import TYPES from './types';

const INITIAL_STATE = {
  chartData: [],
  data: [],
  paging: {
    total: 0,
    per_page: 20,
    current_page: 1,
    last_page: 1
  }
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_SAVED_MARKET_INDEXES.START:
      const { sorting } = payload.params;

      return {
        ...state,
        paging: {
          ...state.paging,
          current_page: payload.params.current_page
        },
        sorting: sorting ? sorting : state.sorting
      };

    case TYPES.GET_SAVED_MARKET_INDEXES.SUCCESS:
      const { meta, data } = payload;

      return {
        ...state,
        data: state.paging.current_page === 1 ? data : [...state.data, ...data],
        paging: meta
      };

    default:
      return state;
  }
};

export default reducer;
