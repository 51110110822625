import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import PropTypes from 'prop-types';
import paths from 'routing/routes/_paths';
import { useSelector } from 'react-redux';
import { selectWidgetState } from 'store/dashboards/selectors';

import TextWithTooltip from 'common/components/general/TextWithTooltip';
import { displaySparePart } from 'common/utils/form/display';
import { numberToStr } from 'common/utils/numbers';
import { displayDate } from 'common/utils/dates';
import SvgRender from 'common/components/general/SvgRender';
import burger from 'common/assets/svg/common/chapter.svg';

const InventorySpareParts = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));

  return (
    <div>
      <WidgetTitle id={id} linkTo={paths.pms_inventory} />
      <WidgetDefaultTable
        id={id}
        columns={[
          {
            header: 'SPARE PART',
            type: 'string',
            key: 'items',
            headerClassName: 'text-violet',
            width: 5
          },
          {
            header: 'OPTIMAL/MIN QUANTITY',
            key: 'minimum_required_rob',
            type: 'string',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'ROB',
            key: 'rob',
            type: 'string',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'LAST SURVEY ROB',
            key: 'last_survey_rob',
            type: 'string',
            headerClassName: 'text-violet'
          },
          {
            header: 'Last Survey Date',
            key: 'last_survey_timestamp',
            headerClassName: 'text-violet',
            type: 'date'
          }
        ]}
        rows={{
          items: data => (
            <TextWithTooltip className="h-auto">
              {displaySparePart(data?.item)}
              <span className="text-violet fs-10 cme-6 cms-6">
                |
                <SvgRender
                  src={burger}
                  style={{ width: 8, height: 8 }}
                  className={'cme-6 cms-6 text-violet'}
                />
                {data.item?.vessel_systems?.[0]?.description}
              </span>
            </TextWithTooltip>
          ),
          rob: data => {
            const item = data?.item;
            const optimalRob = item?.optimal_rob;
            const minimumRob = item?.minimum_required_rob;
            const isOfficial = item?.is_rob_requirement_official;

            const colorTextCase = optimalRob && minimumRob && !isOfficial;
            return (
              <div className={`${colorTextCase ? 'text-warning' : ''}`}>
                {numberToStr(data?.calculated_rob)}
              </div>
            );
          },
          minimum_required_rob: data => {
            const item = data?.item;

            const optimalRob = item?.optimal_rob;
            const minimumRob = item?.minimum_required_rob;
            const isOfficial = item?.is_rob_requirement_official;
            const officialText = isOfficial ? 'Official' : 'Company';

            return optimalRob && minimumRob ? (
              <TextWithTooltip>
                {numberToStr(optimalRob)}/{numberToStr(minimumRob)}
                {minimumRob ? (
                  <span className={`text-truncate fw-medium ${isOfficial ? '' : 'text-warning'}`}>
                    <span className="px-1">|</span>
                    {officialText}
                  </span>
                ) : null}
              </TextWithTooltip>
            ) : (
              '-'
            );
          },
          last_survey_rob: data => data?.last_survey_rob || '-',
          last_survey_timestamp: data =>
            displayDate(data?.last_survey_timestamp, { time: true }) || '-'
        }}
        label={label}
        state={state}
      />
    </div>
  );
};

InventorySpareParts.propTypes = {
  id: PropTypes.number
};

export default InventorySpareParts;
