import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import Priority from 'common/components/general/Priority';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import SvgRender from 'common/components/general/SvgRender';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import Status from 'common/components/purchasing/requisition/components/Status';
import { Link } from 'react-router-dom';

import paths from 'routing/routes/_paths';
import { useSelector } from 'react-redux';
import { selectWidgetState } from 'store/dashboards/selectors';
import eyeSvg from 'common/assets/svg/common/eye.svg';

import vesselIcon from 'common/assets/svg/common/vessels.svg';
import companyIcon from 'common/assets/svg/common/company.svg';

import _get from 'lodash/get';
import { Row, Col } from 'reactstrap';
import AuthCheck from '@/components/permissions/AuthCheck';
import permissions from '@/common/utils/permissions/constants';

const Requisitions = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));

  return (
    <div>
      <WidgetTitle id={id} linkTo={paths.purchasing_requisitions} />
      <WidgetDefaultTable
        id={id}
        columns={[
          {
            header: 'Description',
            key: 'description',
            width: 5,
            headerClassName: 'text-violet'
          },
          { header: 'Vessel/Company', key: 'vessel', headerClassName: 'text-violet', width: 2 },
          {
            header: 'Category',
            key: 'category_id',
            type: 'collection',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Submitted At',
            key: 'submitted_at',
            type: 'date',
            headerClassName: 'text-violet'
          },
          {
            header: 'Status',
            key: 'status',
            headerClassName: 'text-violet',
            minWidth: 217,
            maxWidth: 217
          },
          {
            header: 'Actions',
            key: 'actions',
            field: 'actions'
          }
        ]}
        rows={{
          vessel: data => (
            <TextWithTooltip>
              {data.is_for_vessel
                ? `${_get(data, 'vessel.name', '-')}`
                : `${_get(data, 'company.name', '-')}`}
            </TextWithTooltip>
          ),
          description: data => (
            <Row className="flex-nowrap">
              <Col xs="auto" className="min-width-32 max-width-32 cps-2">
                <Priority value={data.priority?.label} />
              </Col>
              <Col xs="2">
                <AuthCheck
                  permissions={[permissions.office.purchasing.requisitions.edit]}
                  unAuthorizedRender={
                    <TextWithTooltip className="d-flex align-items-center">
                      {data.code}
                    </TextWithTooltip>
                  }
                >
                  <Link to={`${paths.purchasing_requisitions}/${data.id}`}>
                    <TextWithTooltip className="d-flex align-items-center">
                      {data.code}
                    </TextWithTooltip>
                  </Link>
                </AuthCheck>
              </Col>
              <Col xs="9">
                <AuthCheck
                  permissions={[permissions.office.purchasing.requisitions.edit]}
                  unAuthorizedRender={
                    <TextWithTooltip className="d-flex align-items-center">
                      {data.description}
                    </TextWithTooltip>
                  }
                >
                  <Link to={`${paths.purchasing_requisitions}/${data.id}`}>
                    <TextWithTooltip className="d-flex align-items-center">
                      {data.description}
                    </TextWithTooltip>
                  </Link>
                </AuthCheck>
              </Col>
              <Col>
                <SvgRender
                  src={!data.is_for_vessel ? companyIcon : vesselIcon}
                  style={{ width: 12, height: 12 }}
                  className={`me-1 cmb-2 ${
                    !data.is_for_vessel ? 'text-moody-blue' : 'text-primary'
                  }`}
                />
              </Col>
            </Row>
          ),
          category_id: data => (
            <NamesInTooltip
              target={`requisition-categories-${data.id}`}
              names={data.categories.map(category => category.name)}
            />
          ),
          status: data => <Status status={data?.status} isBullet />,
          actions: data => ({
            options: [
              {
                label: 'View',
                icon: eyeSvg,
                onClick: () => window.open(`${paths.purchasing_requisitions}/${data.id}`),
                permissions: [permissions.office.purchasing.requisitions.edit]
              }
            ]
          })
        }}
        label={label}
        state={state}
      />
    </div>
  );
};

export default Requisitions;
