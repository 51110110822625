import TYPES from './types';
import PURCHASING_SETUP_TYPES from 'common/components/purchasing/library-setup/store/types';

import { normalizeTreeStructure } from 'common/components/tree-layout/utils/helpers';

const INITIAL_STATE = {
  isLoading: false,
  inventoryDepletionMethod: null,

  rows: [], // [{ id, type }] - table rows of items & groups

  groups: {}, // { GROUP_ID_1: { ... }, GROUP_ID_2: { ... } }
  items: {}, // { ITEM_ID_1: { ... }, ITEM_ID_2: { ... } }

  accountForm: {
    isOpen: false,
    group: null,
    account: null
  }
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.GET_MGA_ITEMS.START:
      return { ...state, isLoading: true };

    case TYPES.GET_MGA_ITEMS.SUCCESS:
      const updatedState = payload.data.reduce(
        (acc, cur) => {
          const { stores, ...group } = cur;

          acc.rows.push({ id: group.id, type: 'group' });
          acc.rows = [...acc.rows, ...stores.map(store => ({ id: store.id, type: 'item' }))];

          acc.groups[group.id] = group;
          acc.items = { ...acc.items, ...normalizeTreeStructure(stores) };

          return acc;
        },
        {
          rows: [],
          groups: {},
          items: {}
        }
      );

      return {
        ...state,
        isLoading: false,
        ...updatedState
      };

    case TYPES.GET_MGA_SETTINGS.SUCCESS:
      return {
        ...state,
        inventoryDepletionMethod: payload?.data?.mga_inventory_depletion_method
      };

    case TYPES.GET_MGA_ITEMS.ERROR:
      return { ...state, isLoading: false, rows: [], groups: {}, items: {} };

    case TYPES.SET_CONNECTED_ACCOUNT_FORM:
      return {
        ...state,
        accountForm: { ...payload }
      };

    case PURCHASING_SETUP_TYPES.CONNECT_PURCHASING_GROUP_TO_MGA_ACCOUNT.SUCCESS:
      return {
        ...state,
        groups: {
          ...state.groups,
          [payload.id]: { ...state.groups[payload.id], mga_account_id: payload.mga_account_id }
        }
      };

    default:
      return state;
  }
};

export default reducer;
