import { performanceReportPrefilledOptions } from 'common/utils/prefilled-lists';

const getPrefilledOptions = key => {
  switch (key) {
    case 'performance':
      return performanceReportPrefilledOptions;

    default:
      return [];
  }
};

export const displayReportDocumentTitle = (path, generalReportCategoryPath, state, listKey) => {
  if (path === generalReportCategoryPath) return '';

  const newPath = path.substring(generalReportCategoryPath.length + 1); // Remove the generalReportCategoryPath part and the following '/' from the path
  const parts = newPath.split('/'); // Split the remaining path by '/'
  const options = [
    ...(state?.lists?.[`reports-${listKey}`]?.options || []),
    ...getPrefilledOptions(listKey)
  ];

  //show title based on state report lists
  const title =
    options?.find(option => option?.label === parts[0])?.name ||
    parts[0] // if no list options show title based on path
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  return `| ${title}`;
};
