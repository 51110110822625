import React from 'react';
import PropTypes from 'prop-types';

import { numberToStr } from 'common/utils/numbers';
import { Row, Col } from 'reactstrap';
import _get from 'lodash/get';

const FooterRow = ({ data, rowName, isAverage = false }) => {
  const fontWeight = isAverage ? 'fw-bold' : '';

  return (
    <Row
      className={`d-flex flex-nowrap align-items-center fs-12 cpy-12 ${
        !isAverage ? 'bg-white border-radius-5' : ''
      }`}
    >
      <Col xs={2} className="text-violet fw-bold ps-2 cms-4">
        {rowName}
      </Col>
      <Col>
        <Row>
          <Col className={`d-flex align-items-center justify-content-center ${fontWeight} `}>
            {numberToStr(data.market_tce_gross, 2, 2)}
          </Col>
          <Col className={`d-flex align-items-center justify-content-center ${fontWeight}`}>
            {numberToStr(data.market_tce_net, 2, 2)}
          </Col>
          <Col className={`d-flex align-items-center justify-content-center ${fontWeight}`}>
            {numberToStr(data.market_tce_adjusted_net, 2, 2)}
          </Col>
        </Row>
      </Col>
      <Col xs={2} className={`d-flex align-items-center justify-content-end pe-2 ${fontWeight}`}>
        <span className="cpe-4">{numberToStr(data.tce_net, 2, 2)}</span>
      </Col>
      {isAverage ? (
        <Col xs={3} className={`d-flex align-items-center justify-content-center fw-bold`}>
          <Row noGutters className="d-flex align-items-center justify-content-end p-0 w-100p">
            <Col className="d-flex justify-content-end pe-2" xs={7}>
              <span
                className={`cpe-2 ${
                  +_get(data, 'difference', 0) < 0 ? 'text-coral' : 'text-turquoise'
                }`}
              >
                {numberToStr(data.difference, 2, 2)}
              </span>
            </Col>
            <Col
              className={`d-flex justify-content-end pe-2 ${
                +_get(data, 'difference_percent', 0) < 0 ? 'text-coral' : 'text-turquoise'
              }`}
              xs={5}
            >
              {numberToStr(data.difference_percent, 2, 2)}%
            </Col>
          </Row>
        </Col>
      ) : (
        <Col xs={3} className={`d-flex align-items-center justify-content-center`} />
      )}
    </Row>
  );
};

export default FooterRow;

FooterRow.propTypes = {
  rowName: PropTypes.string.isRequired,

  isAverage: PropTypes.bool,

  data: PropTypes.shape({
    commission: PropTypes.oneOf(PropTypes.string, PropTypes.number),
    date_from: PropTypes.string,
    date_to: PropTypes.string,
    difference: PropTypes.oneOf(PropTypes.string, PropTypes.number),
    difference_percent: PropTypes.oneOf(PropTypes.string, PropTypes.number),
    estimated_tce_net: PropTypes.oneOf(PropTypes.string, PropTypes.number),
    key: PropTypes.string.isRequired,
    key_type: PropTypes.string.isRequired,
    market_tce_adjusted_net: PropTypes.oneOf(PropTypes.string, PropTypes.number),
    market_tce_gross: PropTypes.oneOf(PropTypes.string, PropTypes.number),
    market_tce_net: PropTypes.oneOf(PropTypes.string, PropTypes.number),
    tce_net: PropTypes.oneOf(PropTypes.string, PropTypes.number)
  })
};
