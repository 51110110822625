import TYPES from './types';
import { get, post } from 'utils/api';

export const getNotifications = params => async dispatch => {
  try {
    const { type, ...rest } = params;

    dispatch({ type: TYPES.GET_NOTIFICATIONS.START, payload: { type } });

    const response = await get('/account/notifications', rest);
    const { data } = response;

    const payload = {
      data: data.data,
      last_page: data.last_page,
      current_page: data.current_page,
      total: data.total,
      type
    };

    dispatch({ type: TYPES.GET_NOTIFICATIONS.SUCCESS, payload });
    return payload;
  } catch (error) {
    dispatch({ type: TYPES.GET_NOTIFICATIONS.ERROR, payload: error });
  }
};

export const getNotificationsCount = params => async dispatch => {
  try {
    dispatch({ type: TYPES.GET_NOTIFICATIONS_COUNT.START, payload: { params } });

    const response = await get('/account/notifications/count', params);

    dispatch({ type: TYPES.GET_NOTIFICATIONS_COUNT.SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: TYPES.GET_NOTIFICATIONS_COUNT.ERROR, payload: error });
  }
};

export const markNotificationAsSeen = params => async dispatch => {
  try {
    dispatch({ type: TYPES.MARK_NOTIFICATIONS_AS_SEEN.START });

    await post(`/account/notifications/seen`, params);

    dispatch({ type: TYPES.MARK_NOTIFICATIONS_AS_SEEN.SUCCESS });
  } catch (error) {
    dispatch({ type: TYPES.MARK_NOTIFICATIONS_AS_SEEN.ERROR, payload: error });
  }
};

export const getNotificationsSummary = params => async dispatch => {
  try {
    dispatch({ type: TYPES.GET_NOTIFICATIONS_SUMMARY.START });

    const response = await get(`/account/notifications/summary`, params);

    dispatch({ type: TYPES.GET_NOTIFICATIONS_SUMMARY.SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: TYPES.GET_NOTIFICATIONS_SUMMARY.ERROR, payload: error });
  }
};

export const markNotificationAsRead = params => async dispatch => {
  try {
    dispatch({ type: TYPES.MARK_NOTIFICATION_AS_READ.START, payload: { ...params } });

    await post(`/account/notifications/mark-as-read`, params);

    dispatch({ type: TYPES.MARK_NOTIFICATION_AS_READ.SUCCESS, payload: params });
  } catch (error) {
    dispatch({ type: TYPES.MARK_NOTIFICATION_AS_READ.ERROR, payload: error });
  }
};

export const reduceUnreadCountbyType = type => async dispatch => {
  dispatch({ type: TYPES.REDUCE_UNREAD_COUNTS_BY_TYPE, payload: type });
};

export const resetUnreadCount = type => async dispatch => {
  dispatch({ type: TYPES.RESET_UNREAD_COUNT, payload: type });
};

export const setActiveCategoryLabel = label => async dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE_CATEGORY_LABEL, payload: label });
};

export const receiveSocketNotification = notifaction => dispatch => {
  dispatch({ type: TYPES.RECEIVE_SOCKET_NOTIFICATION, payload: notifaction });
};

export const setIsUnreadTabActive = isUnreadTabActive => dispatch => {
  dispatch({ type: TYPES.SET_IS_UNREAD_TAB_ACTIVE, payload: isUnreadTabActive });
};
