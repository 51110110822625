import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = () => (
  <ContentLoader
    speed={1}
    viewBox="0 0 1000 1000"
    backgroundColor="#f5f5f5"
    foregroundColor="#ebebeb"
    animate={true}
  >
    <rect x="20" y="100" rx="35" ry="35" width="40" height="40" />
    <rect x="100" y="40" rx="10" ry="10" width="350" height="30" />
    <rect x="100" y="100" rx="10" ry="10" width="500" height="25" />
    <rect x="100" y="155" rx="10" ry="10" width="100" height="20" />
    <rect x="900" y="155" rx="10" ry="10" width="70" height="20" />
    <rect x="20" y="190" rx="3" ry="3" width="960" height="5" />

    <rect x="20" y="270" rx="35" ry="35" width="40" height="40" />
    <rect x="100" y="210" rx="10" ry="10" width="350" height="30" />
    <rect x="100" y="270" rx="10" ry="10" width="500" height="25" />
    <rect x="100" y="325" rx="10" ry="10" width="100" height="20" />
    <rect x="900" y="325" rx="10" ry="10" width="70" height="20" />
    <rect x="20" y="360" rx="3" ry="3" width="960" height="5" />

    <rect x="20" y="440" rx="35" ry="35" width="40" height="40" />
    <rect x="100" y="380" rx="10" ry="10" width="350" height="30" />
    <rect x="100" y="440" rx="10" ry="10" width="500" height="25" />
    <rect x="100" y="495" rx="10" ry="10" width="100" height="20" />
    <rect x="900" y="495" rx="10" ry="10" width="70" height="20" />
    <rect x="20" y="530" rx="3" ry="3" width="960" height="5" />

    <rect x="20" y="610" rx="35" ry="35" width="40" height="40" />
    <rect x="100" y="550" rx="10" ry="10" width="350" height="30" />
    <rect x="100" y="610" rx="10" ry="10" width="500" height="25" />
    <rect x="100" y="665" rx="10" ry="10" width="100" height="20" />
    <rect x="900" y="665" rx="10" ry="10" width="70" height="20" />
    <rect x="20" y="700" rx="3" ry="3" width="960" height="5" />
  </ContentLoader>
);

export default Loader;
