import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FormDrawer, FormBody, FormFooter } from 'common/components/drawer';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectWidgetCustomTitle } from 'store/dashboards/selectors';
import { useActions } from 'utils/hooks';

import deleteSVG from 'common/assets/svg/actions/delete.svg';
import Input from 'common/components/form/inputs/Input';
import SvgRender from 'common/components/general/SvgRender';

import * as dashboardActions from 'store/dashboards/actions';

const WidgetSettings = ({ id, children, onSave, onClose, isSubmitting }) => {
  const [setWidgetPreventionModal] = useActions([dashboardActions.setWidgetPreventionModal]);

  const widgetCustomTitle = useSelector(state => selectWidgetCustomTitle(state, id));

  const [customTitle, setCustomTitle] = useState('');

  useEffect(() => {
    if (id) {
      setCustomTitle(widgetCustomTitle || '');
    }
  }, [id, widgetCustomTitle]);

  return (
    <FormDrawer>
      <FormBody className="pb-4">
        <div className={children ? 'pb-3 mb-3 border-bottom' : ''}>
          <Input
            placeholder="Add name"
            label="CUSTOM TITLE"
            onChange={e => setCustomTitle(e.target.value)}
            value={customTitle}
            className="mb-0"
          />
        </div>
        {children}
      </FormBody>
      <FormFooter>
        <Button
          onClick={() => setWidgetPreventionModal({ isOpen: true, activeID: id })}
          disabled={isSubmitting}
          color="link"
          className="d-flex align-items-center text-red p-0 ms-3 fw-bold me-auto"
        >
          <SvgRender src={deleteSVG} style={{ width: 14, height: 14 }} className="me-1" />
          Remove
        </Button>
        <Button color="cancel" className="px-0 py-1 me-4" onClick={onClose} disabled={isSubmitting}>
          CANCEL
        </Button>
        <Button
          onClick={() => onSave({ custom_title: customTitle })}
          disabled={isSubmitting}
          color="primary"
          className="px-4"
        >
          SAVE
        </Button>
      </FormFooter>
    </FormDrawer>
  );
};

WidgetSettings.propTypes = {
  id: PropTypes.number.isRequired, // widget id
  onSave: PropTypes.func.isRequired, // Function to call onSubmit
  onClose: PropTypes.func.isRequired, // Function to call onCancel
  isSubmitting: PropTypes.bool.isRequired
};

export default WidgetSettings;
