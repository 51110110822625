import React, { useState, useEffect } from 'react';
import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import paths from 'routing/routes/_paths';
import { useSelector } from 'react-redux';
import { selectWidgetState } from 'store/dashboards/selectors';
import Priority from 'common/components/general/Priority';
import { Link } from 'react-router-dom';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

import SvgRender from 'common/components/general/SvgRender';
import vesselIcon from 'common/assets/svg/common/vessels.svg';
import companyIcon from 'common/assets/svg/common/company.svg';
import Status from 'common/components/purchasing/requisition/components/Status';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import { Row, Col } from 'reactstrap';
import Summary from 'views/purchasing/requisitions/requisition/summary';
import reviewProcess from 'common/assets/svg/common/review-process.svg';
import { toggleSummaryView } from 'common/components/purchasing/requisition/store/actions';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchListOptions } from 'store/lists/actions';

const PurchasingApprovals = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));

  const [summaryRequisitionId, setSummaryRequisitionId] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchListOptions('purchasing-requisition-statuses'));
  }, [dispatch]);

  return (
    <>
      <div>
        <WidgetTitle id={id} linkTo={paths.purchasing_requisitions_approval} />
        <WidgetDefaultTable
          id={id}
          columns={[
            {
              header: '',
              key: 'priority',
              width: 1,
              headerClassName: 'text-violet'
            },
            {
              header: 'Description',
              key: 'description',
              width: 4,
              headerClassName: 'text-violet'
            },
            {
              header: 'Vessel/Company',
              key: 'vessel_company',
              headerClassName: 'text-violet',
              width: 2
            },
            {
              header: 'Category',
              key: 'category_id',
              type: 'collection',
              headerClassName: 'text-violet',
              width: 2
            },
            {
              header: 'Suppliers',
              key: 'suppliers',
              type: 'collection',
              headerClassName: 'text-violet',
              minWidth: 217,
              maxWidth: 217
            },
            {
              header: 'Status',
              key: 'status',
              headerClassName: 'text-violet',
              minWidth: 217,
              maxWidth: 217
            },
            {
              header: 'Actions',
              key: 'actions',
              field: 'actions'
            }
          ]}
          rows={{
            priority: data => (
              <div className="d-flex align-items-center">
                <div className="width-32">
                  {data?.priority?.id ? (
                    <Priority className="d-flex align-items-center" value={data?.priority?.id} />
                  ) : null}
                </div>
                <Link
                  className="d-flex justify-content-start"
                  to={`${paths.purchasing_requisitions}/${data?.id}`}
                  target="_blank"
                >
                  <strong className="fs-10 text-nowrap">{data?.code}</strong>
                </Link>
              </div>
            ),
            description: data => (
              <Row className="flex-nowrap">
                <Col xs="11">
                  <Link to={`${paths.purchasing_requisitions}/${data?.id}`}>
                    <TextWithTooltip className="d-flex align-items-center">
                      {data?.description}
                    </TextWithTooltip>
                  </Link>
                </Col>
                <Col>
                  <SvgRender
                    src={!data?.is_for_vessel ? companyIcon : vesselIcon}
                    style={{ width: 12, height: 12 }}
                    className={`me-1 cmb-2 ${
                      !data?.is_for_vessel ? 'text-moody-blue' : 'text-primary'
                    }`}
                  />
                </Col>
              </Row>
            ),
            vessel_company: data => (
              <TextWithTooltip className="h-auto">
                {data?.is_for_vessel ? data?.vessel?.name : data?.company?.name}
              </TextWithTooltip>
            ),
            category_id: data => (
              <NamesInTooltip
                target={`requisition-categories-${data?.id}`}
                names={data?.categories?.map(category => category?.name)}
              />
            ),
            suppliers: data => (
              <NamesInTooltip
                target={`requisition-suppliers-${data?.id}`}
                names={data?.suppliers?.map(sup => sup?.supplier?.full_name)}
              />
            ),
            status: data => {
              return <Status status={data?.status} isBullet />;
            },
            actions: data => ({
              options: [
                {
                  label: 'Under Approval',
                  icon: reviewProcess,
                  iconStyles: { width: 10, height: 10, marginRight: 8 },
                  onClick: () => {
                    setSummaryRequisitionId(data.id);
                    dispatch(toggleSummaryView(true));
                  },
                  className: 'text-white fw-medium',
                  wrapperClassName: 'bg-green'
                }
              ]
            })
          }}
          label={label}
          state={state}
        />
      </div>
      <Summary requisitionId={summaryRequisitionId} isWidget={true} />
    </>
  );
};

PurchasingApprovals.propTypes = {
  id: PropTypes.number
};

export default PurchasingApprovals;
