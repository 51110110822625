import { strToNumber } from 'common/utils/numbers';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';

import { configSettings } from './configs/helpers';

export const hasValue = value => value || value === 0;

export const getSum = (values, key, isLive) => {
  return (values || []).reduce((prev, value) => {
    if (!value[key]) return prev;

    return (
      prev + strToNumber(isLive ? getLastUpdatedAutoValue(value[key]) : getCptValue(value[key]))
    );
  }, 0);
};

export const getValue = (value, configName) => {
  return _get(value, 'value', null) !== null
    ? _get(value, _get(configSettings, `${configName}.integrationValueKey`, 'value'))
    : value;
};

export const getCptValue = value => {
  const hasSource = _get(value, 'source');
  return hasSource ? _get(value, 'noonValue') : value;
};

export const getLastUpdatedAutoValue = value => {
  const hasSource = _get(value, 'source');
  return hasSource ? _get(value, 'last_updated.last_updated_value') : null;
};

export const getClassName = (value, fallback = '') => {
  return _get(value, 'className') || fallback;
};

export const getSource = value => {
  return _get(value, 'source.name') || '';
};

const valuesToFilterOut = [
  // 'wind_speed',
  // 'wind_direction',
  // 'ocean_current_direction',
  // 'ocean_current_speed',
  'course_og',
  'sea_direction',
  'sea_height'
  // 'swell_direction',
  // 'swell_height'
];

const mapNewValues = (newReportObject, oldReportObject, className = 'text-pacific-blue') => {
  const valuesArray = Object.keys(newReportObject).map(initialKey => {
    const key = initialKey;

    const isConsumptions = key === 'consumptions';

    const data = newReportObject[initialKey];
    const noonValue = _get(oldReportObject, key);

    if (valuesToFilterOut.includes(key)) return {};

    if (isConsumptions) {
      if (!oldReportObject[key]) return [];

      const mappedConsumptions = oldReportObject[key].map(consumption => {
        const newCons = data
          ? data.find(item => item.fuel_grade.id === consumption.fuel_grade.id)
          : null;

        if (!newCons) return consumption;

        const { fuel_grade, ...rest } = newCons;
        const newConsumptionMapped = mapNewValues(rest, consumption);

        return { ...consumption, ...newConsumptionMapped };
      });

      return { [key]: mappedConsumptions };
    }

    return {
      [key]: { ...data, className, noonValue }
    };
  });

  return valuesArray && valuesArray.length ? Object.assign(...valuesArray) : {};
};

export const combineIntegrationsWithVoyage = voyage => {
  // Append the integrated values
  const reportIntegrationData = _get(voyage, 'integration_reports');

  let newPreviousReport = _get(voyage, 'previous_report') || {};

  if (reportIntegrationData) {
    const newValues = mapNewValues(reportIntegrationData, newPreviousReport);

    newPreviousReport = {
      ...newPreviousReport,
      ...newValues
    };
  }

  const newVoyage = {
    ...voyage,
    previous_report: newPreviousReport
  };

  return newVoyage;
};

export const combineIntegrationsWithLegTotals = legTotals => {
  if (!legTotals) return;

  const { captain_report_totals, integration_totals } = legTotals;

  if (integration_totals) {
    const newValues = mapNewValues(integration_totals, captain_report_totals);

    return { ...captain_report_totals, ...newValues };
  }

  return captain_report_totals;
};

export const getVoyageCharterers = voyage => {
  const charterers = _orderBy(_get(voyage, 'voyage.charterers', []), ['created_at'], ['desc']);

  return charterers;
};

export const getVoyageFirstCharterer = voyage => {
  const charterers = getVoyageCharterers(voyage);

  return charterers?.length ? charterers[0] : null;
};

export const getGoodWeatherClauses = voyage => {
  return voyage?.weather?.good_weather_clauses || {};
};
