import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants';

const FreightHire = React.lazy(() => retry(() => import('@/views/freight-hire/index.tsx')));

const freighHire = [
  {
    path: [`${paths.freight_hire}`, `${paths.freight_hire}/:id`],
    component: FreightHire,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'Freight & Hire',
    permissions: [permissions.office.voyages.freight_hire.view]
  }
];

export default freighHire;
