import { get } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import TABLE_TYPES from 'common/components/table/store/types';

export const getLubOilAnalysisReport = createAsyncThunk(
  'GET_LUB_OIL_ANALYSIS_REPORT',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { table, ...rest } = params;
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.START, payload: { params, table } });

      const res = await get('/reports/lubricant-oil-analysis', rest);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
