import { CaptainReportTemplate } from '@/common/types/captain-reports';
import { deleteRequest, get, post, put } from '..';
import { ActivePlannedRoute } from '@/common/types/planned-routes';
import { VesselActivePlannedRouteQueryRequestParams } from '@/common/types/vessel';
import { VesselDocumentCategory, VesselDocumentType } from '@/common/types/vessel';
import { Attachment } from '@/ts-common/types/files';

export const getVesselActivePlannedRoute = async (
  params: VesselActivePlannedRouteQueryRequestParams
) => {
  const { id } = params;

  return (await get<ActivePlannedRoute>(`/vessels/${id}/active-planned-route`))?.data;
};

export type VesselDocumentsQueryParams = {
  id?: number | string;
  search?: string;
};
export const getVesselDocuments = async (params: VesselDocumentsQueryParams) => {
  return (await get<VesselDocumentCategory[]>(`/vessels/${params.id}/document-categories`, params))
    ?.data;
};

export type VesselDocumentMutationParams = {
  id?: number | string;
  vessel_id?: number;
  vessel_document_type_id: string;
  issue_date: string | null;
  expiration_date: string | null;
  range_from: string | null;
  range_to: string | null;
  postponed: string | null;
  extension_requested: string | null;
  completed_on: string | null;
  flag_informed_on: string | null;
  issued_by: string | null;
  port_id: string | null;
  comments: string | null;
  attachments: string[];
};
export const createVesselDocument = async (params: VesselDocumentMutationParams) => {
  return (await post<VesselDocumentType>(`/vessels/documents`, params))?.data;
};

export const editVesselDocument = async (params: VesselDocumentMutationParams) => {
  return (await put<VesselDocumentType>(`/vessels/documents/${params.id}`, params))?.data;
};

export type VesselDocumentDeletionParams = {
  vessel_id?: number | string;
  document_id: number | string;
};
export const archiveDocumentDocument = async (params: VesselDocumentDeletionParams) => {
  return (await deleteRequest<VesselDocumentType>(`/vessels/documents/${params.document_id}`))
    ?.data;
};

export const renewVesselDocument = async (params: VesselDocumentMutationParams) => {
  return (await post<VesselDocumentType>(`/vessels/documents/update`, params))?.data;
};

export type GetVesselDocumentHistoryParams = {
  vessel_id?: number | string;
  category_id: string | number;
};
export const getVesselDocumentHistory = async (params: GetVesselDocumentHistoryParams) => {
  return (
    await get<VesselDocumentType[]>(
      `/vessels/${params.vessel_id}/document-categories/${params.category_id}`
    )
  )?.data;
};

export type GetVesselDocumentAttchmentsParams = {
  id: string | number;
};
export const getVesselDocumentAttchaments = async ({ id }: GetVesselDocumentAttchmentsParams) => {
  return (await get<Attachment[]>(`/vessels/documents/${id}/attachments`))?.data;
};

type GetVesselStoppageFuelGradesParamsType = { vesselId?: number };

export const getVesselStoppageFuelGrades = async ({
  vesselId
}: GetVesselStoppageFuelGradesParamsType) => {
  return (
    await get<CaptainReportTemplate>(`/vessels/${vesselId}/captain-reports/templates/stoppage`)
  ).data;
};
