import { numberToStr, strToNumber } from 'common/utils/numbers';
import _sumBy from 'lodash/sumBy';
import _orderBy from 'lodash/orderBy';
import _sortBy from 'lodash/sortBy';

export const perChapterGraphColors = [
  '#FE5F55',
  '#FEDD00',
  '#E28413',
  '#7084D3',
  '#01D9A4',
  '#C0D3D5'
];

export const perSeverityRiskColors = ['#01D9A4', '#7BF5D7', '#E28413', '#FE5F55', '#CD1E3D'];

export const suptGraphColor = '#FFA200';
export const perVesselGraphColor = '#7084D3';

const LAST_GRAPH_COLOR_INDEX = perChapterGraphColors.length - 1;

export const parsePerChapterData = data => {
  const perChapterData = _orderBy(data, 'value', 'desc');

  const total = _sumBy(perChapterData, 'value');
  const lastIndex = perChapterData.length - 1;

  const percentages = perChapterData.reduce((prev, curr, index) => {
    // if the index is less than 5 that meanst that we have available colors so we just push
    if (index < LAST_GRAPH_COLOR_INDEX) {
      const chapterPercentage = {
        label: `chapter-${curr.chapter.id}`,
        percentage: (strToNumber(curr.value) / total) * 100
      };

      return [...prev, chapterPercentage];
    }

    // if we don't have colors, first we find the previous 'other' percentage if it exists and then we add the value so we can get
    // all those percentages grouped
    const prevOtherPercentage = prev.find(percentage => percentage.label === 'chapter-other');

    const chapterOtherPercentage = {
      label: 'chapter-other',
      total: (prevOtherPercentage?.total || 0) + strToNumber(curr.value),
      percentage:
        index === lastIndex
          ? (((prevOtherPercentage?.total || 0) + strToNumber(curr.value)) / total) * 100
          : null
    };

    return [
      ...prev.filter(percentage => percentage.label !== 'chapter-other'),
      chapterOtherPercentage
    ];
  }, []);

  const colors = perChapterData.reduce((prev, curr, index) => {
    const key = `chapter-${curr.chapter.id}`;
    const color =
      index <= LAST_GRAPH_COLOR_INDEX
        ? perChapterGraphColors[index]
        : perChapterGraphColors[LAST_GRAPH_COLOR_INDEX];

    return { ...prev, [key]: { color } };
  }, {});

  const legends = perChapterData.reduce((prev, curr, index) => {
    const chapterLegend = {
      label: `chapter-${curr.chapter.id}`,
      name: `${curr?.chapter.number}. ${curr?.chapter?.name}`,
      value: curr?.value
    };

    return [...prev, chapterLegend];
  }, []);

  if (legends.length >= LAST_GRAPH_COLOR_INDEX) {
    legends.push({ label: 'chapter-other', name: 'Chapter Other', hidden: true });
    colors['chapter-other'] = { color: perChapterGraphColors[5] };
  }

  const parsedLegends = _orderBy(legends, 'name', 'asc');

  return { total, percentages, colors, legends: parsedLegends };
};

export const parsePerSeverityOrRiskData = (data, { key, label }) => {
  const perSeverityData = _orderBy(data, 'severity');

  const total = _sumBy(data, 'value');

  return perSeverityData.reduce(
    (prev, curr, index) => ({
      ...prev,
      percentages: [
        ...prev.percentages,
        {
          label: `${key}-${curr?.severity}`,
          percentage: (numberToStr(curr?.value) / total) * 100
        }
      ],
      colors: {
        ...prev.colors,
        [`${key}-${curr?.severity}`]: { color: perSeverityRiskColors[index] }
      },
      legends: [
        ...prev.legends,
        {
          label: `${key}-${curr?.severity}`,
          name: `${label} ${curr?.severity}`,
          value: curr?.value
        }
      ]
    }),
    { total, colors: {}, legends: [], percentages: [] }
  );
};

export const parseVettingBarGraphData = (data, color) => {
  // Filter out the values without a vessel name
  return _sortBy(data, 'name')
    .filter(data => data.name)
    .reduce(
      (prev, curr, index) => ({
        ...prev,
        data: [...prev.data, { index, value: curr?.value, color: color, name: curr?.name }]
      }),
      {
        type: ['bar'],
        dataKey: 'value',
        data: []
      }
    );
};
