import TYPES from './types';
import { put, post, deleteRequest, get } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { updateTableRow, resetTableRowUpdate } from 'store/tables/lists/actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const updateDepartmentRole = params => dispatch => {
  const { id, ...rest } = params;
  const table = 'department-roles';
  dispatch({ type: TYPES.UPDATE_DEPARTMENT_ROLE.START, payload: { params } });
  dispatch(updateTableRow({ data: params, table }));

  return put(`/department-roles/${id}`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.UPDATE_DEPARTMENT_ROLE.SUCCESS, payload: response.data });
      dispatch(updateTableRow({ data: response.data, table }));
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_DEPARTMENT_ROLE.ERROR, payload: { response: error, params } });
      dispatch(resetTableRowUpdate({ data: params, table }));
      return error;
    });
};

export const createDepartmentRole = params => dispatch => {
  dispatch({ type: TYPES.CREATE_DEPARTMENT_ROLE.START, payload: { params } });

  return post('/department-roles', params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_DEPARTMENT_ROLE.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_DEPARTMENT_ROLE.ERROR, payload: error });
      return error;
    });
};

export const deleteDepartmentRole = params => dispatch => {
  const { id } = params;
  dispatch({ type: TYPES.DELETE_DEPARTMENT_ROLE.START, payload: { params } });

  return deleteRequest(`/department-roles/${id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_DEPARTMENT_ROLE.SUCCESS, payload: { id } });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.DELETE_DEPARTMENT_ROLE.ERROR, payload: error }));
};

export const getDepartmentRole = createAsyncThunk(
  'GET_DEPARTMENT_ROLE',
  async (params, { rejectWithValue }) => {
    try {
      const res = await get(`/department-roles/${params.id}`, params);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
