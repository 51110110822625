import { RootState } from '@/store';

export const selectSimilarities = (state: RootState) => state.similarities;

export const selectFieldValues = (state: RootState) => selectSimilarities(state).fieldValues;

export const selectFieldValuesType = (state: RootState) => selectFieldValues(state)?.type;

export const selectSimilaritiesData = (state: RootState) => selectSimilarities(state).data;

export const selectIsLoadingSimilarities = (state: RootState) =>
  selectSimilarities(state).isLoading;
