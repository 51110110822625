import TYPES from './types';

export const INITIAL_STATE = {
  categories: [],
  filteredCategories: [],
  activeCategoriesGroup: null,
  isCategoryAssignmentDrawerOpen: false
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.CREATE_PURCHASING_CATEGORY.SUCCESS:
      return {
        ...state,
        categories: [...state.categories, payload],
        filteredCategories: [...state.filteredCategories, payload]
      };

    case TYPES.GET_PURCHASING_CATEGORIES.SUCCESS:
      return {
        ...state,
        categories: payload.params?.isInitial ? payload.data : state.categories,
        filteredCategories: payload.data
      };

    case TYPES.SET_ACTIVE_CATEGORIES_GROUP:
      return { ...state, activeCategoriesGroup: payload };

    case TYPES.TOGGLE_CATEGORY_ASSIGNMENT_DRAWER:
      return { ...state, isCategoryAssignmentDrawerOpen: payload };

    default:
      return state;
  }
};

export default reducer;
