import PropTypes from 'prop-types';
import Sms2Fa from './sms';
import Authenticator from './authenticator';

import { useState, useEffect } from 'react';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { select2faSetupIsRequired } from 'store/auth/selectors';
import { authorizeLogin as authorizeLoginAction } from 'store/auth/slice';

import * as authActions from 'store/auth/actions';

const TwoFactorAuthentication = ({ method }) => {
  const [code, setCode] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittedOnce, setIsSubmittedOnce] = useState(false);
  const [loginWithTwoFactor, setupTwoFactorAuthentication, authorizeLogin] = useActions([
    authActions.loginWithTwoFactor,
    authActions.setupTwoFactorAuthentication,
    authorizeLoginAction
  ]);

  const setupIsRequired = useSelector(select2faSetupIsRequired);

  const onLogin = async e => {
    e?.preventDefault();
    if (!code?.isValid || isSubmitting) return;

    setIsSubmittedOnce(true);

    try {
      setIsSubmitting(true);

      if (setupIsRequired) {
        const response = await setupTwoFactorAuthentication({
          method: 'totp',
          config: { action: 'verifySetup', code: code.value }
        }).unwrap(); // When the totp setup is required, we are using the verifySetup function to login

        if (response?.data?.access_token) {
          authorizeLogin(response?.data);
        }
      } else {
        await loginWithTwoFactor({ code: code.value }).unwrap();
      }

      setCode({});
      setIsSubmitting(false);
    } catch (data) {
      console.error(data);
      setCode(prev => ({
        ...prev,
        error: data?.data?.message || data?.data?.error || 'Invalid code'
      }));
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (code?.isValid && !isSubmitting && !code.error && !isSubmittedOnce) onLogin();
  }, [code, isSubmitting, isSubmittedOnce]);

  const Tag = method === 'sms' ? Sms2Fa : Authenticator;

  return (
    <Tag
      code={code}
      setCode={setCode}
      onLogin={onLogin}
      disabled={isSubmitting || !code?.isValid || code.error}
    />
  );
};

TwoFactorAuthentication.propTypes = {
  method: PropTypes.string.isRequired
};

export default TwoFactorAuthentication;
