import TYPES from './types';
import { get, post, put, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
// import { updateLastDashboardId } from 'store/account/actions';
import paths from 'routing/routes/_paths';

export const fetchDashboards = params => dispatch => {
  dispatch({ type: TYPES.FETCH_ALL_DASHBOARDS.START });

  return get('/dashboards', params)
    .then(res => {
      dispatch({
        type: TYPES.FETCH_ALL_DASHBOARDS.SUCCESS,
        payload: {
          dashboards: res.data.map(d => ({
            ...d,
            name: d.title,
            path: `${paths.dashboard}/${d.id}`
          }))
        }
      });
      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.FETCH_ALL_DASHBOARDS.ERROR, payload: error }));
};

export const fetchDashboard = dashboardId => (dispatch, getState) => {
  const state = getState();
  const account = state.account;

  dispatch({ type: TYPES.FETCH_DASHBOARD.START, payload: { dashboardId } });

  return get(`/dashboards/${dashboardId}`)
    .then(async res => {
      const dashboard = formatDashboard(res.data, account);

      // if (account.last_dashboard_id !== dashboard.id) {
      //   await dispatch(updateLastDashboardId(dashboard.id));
      // }

      dispatch({
        type: TYPES.FETCH_DASHBOARD.SUCCESS,
        payload: { dashboard, dashboardId }
      });

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.FETCH_DASHBOARD.ERROR, payload: error }));
};

export const createDashboard = params => dispatch => {
  dispatch({ type: TYPES.CREATE_DASHBOARD.START });

  return post('/dashboards', params)
    .then(res => {
      const dashboard = res.data;

      dispatch({
        type: TYPES.CREATE_DASHBOARD.SUCCESS,
        payload: {
          dashboard: {
            ...dashboard,
            name: dashboard.title,
            path: `/${paths.dashboard}/${dashboard.id}`
          }
        }
      });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_DASHBOARD.ERROR, payload: error });
      throw error;
    });
};

// Clones dashboard (Save As)
export const createDashboardFrom = params => dispatch => {
  dispatch({ type: TYPES.CREATE_DASHBOARD_FROM.START });

  return get(`/dashboards/${params.start_from}`).then(res => {
    const dashboardFrom = {
      ...res.data,
      title: params.title,
      description: params.description,
      shared_parties: params.shares.parties,
      shared_departments: params.shares.departments
    };

    const cloneParams = calculateCloneDashboardParams(dashboardFrom);

    return post('/dashboards', cloneParams)
      .then(res => {
        const dashboard = res.data;
        dispatch({
          type: TYPES.CREATE_DASHBOARD_FROM.SUCCESS,
          payload: {
            dashboard: {
              ...dashboard,
              name: dashboard.title,
              path: `/dashboard/${dashboard.id}`
            }
          }
        });
        return res.data;
      })
      .catch(error => dispatch({ type: TYPES.CREATE_DASHBOARD_FROM.ERROR, payload: error }));
  });
};

export const deleteDashboard = dashboardId => dispatch => {
  dispatch({ type: TYPES.DELETE_DASHBOARD.START });

  return deleteRequest(`/dashboards/${dashboardId}`)
    .then(res => {
      dispatch({
        type: TYPES.DELETE_DASHBOARD.SUCCESS,
        payload: { dashboardId }
      });

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.DELETE_DASHBOARD.ERROR, payload: error }));
};

export const editDashboard = params => (dispatch, getState) => {
  dispatch({ type: TYPES.EDIT_DASHBOARD.START });

  return put(`/dashboards/${params.id}`, params)
    .then(res => {
      const state = getState();
      const account = state.account;
      const dashboard = formatDashboard(res.data, account);

      dispatch({
        type: TYPES.EDIT_DASHBOARD.SUCCESS,
        payload: { dashboard }
      });

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.EDIT_DASHBOARD.ERROR, payload: error }));
};

export const cloneDashboard = dashboard => dispatch => {
  dispatch({ type: TYPES.CLONE_DASHBOARD.START });
  const params = calculateCloneDashboardParams(dashboard);

  return post('/dashboards', params)
    .then(res => {
      const dashboard = res.data;
      dispatch({
        type: TYPES.CLONE_DASHBOARD.SUCCESS,
        payload: {
          dashboard: {
            ...dashboard,
            name: dashboard.title,
            path: `/dashboard/${dashboard.id}`
          }
        }
      });
      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.CLONE_DASHBOARD.ERROR, payload: error }));
};

export const updateDashboardWidgets = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_DASHBOARD_WIDGETS.START });

  return put(`/dashboards/${params.id}`, params)
    .then(res => {
      dispatch({
        type: TYPES.UPDATE_DASHBOARD_WIDGETS.SUCCESS,
        payload: res.data
      });

      dispatch(
        successHandler({ title: 'Success!', message: 'Dashboard widgets updated successfully' })
      );
      dispatch(fetchDashboard(params.id));

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_DASHBOARD_WIDGETS.ERROR, payload: error });
      throw error;
    });
};

export const addWidget = params => (dispatch, getState) => {
  dispatch({ type: TYPES.ADD_WIDGET.START });
  const widgets = getState().dashboards.active.widgets;
  const oldWidgets = widgets.map((widget, index) => ({
    ...widget,
    sort_index: index + 1
  }));

  return put(`/dashboards/${params.id}`, {
    widgets: oldWidgets.length
      ? [...oldWidgets, { ...params.widget, sort_index: 0 }]
      : [...widgets, { ...params.widget, sort_index: 0 }]
  })
    .then(res => {
      if (res.data && res.data.widgets && res.data.widgets.length) {
        dispatch({
          type: TYPES.ADD_WIDGET.SUCCESS,
          payload: {
            widget: res.data.widgets[0] // The first one is the new widget
          }
        });
      } else {
        dispatch({
          type: TYPES.ADD_WIDGET.SUCCESS,
          payload: {
            widget: null
          }
        });
      }

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.ADD_WIDGET.ERROR, payload: error }));
};

export const removeWidget = params => (dispatch, getState) => {
  dispatch({ type: TYPES.REMOVE_WIDGET.START });
  const widgets = getState().dashboards.active.widgets;

  const updated = {
    widgets: widgets.filter(w => w.id !== params.id).map((w, i) => ({ ...w, sort_index: i }))
  };

  return put(`/dashboards/${params.dashboardId}`, updated)
    .then(res => {
      dispatch({
        type: TYPES.REMOVE_WIDGET.SUCCESS,
        payload: updated
      });

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.REMOVE_WIDGET.ERROR, payload: error }));
};

export const getWidgetData = params => dispatch => {
  dispatch({ type: TYPES.GET_WIDGET_DATA.START, payload: { params } });
  const { widgetId, ...rest } = params;

  return get(`/dashboards/widgets/${widgetId}`, rest)
    .then(res => {
      dispatch({
        type: TYPES.GET_WIDGET_DATA.SUCCESS,
        payload: { data: res.data, params }
      });

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_WIDGET_DATA.ERROR, payload: { error, params } }));
};

export const getWidgetPreferences = params => dispatch => {
  dispatch({ type: TYPES.GET_WIDGET_PREFERENCES.START, payload: { params } });
  const { widgetId, ...rest } = params;

  return get(`/dashboards/widgets/${widgetId}/preferences`, rest)
    .then(res => {
      dispatch({
        type: TYPES.GET_WIDGET_PREFERENCES.SUCCESS,
        payload: { id: widgetId, preferences: res.data }
      });

      return res.data;
    })
    .catch(error =>
      dispatch({ type: TYPES.GET_WIDGET_PREFERENCES.ERROR, payload: { error, params } })
    );
};

export const updateWidgetPreferences = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_WIDGET_PREFERENCES.START });
  const { widgetId, ...rest } = params;

  return put(`/dashboards/widgets/${widgetId}`, { preferences: rest })
    .then(res => {
      dispatch({
        type: TYPES.UPDATE_WIDGET_PREFERENCES.SUCCESS,
        payload: res.data
      });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_WIDGET_PREFERENCES.ERROR, payload: error });
      throw error;
    });
};

export const updateWidget = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_WIDGET.START });
  const { widgetId, ...rest } = params;

  return put(`/dashboards/widgets/${widgetId}`, rest)
    .then(res => {
      dispatch({
        type: TYPES.UPDATE_WIDGET.SUCCESS,
        payload: res.data
      });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_WIDGET.ERROR, payload: error });
      throw error;
    });
};

export const deleteWidget = params => dispatch => {
  dispatch({ type: TYPES.DELETE_WIDGET.START });
  const { widgetId, ...rest } = params;

  return deleteRequest(`/dashboards/widgets/${widgetId}`, rest)
    .then(res => {
      dispatch({
        type: TYPES.DELETE_WIDGET.SUCCESS,
        payload: rest
      });

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.DELETE_WIDGET.ERROR, payload: error }));
};

export const updateWidgetState =
  ({ sorting, paging }, id) =>
  dispatch => {
    dispatch({
      type: TYPES.UPDATE_WIDGET_STATE,
      payload: { params: { sorting, paging, widgetId: id } }
    });
  };

// Helpers

const formatDashboard = (dashboard, account) => {
  const { favorite, ...rest } = dashboard;

  return {
    ...rest,
    ownerName: dashboard.owner.full_name,
    owner: account.id === dashboard.party_id,
    shared_parties: dashboard.shared_parties,
    shared_departments: dashboard.shared_departments.map(el => el.id)
  };
};

const calculateCloneDashboardParams = dashboard => {
  return {
    title: dashboard.title,
    description: dashboard.description,
    is_default: dashboard.is_default,
    party_id: dashboard.party_id,
    shares: {
      parties: dashboard.shared_parties,
      departments: dashboard.shared_departments
    },
    widgets: dashboard.widgets.map(w => {
      const { id, dashboard_id, created_at, updated_at, ...rest } = w;
      return rest;
    })
  };
};

export const setDashboardForm =
  ({ isOpen, active }) =>
  dispatch => {
    dispatch({ type: TYPES.SET_DASHBOARD_FORM, payload: { isOpen, active } });
  };

export const setDashboardWidgetsForm =
  ({ isOpen }) =>
  dispatch => {
    dispatch({ type: TYPES.SET_DASHBOARD_WIDGETS_FORM, payload: { isOpen } });
  };

export const setWidgetSettingsForm =
  ({ isOpen, activeID }) =>
  dispatch => {
    dispatch({ type: TYPES.SET_WIDGET_SETTINGS_FORM, payload: { isOpen, activeID } });
  };

export const setWidgetPreventionModal =
  ({ isOpen, activeID }) =>
  dispatch => {
    dispatch({ type: TYPES.SET_WIDGET_PREVENTION_MODAL, payload: { isOpen, activeID } });
  };
