import TYPES from './types';
import moment from 'moment';

export const INITIAL_STATE = {
  data: [],
  last_page: null,
  current_page: null,
  total: null,
  count: 0,
  hasFetchedNotifications: false,
  loadingMore: false,
  activeCategoryLabel: 'all',
  isUnreadTabActive: false,
  summary: [],
  isNotificationFetching: false,
  isSummaryFetching: false
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_NOTIFICATIONS.START:
      return {
        ...state,
        isNotificationFetching: true,
        loadingMore: payload.type === 'loadMore' ? true : false,
        data: payload.type === 'loadMore' ? state.data : [],
        hasFetchedNotifications: true
      };

    case TYPES.GET_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        data: payload.type === 'loadMore' ? [...state.data, ...payload.data] : payload.data,
        last_page: payload.last_page,
        current_page: payload.current_page,
        total: payload.total,
        loadingMore: false,
        isNotificationFetching: false,
        hasFetchedNotifications: true
      };

    case TYPES.RECEIVE_SOCKET_NOTIFICATION:
      return {
        ...state,
        count: state.count + 1,
        total: state.total + 1,
        data: [{ ...payload, created_at: moment().format('YYYY-MM-DD hh:mm:ss') }, ...state.data]
      };

    case TYPES.GET_NOTIFICATIONS.ERROR:
      return {
        ...state,
        loadingMore: false,
        isNotificationFetching: false
      };

    case TYPES.MARK_NOTIFICATION_AS_READ.SUCCESS:
      const { notification_ids = [] } = payload;

      let newData = state.data.map(el => {
        if (notification_ids.includes(el.id)) {
          return {
            ...el,
            read_at: true
          };
        }
        return el;
      });
      return {
        ...state,
        data: newData
      };

    case TYPES.GET_NOTIFICATIONS_COUNT.SUCCESS:
      return {
        ...state,
        count: payload.count
      };

    case TYPES.SET_ACTIVE_CATEGORY_LABEL: {
      return {
        ...state,
        activeCategoryLabel: payload
      };
    }

    case TYPES.MARK_NOTIFICATIONS_AS_SEEN.SUCCESS:
      return {
        ...state,
        count: 0
      };

    case TYPES.GET_NOTIFICATIONS_SUMMARY.START:
      return {
        ...state,
        summary: [],
        isSummaryFetching: true
      };

    case TYPES.GET_NOTIFICATIONS_SUMMARY.SUCCESS:
      return {
        ...state,
        summary: payload,
        isSummaryFetching: false
      };

    case TYPES.REDUCE_UNREAD_COUNTS_BY_TYPE:
      const summaryUpdatedData = state.summary.map(e =>
        e.type_prefix === payload ? { ...e, unread_count: e?.unread_count - 1 } : { ...e }
      );

      return {
        ...state,
        summary: summaryUpdatedData
      };

    case TYPES.RESET_UNREAD_COUNT:
      const type = payload;
      const resetSummaryData = state.summary.map(e => ({
        ...e,
        unread_count: e.type_prefix === type || !type ? 0 : e.unread_count
      }));
      const updateToSeenNotificationsData = state.data.map(notification => ({
        ...notification,
        read_at: 'seen'
      }));

      return {
        ...state,
        summary: resetSummaryData,
        data: updateToSeenNotificationsData
      };

    case TYPES.SET_IS_UNREAD_TAB_ACTIVE:
      return {
        ...state,
        isUnreadTabActive: payload
      };

    case TYPES.GET_NOTIFICATIONS_SUMMARY.ERROR:
      return {
        ...state,
        isSummaryFetching: false
      };

    default:
      return state;
  }
};

export default reducer;
