import { put, get, post, deleteRequest } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectBeaconIsOfficeView } from 'common/components/beacon/store/selectors';
import { parseBeaconMenusWithPosts } from './helpers';
import TYPES from './types';

export const getBeaconMenus = createAsyncThunk(
  TYPES.GET_BEACON_MENUS,
  async (params, { rejectWithValue, getState }) => {
    try {
      const res = await get('/beacon/menus', params);

      if (selectBeaconIsOfficeView(getState())) {
        return parseBeaconMenusWithPosts(res?.data?.data);
      }

      return res?.data?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createBeaconMenu = createAsyncThunk(
  TYPES.CREATE_BEACON_MENU,
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const res = await post('/beacon/menus', params);

      dispatch(getBeaconMenus());

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const editBeaconMenu = createAsyncThunk(
  TYPES.EDIT_BEACON_MENU,
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const res = await put(`/beacon/menus/${params.id}`, params);

      dispatch(getBeaconMenus());

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteBeaconMenu = createAsyncThunk(
  TYPES.DELETE_BEACON_MENU,
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const res = await deleteRequest(`/beacon/menus/${params.id}`, params);

      dispatch(getBeaconMenus());

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
