import { stringField, hiddenField } from 'common/utils/form/fieldTypes';

const config = {
  bookmarks: {
    title: stringField({ required: true }),
    url: stringField({ required: true }),
    id: hiddenField()
  }
};

export default config;
