export const PURCHASING_REQUISITION_ITEM_COMMENTS = 'purchasing_requisition_item_comments';

export const PURCHASING_REQUISITION_STORE_ITEM_DETAILS =
  'purchasing_requisition_store_item_details';

export const PURCHASING_REQUISITION_SPARE_PARTS_ITEM_DETAILS =
  'purchasing_requisition_spare_parts_item_details';

export const PURCHASING_REQUISITION_ITEMS_COMPARISON = 'purchasing_requisition_items_comparison';

export const PURCHASING_REQUISITION_ITEMS_SUPPLIER_TOTALS =
  'purchasing_requisition_items_supplier_totals';

export const PURCHASING_REQUISITION_ACCOUNT_BUDGETS = 'purchasing_requisition_account_budgets';

export const PURCHASING_REQUISITIONS_TABLE_KEY = 'purchasing_requisitions';

export const PURCHASING_REQUISITION_APPROVALS_TABLE_KEY = 'purchasing_requisitions_approvals';

export const PURCHASING_REQUISITION_CATEGORIES_TOTALS = 'purchasing_requisition_categories_totals';
