import {
  getVesselCiiProfile,
  getVesselCiiProfileGraphs,
  getVesselVoyageForecast,
  updateVesselVoyageForecast,
  calculateVesselVoyageForecast
} from './actions';
import { createSlice } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';

const DEFAULT_INITIAL_PORT_STATE = {
  id: 'temp',
  is_new: true,
  port: null,
  port_id: null,
  grade: 'go',
  condition: 'idle'
};

export const INITIAL_STATE = {
  isFetching: false,
  isGraphFetching: false,
  data: {
    ballast: [],
    cii_reference: {},
    day_totals: {},
    laden: [],
    port_average_consumption_rate: null,
    graphs: {
      laden: [],
      ballast: []
    }
  },
  voyageForecast: {
    is_saving: false,
    is_loading: false,
    is_adding_new: false,
    is_saving_new: false,
    data_from: null, // 'dynamic_data' || 'vessel_tc_description
    calculated_data_from: null, // When data_from !== calculated_data_from, reset instructed speed
    vessel_id: null,
    new_port_state: {
      ...DEFAULT_INITIAL_PORT_STATE
    }
  }
};

const slice = createSlice({
  name: 'vesselCiiProfile',
  initialState: INITIAL_STATE,
  reducers: {
    setVoyageForecastIsAddingNew: (state, { payload }) => {
      state.voyageForecast.is_adding_new = payload;

      if (payload)
        state.voyageForecast.new_port_state = {
          ...DEFAULT_INITIAL_PORT_STATE
        };

      return state;
    },
    setVoyageForecastNewPortState: (state, { payload }) => {
      state.voyageForecast.new_port_state = { ...state.voyageForecast.new_port_state, ...payload };

      return state;
    },
    setVoyageForecastDataFrom: (state, { payload }) => {
      state.voyageForecast.data_from = payload;

      return state;
    }
  },
  extraReducers: builder => {
    /* Vessel CII */
    builder
      .addCase(getVesselCiiProfile.pending, state => {
        state.isFetching = true;

        return state;
      })
      .addCase(getVesselCiiProfile.rejected, state => {
        state.isFetching = false;

        return state;
      })
      .addCase(getVesselCiiProfile.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.data = {
          ...state.data,
          ...payload?.data
        };

        return state;
      })
      .addCase(getVesselCiiProfileGraphs.pending, state => {
        state.isGraphFetching = true;

        return state;
      })
      .addCase(getVesselCiiProfileGraphs.rejected, state => {
        state.isGraphFetching = false;

        return state;
      })
      .addCase(getVesselCiiProfileGraphs.fulfilled, (state, { payload }) => {
        state.isGraphFetching = false;
        state.data.graphs = payload;

        return state;
      })
      .addCase(LOCATION_CHANGE, state => {
        state.report_type = null;

        return state;
      });
    /* -- */

    /* Voyage Forecast */
    builder
      .addCase(getVesselVoyageForecast.pending, (state, { meta }) => {
        if (meta.arg.id !== state.voyageForecast.vessel_id) {
          state.voyageForecast.data_from = null;
          state.voyageForecast.is_adding_new = false;
          state.voyageForecast.is_saving_new = false;
        }

        state.voyageForecast.vessel_id = meta.arg.id;
        state.voyageForecast.is_loading = true;

        return state;
      })
      .addCase(updateVesselVoyageForecast.pending, state => {
        state.voyageForecast.is_saving = true;

        return state;
      })
      .addCase(updateVesselVoyageForecast.fulfilled, state => {
        state.voyageForecast.is_saving = false;

        return state;
      })
      .addCase(updateVesselVoyageForecast.rejected, state => {
        state.voyageForecast.is_saving = false;

        return state;
      })
      .addCase(calculateVesselVoyageForecast.pending, (state, { meta }) => {
        if (meta?.arg?.is_new) state.voyageForecast.is_saving_new = true;

        return state;
      })
      .addCase(calculateVesselVoyageForecast.fulfilled, (state, { meta, payload }) => {
        if (meta?.arg?.is_new) {
          state.voyageForecast.is_saving_new = false;
          state.voyageForecast.is_adding_new = false;
        }

        state.voyageForecast.calculated_data_from = payload?.data_from;

        return state;
      })
      .addCase(calculateVesselVoyageForecast.rejected, (state, { meta }) => {
        if (meta?.arg?.is_new) state.voyageForecast.is_saving_new = false;

        return state;
      })
      .addCase(getVesselVoyageForecast.rejected, state => {
        state.voyageForecast.is_loading = false;

        return state;
      })
      .addCase(getVesselVoyageForecast.fulfilled, (state, { payload }) => {
        state.voyageForecast.data_from = payload?.data_from || 'dynamic_data';
        state.voyageForecast.calculated_data_from = payload?.data_from || 'dynamic_data';
        state.voyageForecast.is_loading = false;

        return state;
      });
    /* -- */
  }
});

export const {
  setVoyageForecastNewPortState,
  setVoyageForecastIsAddingNew,
  setVoyageForecastDataFrom
} = slice.actions;

export default slice.reducer;
