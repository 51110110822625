import PropTypes from 'prop-types';
import SvgRender from 'common/components/general/SvgRender';

const SectionHeader = ({ header }) => {
  return (
    <>
      <SvgRender src={header.icon} style={{ width: 14, height: 14 }} className="cme-12" />
      {header.label}
    </>
  );
};

SectionHeader.propTypes = {
  header: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })
};

export default SectionHeader;
