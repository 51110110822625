import TYPES from './types';

const INITIAL_STATE = {
  downloading: []
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.DOWNLOAD_CREW_TRAINING_ATTACHMENTS.START:
      return {
        ...state,
        downloading: [...state.downloading, payload.params.id]
      };

    case TYPES.DOWNLOAD_CREW_TRAINING_ATTACHMENTS.SUCCESS:
    case TYPES.DOWNLOAD_CREW_TRAINING_ATTACHMENTS.ERROR:
      return {
        ...state,
        downloading: state.downloading.filter(d => d !== payload.params.id)
      };

    default:
      return state;
  }
};

export default reducer;
