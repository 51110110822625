import Table from 'common/components/table';
import TopPagination from 'common/components/table/main/TopPagination';
import PropTypes from 'prop-types';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { selectIsWidgetStateLoading } from 'store/dashboards/selectors';

import * as dashboardsActions from 'store/dashboards/actions';

const WidgetDefaultTable = ({ id, state, ...rest }) => {
  const isLoading = useSelector(state => selectIsWidgetStateLoading(state, id));
  const [updateWidgetState] = useActions([dashboardsActions.updateWidgetState]);

  return (
    <div
      className={`widget-default-table ${
        state?.paging?.total === 0 ? 'widget-default-table--empty' : ''
      }`}
    >
      <Table
        hideTableFilters
        hideTableReorder
        hideTableSearch
        hideTopPagination
        hideBottomPagination
        state={state}
        {...rest}
      />
      {state?.paging?.current_page ? (
        <div className="mt-1">
          <TopPagination
            standAlone
            hidePerPage
            scrollToTop={false}
            state={state}
            fetchData={params => (!isLoading ? updateWidgetState(params, id) : null)}
          />
        </div>
      ) : null}
    </div>
  );
};

WidgetDefaultTable.propTypes = {
  id: PropTypes.number.isRequired // widget id
};

export default WidgetDefaultTable;
