import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('CREATE_PURCHASING_CATEGORY'),
  ...asyncTypes('UPDATE_PURCHASING_CATEGORY'),
  ...asyncTypes('DELETE_PURCHASING_CATEGORY'),
  ...asyncTypes('GET_PURCHASING_CATEGORIES'),
  SET_ACTIVE_CATEGORIES_GROUP: 'SET_ACTIVE_CATEGORIES_GROUP',
  TOGGLE_CATEGORY_ASSIGNMENT_DRAWER: 'TOGGLE_CATEGORY_ASSIGNMENT_DRAWER'
};

export default TYPES;
