import { Row, Col } from 'reactstrap';

export const vesselCiiProfileColumns = [
  {
    header: <div className="d-flex align-items-start justify-content-start h-100p">SPEED OG</div>,
    key: 'miles_per_hour'
  },
  {
    header: (
      <div className="d-flex flex-column w-100p min-width-350">
        <div>
          <span className="text-primary">FUEL CONSUMPTION</span>
          <span className="text-violet cms-4 text-lowercase">mt/d</span>
        </div>

        <Row className="w-100p">
          <Col xs={3} className="fw-bold text-dark fs-10 text-lowercase">
            min
          </Col>

          <Col xs={3} className="fw-bold text-dark fs-10 text-lowercase">
            average
          </Col>

          <Col xs={3} className="fw-bold text-dark fs-10 text-lowercase">
            max
          </Col>
        </Row>
      </div>
    ),
    width: 350,
    key: 'fuel_consumption'
  },
  {
    header: <div className="d-flex align-items-start justify-content-start h-100p">CII</div>,
    key: 'cii'
  },
  {
    header: <div className="d-flex align-items-start justify-content-start h-100p">RATING</div>,
    key: 'rating'
  }
];
