import { createAsyncThunk } from '@reduxjs/toolkit';
import { getReportSetup } from '@/api/budgeting-reports/api.ts';
import TYPES from './types.ts';

type GetReportSetupParams = {
  id: string;
};

export const getReportSetupAction = createAsyncThunk(
  TYPES.GET_REPORT_SETUP,
  async (params: GetReportSetupParams, { rejectWithValue, fulfillWithValue }) => {
    const { id } = params;

    try {
      const res = await getReportSetup({ id });

      return fulfillWithValue(res);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
