import {
  getLastUpdatedAutoValue,
  getCptValue
} from 'views/the-fleet/components/position-list/card/components/body/info/detailed-info/fields-list/helpers';

export const transformNumber = val => (!(!val && val !== 0) ? +val : null);

export const transformOceanCurrent = (val, tab, oceanCurrentRelativeDirection) => {
  const oceanCurrentValue = transformNumber(val)?.toFixed(1);

  const oceanCurrentRelativeDirectionValue =
    tab === 'live'
      ? getLastUpdatedAutoValue(oceanCurrentRelativeDirection)
      : getCptValue(oceanCurrentRelativeDirection);

  return oceanCurrentValue !== null && oceanCurrentRelativeDirection !== null
    ? `${
        oceanCurrentRelativeDirectionValue <= 90 || oceanCurrentRelativeDirectionValue >= 270
          ? ''
          : '-'
      }${oceanCurrentValue || ''}`
    : oceanCurrentValue;
};
