import { asyncTypes } from 'store/_helpers';

const types = {
  ...asyncTypes('GET_VESSEL_TAGS'),
  ...asyncTypes('CREATE_COMPARISON'),
  ...asyncTypes('GET_COMPARISONS'),
  ...asyncTypes('GET_SINGLE_COMPARISON'),
  ...asyncTypes('EDIT_COMPARISON'),
  ...asyncTypes('GET_COMPARISON_DATA'),
  ...asyncTypes('DELETE_COMPARISON'),
  SET_SELECTED_COMPARISON: 'SET_SELECTED_COMPARISON'
};

export default types;
