import React from 'react';
import widgetsDetails from './widgets-details';
import useWidget from 'views/dashboard/widgets/useWidget';
import { useSelector } from 'react-redux';
import {
  selectWidgetTypeLabel,
  selectIsWidgetStateInitialized,
  selectIsWidgetStateLoading
} from 'store/dashboards/selectors';

import UnsetWidget from 'views/dashboard/components/UnsetWidget';
import LoadingWidgetData from 'views/dashboard/components/LoadingWidgetData';
import Spinner from 'common/components/general/Spinner';

const Widget = ({ id, layout }) => {
  const initialized = useSelector(state => selectIsWidgetStateInitialized(state, id));
  const isLoading = useSelector(state => selectIsWidgetStateLoading(state, id));
  const label = useSelector(state => selectWidgetTypeLabel(state, id));

  const widgetDetails = widgetsDetails?.[label];
  const WidgetComponent = widgetDetails?.Component;

  const widget = useWidget({
    id
  });

  if (isLoading && !initialized) return <LoadingWidgetData id={id} label={label} layout={layout} />;
  else if (widget?.isUnset) return <UnsetWidget id={id} label={label} layout={layout} />;

  return WidgetComponent ? (
    <div className={`widget widget-${label}`}>
      <WidgetComponent id={id} />
      {isLoading && initialized ? <Spinner /> : null}
    </div>
  ) : null;
};

export default Widget;
