import TYPES from './types';

const INITIAL_STATE = {
  vessels: [],
  selectedDocumentType: null,
  isLoading: false
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_VESSELS_DOCUMENT_MANAGER.ERROR:
      return {
        ...state,
        isLoading: false,
        vessels: []
      };

    case TYPES.GET_VESSELS_DOCUMENT_MANAGER.START:
      return {
        ...state,
        isLoading: true,
        vessels: []
      };

    case TYPES.GET_VESSELS_DOCUMENT_MANAGER.SUCCESS:
      return {
        ...state,
        isLoading: false,
        vessels: Object.keys(payload).map(key => ({
          type: payload[key][0].type,
          documents: payload[key]
        }))
      };

    case TYPES.SET_SELECTED_DOCUMENT_TYPE:
      return {
        ...state,
        selectedDocumentType: payload
      };

    default:
      return state;
  }
};

export default reducer;
