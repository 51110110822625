import { useSelector } from 'react-redux';
import { select2faSetupIsRequired } from 'store/auth/selectors';

import Setup from './Setup';
import FillCode from './FillCode';
import PropTypes from 'prop-types';

const Authenticator = ({ ...props }) => {
  const setupIsRequired = useSelector(select2faSetupIsRequired);

  const Tag = setupIsRequired ? Setup : FillCode;

  return <Tag {...props} />;
};

Authenticator.propTypes = {
  code: PropTypes.object,
  setCode: PropTypes.func,
  onLogin: PropTypes.func,
  disabled: PropTypes.bool
};

export default Authenticator;
