import PropTypes from 'prop-types';
import ModularTable from 'common/components/ModularTable';

const WidgetModularTable = ({ state, label, rows, columns }) => {
  return (
    <div className={`widget-default-table`}>
      <ModularTable
        hideTableFilters={true}
        hideTableSearch={true}
        hideTopPagination={true}
        hideBottomPagination={true}
        hideTableReorder={true}
        highlightColumnOnHover={false}
        setPageMaxHeight={false}
        rows={rows}
        columns={columns}
        loader={false}
        label={label}
        state={state}
        style="transparent"
        isModularTableShownInWidget
      />
    </div>
  );
};

WidgetModularTable.propTypes = {
  id: PropTypes.number.isRequired, // widget id
  label: PropTypes.string,
  rows: PropTypes.object,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      type: PropTypes.string,
      header: PropTypes.string
    })
  ).isRequired
};

export default WidgetModularTable;
