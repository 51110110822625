import TYPES from './types';
import { deleteRequest, get, post } from 'utils/api';
import TABLE_TYPES from 'common/components/table/store/types';
import { successHandler } from 'common/utils/notifications';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getCrewExperience = params => dispatch => {
  const { id } = params;
  dispatch({ type: TYPES.GET_CREW_RANK_TIMELINE.START, payload: { params } });

  return get(`/crew/${id}/experience`)
    .then(res => {
      dispatch({ type: TYPES.GET_CREW_RANK_TIMELINE.SUCCESS, payload: res.data });
      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_CREW_RANK_TIMELINE.ERROR, payload: { response: error, params } });
      return error;
    });
};

export const getCrewExperienceCargoHandled = params => dispatch => {
  const table = 'crew_profile_skills_cargo_handled';
  dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.START, payload: { params, table } });

  return get(`/crew/${params.id}/cargo-handled`, params)
    .then(response => {
      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: { data: response.data, table }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } });

      throw error;
    });
};

export const addCrewSkillType = createAsyncThunk(
  'ADD_CREW_SKILL_TYPE',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { crew_id, ...rest } = params;
      const res = await post(`/crew/${crew_id}/skills`, rest);

      dispatch(successHandler({ title: 'Success!', message: 'Added successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const removeCrewSkillType = createAsyncThunk(
  'REMOVE_CREW_SKILL_TYPE',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { crew_skill_Id, ...rest } = params;
      const res = await deleteRequest(`/crew/skills/${crew_skill_Id}`, rest);

      dispatch(successHandler({ title: 'Success!', message: 'Removed successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
