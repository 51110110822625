import 'tools/assets/scss/_draft-dwt-tool.scss';
import React, { useState, useEffect } from 'react';
import Select from '../../../general/Select';
import NumberInput from '../../../general/NumberInput';
import { Row, Col } from 'reactstrap';
import { cargoSfOptions, salinityOptions, cargoOptions, calculate } from './_helpers';
import BinaryTabSwitch from '../../../general/BinaryTabSwitch';
import Card from './Card';
import { strToNumber } from 'common/utils/numbers';
import warningIcon from 'assets/svg/common/warning-empty.svg';
import calculatorIcon from 'tools/assets/svg/calculator-full.svg';
import SvgRender from 'common/components/general/SvgRender';
import components from './VesselSelectorComponents';

let initialCardValues = {
  draft: '',
  cargo: '',
  displacement: '',
  cubic: ''
};

const DraftDwt = ({ settings }) => {
  const [vessel, setVessel] = useState(null);
  const [fuels, setFuels] = useState(null);
  const [cargoSf, setCargoSf] = useState(null);
  const [cargoSfUnit, setCargoSfUnit] = useState('');
  const [constants, setConstants] = useState(null);
  const [salinity, setSalinity] = useState();
  const [sag, setSag] = useState(null);
  const [itemToCalculate, setItemToCalculate] = useState('Draft');

  const [cargo, setCargo] = useState('');
  const [draft, setDraft] = useState('');

  const [error, setError] = useState('');

  const [cargoUnit, setCargoUnit] = useState('');

  const [cardValues, setCardValues] = useState(initialCardValues);

  useEffect(() => {
    setCargoSfUnit(cargoSfOptions.find(el => el.default).id);
    setSalinity(salinityOptions.find(el => el.default).id);
    setCargoUnit(cargoOptions.find(el => el.default).id);
  }, []);

  const onChangeFuel = e => {
    setFuels(e.target.value);
  };

  const onChangeCargoSf = e => {
    setCargoSf(e.target.value);
  };

  const onChangeSag = e => {
    setSag(e.target.value);
  };

  const onChangeConstants = e => {
    setConstants(e.target.value);
  };

  const onChangeCargo = e => {
    setCargo(e.target.value);
  };

  const onChangeDraft = e => {
    setDraft(e.target.value);
  };

  const onSelectChange = (name, value) => {
    if (name === 'cargoSfUnit') {
      setCargoSfUnit(value);
    } else if (name === 'salinity') {
      setSalinity(value);
    } else if (name === 'cargoUnit') {
      setCargoUnit(value);
    }
  };

  const onChangeItemToCalculate = item => {
    setItemToCalculate(item);
  };

  const getInitialAsyncValues = async () => {
    let res = await settings.getInitialVessels();

    return res.data.length ? res.data : null;
  };

  const getSearchOptions = async search => {
    let res = await settings.getVessels(search);

    return res.data.length ? res.data : null;
  };

  const onVesselSelectChange = opt => {
    setVessel(opt);
  };

  useEffect(() => {
    if ((itemToCalculate === 'Draft' && !cargo) || (itemToCalculate === 'Cargo' && !draft)) return;

    setError('');
    if (!vessel) {
      setError('Please select a vessel');
      setCardValues(initialCardValues);
      return;
    }

    if (!vessel.ssw_tpc) {
      setError('Please set up T.P.C. on SSW of vessel');
      setCardValues(initialCardValues);
      return;
    }

    if (itemToCalculate === 'Draft' && strToNumber(draft) > +vessel.dwtSummer) {
      setError('Draft must be less than summer draft');
      setCardValues(initialCardValues);
      return;
    }

    if (itemToCalculate === 'Draft' && strToNumber(draft) < +vessel.lightShipDraft) {
      setError('Draft must be more than lightship draft');
      setCardValues(initialCardValues);
      return;
    }

    if (
      itemToCalculate === 'Cargo' &&
      strToNumber(cargo) + strToNumber(fuels) + strToNumber(constants) > +vessel.dwtSummer
    ) {
      setError('Total weight must not be more than summer DWT');
      setCardValues(initialCardValues);
      return;
    }

    if (
      itemToCalculate === 'Cargo' &&
      strToNumber(cargo) + strToNumber(fuels) + strToNumber(constants) < 0
    ) {
      setError('Invalid total weight');
      setCardValues(initialCardValues);
      return;
    }

    let params = {
      draft: draft ? strToNumber(draft) : 0,
      cargo: cargo ? strToNumber(cargo) : 0,
      cargoUnit,
      sag: sag ? strToNumber(sag) : 0,
      fuels: fuels ? strToNumber(fuels) : 0,
      constants: constants ? strToNumber(constants) : 0,
      cargoSf: cargoSf ? strToNumber(cargoSf) : 1,
      cargoSfUnit,
      salinity
    };

    if (itemToCalculate === 'Draft') {
      let result = calculate('draft', vessel, params);
      setCardValues({ ...cardValues, ...result });
      if (result.error) {
        setError(result.error);
      }
    } else {
      let result = calculate('cargo', vessel, params);
      setCardValues({ ...cardValues, ...result });
      if (result.error) {
        setError(result.error);
      }
    }
  }, [cargo, draft, fuels, cargoSf, constants, salinity, sag, vessel]);

  return (
    <div className="draft-dwt-tool pt-3">
      <Row>
        <Col xs={12}>
          <Select
            onChange={onVesselSelectChange}
            value={vessel}
            isAsync
            components={components}
            loadOptions={getSearchOptions}
            defaultOptions={getInitialAsyncValues}
            label="Vessel"
            placeholder="Select vessel"
          />
        </Col>
        <Col xs={4} className="mt-1">
          <NumberInput onChange={onChangeFuel} value={fuels} label="Fuels (mt)" />
        </Col>
        <Col xs={4} className="mt-1 ps-0">
          <NumberInput onChange={onChangeCargoSf} value={cargoSf} label="Cargo SF" />
        </Col>
        <Col xs={4} className="mt-1 ps-0">
          <Select
            onChange={opt => onSelectChange('cargoSfUnit', opt)}
            className="unit-select"
            value={cargoSfUnit}
            options={cargoSfOptions}
            placeholder="Unit"
          />
        </Col>
        <Col className="mt-1" xs={6}>
          <NumberInput onChange={onChangeConstants} value={constants} label="Constants (mt)" />
        </Col>
        <Col xs={6} className="mt-1 ps-0">
          <Select
            label="Salinity"
            onChange={opt => onSelectChange('salinity', opt)}
            value={salinity}
            options={salinityOptions}
          />
        </Col>
        {/* <Col xs={6} className="mt-1">
          <NumberInput onChange={onChangeSag} value={sag} label="Sag (cm)" />
        </Col> */}
      </Row>
      <div className="tool-divider my-2" />
      <Row>
        <Col xs={5}>
          <BinaryTabSwitch
            labelIcon={
              <SvgRender
                className="me-1 text-white"
                src={calculatorIcon}
                style={{ width: 13, height: 11 }}
              />
            }
            label="Calculate"
            option1="Draft"
            option2="Cargo"
            value={itemToCalculate}
            onChange={onChangeItemToCalculate}
          />
        </Col>
        {itemToCalculate === 'Draft' ? (
          <>
            <Col xs={4} className="ps-0">
              <NumberInput onChange={onChangeCargo} value={cargo} label="Cargo" />
            </Col>
            <Col xs={3} className="ps-0">
              <Select
                onChange={opt => onSelectChange('cargoUnit', opt)}
                className="unit-select"
                value={cargoUnit}
                options={cargoOptions}
                placeholder="Unit"
              />
            </Col>
          </>
        ) : (
          <Col xs={7} className="ps-0">
            <NumberInput onChange={onChangeDraft} value={draft} label="Draft" />
          </Col>
        )}

        <Col xs={4} className="cpe-4 mt-3">
          <Card
            value={itemToCalculate === 'Draft' ? cardValues.draft : cardValues.cargo}
            yellow
            title={itemToCalculate}
            unit={itemToCalculate === 'Draft' ? 'm' : 'mt'}
          />
        </Col>
        <Col xs={4} className="cpe-4 cps-4 mt-3">
          <Card value={cardValues.displacement} title="Displacement" unit="mt" />
        </Col>
        <Col xs={4} className="cps-4 mt-3">
          <Card
            title="Cubic"
            value={cardValues.cubic}
            unit={
              <span>
                m<sup>3</sup>
              </span>
            }
          />
        </Col>
      </Row>
      {error && (
        <Row className="mt-2">
          <Col xs={12} className="d-flex align-items-center text-red">
            <SvgRender src={warningIcon} style={{ width: 13, height: 11 }} />
            <div className=" fs-10 ms-1">{error}</div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DraftDwt;
