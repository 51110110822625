import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';

import Preferences from './Preferences';
import { useActions } from 'utils/hooks';
import * as tceWidgetActions from './store/actions';
import { useSelector } from 'react-redux';
import {
  selectActiveDashboardHasSidebar,
  selectWidgetPreferences
} from 'store/dashboards/selectors';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import VesselTCE from 'views/reports/voyages/tce-results/index';
import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import paths from 'routing/routes/_paths';

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'changeView':
      return {
        ...state,
        view: payload
      };
    case 'setMarketIndex':
      return {
        ...state,
        marketIndex: payload
      };
    case 'setDates':
      return {
        ...state,
        dates: {
          ...state.dates,
          [payload.type === 'date_from' ? 'from' : 'to']: payload.date
        }
      };
    case 'setProfile':
      return {
        ...state,
        profile: payload
      };
    case 'setTceResults':
      return {
        ...state,
        tceResults: payload
      };
    case 'setTceAverages':
      return {
        ...state,
        tceAverages: { data: payload }
      };

    case 'setChartData':
      return {
        ...state,
        chartData: { data: payload }
      };

    default:
      return state;
  }
};

const VesselTceWidget = ({ id }) => {
  const [state, dispatch] = useReducer(reducer, {
    profile: {},
    marketIndex: null,
    isLoading: false,
    chartData: { data: { graph: [] } },
    tceResults: [],
    tceAverages: {},
    dates: { from: moment().subtract(12, 'month').startOf('month'), to: moment() },
    view: 'monthly'
  });

  const { profile, marketIndex, isLoading, chartData, tceResults, tceAverages, dates, view } =
    state;

  const { vessel_id } = useSelector(state => selectWidgetPreferences(state, id));
  const hasSidebar = useSelector(selectActiveDashboardHasSidebar);
  const vessels = useSelector(state => selectListOptionsFromStore(state, 'vessels'));

  const [getWidgetVesselProfile, getWidgetVesselTceResults, getWidgetVesselTceResultsGraph] =
    useActions([
      tceWidgetActions.getWidgetVesselProfile,
      tceWidgetActions.getWidgetVesselTceResults,
      tceWidgetActions.getWidgetVesselTceResultsGraph
    ]);

  const indexOptions = profile?.market_indexes
    ? profile.market_indexes.map(el => ({ id: el.id, name: el.name }))
    : [];

  const onViewChange = value => dispatch({ type: 'changeView', payload: value });

  const onMarketIndexSelectChange = value => dispatch({ type: 'setMarketIndex', payload: value });

  const onDateChange = (date, type) => dispatch({ type: 'setDates', payload: { date, type } });

  const fetchChartData = useCallback(
    async id => {
      try {
        const params = {
          vessel_id,
          market_index_id: marketIndex || undefined,
          date_from: dates.from.format('YYYY-MM-DD'),
          date_to: dates.to.format('YYYY-MM-DD'),
          type: 'daily'
        };

        const res = await getWidgetVesselTceResultsGraph(params);
        dispatch({ type: 'setChartData', payload: res.payload });
      } catch (error) {
        console.error(error);
      }
    },
    [getWidgetVesselTceResultsGraph, marketIndex, dates]
  );

  const fetchTceResults = useCallback(
    async ({ isAverage }) => {
      const params = {
        vessel_id,
        market_index_id: marketIndex || undefined,
        date_from: dates.from.format('YYYY-MM-DD'),
        date_to: dates.to.format('YYYY-MM-DD'),
        type: isAverage ? 'averages' : view
      };

      try {
        const res = await getWidgetVesselTceResults(params);
        const returnal = isAverage ? res.payload : res.payload?.data;

        dispatch({
          type: isAverage ? 'setTceAverages' : 'setTceResults',
          payload: returnal
        });

        return returnal;
      } catch (error) {
        console.error(error);
      }
    },
    [getWidgetVesselTceResults, vessel_id, marketIndex, dates, view]
  );

  const fetchVesselProfile = useCallback(
    async id => {
      try {
        const res = await getWidgetVesselProfile({ id });
        dispatch({ type: 'setProfile', payload: res.payload });
      } catch (error) {
        console.error(error);
      }
    },
    [getWidgetVesselProfile]
  );

  const vesselName = useMemo(() => {
    return vessels?.find(v => v.id === vessel_id)?.name;
  }, [vessel_id, vessels]);

  useEffect(() => {
    if (!vessel_id) return;

    fetchVesselProfile(vessel_id);
    fetchChartData();
    fetchTceResults({ isAverage: true });
  }, [fetchVesselProfile, fetchChartData, fetchTceResults, vessel_id]);

  if (!vessel_id) return null;

  return (
    <div className="widget-tce-vessel_table">
      <WidgetTitle
        id={id}
        linkTo={`${paths.reports_voyage}/vessel-tce-results`}
        vesselName={vesselName}
      />
      <VesselTCE widths={[6, 6]} showAllFilters={false} />
    </div>
  );
};

export const VesselTcePreferences = Preferences;

export default VesselTceWidget;
