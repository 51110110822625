import TYPES from './types';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  getRootItemsIDs,
  getSubItems,
  normalizeTreeStructure,
  getTreeOfSearchedItems,
  getAscendanceTree
} from 'common/components/tree-layout/utils/helpers';
import { parseQueryParams } from 'common/utils/urls';

import _mapValues from 'lodash/mapValues';
import paths from 'routing/routes/_paths';

const INITIAL_STATE = {
  form: {
    isOpen: false,
    type: '',
    accountID: null,
    parentAccountID: null
  },

  search: '',

  isLoading: false,

  accounts: {},
  rootAccounts: [],
  subAccounts: {},
  accountsInSeach: {},
  accountsLevel: {},

  deleteAccountModal: { isOpen: false, accountID: null },
  activeView: ''
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.GET_ACCOUNTING_ACCOUNTS.START:
      return { ...state, isLoading: true };

    case TYPES.GET_ACCOUNTING_ACCOUNTS.SUCCESS:
      const subAccounts = getSubItems(payload.data);
      const accounts = normalizeTreeStructure(
        payload.data.map((a, i) => ({ ...a, sort_index: i }))
      );

      return {
        ...state,
        isLoading: false,
        rootAccounts:
          state.activeView === 'groups'
            ? getRootItemsIDs(payload.data) // Show group accounts as a Tree
            : payload.data.map(({ id }) => id), // Show accounts as a Flat List
        subAccounts,
        accounts,
        accountsInSearch: payload?.params?.search
          ? getTreeOfSearchedItems(state.accounts, state.subAccounts, payload.data)
          : {},
        accountsLevel: _mapValues(accounts, account =>
          account.parent_id ? getAscendanceTree(account.id, accounts)?.length : 1
        )
      };

    case TYPES.GET_ACCOUNTING_ACCOUNTS.ERROR:
      return { ...state, isLoading: false, rootAccounts: [], subAccounts: {} };

    case TYPES.EDIT_ACCOUNTING_ACCOUNT.SUCCESS:
      return {
        ...state,
        accounts: { ...state.accounts, [payload.id]: { ...state.accounts[payload.id], ...payload } }
      };

    case TYPES.SET_ACCOUNTING_ACCOUNT_PREVENTION_MODAL:
      return {
        ...state,
        deleteAccountModal: { isOpen: payload.isOpen, accountID: payload.accountID }
      };

    case TYPES.SET_ACCOUNTING_ACCOUNT_FORM:
      return {
        ...state,
        form: { ...payload }
      };

    case LOCATION_CHANGE:
      return {
        ...state,
        activeView: payload.location.pathname.startsWith(paths.accounting_coa_groups_settings)
          ? 'groups'
          : 'accounts',
        rootAccounts: [],
        search: payload.location.search?.includes('searchText')
          ? parseQueryParams(payload.location.search)?.searchText
          : '',
        form: {
          isOpen: false,
          type: '',
          active: null
        }
      };

    default:
      return state;
  }
};

export default reducer;
