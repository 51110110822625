import { createSelector } from 'reselect';

const selectEnvironmentalReducer = state => state.reports.environmental;
const selectVesselCiiProfileReport = state => selectEnvironmentalReducer(state)?.vesselCiiProfile;

export const selectVesselCiiProfileReportCIIReference = state =>
  selectVesselCiiProfileReport(state)?.data?.cii_reference;

export const selectVesselCiiProfileReportBallast = state =>
  selectVesselCiiProfileReport(state)?.data?.ballast;

export const selectVesselCiiProfileReportDayTotals = state =>
  selectVesselCiiProfileReport(state)?.data?.day_totals;

export const selectVesselCiiProfileReportAverageConsumptionRate = state =>
  selectVesselCiiProfileReport(state)?.data?.port_average_consumption_rate;

export const selectVesselCiiProfileReportGraphBallast = state =>
  selectVesselCiiProfileReport(state)?.data?.graphs?.ballast;

export const selectVesselCiiProfileReportGraphLaden = state =>
  selectVesselCiiProfileReport(state)?.data?.graphs?.laden;

export const selectVesselCiiProfileReportIsFetching = state =>
  selectVesselCiiProfileReport(state)?.isFetching;

export const selectVesselCiiProfileReportIsGraphFetching = state =>
  selectVesselCiiProfileReport(state)?.isGraphFetching;

// Voyage Forecast
const selectVesselVoyageForecast = state => selectVesselCiiProfileReport(state)?.voyageForecast;

export const selectVesselVoyageForecastIsLoading = state =>
  selectVesselVoyageForecast(state)?.is_loading;

export const selectVesselVoyageForecastIsSaving = state =>
  selectVesselVoyageForecast(state)?.is_saving;

export const selectVesselVoyageForecastIsAddingNew = state =>
  selectVesselVoyageForecast(state)?.is_adding_new;

export const selectVesselVoyageForecastIsSavingNew = state =>
  selectVesselVoyageForecast(state)?.is_saving_new;

export const selectVesselVoyageForecastNewPortState = state =>
  selectVesselVoyageForecast(state)?.new_port_state;

export const selectVesselVoyageForecastVesselId = state =>
  selectVesselVoyageForecast(state)?.vessel_id;

export const selectVesselVoyageForecastDataFrom = state =>
  selectVesselVoyageForecast(state)?.data_from;

export const selectVesselVoyageForecastCalculatedDataFrom = state =>
  selectVesselVoyageForecast(state)?.calculated_data_from;

export const selectVesselVoyageForecastHasDynamicData = createSelector(
  selectVesselVoyageForecastDataFrom,
  dataFrom => dataFrom === 'dynamic_data'
);

export const selectVesselCiiprofileReportIsEmpty = createSelector(
  [
    selectVesselCiiProfileReportBallast,
    selectVesselCiiProfileReportDayTotals,
    selectVesselCiiProfileReportGraphBallast,
    selectVesselCiiProfileReportIsFetching
  ],
  (ballast, totals, graphBallast, isFetching) => {
    if (isFetching) {
      return false;
    } else if (
      !isFetching &&
      (ballast?.length || graphBallast?.length || totals?.days_at_port || totals?.days_at_sea)
    ) {
      return false;
    } else return true;
  }
);
