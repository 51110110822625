import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import PropTypes from 'prop-types';

const WidgetLabel = ({ customLabel }) => {
  return (
    <div
      className={`widget__label d-flex align-items-center height-20 border-radius-3 px-1 fs-12 cpy-4 text-${customLabel.color}`}
    >
      <SvgRender className={`me-1`} src={customLabel.icon} style={{ height: 12, width: 12 }} />
      {customLabel?.text}
    </div>
  );
};

WidgetLabel.propTypes = {
  customLabel: PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
    color: PropTypes.string
  })
};

export default WidgetLabel;
