import SvgRender from 'common/components/general/SvgRender';
import send from 'common/assets/svg/actions/send.svg';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { numberToStr } from 'common/utils/numbers';
import { DrawerState } from '@/common/entities/drawer/DrawerTypes';
import usePrevious from '@/ts-common/utils/hooks/usePrevious';

const SendToAccountingLabel = ({ data, drawer }) => {
  const { avoidRender, tooltipID } = useTooltipID(`is-sent-to-accounting-label`);
  const prevTooltipID = usePrevious(tooltipID); // Fixes re-rendering bug with tooltipID

  return (
    <div
      className={`${
        data.sent_to_accounting ? 'bg-turquoise' : 'bg-white'
      }  border border-turquoise d-flex align-items-center justify-content-center me-0 border-radius-3 width-20`}
      id={tooltipID}
    >
      <SvgRender
        src={send}
        style={{ width: 11, height: 11, color: data.sent_to_accounting ? 'white' : '#01d9a4' }}
      />
      {tooltipID &&
      !avoidRender &&
      !drawer.isOpen &&
      (prevTooltipID === tooltipID || !prevTooltipID) ? (
        <Tooltip
          target={tooltipID}
          innerClassName={data.sent_to_accounting ? 'min-width-412 max-width-412' : ''}
          fade={false}
        >
          {data.sent_to_accounting ? (
            <div className="d-flex flex-column text-start fs-12">
              <div className="border-bottom border-davys-gray cpb-2 cpt-2">Sent to Accounting</div>
              <div className="d-flex flex-column align-items-start mt-1">
                <div className="fs-10 fw-bold">Document type</div>
                <div>{data?.document_type ?? '-'}</div>
              </div>
              <Row className="fs-10 fw-bold cmt-12 text-nowrap">
                <Col xs={4}>Company</Col>
                <Col xs={4}>Account</Col>
                <Col xs={4}>Local Amount</Col>
              </Row>
              {data?.accounting_companies?.map((c, index) => (
                <Row key={index}>
                  <Col xs={4} className="text-truncate">
                    {c?.accounting_company?.name ?? '-'}
                  </Col>
                  <Col xs={4} className="text-truncate">
                    {c.account?.code}.{c.account?.name ?? '-'}
                  </Col>
                  <Col xs={4} className="d-flex justify-content-between">
                    <span>{numberToStr(c.local_amount, 2, 2)}</span>
                    {c.local_amount_percentage ? (
                      <strong>{numberToStr(c.local_amount_percentage, 2, 2)}%</strong>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
              ))}
            </div>
          ) : (
            'Not Sent To Accounting'
          )}
        </Tooltip>
      ) : null}
    </div>
  );
};

SendToAccountingLabel.propTypes = {
  drawer: DrawerState,
  data: PropTypes.shape({
    sent_to_accounting: PropTypes.bool,
    document_type: PropTypes.string,
    accounting_company: PropTypes.shape({
      name: PropTypes.string
    }),
    account: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string
    })
  })
};

export default SendToAccountingLabel;
