import 'tools/assets/scss/_calculator.scss';
import React, { Component } from 'react';

import CalculatorDisplay from './CalculatorDisplay';
import CalculatorKey from './CalculatorKey';

import equals from 'tools/assets/svg/equals.svg';

const CalculatorOperations = {
  '/': (prevValue, nextValue) => prevValue / nextValue,
  '*': (prevValue, nextValue) => prevValue * nextValue,
  '+': (prevValue, nextValue) => prevValue + nextValue,
  '-': (prevValue, nextValue) => prevValue - nextValue,
  '=': (prevValue, nextValue) => nextValue
};

class Calculator extends Component {
  state = {
    value: null,
    displayValue: '0',
    operator: null,
    waitingForOperand: false,
    isFocused: false
  };

  clearAll() {
    this.setState({
      value: null,
      displayValue: '0',
      operator: null,
      waitingForOperand: false
    });
  }

  clearDisplay() {
    this.setState({
      displayValue: '0'
    });
  }

  clearLastChar() {
    const { displayValue } = this.state;

    this.setState({
      displayValue: displayValue.substring(0, displayValue.length - 1) || '0'
    });
  }

  toggleSign() {
    const { displayValue } = this.state;
    const newValue = parseFloat(displayValue) * -1;

    this.setState({
      displayValue: String(newValue)
    });
  }

  pressInputEffect = key => {
    const el = document.querySelector(`.key-${key}`);
    if (el) {
      el.classList.add('pressed');
      setTimeout(() => el.classList.remove('pressed'), 200);
    }
  };

  inputPercent() {
    const { displayValue } = this.state;
    const currentValue = parseFloat(displayValue);

    if (currentValue === 0) return;

    const fixedDigits = displayValue.replace(/^-?\d*\.?/, '');
    const newValue = parseFloat(displayValue) / 100;

    this.setState({
      displayValue: String(newValue.toFixed(fixedDigits.length + 2))
    });
  }

  inputDot() {
    const { displayValue } = this.state;
    this.pressInputEffect('dot');

    if (!/\./.test(displayValue)) {
      this.setState({
        displayValue: displayValue + '.',
        waitingForOperand: false
      });
    }
  }

  inputDigit(digit) {
    const { displayValue, waitingForOperand } = this.state;

    this.pressInputEffect(digit);

    document.getElementById('calculator_input').focus();

    if (waitingForOperand) {
      this.setState({
        displayValue: String(digit),
        waitingForOperand: false
      });
    } else {
      this.setState({
        displayValue: displayValue === '0' ? String(digit) : displayValue + digit
      });
    }
  }

  performOperation(nextOperator) {
    const { value, displayValue, operator } = this.state;
    const inputValue = parseFloat(displayValue);

    document.getElementById('calculator_input').focus();

    if (value == null) {
      this.setState({
        value: inputValue
      });
    } else if (operator) {
      const currentValue = value || 0;
      const newValue = CalculatorOperations[operator](currentValue, inputValue);

      this.setState({
        value: newValue,
        displayValue: String(newValue)
      });
    }

    this.setState({
      waitingForOperand: true,
      operator: nextOperator
    });
  }

  handleKeyDown = event => {
    if (!this.props.isSidebarOpen || !this.state.isFocused) return;

    let { key } = event;

    if (key === 'Enter') key = '=';

    if (/\d/.test(key)) {
      event.preventDefault();
      this.inputDigit(parseInt(key, 10));
    } else if (key in CalculatorOperations) {
      event.preventDefault();
      this.performOperation(key);

      if (key === '/') {
        this.pressInputEffect('divide');
      } else if (key === '*') {
        this.pressInputEffect('multiply');
      } else if (key === '+') {
        this.pressInputEffect('add');
      } else if (key === '-') {
        this.pressInputEffect('subtract');
      }
    } else if (key === '.') {
      event.preventDefault();
      this.inputDot();
    } else if (key === '%') {
      event.preventDefault();
      this.inputPercent();
      this.pressInputEffect('percent');
    } else if (key === 'Backspace') {
      event.preventDefault();
      this.clearLastChar();
      this.pressInputEffect('clear');
    } else if (key === 'Clear') {
      event.preventDefault();
      this.pressInputEffect('clear');

      if (this.state.displayValue !== '0') {
        this.clearDisplay();
      } else {
        this.clearAll();
      }
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  render() {
    const { displayValue } = this.state;

    const clearDisplay = displayValue !== '0';
    const clearText = clearDisplay ? 'C' : 'AC';

    return (
      <div className="calculator-tool">
        <CalculatorDisplay
          value={displayValue}
          isFocused={this.state.isFocused}
          setFocused={focus => this.setState({ isFocused: focus })}
          handleChange={num => this.inputDigit(num)}
        />
        <div className="calculator-keypad row g-0">
          <div className="input-keys col-9">
            <div className="function-keys d-flex">
              <CalculatorKey
                className="key-clear"
                onPress={() => (clearDisplay ? this.clearDisplay() : this.clearAll())}
              >
                {clearText}
              </CalculatorKey>
              <CalculatorKey className="key-sign" onPress={() => this.toggleSign()}>
                ±
              </CalculatorKey>
              <CalculatorKey className="key-percent" onPress={() => this.inputPercent()}>
                %
              </CalculatorKey>
            </div>
            <div className="digit-keys">
              <div className="digit-keys--row">
                <CalculatorKey className="key-7" onPress={() => this.inputDigit(7)}>
                  7
                </CalculatorKey>
                <CalculatorKey className="key-8" onPress={() => this.inputDigit(8)}>
                  8
                </CalculatorKey>
                <CalculatorKey className="key-9" onPress={() => this.inputDigit(9)}>
                  9
                </CalculatorKey>
              </div>

              <div className="digit-keys--row">
                <CalculatorKey className="key-4" onPress={() => this.inputDigit(4)}>
                  4
                </CalculatorKey>
                <CalculatorKey className="key-5" onPress={() => this.inputDigit(5)}>
                  5
                </CalculatorKey>
                <CalculatorKey className="key-6" onPress={() => this.inputDigit(6)}>
                  6
                </CalculatorKey>
              </div>

              <div className="digit-keys--row">
                <CalculatorKey className="key-1" onPress={() => this.inputDigit(1)}>
                  1
                </CalculatorKey>
                <CalculatorKey className="key-2" onPress={() => this.inputDigit(2)}>
                  2
                </CalculatorKey>
                <CalculatorKey className="key-3" onPress={() => this.inputDigit(3)}>
                  3
                </CalculatorKey>
              </div>

              <div className="digit-keys--row">
                <div className="calculator-key">&nbsp;</div>
                <CalculatorKey className="key-0" onPress={() => this.inputDigit(0)}>
                  0
                </CalculatorKey>
                <CalculatorKey className="key-dot" onPress={() => this.inputDot()}>
                  .
                </CalculatorKey>
              </div>
            </div>
          </div>
          <div className="operator-keys col-3">
            <CalculatorKey className="key-divide" onPress={() => this.performOperation('/')}>
              ÷
            </CalculatorKey>
            <CalculatorKey className="key-multiply" onPress={() => this.performOperation('*')}>
              ×
            </CalculatorKey>
            <CalculatorKey className="key-subtract" onPress={() => this.performOperation('-')}>
              −
            </CalculatorKey>
            <CalculatorKey className="key-add" onPress={() => this.performOperation('+')}>
              +
            </CalculatorKey>
            <div className="calculator-key">&nbsp;</div>
          </div>
        </div>

        <CalculatorKey className="key-equals" onPress={() => this.performOperation('=')}>
          <img src={equals} width={10} height={10} alt="equals icon" />
        </CalculatorKey>
      </div>
    );
  }
}

export default Calculator;
