import { hiddenField, optionField, stringField, dateField } from 'common/utils/form/fieldTypes';

const config = {
  id: hiddenField(),
  type: stringField({ initialValue: 'person' }),

  // Main Info
  first_name: stringField({ required: true }),
  middle_name: stringField(),
  last_name: stringField({ required: true }),
  company: optionField(),
  position: stringField(),
  birthday: dateField(),

  email: stringField(),
  emails: {
    type: optionField({ initialValue: 'personal' }),
    value: stringField()
  },
  phone: stringField(),
  contact_info: {
    type: optionField({ initialValue: 'phone' }),
    value: stringField()
  },

  // Address
  address: stringField(),
  city: stringField(),
  postal_code: stringField(),
  country: optionField(),
  state: stringField(),

  // Passport
  passport_number: stringField(),
  passport_issued_at: dateField(),
  passport_expires_at: dateField(),
  passport_attachment: optionField(),

  // Seaman Book
  seaman_book_number: stringField(),
  seaman_book_issued_at: dateField(),
  seaman_book_expires_at: dateField(),
  seaman_book_attachment: optionField(),

  // Covid Vaccination
  covid_vaccination_date: dateField(),
  covid_vaccination_remarks: stringField(),

  // International Vaccination
  international_vaccination_date: dateField(),
  international_vaccination_remarks: stringField()
};

export default config;
