import AUTH_TYPES from 'store/auth/types';
import jwt_decode from 'jwt-decode';
import { persistor } from 'store';

const authMiddleware =
  _ =>
  next =>
  ({ type, payload, ...rest }) => {
    switch (type) {
      case AUTH_TYPES.AUTHORIZE_LOGIN:
      case AUTH_TYPES.REFRESH_TOKEN.SUCCESS:
        window.localStorage.setItem('accessToken', payload.access_token);
        window.localStorage.setItem('refreshToken', payload.refresh_token);
        window.localStorage.setItem('tokenExpiresAt', jwt_decode(payload.access_token).exp);
        break;

      case AUTH_TYPES.LOGOUT_CLEANUP:
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('refreshToken');
        window.localStorage.removeItem('tokenExpiresAt');
        persistor.purge();

        break;

      default:
        break;
    }

    next({ type, payload, ...rest });
  };

export default authMiddleware;
