import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SvgRender from 'common/components/general/SvgRender';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectActiveDashboardIsShared } from 'store/dashboards/selectors';
import { useActions } from 'utils/hooks';
import * as dashboardActions from 'store/dashboards/actions';

import deleteSVG from 'common/assets/svg/actions/delete.svg';
import settings from 'common/assets/svg/actions/settings.svg';
import dots from 'common/assets/svg/actions/three-dots.svg';

const WidgetActions = ({ id, className = '' }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const isShared = useSelector(selectActiveDashboardIsShared);

  const [setWidgetSettingsForm, setWidgetPreventionModal] = useActions([
    dashboardActions.setWidgetSettingsForm,
    dashboardActions.setWidgetPreventionModal
  ]);

  if (isShared) return null;

  return (
    <>
      <Dropdown
        direction="down"
        isOpen={isSettingsOpen}
        toggle={() => setIsSettingsOpen(!isSettingsOpen)}
        className={`widget-dropdown ms-1 ${className}`}
      >
        <DropdownToggle
          color="link"
          className="px-1 py-0 d-inline-flex align-items-center justify-content-center"
        >
          <SvgRender src={dots} style={{ height: 18, width: 10 }} />
        </DropdownToggle>
        <DropdownMenu className="mt-1" end={true}>
          <DropdownItem
            tag="div"
            className="btn d-flex align-items-center fw-light"
            onClick={() => setWidgetSettingsForm({ isOpen: true, activeID: id })}
          >
            <SvgRender src={settings} style={{ height: 16, width: 16 }} className="me-1" />
            Edit
          </DropdownItem>
          <DropdownItem
            tag="div"
            className="btn d-flex align-items-center fw-light"
            onClick={() => setWidgetPreventionModal({ isOpen: true, activeID: id })}
          >
            <SvgRender src={deleteSVG} style={{ height: 16, width: 16 }} className="me-1" />
            Remove
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

WidgetActions.propTypes = {
  id: PropTypes.number.isRequired // widget id
};

export default WidgetActions;
