import { useQuery, queryOptions } from '@tanstack/react-query';
import { getEntityLogs } from './api';
import { ENTITY_LOGS } from './query-keys';
import { EntityLogsRequestParams } from '@/common/types/logs';

export const useEntityLogs = (
  options: { enabled: boolean; refetchOnMount: boolean } = { enabled: true, refetchOnMount: true },
  params: EntityLogsRequestParams
) => {
  return useQuery(
    queryOptions({
      queryKey: [ENTITY_LOGS, params],
      queryFn: () => getEntityLogs(params),
      retry: false,
      ...options
    })
  );
};
