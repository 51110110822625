import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetModularTable from 'views/dashboard/components/WidgetModularTable';
import PropTypes from 'prop-types';
import paths from 'routing/routes/_paths';
import { useSelector } from 'react-redux';
import { selectWidgetState, selectActiveDashboardHasSidebar } from 'store/dashboards/selectors';
import { constructColumns } from 'common/components/table/utils/modular-table/helpers';
import ProgressBar from 'views/reports/pms/components/ProgressBar';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import { stringifyObj } from 'utils/urls';
import { columns } from './columns';
import { useMemo } from 'react';
import { numberToStr } from '@/ts-common/utils/numbers';
import _mapValues from 'lodash/mapValues';

const OverdueJobs = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));
  const hasSidebar = useSelector(selectActiveDashboardHasSidebar);

  const modifiedColumns = hasSidebar
    ? columns?.map(c => {
        return { ...c, width: c.closedWidth };
      })
    : columns?.map(c => {
        return { ...c, width: c.openWidth };
      });

  const totals = useMemo(() => {
    return _mapValues(state?.totals || {}, t => numberToStr(t));
  }, [state?.totals]);

  return (
    <div>
      <WidgetTitle
        id={id}
        linkTo={`${paths?.pms_jobs}?${stringifyObj({
          filters: [
            {
              name: 'is_overdue',
              operation: '=',
              value: 'true'
            }
          ]
        })}`}
        withActions={false}
      />
      <WidgetModularTable
        state={{ ...state, totals }}
        label={label}
        columns={[...constructColumns([...modifiedColumns], [], label)]}
        rows={{
          overdue_percentage: data => {
            const percentage = data?.overdue_percentage;
            return (
              <ProgressBar value={percentage} label={label} widgetPercentage={state?.percentages} />
            );
          },
          actions: data => {
            return {
              options: [
                {
                  label: 'View',
                  icon: eyeSvg,
                  onClick: () =>
                    window.open(
                      `${paths?.pms_jobs}?${stringifyObj({
                        filters: [
                          {
                            name: 'vessel_id',
                            operation: 'oneOf',
                            value: [data.id]
                          },
                          {
                            name: 'is_overdue',
                            operation: '=',
                            value: 'true'
                          }
                        ]
                      })}`,
                      '_blank'
                    )
                }
              ]
            };
          }
        }}
      />
    </div>
  );
};

OverdueJobs.propTypes = {
  id: PropTypes.number
};

export default OverdueJobs;
