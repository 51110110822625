import { combineReducers } from 'redux';
import crew from './crew';
import voyage from './voyage';
import events from './events';
import performance from './performance';
import environmental from './environmental';
import pms from './pms';
import accounting from './accounting';

export default combineReducers({
  crew,
  voyage,
  events,
  performance,
  environmental,
  pms,
  accounting
});
