import axios, { AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import store from 'store';
import moment from 'moment';

import { stringifyObj } from 'utils/urls';
import { refreshTokens } from 'store/auth/actions';
import { getAccountPermissions } from 'store/account/actions';
import { authorizeLogin, logoutCleanup } from 'store/auth/slice';
import { selectIsAuthenticated } from 'store/auth/selectors';

export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

export const authHeader = () => {
  const token = window.localStorage.getItem('accessToken');

  return { Authorization: `Bearer ${token}` };
};

export const REFRESH_TOKEN_REQUEST_URL = '/verification/refresh';

/* If any of these URLs, use AUTH_API or VTS_API */
const interceptedUrls = {
  vts: ['/vessel-route-detailed', '/benchmarks/conditions', '/benchmarks/types'],
  vessel: ['/orca/info']
};

type ReturnedHeadersType = {
  Accept: string;
  'Content-Type': string;
  Authorization?: string;
};

export const getApiBaseUrl = (path: string, fullURL?: boolean) => {
  if (interceptedUrls.vts.includes(path)) return import.meta.env.VITE_VTS_API_URL;
  else if (interceptedUrls.vessel.includes(path)) return import.meta.env.VITE_VESSEL_API_URL;

  return fullURL ? import.meta.env.VITE_FULL_API_URL : import.meta.env.VITE_API_URL;
};

export const getURL = (path: string, fullURL?: boolean) =>
  getApiBaseUrl(path, fullURL) + (path.startsWith('/') ? path : '/' + path);

const getHeaders = (auth?: boolean) => {
  let headers = { ...defaultHeaders };

  if (auth) {
    headers = { ...headers, ...authHeader() };
  }

  return headers;
};

const apiService = import.meta.env.MODE === 'test' ? axios : axios.create({});

export const get = <T>(
  path: string,
  params: Record<string, any> = {},
  auth = true
): Promise<AxiosResponse<T>> =>
  apiService.get(getURL(path), {
    params,
    headers: getHeaders(auth),
    paramsSerializer: params => stringifyObj(params)
  });

export const post = <T>(
  path: string,
  params: Record<string, any> = {},
  auth = true,
  headers?: object
): Promise<AxiosResponse<T>> => {
  return apiService.post(getURL(path), params, { headers: headers || getHeaders(auth) });
};

export const put = <T>(
  path: string,
  params: Record<string, any> = {},
  auth = true
): Promise<AxiosResponse<T>> =>
  apiService.put(getURL(path), params, {
    headers: getHeaders(auth)
  });

type DeleteParamsType = {
  data?: Record<string, unknown>;
};
export const deleteRequest = <T>(
  path: string,
  params: DeleteParamsType = {},
  auth = true
): Promise<AxiosResponse<T>> => {
  const { data, ...rest } = params;
  return apiService.delete(getURL(path), { params: rest, headers: getHeaders(auth), data });
};

export const upload = <T>(
  path: string,
  params = {},
  config: Record<string, unknown> = {}
): Promise<AxiosResponse<T>> =>
  apiService.post(getURL(path, true), params, {
    headers: { ...getHeaders(true), 'content-type': 'multipart/form-data' },
    ...config
  });

type DownloadConfigType = {
  responseType: 'blob';
  params: Record<string, unknown>;
  headers: ReturnedHeadersType;
  paramsSerializer?: (params: Record<string, unknown>) => string;
};

export const download = <T = Blob>(
  path: string,
  params: Record<string, unknown> = {},
  parsed?: boolean,
  customHeaders: object = {}
): Promise<AxiosResponse<T>> | null => {
  let config: DownloadConfigType = {
    responseType: 'blob',
    params,
    headers: { ...getHeaders(true), ...customHeaders }
  };
  if (parsed) {
    config = {
      ...config,
      paramsSerializer: params => stringifyObj(params)
    };
  }
  const isUrlFromS3 = path.includes('s3');
  const isUrlPublic = path.includes('public-files');

  if (isUrlFromS3 || isUrlPublic) {
    window.open(isUrlPublic ? getURL(path, true) : path, '_blank');
    return null;
  }

  return apiService.get(getURL(path, true), config);
};

type TokenResponseType = {
  access_token: string | null;
};

let refreshingTokens: Promise<TokenResponseType | undefined> | null = null;
let refreshTokenRequested = false;

const handleTokenRefresh = async (config: InternalAxiosRequestConfig) => {
  const res = await refreshingTokens;

  if (res && res.access_token) {
    const Authorization = `Bearer ${res.access_token}`;
    refreshTokenRequested = false;

    return {
      ...config,
      headers: { ...config.headers, Authorization } as AxiosRequestHeaders
    };
  } else {
    refreshTokenRequested = false;
  }

  return config;
};

(function checkIfAuthStatusHasChanged() {
  window.addEventListener(
    'focus',
    () => {
      if (window.localStorage.getItem('refreshTokenPending') === 'true') return;

      if (window.localStorage.getItem('accessToken') && !selectIsAuthenticated(store.getState())) {
        // if user has logged in another tab, log him in this tab too
        // console.log('Tab Focus - Updating tab token');

        store.dispatch(
          authorizeLogin({
            access_token: window.localStorage.getItem('accessToken'),
            refresh_token: window.localStorage.getItem('refreshToken')
          })
        );
      } else if (
        !window.localStorage.getItem('accessToken') &&
        selectIsAuthenticated(store.getState())
      ) {
        // if user is logged in this tab, but the token is missing, log him out
        // console.log('Tab Focus - Will logout user from this tab');
        store.dispatch(logoutCleanup());
      }
    },
    false
  );

  window.addEventListener('beforeunload', () => {
    // Tab is closing
    if (refreshTokenRequested) {
      /* This tab has requested a new refresh token and the request is still pending.
        We have to reset the refreshTokenPending so that the other orca tabs (if any) won't stay on pending mode.
        The user will be logged out when he navigates on any other tab.
      */
      window.localStorage.setItem('refreshTokenPending', 'false');
    }
  });
})();

const refreshFunc = async () => {
  if (window.localStorage.getItem('refreshTokenPending') === 'true') {
    // console.log('Another tab is already requesting a new token');

    const detectLocalStorageUpdate = (
      e: StorageEvent,
      resolve?: (value: TokenResponseType) => void
    ) => {
      if (e.key === 'refreshTokenPending') {
        if (e.oldValue === 'true' && e.newValue === 'false') {
          // Add some timeout to make sure the new access token is stored in cookies
          // console.log('The new token is set');
          if (resolve)
            setTimeout(
              () => resolve({ access_token: window.localStorage.getItem('accessToken') }),
              300
            );
        }
      }
    };

    const requesting = new Promise(resolve => {
      window.addEventListener('storage', e => detectLocalStorageUpdate(e, resolve), false);
    }).then(data => {
      window.removeEventListener('storage', detectLocalStorageUpdate, false);
      return data;
    });

    return requesting;
  } else {
    // console.log('Will request new token');
    window.localStorage.setItem('refreshTokenPending', 'true');

    return store.dispatch(refreshTokens()).then(data => {
      // console.log('The new access token', data);
      window.localStorage.setItem('refreshTokenPending', 'false');

      return data;
    });
  }
};

// API request interceptor
export function setupRequestInterceptors() {
  apiService.interceptors.request.use(
    config => {
      // Check if the request has expired token. Request new tokens if it does.
      const tokenExpiration = window.localStorage.getItem('tokenExpiresAt');

      if (refreshTokenRequested && config?.url?.includes(REFRESH_TOKEN_REQUEST_URL)) {
        return config;
      } else if (refreshTokenRequested) {
        return handleTokenRefresh(config);
      } else if (tokenExpiration && moment().unix() + 10 >= parseInt(tokenExpiration, 10)) {
        // Request has expired token
        refreshTokenRequested = true;
        refreshingTokens = refreshFunc();

        return handleTokenRefresh(config);
      } else {
        return config;
      }
    },
    function (error) {
      return Promise.reject(error);
    }
  );
}

type ToolkitStoreType = {
  dispatch: (params: any) => unknown;
};

// API response interceptor
export function setupResponseInterceptors(store: ToolkitStoreType) {
  apiService.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      switch (error && error.response && error.response.status) {
        case 401:
          // console.log('401 Response, will logout user');
          store.dispatch(logoutCleanup());

          break;
        case 403:
          store.dispatch(getAccountPermissions());

          break;
        default:
          return Promise.reject(error.response);
      }

      return Promise.reject(error.response);
    }
  );
}
