import TYPES from './types';
import {
  configureStatisticsGraphData,
  configureStatisticsTopFourGraphData
} from 'store/reports/crew/helpers';

const INITIAL_STATE = {
  data: {}
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_REPORT_ON_SIGNER_STATISTICS_DATA.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          totalSeaman: configureStatisticsGraphData(payload)
        }
      };

    case TYPES.GET_REPORT_ON_SIGNER_STATISTICS_DATA_TOP_FOUR.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          topFour: configureStatisticsTopFourGraphData(payload)
        }
      };

    default:
      return state;
  }
};

export default reducer;
