import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_DEPARTMENTS'),
  ...asyncTypes('UPDATE_DEPARTMENT'),
  ...asyncTypes('CREATE_DEPARTMENT'),
  ...asyncTypes('GET_DEPARTMENT'),
  ...asyncTypes('DELETE_DEPARTMENT')
};

export default TYPES;
