export const vesselInfoMappings = {
  summer_dwt: 'Summer DWT',
  winter_dwt: 'Winter DWT',
  tropical_dwt: 'Tropical DWT',
  tropical_fresh_dwt: 'Tropical Fresh DWT',
  fresh_dwt: 'Fresh DWT',
  summer_draft: 'Summer draft',
  winter_draft: 'Winter draft',
  tropical_draft: 'Tropical draft',
  tropical_fresh_draft: 'Tropical Fresh draft',
  fresh_draft: 'Fresh draft',
  ssw_tpc: 'T.P.C. on SSW',
  ballast_normal_draft: 'Normal Ballast Draft',
  speed_consumption: 'Speed and Consumption'
};
