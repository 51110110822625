import { useEffect, useCallback } from 'react';
import { selectChannelPrefix, selectNotificationChannel } from 'store/sockets/selectors';
import { selectOrganizationDomain } from 'store/organization/selectors';
import { selectIsAuthenticated } from 'store/auth/selectors';
import {
  getNotificationsCount,
  receiveSocketNotification,
  getNotifications
} from 'store/notifications/actions';
import { getSocketInfo } from 'store/sockets/actions';
import { io } from 'socket.io-client';
import { useUpdateAsyncSystemTasks } from '@/api/system-tasks/mutations';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncSystemTask } from '@/types/system-tasks';
import { selectAccountId } from '@/store/account/selectors-ts';

const useSockets = () => {
  const channel = useAppSelector(selectChannelPrefix);
  const notificationChannel = useAppSelector(selectNotificationChannel);

  const organizationDomain = useAppSelector(selectOrganizationDomain);
  const userId = useAppSelector(selectAccountId);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const dispatch = useAppDispatch();

  const { mutate } = useUpdateAsyncSystemTasks();

  const handleRealData = useCallback(
    (realtimeData: AsyncSystemTask) => {
      console.info('Async task triggered', realtimeData);

      if (realtimeData.event?.includes('Notifications') || !realtimeData.event) {
        dispatch(receiveSocketNotification(realtimeData.data));
      } else if (realtimeData.event.startsWith('async_action.')) {
        mutate(realtimeData);
      }
    },
    [dispatch, mutate]
  );

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getSocketInfo());
      dispatch(getNotificationsCount());
      dispatch(getNotifications({ current_page: 1, per_page: 50 }));
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    let socket = null;

    if (channel && notificationChannel && organizationDomain && isAuthenticated && userId) {
      const token = window.localStorage.getItem('accessToken');
      const apiUrl = import.meta.env.VITE_SOCKET_API_URL;

      socket = io(apiUrl, {
        reconnectionDelayMax: 10000,
        reconnectionAttempts: 3,
        path: '/realtime',
        transports: ['polling', 'websocket'],
        extraHeaders: { Authorization: 'Bearer ' + token }
        // addTrailingSlash: false
      });

      socket.on(`private-${channel}.${notificationChannel}.${userId}`, notification =>
        handleRealData(notification)
      );
    }

    return () => {
      if (socket) socket.disconnect();
    };
  }, [channel, notificationChannel, organizationDomain, isAuthenticated, userId, handleRealData]);
};
export default useSockets;
