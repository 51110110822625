import React from 'react';
import { numberToStr } from 'common/utils/numbers';

export const cargoSfOptions = [
  {
    id: 1,
    label: 'm3_mt',
    default: true,
    name: (
      <div>
        m<sup>3</sup>/mt
      </div>
    )
  },
  {
    id: 2,
    label: 'cft_mt',
    name: 'cft/mt'
  }
];

export const salinityOptions = [
  {
    id: 1,
    label: 'salt',
    default: true,
    name: 'Salt'
  },
  {
    id: 2,
    label: 'brackish',
    name: 'Brackish'
  },
  {
    id: 3,
    label: 'fresh',
    name: 'Fresh'
  }
];

export const cargoOptions = [
  {
    id: 1,
    label: 'mt',
    default: true,
    name: 'mt'
  }
];

export const calculate = (name, vessel, params) => {
  const draftSummer = +vessel.draft_summer || 0;
  const dwtSummer = +vessel.dwt_summer || 0;
  const freshWaterAllowance = +vessel.fresh_water_allowance || 0;
  const lightShip = +vessel.light_ship || 0;
  const lightShipDraft = +vessel.lightship_draft || 0;
  const sswTpc = +vessel.ssw_tpc || 0;

  let salinity = salinityOptions.find(el => el.id === params.salinity).label;
  let cargoSfUnit = cargoSfOptions.find(el => el.id === params.cargoSfUnit).label;

  let cargoSf = cargoSfUnit === 'm3_mt' ? params.cargoSf : params.cargoSf / 35.314667;

  let baseDraft =
    salinity === 'salt'
      ? draftSummer
      : salinity === 'brackish'
      ? draftSummer + freshWaterAllowance / 2
      : draftSummer + freshWaterAllowance;

  if (name === 'draft') {
    let totalOnBoard = params.fuels + params.constants + params.cargo;
    let draft = baseDraft + (totalOnBoard - dwtSummer) / (sswTpc * 100) + params.sag / 100;

    let cubic = numberToStr(params.cargo * cargoSf, 0);

    let error = null;

    let displacement = numberToStr(totalOnBoard + lightShip, 0);

     

    let res = {
       draft: numberToStr(draft),
      displacement,
      cubic,
      error
    }

    if (totalOnBoard > dwtSummer) {
      displacement = numberToStr(dwtSummer + lightShip, 0);
      error = 'Ship is overweight';
      return {
        ...res,
        draft,
        error
      }
    }

    if (draft < lightShipDraft + params.sag / 100) {
      draft = lightShipDraft + params.sag / 100;
      error = 'Invalid cargo value. Draft is less than lightship draft';
       return {
        ...res,
        draft,
        error
      }
    }

    if (draft > baseDraft) {
      draft = baseDraft;
      error = 'Invalid cargo value, draft exceeding max draft';
       return {
        ...res,
        draft,
        error
      }
    }

    return res
 
  } else if (name === 'cargo') {
    let totalOnBoard = (params.draft - baseDraft - params.sag / 100) * (sswTpc * 100) + dwtSummer;
    let cargo = totalOnBoard - params.fuels - params.constants;

    let error = null;

    let displacement = numberToStr(cargo + params.fuels + params.constants + lightShip, 0);

    if (params.draft > baseDraft) {
      error = 'Invalid draft value,draft exceeding max draft';
    }

    if (params.draft < lightShipDraft + params.sag / 100) {
      error = 'Invalid draft value, draft is lower than light ship draft';
    }

    if (cargo < 0) {
      cargo = 0;
      error = 'Invalid draft value';
    }
    if (cargo > dwtSummer - params.fuels - params.constants) {
      cargo = dwtSummer - params.fuels - params.constants;
      error = 'Invalid draft value, ship is overweight';
    }

    let cubic = numberToStr(cargo * cargoSf, 0);

    return {
      cargo: numberToStr(cargo, 0),
      displacement,
      cubic,
      error
    };
  }
};
