import React from 'react';
import PropTypes from 'prop-types';
import OvalButton from 'common/components/buttons/OvalButton';
import { useSelector } from 'react-redux';
import { selectIsJobLocked } from 'common/components/jobs/_base/store/selectors';

const InputSaveActions = ({ onCancel, onSave, className, canSubmit }) => {
  const isJobLocked = useSelector(state => selectIsJobLocked(state, true));

  if (isJobLocked) return null;

  return (
    <>
      {onSave && onCancel ? (
        <div
          className={`input-save-actions d-flex align-items-center justify-content-end ${className} `}
        >
          <OvalButton type="close" onClick={onCancel} size="sm" className={`me-1`} />
          <OvalButton type="send" disabled={!canSubmit} size="sm" onClick={onSave} text="Submit" />
        </div>
      ) : null}
    </>
  );
};

InputSaveActions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  className: PropTypes.string,
  canSubmit: PropTypes.bool
};

export default InputSaveActions;
