import TYPES from './types';
import { post, get, put, download, deleteRequest } from 'utils/api';
import { updateTableRow, resetTableRowUpdate } from 'store/tables/lists/actions';
import {
  updateFormPlanningSubmission,
  updateFormPlanningFormSubmissionStatusTableRow
} from 'common/components/forms/planning-table/store/actions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { successHandler } from 'common/utils/notifications';
import { selectIsInFormPlanning } from '@/store/forms/selectors.ts';

export const downloadFormFile = params => dispatch => {
  dispatch({ type: TYPES.DOWNLOAD_FORM_FILE.START, payload: { params } });
  const { id: fileId, ...rest } = params;

  return download(`/files/${fileId}`, rest)
    .then(response => {
      dispatch({ type: TYPES.DOWNLOAD_FORM_FILE.SUCCESS, payload: response.data });
      return response;
    })
    .catch(error => dispatch({ type: TYPES.DOWNLOAD_FORM_FILE.ERROR, payload: error }));
};

export const downloadFormSubmissionAttachments = params => dispatch => {
  const { formSubmissionID, fieldID } = params;

  dispatch({ type: TYPES.DOWNLOAD_FORM_SUBMISSION_ATTACHMENTS.START, payload: { params } });

  return download(`form-submissions/${formSubmissionID}/fields/${fieldID}/files`)
    .then(response => {
      dispatch({
        type: TYPES.DOWNLOAD_FORM_SUBMISSION_ATTACHMENTS.SUCCESS,
        payload: response.data
      });
      return response;
    })
    .catch(error =>
      dispatch({ type: TYPES.DOWNLOAD_FORM_SUBMISSION_ATTACHMENTS.ERROR, payload: error })
    );
};

export const createForm = params => dispatch => {
  dispatch({ type: TYPES.CREATE_FORM.START, payload: { params } });

  const { id, ...rest } = params;

  return post('/forms', rest)
    .then(response => {
      dispatch({ type: TYPES.CREATE_FORM.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_FORM.ERROR, payload: error });

      throw error;
    });
};

export const cloneForm = params => dispatch => {
  dispatch({ type: TYPES.CLONE_FORM.START, payload: { params } });

  const { id, ...rest } = params;

  return put(`/forms/${id}/clone`, rest)
    .then(response => {
      dispatch({ type: TYPES.CLONE_FORM.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Cloned successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CLONE_FORM.ERROR, payload: error });

      throw error;
    });
};

export const getFormSignatureRequirementSettings = createAsyncThunk(
  'GET_FORM_SIGNATURE_REQUIREMENT_SETTINGS',
  async ({ uid }, { rejectWithValue }) => {
    try {
      const res = await get(`/forms/${uid}/signature-requirement-settings`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const setFormSignatureRequirementSettings = createAsyncThunk(
  'SET_FORM_SIGNATURE_REQUIREMENT_SETTINGS',
  async ({ uid, ...params }, { rejectWithValue }) => {
    try {
      let res;
      if (
        !params?.crew_rank_ids?.length &&
        !params?.department_ids?.length &&
        !params?.department_role_ids?.length
      ) {
        // When everything is empty, make a delete request
        res = await deleteRequest(`/forms/${uid}/signature-requirement-settings`, params);
      } else {
        res = await put(`/forms/${uid}/signature-requirement-settings`, params);
      }

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getFormSubmissionSignatureRequirements = createAsyncThunk(
  'GET_FORM_SUBMISSION_REQUIRED_SIGNATURES',
  async ({ id, ...params }, { rejectWithValue, dispatch }) => {
    try {
      const res = await get(`/form-submissions/${id}/signature-requirements`, {
        is_pending: true,
        ...params
      });

      // Update the table pending_signature_requirements_count
      dispatch(
        updateTableRow({
          data: { id, pending_signature_requirements_count: res.data?.length },
          table: 'form_submissions'
        })
      );

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateFormSubmission = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_FORM_SUBMISSION.START, payload: { params } });

  const { id, ...rest } = params;

  return put(`/form-submissions/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_FORM_SUBMISSION.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));
      dispatch(updateFormPlanningSubmission(params));
      dispatch(updateTableRow({ data: response.data, table: 'form_submissions' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_FORM_SUBMISSION.ERROR, payload: error });

      throw error;
    });
};

export const deleteFormSubmission = params => dispatch => {
  dispatch({ type: TYPES.REMOVE_FORM_SUBMISSION.START, payload: { params } });

  const { id, ...rest } = params;

  return deleteRequest(`/form-submissions/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.REMOVE_FORM_SUBMISSION.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.REMOVE_FORM_SUBMISSION.ERROR, payload: error });

      throw error;
    });
};

export const getFormSubmission = params => dispatch => {
  dispatch({ type: TYPES.GET_FORM_SUBMISSION.START, payload: { params } });

  const { id, ...rest } = params;

  return get(`/form-submissions/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.GET_FORM_SUBMISSION.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_FORM_SUBMISSION.ERROR, payload: error });
    });
};

export const getFormSubmissionPreviousValues = params => dispatch => {
  dispatch({ type: TYPES.GET_FORM_SUBMISSION_PREVIOUS_VALUES.START, payload: { params } });

  const { id, ...rest } = params;

  return get(`/form-submissions/${id}/past-entries`, { ...rest, number_of_entries: 7 })
    .then(response => {
      dispatch({ type: TYPES.GET_FORM_SUBMISSION_PREVIOUS_VALUES.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_FORM_SUBMISSION_PREVIOUS_VALUES.ERROR, payload: error });
    });
};

export const createFormSubmission = params => dispatch => {
  dispatch({ type: TYPES.CREATE_FORM_SUBMISSION.START, payload: { params } });

  const { id, ...rest } = params;

  return post(`/forms/${id}/submissions`, rest)
    .then(response => {
      dispatch({ type: TYPES.CREATE_FORM_SUBMISSION.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_FORM_SUBMISSION.ERROR, payload: error });
      throw error;
    });
};

export const updateFormFields = createAsyncThunk(
  'UPDATE_FORM_FIELDS',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { id, ...rest } = params;
    try {
      const response = await put(`/forms/${id}/fields`, rest);

      return fulfillWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDigitalForm = params => dispatch => {
  dispatch({ type: TYPES.GET_DIGITAL_FORM.START, payload: { params } });

  const { id, ...rest } = params;

  return get(`/forms/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.GET_DIGITAL_FORM.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_DIGITAL_FORM.ERROR, payload: error });
      throw error;
    });
};

export const getLastSubmittedForm = params => dispatch => {
  dispatch({ type: TYPES.GET_LAST_SUBMITTED_FORM.START, payload: { params } });

  const { id } = params;

  return get(`/forms/${id}/latest-submission`)
    .then(response => {
      dispatch({ type: TYPES.GET_LAST_SUBMITTED_FORM.SUCCESS, payload: response });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_LAST_SUBMITTED_FORM.ERROR, payload: error });
      throw error;
    });
};

export const updateForm = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_DIGITAL_FORM.START, payload: { params } });

  const { id, ...rest } = params;

  return put(`/forms/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_DIGITAL_FORM.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Edited successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_DIGITAL_FORM.ERROR, payload: error });

      throw error;
    });
};

export const deleteForm = params => dispatch => {
  dispatch({ type: TYPES.DELETE_FORM.START });

  const { id } = params;

  return deleteRequest(`/forms/${id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_FORM.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_FORM.ERROR, payload: error });

      throw error;
    });
};

export const createFormField = params => dispatch => {
  dispatch({ type: TYPES.CREATE_FORM_FIELD.START });

  const { table, ...rest } = params;

  return post('/form-fields', rest)
    .then(res => {
      dispatch({ type: TYPES.CREATE_FORM_FIELD.SUCCESS });
      dispatch(updateTableRow({ data: res.data, table }));
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_FORM_FIELD.ERROR, payload: error });
      dispatch(resetTableRowUpdate({ data: params, table }));

      throw error;
    });
};

export const updateFormField = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_FORM_FIELD.START });

  const { id, table, ...rest } = params;

  return put(`/form-fields/${id}`, rest)
    .then(res => {
      dispatch({ type: TYPES.UPDATE_FORM_FIELD.SUCCESS });
      dispatch(updateTableRow({ data: res.data, table }));
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_FORM_FIELD.ERROR, payload: error });
      dispatch(resetTableRowUpdate({ data: params, table }));

      throw error;
    });
};

/* FORM FIELD ACTIONS */

export const getOfficeCrewParticipants = params => dispatch => {
  dispatch({ type: TYPES.GET_OFFICE_CREW_PARTICIPANTS.START, payload: params });

  return get('/crew-list', params)
    .then(response => {
      dispatch({ type: TYPES.GET_OFFICE_CREW_PARTICIPANTS.SUCCESS, payload: response });
      return response;
    })
    .catch(error => dispatch({ type: TYPES.GET_OFFICE_CREW_PARTICIPANTS.ERROR, payload: error }));
};

export const getOnboardCrewParticipants = params => dispatch => {
  dispatch({ type: TYPES.GET_ONBOARD_CREW_PARTICIPANTS.START, payload: params });

  return get('/crew', params)
    .then(response => {
      dispatch({ type: TYPES.GET_ONBOARD_CREW_PARTICIPANTS.SUCCESS, payload: response });
      return response;
    })
    .catch(error => dispatch({ type: TYPES.GET_ONBOARD_CREW_PARTICIPANTS.ERROR, payload: error }));
};

/* DIGITAL SIGNATURE ACTIONS */

export const updateFormVisibility = createAsyncThunk(
  'UPDATE_FORM_VISIBILITY',
  async (params, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const { id, ...rest } = params;
    try {
      const response = await put(`/forms/${id}/visibility`, rest);

      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      if (response.data?.type === 'digital') {
        dispatch(updateTableRow({ data: response?.data, table: 'digital_forms' }));
      } else {
        dispatch(updateTableRow({ data: response?.data, table: 'upload_forms' }));
      }

      return fulfillWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateFormSubmissionStatus = createAsyncThunk(
  'UPDATE_FORM_STATUS',
  async (params, { rejectWithValue, dispatch, getState }) => {
    const { id, ...rest } = params;

    const inOnFormPlanning = selectIsInFormPlanning(getState());

    try {
      const res = await put(`/form-submissions/${id}/status`, rest);

      if (inOnFormPlanning) {
        dispatch(
          updateFormPlanningFormSubmissionStatusTableRow({
            id: res.data.form_planning.id,
            form_id: res.data.form_planning.form_submission_id,
            form_status: res.data.status
          })
        );
      } else {
        dispatch(updateTableRow({ data: res.data, table: 'form_submissions' }));
      }

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createFormSubmissionDigitalSignature = params => dispatch => {
  dispatch({ type: TYPES.CREATE_FORM_SUBMISSION_DIGITAL_SIGNATURE.START, payload: { params } });
  const { id, ...rest } = params;

  return post(`/form-submissions/${id}/signatures`, rest)
    .then(response => {
      dispatch({
        type: TYPES.CREATE_FORM_SUBMISSION_DIGITAL_SIGNATURE.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_FORM_SUBMISSION_DIGITAL_SIGNATURE.ERROR, payload: error });
      return error;
    });
};

export const deleteFormSubmissionDigitalSignature = params => dispatch => {
  dispatch({ type: TYPES.DELETE_FORM_SUBMISSION_DIGITAL_SIGNATURE.START, payload: { params } });

  const { id, signature_id } = params;

  return deleteRequest(`/form-submissions/${id}/signatures/${signature_id}`)
    .then(response => {
      dispatch({
        type: TYPES.DELETE_FORM_SUBMISSION_DIGITAL_SIGNATURE.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.DELETE_FORM_SUBMISSION_DIGITAL_SIGNATURE.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const getFormSubmissionDigitalSignatures = params => dispatch => {
  const { id } = params;
  dispatch({ type: TYPES.GET_FORM_SUBMISSION_DIGITAL_SIGNATURES.START, payload: { params } });

  return get(`/form-submissions/${id}/signatures`)
    .then(response => {
      dispatch({
        type: TYPES.GET_FORM_SUBMISSION_DIGITAL_SIGNATURES.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_FORM_SUBMISSION_DIGITAL_SIGNATURES.ERROR, payload: error });
      return error;
    });
};

export const getFormSubmissionWarnings = createAsyncThunk(
  'GET_FORM_SUBMISSION_WARNINGS',
  async (params, { rejectWithValue, dispatch }) => {
    const { id } = params;

    try {
      const res = await get(`/form-submissions/${id}/warnings`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const unlinkFormFinding = createAsyncThunk(
  'UNLINK_FORM_FINDING',
  async (params, { rejectWithValue, dispatch }) => {
    const { submission_id, id, ...rest } = params;

    try {
      const res = await deleteRequest(`/form-submissions/${submission_id}/findings/${id}`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
