import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import PropTypes from 'prop-types';
import paths from 'routing/routes/_paths';
import { useSelector } from 'react-redux';
import { selectWidgetState } from 'store/dashboards/selectors';
import InvoiceActionButtons from 'views/accounting/invoices/components/InvoiceActionButtons';
import { numberToStr } from 'common/utils/numbers';
import Departments from 'common/components/general/Departments';

import { strToNumber } from 'common/utils/numbers';
import { displayDate } from 'common/utils/dates';
import { useDrawer } from '@/common/components/drawer';
import { selectListOptionsFromStore } from '@/store/lists/selectors';

const Invoices = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));

  const departments = useSelector(state => selectListOptionsFromStore(state, 'departments'));

  const drawer = useDrawer();

  return (
    <div>
      <WidgetTitle id={id} linkTo={paths.accounting_invoices} />
      <WidgetDefaultTable
        id={id}
        columns={[
          {
            header: 'Ref.code',
            key: 'code',
            type: 'string',
            headerClassName: 'text-violet',
            truncate: true,
            minWidth: 80,
            maxWidth: 80
          },
          {
            header: 'Vendor',
            key: 'vendor_name',
            data_key: 'vendor.full_name',
            truncate: true,
            headerClassName: 'text-violet',
            minWidth: 300,
            maxWidth: 300
          },
          {
            header: 'Vessel',
            key: 'vessel_name',
            data_key: 'vessel.name',
            truncate: true,
            headerClassName: 'text-violet',
            minWidth: 160,
            maxWidth: 160
          },
          {
            header: 'Departments',
            key: 'department_id',
            type: 'string',
            headerClassName: 'text-violet',
            minWidth: 215,
            maxWidth: 215
          },
          {
            header: 'Due Date',
            type: 'date',
            key: 'due_date',
            headerClassName: 'text-violet',
            minWidth: 215,
            maxWidth: 215
          },
          {
            header: 'Amount in usd',
            filterLabel: 'Amount USD',
            type: 'string',
            key: 'base_amount',
            headerClassName: 'text-violet',
            minWidth: 160,
            maxWidth: 160
          },
          {
            header: '',
            key: 'items',
            bodyClassName: 'align-items-end',
            minWidth: 160,
            maxWidth: 160
          }
        ]}
        rows={{
          code: data => {
            return <div className="fs-10 fw-bold">{data?.code || '-'}</div>;
          },
          items: data => (
            <InvoiceActionButtons
              data={data}
              viewOnly={true}
              drawer={drawer}
              departments={departments}
            />
          ),
          department_id: data =>
            data.departments?.length ? <Departments values={data.departments} /> : '-',
          linked_case_total_local_amount: data =>
            data?.linked_case_total_local_amount ? (
              <div
                className={`${
                  strToNumber(data?.linked_case_difference_percentage) ? 'text-red' : ''
                }`}
              >
                {numberToStr(data.linked_case_total_local_amount, 2, 2)}
              </div>
            ) : (
              '-'
            ),
          base_amount: data =>
            data?.base_amount ? (
              <div
                className={`${
                  strToNumber(data?.linked_case_difference_percentage) ? 'text-red' : ''
                }`}
              >
                {numberToStr(data.base_amount, 2, 2)}
              </div>
            ) : (
              '-'
            ),
          due_date: data => (
            <span
              className={data.is_overdue ? 'text-red' : data.becoming_overdue ? 'text-warning' : ''}
            >
              {displayDate(data?.due_date, { time: true }) || '-'}
            </span>
          )
        }}
        label={label}
        state={state}
      />
    </div>
  );
};

Invoices.propTypes = {
  id: PropTypes.number
};

export default Invoices;
