import { selectTableListPercentages } from 'common/components/table/store/selectors';
import { Progress } from 'reactstrap';
import { useSelector } from 'react-redux';

const ProgressBar = ({ value, label, widgetPercentage }) => {
  const percentage = useSelector(state => selectTableListPercentages(state, label));
  const overdueJobsPercentage = widgetPercentage
    ? widgetPercentage?.pms_pms_overdue_jobs_percentage
    : percentage?.pms_pms_overdue_jobs_percentage;

  return (
    <div className="d-flex justify-content-center align-items-center w-100p me-0 pe-0">
      <div className="position-relative flex-1">
        <Progress
          color={parseFloat(value) <= overdueJobsPercentage ? 'success' : 'danger'}
          value={value * 5}
          className="overview-jobs-progress"
        >
          <div
            className={`overview-jobs-progress__bar`}
            style={{ left: `${overdueJobsPercentage * 5}%` }}
          ></div>
        </Progress>
      </div>
      <div
        className={`width-48  ${
          parseFloat(value) <= overdueJobsPercentage ? 'text-success' : 'text-danger'
        }`}
      >
        {value}
      </div>
    </div>
  );
};

export default ProgressBar;
