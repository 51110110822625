import React, { useState } from 'react';
import ButtonIcon from 'tools/components/general/ButtonIcon';
import Switch from 'tools/components/general/Switch';

import _orderBy from 'lodash/orderBy';

const CurrencyRow = ({ cur, isActive, isDisabled, handleSelect }) => {
  if (!cur) return null;

  return (
    <div className="forex-rates-edit--row-handler">
      <strong>{cur.label}</strong>
      <small className="px-1">{cur.name}</small>
      <Switch active={isActive} disabled={isDisabled} onClick={() => handleSelect(cur)} />
    </div>
  );
};

const Edit = ({ currencies, selectedCurrencies, setIsSettingsOpen, update, isSaving }) => {
  const [value, setValue] = useState(selectedCurrencies.filter(o => o.label !== 'USD'));
  const excluded = ['USD', 'EUR', 'JPY', 'GBP', 'AUD', 'CAD', 'CHF', 'CNH', 'SEK', 'NZD'];

  const handleSelect = cur => {
    if (!value) {
      setValue([cur]);
    } else if (value.find(f => f.label === cur.label)) {
      setValue(value.filter(f => f.label !== cur.label));
    } else {
      setValue([...value, cur]);
    }
  };

  return (
    <div className="forex-rates-edit py-2">
      <div className="forex-rates-edit--label mb-1">Choose up to 10</div>

      <div className="forex-rates-edit--rows">
        {excluded.map(label => {
          if (label !== 'USD') {
            const isActive = value && value.find(f => f.label === label);

            return (
              <CurrencyRow
                key={label}
                cur={currencies.find(c => c.label === label)}
                isActive={isActive}
                isDisabled={isSaving || (value && value.length === 10 && !isActive)}
                handleSelect={handleSelect}
              />
            );
          }

          return null;
        })}

        {_orderBy(
          currencies.filter(c => excluded.indexOf(c.label) === -1),
          ['label']
        ).map(cur => {
          const isActive = value && value.find(f => f.label === cur.label);

          return (
            <CurrencyRow
              key={cur.label}
              cur={cur}
              isActive={isActive}
              isDisabled={isSaving || (value && value.length === 10 && !isActive)}
              handleSelect={handleSelect}
            />
          );
        })}
      </div>

      <div className="forex-rates-edit--actions">
        <ButtonIcon type="x" onClick={() => setIsSettingsOpen(false)}></ButtonIcon>
        <ButtonIcon type="done" onClick={() => update(value)} disabled={isSaving}></ButtonIcon>
      </div>
    </div>
  );
};

export default Edit;
