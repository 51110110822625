import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';

const Comparison = React.lazy(() => retry(() => import('@/views/comparison/index.jsx')));

const comparison = [
  {
    path: `${paths.comparison}/vessels`,
    component: Comparison,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.vessels.compare],
    documentTitle: 'Vessels Comparison'
  },
  {
    path: `${paths.comparison}/estimations`,
    component: Comparison,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.voyages.estimations.comparison],
    documentTitle: 'Estimations Comparison'
  },
  {
    path: `${paths.comparison}/crew`,
    component: Comparison,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.crew.comparison],
    documentTitle: 'Crew Comparison'
  }
];

export default comparison;
