import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import WidgetTitle from './WidgetTitle';
import LoadMoreButton from './LoadMoreButton';

import { selectWidgetStateData, selectIsWidgetStateLoading } from 'store/dashboards/selectors';

const WidgetDefaultListSidebar = ({ id, renderListItem, withColoredIcon }) => {
  const isLoading = useSelector(state => selectIsWidgetStateLoading(state, id));
  const data = useSelector(state => selectWidgetStateData(state, id));

  return (
    <div className="widget-default-sidebar">
      <div className="widget-default-sidebar__header mb-2 pb-1">
        <WidgetTitle
          id={id}
          titleClassName="fs-16 fw-black text-primary"
          withActions={true}
          withColoredIcon={withColoredIcon}
        />
      </div>
      <div className="widget-default-sidebar__body">
        {data.map((item, index) => {
          return renderListItem(item, index);
        })}
      </div>
      <LoadMoreButton id={id} disabled={isLoading} />
    </div>
  );
};

WidgetDefaultListSidebar.propTypes = {
  id: PropTypes.number.isRequired, // widget id
  renderListItem: PropTypes.func
};

export default WidgetDefaultListSidebar;
