import React from 'react';
import { useFormState } from 'utils/hooks';
import Input from 'common/components/form/inputs/Input';
import CircledButton from 'common/components/buttons/CircledButton';

const Bookmark = ({ bookmark, index, removeSubform }) => {
  const { changeField, fields } = useFormState(bookmark);

  return (
    <div className="bookmarks-widget-form__bookmark position-relative bg-light-gray py-1 cpx-12 rounded-lg mb-1">
      <Input
        placeholder="Bookmark name"
        label="NAME"
        onChange={changeField('title')}
        value={fields.title.value}
        className="mb-2"
        {...fields.title}
      />
      <Input
        placeholder="Enter a URL"
        label="URL"
        onChange={changeField('url')}
        value={fields.url.value}
        className="mb-0"
        {...fields.url}
      />

      {index !== 0 ? (
        <CircledButton
          style={{ width: 17, height: 17 }}
          className="bookmarks-widget-form__bookmark-remove cms-4 mt-1"
          type="remove"
          onClick={() => removeSubform('bookmarks', index)}
        />
      ) : null}
    </div>
  );
};

export default Bookmark;
