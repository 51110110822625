import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import { selectPort } from 'store/ports/selectors';

const Ports = React.lazy(() => retry(() => import('@/views/ports/index.jsx')));
const PortProfile = React.lazy(() => retry(() => import('@/views/ports/profile/index.jsx')));

const ports = [
  {
    path: `${paths.ports}`,
    component: Ports,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.voyages.ports.view],
    documentTitle: 'Ports'
  },
  {
    path: `${paths.ports}/:id`,
    component: PortProfile,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.voyages.ports.view],
    documentTitle: state => selectPort(state)?.name
  }
];

export default ports;
