import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_NOTIFICATIONS'),
  ...asyncTypes('LOAD_MORE_NOTIFICATIONS'),
  ...asyncTypes('GET_NOTIFICATIONS_COUNT'),
  ...asyncTypes('MARK_NOTIFICATIONS_AS_SEEN'),
  ...asyncTypes('MARK_NOTIFICATION_AS_READ'),
  ...asyncTypes('GET_NOTIFICATIONS_SUMMARY'),
  RECEIVE_SOCKET_NOTIFICATION: 'RECEIVE_SOCKET_NOTIFICATION',
  SET_ACTIVE_CATEGORY_LABEL: 'SET_ACTIVE_CATEGORY_LABEL',
  REDUCE_UNREAD_COUNTS_BY_TYPE: 'REDUCE_UNREAD_COUNTS_BY_TYPE',
  RESET_UNREAD_COUNT: 'RESET_UNREAD_COUNT',
  SET_IS_UNREAD_TAB_ACTIVE: 'SET_IS_UNREAD_TAB_ACTIVE'
};

export default TYPES;
