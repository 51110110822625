import CircledButton from 'common/components/buttons/CircledButton';

import { useSelector } from 'react-redux';
import { selectMenuFormIsVisible } from 'common/components/beacon/store/selectors';
import { useActions } from 'utils/hooks';
import { setActiveMenuIdAction, toggleMenuFormAction } from 'common/components/beacon/store/slice';

const Footer = () => {
  const formIsVisible = useSelector(selectMenuFormIsVisible);
  const [setActiveMenuId, toggleMenuForm] = useActions([
    setActiveMenuIdAction,
    toggleMenuFormAction
  ]);

  return (
    <div className="border-top mt-3 pt-2">
      <CircledButton
        type="add"
        className="text-primary fw-bold fs-12"
        label={'Add menu'}
        onClick={() => {
          setActiveMenuId(null);
          toggleMenuForm(true);
        }}
        disabled={formIsVisible}
        style={{ width: 16, maxWidth: 16, height: 16 }}
        svgStyle={{ width: 8, height: 8 }}
      />
    </div>
  );
};

export default Footer;
