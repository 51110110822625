// import TYPES from './types';

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    default:
      return state;
  }
};

export default reducer;
