import { LOCATION_CHANGE } from 'connected-react-router';
import TYPES from './types';
import { dateToUtc } from 'utils/dates';

const INITIAL_STATE = {
  currentCharterPartyType: { name: 'Voyage', label: 'voyage' },
  formMode: 'view',
  activeForm: null,
  isFetching: true,
  deliveryDate: null,
  redeliveryDate: null,
  hasActualDeliveryReport: false,
  hasActualRedeliveryReport: false,
  estimatedDates: null,
  offHireDays: null,
  evaluationDrawer: { charterer: {}, evaluation: {}, isOpen: false },
  evaluation: {
    charterer: null,
    subCharterers: {}
  }
};

const constructSubCharterersEvaluationState = (state, data) => {
  const { subcharterer_id, ...rest } = data;

  return {
    ...state.evaluation,
    subCharterers: {
      ...state.evaluation.subCharterers,
      [subcharterer_id]: { ...rest }
    }
  };
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SET_CHARTER_PARTY_TYPE:
      return {
        ...state,
        currentCharterPartyType: payload
      };

    case TYPES.GET_CHARTER_PARTY.START:
      return {
        ...state,
        isFetching: true
      };

    case TYPES.SET_CHARTER_PARTY_EVALUATION_DRAWER:
      return {
        ...state,
        evaluationDrawer: payload
      };

    case TYPES.GET_SUBCHARTER_PARTY_EVALUATION.SUCCESS:
    case TYPES.CREATE_SUBCHARTER_PARTY_EVALUATION.SUCCESS: {
      const subChartererData = payload?.length ? payload[0] : payload;

      const evaluationState = constructSubCharterersEvaluationState(state, subChartererData);

      return {
        ...state,
        evaluation: evaluationState
      };
    }

    case TYPES.CREATE_CHARTER_PARTY_EVALUATION.SUCCESS:
      return {
        ...state,
        evaluation: {
          ...state.evaluation,
          charterer: payload
        }
      };

    case TYPES.UPDATE_CHARTER_PARTY_EVALUATION.SUCCESS:
      const { isSubcharterer, data } = payload;

      if (isSubcharterer) {
        const evaluationState = constructSubCharterersEvaluationState(state, data);

        return {
          ...state,
          evaluation: evaluationState
        };
      } else {
        return {
          ...state,
          evaluation: {
            ...state.evaluation,
            charterer: data
          }
        };
      }

    case TYPES.GET_CHARTER_PARTY_EVALUATION.SUCCESS:
      return {
        ...state,
        evaluation: {
          ...state.evaluation,
          charterer: payload?.length ? payload[0] : null
        }
      };

    case TYPES.UPDATE_CHARTER_PARTY.SUCCESS:
    case TYPES.GET_CHARTER_PARTY.SUCCESS:
      const isDeliveryScheduled = payload?.data?.delivery_is_scheduled;

      const isRedeliveryScheduled = payload?.data?.redelivery_is_scheduled;

      const deliveryDate = payload?.data?.delivery_date;

      const redeliveryDate = payload?.data?.redelivery_date;

      return {
        ...state,
        activeForm: payload.data,
        currentCharterPartyType: payload.selectedType,
        isFetching: false,
        deliveryDate: deliveryDate ? dateToUtc(deliveryDate) : null,
        redeliveryDate: redeliveryDate ? dateToUtc(redeliveryDate) : null,
        hasActualDeliveryReport: !isDeliveryScheduled,
        hasActualRedeliveryReport: !isRedeliveryScheduled,
        estimatedDates: {
          estimated_earliest_redelivery_date: payload.data.estimated_earliest_redelivery_date,
          estimated_latest_redelivery_date: payload.data.estimated_latest_redelivery_date,
          estimated_redelivery_date: payload.data.estimated_redelivery_date
        },
        offHireDays: payload.data?.off_hire_days
      };

    case TYPES.GET_OFF_HIRE_EVENTS.SUCCESS:
      return {
        ...state,
        offHireDays: payload?.off_hire_days || null
      };

    case TYPES.GET_ESTIMATED_DELIVERY.SUCCESS:
      return {
        ...state,
        estimatedDates: {
          estimated_earliest_redelivery_date: payload.estimated_earliest_redelivery_date,
          estimated_latest_redelivery_date: payload.estimated_latest_redelivery_date,
          estimated_redelivery_date: payload.estimated_redelivery_date
        }
      };

    case TYPES.GET_CHARTER_PARTY.ERROR:
      return {
        ...state,
        isFetching: false
      };

    case TYPES.SET_CHARTER_PARTY_FORM_MODE: {
      return {
        ...state,
        formMode: payload
      };
    }

    case LOCATION_CHANGE:
      return {
        ...INITIAL_STATE
      };

    default:
      return state;
  }
};

export default reducer;
