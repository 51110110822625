import styled from '@emotion/styled';
import { number, string, bool } from 'prop-types';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const Value = ({ count, className, isActive }) => {
  if (!count) return;

  return (
    <Container
      className={`d-flex align-items-center justify-content-center fw-medium fs-12 position-relative ${
        className || ''
      }`}
      isActive={isActive}
    >
      <div className="circle"></div>
      {count}
    </Container>
  );
};

export default Value;

const Container = styled.div`
  border-radius: 5px;
  background: ${props => (props.isActive ? 'rgba(255, 255, 255, 0.2)' : 'rgba(254, 95, 85, 0.1)')};
  color: ${props => (props.isActive ? 'white' : variables.coral)};
  min-width: 1.375rem;
  height: 1.175rem;
  padding: 0 0.25rem;

  .circle {
    position: absolute;
    top: -1px;
    right: -1px;
    box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: ${variables.coral};
    min-width: 7px;
    max-width: 7px;
    min-height: 7px;
    max-height: 7px;
  }
`;

Value.propTypes = {
  count: number,
  className: string,
  isActive: bool
};
