import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  expandedGroups: []
};

const slice = createSlice({
  name: 'overlappingDays',
  initialState: { ...INITIAL_STATE },
  reducers: {
    expandGroup: (state, { payload }) => {
      state.expandedGroups = [...state.expandedGroups, payload];

      return state;
    },
    shinkGroup: (state, { payload }) => {
      state.expandedGroups = state.expandedGroups.filter(index => index !== payload);

      return state;
    },
    expandAllGroups: (state, { payload }) => {
      const indexes = Array.from(Array(payload).keys()); // create array indexes based on data length

      state.expandedGroups = [...indexes];

      return state;
    },
    shrinkAllGroups: state => {
      state.expandedGroups = [];

      return state;
    }
  }
});

export const { expandGroup, shinkGroup, expandAllGroups, shrinkAllGroups } = slice.actions;

export default slice.reducer;
