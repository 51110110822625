import React from 'react';
import PropTypes from 'prop-types';

const SharedByLabel = ({
  shouldShowName = false,
  name = '',
  sharedWithNumber = 0,
  className = '',
  wrapperClassName = ''
}) => {
  if ((shouldShowName && !name) || (!shouldShowName && !sharedWithNumber)) {
    return null;
  }

  return (
    <div className={`shared-dashboard-label-wrapper d-flex align-items-center ${wrapperClassName}`}>
      <div
        className={`d-flex align-items-center fs-10 text-primary shared-dashboard-label ${className}`}
      >
        <span className="fw-normal cme-4">
          {shouldShowName ? 'Shared by' : 'Shared with other'}
        </span>
        <span className="fw-bold">{shouldShowName ? name : sharedWithNumber}</span>
      </div>
    </div>
  );
};

SharedByLabel.propTypes = {
  shouldShowName: PropTypes.bool.isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  sharedWithNumber: PropTypes.number
};

export default SharedByLabel;
