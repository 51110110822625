import TYPES from './types';

const INITIAL_STATE = {
  manualModalFormOpened: false,
  manualModalFormActive: null
};

const manuals = function (state = { ...INITIAL_STATE }, { type, payload }) {
  switch (type) {
    case TYPES.SHOW_MANUAL_MODAL_FORM:
      return {
        ...state,
        manualModalFormOpened: payload
      };

    case TYPES.SET_MANUAL_MODAL_FORM_ACTIVE:
      return {
        ...state,
        manualModalFormActive: payload
      };

    default:
      return state;
  }
};

export default manuals;
