import { Button } from 'reactstrap';
import { VesselType } from 'common/entities/vessels/VesselTypes';

import paths from 'routing/routes/_paths';
import SvgRender from 'common/components/general/SvgRender';
import settingsIcon from 'common/assets/svg/actions/settings.svg';

const MarketIndexesButton = ({ vessel }) => {
  return (
    <Button
      color="yellow"
      className="d-flex align-items-center text-primary fs-12 fw-medium cpx-12 cpy-4"
      onClick={() =>
        window.open(`${paths.vessels}/${vessel.id}/info${paths.market_indexes}`, '_blank')
      }
    >
      <SvgRender className="me-1" src={settingsIcon} style={{ width: 14, height: 14 }} />
      Setup Market Indexes
    </Button>
  );
};

MarketIndexesButton.propTypes = {
  vessel: VesselType.isRequired
};

export default MarketIndexesButton;
