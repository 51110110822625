import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_PERSONS'),
  ...asyncTypes('UPDATE_PERSON'),
  ...asyncTypes('UPDATE_PERSON_LOGIN_ACCESS'),
  ...asyncTypes('UPDATE_PERSON_DEPARTMENT'),
  ...asyncTypes('CREATE_PERSON'),
  ...asyncTypes('GET_PERSON'),
  ...asyncTypes('ARCHIVE_PERSON'),
  ...asyncTypes('UNARCHIVE_PERSON'),
  ...asyncTypes('GET_PERSON_LOGIN_INFO'),
  ...asyncTypes('GET_SENSITIVE_INFO'),

  ...asyncTypes('GET_PERSON_DOCUMENTS'),
  ...asyncTypes('UPDATE_PERSON_DOCUMENTS'),

  RESET_PROFILE: 'RESET_PROFILE',
  SET_TYPE_MODAL: 'SET_TYPE_MODAL',

  // Access
  ...asyncTypes('GRANT_ACCESS_TO_PERSON'),
  ...asyncTypes('GET_PERSON_ACCESS'),
  ...asyncTypes('EDIT_PERSON_ACCESS'),
  ...asyncTypes('REVOKE_PERSON_ACCESS'),
  ...asyncTypes('REMOVE_PERSON_TWO_FACTOR_AUTHENTICATION')
};

export default TYPES;
