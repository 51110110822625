import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import ShadowBox from 'common/components/general/ShadowBox';
import AddMore from 'common/components/form/helpers/AddMore';
import ConnectedCompanyField from '../components/ConnectedCompanyField';

const ConnectedCompanies = ({ selectField, changeField, subStates, removeSubform, addSubform }) => {
  const connectedCompaniesState = subStates('connected_companies');

  return (
    <ShadowBox color="light-1" className="pt-1 px-2 cmb-20" flat>
      <div className="fw-bold fs-12 text-violet border-bottom cpb-4 mb-1">Connected Companies</div>

      <Row>
        <Col xs={3} className="d-flex cmt-4 justify-content-end mb-1">
          <div className="form-label fs-12 text-blue mb-0">COMPANY</div>
        </Col>

        <Col xs={8}>
          {connectedCompaniesState?.map((state, index) => (
            <ConnectedCompanyField
              key={index}
              state={state}
              connectedCompaniesState={connectedCompaniesState}
              hideRemove={index === 0}
              onRemove={() => removeSubform('connected_companies', index)}
            />
          ))}
        </Col>

        <Col xs={{ size: 8, offset: 3 }} className="mb-2">
          <AddMore label="Add more" onClick={() => addSubform('connected_companies')} variant />
        </Col>
      </Row>
    </ShadowBox>
  );
};

export default ConnectedCompanies;

ConnectedCompanies.propTypes = {
  selectField: PropTypes.func.isRequired,
  changeField: PropTypes.func.isRequired,
  subStates: PropTypes.func,
  addSubform: PropTypes.func,
  removeSubform: PropTypes.func
};
