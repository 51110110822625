import { stringifyObj } from 'utils/urls';

export const createTableLink = (path, item) => {
  const obj = {};

  if (item?.id) {
    if (item?.filters) obj.filters = item?.filters;
    if (item?.sorting) obj.sorting = item?.sorting;
    if (item?.page_size) obj.page_size = item?.page_size;

    return `${path}?${stringifyObj(obj)}&searchId=${item?.id}`;
  } else {
    return {
      pathname: path
    };
  }
};
