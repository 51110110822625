import { useDrawer } from '@/ts-common/components/drawer';
import { useForm } from '@/utils/hooks';
import {
  selectEventUnderwaterServicesData,
  selectEventUnderwaterServicesDataLength
} from '@/events/store/event-modules/underwater-services/selectors';
import { addService, deleteService } from '@/events/store/event-modules/underwater-services/slice';
import bin from 'common/assets/svg/common/bin.svg';
import { optionField } from 'common/utils/form/fieldTypes';
import TableView from './services/TableView';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { EventTemplate } from '@/common/types/events';

type UnderwaterServiceValueWithCustomIndex = EventTemplate['underwater_service_values'][0] & {
  index: number;
};

const config = {
  underwater_service_type: optionField({ required: true })
};

const UnderwaterServicesTemplateWrapper = () => {
  const dispatch = useAppDispatch();
  const drawer = useDrawer();
  const { formState, collectValues, resetForm } = useForm(config, {});
  const services = useAppSelector(selectEventUnderwaterServicesData);
  const servicesLength = useAppSelector(selectEventUnderwaterServicesDataLength);

  const handleSave = () => {
    const values = collectValues();

    dispatch(addService({ ...values, index: servicesLength }));

    resetForm();
    drawer.close();
  };

  const handleDelete = (data: UnderwaterServiceValueWithCustomIndex) => {
    dispatch(deleteService(data.index));
  };

  return (
    <TableView
      services={services}
      onSaveUnderwaterService={handleSave}
      state={{ data: services }}
      columns={[
        {
          header: 'Type',
          key: 'name',
          headerClassName: 'bg-transparent'
        },
        {
          key: 'actions',
          field: 'actions'
        }
      ]}
      rows={{
        name: (data: UnderwaterServiceValueWithCustomIndex) =>
          data.underwater_service_type?.name || data.type?.name,
        actions: (data: UnderwaterServiceValueWithCustomIndex) => ({
          options: [
            {
              label: 'Delete',
              icon: bin,
              preventionModal: true,
              modalProps: {
                onAccept: () => handleDelete(data),
                header: 'Delete',
                actionText: 'Delete',
                action: 'delete',
                actionHoverColor: 'delete',
                body: (
                  <div className="text-center">
                    You are about to delete this underwater service. <br />
                    Are you sure you want to continue?
                  </div>
                )
              }
            }
          ]
        })
      }}
      drawer={drawer}
      formState={formState}
      isTemplate={true}
    />
  );
};

export default UnderwaterServicesTemplateWrapper;
