import NumberInput from 'common/components/form/inputs/NumberInput';
import React from 'react';
import CircledButton from 'common/components/buttons/CircledButton';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setPriceZoneDiscount } from 'store/purchasing/pricelist/slice';
import { selectPriceZoneDiscount } from 'store/purchasing/pricelist/selectors';
import { applyPurchasingPricelistPriceZoneDiscount } from 'store/purchasing/pricelist/actions';
import { PriceZone } from 'common/entities/pricelist/PricelistTypes';

const PriceZoneGroupDiscount = ({ priceZone }) => {
  const dispatch = useDispatch();
  const value = useSelector(state => selectPriceZoneDiscount(state, priceZone.id));

  return (
    <div>
      <div className="cmb-4">
        DISCOUNT <span className="text-violet">%</span>
      </div>
      <div className="d-flex flex-nowrap">
        <NumberInput
          className="mb-0 flex-1 me-1"
          innerClassName="py-0 height-20 text-end"
          allowNegative={false}
          value={value}
          isAllowed={value => !value?.floatValue || value?.floatValue <= 100}
          onChange={e =>
            dispatch(setPriceZoneDiscount({ priceZoneId: priceZone.id, value: e.target.value }))
          }
        />

        <CircledButton
          type="reset"
          svgStyle={{ width: 10, height: 10 }}
          style={{ width: 14, height: 14 }}
          svgWrapperClassName="bg-primary"
          svgClassName="text-white"
          onClick={() =>
            dispatch(applyPurchasingPricelistPriceZoneDiscount({ priceZoneId: priceZone?.id }))
          }
          disabled={!value}
        />
      </div>
    </div>
  );
};

PriceZoneGroupDiscount.propTypes = {
  priceZone: PriceZone.isRequired
};

export default PriceZoneGroupDiscount;
