import TYPES from './types';
import FORM_TYPES from 'store/forms/types';
import { parseAssignment } from 'views/settings/forms/helpers/utils';
import _cloneDeep from 'lodash/cloneDeep';
import { getModeledFormFields } from 'views/settings/forms/helpers/models';

const VISIBILITY_FORM_INITIAL_STATE = {
  visibility: {
    fill_new_form: true,
    pms_jobs: true,
    jobs: true,
    events: true,
    port_documents: true
  }
};

const ACTIVE_FORM_INITIAL_STATE = {
  importance_id: null,
  department_id: null,
  assignment: null,
  revision: null,
  vessels: [],
  name: null,
  fields: []
};

const INITIAL_STATE = {
  errors: {},
  drawerType: null,
  calculations: {},
  calculationsForm: {
    isDrawerOpen: false,
    field: {},
    fieldIndex: {}
  },
  form: {
    ...VISIBILITY_FORM_INITIAL_STATE
  },
  validations: {},
  validationsForm: {
    isModalOpen: false,
    field: {},
    fieldIndex: {}
  },
  activeForm: {
    ...ACTIVE_FORM_INITIAL_STATE
  },
  formTableIndexes: { rowIndex: null, columnIndex: null }, //used in calculationsForm and validationsForm
  isFormTableField: false //used in calculationsForm and validationsForm
};

const reducer = (state = _cloneDeep(INITIAL_STATE), { type, payload }) => {
  switch (type) {
    case TYPES.CLEAR_ERRORS:
      return {
        ...state,
        errors: {}
      };

    case TYPES.SET_ERRORS:
      return {
        ...state,
        errors: payload
      };

    case TYPES.CLEAR_CALCULATIONS:
      return {
        ...state,
        calculations: {}
      };

    case FORM_TYPES.GET_DIGITAL_FORM.START:
      return {
        ...state,
        form: {}
      };

    case FORM_TYPES.GET_DIGITAL_FORM.SUCCESS: {
      const form = { ...payload, ...parseAssignment(payload) };
      return {
        ...state,
        activeForm: {
          importance_id: form?.importance_id,
          department_id: form?.department_id,
          vessels: form?.vessels,
          revision: form?.revision,
          assignment: form?.assignment,
          name: form?.name,
          fields: getModeledFormFields(form?.form_fields || [])
        },
        form
      };
    }

    case TYPES.CLEAR_FORM_DATA:
      return {
        ...state,
        form: {
          ..._cloneDeep(VISIBILITY_FORM_INITIAL_STATE)
        },
        activeForm: {
          ...ACTIVE_FORM_INITIAL_STATE
        }
      };

    case FORM_TYPES.CREATE_FORM.START:
      return {
        ...state
      };

    case FORM_TYPES.UPDATE_DIGITAL_FORM.SUCCESS:
    case FORM_TYPES.CREATE_FORM.SUCCESS:
    case 'UPDATE_FORM_FIELDS/fulfilled':
    case 'UPDATE_FORM_VISIBILITY/fulfilled':
      return {
        ...state,
        form: {
          ...state.form,
          ...payload,
          ...parseAssignment(payload)
        }
      };

    case 'UPDATE_FORM_FIELDS/rejected':
      return {
        ...state
      };

    case TYPES.SET_CALCULATIONS:
      return {
        ...state,
        calculations: payload
      };

    case TYPES.CLEAR_NUMBER_FIELD_VALIDATIONS:
      return {
        ...state,
        validations: {}
      };

    case TYPES.SET_NUMBER_FIELD_VALIDATIONS:
      return {
        ...state,
        validations: payload
      };

    case TYPES.SET_CALCULATIONS_FORM_FIELD:
      return {
        ...state,
        calculationsForm: {
          ...state.calculationsForm,
          field: payload
        }
      };

    case TYPES.SET_CALCULATIONS_FORM_FIELD_INDEX:
      return {
        ...state,
        calculationsForm: {
          ...state.calculationsForm,
          fieldIndex: payload
        }
      };

    case TYPES.TOGGLE_CALCULATIONS_FORM_DRAWER:
      return {
        ...state,
        calculationsForm: {
          ...state.calculationsForm,
          isDrawerOpen: payload
        }
      };

    case TYPES.SET_VALIDATIONS_FORM_FIELD:
      return {
        ...state,
        validationsForm: {
          ...state.validationsForm,
          field: payload
        }
      };

    case TYPES.SET_VALIDATIONS_FORM_FIELD_INDEX:
      return {
        ...state,
        validationsForm: {
          ...state.validationsForm,
          fieldIndex: payload
        }
      };

    case TYPES.TOGGLE_VALIDATIONS_MODAL:
      return {
        ...state,
        validationsForm: {
          ...state.validationsForm,
          isModalOpen: payload
        }
      };

    case TYPES.SET_FORM_FIELD_INDEXES:
      return {
        ...state,
        formTableIndexes: payload
      };

    case TYPES.SET_IS_FORM_TABLE_FIELD:
      return {
        ...state,
        isFormTableField: payload
      };

    case TYPES.SET_ACTIVE_FORM_FIELD:
      return {
        ...state,
        activeForm: {
          ...state.activeForm,
          [payload.key]: payload.value
        }
      };

    case TYPES.SET_FORM_DRAWER_TYPE:
      return {
        ...state,
        drawerType: payload
      };

    default:
      return state;
  }
};

export default reducer;
