import _orderBy from 'lodash/orderBy';

export const normalizeRules = rules =>
  _orderBy(rules, 'type.sort_index').reduce(
    (prev, rule) => {
      prev.ruleIds.push(rule.id);

      prev.selectedRules = { ...prev.selectedRules, [rule.id]: rule };

      if (!prev.ruleTypeIds.includes(rule.type.id)) {
        prev.ruleTypeIds.push(rule.type.id);
        prev.ruleTypeDetails = { ...prev.ruleTypeDetails, [rule.type.id]: rule.type };
      }

      if (!prev.ruleTypesAssigned?.[rule.type.id]) {
        prev.ruleTypesAssigned[rule.type.id] = [];
      }

      prev.ruleTypesAssigned = {
        ...prev.ruleTypesAssigned,
        [rule.type.id]: [...prev.ruleTypesAssigned[rule.type.id], rule.id]
      };

      return prev;
    },
    { ruleIds: [], selectedRules: {}, ruleTypeIds: [], ruleTypesAssigned: {}, ruleTypeDetails: {} }
  );
