import TableSearchText from 'common/components/table/TableSearchText';
import * as sidebarActions from 'store/beacon/sidebar/actions';
import * as tableActions from 'common/components/table/store/actions';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { selectBeaconDrawerIsOpen } from 'store/beacon/sidebar/selectors';
import { setActivePostIdAction } from 'common/components/beacon/store/slice';
import { resetBeaconDrawerSearchAction } from 'store/beacon/sidebar/slice';
import { useEffect } from 'react';

const HeaderSearch = () => {
  const isOpen = useSelector(selectBeaconDrawerIsOpen);

  const [setTablePageSearch, searchBeaconPosts, setActivePostId, resetBeaconDrawerSearch] =
    useActions([
      tableActions.setTablePageSearch,
      sidebarActions.searchBeaconPosts,
      setActivePostIdAction,
      resetBeaconDrawerSearchAction
    ]);

  useEffect(() => {
    if (!isOpen) setTablePageSearch({ text: '', table: 'beacon_posts' });
  }, [isOpen, setTablePageSearch]);

  const onSearch = search => {
    if (search) {
      searchBeaconPosts({ search, paging: { current_page: 1, per_page: 100 } });
      setActivePostId(null);
    } else {
      resetBeaconDrawerSearch();
    }
  };

  return <TableSearchText label="beacon_posts" className="w-100p" onSearch={onSearch} />;
};

export default HeaderSearch;
