import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import { get, put } from 'utils/api';
import { selectDefaultMatrix, selectMatrices, selectSelectedMatrix } from './selectors';

export const getDefaultCrewMatrix = createAsyncThunk(
  'GET_DEFAULT_CREW_MATRIX',
  async (params, { rejectWithValue, getState, dispatch }) => {
    const { id } = params;
    const selectedMatrix = selectSelectedMatrix(getState());

    try {
      const res = await get(`/vessels/${id}/default-crew-matrix`);

      if (!selectedMatrix && res.data?.id) {
        dispatch(setSelectedMatrix({ id: res.data.id, vesselId: id }));
      }

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getVesselCrewMatrices = createAsyncThunk(
  'GET_VESSEL_CREW_MATRICES',
  async (params, { rejectWithValue }) => {
    const { id } = params;

    try {
      const res = await get(`/vessels/${id}/crew-matrices`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getVesselCrewMatrix = createAsyncThunk(
  'GET_VESSEL_CREW_MATRIX',
  async (params, { rejectWithValue }) => {
    const { vessel_id, id } = params;

    try {
      const res = await get(`/vessels/${vessel_id}/crew-matrices/${id}`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const setSelectedMatrix = createAsyncThunk(
  'SET_SELECTED_MATRIX',
  async (params, { rejectWithValue, getState }) => {
    const { id, vesselId } = params;
    const defaultMatrix = selectSelectedMatrix(getState());

    if (!id || defaultMatrix?.id === id) return;

    try {
      const res = await get(`/vessels/${vesselId}/crew-matrices/${id}`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const initializeMatrices = createAction('INITIALIZE_MATRICES');

export const setDefaultCrewMatrix = createAsyncThunk(
  'SET_DEFAULT_CREW_MATRIX',
  async (params, { rejectWithValue, getState }) => {
    const { id, vessel_id } = params;
    const defaultMatrix = selectDefaultMatrix(getState());

    if (!id || !vessel_id || defaultMatrix?.id === id) return;

    const isSatisfied = selectMatrices(getState()).find(matrix => matrix.id === id)?.is_satisfied;

    try {
      const res = await put(`vessels/${vessel_id}/default-crew-matrix`, { crew_matrix_id: id });

      return { ...res.data, is_satisfied: isSatisfied };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
