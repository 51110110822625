import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import Priority from 'common/components/general/Priority';
import Status from 'common/components/general/Status';
import DueDate from 'common/components/jobs/_base/components/DueDate';
import { Link } from 'react-router-dom';
import eyeSvg from 'common/assets/svg/common/eye.svg';

import paths from 'routing/routes/_paths';
import { useSelector } from 'react-redux';
import { selectWidgetState } from 'store/dashboards/selectors';

import _get from 'lodash/get';
import { Col, Row } from 'reactstrap';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import NamesInTooltip from 'common/components/general/NamesInTooltip';

const Jobs = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));

  return (
    <div>
      <WidgetTitle id={id} linkTo={paths.regular_jobs} />
      <WidgetDefaultTable
        id={id}
        columns={[
          {
            header: 'Title',
            key: 'title',
            headerClassName: 'text-violet',
            minWidth: 330
          },
          { header: 'Vessels', key: 'vessel', width: 2, headerClassName: 'text-violet' },
          {
            header: 'Status',
            key: 'status_id',
            type: 'collection',
            minWidth: 180,
            maxWidth: 180,
            headerClassName: 'text-violet'
          },
          {
            header: 'Due Date',
            key: 'due_date',
            type: 'date',
            headerClassName: 'text-violet',
            minWidth: 150,
            maxWidth: 150
          },
          {
            header: 'Actions',
            key: 'actions',
            field: 'actions'
          }
        ]}
        rows={{
          title: data => (
            <Row className="flex-nowrap overflow-hidden">
              <Col className="d-flex align-items-center justify-content-between max-width-72 min-width-72">
                {data.importance_id ? (
                  <Priority className="d-flex align-items-center" value={data.importance_id} />
                ) : (
                  <div />
                )}
                <Link
                  className="d-flex align-items-center"
                  to={`${paths.regular_jobs}/${data.id}`}
                  target="_blank"
                >
                  <strong className="fs-10 text-nowrap">{data.id}</strong>
                </Link>
              </Col>

              <Col xs="auto" className="pe-10">
                <TextWithTooltip>
                  <Link to={`${paths.regular_jobs}/${data.id}`} target="_blank">
                    {data.title}
                  </Link>
                </TextWithTooltip>
              </Col>
            </Row>
          ),
          vessel: data => {
            const vessel = _get(data, 'vessel.name', null);
            const vessels = _get(data, 'vessels', []);

            return <NamesInTooltip names={vessel ? [vessel] : vessels.map(ves => ves.name)} />;
          },
          status_id: data => <Status value={data.status_id} />,
          due_date: data => (
            <DueDate
              dueDateChanged={data.last_due_date_change}
              showTime={data.due_date_show_time}
              className="fs-12 fw-medium"
              dueDate={data.due_date}
              createdAt={data.created_at}
              status={data.status_id}
            />
          ),
          actions: data => ({
            options: [
              {
                label: 'View',
                icon: eyeSvg,
                onClick: () => window.open(`${paths.regular_jobs}/${data.id}`)
              }
            ]
          })
        }}
        label={label}
        state={state}
      />
    </div>
  );
};

export default Jobs;
