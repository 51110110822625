const selectBeaconReducer = state => state.beacon.sidebar;

export const selectBeaconDrawerIsOpen = state => selectBeaconReducer(state).drawerIsOpen;

export const selectBeaconDrawerHasSearch = state => selectBeaconReducer(state).hasSearch;

export const selectBeaconDrawerSearchIsLoading = state => selectBeaconReducer(state).isSearching;

export const selectBeaconDrawerSearchResults = state => selectBeaconReducer(state).searchResults;

export const selectBeaconDrawerSearchText = state => selectBeaconReducer(state).searchText;
