import React, { useEffect, useCallback } from 'react';
import {
  getReviewProcessNotes,
  deleteReviewProcessNote
} from 'common/components/review-process/store/actions';
import { useDispatch } from 'react-redux';
import ShadowBox from 'common/components/general/ShadowBox';
import Tags from 'common/components/general/Tags';
import Header from 'common/components/jobs/_base/modules/notes/components/Header';
import { PreviewHtml } from 'common/components/form/inputs/Editor';
import PropTypes from 'prop-types';
import { NoteState } from 'common/entities/notes/NoteTypes';
import { NoteEntityTypes } from 'common/entities/notes/NoteTypes';
import Form from 'common/components/review-process/components/notes/form/index';

const Preview = ({
  note,
  isLoading,
  activeNote,
  setActiveNote,
  reviewProcessId,
  setShowForm,
  fields,
  selectField,
  loadValues,
  closeForm,
  index
}) => {
  const dispatch = useDispatch();

  const load = useCallback(() => {
    loadValues(note);
  }, [loadValues, note]);

  const onEdit = () => {
    setShowForm(false);
    setActiveNote(note);
  };

  const onDelete = async () => {
    try {
      if (reviewProcessId) {
        await dispatch(deleteReviewProcessNote({ id: reviewProcessId, note_id: note.id })).unwrap();

        const res = await dispatch(getReviewProcessNotes({ id: reviewProcessId })).unwrap();

        if (!res?.length) {
          setShowForm(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    load();
  }, [load]);

  if (
    activeNote &&
    (reviewProcessId ? activeNote.id === note.id : activeNote?.index === note?.index)
  ) {
    return (
      <Form
        isLoading={isLoading}
        setActiveNote={setActiveNote}
        activeNote={activeNote}
        note={note}
        reviewProcessId={reviewProcessId}
        fields={fields}
        selectField={selectField}
        loadValues={loadValues}
        closeForm={closeForm}
      />
    );
  }

  return (
    <div className="mb-3">
      <Header note={note} onEdit={onEdit} onDelete={onDelete} />

      <ShadowBox className="d-flex flex-column h-100p position-relative p-1" color="light-1" flat>
        <div className="flex-1  px-2 pb-2">
          <PreviewHtml value={note?.comments} />
        </div>
        <div className="flex-1  px-2">
          {note?.tags?.length ? (
            <Tags
              placeholder="Add tag(s)"
              className="mb-0"
              tags={note.tags.map(t => t?.text)}
              invisible
              disabled
            />
          ) : null}
        </div>
      </ShadowBox>
    </div>
  );
};

Header.propTypes = {
  note: NoteState,
  activeNote: NoteState,
  closeForm: PropTypes.func,
  setActiveNote: PropTypes.func,
  isLoading: PropTypes.bool,
  reviewProcessId: PropTypes.number.isRequired,
  entityType: NoteEntityTypes,
  setShowForm: PropTypes.func,
  index: PropTypes.number
};

export default Preview;
