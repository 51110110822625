import { createSelector } from 'reselect';
import { getItemBreadcrumbs } from 'common/components/tree-layout/utils/helpers';
import _pickBy from 'lodash/pickBy';

const selectBeaconReducer = state => state.beacon.menus;
const getKey = (_, id) => id;

export const selectBeaconIsOfficeView = state => state.beacon?.sidebar?.officeView;

/* Init Tree */
export const selectIsTreeInitialized = state => selectBeaconReducer(state).isInitialized;
/* -- */

/* Root lvl */
export const selectRootMenus = state => selectBeaconReducer(state).rootMenus || [];
/* -- */

/* Sub-menus */
export const selectSubMenus = state => selectBeaconReducer(state).subMenus;
export const selectMenuSubMenus = (state, id) => selectSubMenus(state)?.[id];
export const hasNestedContent = createSelector(
  selectSubMenus,
  subMenus => Object.keys(subMenus)?.length
);
/* -- */

/* Menus */
export const selectMenusLoading = state => selectBeaconReducer(state).isFetchingMenus;
export const selectMenus = state => selectBeaconReducer(state).menus;
export const selectMenu = (state, id) => selectMenus(state)?.[id];

export const selectMenuPosts = state => selectMenu(state)?.posts;

export const selectMenusList = state => selectBeaconReducer(state).menusList;

export const selectNumberOfMenus = createSelector(selectMenus, menus => Object.keys(menus).length);
export const selectHasMenus = state => selectNumberOfMenus(state) > 0;

export const selectMenuBreadcrumbs = createSelector(selectMenus, getKey, (menus, id) =>
  getItemBreadcrumbs(id, menus)
);
/* -- */

/* Expanded Menus */
export const selectExpandedMenus = state => selectBeaconReducer(state).expandedMenus;
export const selectIsMenuExpanded = (state, id) => selectExpandedMenus(state)?.[id];
export const areAllMenusExpanded = createSelector(
  selectExpandedMenus,
  selectSubMenus,
  (expandedMenus, subMenus) => Object.keys(expandedMenus).length === Object.keys(subMenus).length
);
/* -- */

/* Search */
export const selectMenusTreeSearch = state => selectBeaconReducer(state).treeSearch;
export const selectHasSearch = state => !!selectMenusTreeSearch(state);

export const selectMenusInSearch = state => selectBeaconReducer(state).menusInSearch;

export const isMenusInSearch = createSelector(
  selectMenusInSearch,
  getKey,
  selectHasSearch,
  selectMenusLoading,
  (menusInSearch, id, hasSearch, isLoading) =>
    !isLoading && hasSearch ? menusInSearch?.[id] : true
);
/* -- */

// /* Active Menu */
export const selectActiveMenuId = state => {
  const activeMenuId = selectBeaconReducer(state).activeMenuId;

  return activeMenuId ? parseInt(activeMenuId) : null;
};

export const selectActiveMenu = createSelector(
  selectMenus,
  selectActiveMenuId,
  (menus, activeMenuId) => menus[activeMenuId]
);

export const selectMenuFormIsVisible = state => selectBeaconReducer(state).menuFormIsVisible;
// /* -- */

// /* Active Post */
export const selectActivePostId = state => selectBeaconReducer(state).activePostId;

export const selectActivePostMenuId = createSelector(
  selectMenus,
  selectActivePostId,
  (menus, activePostId) => {
    if (activePostId) {
      const matchingMenu = _pickBy(menus, item => item.post_id && item.post_id === activePostId);

      if (matchingMenu) {
        const menu = Object.values(matchingMenu)[0];

        return menu?.id;
      }
    }

    return null;
  }
);

// /* -- */
