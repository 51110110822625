import { selectIsCreate, selectIsTemplate } from 'events/store/events/selectors';
import { createSelector } from 'reselect';

const getKey = (_, key) => key;
const selectIsOnboard = state => state.isOnBoard;

const selectAudits = state => state.eventModules.audits;

export const selectAuditsAreLoading = state => selectAudits(state).loading;
export const selectAuditsData = state => selectAudits(state).data.audits;
export const selectAuditsDataLength = state => selectAuditsData(state).length;
export const selectSubmissionsFindingsData = state => selectAudits(state).data.findings_submissions;
export const selectEvaluationCriteria = state => selectAudits(state).evaluation_criteria;

const selectFindings = state => selectAudits(state).data.findings;

export const selectAuditsFindings = createSelector([selectFindings, getKey], (findings, auditId) =>
  findings.filter(finding => finding.auditId === auditId)
);

export const selectAreAuditActionsLocked = state => {
  const isOnBoard = selectIsOnboard(state);
  const isCreate = selectIsCreate(state);
  const isTemplate = selectIsTemplate(state);

  if (isTemplate || isCreate) return false;

  if (isOnBoard) return true;

  return false;
};
