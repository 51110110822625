import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import setup from 'common/components/purchasing/library-setup/store/reducer';
import requisitions from 'common/components/purchasing/requisition/store/reducer';
import requisitionSummary from 'store/purchasing/requisition-summary/slice';
import categories from 'store/purchasing/categories/reducer';
import pricelist from 'store/purchasing/pricelist/slice';
import deliveryReport from '@/common/components/purchasing/deliveries/store/slice.ts';
import forwarding from '@/common/components/purchasing/forwarding/store/slice.ts';

const requisitionsPersistConfig = {
  key: 'requisitions',
  storage,
  whitelist: ['itemsTableConfiguration']
};

export default combineReducers({
  setup,
  requisitions: persistReducer(requisitionsPersistConfig, requisitions),
  requisitionSummary,
  categories,
  pricelist,
  deliveryReport,
  forwarding
});
