import TYPES from './types';
import { layerOptions } from 'views/the-fleet/_helpers';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

const INITIAL_TOOLS = {
  distance: false,
  goTo: false,
  erbl: false
};

const INITIAL_STATE = {
  weather: {},
  layers: layerOptions.reduce((acc, cur) => {
    acc[cur.value] = false;
    return acc;
  }, {}),
  vessel: null,
  vesselGroup: null,
  activeView: 'simple',
  fleet: [],
  vesselRoute: [],
  vesselRouteId: null,
  automatedRoute: null,
  automatedRouteId: null,
  isLoading: true,
  loadLines: [],
  estimatedRoute: {},
  weatherDate: null,
  isBottomTabOpen: false,
  activeBottomTab: null,
  isMenuPortalOpen: false,
  isWeatherLoading: false,
  isFullRouteEnabled: false,
  bottomTabPosition: 0,
  cardView: 'overview',
  tools: INITIAL_TOOLS,
  selectedCustomArea: null,
  customAreaData: null,
  customAreas: [],
  customAreasLoading: false,
  isCreatingCustomArea: false,
  customAreaWarning: '',
  openedVessels: []
};

const reducer = (state = _cloneDeep(INITIAL_STATE), { type, payload }) => {
  switch (type) {
    case TYPES.SET_CUSTOM_AREA_WARNING:
      return {
        ...state,
        customAreaWarning: payload
      };
    case TYPES.DELETE_CUSTOM_AREA.SUCCESS:
      return {
        ...state,
        customAreas: state.customAreas.filter(el => el.id !== payload)
      };
    case TYPES.GET_CUSTOM_AREAS.START:
      return {
        ...state,
        customAreasLoading: true
      };
    case TYPES.GET_CUSTOM_AREAS.SUCCESS:
      return {
        ...state,
        customAreas: payload
      };
    case TYPES.GET_CUSTOM_AREAS.ERROR:
      return {
        ...state,
        customAreasLoading: false
      };
    case TYPES.CREATE_CUSTOM_AREA.START:
      return {
        ...state,
        isCreatingCustomArea: true
      };
    case TYPES.CREATE_CUSTOM_AREA.SUCCESS:
      return {
        ...state,
        isCreatingCustomArea: false,
        customAreas: [...state.customAreas, payload]
      };
    case TYPES.CREATE_CUSTOM_AREA.ERROR:
      return {
        ...state,
        isCreatingCustomArea: false
      };
    case TYPES.SET_CUSTOM_AREA_DATA:
      return {
        ...state,
        customAreaData: payload
      };
    case TYPES.SET_CURRENT_CUSTOM_AREA:
      return {
        ...state,
        ...payload
      };

    case TYPES.SET_SELECTED_CUSTOM_AREA:
      return {
        ...state,
        selectedCustomArea: payload
      };
    case TYPES.RESET_TOOLS:
      return {
        ...state,
        tools: INITIAL_TOOLS
      };
    case TYPES.SET_TOOL:
      return {
        ...state,
        tools: {
          ...INITIAL_TOOLS,
          ...payload
        }
      };

    case TYPES.SET_CARD_VIEW:
      return {
        ...state,
        cardView: payload
      };

    case TYPES.SET_WEATHER:
      return {
        ...state,
        weather: {
          ...state.weather,
          [payload.name]:
            typeof variable !== 'undefined' ? payload.value : !state.weather[payload.name]
        }
      };

    case TYPES.CLEAR_WEATHER:
      return {
        ...state,
        weather: Object.assign(
          ...Object.keys(state.weather).map(key =>
            layerOptions.find(layer => layer.value === key && layer.isWeather)
              ? { [key]: state.weather[key] }
              : { [key]: false }
          )
        )
      };

    case TYPES.SET_IS_WEATHER_LOADING:
      return {
        ...state,
        isWeatherLoading: payload
      };

    case TYPES.SET_IS_FULL_ROUTE_ENABLED:
      return {
        ...state,
        isFullRouteEnabled: payload
      };

    case TYPES.SET_IS_FLEET_LOADING:
      return {
        ...state,
        isLoading: payload
      };

    case TYPES.SET_LAYERS:
      return {
        ...state,
        layers: payload
      };

    case TYPES.SET_VESSEL:
      return {
        ...state,
        vessel: payload
      };

    case TYPES.SET_VESSEL_GROUP:
      return {
        ...state,
        vesselGroup: state.vesselGroup?.id !== payload?.id ? payload : state.vesselGroup
      };

    case TYPES.GET_FLEET.ERROR:
    case TYPES.GET_FLEET.START:
      return {
        ...state,
        fleet: [],
        vessel: null
      };

    case TYPES.GET_FLEET.SUCCESS:
      return {
        ...state,
        fleet: payload,
        vessel: null
      };

    case TYPES.GET_AUTOMATED_ROUTE.SUCCESS:
      const lastTimestamp = _get(payload.data, 'last_position.timestamp');
      return {
        ...state,
        automatedRoute: payload.data,
        fleet:
          state.automatedRouteId === payload.id && payload.isAtInitialPort
            ? state.fleet.map(vessel => {
                const shouldChangePosition =
                  lastTimestamp &&
                  vessel.position &&
                  vessel.position.timestamp &&
                  moment(vessel.position.timestamp).isBefore(moment(lastTimestamp));

                return vessel.id === payload.id && shouldChangePosition
                  ? {
                      ...vessel,
                      position: {
                        ...vessel.position,
                        ...payload.data.last_position
                      }
                    }
                  : vessel;
              })
            : state.fleet
      };

    case TYPES.SET_WEATHER_DATE:
      return {
        ...state,
        weatherDate: payload
      };

    case TYPES.CLEAR_AUTOMATED_ROUTE:
    case TYPES.GET_AUTOMATED_ROUTE.ERROR:
    case TYPES.GET_AUTOMATED_ROUTE.START:
      return {
        ...state,
        automatedRoute: null,
        automatedRouteId: payload
      };

    case TYPES.GET_LOAD_LINES.ERROR:
    case TYPES.GET_LOAD_LINES.START:
      return {
        ...state,
        loadLines: []
      };

    case TYPES.GET_LOAD_LINES.SUCCESS:
      return {
        ...state,
        loadLines: payload
      };

    case TYPES.CLEAR_REPORT_ROUTE:
    case TYPES.GET_VOYAGE_ROUTE.ERROR:
    case TYPES.GET_VOYAGE_ROUTE.START:
      return {
        ...state,
        vesselRoute: [],
        vesselRouteId: payload
      };

    case TYPES.GET_VOYAGE_ROUTE.SUCCESS:
      return {
        ...state,
        vesselRoute: payload
      };

    case TYPES.GET_ESTIMATED_ROUTE.ERROR:
    case TYPES.GET_ESTIMATED_ROUTE.START:
    case TYPES.CLEAR_ESTIMATED_ROUTE:
      return {
        ...state,
        estimatedRoute: []
      };

    case TYPES.GET_ESTIMATED_ROUTE.SUCCESS:
      return {
        ...state,
        estimatedRoute: payload
      };

    case TYPES.SET_ACTIVE_VIEW:
      return {
        ...state,
        activeView: payload
      };

    case TYPES.SET_IS_BOTTOM_TAB_OPEN:
      return {
        ...state,
        isBottomTabOpen: payload
      };

    case TYPES.SET_ACTIVE_BOTTOM_TAB:
      return {
        ...state,
        activeBottomTab: payload
      };

    case TYPES.SET_IS_MENU_PORTAL_OPEN:
      return {
        ...state,
        isMenuPortalOpen: payload
      };

    case TYPES.SET_BOTTOM_TAB_POSITION:
      return { ...state, bottomTabPosition: payload };

    case TYPES.CLEAR_STATE:
      return {
        ...INITIAL_STATE,
        // Things to keep (persist)
        vesselGroup: state.vesselGroup,
        activeView: state.activeView || INITIAL_STATE.activeView,
        bottomTabPosition: state.bottomTabPosition,
        cardView: state.cardView || INITIAL_STATE.cardView
      };

    case TYPES.ADD_VESSEL:
      return { ...state, openedVessels: _uniq([...state.openedVessels, payload]) };

    case TYPES.REMOVE_VESSEL:
      return { ...state, openedVessels: _uniq(state.openedVessels.filter(el => el !== payload)) };

    case TYPES.TOGGLE_VESSEL:
      if (state.openedVessels.find(el => el === payload)) {
        return { ...state, openedVessels: _uniq(state.openedVessels.filter(el => el !== payload)) };
      } else {
        return { ...state, openedVessels: _uniq([...state.openedVessels, payload]) };
      }

    case TYPES.CLEAR_VESSELS:
      return { ...state, openedVessels: [] };

    default:
      return state;
  }
};

export default reducer;
