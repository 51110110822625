import PurchasingItemsSelector from 'common/components/selectors/PurchasingItemsSelector';
import PriceZoneItemInput from './PriceZoneItemInput';
import RemoveItem from './RemoveItem';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import StoreDescription from './StoreDescription';
import SvgRender from 'common/components/general/SvgRender';
import check from 'common/assets/svg/common/check-circle-solid.svg';

export const staticColumns = [
  {
    header: 'Item Group',
    type: 'string',
    sortingKey: 'store_group',
    key: 'store_group_id',
    minWidth: 282,
    maxWidth: 282,
    sort: true,
    canFilter: false
  },
  {
    header: 'Code',
    type: 'string',
    key: 'code',
    minWidth: 140,
    maxWidth: 140,
    sort: true
  },
  {
    header: 'Item',
    key: 'store_id',
    sortingKey: 'description',
    minWidth: 380,
    maxWidth: 380,
    sort: true,
    type: 'collection',
    component: PurchasingItemsSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Unit',
    type: 'string',
    sortingKey: 'unit',
    key: 'unit_id',
    minWidth: 104,
    maxWidth: 104,
    bodyClassName: 'unit-cell',
    sort: true,
    canFilter: false
  },
  {
    header: 'IHM',
    type: 'string',
    key: 'ihm_related',
    minWidth: 104,
    maxWidth: 104,
    bodyClassName: 'last-static-cell',
    sort: false,
    canFilter: false
  }
];

export const rows = {
  store_group_id: data => (
    <TextWithTooltip className="h-auto">{data.store?.store_group?.name ?? '-'}</TextWithTooltip>
  ),
  code: data => <TextWithTooltip className="h-auto">{data.store?.code ?? '-'}</TextWithTooltip>,
  store_id: data => <StoreDescription data={data.store} />,
  unit_id: data => data.store?.purchasing_unit?.notation ?? '-',
  price: (data, column, _, rowIndex) => (
    <PriceZoneItemInput itemId={data.id} column={column} rowIndex={rowIndex} />
  ),
  discount: (data, column, _, rowIndex) => (
    <PriceZoneItemInput itemId={data.id} column={column} rowIndex={rowIndex} />
  ),
  remove_item: data => <RemoveItem item={data} />,
  ihm_related: data =>
    data.store?.ihm_related ? (
      <SvgRender src={check} style={{ width: 16, height: 16 }} className="text-turquoise" />
    ) : null
};
