import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('CREATE_DASHBOARD'),
  ...asyncTypes('FETCH_ALL_DASHBOARDS'),
  ...asyncTypes('FETCH_DASHBOARD'),
  ...asyncTypes('DELETE_DASHBOARD'),
  ...asyncTypes('EDIT_DASHBOARD'),
  ...asyncTypes('CLONE_DASHBOARD'),
  ...asyncTypes('CREATE_DASHBOARD_FROM'),
  ...asyncTypes('UPDATE_DASHBOARD_WIDGETS'),
  ...asyncTypes('ADD_WIDGET'),
  ...asyncTypes('REMOVE_WIDGET'),
  ...asyncTypes('GET_WIDGET_DATA'),
  ...asyncTypes('GET_WIDGET_PREFERENCES'),
  ...asyncTypes('UPDATE_WIDGET_PREFERENCES'),
  ...asyncTypes('UPDATE_WIDGET'),
  ...asyncTypes('DELETE_WIDGET'),
  UPDATE_WIDGET_STATE: 'UPDATE_WIDGET_STATE',
  SET_WIDGET_DATA: 'SET_WIDGET_DATA',
  SET_DASHBOARD_FORM: 'SET_DASHBOARD_FORM',
  SET_DASHBOARD_WIDGETS_FORM: 'SET_DASHBOARD_WIDGETS_FORM',
  SET_WIDGET_SETTINGS_FORM: 'SET_WIDGET_SETTINGS_FORM',
  SET_WIDGET_PREVENTION_MODAL: 'SET_WIDGET_PREVENTION_MODAL'
};

export default TYPES;
