import _get from 'lodash/get';
import { numberToStr } from './format';
import { vesselInfoMappings } from './vesselInfoMappings';

const config = {
  // Errors
  cargo_lift_exceeded: {
    type: 'error',
    positions: ['cp'],
    message: () => "CP minimum cargo quantity exceeds vessel's capacity."
  },
  no_opening_date: {
    type: 'error',
    positions: ['top'],
    message: 'Opening date not set.'
  },
  no_fuels: {
    type: 'error',
    positions: ['top'],
    message: 'Insufficient fuels.'
  },
  no_tc_hires_found: {
    type: 'error',
    positions: ['top'],
    message:
      'Vessel is chartered, but chartering details are not set. You can set it from vessel profile.'
  },
  vessel_info_not_set: {
    type: 'warning',
    positions: ['port'],
    message: ({ info }) => `Please fill ${_get(vesselInfoMappings, info)} of the vessel.`
  },
  cubic_capacity_exceeded: {
    type: 'error',
    positions: ['cp'],
    message: "Cargo exceeds the vessel's holds cubic capacity."
  },
  arrival_after_canceling: {
    type: 'error',
    positions: ['cp'],
    message: ({ port }) => `Arrival on port ${port} is projected after cancelling date.`
  },
  cp_loading_exceeded: {
    type: 'error',
    positions: ['port'],
    message: ({ cargo, port }) =>
      `Loading of ${parseFloat(cargo).toFixed(
        2
      )} at ${port} exceeds charterer's appointed quantity.`
  },
  discharge_exceeded: {
    type: 'error',
    positions: ['port'],
    message: ({ cargo, port }) =>
      `Discharge of ${parseFloat(cargo).toFixed(2)} at ${port} exceeds loaded quantity.`
  },
  vessel_exceeds_max_draft_error: {
    type: 'error',
    positions: ['port'],
    message: ({ port }) => `${port} max allowed draft is inadequate.`
  },
  no_market_index_on_vessel: {
    type: 'error',
    positions: ['cp'],
    message: ({ vessel }) => `No market indexes selected for ${vessel.name}.`
  },
  no_market_index_selected: {
    type: 'error',
    positions: ['cp'],
    message: 'No market index is selected'
  },
  // Warnings
  rob_fuels_insufficient: {
    type: 'warning',
    positions: ['port'],
    message: 'Estimated ROB fuel capacity will not be sufficient (below 0).'
  },
  rob_fuels_undesirable: {
    type: 'warning',
    positions: ['port'],
    message: 'Estimated ROB fuel capacity is below desired ROB fuel capacity.'
  },
  insufficient_fuels: {
    type: 'warning',
    positions: ['top'],
    message: ({ remaining, grade_used, port }) =>
      `Fuel ${grade_used.toUpperCase()} will not be sufficient at ${port} (missing ${parseFloat(
        numberToStr(remaining)
      ).toFixed(2)} mt).`
  },
  cp_loading_not_complete: {
    type: 'warning',
    positions: ['port'],
    message: ({ cargo }) =>
      `Loading less ${parseFloat(cargo).toFixed(2)} than charterer's appointed quantity.`
  },
  discharge_not_complete: {
    type: 'warning',
    positions: ['port'],
    message: ({ cargo }) => `Discharging less ${parseFloat(cargo).toFixed(2)} than loaded.`
  },
  arrival_before_layday: {
    type: 'warning',
    positions: ['port'],
    message: ({ port }) => `Arrival on port ${port} is projected before layday.`
  },
  layday_not_filled: {
    type: 'warning',
    positions: ['cp'],
    message: () => 'Please fill in Layday date.'
  },
  cancelling_not_filled: {
    type: 'warning',
    positions: ['cp'],
    message: () => 'Please fill in Cancelling date.'
  },
  vessel_exceeds_max_draft: {
    type: 'warning',
    positions: ['port'],
    message: ({ port, max_cargo }) =>
      `Max draft of ${port} limits intake to ${parseFloat(max_cargo).toFixed(2)} mt.`
  },
  port_dwt: {
    type: 'warning',
    positions: ['port'],
    message: ({ cargo, port }) => `Cargo loded at ${port} exceeds vessel's capacity.`
  },

  vessel_dwt: {
    type: 'warning',
    positions: ['port'],
    message: ({ cargo, port }) => `Cargo loded at ${port} exceeds vessel's capacity.`
  }
};

export default config;
