import { getBeaconMenus, createBeaconMenu } from './actions';
import { createSlice } from '@reduxjs/toolkit';
import {
  getRootItemsIDs,
  getSubItems,
  normalizeTreeStructure,
  toggleNestedItems,
  getAscendanceTree,
  getItemBreadcrumbs,
  getTreeOfSearchedItems
} from 'common/components/tree-layout/utils/helpers';
import { LOCATION_CHANGE } from 'connected-react-router';
import _pickBy from 'lodash/pickBy';
import paths from 'routing/routes/_paths';

const INITIAL_STATE = {
  menuFormIsVisible: false,

  treeSearch: '',
  menusInSearch: {}, // pages that include the search term in their title

  rootMenus: [],
  subMenus: [],
  menus: {},
  menusList: [], // All menus as a flat aray of objects - as returned from API. Use it for selects, listings, etc.

  expandedMenus: {},

  isFetchingMenus: false,
  isInitialized: false,

  activeMenuId: null,
  activePostId: null
};

const slice = createSlice({
  name: 'beacon',
  initialState: INITIAL_STATE,
  reducers: {
    setTreeSearchAction: (state, { payload }) => {
      state.treeSearch = payload;

      return state;
    },
    expandAllMenusAction: (state, { payload }) => {
      state.expandedMenus = toggleNestedItems(state.subMenus, payload);

      return state;
    },
    expandeMenuAction: (state, { payload }) => {
      if (payload.ids) {
        state.expandedMenus = { ...payload.ids }; // override expandedMenus
      } else {
        state.expandedMenus[payload.id] = payload.isOpen;
      }

      return state;
    },
    setActiveMenuIdAction: (state, { payload }) => {
      state.activeMenuId = payload;

      return state;
    },
    setActivePostIdAction: (state, { payload }) => {
      state.activePostId = payload;

      return state;
    },
    toggleMenuFormAction: (state, { payload }) => {
      state.menuFormIsVisible = payload;

      return state;
    }
  },
  extraReducers: {
    [getBeaconMenus.pending]: state => {
      state.isFetchingMenus = true;

      return state;
    },
    [getBeaconMenus.fulfilled]: (state, { payload, meta }) => {
      state.isFetchingMenus = false;
      state.isInitialized = true;

      if (meta?.arg?.search) {
        const menusInSearch = getTreeOfSearchedItems(state.menus, state.subMenus, payload);

        state.menusInSearch = menusInSearch;
        state.expandedMenus = _pickBy(menusInSearch, (_, key) => state.subMenus[key]);

        return state;
      }

      state.rootMenus = getRootItemsIDs(payload);
      state.subMenus = getSubItems(payload);
      state.menus = normalizeTreeStructure(payload);
      state.menusList = payload.map(menu => ({
        ...menu,
        level: getAscendanceTree(menu.id, state.menus)?.length - 1,
        breadcrumbs: getItemBreadcrumbs(menu.id, state.menus)
      }));

      return state;
    },
    [getBeaconMenus.rejected]: state => {
      state.isFetchingMenus = false;
      state.isInitialized = true;

      return state;
    },
    [createBeaconMenu.fulfilled]: (state, { payload }) => {
      if (payload.parent_id && !state.expandedMenus[payload.parent_id]) {
        state.expandedMenus[payload.parent_id] = true;
      }

      return state;
    },
    [LOCATION_CHANGE]: (state, { payload }) => {
      if (!payload.location.pathname.startsWith(paths.beacon)) {
        state.isInitialized = false;
      }

      state.activeMenuId = false;
      state.menuFormIsVisible = false;
      state.expandedMenus = {};
      state.treeSearch = '';

      return state;
    }
  }
});

export const {
  setTreeSearchAction,
  expandAllMenusAction,
  expandeMenuAction,
  setActiveMenuIdAction,
  setActivePostIdAction,
  toggleMenuFormAction
} = slice.actions;

export default slice.reducer;
