import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { selectWidgetStateData, selectWidgetStatePaging } from 'store/dashboards/selectors';
import BookmarkLine from 'views/dashboard/widgets/types/bookmarks-card/components/BookmarkLine';

import { useActions } from 'utils/hooks';
import * as dashboardsActions from 'store/dashboards/actions';
import PaginationNavigation from 'common/components/table/main/PaginationNavigation';
import PropTypes from 'prop-types';

const BookmarksTable = ({ id }) => {
  const paging = useSelector(state => selectWidgetStatePaging(state, id));
  const bookmarksData = useSelector(state => selectWidgetStateData(state, id));
  const [updateWidgetState] = useActions([dashboardsActions.updateWidgetState]);
  const className = 'bg-white border-radius-5';
  const columnsCount = 4;
  const itemsPerColumn = 8;

  const calculateItemRanges = () => {
    const itemRanges = [];
    for (let i = 0; i < columnsCount; i++) {
      const start = i * itemsPerColumn;
      const end = start + itemsPerColumn;
      itemRanges.push({ start, end });
    }
    return itemRanges;
  };

  const handlePageChange = async ({ page }) => {
    updateWidgetState({ paging: { ...paging, current_page: page } }, id);
  };

  return (
    <div className="w-100p">
      <WidgetTitle id={id} />

      {bookmarksData?.length ? (
        <Row className="flex-1 pt-2">
          {calculateItemRanges().map(({ start, end }, columnIndex) => (
            <Col key={columnIndex} xs={3}>
              {bookmarksData.slice(start, end).map(bookmark => (
                <BookmarkLine key={bookmark.id} bookmark={bookmark} className={className} />
              ))}
            </Col>
          ))}
        </Row>
      ) : null}

      <div className="d-flex justify-content-end mt-2 ms-auto">
        <PaginationNavigation paging={paging} customTableFetch={handlePageChange} />
      </div>
    </div>
  );
};

BookmarksTable.propTypes = {
  id: PropTypes.number
};

export default BookmarksTable;
