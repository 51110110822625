import captainReports from 'common/assets/svg/common/crew.svg';
import events from 'common/assets/svg/common/events.svg';
import forms from 'common/assets/svg/common/forms.svg';
import evaluationObligation from 'common/assets/svg/common/star.svg';
import crew from 'common/assets/svg/common/crew_planning.svg';
import ticketing from 'assets/svg/sidebar/ticketing.svg';
import reviewProcesses from 'common/assets/svg/common/review-process.svg';
import purchasing from 'common/assets/svg/common/purchasing.svg';
import accounting from 'common/assets/svg/accounting/calculate.svg';
import newsAndAnnouncements from 'common/assets/svg/common/news-announcements.svg';
import charterParty from 'common/assets/svg/voyages/charter-party.svg';

import { TYPES, COMPONENT_TYPES } from 'components/layouts/sidebar/panel/notifications/_types';
import { getNotificationComponentType } from './_helpers';
import clock from 'common/assets/svg/common/reminder.svg';

const icons = {
  [COMPONENT_TYPES.CAPTAIN_REPORTS]: captainReports,
  [COMPONENT_TYPES.EVENTS]: events,
  [COMPONENT_TYPES.FORMS]: forms,
  [COMPONENT_TYPES.EVALUATION_OBLIGATIONS]: evaluationObligation,
  [COMPONENT_TYPES.SEAMEN]: crew,
  [COMPONENT_TYPES.TICKETING]: ticketing,
  [COMPONENT_TYPES.PURCHASING]: purchasing,
  [COMPONENT_TYPES.OFFICE_PURCHASING_REQUISITIONS]: purchasing,
  [COMPONENT_TYPES.VESSEL_PURCHASING_REQUISITIONS]: purchasing,
  [COMPONENT_TYPES.MGA]: accounting,
  [COMPONENT_TYPES.NEWS_AND_ANNOUNCEMENTS]: newsAndAnnouncements,
  [COMPONENT_TYPES.CHARTER_PARTY]: charterParty,
  [COMPONENT_TYPES.VESSEL_DOCUMENTS]: forms,

  [TYPES.PURCHASING_REVIEW_PROCESS_CREATED]: reviewProcesses,
  [TYPES.PURCHASING_REVIEW_PROCESS_ACTION_REQUIRED]: reviewProcesses,
  [TYPES.VESSEL_DOCUMENTS_REMINDER]: clock
};

const svgClassNames = {};

const getIcon = type => {
  const componentType = getNotificationComponentType(type);

  const icon = icons?.[type] || icons?.[componentType];
  const svgClassName = svgClassNames?.[type] || svgClassNames?.[componentType];

  return {
    icon,
    svgClassName
  };
};

export default getIcon;
