import React from 'react';
import FormPreviewActions from 'common/components/form/FormPreviewActions';
import PersonBasicInfo from 'common/components/person/components/PersonBasicInfo';
import { selectAccountId } from 'store/account/selectors';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { NoteState } from 'common/entities/notes/NoteTypes';
import { dateToLocal } from 'common/utils/dates';
import InputSaveActions from 'common/components/jobs/_base/modules/notes/form/InputSaveActions';

const Header = ({ note, onEdit, onDelete, activeNote, onCancel, handleSubmit, canSubmit }) => {
  const userId = useSelector(selectAccountId);

  // Can see actions only if the logged in user is the user that created a note
  const showActions = onEdit && onDelete && note?.author_id === userId;

  return (
    <>
      {note ? (
        <div className="d-flex align-items-center mb-1">
          <div className="d-flex">
            <PersonBasicInfo
              person={note?.author}
              withFlag={false}
              className="fs-12 text-primary"
              imgProps={{ width: 20, height: 20 }}
            />
            {showActions ? <FormPreviewActions onEdit={onEdit} onDelete={onDelete} /> : null}
          </div>
          <div className="ms-auto text-nowrap text-violet fs-12">
            {activeNote?.id === note?.id && !showActions ? (
              <InputSaveActions onCancel={onCancel} onSave={handleSubmit} canSubmit={canSubmit} />
            ) : note?.created_at ? (
              dateToLocal(note.created_at).format('DD MMM YYYY, hh:mm a')
            ) : null}
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-between mb-1">
          <div className="form-label mb-0">NOTES</div>
          <InputSaveActions onCancel={onCancel} onSave={handleSubmit} canSubmit={canSubmit} />
        </div>
      )}
    </>
  );
};

Header.propTypes = {
  note: NoteState,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  canSubmit: PropTypes.bool,
  activeNote: NoteState
};

export default Header;
