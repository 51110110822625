import React from 'react';

const Dots = ({ consumptionKeys, active, setActive }) => {
  const Dot = ({ filled, className, onClick }) => {
    return (
      <div
        onClick={onClick}
        className={`dot d-flex justify-content-center align-items-center ${
          className ? className : ''
        }`}
      >
        {!filled && <div className="dot-filled" />}
      </div>
    );
  };

  return (
    <div className="vessel-consumptions-dots d-flex justify-content-end align-items-center">
      {consumptionKeys.map((el, i) => {
        return (
          <Dot
            key={el.name}
            onClick={() => setActive(el)}
            filled={true}
            className={`vessel-consumption-${el.color} me-1 ${
              active.name === el.name ? 'active' : ''
            }`}
          />
        );
      })}
    </div>
  );
};

export default Dots;
