import { deleteRequest, get, put } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { successHandler } from 'common/utils/notifications';
import { getDescendanceTree } from 'common/components/tree-layout/utils/helpers';
import {
  selectActiveEntityId,
  selectActiveEntityModel,
  selectGroup,
  selectGroups,
  selectSubGroups
} from './selectors';
import { selectItineraryEditScheduledPortsOnboard } from '@/store/settings/selectors';
import permissions from '@/common/utils/permissions/constants';
import _isArray from 'lodash/isArray';

export const getEntityPermissions = createAsyncThunk(
  'GET_ENTITY_PERMISSIONS',
  async (params, { rejectWithValue, getState }) => {
    try {
      const enabledEditScheduledPorts = selectItineraryEditScheduledPortsOnboard(getState());
      const res = await get(`/permissions`, params);

      return res.data?.length
        ? res.data.filter(d =>
            !enabledEditScheduledPorts && d.label === permissions.onboard.voyages.itinerary.edit
              ? false
              : true
          )
        : [];
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getDescendanceGroupPermissions = (id, state) => {
  const groups = selectGroups(state);
  const subGroups = selectSubGroups(state);

  return getDescendanceTree(id, groups, subGroups);
};

export const setEntityGroupPermissions = createAsyncThunk(
  'SET_ENTITY_GROUP_PERMISSIONS',
  async ({ id, single }, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const params = { id: selectActiveEntityId(state), model: selectActiveEntityModel(state) };

    if (single) {
      const permission = selectGroup(state, id);

      params.permissions = { [permission.id]: permission.details };
    } else {
      const permissions = getDescendanceGroupPermissions(id, state);

      params.permissions = permissions.reduce((acc, permission) => {
        acc[permission.id] = permission.details;

        return acc;
      }, {});
    }

    try {
      await put(`/permissions/bulk`, params);

      if (!single) dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return params;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const unsetEntityGroupPermissions = createAsyncThunk(
  'UNSET_ENTITY_GROUP_PERMISSIONS',
  async ({ id, single }, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const params = { id: selectActiveEntityId(state), model: selectActiveEntityModel(state) };

    if (single) {
      const permission = selectGroup(state, id);

      params.labels = [permission?.id];
    } else {
      const permissions = getDescendanceGroupPermissions(id, state);

      params.labels = permissions.map(({ id }) => id);
    }

    try {
      await deleteRequest(`/permissions/bulk`, params);

      if (!single) dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return params;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateEntityGroupConfigurationDetails = createAsyncThunk(
  'UPDATE_ENTITY_GROUP_CONFIGURATION_DETAILS',
  async ({ id }, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const params = { id: selectActiveEntityId(state), model: selectActiveEntityModel(state) };

    const permission = selectGroup(state, id);
    let details = null;

    if (permission.form_details) {
      details = {};

      Object.keys(permission.form_details).forEach(type => {
        details[type] = _isArray(permission.form_details[type])
          ? permission.form_details[type]?.map(item =>
              type === 'forms' ? item?.uid : item?.id ?? item
            )
          : permission.form_details[type];
      });
    }

    params.permissions = { [permission.id]: details };

    try {
      await put(`/permissions/bulk`, params);
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return id;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
