import moment from 'moment';

import { captainReportIcon } from 'captain-reports/utils/helpers';
import paths from 'routing/routes/_paths';
import { reportURL } from 'captain-reports/components/ReportLink';
import _get from 'lodash/get';

import pmsJobs from 'common/assets/svg/common/setup.svg';
import purchasing from 'common/assets/svg/common/purchasing.svg';
import documents from 'common/assets/svg/common/documents.svg';
import accounting from 'common/assets/svg/accounting/calculate.svg';
import jobs from 'common/assets/svg/common/jobs.svg';

const entityTypeIcons = {
  form_submission: documents,
  mga_period: accounting,
  pms_job: pmsJobs,
  regular_job: jobs,
  purchasing_requisition: purchasing
};

export const getProperties = item => {
  if (!item) return null;

  const {
    entity_id,
    report,
    entity_type,
    form_submission,
    job,
    requisition,
    mga_period,
    entity_uuid
  } = item;

  switch (entity_type) {
    case 'report':
      return {
        path: `${reportURL[_get(report, 'template.type')] || paths.captain_reports}/${entity_id}`,
        icon: captainReportIcon[_get(report, 'template.type')],
        description: `${_get(report, 'vessel.name') ? `${report.vessel.name} - ` : ''}${_get(
          report,
          'template.name'
        )} - ${moment(_get(report, 'timestamp')).format('DD/MM/YYYY HH:mm')}`
      };

    case 'form_submission':
      return {
        path: `${paths.forms}/${entity_id}`,
        icon: entityTypeIcons[entity_type],
        description: `${
          _get(form_submission, 'vessel.name') ? `${form_submission.vessel.name} - ` : ''
        }${_get(form_submission, 'form.form.name')} - ${moment(
          _get(form_submission, 'submission_date')
        ).format('DD/MM/YYYY')}`
      };

    case 'job':
      const jobType = _get(job, 'type');
      const isPms = jobType === 'pms';

      return {
        path: `${isPms ? paths.pms_jobs : paths.regular_jobs}/${entity_id}`,
        icon: entityTypeIcons[isPms ? 'pms_job' : 'regular_job'],
        description: `${_get(job, 'vessel.name') ? `${job.vessel.name} - ` : ''}${_get(
          job,
          'title',
          ''
        )} ${
          isPms ? `- ${_get(job, 'maintenance_details.vessel_system_assignment.description')}` : ''
        }`
      };

    case 'purchasing_requisition':
      return {
        path: `${paths.purchasing_requisitions}/${item?.entity_id}`,
        icon: entityTypeIcons[entity_type],
        description: `${
          _get(requisition, 'vessel.name') ? `${requisition.vessel.name} - ` : ''
        }${_get(requisition, 'code')} - ${_get(requisition, 'description')}`
      };

    case 'mga_period':
      return {
        path: `${paths.mga}/overview/${mga_period.vessel_id}/${entity_uuid}`,
        icon: entityTypeIcons[entity_type],
        description: `${_get(mga_period, 'vessel.name')} - Period: ${moment(
          _get(mga_period, 'started_at')
        ).format('DD/MM/YYYY')} - ${moment(_get(mga_period, 'ended_at')).format('DD/MM/YYYY')}`
      };

    default:
      return null;
  }
};
