import React, { useEffect, useCallback } from 'react';
import WidgetSettings from 'views/dashboard/components/WidgetSettings';
import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, refetchAsyncOptions } from 'utils/helpers';
import { getInitialSingleValue } from 'common/utils/lists';
import { selectWidgetPreferences } from 'store/dashboards/selectors';
import { useSelector } from 'react-redux';
import widgetsDetails from 'views/dashboard/widgets/widgets-details';

import { Row, Col } from 'reactstrap';
import { useForm, useFormState } from 'utils/hooks';
import { optionField } from 'common/utils/form/fieldTypes';
import CallToActionSelector from 'common/components/selectors/CallToActionSelector';

import { selectListOptionsFromStore } from 'store/lists/selectors';
import { fetchListOptions } from 'store/lists/actions';
import { useDispatch } from 'react-redux';

import accounting from 'common/assets/svg/accounting/calculate.svg';
import purchasing from 'common/assets/svg/common/purchasing.svg';
import ticketing from 'assets/svg/sidebar/ticketing.svg';
import PropTypes from 'prop-types';

const customIconEntities = label => {
  if (label === 'purchasing') return purchasing;
  if (label === 'accounts') return accounting;
  if (label === 'ticketing') return ticketing;
};

const BudgetPreferences = ({ id, onSave, label, onClose, isSubmitting }) => {
  const { formState, collectValues, loadValues } = useForm(
    { filter: optionField({ required: true }), category: optionField({ required: true }) },
    {}
  );
  const { fields, selectField } = useFormState(formState);

  const dispatch = useDispatch();

  const budgetTypes = useSelector(state => selectListOptionsFromStore(state, 'budget-types'));
  const preferences = useSelector(state => selectWidgetPreferences(state, id));

  const tableLabels = widgetsDetails?.[label].preferencesComponentProps.tableLabels;
  const allOptionsText = widgetsDetails?.[label].preferencesComponentProps.allOptionsText;

  const handleSubmitForm = async ({ custom_title }) => {
    const values = collectValues();
    if (!values) return;

    const params = {
      custom_title,
      saved_table_id: values.filter.id || null,
      category: values.category || null
    };

    onSave(params);
  };

  const getInitialValues = async () => {
    const opts = await refetchAsyncOptions('saved-tables', {
      table_labels: tableLabels
    });

    if (opts) {
      return [{ id: 0, name: allOptionsText }, ...opts];
    } else return [{ id: 0, name: allOptionsText }];
  };

  useEffect(() => {
    dispatch(fetchListOptions('budget-types'));
  }, [dispatch]);

  const init = useCallback(async () => {
    const res = await getInitialSingleValue(`/tables/${preferences?.saved_table_id}`);
    const params = {
      filter: preferences?.saved_table_id
        ? { id: res?.id, name: res?.name }
        : preferences?.saved_table_id !== undefined
        ? { id: 0, name: allOptionsText }
        : null,
      category: preferences?.category
    };
    loadValues(params);
  }, [preferences?.saved_table_id, preferences?.category, allOptionsText, loadValues]);

  useEffect(() => {
    init();
  }, [id, init]);

  return (
    <WidgetSettings id={id} isSubmitting={isSubmitting} onClose={onClose} onSave={handleSubmitForm}>
      <Row className="mt-3">
        <Col xs={12}>
          <div className="form-label">BUDGET CATEGORY</div>
          <CallToActionSelector
            options={budgetTypes}
            placeholder="Select call to action"
            size="sm"
            customIconEntities={customIconEntities}
            getOptionValue={v => v.label}
            getOptionLabel={v => v.name}
            onChange={selectField('category')}
            {...fields.category}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Select
            isAsync
            loadOptions={search =>
              getAsyncOptions(search, 'saved-tables', { table_labels: tableLabels })
            }
            label="Choose filter"
            defaultOptions={getInitialValues}
            placeholder="Select filter"
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            onChange={selectField('filter')}
            isClearable={false}
            disabled={!fields.category?.value}
            {...fields.filter}
          />
        </Col>
      </Row>
    </WidgetSettings>
  );
};

BudgetPreferences.propTypes = {
  id: PropTypes.number,
  onSave: PropTypes.func,
  label: PropTypes.string,
  onClose: PropTypes.func,
  isSubmitting: PropTypes.bool
};

export default BudgetPreferences;
