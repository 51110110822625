import { bool } from 'prop-types';

import Drawer from 'common/components/purchasing/requisition/communications/drawer';
import ToggleButton from 'common/components/purchasing/requisition/communications/ToggleButton';

const CommunicationsBox = ({ hideEmailForm = false }) => {
  return (
    <>
      <ToggleButton />
      <Drawer hideEmailForm={hideEmailForm} />
    </>
  );
};

export default CommunicationsBox;

CommunicationsBox.propTypes = {
  hideEmailForm: bool
};
