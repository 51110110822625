import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import rearrange from '../../assets/svg/rearrange.svg';

const DragHandler = SortableHandle(() => (
  <div className="toolbar-tool--header-actions-rearrange">
    <img src={rearrange} width={12} height={12} alt="rearrange" />
  </div>
));

export default DragHandler;
