import { useDispatch, useSelector } from 'react-redux';

import {
  selectActiveRequisitionFindings,
  selectCollapsedFindingsIsCollapsed,
  selectCollapsedFindingsMode,
  selectIsSummaryViewActive
} from 'common/components/purchasing/requisition/store/selectors';

import { setCollapsedFindings } from 'common/components/purchasing/requisition/store/actions';
import FindingsExpandButton from 'common/components/buttons/FindingsExpandButton';
import { useEffect } from 'react';

const ShowLinkedFindingsButton = () => {
  const linkedFindings = useSelector(selectActiveRequisitionFindings);
  const isSummary = useSelector(selectIsSummaryViewActive);
  const dispatch = useDispatch();
  const isCollapsed = useSelector(selectCollapsedFindingsIsCollapsed);
  const collapsedMode = useSelector(selectCollapsedFindingsMode);

  const mode = isSummary ? 'summary' : 'requisition';

  useEffect(() => {
    dispatch(setCollapsedFindings({ mode: mode, collapsed: true }));
  }, [isSummary, dispatch, mode]);

  if (!linkedFindings?.length) return null;

  const onClick = () => {
    dispatch(setCollapsedFindings({ mode: mode, collapsed: !isCollapsed }));
  };

  return (
    <FindingsExpandButton
      isCollapsed={isCollapsed && collapsedMode === mode}
      onClick={onClick}
      svgStyle={isSummary ? null : { width: 12, height: 12 }}
      className={isSummary ? 'cpy-2 cms-4' : 'py-0 cms-4'}
      label={isSummary ? null : 'Show Linked Findings:'}
      findingsCount={linkedFindings?.length}
    />
  );
};

export default ShowLinkedFindingsButton;
