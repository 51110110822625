import TYPES from './types';
import { get, put, post, deleteRequest, download } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import _get from 'lodash/get';
import { selectTripFieldData } from './selectors';

const getTripBaseUrl = (tripType, tripCaseID) =>
  tripType === 'list' ? '/trips' : `/${tripType}-ticketing-cases/${tripCaseID}/trips`;

const getTripListParams = params => (dispatch, getState) => {
  const state = getState();

  const vessel = selectTripFieldData(state, 'vessel');
  const port = selectTripFieldData(state, 'port');
  const started_at = selectTripFieldData(state, 'started_at');

  if (!port || !started_at || params?.parties?.some(p => !p.id || !p.ticketing_type_id)) {
    dispatch(setTripHasValidationErrors(true));

    return null;
  } else {
    dispatch(setTripHasValidationErrors(false));

    return {
      ...params,
      vessel_id: vessel?.id,
      port_id: port?.id,
      started_at
    };
  }
};

export const getTrip = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.GET_TRIP.START, payload: { params } });

  return get(`/trips/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.GET_TRIP.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_TRIP.ERROR, payload: { response: error, params } });

      throw error;
    });
};

export const createTrip = params => dispatch => {
  const { tripType, tripCaseID, ...rest } = params;

  let requestParams = null;

  if (tripType === 'list') {
    requestParams = dispatch(getTripListParams(rest));
  } else {
    requestParams = { ...rest };
  }

  if (!requestParams) throw new Error('Validation error');

  dispatch({ type: TYPES.CREATE_TRIP.START, payload: { params: requestParams } });

  return post(getTripBaseUrl(tripType, tripCaseID), requestParams)
    .then(response => {
      dispatch({
        type: TYPES.CREATE_TRIP.SUCCESS,
        payload: { data: response.data, params: requestParams, tripType, tripCaseID }
      });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      dispatch(getTrip({ id: response.data.id }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.CREATE_TRIP.ERROR,
        payload: { response: error, params: requestParams }
      });

      throw error;
    });
};

export const editTrip = params => dispatch => {
  const { tripID, ...rest } = params;
  dispatch({ type: TYPES.EDIT_TRIP.START, payload: { params } });

  return put(`/trips/${tripID}`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.EDIT_TRIP.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.EDIT_TRIP.ERROR, payload: { response: error, params } });

      throw error;
    });
};

export const deleteTrip = params => dispatch => {
  const { id } = params;
  dispatch({ type: TYPES.DELETE_TRIP.START, payload: { params } });

  return deleteRequest(`/trips/${id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_TRIP.SUCCESS, payload: params });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.DELETE_TRIP.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const addNewTicketToTrip = params => dispatch => {
  const { tripID, ...rest } = params;
  dispatch({ type: TYPES.ADD_NEW_TICKET_TO_TRIP.START, payload: { params } });

  return post(`/trips/${tripID}/tickets`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.ADD_NEW_TICKET_TO_TRIP.SUCCESS, payload: response.data });
      dispatch(getTrip({ id: tripID }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.ADD_NEW_TICKET_TO_TRIP.ERROR, payload: { response: error, params } });

      throw error;
    });
};

export const updateTripTicket = params => dispatch => {
  const { id, tripID, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_TRIP_TICKET.START, payload: { params } });

  return put(`/ticket-offers/${id}`, { ...rest })
    .then(response => {
      dispatch({
        type: TYPES.UPDATE_TRIP_TICKET.SUCCESS,
        payload: response.data
      });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));
      dispatch(getTrip({ id: tripID }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.UPDATE_TRIP_TICKET.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const acceptTripTicket = params => dispatch => {
  const { id, tripID, type, ...rest } = params;
  dispatch({ type: TYPES.ACCEPT_TRIP_TICKET.START, payload: { params } });

  return put(`/ticket-offers/${id}`, { ...rest })
    .then(response => {
      dispatch({
        type: TYPES.ACCEPT_TRIP_TICKET.SUCCESS,
        payload: { accepted: rest.accepted, id }
      });
      dispatch(getTrip({ id: tripID }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.ACCEPT_TRIP_TICKET.ERROR, payload: { response: error, params } });

      throw error;
    });
};

export const deleteTripTicket = params => dispatch => {
  const { id, tripID } = params;
  dispatch({ type: TYPES.DELETE_TRIP_TICKET.START, payload: { params } });

  return deleteRequest(`/ticket-offers/${id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_TRIP_TICKET.SUCCESS, payload: params });
      dispatch(getTrip({ id: tripID }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.DELETE_TRIP_TICKET.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const addTripParticipant = params => dispatch => {
  const { tripID, ...rest } = params;
  dispatch({ type: TYPES.ADD_TRIP_PARTICIPANT.START, payload: { params } });

  return post(`/trips/${tripID}/parties`, rest)
    .then(response => {
      dispatch({ type: TYPES.ADD_TRIP_PARTICIPANT.SUCCESS, payload: params });
      dispatch(getTrip({ id: tripID }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.ADD_TRIP_PARTICIPANT.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const deleteTripParticipant = params => dispatch => {
  const { id, tripID } = params;
  dispatch({ type: TYPES.DELETE_TRIP_PARTICIPANT.START, payload: { params } });

  return deleteRequest(`/trips/${tripID}/parties/${id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_TRIP_PARTICIPANT.SUCCESS, payload: params });
      dispatch(getTrip({ id: tripID }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.DELETE_TRIP_PARTICIPANT.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const getTicketOfferTotals = params => dispatch => {
  dispatch({ type: TYPES.GET_TICKET_OFFER_TOTALS.START, payload: { params } });

  return get(`/ticket-offer-totals`, params)
    .then(response => {
      dispatch({
        type: TYPES.GET_TICKET_OFFER_TOTALS.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_TICKET_OFFER_TOTALS.ERROR, payload: { response: error, params } });

      throw error;
    });
};

export const setTripPreventionModal = params => dispatch => {
  dispatch({ type: TYPES.SET_TRIP_PREVENTION_MODAL, payload: params });
};

/* Ticketing Common */

export const toggleTicketingRowBulkMode =
  ({ ticketingCaseID, enabled }) =>
  dispatch => {
    dispatch({ type: TYPES.TOGGLE_TICKETING_ROW_BULK_MODE, payload: { ticketingCaseID, enabled } });
  };

export const editTicketingCase = params => dispatch => {
  const { tripType, ticketingCaseID, ...rest } = params;

  dispatch({ type: TYPES.EDIT_TICKETING_CASE.START, payload: { params } });

  return put(`/${tripType}-ticketing-cases/${ticketingCaseID}`, rest)
    .then(response => {
      dispatch({
        type: TYPES.EDIT_TICKETING_CASE.SUCCESS,
        payload: { data: response.data, ticketingCaseID }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_TRIP.ERROR, payload: { response: error, params } });

      throw error;
    });
};

export const downloadTicketOfferAttachments = params => dispatch => {
  dispatch({
    type: TYPES.DOWNLOAD_TICKET_OFFER_ATTACHMENTS.START,
    payload: { params }
  });

  return download(`/files/entity`, { id: params.id, type: 'ticket_offer' })
    .then(response => {
      dispatch({
        type: TYPES.DOWNLOAD_TICKET_OFFER_ATTACHMENTS.SUCCESS,
        payload: { data: response.data, params }
      });
      return response;
    })
    .catch(error =>
      dispatch({
        type: TYPES.DOWNLOAD_TICKET_OFFER_ATTACHMENTS.ERROR,
        payload: { error, params }
      })
    );
};

export const getTicketsTotals = params => dispatch => {
  dispatch({ type: TYPES.GET_TICKETS_TOTALS.START, payload: { params } });

  return get(`/budgets/tickets/totals`, params)
    .then(response => {
      dispatch({
        type: TYPES.GET_TICKETS_TOTALS.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_TICKETS_TOTALS.ERROR, payload: { response: error, params } });

      throw error;
    });
};

/* -- */

/* Ticketing Rotations */

export const bulkSelectTicketingRotation =
  ({ ticketingCaseID, rotationID, type }) =>
  dispatch => {
    dispatch({
      type: TYPES.BULK_SELECT_TICKETING_ROTATION,
      payload: { ticketingCaseID, rotationID, type }
    });
  };

export const clearSelectedTicketingRotations =
  ({ ticketingCaseID }) =>
  dispatch => {
    dispatch({ type: TYPES.CLEAR_SELECTED_TICKETING_ROTATIONS, payload: { ticketingCaseID } });
  };

export const addBulkTicketsToSelectedTicketingRotations =
  ({ ticketingCaseID }) =>
  (dispatch, getState) => {
    const { date_of_change, port, vessel } = getState().ticketing.ticketingCase[ticketingCaseID];
    const rotations = getState().ticketing.ticketingRotation;
    const { on_signer, off_signer } =
      getState().ticketing.selectedTicketingRotations[ticketingCaseID];
    const ticketingTypes = getState().lists['ticketing-types']?.options || [];

    const parties = [
      ...Object.keys(on_signer || {}).reduce((acc, rotationID) => {
        // Get selected rows for on_signers
        if (on_signer?.[rotationID])
          acc.push({ ..._get(rotations, `${rotationID}.on_signer`), rotationID });

        return acc;
      }, []),
      ...Object.keys(off_signer || {}).reduce((acc, rotationID) => {
        // Get selected rows for off_signers
        if (off_signer?.[rotationID])
          acc.push({ ..._get(rotations, `${rotationID}.off_signer`), rotationID });

        return acc;
      }, [])
    ].map(({ rotationID, ...person }) => ({
      person,
      type: ticketingTypes.find(t => t.is_for_crew),
      started_at: date_of_change,
      port,
      vessel
    }));

    dispatch(
      openTripDrawer({
        parties,
        type: 'crew',
        ticketingCaseID: ticketingCaseID
      })
    );
  };

export const addSingleTicketsToTicketingRotation =
  ({ ticketingCaseID, tripID, crew }) =>
  (dispatch, getState) => {
    const ticketingTypes = getState().lists['ticketing-types']?.options || [];

    if (tripID) {
      dispatch(
        openTripDrawer({ tripIDs: [tripID], type: 'crew', ticketingCaseID: ticketingCaseID })
      );
    } else {
      const { date_of_change, port, vessel } = getState().ticketing.ticketingCase[ticketingCaseID];

      const parties = [
        {
          person: crew,
          type: ticketingTypes.find(t => t.is_for_crew),
          started_at: date_of_change,
          port,
          vessel
        }
      ];

      dispatch(openTripDrawer({ parties, type: 'crew', ticketingCaseID: ticketingCaseID }));
    }
  };

/* -- */

/* Ticketing Events */
export const bulkSelectTicketingParticipant =
  ({ ticketingCaseID, eventParticipantID }) =>
  dispatch => {
    dispatch({
      type: TYPES.BULK_SELECT_TICKETING_PARTICIPANT,
      payload: { ticketingCaseID, eventParticipantID }
    });
  };

export const clearSelectedTicketingParticipants =
  ({ ticketingCaseID }) =>
  dispatch => {
    dispatch({ type: TYPES.CLEAR_SELECTED_TICKETING_PARTICIPANTS, payload: { ticketingCaseID } });
  };

export const addBulkTicketsToSelectedTicketingParticipants =
  ({ ticketingCaseID }) =>
  (dispatch, getState) => {
    const { date, port, vessel } = getState().ticketing.ticketingCase[ticketingCaseID];
    const eventID = getState().ticketing.ticketingCaseEvents[ticketingCaseID];

    const eventParticipants = getState().ticketing.ticketingEventParticipants[eventID];

    const selectedTicketingParticipants =
      getState().ticketing.selectedTicketingParticipants[ticketingCaseID];
    const ticketingTypes = getState().lists['ticketing-types']?.options || [];

    const parties = eventParticipants
      .filter(eventParticipant => selectedTicketingParticipants[eventParticipant.id])
      .map(eventParticipant => ({
        person: eventParticipant.party,
        type: ticketingTypes.find(t => !t.is_for_crew),
        started_at: date,
        port,
        vessel
      }));

    dispatch(openTripDrawer({ parties, type: 'event', ticketingCaseID: ticketingCaseID }));
  };

export const addSingleTicketsToTicketingParticipant =
  ({ ticketingCaseID, tripID, person }) =>
  (dispatch, getState) => {
    if (tripID) {
      dispatch(
        openTripDrawer({ tripIDs: [tripID], type: 'event', ticketingCaseID: ticketingCaseID })
      );
    } else {
      const { date, port, vessel } = getState().ticketing.ticketingCase[ticketingCaseID];
      const ticketingTypes = getState().lists['ticketing-types']?.options || [];

      const parties = [
        {
          person,
          type: ticketingTypes.find(t => !t.is_for_crew),
          started_at: date,
          port,
          vessel
        }
      ];

      dispatch(openTripDrawer({ parties, type: 'event', ticketingCaseID: ticketingCaseID }));
    }
  };

export const createTripWithoutTicketingCase = () => (dispatch, getState) => {
  const ticketingTypes = getState().lists['ticketing-types']?.options || [];

  const parties = [
    {
      person: null,
      type: ticketingTypes.find(t => !t.is_for_crew),
      started_at: null,
      port: null,
      vessel: null
    }
  ];

  dispatch(updateTripData({ vessel: null, port: null, started_at: null }));
  dispatch(setTripHasValidationErrors(false));
  dispatch(openTripDrawer({ parties, type: 'list' }));
};
/* -- */

/* Trip Drawer */

export const openTripDrawer =
  ({ tripIDs, tripVisibleParties, ticketingCaseID, parties, type, disabled }) =>
  dispatch => {
    dispatch({
      type: TYPES.OPEN_TRIP_DRAWER,
      payload: { tripIDs, tripVisibleParties, ticketingCaseID, parties, type, disabled }
    });
  };

export const closeTripDrawer = () => dispatch => {
  dispatch({ type: TYPES.CLOSE_TRIP_DRAWER });
};

export const updateTripParty =
  ({ personId, index }, data) =>
  dispatch => {
    dispatch({ type: TYPES.UPDATE_TRIP_PARTY, payload: { personId, index, data } });
  };

export const updateTripParties =
  ({ isNew = false, isRemoved = false }, { index, tripID }) =>
  (dispatch, getState) => {
    if (isNew) {
      const ticketingTypes = getState().lists['ticketing-types']?.options || [];
      const party = {
        person: null,
        type: ticketingTypes.find(t => !t.is_for_crew),
        trip_id: tripID
      };

      dispatch({ type: TYPES.UPDATE_TRIP_PARTIES, payload: { isNew, party } });
    } else {
      dispatch({ type: TYPES.UPDATE_TRIP_PARTIES, payload: { isRemoved, index } });
    }
  };

export const updateTripData = data => dispatch => {
  dispatch({ type: TYPES.UPDATE_TRIP_DATA, payload: data });
};

export const collapseTrip = tripID => dispatch => {
  dispatch({ type: TYPES.COLLAPSE_TRIP, payload: { tripID } });
};

export const setTripHasValidationErrors = payload => dispatch => {
  dispatch({ type: TYPES.SET_TRIP_HAS_VALIDATION_ERRORS, payload });
};
/* -- */
