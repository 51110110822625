import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import { selectCrewProfileName } from '@/crew/store/profile/selectors.js';

const Crew = React.lazy(() => retry(() => import('@/views/crew/seamen/index.tsx')));
const CrewProfile = React.lazy(() => retry(() => import('@/views/crew/profile/index.jsx')));
const CrewList = React.lazy(() => retry(() => import('@/views/crew/list/index.jsx')));
const CrewPlanning = React.lazy(() => retry(() => import('@/views/crew/planning/index.jsx')));
const CrewPlanningHistory = React.lazy(() =>
  retry(() => import('@/views/crew/planning/history/index.jsx'))
);
const CrewDocumentManager = React.lazy(() =>
  retry(() => import('@/views/crew/document-manager/index.jsx'))
);
const CrewWatchkeeping = React.lazy(() =>
  retry(() => import('@/views/crew/watchkeeping/index.jsx'))
);
const Ticketing = React.lazy(() => retry(() => import('@/views/ticketing/index.jsx')));
const Evaluations = React.lazy(() => retry(() => import('@/views/crew/evaluations/index.jsx')));
const CrewSeamenContracts = React.lazy(() =>
  retry(() => import('@/views/crew/seamen-contracts/index.jsx'))
);

const crew = [
  {
    path: `${paths.crew}`,
    component: Crew,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.crew.seamen_list.view],
    documentTitle: 'Crew'
  },
  {
    path: `${paths.crew}/:id`,
    component: CrewProfile,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.crew.seaman_profile.view],
    documentTitle: selectCrewProfileName
  },
  {
    path: [
      `${paths.crew}/:id/personal`,
      `${paths.crew}/:id/shifts`,
      `${paths.crew}/:id/skills`,
      `${paths.crew}/:id/medical`,
      `${paths.crew}/:id/payroll`,
      `${paths.crew}/:id/contracts/:view(list)?`,
      `${paths.crew}/:id/evaluation`,
      `${paths.crew}/:id/evaluation/:view(create)`,
      `${paths.crew}/:id/evaluation/:view(draft|view)/:evaluationID`,
      `${paths.crew}/:id/evaluation/compare-evaluations`,
      `${paths.crew}/:id/training`,
      `${paths.crew}/:id/documents`,
      `${paths.crew}/:id/documents/:category(stcw|travelling|medical|flag|non-stcw|company-reqs|other|license)`,
      `${paths.crew}/:id/policies`
    ],
    component: CrewProfile,
    app: true,
    type: 'private',
    exact: true,
    permissions: {
      0: [permissions.office.crew.seaman_profile.personal.view],
      1: [permissions.office.crew.seaman_profile.watchkeeping.view],
      2: [permissions.office.crew.seaman_profile.skills_experience.view],
      3: [permissions.office.crew.seaman_profile.medical.view],
      4: [permissions.office.crew.seaman_profile.payroll.view],
      5: [
        permissions.office.crew.seaman_profile.contracts_detailed.view,
        permissions.office.crew.seaman_profile.contracts_list.view
      ],
      6: [permissions.office.crew.seaman_profile.evaluations.view],
      7: [permissions.office.crew.seaman_profile.evaluations.create],
      8: [permissions.office.crew.seaman_profile.evaluations.create],
      9: [permissions.office.crew.seaman_profile.evaluations.view],
      10: [permissions.office.crew.seaman_profile.training.view],
      11: [permissions.office.crew.seaman_profile.documents.view],
      12: [permissions.office.crew.seaman_profile.documents.view],
      13: [permissions.office.crew.seaman_profile.policies.view]
    },
    documentTitle: selectCrewProfileName
  },
  {
    path: `${paths.crew_planning}`,
    component: CrewPlanning,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.crew.crew_planning.view],
    documentTitle: 'Crew Planning'
  },
  {
    path: `${paths.crew_planning}/history`,
    component: CrewPlanningHistory,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.crew.crew_planning.view],
    documentTitle: 'Crew Planning History'
  },
  {
    path: paths.crew_document_manager,
    component: CrewDocumentManager,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.crew.document_manager.view],
    documentTitle: 'Crew Document Manager'
  },
  {
    path: `${paths.crew_document_manager}/documents`,
    component: CrewDocumentManager,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.crew.document_manager.view],
    documentTitle: 'Crew Document Manager'
  },
  {
    path: `${paths.crew_seamen_contracts}`,
    component: CrewSeamenContracts,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.crew.contracts.view],
    documentTitle: 'Crew Seamen Contracts'
  },
  {
    path: `${paths.crew_list}`,
    component: CrewList,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.crew.crew_list.view],
    documentTitle: 'Crew List'
  },

  {
    path: [`${paths.ticketing}`, `${paths.ticketing}/:type(crew|events)`, `${paths.ticketing}/:id`],
    component: Ticketing,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.crew.ticketing.view],
    documentTitle: 'Ticketing'
  },
  {
    path: `${paths.crew_evaluation_obligations}`,
    component: Evaluations,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.crew.evaluation_obligations.view],
    documentTitle: 'Evaluation Obligations'
  },
  {
    path: `${paths.crew_watchkeeping}`,
    component: CrewWatchkeeping,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.crew.watchkeeping.view],
    documentTitle: 'Crew - Watchkeeping'
  }
];

export default crew;
