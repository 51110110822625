import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import { selectVesselTitle } from 'store/vessels/selectors';

const Vessels = React.lazy(() => retry(() => import('@/views/vessels/index.tsx')));
const Profile = React.lazy(() => retry(() => import('@/views/vessels/profile/index.jsx')));
const VesselsDocumentManager = React.lazy(() =>
  retry(() => import('@/views/vessels/document-manager/index.jsx'))
);

const vessels = [
  {
    path: `${paths.vessels}`,
    component: Vessels,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.vessels.list],
    documentTitle: 'Vessels'
  },
  {
    path: `${paths.vessels}/:id`,
    component: Profile,
    key: 'vessel-profile',
    app: true,
    appClass: 'vessel-profile-wrapper',
    type: 'private',
    exact: true,
    permissions: [permissions.office.vessels.list, permissions.office.vessels.edit],
    documentTitle: selectVesselTitle
  },
  {
    path: `${paths.vessels}/:id/:tab(info|activity|bunkers|crew)`,
    component: Profile,
    key: 'vessel-profile',
    app: true,
    appClass: 'vessel-profile-wrapper',
    type: 'private',
    exact: true,
    permissions: [permissions.office.vessels.list, permissions.office.vessels.edit],
    documentTitle: selectVesselTitle
  },
  {
    path: `${paths.vessels}/:id/:tab(documents)`,
    component: Profile,
    key: 'vessel-profile',
    app: true,
    appClass: 'vessel-profile-wrapper',
    type: 'private',
    exact: true,
    permissions: [
      permissions.office.vessels.documents.view,
      permissions.office.vessels.documents.edit
    ],
    documentTitle: selectVesselTitle
  },
  {
    path: `${paths.vessels}/:id/:tab(info|activity)/:subtab`,
    component: Profile,
    key: 'vessel-profile',
    app: true,
    appClass: 'vessel-profile-wrapper',
    type: 'private',
    exact: true,
    permissions: [permissions.office.vessels.list, permissions.office.vessels.edit],
    documentTitle: selectVesselTitle
  },
  {
    path: `${paths.vessels}/:id/:tab(info|activity)/:subtab/:panel`,
    component: Profile,
    key: 'vessel-profile',
    app: true,
    appClass: 'vessel-profile-wrapper',
    type: 'private',
    exact: true,
    permissions: [permissions.office.vessels.list, permissions.office.vessels.edit],
    documentTitle: selectVesselTitle
  },
  {
    path: [paths.vessels_document_manager, paths.vessels_document_manager_per_document],
    component: VesselsDocumentManager,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.vessels.document_manager.view],
    documentTitle: 'Vessels Document'
  }
];

export default vessels;
