import { useSelector } from 'react-redux';

import WidgetDefaultCard from 'views/dashboard/components/WidgetDefaultCard';
import paths from 'routing/routes/_paths';
import PropTypes from 'prop-types';

import { selectWidgetStateData } from 'store/dashboards/selectors';

const CompaniesCardWidget = ({ id }) => {
  const data = useSelector(state => selectWidgetStateData(state, id));

  return <WidgetDefaultCard id={id} count={data.companies_count} linkTo={paths.companies} />;
};

CompaniesCardWidget.propTypes = {
  id: PropTypes.number
};

export default CompaniesCardWidget;
