import { get, post, put } from '@/api';
import { ReportBuilderType, GroupType, BudgetingReportType } from '@/common/types/report-bulder.ts';

type ReportSetupParams = {
  id: string;
};

export const unArchiveReportSetup = async (params: ReportSetupParams) => {
  const { id } = params;
  if (!id) return null;

  const response = await put<ReportBuilderType>(`/budgeting-reports/${id}/unarchive`);
  return response.data;
};

export const archiveReportSetup = async (params: ReportSetupParams) => {
  const { id } = params;
  if (!id) return null;

  const response = await put<ReportBuilderType>(`/budgeting-reports/${id}/archive`);
  return response.data;
};

interface GroupTypeWithAccountIdsType {
  id?: number;
  name: string;
  parent_id: number | null;
  account_ids: number[];
}

type GetReportSetupParams = {
  id: string;
};

type CreateReportSetupParams = {
  name: string;
  groups: GroupTypeWithAccountIdsType[]; // Make optional
  field_type_ids: number[];
};

type EditReportSetupParams = {
  id: string;
  name: string;
  groups: GroupTypeWithAccountIdsType[]; // Make optional
  field_type_ids: number[];
};

export const getReportSetup = async (params: GetReportSetupParams) => {
  const { id } = params;
  if (!id) return null;

  const response = await get<ReportBuilderType>(`/budgeting-reports/${id}`);
  return response.data;
};

export const createReportSetup = async (params: CreateReportSetupParams) => {
  const response = await post<ReportBuilderType>(`/budgeting-reports`, params);
  return response.data;
};

export const editReportSetup = async (params: EditReportSetupParams) => {
  const { id, ...rest } = params;

  const response = await put(`/budgeting-reports/${id}`, rest);
  return response.data;
};

export const getBudgetingReport = async (params: GetReportSetupParams) => {
  const { id, ...rest } = params;
  if (!id) return null;

  const response = await get<BudgetingReportType>(`/budgeting-reports/${id}/report`, rest);
  return response.data;
};
