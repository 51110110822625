import { createSelector } from 'reselect';
import _pickBy from 'lodash/pickBy';

const selectTicketing = state => state.ticketing;
const getKey = (_, key) => key;
const getSecondaryKey = (_, __, key) => key;

export const selectIsTripEditting = createSelector(
  selectTicketing,
  ticketing => ticketing.tripIsEditting
);

export const selectTripPreventionModal = createSelector(
  selectTicketing,
  ticketing => ticketing.preventionModal
);
//

/* Ticketing Common */

export const selectTicketingCases = createSelector(
  selectTicketing,
  ticketing => ticketing.ticketingCases
);

const selectAllTicketingCases = createSelector(
  selectTicketing,
  ticketing => ticketing.ticketingCase
);

export const selectTicketingCase = createSelector(
  selectAllTicketingCases,
  getKey,
  (ticketingCase, ticketingCaseID) => ticketingCase[ticketingCaseID]
);

const selectAllTicketingBulkModeEnabledCases = createSelector(
  selectTicketing,
  ticketing => ticketing.bulkModeEnabledTicketingCases
);

export const selectIsBulkModeEnabled = createSelector(
  selectAllTicketingBulkModeEnabledCases,
  getKey,
  (bulkModeEnabledTicketingCases, ticketingCaseID) =>
    bulkModeEnabledTicketingCases[ticketingCaseID] || false
);

export const shouldRefetchTicketingCases = createSelector(
  selectTicketing,
  ticketing => ticketing.shouldRefetchTicketingCases
);

/* -- */

/* Ticketing Case Trip Ids */

const selectAllTicketingCaseTripIDs = createSelector(
  selectTicketing,
  ticketing => ticketing.ticketingCaseTripIDs
);

export const selectTicketingCaseTripIDs = createSelector(
  selectAllTicketingCaseTripIDs,
  getKey,
  (ticketingCaseTripIDs, ticketingCaseID) => ticketingCaseTripIDs[ticketingCaseID]
);

export const selectTicketingCaseTripIndex = createSelector(
  selectTicketingCaseTripIDs,
  getSecondaryKey,
  (ticketingCaseTripIDs, tripID) => {
    const index = ticketingCaseTripIDs.findIndex(t => t === tripID);

    return index !== -1 ? index % 10 : null;
  }
);

/* Ticketing Rotations */

const selectAllTicketingCaseRotations = createSelector(
  selectTicketing,
  ticketing => ticketing.ticketingCaseRotations
);

export const selectTicketingCaseRotations = createSelector(
  selectAllTicketingCaseRotations,
  getKey,
  (ticketingCaseRotations, ticketingCaseID) => ticketingCaseRotations[ticketingCaseID]
);

export const selectTicketingCaseRotationsOnSignerLength = createSelector(
  selectTicketingCaseRotations,
  ticketingCaseRotations => ticketingCaseRotations?.on_signer.length
);

export const selectTicketingCaseRotationsOffSignerLength = createSelector(
  selectTicketingCaseRotations,
  ticketingCaseRotations => ticketingCaseRotations?.off_signer.length
);

const selectAllTicketingRotations = createSelector(
  selectTicketing,
  ticketing => ticketing.ticketingRotation
);

export const selectTicketingRotation = createSelector(
  selectAllTicketingRotations,
  getKey,
  (ticketingRotation, rotationID) => ticketingRotation[rotationID]
);

const selectAllSelectedTicketingRotations = createSelector(
  selectTicketing,
  ticketing => ticketing.selectedTicketingRotations
);

export const selectIsTicketingRotationSelected = createSelector(
  selectAllSelectedTicketingRotations,
  getKey,
  (selectedTicketingRotations, { ticketingCaseID, rotationID, type }) =>
    selectedTicketingRotations[ticketingCaseID]?.[type]?.[rotationID] || false
);

export const countSelectedRotations = createSelector(
  selectAllSelectedTicketingRotations,
  getKey,
  (selectedTicketingRotations, ticketingCaseID) =>
    +Object.keys(_pickBy(selectedTicketingRotations[ticketingCaseID]?.on_signer, s => s))?.length +
    +Object.keys(_pickBy(selectedTicketingRotations[ticketingCaseID]?.off_signer, s => s))?.length
);

export const selectTicketingRotationCaseHasPendingOffers = createSelector(
  selectAllTicketingRotations,
  getKey,
  (ticketRotation, ticketingCaseID) =>
    Object.keys(
      _pickBy(
        ticketRotation,
        rotation =>
          rotation.crew_ticketing_case_id === ticketingCaseID &&
          ((rotation.off_signer &&
            !rotation.has_off_signer_accepted_offer &&
            rotation.has_off_signer_offers) ||
            (rotation.on_signer &&
              !rotation.has_on_signer_accepted_offer &&
              rotation.has_on_signer_offers))
      )
    ).length > 0
      ? true
      : false
);

export const selectTicketingRotationCaseHasAcceptedOffers = createSelector(
  selectAllTicketingRotations,
  getKey,
  (ticketRotation, ticketingCaseID) =>
    Object.keys(
      _pickBy(
        ticketRotation,
        rotation =>
          rotation.crew_ticketing_case_id === ticketingCaseID &&
          ((rotation.off_signer &&
            rotation.has_off_signer_accepted_offer &&
            rotation.has_off_signer_offers) ||
            (rotation.on_signer &&
              rotation.has_on_signer_accepted_offer &&
              rotation.has_on_signer_offers))
      )
    ).length ===
    Object.keys(
      _pickBy(ticketRotation, rotation => rotation.crew_ticketing_case_id === ticketingCaseID)
    ).length
      ? true
      : false
);
///
export const countTicketingRotationOnSignerPendingOffers = createSelector(
  selectAllTicketingRotations,
  getKey,
  (ticketRotation, ticketingCaseID) =>
    Object.keys(
      _pickBy(
        ticketRotation,
        rotation =>
          rotation.crew_ticketing_case_id === ticketingCaseID &&
          rotation.on_signer &&
          !rotation.has_on_signer_accepted_offer &&
          rotation.has_on_signer_offers
      )
    ).length
);

export const countTicketingRotationOffSignerPendingOffers = createSelector(
  selectAllTicketingRotations,
  getKey,
  (ticketRotation, ticketingCaseID) =>
    Object.keys(
      _pickBy(
        ticketRotation,
        rotation =>
          rotation.crew_ticketing_case_id === ticketingCaseID &&
          rotation.off_signer &&
          !rotation.has_off_signer_accepted_offer &&
          rotation.has_off_signer_offers
      )
    ).length
);

export const countTicketingRotationOnSignerAcceptedOffers = createSelector(
  selectAllTicketingRotations,
  getKey,
  (ticketRotation, ticketingCaseID) =>
    Object.keys(
      _pickBy(
        ticketRotation,
        rotation =>
          rotation.crew_ticketing_case_id === ticketingCaseID &&
          rotation.on_signer &&
          rotation.has_on_signer_accepted_offer &&
          rotation.has_on_signer_offers
      )
    ).length
);

export const countTicketingRotationOffSignerAcceptedOffers = createSelector(
  selectAllTicketingRotations,
  getKey,
  (ticketRotation, ticketingCaseID) =>
    Object.keys(
      _pickBy(
        ticketRotation,
        rotation =>
          rotation.crew_ticketing_case_id === ticketingCaseID &&
          rotation.off_signer &&
          rotation.has_off_signer_accepted_offer &&
          rotation.has_off_signer_offers
      )
    ).length
);
/* -- */

/* Ticketing Event Participants */

const selectAllTicketingCaseEvents = createSelector(
  selectTicketing,
  ticketing => ticketing.ticketingCaseEvents
);

export const selectTicketingCaseEvents = createSelector(
  selectAllTicketingCaseEvents,
  getKey,
  (ticketingCaseEvents, ticketingCaseID) => ticketingCaseEvents[ticketingCaseID]
);

const selectAllTicketingEvents = createSelector(
  selectTicketing,
  ticketing => ticketing.ticketingEvent
);

export const selectTicketingEvent = createSelector(
  selectAllTicketingEvents,
  getKey,
  (ticketingEvent, eventID) => ticketingEvent[eventID]
);

const selectAllTicketingEventParticipants = createSelector(
  selectTicketing,
  ticketing => ticketing.ticketingEventParticipants
);

export const selectTicketingEventParticipants = createSelector(
  selectAllTicketingEventParticipants,
  getKey,
  (ticketingEventParticipants, eventID) => ticketingEventParticipants[eventID]
);

export const selectTicketingEventParticipantsLength = createSelector(
  selectTicketingEventParticipants,
  ticketingEventParticipants => ticketingEventParticipants?.length
);

const selectAllSelectedTicketingParticipants = createSelector(
  selectTicketing,
  ticketing => ticketing.selectedTicketingParticipants
);

export const selectIsTicketingParticipantSelected = createSelector(
  selectAllSelectedTicketingParticipants,
  getKey,
  (selectedTicketingParticipants, { ticketingCaseID, eventParticipantID }) =>
    selectedTicketingParticipants[ticketingCaseID]?.[eventParticipantID] || false
);

export const countSelectedParticipants = createSelector(
  selectAllSelectedTicketingParticipants,
  getKey,
  (ticketingEventParticipants, ticketingCaseID) =>
    +Object.keys(_pickBy(ticketingEventParticipants[ticketingCaseID], s => s))?.length
);

export const selectTicketingEventParticipantsHasPendingOffers = createSelector(
  selectTicketingEventParticipants,
  getKey,
  (eventParticipants, eventID) =>
    Object.keys(
      _pickBy(
        eventParticipants,
        event =>
          event.event_id === eventID &&
          event?.ticketing?.trip_id &&
          !event?.ticketing?.accepted_ticket
      )
    ).length > 0
      ? true
      : false
);

export const selectTicketingEventParticipantsHasAcceptedOffers = createSelector(
  selectTicketingEventParticipants,
  getKey,
  (eventParticipants, eventID) =>
    Object.keys(
      _pickBy(
        eventParticipants,
        event =>
          event.event_id === eventID &&
          event?.ticketing?.trip_id &&
          event?.ticketing?.accepted_ticket
      )
    ).length === Object.keys(_pickBy(eventParticipants, event => event.event_id === eventID)).length
      ? true
      : false
);

export const countTicketingEventPendingOffers = createSelector(
  selectTicketingEventParticipants,
  getKey,
  (eventParticipants, eventID) =>
    Object.keys(
      _pickBy(
        eventParticipants,
        eventParticipant =>
          eventParticipant.event_id === eventID &&
          eventParticipant?.trip_id &&
          !eventParticipant?.accepted_tickets?.length
      )
    ).length
);

export const countTicketingEventAcceptedOffers = createSelector(
  selectTicketingEventParticipants,
  getKey,
  (eventParticipants, eventID) =>
    Object.keys(
      _pickBy(
        eventParticipants,
        eventParticipant =>
          eventParticipant.event_id === eventID &&
          eventParticipant?.trip_id &&
          eventParticipant?.accepted_tickets?.length
      )
    ).length
);

/* -- */

/* Trip Drawer */

export const isTripDrawerOpened = createSelector(
  selectTicketing,
  ticketing => ticketing.tripDrawerIsOpen
);

export const selectTripIDs = createSelector(selectTicketing, ticketing => ticketing.tripIDs);

export const selectTripLogDetails = createSelector(
  selectTicketing,
  ticketing => ticketing.logDetails
);

export const selectTripCaseID = createSelector(selectTicketing, ticketing => ticketing.tripCaseID);

export const selectTripType = createSelector(selectTicketing, ticketing => ticketing.tripType);

export const selectTripDisabled = createSelector(
  selectTicketing,
  ticketing => ticketing.tripDisabled
);

export const selectTripParties = createSelector(selectTicketing, getKey, (ticketing, tripID) =>
  ticketing.tripParties.filter(p => (tripID ? p.trip_id === tripID : p))
);

export const selectTripVisibleParties = createSelector(
  selectTicketing,
  getKey,
  (ticketing, tripID) =>
    ticketing.tripVisibleParties.filter(p => (tripID ? p.trip_id === tripID : p))
);

export const selectCollpasedTrips = createSelector(
  selectTicketing,
  getKey,
  ticketing => ticketing.tripIsCollapsed
);

export const selectIsTripCollpased = createSelector(
  selectCollpasedTrips,
  getKey,
  (tripIsCollapsed, tripID) => (tripIsCollapsed[tripID] ? true : false)
);

export const selectTripOffers = createSelector(selectTicketing, getKey, (ticketing, tripID) =>
  ticketing.tripOffers.filter(p => (tripID ? p.trip_id === tripID : p))
);

export const selectTripAcceptedOffer = createSelector(selectTripOffers, tripOffers =>
  tripOffers?.find(o => o.accepted)
);

export const selectTripHasValidationErrors = state =>
  selectTicketing(state)?.tripHasValidationErrors;

const selectTripData = state => selectTicketing(state)?.tripData;

export const selectTripFieldData = (state, key) => selectTripData(state)?.[key];

/* -- */

export const selectTicketOfferTotals = createSelector(
  selectTicketing,
  ticketing => ticketing.ticketOfferTotals
);

export const selectTicketsTotals = state => selectTicketing(state).ticketsTotals;
