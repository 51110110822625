import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import { selectPersonTitle } from '@/store/persons/selectors.js';

const Persons = React.lazy(() => retry(() => import('@/views/persons/index.jsx')));
const PersonProfile = React.lazy(() => retry(() => import('@/views/persons/profile/index.jsx')));

const persons = [
  {
    path: `${paths.persons}`,
    component: Persons,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.contacts.persons.view],
    documentTitle: 'Person'
  },
  {
    path: `${paths.persons}/create`,
    component: PersonProfile,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.contacts.persons.create],
    documentTitle: 'Create Person'
  },
  {
    path: `${paths.persons}/:id`,
    component: PersonProfile,
    app: true,
    type: 'private',
    exact: true,
    permissions: null, // Logged in users should be able to see their profile
    documentTitle: selectPersonTitle
  },
  {
    path: `${paths.persons}/:id/notification-preferences`,
    component: PersonProfile,
    app: true,
    type: 'private',
    exact: true,
    permissions: null, // Logged in users should be able to see their profile
    documentTitle: selectPersonTitle
  }
];

export default persons;
