import React from 'react';
import CtaBox from 'components/general/CtaBox';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useActions } from 'utils/hooks';
import {
  selectActiveDashboardHasWidgets,
  selectIsLoadingActiveDashboard
} from 'store/dashboards/selectors';

import widgetsColored from 'common/assets/svg/common/widgets-colored.svg';
import widgets from 'common/assets/svg/common/widgets.svg';
import SvgRender from 'common/components/general/SvgRender';

import * as dashboardsActions from 'store/dashboards/actions';

const NoWidgets = () => {
  const hasWidgets = useSelector(selectActiveDashboardHasWidgets);
  const isLoadingActiveDashboard = useSelector(selectIsLoadingActiveDashboard);

  const [setDashboardWidgetsForm] = useActions([dashboardsActions.setDashboardWidgetsForm]);

  const onOpen = useCallback(() => setDashboardWidgetsForm({ isOpen: true }), []);

  if (isLoadingActiveDashboard || (!isLoadingActiveDashboard && hasWidgets)) return null;

  return (
    <div className="flex-fill d-flex align-items-center justify-content-center">
      <CtaBox
        firstLine={'Getting Started'}
        secondLine={'You haven’t added any widgets yet.'}
        thirdLine="Click on the button below & choose the widgets that suit you.
        Organize your Dashboard as you wish with the reorder tool."
        cta={
          <>
            <SvgRender style={{ width: 12, height: 12 }} src={widgets} className="me-1" />
            Widgets
          </>
        }
        icon={widgetsColored}
        className="position-relative mb-5"
        onClick={onOpen}
      />
    </div>
  );
};

export default NoWidgets;
