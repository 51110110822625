import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { downloadAllFilesOfEntity } from '@/api/files/api';
import { selectRequisitionEmails } from 'common/components/purchasing/requisition/store/selectors';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import { getPurchasingRequisitionEmailAttachments } from 'common/components/purchasing/requisition/store/actions';
import { getPurchasingRequisitionEmailTempUrl, SentEmailTempUrl } from '@/api/purchasing/api.ts';
import email from 'common/assets/svg/socials/email.svg';
import SvgRender from 'common/components/general/SvgRender';
import goToPage from 'common/assets/svg/actions/go-to-page.svg';
import { RequisitionEmail } from '@/common/types/purchasing.ts';
import { displayDate } from '@/ts-common/utils/dates.ts';

const SentEmails = () => {
  const requisitionEmails = useAppSelector(selectRequisitionEmails);
  const dispatch = useAppDispatch();

  const requestAttachments = async (emailId: string | number | null) => {
    try {
      if (!emailId) return;

      const res = await dispatch(getPurchasingRequisitionEmailAttachments({ email_id: emailId }));
      return res;
    } catch (error) {
      console.log(error);
      return;
    }
  };

  const openEmailInANewTab = async (emailId: string) => {
    if (!emailId) return;

    try {
      const res = await getPurchasingRequisitionEmailTempUrl({ email_id: emailId });

      const payload = res as SentEmailTempUrl;

      if (!payload.url) return;

      window.open(payload.url, '_blank');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {requisitionEmails?.length ? (
        <div className="h-100p d-flex flex-column">
          <div className="d-flex align-items-center text-primary fs-12">
            <SvgRender className="me-1" src={email} style={{ width: 12, height: 12 }} />
            <span>
              Sent Emails: <strong>{requisitionEmails?.length}</strong>
            </span>
          </div>
          <div className="flex-1 overflow-y">
            {requisitionEmails?.map((requisitionEmail: RequisitionEmail) => (
              <div key={requisitionEmail?.id} className="mt-1 bg-shades-1 cpy-6 ps-1">
                <div className="fs-12 text-moody-blue">
                  {requisitionEmail?.status?.label === 'dlv'
                    ? 'PO'
                    : requisitionEmail?.status?.name}{' '}
                  | {displayDate(requisitionEmail?.created_at, {}, 'dd MMM yyyy')}
                </div>
                <div className="text-primary">
                  {requisitionEmail?.email?.subject}
                  {requisitionEmail?.email?.external_ids?.length ? (
                    <SvgRender
                      className="text-primary ms-1 cursor-pointer"
                      onClick={() => openEmailInANewTab(requisitionEmail?.id)}
                      src={goToPage}
                      style={{ width: 12, height: 12 }}
                    />
                  ) : null}
                </div>
                <div className="fs-12 text-violet">
                  {requisitionEmail?.requisition_supplier?.supplier?.full_name}
                </div>
                <DownloadAttachment
                  data={{ attachments_count: requisitionEmail?.email.attachments_count || 0 }}
                  requestAttachments={() => requestAttachments(requisitionEmail?.id)}
                  hasAttachments={requisitionEmail?.email?.attachments_count > 0}
                  downloadFiles={() =>
                    downloadAllFilesOfEntity({
                      id: requisitionEmail?.id,
                      type: 'email'
                    })
                  }
                  canAddAttachments={false}
                  popupPlacement={'right'}
                />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SentEmails;
