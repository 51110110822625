export const selectCrewVesselMatrices = state => state.vesselCrewMatrices;

export const selectDefaultMatrix = state => selectCrewVesselMatrices(state)?.defaultMatrix;
export const selectDefaultMatrixIsSatisfied = state => selectDefaultMatrix(state)?.is_satisfied;
export const selectDefaultMatrixId = state => selectDefaultMatrix(state)?.id;

export const selectMatrices = state => selectCrewVesselMatrices(state).matrices;
export const selectSelectedMatrix = state => selectCrewVesselMatrices(state)?.selectedMatrix;
export const selectSelectedMatrixId = state => selectSelectedMatrix(state)?.id;
export const selectSelectedMatrixRemarks = state => selectSelectedMatrix(state)?.remarks;

export const selectIsLoadingCrewMatrix = state => selectCrewVesselMatrices(state).isLoading;
export const selectIsLoadingSelectedCrewMatrix = state =>
  selectCrewVesselMatrices(state).isLoadingSelectedMatrix;

export const selectSelectedMatrixRuleIds = state =>
  selectCrewVesselMatrices(state).selectedMatrixRuleIds;
export const selectSelectedMatrixRules = state =>
  selectCrewVesselMatrices(state).selectedMatrixRules;

export const selectSelectedMatrixRuleTypeIds = state =>
  selectCrewVesselMatrices(state).selectedRuleTypeIds;

export const selectSelectedRuleTypeDetails = (state, ruleTypeId) =>
  selectCrewVesselMatrices(state).selectedRuleTypeDetails?.[ruleTypeId];

export const selectSelectedRuleTypeAssignedRules = (state, ruleTypeId) =>
  selectCrewVesselMatrices(state).selectedRuleTypesAsignedRules?.[ruleTypeId];

export const selectSelectedMatrixRuleRuleById = (state, ruleId) =>
  selectCrewVesselMatrices(state).selectedMatrixRules?.[ruleId];
