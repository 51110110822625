import { LOCATION_CHANGE } from 'connected-react-router';
import {
  getEntityPermissions,
  setEntityGroupPermissions,
  unsetEntityGroupPermissions,
  updateEntityGroupConfigurationDetails
} from './actions';
import { createSlice } from '@reduxjs/toolkit';
import {
  getRootItems,
  getRootItemsIDs,
  getSubItems,
  getTreeOfSearchedItems,
  normalizeTreeStructure
} from 'common/components/tree-layout/utils/helpers';
import _sortBy from 'lodash/sortBy';
import { ENTITY_BASE_PATH } from '@/components/permissions/policies/constants';

const INITIAL_STATE = {
  permissions: {
    rootGroupsIDs: [],
    rootGroups: [],
    subGroups: {},
    groups: {}, // Group data
    expandedGroups: [],
    configuringGroups: [],

    treeSearch: '',
    groupsInSearch: {}
  },
  isLoading: false,
  activeEntityId: null,
  activeEntityModel: null
};

const slice = createSlice({
  name: 'policies',
  initialState: { ...INITIAL_STATE },
  reducers: {
    expandAllGroups: (state, { payload }) => {
      state.permissions.expandedGroups = payload;

      return state;
    },
    expandGroup: (state, { payload }) => {
      if (state.permissions.expandedGroups.includes(payload)) {
        state.permissions.expandedGroups = state.permissions.expandedGroups.filter(
          id => id !== payload
        );
      } else {
        state.permissions.expandedGroups = [...state.permissions.expandedGroups, payload];
      }

      return state;
    },
    configureGroup: (state, { payload }) => {
      if (state.permissions.configuringGroups.includes(payload)) {
        state.permissions.configuringGroups = state.permissions.configuringGroups.filter(
          id => id !== payload
        );
      } else {
        state.permissions.configuringGroups = [...state.permissions.configuringGroups, payload];
      }

      return state;
    },
    resetGroupFromDetails: (state, { payload }) => {
      state.permissions.groups[payload].form_details = state.permissions.groups[payload].details
        ? { ...state.permissions.groups[payload].details }
        : {};

      return state;
    },
    setGroupFromDetailValue: (state, { payload }) => {
      const { id, type, value } = payload;

      state.permissions.groups[id].form_details[type] = value;

      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getEntityPermissions.pending, (state, { meta }) => {
        state.isLoading = true;
        state.permissions.expandedGroups = [];
        state.permissions.configuringGroups = [];

        if (meta.arg?.id && meta.arg?.id !== state.activeEntityId) {
          state.permissions.subGroups = {};
          state.permissions.rootGroups = [];
          state.permissions.rootGroupsIDs = [];
          state.permissions.groups = {};
        }

        if (meta.arg?.id) {
          state.activeEntityId = meta.arg.id;
          state.activeEntityModel = meta.arg.model;
        }

        return state;
      })
      .addCase(getEntityPermissions.fulfilled, (state, { payload, meta }) => {
        const data = payload.map(({ id, label, parent_label, ...p }) => ({
          ...p,
          id: label,
          parent_id: parent_label,
          form_details: p.details ? { ...p.details } : {} // Configuration form
        }));
        const sortedData = _sortBy(data, 'name');

        if (meta?.arg?.search) {
          const groupsInSearch = getTreeOfSearchedItems(
            state.permissions.groups,
            state.permissions.subGroups,
            sortedData
          );

          state.permissions.treeSearch = meta?.arg?.search;
          state.permissions.groupsInSearch = groupsInSearch;
          state.permissions.expandedGroups = Object.keys(groupsInSearch)
            .filter(key => state.permissions.subGroups[key])
            .map(key => key);
        } else {
          state.permissions.treeSearch = '';
          state.permissions.groupsInSearch = {};

          state.permissions.subGroups = getSubItems(sortedData);
          state.permissions.rootGroups = getRootItems(sortedData);
          state.permissions.rootGroupsIDs = getRootItemsIDs(sortedData);
          state.permissions.groups = normalizeTreeStructure(sortedData);
        }

        state.isLoading = false;

        return state;
      })
      .addCase(getEntityPermissions.rejected, state => {
        state.isLoading = false;

        return state;
      })
      .addCase(setEntityGroupPermissions.fulfilled, (state, { payload }) => {
        if (payload.permissions) {
          Object.keys(payload.permissions).forEach(key => {
            state.permissions.groups[key].enabled = true;
          });
        }

        return state;
      })
      .addCase(unsetEntityGroupPermissions.fulfilled, (state, { payload }) => {
        if (payload.labels) {
          payload.labels.forEach(key => {
            state.permissions.groups[key].enabled = false;
            state.permissions.groups[key].details = null;
            state.permissions.groups[key].form_details = {};
          });
        }

        return state;
      })
      .addCase(updateEntityGroupConfigurationDetails.fulfilled, (state, { payload }) => {
        if (payload) {
          state.permissions.groups[payload].details = state.permissions.groups[payload].form_details
            ? { ...state.permissions.groups[payload].form_details }
            : null;
        }

        return state;
      })
      .addCase(LOCATION_CHANGE, (state, { payload }) => {
        const keys = Object.keys(ENTITY_BASE_PATH);
        const isFromEntity = keys.some(key =>
          payload.location.pathname.includes(ENTITY_BASE_PATH[key])
        );

        if (
          !payload.location.pathname.endsWith('policies') &&
          !payload.location.pathname.includes('onboard') &&
          !payload.location.pathname.includes('core') &&
          !payload.location.pathname.includes('settings')
        ) {
          return { ...INITIAL_STATE };
        }

        return {
          ...state,
          activeEntityId: isFromEntity ? state.activeEntityId : null,
          activeEntityModel: isFromEntity ? state.activeEntityModel : null
        };
      });
  }
});

export const {
  expandAllGroups,
  expandGroup,
  configureGroup,
  setGroupFromDetailValue,
  resetGroupFromDetails
} = slice.actions;

export default slice.reducer;
