import moment from 'moment';
import { getTextColorByDaysLeft } from './helpers';
import { useSelector } from 'react-redux';
import { selectWidgetState } from 'store/dashboards/selectors';

import { strToNumber } from 'common/utils/numbers';

import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import paths from 'routing/routes/_paths';

const RunningHoursReports = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));

  return (
    <div>
      <WidgetTitle id={id} />
      <WidgetDefaultTable
        id={id}
        columns={[
          {
            header: 'vessel',
            key: 'vessel',
            type: 'string',
            headerClassName: 'text-violet',
            width: 7
          },
          {
            header: 'last report',
            key: 'last_report',
            type: 'date',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'report due date',
            key: 'due_date',
            type: 'date',
            headerClassName: 'text-violet'
          },
          {
            header: 'days left',
            key: 'days_left',
            type: 'date',
            headerClassName: 'text-start'
          },
          {
            header: 'Actions',
            key: 'actions',
            field: 'actions'
          }
        ]}
        rows={{
          vessel: data => {
            return data.vessel.name;
          },
          last_report: data => moment(data.last_report, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          due_date: data => moment(data.due_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),

          days_left: data => (
            <div className={`${getTextColorByDaysLeft(strToNumber(data?.days_left))}`}>
              {strToNumber(data?.days_left)}
            </div>
          ),
          actions: data => ({
            options: [
              {
                label: 'View',
                icon: eyeSvg,
                onClick: () =>
                  window.open(`${paths.captain_reports}/system-running-hours/${data.id}`)
              }
            ]
          })
        }}
        label={label}
        state={state}
      />
    </div>
  );
};

export default RunningHoursReports;
