import React, { useEffect } from 'react';
import WidgetSettings from 'views/dashboard/components/WidgetSettings';
import Select from 'common/components/form/inputs/Select';
import Checkbox from 'common/components/form/inputs/Checkbox';
import { getAsyncOptions, refetchAsyncOptions } from 'utils/helpers';
import { getInitialSingleValue } from 'common/utils/lists';
import { selectWidgetPreferences } from 'store/dashboards/selectors';
import { useSelector } from 'react-redux';
import widgetsDetails from 'views/dashboard/widgets/widgets-details';

import { Row, Col } from 'reactstrap';
import { useForm, useFormState } from 'utils/hooks';
import { booleanField, optionField } from 'common/utils/form/fieldTypes';

const WidgetSavedTableSettings = ({ id, onSave, label, onClose, isSubmitting }) => {
  const { formState, collectValues, loadValues } = useForm(
    { filter: optionField({ required: true }), update: booleanField() },
    {}
  );
  const { fields, selectField } = useFormState(formState);

  const preferences = useSelector(state => selectWidgetPreferences(state, id));

  const tableLabels = widgetsDetails?.[label].preferencesComponentProps.tableLabels;
  const allOptionsText = widgetsDetails?.[label].preferencesComponentProps.allOptionsText;
  const showUpdate = widgetsDetails?.[label].preferencesComponentProps?.showUpdate;

  const init = async () => {
    if (preferences?.saved_table_id) {
      const res = await getInitialSingleValue(`/tables/${preferences?.saved_table_id}`);

      selectField('filter')({ id: res?.id, name: res?.name });
    } else if (preferences?.saved_table_id !== undefined) {
      selectField('filter')({ id: 0, name: allOptionsText });
    }
  };

  useEffect(() => {
    if (preferences?.filter?.id !== 'all') {
      loadValues(preferences);
    }
    init();
  }, [id, preferences]);

  const handleSubmitForm = async ({ custom_title }) => {
    const values = collectValues();
    if (!values) return;

    const params = {
      custom_title,
      saved_table_id: values.filter.id || null,
      update: values.update
    };

    onSave(params);
  };

  const getInitialValues = async () => {
    const opts = await refetchAsyncOptions('saved-tables', {
      table_labels: tableLabels
    });

    if (opts) {
      return [{ id: 0, name: allOptionsText }, ...opts];
    } else return [{ id: 0, name: allOptionsText }];
  };

  return (
    <WidgetSettings id={id} isSubmitting={isSubmitting} onClose={onClose} onSave={handleSubmitForm}>
      <Row className="g-0 mt-3">
        <Col xs={12}>
          <Select
            isAsync
            loadOptions={search =>
              getAsyncOptions(search, 'saved-tables', { table_labels: tableLabels })
            }
            label="Choose filter"
            defaultOptions={getInitialValues}
            placeholder=""
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            onChange={selectField('filter')}
            isClearable={false}
            {...fields.filter}
          />
        </Col>
      </Row>
      {showUpdate ? (
        <Row className="g-0 fs-12 text-primary">
          <Col xs={12}>
            <Checkbox
              value={fields.update.value}
              onChange={() => selectField('update')(!fields.update.value)}
              label="Update every 15 minutes"
            />
          </Col>
        </Row>
      ) : null}
    </WidgetSettings>
  );
};

export default WidgetSavedTableSettings;
