import React, { useEffect, useState } from 'react';
import DashboardForm from './DashboardForm';

import config from './config';
import { useForm, useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import useRouter from 'use-react-router';
import { selectUserDashboards } from 'store/dashboards/selectors';
import * as dashboardsActions from 'store/dashboards/actions';
import paths from 'routing/routes/_paths';

import selectDataFromState from 'common/utils/hooks/useForm/selectDataFromState';
import PropTypes from 'prop-types';

const EditForm = ({ isOpen, active, onClose }) => {
  const list = useSelector(selectUserDashboards);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { history } = useRouter();
  const { formState, collectValues, loadValues, resetForm, hasErrors, handleSubmitError } = useForm(
    config,
    {}
  );
  const [editDashboard, deleteDashboard, setDashboardForm] = useActions([
    dashboardsActions.editDashboard,
    dashboardsActions.deleteDashboard,
    dashboardsActions.setDashboardForm
  ]);

  useEffect(() => {
    if (isOpen && active) {
      const initialValues = selectDataFromState(active, config);

      loadValues({
        ...initialValues,
        shared_parties: initialValues.shared_parties || [],
        shared_departments: initialValues.shared_departments || []
      });
    }
  }, [isOpen, active?.id]);

  const onSubmit = async params => {
    setIsSubmitting(true);

    try {
      await editDashboard({ ...params, id: active.id });

      onClose();
      resetForm();

      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      handleSubmitError(error);
    }
  };

  const onDelete = async () => {
    setIsDeleting(true);

    const id = active.id;
    const res = await deleteDashboard(id);

    setIsDeleting(false);

    if (res) {
      setDashboardForm({ isOpen: false, active: null });
      const nextDashboards = list.filter(d => d.id !== id);
      const newId = nextDashboards.length ? nextDashboards[nextDashboards.length - 1].id : null;

      if (newId) {
        history.push(`${paths.dashboard}/${newId}`);
      } else {
        history.push(`${paths.dashboard}`);
      }
    }
  };

  return (
    <DashboardForm
      formState={formState}
      collectValues={collectValues}
      hasErrors={hasErrors}
      onSubmit={onSubmit}
      onDelete={onDelete}
      isSubmitting={isSubmitting}
      isDeleting={isDeleting}
      onClose={onClose}
    />
  );
};

EditForm.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  active: PropTypes.shape({ id: PropTypes.number })
};

export default EditForm;
