import TYPES from './types';

const INITIAL_STATE = {
  isIgnoring: false
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SET_IS_IGNORING:
      return {
        ...state,
        isIgnoring: payload.isIgnoring
      };
    default:
      return state;
  }
};

export default reducer;
