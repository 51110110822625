import { useSelector } from 'react-redux';

import WidgetDefaultCard from 'views/dashboard/components/WidgetDefaultCard';
import paths from 'routing/routes/_paths';

import { selectWidgetStateData } from 'store/dashboards/selectors';

const RequisitionsCardWidget = ({ id }) => {
  const data = useSelector(state => selectWidgetStateData(state, id));

  return (
    <WidgetDefaultCard
      id={id}
      count={data.requisition_count}
      linkTo={paths.purchasing_requisitions}
    />
  );
};

export default RequisitionsCardWidget;
