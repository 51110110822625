import { getCrewSecurity } from './actions';
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  id: null
};

const slice = createSlice({
  name: 'security',
  initialState: INITIAL_STATE,
  extraReducers: builder => {
    builder
      .addCase(getCrewSecurity.pending, (state, { meta }) => {
        if (state.id !== meta.arg.id) return { id: meta.arg.id };

        return state;
      })
      .addCase(getCrewSecurity.fulfilled, (state, { payload }) => {
        state = {
          ...state,
          ...payload
        };

        return state;
      });
  }
});

export default slice.reducer;
