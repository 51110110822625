import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('CREATE_FORM'),
  ...asyncTypes('GET_DIGITAL_FORM'),
  ...asyncTypes('GET_LAST_SUBMITTED_FORM'),
  ...asyncTypes('UPDATE_DIGITAL_FORM'),
  ...asyncTypes('CREATE_FORM_SUBMISSION'),
  ...asyncTypes('GET_FORM_SUBMISSION'),
  ...asyncTypes('GET_FORM_SUBMISSION_PREVIOUS_VALUES'),
  ...asyncTypes('UPDATE_FORM_SUBMISSION'),
  ...asyncTypes('REMOVE_FORM_SUBMISSION'),
  ...asyncTypes('DOWNLOAD_FORM_FILE'),
  ...asyncTypes('DOWNLOAD_FORM_SUBMISSION_ATTACHMENTS'),
  ...asyncTypes('DELETE_FORM'),
  ...asyncTypes('CLONE_FORM'),

  /* FORM FIELD TYPES */

  ...asyncTypes('CREATE_FORM_FIELD'),
  ...asyncTypes('UPDATE_FORM_FIELD'),
  ...asyncTypes('GET_OFFICE_CREW_PARTICIPANTS'),
  ...asyncTypes('GET_ONBOARD_CREW_PARTICIPANTS'),

  /* FORM SUBMISSION DIGITAL SIGNATURE TYPES */
  ...asyncTypes('GET_FORM_SUBMISSION_DIGITAL_SIGNATURES'),
  ...asyncTypes('CREATE_FORM_SUBMISSION_DIGITAL_SIGNATURE'),
  ...asyncTypes('DELETE_FORM_SUBMISSION_DIGITAL_SIGNATURE')
};

export default TYPES;
