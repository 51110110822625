import React from 'react';
import WidgetTitle from 'views/dashboard/components/WidgetTitle';

const CashBalance = ({ id }) => {
  return (
    <div>
      <WidgetTitle id={id} />
      <div className="bg-white p-2 rounded rounded-xl text-violet fs-12">
        Cash balance widget coming Soon
      </div>
    </div>
  );
};

export default CashBalance;
