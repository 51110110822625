import { useSelector } from 'react-redux';
import Departments from 'common/components/general/Departments';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import paths from 'routing/routes/_paths';

import { selectWidgetState } from 'store/dashboards/selectors';

import moment from 'moment';
import PropTypes from 'prop-types';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import { Col, Row } from 'reactstrap';
import { downloadVesselDocumentAttachments } from '@/common/components/vessels/profile/tabs/documents/store/actions';

const VesselDocuments = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));

  return (
    <div>
      <WidgetTitle id={id} linkTo={paths.vessels_document_manager} />
      <WidgetDefaultTable
        id={id}
        columns={[
          {
            header: 'Name',
            key: 'title',
            type: 'string',
            headerClassName: 'text-violet',
            width: 3
          },
          {
            header: 'Vessel',
            key: 'vessel',
            type: 'string',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Category',
            key: 'category',
            type: 'string',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Department',
            key: 'department',
            type: 'string',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Issue',
            key: 'issue_date',
            type: 'string',
            headerClassName: 'text-violet'
          },
          {
            header: 'Expiration',
            key: 'expiration_date',
            type: 'string',
            headerClassName: 'text-violet'
          }
        ]}
        rows={{
          title: data => {
            return data?.type?.name ? (
              <TextWithTooltip className="h-auto">{data.type.name}</TextWithTooltip>
            ) : (
              ''
            );
          },
          vessel: data => {
            return data?.vessel?.name ? (
              <TextWithTooltip className="h-auto">{data.vessel.name}</TextWithTooltip>
            ) : (
              ''
            );
          },
          category: data => {
            return data?.type?.category?.name ? (
              <TextWithTooltip className="h-auto">{data.type.category.name}</TextWithTooltip>
            ) : (
              ''
            );
          },
          department: data => <Departments values={[data.type.responsible_department]} />,
          expiration_date: data => (
            <Row className="d-flex align-items-center">
              <Col xs={9}>
                <div>
                  {data?.expiration_date ? moment(data.expiration_date).format('DD/MM/YYYY') : '-'}
                </div>
              </Col>
              <Col xs={3}>
                <DownloadAttachment
                  downloadFileName={`${data?.type?.name}-attachments`}
                  data={data}
                  downloadFiles={() => downloadVesselDocumentAttachments({ id: data.id })}
                  hasAttachments={(data.attachments?.length || 0) > 0}
                />
              </Col>
            </Row>
          ),
          issue_date: data =>
            data?.issue_date ? moment(data.issue_date).format('DD/MM/YYYY') : null
        }}
        label={label}
        state={state}
      />
    </div>
  );
};

VesselDocuments.propTypes = {
  id: PropTypes.number
};

export default VesselDocuments;
