import paths from 'routing/routes/_paths';
import { createSelector } from 'reselect';

const selectPurchasingSetupReducer = state => state.purchasing.setup;
const getKey = (_, key) => key;

/* Init Tree */
export const selectIsTreeInitialized = createSelector(
  selectPurchasingSetupReducer,
  reducer => reducer.initialized
);
/* -- */

/* Root lvl */
export const selectRootGroups = createSelector(
  selectPurchasingSetupReducer,
  reducer => reducer.rootGroups || []
);
/* -- */

/* Sub-groups */
export const selectSubGroups = createSelector(
  selectPurchasingSetupReducer,
  reducer => reducer.subGroups
);
export const selectGroupSubGroups = createSelector(
  selectSubGroups,
  getKey,
  (subGroups, id) => subGroups[id]
);
/* -- */

/* Groups */
export const selectGroupsLoading = createSelector(
  selectPurchasingSetupReducer,
  reducer => reducer.groupsLoading
);
export const selectGroups = createSelector(selectPurchasingSetupReducer, reducer => reducer.groups);
export const selectGroup = createSelector(selectGroups, getKey, (groups, id) => groups[id]);

export const selectIsSystemGroup = createSelector(selectGroup, group => group.is_extra_charges);

export const selectNumberOfGroups = createSelector(
  selectGroups,
  groups => Object.keys(groups).length
);

export const selectPurchasingSingleGroupIsLoading = state =>
  selectPurchasingSetupReducer(state)?.singleGroupIsLoading;
/* -- */

/* Expanded Groups */
export const selectExpandedGroups = createSelector(
  selectPurchasingSetupReducer,
  reducer => reducer.expandedGroups
);
export const selectIsGroupExpanded = createSelector(
  selectExpandedGroups,
  getKey,
  (expandedGroups, id) => expandedGroups[id]
);
export const areAllGroupsExpanded = createSelector(
  selectExpandedGroups,
  selectSubGroups,
  (expandedGroups, subGroups) =>
    Object.keys(expandedGroups).length === Object.keys(subGroups).length
);
/* -- */

/*  Modals */
export const selectOpenedModals = createSelector(
  selectPurchasingSetupReducer,
  reducer => reducer.openedModals
);
export const selectIsModalOpened = createSelector(
  selectOpenedModals,
  getKey,
  (openedModals, type) => openedModals[type]
);
export const selectActiveGroupInModal = createSelector(
  selectPurchasingSetupReducer,
  reducer => reducer.activeGroupInModal
);
export const selectActiveItemInModal = createSelector(
  selectPurchasingSetupReducer,
  reducer => reducer.activeItemInModal
);
/* -- */

export const selectTreeSearch = createSelector(
  selectPurchasingSetupReducer,
  reducer => reducer.treeSearch
);
export const selectTypeTreeSearch = createSelector(
  selectTreeSearch,
  getKey,
  (treeSearch, type) => treeSearch[type]
);

export const selectPurchasingLibraryIsUsedInRequisition = state =>
  state?.router?.location?.pathname?.startsWith(paths.purchasing_requisitions);
export const selectPurchasingLibraryIsUsedInCategories = state =>
  state?.router?.location?.pathname?.startsWith(paths.purchasing_categories);
export const selectPurchasingLibraryIsUsedInPricelist = state =>
  state?.router?.location?.pathname?.startsWith(paths.purchasing_pricelist);
export const selectPurchasingLibraryIsUsedInSetup = state =>
  state?.router?.location?.pathname?.startsWith(paths.purchasing_library_setup);

export const selectSelectedSettingsCategoryId = createSelector(
  selectPurchasingSetupReducer,
  reducer => reducer.selectedSettingsCategoryId
);

/* Active Group */
export const selectActiveGroupId = createSelector(selectPurchasingSetupReducer, reducer =>
  reducer.activeGroupId ? parseInt(reducer.activeGroupId) : null
);
export const selectActiveGroup = createSelector(
  selectGroups,
  selectActiveGroupId,
  (groups, activeGroupId) => groups[activeGroupId]
);

export const selectActiveGroupTypes = state => selectActiveGroup(state)?.company_types;
/* -- */

/* Items */
export const selectItemsLoading = createSelector(
  selectPurchasingSetupReducer,
  reducer => reducer.itemsLoading
);
export const selectItems = createSelector(selectPurchasingSetupReducer, reducer => reducer.items);

export const selectIsCreatingItem = createSelector(
  selectPurchasingSetupReducer,
  reducer => reducer.isCreatingItem
);

export const selectActiveItemId = createSelector(selectPurchasingSetupReducer, reducer =>
  reducer.activeItemId ? parseInt(reducer.activeItemId) : null
);

export const selectItemsPaging = createSelector(
  selectPurchasingSetupReducer,
  reducer => reducer.itemsPaging
);

export const selectItemsPagingTotal = createSelector(
  selectItemsPaging,
  itemsPaging => itemsPaging.total
);

export const selectTriggerItemRefetch = createSelector(
  selectPurchasingSetupReducer,
  reducer => reducer.triggerItemRefetch
);

export const selectSelectedItemIds = state => selectPurchasingSetupReducer(state).selectedItemIds;
/* -- */
