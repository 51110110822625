import { createSelector } from 'reselect';
import {
  isItineraryDeliveredAtSea,
  isItineraryReDeliveredAtSea
} from 'common/utils/voyages/helpers';

const getId = (_, id) => id;
const getSecondaryKey = (_, __, id) => id;

const selectPortsReducer = state => state.ports;
const selectPortTimelineReducer = state => state.ports.timeline;
const selectBunkerPricesReducer = state => state.ports.bunker_prices;
const selectWeatherReducer = state => state.ports.weather;

export const selectPort = createSelector([selectPortsReducer], ports => ports.port);
export const selectPortId = state => selectPort(state).id;

export const selectPortSummary = createSelector([selectPortsReducer], ports => ports.summary);
export const selectPortProfileLoading = createSelector(
  [selectPortsReducer],
  ports => ports.profileLoading
);
export const selectPortTimelineIsFetching = createSelector(
  selectPortTimelineReducer,
  timeline => timeline.isFetching
);

export const selectBunkerPricesData = state => selectBunkerPricesReducer(state).data;

export const selectBunkerPricesIsFetching = state => selectBunkerPricesReducer(state).isFetching;

export const selectSelectedVessels = createSelector(
  [selectPortsReducer],
  ports =>
    ports &&
    ports.data.map(item => {
      return item.voyage.vessel;
    })
);

export const selectPortData = createSelector([selectPortsReducer], ports => ports.data);

/* Itineraries */
export const selectItineraries = createSelector(
  selectPortTimelineReducer,
  reducer => reducer.itineraries
);

const selectiIineraryActionsState = createSelector(
  selectPortTimelineReducer,
  reducer => reducer.itineraryActions
);
export const selectItineraryActions = createSelector(
  selectiIineraryActionsState,
  getId,
  (itineraryActions, id) => itineraryActions?.[id] || []
);

export const selectItinerary = createSelector(
  selectPortTimelineReducer,
  getId,
  ({ itinerary }, id) => itinerary[id]
);

export const selectItineraryIsScheduled = createSelector(
  selectItinerary,
  itinerary => itinerary?.is_scheduled
);

export const selectItineraryIsLoaded = createSelector(
  selectItinerary,
  itinerary => itinerary?.loaded
);

export const selectItineraryIsDeliveredAtSea = createSelector(selectItinerary, itinerary =>
  isItineraryDeliveredAtSea(itinerary)
);

export const selectItineraryIsReDeliveredAtSea = createSelector(selectItinerary, itinerary =>
  isItineraryReDeliveredAtSea(itinerary)
);

const selectItineraryTripState = createSelector(
  selectPortTimelineReducer,
  reducer => reducer.itineraryTrip
);
export const selectItineraryTrip = createSelector(
  selectItineraryTripState,
  getId,
  (itineraryTrip, id) => itineraryTrip?.[id]
);

/* Reports */
const selectPortStatementState = createSelector(
  selectPortTimelineReducer,
  reducer => reducer.portStatement
);
export const selectPortStatement = createSelector(
  selectPortStatementState,
  getId,
  (portStatement, id) => portStatement?.[id]
);

export const selectPortStatementAction = createSelector(
  selectPortStatement,
  getSecondaryKey,
  (portStatement, actionId) => (portStatement?.detailed_actions || []).find(a => a.id === actionId)
);

const selectDeliveryReportState = createSelector(
  selectPortTimelineReducer,
  reducer => reducer.deliveryReport
);
export const selectDeliveryReport = createSelector(
  selectDeliveryReportState,
  getId,
  (deliveryReport, reportId) => deliveryReport[reportId]
);

const selectRedeliveryReportState = createSelector(
  selectPortTimelineReducer,
  reducer => reducer.redeliveryReport
);
export const selectRedeliveryReport = createSelector(
  selectRedeliveryReportState,
  getId,
  (redeliveryReport, reportId) => redeliveryReport[reportId]
);

export const selectWeatherisFetching = createSelector(
  [selectWeatherReducer],
  weather => weather.isFetching
);

export const selectWeatherCurrent = createSelector(
  [selectWeatherReducer],
  weather => weather.current
);

export const selectWeatherDaily = createSelector([selectWeatherReducer], weather => weather.daily);

// actions

export const selectPortActionsIsFetching = state => selectPortsReducer(state)?.actions?.isFetching;

// actions bunkering

export const selectPortActionsBunkeringData = state =>
  selectPortsReducer(state)?.actions?.data?.bunkering?.data;

export const selectPortActionsBunkeringCount = state =>
  selectPortsReducer(state)?.actions?.data?.bunkering?.count;

// actions loading

export const selectPortActionsLoadingData = state =>
  selectPortsReducer(state)?.actions?.data?.loading?.data;

export const selectPortActionsLoadingCount = state =>
  selectPortsReducer(state)?.actions?.data?.loading?.count;

// actions discharging

export const selectPortActionsDischargingData = state =>
  selectPortsReducer(state)?.actions?.data?.discharging?.data;

export const selectPortActionsDischargingCount = state =>
  selectPortsReducer(state)?.actions?.data?.discharging?.count;

/* -- */
