import { useSelector } from 'react-redux';

import WidgetDefaultCard from 'views/dashboard/components/WidgetDefaultCard';
import paths from 'routing/routes/_paths';

import { selectWidgetStateData } from 'store/dashboards/selectors';

const PendingEvaluationsCardWidget = ({ id }) => {
  const data = useSelector(state => selectWidgetStateData(state, id));

  return (
    <WidgetDefaultCard
      id={id}
      count={data.pending_evaluations_count}
      linkTo={paths.crew_evaluation_obligations}
    />
  );
};

export default PendingEvaluationsCardWidget;
