import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_JOB_TRIGGER'),
  ...asyncTypes('CREATE_JOB_TRIGGER'),
  ...asyncTypes('UPDATE_JOB_TRIGGER'),
  ...asyncTypes('UPDATE_JOB_TRIGGER_ACTIVE'),
  ...asyncTypes('UPDATE_JOB_TRIGGER_ARCHIVE'),
  ...asyncTypes('GET_JOB_TRIGGERS'),
  ...asyncTypes('DELETE_JOB_TRIGGER'),
  SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
  SET_DUE_DATE: 'SET_DUE_DATE',
  INVALID_DUE_DATE: 'INVALID_DUE_DATE'
};

export default TYPES;
