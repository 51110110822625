import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_CREW_DOCUMENT_TYPES_SETTINGS'),
  ...asyncTypes('UPDATE_CREW_DOCUMENT_TYPE_SETTINGS'),
  ...asyncTypes('GET_CREW_DOCUMENT_TYPES_REMINDERS'),
  ...asyncTypes('UPDATE_CREW_DOCUMENT_REMINDER'),
  ...asyncTypes('CREATE_CREW_DOCUMENT_TYPE'),
  ...asyncTypes('UPDATE_CREW_DOCUMENT_TYPE'),
  ...asyncTypes('ARCHIVE_CREW_DOCUMENT_TYPE'),
  ...asyncTypes('UNARCHIVE_CREW_DOCUMENT_TYPE'),

  SET_CREW_DOCUMENTS_MATRIX_CATEGORY_FILTER: 'SET_CREW_DOCUMENTS_MATRIX_CATEGORY_FILTER',
  SET_CREW_DOCUMENTS_MATRIX_RANKS_FILTER: 'SET_CREW_DOCUMENTS_MATRIX_RANKS_FILTER',
  SET_CREW_DOCUMENTS_MATRIX_DOCUMENTS_FILTER: 'SET_CREW_DOCUMENTS_MATRIX_DOCUMENTS_FILTER',

  SET_CREW_DOCUMENTS_MATRIX_SORTING: 'SET_CREW_DOCUMENTS_MATRIX_SORTING',

  SET_CREW_DOCUMENTS_MATRIX_CATEGORY_DOCUMENTS: 'SET_CREW_DOCUMENTS_MATRIX_CATEGORY_DOCUMENTS',
  SET_CREW_DOCUMENTS_MATRIX_CATEGORY_PAGINATION_CURRENT_PAGE:
    'SET_CREW_DOCUMENTS_MATRIX_CATEGORY_PAGINATION_CURRENT_PAGE'
};

export default TYPES;
