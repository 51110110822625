import moment from 'moment';
import _reduce from 'lodash/reduce';
import _isNumber from 'lodash/isNumber';
import _isObject from 'lodash/isObject';
import _isArray from 'lodash/isArray';

export const uppercase = string => (string ? string.toUpperCase() : '-');

export const numberToStr = (number, fractionDigits = 2) => {
  number = parseFloat(number);
  return !isNaN(number)
    ? number.toLocaleString('en', { maximumFractionDigits: fractionDigits })
    : '-';
};
export const strToNumber = formattedStr => {
  if (_isNumber(formattedStr)) return formattedStr;
  if (!formattedStr || formattedStr.length === 0) return null;
  return Number(formattedStr.replace(/[^\d\.]/g, ''));
};

export const dateOnlyToStr = date => (date ? moment(date).format('ddd DD MMM YYYY') : '-');

export const dateToStr = date => (date ? moment(date).format('ddd DD MMM YYYY HH:mm') : '-');

export const toFloats = value => {
  if (_isArray(value)) {
    return value.map(toFloats);
  } else if (_isObject(value)) {
    return _reduce(
      value,
      (floats, value, key) => ({
        ...floats,
        [key]: toFloats(value)
      }),
      {}
    );
  } else {
    return strToNumber(value);
  }
};

export const base = (value, ratio) => {
  const v = toFloats({ value });
  return v.value * ratio;
};

export const formatBEH = (value, unitId, units) => {
  const formatedValue = numberToStr(value, 0);
  const unit = units.options.find(unit => unit.id === unitId);
  let formatedUnit;
  if (!unit) {
    return '-';
  }
  switch (unit.type) {
    case 'lump_sum':
      formatedUnit = '$';
      break;
    case 'time':
      formatedUnit = `$/${unit.notation[0].toUpperCase()}`;
      break;
    default:
      formatedUnit = `$/${unit.notation}`;
  }
  return `${formatedValue} ${formatedUnit}`;
};
