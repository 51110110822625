import { useSelector } from 'react-redux';
import { selectList } from 'store/lists/selectors';
import { countActiveDashboardWidgetsPerType } from 'store/dashboards/selectors';

import { Row, Col, Button } from 'reactstrap';
import { icons } from './helpers';
import widgetsDetails from 'views/dashboard/widgets/widgets-details';

import ShadowBox from 'common/components/general/ShadowBox';
import SvgRender from 'common/components/general/SvgRender';

const WidgetType = ({ data, onAdd }) => {
  const icon = widgetsDetails[data.label]?.icon;
  const count = useSelector(state => countActiveDashboardWidgetsPerType(state, data?.label));

  return (
    <ShadowBox className="widget-type-box p-2 d-flex flex-column" color="light-1" flat>
      {icon ? (
        <div className="widget-type-box__icon cmb-12">
          <ShadowBox className="p-1 d-inline-flex align-items-center justify-content-center text-primary">
            <SvgRender src={icon} style={{ width: 18, height: 18 }} />
          </ShadowBox>
        </div>
      ) : null}
      <div className="widget-type-box__title fs-14 text-primary mb-1">
        <strong>{data.name}</strong>
      </div>
      <div className="widget-type-box__description fs-12 text-violet">{data.description}</div>
      <div className="widget-type-box__actions mt-auto d-flex align-items-center justify-content-between">
        <Button
          color="yellow"
          size="sm"
          className="text-primary rounded-xl d-inline-flex align-items-center justify-content-center height-24"
          onClick={() => onAdd(data)}
        >
          <SvgRender src={icons[data.layout]} style={{ width: 12, height: 12 }} className="me-1" />
          <strong className="fs-14 lh-1">Add</strong>
        </Button>
        {count ? (
          <div className="height-24 min-width-24 d-inline-flex align-items-center justify-content-center text-primary border border-primary border-2 rounded-circle cps-4 cpe-4">
            <strong className="fs-12 lh-1">{count}</strong>
          </div>
        ) : null}
      </div>
    </ShadowBox>
  );
};

const WidgetsList = ({ onAdd }) => {
  const widgetTypes = useSelector(selectList('widget-types'))?.options;

  return (
    <div className="flex-1 widgets-list-container gray-scrollbar">
      <Row>
        {widgetTypes?.map(widgetType => (
          <Col xs={6} className="mb-2" key={widgetType.id}>
            <WidgetType data={widgetType} onAdd={onAdd} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default WidgetsList;
