import { useCallback, useEffect, useState } from 'react';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectWidgetPreferences,
  selectWidgetStatePaging,
  selectWidgetTypeLabel
} from 'store/dashboards/selectors';

import * as dashboardsActions from 'store/dashboards/actions';

import _get from 'lodash/get';
import moment from 'moment';

const isWidgetInitialized = async (label, fetchWidgetData) => {
  switch (label) {
    case 'bookmarks_card':
      try {
        const { data } = await fetchWidgetData();
        return data && data?.length ? true : false;
      } catch (e) {
        console.error(e);
        return false;
      }
    default:
      return true;
  }
};

const isWidgetUnset = async ({ preferences, label, fetchWidgetData }) => {
  // Check if the widget required preferences are set

  switch (label) {
    case 'captain_reports_table':
    case 'captain_reports_card':
    case 'pending_evaluations_table':
    case 'pending_evaluations_card':
    case 'pms_jobs_table':
    case 'pms_jobs_card':
    case 'jobs_card':
    case 'jobs_table':
    case 'forms_table':
    case 'forms_card':
    case 'requisition_card':
    case 'requisition_table':
    case 'events_table':
    case 'events_card':
    case 'seamen_card':
    case 'seamen_table':
    case 'vessel_documents_table':
    case 'vessel_documents_card':
    case 'findings_table':
    case 'findings_card':
    case 'purchasing_approvals_table':
    case 'purchasing_approvals_card':
    case 'crew_documents_table':
    case 'crew_documents_card':
    case 'budget':
    case 'flag_dispensation_table':
    case 'flag_dispensation_card':
    case 'companies_table':
    case 'companies_card':
    case 'persons_table':
    case 'persons_card':
    case 'spare_parts_inventory_table':
    case 'spare_parts_inventory_card':
    case 'purchasing_table':
    case 'purchasing_card':
    case 'invoices_table':
    case 'invoices_card':
      return _get(preferences, 'saved_table_id') === undefined;
    case 'running_hours_reports_table':
    case 'spare_parts_inventory_survey_due_dates_table':
      return !preferences.vessel_ids?.length && !preferences.vessel_group_ids?.length;
    case 'unread_messages_sidebar':
      return preferences?.entity_types?.length === 0;
    case 'vessel_tce_results_table':
      return !preferences?.vessel_id;
    case 'bookmarks_card':
      return !(await isWidgetInitialized(label, fetchWidgetData));
    case 'bookmarks_table':
      return !preferences?.custom_title && !preferences?.bookmarks?.length;
    case 'overdue_jobs_table':
      return false;
    default:
      return false;
  }
};
const getWidgetParams = async ({ label, preferences }, { paging }) => {
  // Override request params

  const params = {
    paging: { per_page: paging.per_page, current_page: paging.current_page }
  };

  switch (label) {
    case 'birthday_table':
      const { type, rank_ids, status_ids } = preferences;

      const isCrewType = type === 'crew';

      params.filters = [
        { name: 'next_birthday', value: moment().format('YYYY-MM-DD'), operation: '>' },
        isCrewType && rank_ids?.length
          ? {
              name: 'rank',
              operation: 'oneOf',
              value: rank_ids
            }
          : undefined,
        isCrewType && status_ids?.length
          ? {
              name: 'status',
              operation: 'oneOf',
              value: status_ids
            }
          : undefined
      ];
      break;

    case 'persons_table':
      const { show_more } = preferences;
      params.paging.per_page = paging.current_page === 1 && show_more ? 100 : 12;
      break;

    case 'bookmarks_table':
      params.paging.per_page = 32;
      break;

    default:
      break;
  }

  return new Promise(resolve => resolve(params));
};

export default function useWidget({ id }) {
  const [isUnset, setIsUnset] = useState(true);
  const [getWidgetData] = useActions([dashboardsActions.getWidgetData]);

  const { current_page, default_per_page, per_page } = useSelector(state =>
    selectWidgetStatePaging(state, id)
  );

  const label = useSelector(state => selectWidgetTypeLabel(state, id));
  const preferences = useSelector(state => selectWidgetPreferences(state, id));

  const fetchWidgetData = useCallback(
    async current_page => {
      try {
        const params = await getWidgetParams(
          { preferences, label },
          {
            paging: {
              current_page: current_page || 1,
              per_page: per_page || default_per_page
            }
          }
        );
        return await getWidgetData({ ...params, widgetId: id });
      } catch (error) {
        console.error(error);
      }
    },
    [preferences, label, per_page, default_per_page, getWidgetData, id]
  );

  const getIsWidgetUnset = useCallback(async (preferences, label, fetchWidgetData) => {
    const isUnset = await isWidgetUnset({ preferences, label, fetchWidgetData });
    setIsUnset(isUnset);
  }, []);

  useEffect(() => {
    getIsWidgetUnset(preferences, label, fetchWidgetData);
  }, [preferences, label, getIsWidgetUnset, fetchWidgetData]);

  useEffect(() => {
    if (id && !isUnset && label) {
      fetchWidgetData(current_page || null);
    }
  }, [isUnset, id, current_page, fetchWidgetData, label]);

  return { isUnset, fetchWidgetData };
}
