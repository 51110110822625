import { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

const useTooltipID = (tooltipIDText = 'tooltip') => {
  const [tooltipText, setTooltipText] = useState<string>('');

  const tooltipID = useMemo(() => {
    return tooltipText ? `${tooltipText}--${uuid()}` : '';
  }, [tooltipText]);

  useEffect(() => {
    setTooltipText(tooltipIDText);

    return () => {
      setTooltipText('');
    };
  }, [tooltipIDText]);

  return { avoidRender: !tooltipID, tooltipID: tooltipID };
};

export default useTooltipID;
