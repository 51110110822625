import PropTypes from 'prop-types';
import Drawer, { DrawerHeader } from 'common/components/drawer';
import _flatten from 'lodash/flatten';
import { useState, useEffect, useCallback } from 'react';
import { Button } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { FormDrawer } from 'common/components/drawer';
import { FormBody } from 'common/components/drawer';
import { FormFooter } from 'common/components/drawer';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';

import { useForm, useFormState } from 'utils/hooks';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';

import PartiesSimilarityModal from 'common/components/person/components/PartiesSimilarityModal';
import { triggerModalBySetFieldValues } from 'store/similarities/slice';

import config from './config';
import MainInfo from './sections/MainInfo';
import Address from '../common-sections/Address';
import ConnectedCompanies from './sections/ConnectedCompanies';
import { createPerson } from '@/store/persons/actions';
import { selectIsLoadingSimilarities } from '@/store/similarities/selectors';

const CreateCompanyDrawer = ({ drawer, className, onCloseCallback, onSaveCallback }) => {
  const [isSaving, setIsSaving] = useState(false);
  const { formState, collectValues, resetForm, hasErrors, handleSubmitError } = useForm(config, {
    emails: [],
    contact_info: []
  });
  const { fields, selectField, changeField, subStates, removeSubform, addSubform } =
    useFormState(formState);
  const dispatch = useAppDispatch();
  const isLoadingSimilarities = useAppSelector(selectIsLoadingSimilarities);

  const { tooltipID: companyDrawerUniqName } = useTooltipID('company-drawer');

  useEffect(() => {
    if (!drawer.isOpen) {
      resetForm();
    }
  }, [drawer.isOpen, resetForm]);

  const onSubmit = () => {
    const values = collectValues();

    if (!values || hasErrors) return;

    dispatch(triggerModalBySetFieldValues(values));
  };

  const onClose = () => {
    drawer.close();

    if (onCloseCallback) onCloseCallback();
  };

  const onCreateCompany = useCallback(
    async values => {
      if (!values) return;

      try {
        setIsSaving(true);

        const { company_types, ...restValues } = values;

        const parsedValues = {
          ...restValues,
          company_type_ids: (company_types || [])?.map(e => e?.company_type_id?.id) || [],
          company_subtype_ids: _flatten(
            (company_types || [])
              .filter(e => e?.company_subtype_ids?.id || e?.company_subtype_ids)
              .map(e => e.company_subtype_ids?.id || e.company_subtype_ids)
          )
        };

        const {
          serving_ports,
          country,
          connected_companies,
          iso_attachments,
          emails,
          contact_info,
          ...rest
        } = parsedValues;

        const params = {
          ...rest,
          iso_attachments: iso_attachments?.map(attachment => attachment?.id) || [],
          serving_ports: serving_ports?.filter(e => e?.port?.id)?.map(e => e?.port?.id) || [],
          emails: emails?.filter(email => email?.value) || [],
          contact_info: contact_info?.filter(info => info?.value) || [],
          country_id: country?.id || null,
          connected_companies:
            connected_companies?.filter(e => e?.company?.id)?.map(e => e?.company?.id) || []
        };

        const res = await dispatch(createPerson(params));

        drawer.close();
        setIsSaving(false);

        if (onSaveCallback) onSaveCallback(res);
      } catch (error) {
        setIsSaving(false);
        handleSubmitError(error);
      }
    },
    [dispatch, drawer, handleSubmitError, onSaveCallback]
  );

  return (
    <Drawer
      size="sm-md"
      className={`create-company-drawer ${className} ${companyDrawerUniqName}`}
      {...drawer}
      close={onClose}
    >
      <DrawerHeader>Create new Company</DrawerHeader>
      <FormDrawer>
        <FormBody className="px-4">
          <MainInfo
            fields={fields}
            selectField={selectField}
            changeField={changeField}
            subStates={subStates}
            removeSubform={removeSubform}
            addSubform={addSubform}
          />

          <Address fields={fields} selectField={selectField} changeField={changeField} />

          <ConnectedCompanies
            selectField={selectField}
            changeField={changeField}
            subStates={subStates}
            removeSubform={removeSubform}
            addSubform={addSubform}
          />
        </FormBody>

        <FormFooter>
          <Button color="cancel" className="px-0 py-1 me-4" onClick={onClose} disabled={isSaving}>
            CANCEL
          </Button>
          <Button
            onClick={onSubmit}
            disabled={isSaving || isLoadingSimilarities}
            color="primary"
            className="px-2"
          >
            CREATE
          </Button>
        </FormFooter>
      </FormDrawer>

      {drawer.isOpen ? (
        <PartiesSimilarityModal
          container={`.${companyDrawerUniqName}`}
          backdropClassName="blurred-backdrop"
          label="company"
          onSave={onCreateCompany}
        />
      ) : null}
    </Drawer>
  );
};

export default CreateCompanyDrawer;

CreateCompanyDrawer.propTypes = {
  className: PropTypes.string,
  drawer: DrawerState,
  onCloseCallback: PropTypes.func,
  onSaveCallback: PropTypes.func,
  hideInitialForm: PropTypes.bool
};
