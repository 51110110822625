import PreventActionButton from 'common/components/buttons/PreventActionButton';
import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import binIcon from 'common/assets/svg/actions/delete.svg';
import { useDispatch } from 'react-redux';
import { deletePurchasingPricelistItem } from 'store/purchasing/pricelist/actions';
import { removeSelectedLibraryItemId } from 'common/components/purchasing/library-setup/store/actions';

const RemoveItem = ({ item }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const onAccept = useCallback(() => {
    dispatch(deletePurchasingPricelistItem({ id: item.id }));
    dispatch(removeSelectedLibraryItemId(item.store_id));
  }, [dispatch, item.id, item.store_id]);

  return (
    <PreventActionButton
      svgClassName="text-red"
      buttonColor="link"
      buttonIcon={binIcon}
      buttonClassName="px-0 py-0"
      iconStyle={{ width: 16, height: 16 }}
      onButtonClick={() => setIsModalOpen(true)}
      closeModal={() => setIsModalOpen(false)}
      onModalAccept={onAccept}
      isModalOpen={isModalOpen}
      modalProps={{
        transparent: true,
        header: 'Delete item',
        body: (
          <>
            Are you sure that you want to delete the item{' '}
            <strong>{item?.store?.description}</strong>?
          </>
        )
      }}
    />
  );
};

RemoveItem.propTypes = {
  id: PropTypes.number.isRequired,
  item: PropTypes.shape({
    store: PropTypes.shape({
      desciption: PropTypes.string
    }).isRequired
  })
};

export default RemoveItem;
