import { asyncTypes } from 'store/_helpers';

const types = {
  ...asyncTypes('GET_TABS'),
  ...asyncTypes('GET_TAGS'),
  ...asyncTypes('GET_FILTER_TAGS'),
  ...asyncTypes('GET_GRAPH_VALUES'),
  ...asyncTypes('GET_GRAPH_BENCHMARKS'),
  ...asyncTypes('GET_DEFAULT_GRAPH'),
  ...asyncTypes('GET_DEFAULT_GRAPH_VALUES'),

  ...asyncTypes('CREATE_TAB'),
  ...asyncTypes('CREATE_GRAPH'),

  ...asyncTypes('UPDATE_TAB'),
  ...asyncTypes('UPDATE_GRAPH'),
  ...asyncTypes('UPDATE_DEFAULT_GRAPH'),
  ...asyncTypes('UPDATE_BENCHMARKS'),

  ...asyncTypes('DELETE_TAB'),
  ...asyncTypes('DELETE_GRAPH'),

  ...asyncTypes('GET_ENGINE_LOAD_DIAGRAM'),
  ...asyncTypes('UPDATE_ENGINE_LOAD_DIAGRAM'),

  ...asyncTypes('REORDER_GRAPHS'),

  SET_INTERVAL: 'SET_INTERVAL',
  TOGGLE_IS_FULLSCREEN_GRAPH: 'TOGGLE_IS_FULLSCREEN_GRAPH'
};

export default types;
