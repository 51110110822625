import { combineReducers } from 'redux';

import onSignerEvaluationStatisticsReducer from './on-signer-evaluation-statistics/reducer';
import onSignerStatisticsReducer from './on-signer-statistics/reducer';
import onBoardNationalitiesReducer from './onboard-nationalities/reducer';
import intertankoRetentionRateReducer from './intertanko-retention-rate/reducer';
import ticketingReducer from './ticketing/reducer';
import trainingNeedsReducer from './training-needs/reducer';
import retentionRateReducer from 'store/reports/crew/retention-rate/slice';
import debriefingReducer from 'store/reports/crew/debriefing/slice';
import wagesReducer from 'store/reports/crew/wages/slice';
import overlappingDays from 'store/reports/crew/overlapping-days/slice';

export default combineReducers({
  onSignerEvaluationStatisticsReducer,
  onSignerStatisticsReducer,
  onBoardNationalitiesReducer,
  intertankoRetentionRateReducer,
  ticketingReducer,
  trainingNeedsReducer,
  retentionRateReducer,
  debriefingReducer,
  wagesReducer,
  overlappingDays
});
