import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';

import { useActions } from 'utils/hooks';
import { selectWidgetStateData, selectWidgetStatePaging } from 'store/dashboards/selectors';
import * as dashboardsActions from 'store/dashboards/actions';

const LoadMoreButton = ({ id, ...rest }) => {
  const [updateWidgetState] = useActions([dashboardsActions.updateWidgetState]);

  const data = useSelector(state => selectWidgetStateData(state, id));
  const paging = useSelector(state => selectWidgetStatePaging(state, id));

  return paging.current_page !== paging.last_page ? (
    <div className="text-center mt-2">
      <Button
        color="link"
        onClick={() =>
          updateWidgetState(
            {
              paging: {
                current_page:
                  paging.current_page < paging.last_page
                    ? paging.current_page + 1
                    : paging.current_page,
                per_page: paging.per_page
              }
            },
            id
          )
        }
        className="widget-load-more-btn text-primary px-1 cpy-4 fw-medium fs-12"
        {...rest}
      >
        Load{' '}
        <span className="fw-bold">
          {paging.total - data.length >= 4 ? paging.per_page : paging.total - data.length}
        </span>{' '}
        more
      </Button>
    </div>
  ) : null;
};

export default LoadMoreButton;
