import { post, get, put } from '@/api';
import { OnboardSetupFilter } from '@/common/types/onboard-setup.ts';

type CreateOnboardSetupFilterPresetParam = Partial<
  Pick<OnboardSetupFilter, 'filters' | 'visible' | 'name' | 'page_size' | 'sorting' | 'description'>
>;

type UpdateOnboardSetupFilterPresetParam = {
  id: number | string;
} & CreateOnboardSetupFilterPresetParam;

type GetOnboardSetupFilterPresetParam = {
  id: number | string;
};

export const createOnboardSetupFilterPreset = async (
  params: CreateOnboardSetupFilterPresetParam
) => {
  return (await post<OnboardSetupFilter>(`/onboard-setup/filters`, params)).data;
};

export const updateOnboardSetupFilterPreset = async (
  params: UpdateOnboardSetupFilterPresetParam
) => {
  const { id } = params;
  return (await put<OnboardSetupFilter>(`/onboard-setup/filters/${id}`, params)).data;
};

export const getOnboardSetupFilterPresets = async (params: CreateOnboardSetupFilterPresetParam) => {
  return (await get<OnboardSetupFilter[]>(`/onboard-setup/filters`, params)).data;
};

export const getOnboardSetupFilterPreset = async (params: GetOnboardSetupFilterPresetParam) => {
  const { id } = params;
  return (await get<OnboardSetupFilter>(`/onboard-setup/filters/${id}`)).data;
};
