import pick from 'lodash/pick';
import TYPES from './types';
import { selectEditting } from './selectors';
import { groupPorts } from '../utils/helpers';

const INITIAL_STATE = {
  loading: true,
  groupedPorts: [],
  route: [],
  eca_zones: [],
  misc: [],
  fuels: []
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.CALCULATE.START:
    case TYPES.FETCH.START:
    case TYPES.CHANGE_MARKET_INDEX.START:
      return {
        ...state,
        loading: true
      };
    case TYPES.FETCH.SUCCESS:
    case TYPES.CALCULATE.SUCCESS:
      return {
        ...transformData(state, payload.data),
        loading: false
      };
    case TYPES.FETCH_ROUTES.SUCCESS:
      return {
        ...state,
        route: payload
      };
    case TYPES.FETCH_ECA_ZONES.SUCCESS:
      return {
        ...state,
        eca_zones: payload
      };
    case TYPES.FETCH_ECA_ZONES.ERROR:
    case TYPES.FETCH.ERROR:
    case TYPES.CALCULATE.ERROR:
    case TYPES.CHANGE_MARKET_INDEX.ERROR:
      return {
        ...state,
        loading: false
      };
    case TYPES.REORDER:
      return {
        ...state,

        groupedPorts: payload.ports,
        trips: payload.trips
      };
    case TYPES.CLEAR_ROUTES:
      return {
        ...state,
        route: []
      };
    case TYPES.SET_LOADING:
      return {
        ...state,
        loading: payload.status
      };

    case TYPES.SAVE_STATE:
      return {
        ...state,
        saved: pick(state, ['groupedPorts', 'trips'])
      };
    case TYPES.RESTORE_STATE:
      if (!state.saved) return state;
      else
        return {
          ...state,
          ...state.saved,
          loading: false,
          saved: undefined
        };

    case TYPES.CREATE_NEW:
      return createNewEntity(state, payload.type);
    case TYPES.START_EDITTING:
      return startEditting(state, payload);
    case TYPES.STOP_EDITTING:
      return stopEditting(state);
    case TYPES.CHANGE_MARKET_INDEX.SUCCESS:
      return {
        ...state,
        ...payload.data,
        loading: false
      };

    case TYPES.CLEAR:
      return INITIAL_STATE;

    default:
      return state;
  }
};
export default reducer;

const createNewEntity = (state, type) => {
  const entityName = getEnityNameFromType(type);

  return {
    ...state,
    [entityName]: [...state[entityName], { id: 'new', isEditting: true, actions: [] }]
  };
};

const startEditting = (state, { id, type }) => {
  const entityName = getEnityNameFromType(type);
  return {
    ...state,
    [entityName]: state[entityName].map(item =>
      item.id === id ? { ...item, isEditting: true } : item
    )
  };
};

const stopEditting = state => {
  const editting = selectEditting({ estimator: state });
  if (editting !== null) {
    const { id, type } = editting;

    const entityName = getEnityNameFromType(type);
    return {
      ...state,
      [entityName]:
        id === 'new'
          ? state[entityName].filter(item => item.id !== 'new')
          : state[entityName].map(item => (item.id === id ? { ...item, isEditting: false } : item))
    };
  } else {
    return state;
  }
};

const getEnityNameFromType = type => {
  const translate = {
    port: 'groupedPorts',
    cp: 'cps',
    trip: 'trips'
  };
  return translate[type];
};

const transformData = (state, data) => {
  //Remove when the stars align
  const ports = groupPorts(data.ports, data.misc);

  return {
    ...state,
    ...data,
    cps: initializeEditableEntity(data.cps),
    groupedPorts: initializeEditableEntity(ports),
    trips: initializeEditableEntity(data.trips)
  };
};

const initializeEditableEntity = collection =>
  collection.map(item => ({ ...item, isEditting: false }));
