import TYPES from './types';

const INITIAL_STATE = {
  data: [],
  list: [],
  fetched: false
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_TOOLS_LIST.SUCCESS:
      return {
        ...state,
        list: payload,
        fetched: true
      };

    case TYPES.GET_TOOLS.SUCCESS:
      return {
        ...state,
        data: payload
      };

    case TYPES.UPDATE_TOOLS.SUCCESS:
      return {
        ...state,
        data: payload
      };

    default:
      return state;
  }
};

export default reducer;
