import React, { useEffect, useCallback, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectPaging,
  selectLoadingMore,
  selectActiveCategoryLabel,
  selectIsUnreadTabActive
} from 'store/notifications/selectors';
import { notificationTypeOverrides } from './_helpers';
import Notification from './components/Notification';

import { getNotifications } from 'store/notifications/actions';
import Loader from './Loader';

const NotificationsContainer = ({ notifications }) => {
  const notificationsContainer = useRef(null);
  const paging = useSelector(selectPaging());
  const loadingMore = useSelector(selectLoadingMore);
  const activeCategoryLabel = useSelector(selectActiveCategoryLabel);
  const isUnreadTabActive = useSelector(selectIsUnreadTabActive);

  const dispatch = useDispatch();

  const filteredNotifications = useMemo(
    () =>
      isUnreadTabActive
        ? notifications.filter(notification => !notification.read_at)
        : notifications,
    [isUnreadTabActive, notifications]
  );

  const renderNotification = notification => {
    if (!notification?.type) return null;

    const type = notification?.type;
    const Tag = notificationTypeOverrides?.[type]?.component || Notification;

    return <Tag key={notification?.data?.id} notification={notification} type={type} />;
  };

  const loadMore = useCallback(() => {
    const { clientHeight, scrollTop, scrollHeight } = notificationsContainer.current;
    const { current_page } = paging;
    const type_prefix =
      activeCategoryLabel === 'all' || activeCategoryLabel === 'per_category'
        ? undefined
        : activeCategoryLabel;

    if (scrollHeight - (scrollTop + clientHeight) <= 20) {
      dispatch(
        getNotifications({
          current_page: current_page + 1,
          per_page: 50,
          type: 'loadMore',
          type_prefix,
          ...(isUnreadTabActive ? {} : { read: false })
        })
      );
    }
  }, [notificationsContainer, activeCategoryLabel, dispatch, paging]);

  useEffect(() => {
    const container = notificationsContainer.current;

    if (paging.last_page > paging.current_page) {
      container.addEventListener('scroll', loadMore, true);
    }

    return () =>
      paging.last_page > paging.current_page
        ? container.removeEventListener('scroll', loadMore, true)
        : null;
  }, [loadMore, paging.current_page, paging.last_page]);

  return (
    <div className="notifications-container gray-scrollbar" ref={notificationsContainer}>
      {filteredNotifications.map(notification => renderNotification(notification))}
      {loadingMore ? <Loader /> : null}
    </div>
  );
};

export default NotificationsContainer;
