import PropTypes from 'prop-types';
import SvgRender from 'common/components/general/SvgRender';
import { Button } from 'reactstrap';

import widgetsDetails from 'views/dashboard/widgets/widgets-details';
import edit from 'common/assets/svg/actions/edit.svg';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { selectWidgetType } from 'store/dashboards/selectors';
import * as dashboardActions from 'store/dashboards/actions';

const UnsetWidget = ({ layout, label, id }) => {
  const widgetDetails = widgetsDetails?.[label];
  const type = useSelector(state => selectWidgetType(state, id));

  const [setWidgetSettingsForm] = useActions([dashboardActions.setWidgetSettingsForm]);

  return (
    <div
      className={`widgets-unset-settings widgets-unset-settings--${layout} text-primary d-flex flex-column align-items-center justify-content-center`}
    >
      {widgetDetails?.icon ? (
        <SvgRender className="widgets-unset-settings__icon cmb-12" src={widgetDetails?.icon} />
      ) : null}
      <div className="widgets-unset-settings__title fs-14 fw-bold">{type?.name}</div>
      {widgetDetails?.preferencesInfoText ? (
        <div
          className="widgets-unset-settings__info-text fs-12 text-violet text-center mt-1"
          dangerouslySetInnerHTML={{ __html: widgetDetails?.preferencesInfoText }}
        ></div>
      ) : null}
      <Button
        color="yellow"
        size="sm"
        className="text-primary rounded-xl d-inline-flex align-items-center justify-content-center height-24 mt-3"
        onClick={() => setWidgetSettingsForm({ isOpen: true, activeID: id })}
      >
        <SvgRender src={edit} style={{ width: 12, height: 12 }} className="me-1" />
        <strong className="fs-14 lh-1">Edit</strong>
      </Button>
    </div>
  );
};

UnsetWidget.propTypes = {
  id: PropTypes.number.isRequired, // widget id
  label: PropTypes.string.isRequired, // widget type label
  layout: PropTypes.oneOf(['card', 'table', 'sidebar']).isRequired
};

export default UnsetWidget;
