import TYPES from './types';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const initialSubProject = {
  completed: false,
  deliverable: null,
  description: null,
  end: null,
  is_archived: false,
  jobs_count: 0,
  manual_progress_calculation: false,
  progress: 0,
  responsible: null,
  responsible_id: null,
  departments: [],
  start: null,
  title: ''
};

export const INITIAL_STATE = {
  list: [],
  listLoading: false,
  projectLoading: false,
  archivedView: false,
  isCreating: false,
  isSaving: false,
  errors: {
    title: '',
    startDate: '',
    subProjects: []
  },
  project: {
    title: '',
    completed: false,
    deliverable: '',
    start: null,
    end: null,
    departments: [],
    manual_progress_calculation: false,
    responsible: null,
    responsible_id: null,
    description: '',
    progress: 0,
    subprojects: []
  },
  meta: {
    current_page: 1,
    total: 0,
    last_page: 1
  }
};

const subProjectsColors = [
  variables.shadowBlue,
  variables.orangeWeb,
  variables.darkTurquise,
  variables.deepSea,
  variables.babyBlueEyes,
  variables.aqua,
  variables.lavenderFloral,
  variables.lightPink,
  variables.coral,
  variables.turquoise
];

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.RESET_PROJECT_STATE:
      return {
        ...state,
        project: INITIAL_STATE.project
      };

    case TYPES.SET_SUB_PROJECT_VALUE:
      return {
        ...state,
        project: {
          ...state.project,
          subprojects: state.project.subprojects.map((el, i) => {
            if (i === payload.index) {
              return {
                ...el,
                ...payload.params
              };
            } else {
              return el;
            }
          })
        }
      };

    case TYPES.SET_PROJECT_ERRORS:
      return {
        ...state,
        errors: payload
      };

    case TYPES.REMOVE_SUB_PROJECT:
      return {
        ...state,
        project: {
          ...state.project,
          subprojects: state.project.subprojects.filter((el, i) => i !== payload.index)
        }
      };
    case TYPES.ADD_SUB_PROJECT:
      return {
        ...state,
        project: {
          ...state.project,
          subprojects: [
            ...state.project.subprojects,
            {
              ...initialSubProject,
              color:
                subProjectsColors[
                  state.project.subprojects?.length >= subProjectsColors.length
                    ? state.project.subprojects?.length - subProjectsColors.length
                    : state.project.subprojects?.length
                ],
              start: payload
            }
          ]
        }
      };
    case TYPES.SET_PROJECT_VALUE:
      return {
        ...state,
        project: {
          ...state.project,
          ...payload
        }
      };

    case TYPES.GET_PROJECTS.START:
      return {
        ...state,
        listLoading: true
      };

    case TYPES.GET_PROJECTS.SUCCESS:
      return {
        ...state,
        list: payload.data,
        listLoading: false,
        meta: {
          current_page: payload.meta.current_page,
          total: payload.meta.total,
          last_page: payload.meta.last_page
        }
      };

    case TYPES.GET_PROJECTS.ERROR:
      return {
        ...state,
        listLoading: false
      };

    case TYPES.GET_PROJECT.START:
      return {
        ...state,
        projectLoading: true
      };

    case TYPES.GET_PROJECT.SUCCESS:
      return {
        ...state,
        project: {
          ...payload,
          subprojects: payload.subprojects.map((e, index) => {
            return {
              ...e,
              color:
                subProjectsColors[
                  index >= subProjectsColors.length ? index - subProjectsColors.length : index
                ]
            };
          })
        },
        projectLoading: false
      };

    case TYPES.GET_PROJECT.ERROR:
      return {
        ...state,
        projectLoading: false
      };

    case TYPES.RESET_PROJECT:
      return {
        ...state,
        project: INITIAL_STATE.project
      };

    case TYPES.CREATE_PROJECT.START:
      return {
        ...state,
        isCreating: true
      };

    case TYPES.CREATE_PROJECT.SUCCESS:
    case TYPES.CREATE_PROJECT.ERROR:
      return {
        ...state,
        isCreating: false
      };

    case TYPES.EDIT_PROJECT.START:
      return {
        ...state,
        isSaving: true
      };

    case TYPES.EDIT_PROJECT.SUCCESS:
    case TYPES.EDIT_PROJECT.ERROR:
      return {
        ...state,
        isSaving: false
      };

    default:
      return state;
  }
};

export default reducer;
