import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'utils/api';

export const getRetentionRate = createAsyncThunk(
  'GET_RETENTION_RATE',
  async (params, { rejectWithValue }) => {
    try {
      const res = await get('/reports/retention-rate', params);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
