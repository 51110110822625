import React, { useEffect } from 'react';
import WidgetSettings from 'views/dashboard/components/WidgetSettings';
import ListSelect from 'common/components/selectors/ListSelect';
import VesselSelector from 'common/components/selectors/VesselSelector';
import { useActions } from 'utils/hooks';

import * as dashboardsActions from 'store/dashboards/actions';
import { Row, Col } from 'reactstrap';
import { useForm, useFormState } from 'utils/hooks';
import { booleanField, optionField } from 'common/utils/form/fieldTypes';

const Preferences = ({ id, onSave, onClose, isSubmitting }) => {
  const { formState, collectValues, loadValues } = useForm({
    entity_types: optionField({ required: true }),
    vessels: optionField(),
    update: booleanField()
  });

  const { fields, selectField } = useFormState(formState);

  const [getWidgetPreferences] = useActions([dashboardsActions.getWidgetPreferences]);

  const init = async () => {
    const preferences = await getWidgetPreferences({ widgetId: id });

    loadValues({ ...preferences });
  };

  useEffect(() => {
    init();
  }, [id]);

  const handleSubmitForm = async ({ custom_title }) => {
    const values = collectValues();

    if (!values) return;

    const params = {
      custom_title,
      vessel_ids: values.vessels?.length > 0 ? values.vessels.map(v => v.id) : [],
      entity_types: values.entity_types
    };
    onSave(params);
  };

  return (
    <WidgetSettings id={id} isSubmitting={isSubmitting} onClose={onClose} onSave={handleSubmitForm}>
      <Row>
        <Col className="text-start text-primary fs-10 fw-bold">PAGES</Col>
        <Col xs={12} className="cmt-6">
          <ListSelect
            list="chat-entity-types"
            isMulti={true}
            isAsync={false}
            placeholder="Select pages *"
            getOptionValue={option => option.type}
            getOptionLabel={option => option.name}
            onChange={selectField('entity_types')}
            {...fields.entity_types}
          />
        </Col>
      </Row>
      <Row>
        <Col className="text-start text-primary fs-10 fw-bold ">VESSELS</Col>
        <Col xs={12} className="cmt-6">
          <VesselSelector
            isMulti={true}
            isClearable
            autoFocus={false}
            placeholder="Select Vessel"
            onChange={selectField('vessels')}
            {...fields.vessels}
          />
        </Col>
      </Row>
      {/* <Row className="g-0">
        <Col xs={12}>
          <Checkbox
            value={fields.update.value}
            onChange={() => selectField('update')(!fields.update.value)}
            label="Update every 15 minutes"
          />
        </Col>
      </Row> */}
    </WidgetSettings>
  );
};

export default Preferences;
