import PropTypes from 'prop-types';
import WidgetActions from 'views/dashboard/components/WidgetActions';
import WidgetLinkTo from './WidgetLinkTo';
import WidgetLabel from './WidgetLabel';
import SvgRender from 'common/components/general/SvgRender';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import widgetsDetails from '../widgets/widgets-details';

import { useSelector } from 'react-redux';
import {
  selectWidgetTitle,
  selectWidgetCustomTitle,
  selectWidgetTypeLabel,
  selectWidgetTypeLayout,
  selectWidgetStatePagingTotal
} from 'store/dashboards/selectors';
import VesselName from './VesselName';

const WidgetTitle = ({
  id,
  linkTo,
  linkToPermissions,
  withActions = true,
  className = '',
  titleClassName = '',
  withIcon = true,
  withColoredIcon = false,
  customLabel,
  vesselName = '',
  component
}) => {
  const title = useSelector(state => selectWidgetTitle(state, id));
  const customTitle = useSelector(state => selectWidgetCustomTitle(state, id));
  const label = useSelector(state => selectWidgetTypeLabel(state, id));
  const layout = useSelector(state => selectWidgetTypeLayout(state, id));
  const totals = useSelector(state => selectWidgetStatePagingTotal(state, id));

  const icon =
    widgetsDetails?.[label]?.[
      withColoredIcon
        ? 'iconColored'
        : widgetsDetails?.[label]?.['previewCardIcon']
        ? 'previewCardIcon'
        : 'icon'
    ];

  const isCard = layout === 'card';
  const isSidebar = layout === 'sidebar';

  return (
    <div
      className={`widget__title d-flex align-items-center justify-content-between ${
        className || 'mb-1'
      } ${isSidebar ? '' : ''}`}
    >
      <div
        className={`flex-1 d-flex ${
          isCard ? 'flex-column' : 'align-items-center'
        } flex-nowrap overflow-hidden me-2 w-100p`}
      >
        {isCard && withIcon ? (
          <div className="mb-1 text-primary">
            <SvgRender src={icon} style={{ width: 24, height: 24 }} />{' '}
          </div>
        ) : null}
        <div className={`${titleClassName || 'fs-18 text-primary lh-1'} fw-bold overflow-hidden`}>
          <TextWithTooltip>{customTitle || title}</TextWithTooltip>
        </div>
        {customTitle && !isSidebar ? (
          <div
            className={`${
              !isCard ? 'border-start cms-6 cps-6' : ''
            } fs-12 text-violet narrow-light overflow-hidden`}
          >
            <TextWithTooltip>{title}</TextWithTooltip>
          </div>
        ) : null}
      </div>
      {isSidebar && withIcon ? (
        <div className="d-flex align-items-center bg-light-gray border-radius-7 p-1">
          <SvgRender
            className={`${label === 'review_process_sidebar' ? 'text-turquoise' : ''}`}
            src={icon}
            style={{ width: 15, height: 15 }}
          />
          <span className="cms-10 fw-bold text-primary">{totals}</span>
        </div>
      ) : null}
      {vesselName ? <VesselName vesselName={vesselName} /> : null}
      {customLabel ? (
        <>
          <WidgetLabel customLabel={customLabel} />
          <div className="small-separator mx-3 height-24"></div>
        </>
      ) : null}
      {component ? component : null}
      <WidgetLinkTo id={id} linkTo={linkTo} linkToPermissions={linkToPermissions} />
      {withActions ? <WidgetActions id={id} /> : null}
    </div>
  );
};

WidgetTitle.propTypes = {
  id: PropTypes.number.isRequired, // widget id
  withActions: PropTypes.bool, // show/hide actions
  vesselName: PropTypes.string, // if title has vessel
  customLabel: PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
    color: PropTypes.string
  }),
  component: PropTypes.node,
  linkTo: PropTypes.string,
  linkToPermissions: PropTypes.arrayOf(PropTypes.string)
};

export default WidgetTitle;
