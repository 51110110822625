import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

import { numberToStr } from 'common/utils/numbers';

import _get from 'lodash/get';
import Eta from './InstructedFieldETA';
import SpeedOrConsumption from './InstructedFieldSpeedConsumptions';

import { hasValue } from '../../helpers';

const InstructedWithTooltip = ({ values, field }) => {
  const { tooltipID, avoidRender } = useTooltipID('fleet-instructed');
  if (avoidRender) return null;

  const instructedFor = _get(values, field.keys.for);
  const instructedETA = _get(values, field.keys.eta);

  const instructedSpeed = _get(values, field.keys.speed);
  const instructedFOCons = _get(values, field.keys.foCons);
  const instructedGOCons = _get(values, field.keys.goCons);

  const minimumInstructedSpeed = _get(values, field.keys.minSpeed);
  const maximumInstructedFOCons = _get(values, field.keys.maxFOCons);
  const maximumInstructedGOCons = _get(values, field.keys.maxGOCons);

  const isWeatherRoutingInstruction = instructedFor === 'weather_routing_instruction';

  return (
    <>
      <div className="d-flex align-items-center w-100p mb-1 ms-1">
        <span className="fw-bold lh-12">
          {instructedFor === 'eta' ? 'Target ETA' : 'Instructed'}:
        </span>
        <span className="cms-6 lh-12" id={tooltipID}>
          {instructedFor === 'eta' ? (
            <Eta instructedETA={instructedETA} />
          ) : (
            <SpeedOrConsumption
              instructedFor={instructedFor}
              instructedSpeed={instructedSpeed}
              instructedFOCons={instructedFOCons}
              instructedGOCons={instructedGOCons}
              isWeatherRoutingInstruction={isWeatherRoutingInstruction}
            />
          )}
        </span>
      </div>
      <Tooltip
        className="max-width-none"
        target={tooltipID}
        innerClassName="max-width-none text-start"
        fade={false}
      >
        <div className="d-flex flex-column lh-16 mb-1">
          <span className="fw-bold">Instructed Speed/Consumption</span>

          {instructedFor === 'eta' ? (
            instructedETA ? (
              <span className="mt-1">
                Target ETA:
                <b className="cms-4">
                  <Eta />
                </b>
              </span>
            ) : null
          ) : isWeatherRoutingInstruction ? (
            <>
              <span className="mt-1">
                Speed:
                <b className="cms-4">Various - WOG</b>
              </span>
              <span className="mt-1">
                FO Cons:
                <b className="cms-4">Various - WOG</b>
              </span>
              <span className="mt-1">
                GO Cons:
                <b className="cms-4">Various - WOG</b>
              </span>
            </>
          ) : (
            <>
              {hasValue(instructedSpeed) ? (
                <span className="mt-1">
                  Speed:
                  <b className="cms-4">
                    {`${numberToStr(instructedSpeed, 1)} (> ${numberToStr(
                      minimumInstructedSpeed,
                      1
                    )}) kt`}
                  </b>
                </span>
              ) : null}
              {hasValue(instructedFOCons) ? (
                <span className="mt-1">
                  FO Cons:
                  <b className="cms-4">
                    {`${numberToStr(instructedFOCons, 2)} (< ${numberToStr(
                      maximumInstructedFOCons,
                      2
                    )}) mt/d`}
                  </b>
                </span>
              ) : null}
              {hasValue(instructedGOCons) ? (
                <span className="mt-1">
                  GO Cons:
                  <b className="cms-4">
                    {`${numberToStr(instructedGOCons, 2)} (< ${numberToStr(
                      maximumInstructedGOCons,
                      2
                    )}) mt/d`}
                  </b>
                </span>
              ) : null}
            </>
          )}
        </div>
      </Tooltip>
    </>
  );
};

export default InstructedWithTooltip;
