import { optionField, stringField } from 'common/utils/form/fieldTypes';

const config = {
  status_id: stringField({ required: true }),
  from: stringField({ required: true, email: true }),
  to: optionField({ required: true, initialValue: [] }),
  cc: optionField({ initialValue: [] }),
  bcc: optionField({ initialValue: [] }),
  subject: stringField({ required: true }),
  body: stringField({ required: true }),
  attachments: optionField({ initialValue: [] }),
  synced_office_attachments: optionField({ initialValue: [] }),
  synced_vessel_attachments: optionField({ initialValue: [] }),
  email_template_id: optionField()
};

export default config;
