import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_PORT'),
  ...asyncTypes('GET_PORT_SUMMARY'),
  ...asyncTypes('GET_PORT_TIMELINE'),
  ...asyncTypes('GET_PORT_ITINERARY'),
  ...asyncTypes('SET_PORT_COVID_RESTRICTIONS'),
  ...asyncTypes('GET_PORT_WEATHER'),
  ...asyncTypes('GET_BUNKERING_PRICES'),
  ...asyncTypes('GET_PORT_AGENTS'),
  ...asyncTypes('GET_PORT_ACTIONS'),
  SET_PORT_NAME: 'SET_PORT_NAME'
};

export default TYPES;
