import PropTypes from 'prop-types';
import WidgetPersonsList from 'views/dashboard/components/WidgetPersonsList';
import WidgetPersonType from 'views/dashboard/components/WidgetPersonType';

import birthdayIcon from 'assets/svg/contacts/birthday.svg';
import SvgRender from 'common/components/general/SvgRender';
import { getInitialSingleValue } from 'common/utils/lists';
import { useEffect, useState } from 'react';
import {
  selectWidgetPreferences,
  selectIsWidgetStateInitialized
} from 'store/dashboards/selectors';
import { useSelector } from 'react-redux';
import moment from 'moment';
import EmptyState from './EmptyState';

const Current = ({ id }) => {
  const [data, setData] = useState([]);
  const preferences = useSelector(state => selectWidgetPreferences(state, id));
  const initialized = useSelector(state => selectIsWidgetStateInitialized(state, id));

  const { type } = preferences;

  const getInitialValues = async () => {
    const { type, rank_ids, status_ids } = preferences;

    const isCrewType = type === 'crew';

    if (type) {
      const params = {
        filters: [
          {
            name: 'next_birthday',
            value: moment().format('YYYY-MM-DD'),
            operation: '='
          },
          isCrewType && rank_ids?.length
            ? {
                name: 'rank',
                operation: 'oneOf',
                value: rank_ids
              }
            : undefined,
          isCrewType && status_ids?.length
            ? {
                name: 'status',
                operation: 'oneOf',
                value: status_ids
              }
            : undefined
        ]
      };

      const res = await getInitialSingleValue(`/dashboards/widgets/${id}`, params);

      if (res) setData(res);
      else setData([]);
    }
  };

  useEffect(() => {
    if (initialized) getInitialValues();
  }, [preferences, id, initialized]);

  return (
    <div className={`widget-birthday__current widget-birthday__current--${type}`}>
      <div className="d-flex align-items-center cpb-12 cmb-12 border-bottom">
        <WidgetPersonType type={type} className={'cme-12'} />
        <strong>{type === 'person' ? 'Office' : 'Crew'}</strong>
        <div className={`text-violet ms-1 fw-medium`}>
          <span>Today {moment().format('DD MMM, YYYY')}</span>
        </div>
      </div>
      {data?.length > 0 ? (
        <>
          <div className="widget-birthday__current-content gray-scrollbar">
            <WidgetPersonsList data={data?.length > 0 ? data : []} />
          </div>
          <SvgRender src={birthdayIcon} className={`birthday-icon`} />
        </>
      ) : (
        <EmptyState label={'No Birthdays Today'} />
      )}
    </div>
  );
};

Current.propTypes = {
  id: PropTypes.number.isRequired // widget id
};

export default Current;
