import TYPES from './types';
// import ACCOUNT_TYPES from 'store/account/types';
import AUTH_TYPES from 'store/auth/types';
import FAVORITE_TYPES from 'store/favorites/types';

import menuItems from 'components/layouts/sidebar/panel/menu/_constants';
import settingsItems from 'components/layouts/sidebar/panel/settings/_constants';

const createItemSubmenuLabels = item => {
  return item?.submenu
    ? item.submenu
        .filter(s => s.path)
        .map(s => {
          if (s.groupPath) {
            return s.groupPath;
          }

          const paths = s.path?.split('/');

          return `/${paths?.[1]}${paths?.[2] ? `/${paths?.[2]}` : ''}`;
        })
    : item.label;
};

const INITIAL_STATE = {
  isPanelOpen: false,
  activePanel: null,
  isOtherMenuCollapse: false,
  menu: {
    items: menuItems.map(i => {
      return {
        ...i,
        label: createItemSubmenuLabels(i)
      };
    })
  },
  settings: {
    items: settingsItems.map(i => {
      return {
        ...i,
        label: createItemSubmenuLabels(i)
      };
    })
  },
  recents: [],
  savedItems: {
    fetched: false,
    types: [],
    data: [],
    paging: {
      total: 0,
      per_page: 20,
      current_page: 1,
      last_page: 1
    }
  }
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.TOGGLE_PANEL:
      const isPanelOpen =
        payload && payload !== state.activePanel && state.isPanelOpen ? true : !state.isPanelOpen;

      return {
        ...state,
        isPanelOpen,
        activePanel: !isPanelOpen ? null : payload
      };

    case TYPES.COLLAPSE_MENU:
      return {
        ...state,
        isOtherMenuCollapse: !state.isOtherMenuCollapse
      };

    case TYPES.GET_SAVED_ITEMS.START:
      const { sorting, types } = payload.params;

      return {
        ...state,
        savedItems: {
          ...state.savedItems,
          fetched: true,
          types: types ? types : [],
          paging: {
            ...state.savedItems.paging,
            current_page: payload.params.current_page
          },
          sorting: sorting ? sorting : state.sorting
        }
      };

    case TYPES.GET_SAVED_ITEMS.SUCCESS:
      const { meta, data } = payload;

      return {
        ...state,
        savedItems: {
          ...state.savedItems,
          data,
          paging: {
            ...meta
          }
        }
      };

    case TYPES.DELETE_SAVED_ITEM.START:
      return {
        ...state,
        savedItems: {
          ...state.savedItems,
          data: state.savedItems.data.filter(i => i.id !== payload.id)
        },
        recents: state.recents.filter(recent => recent.id !== payload.id)
      };

    case TYPES.GET_RECENT_ITEMS.SUCCESS:
      return {
        ...state,
        recents: payload
      };

    case AUTH_TYPES.LOGOUT_CLEANUP:
      return {
        ...state,
        isPanelOpen: false,
        activePanel: null
      };

    case FAVORITE_TYPES.TOGGLE_FAVORITE.SUCCESS:
      return {
        ...state,
        savedItems: {
          ...state.savedItems,
          data: state.savedItems.data.map(d => {
            if (d.id === payload.favourite_id) {
              return {
                ...d,
                favourites_count: d.favourites_count === 0 ? 1 : 0
              };
            }
            return d;
          })
        },
        recents: state.recents.map(recent =>
          recent.id === payload.favourite_id
            ? { ...recent, favourites_count: recent.favourites_count === 0 ? 1 : 0 }
            : recent
        )
      };

    default:
      return state;
  }
};

export default reducer;
