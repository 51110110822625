import React from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import ShadowBox from 'common/components/general/ShadowBox';
import GraphLegend from './GraphLegend';

import D3Graph from 'common/components/graph/base';
import useD3Events from 'common/components/graph/base/hooks/useD3Events';

const Graph = ({ chartData, loading, tableHasData }) => {
  const data = _get(chartData, 'data.graph', {});

  const timeseriesConfig = {
    isTimeseries: true,
    dateFormat: '%d/%m/%Y'
  };

  const graphEvents = useD3Events(timeseriesConfig);

  const hoverStyling = {
    hoverRadius: 4
  };

  const plots = [
    {
      label: 'Actual TCE Net',
      prefix: 'TCE',
      type: ['line', 'scatter'],
      style: {
        lineWidth: 3,
        ...hoverStyling
      },
      dataKey: 'tce_net'
    },
    {
      label: 'Estimated TCE Results',
      prefix: ' ',
      type: ['line', 'scatter'],
      style: {
        lineWidth: 3,
        lineDashArray: '3,2',
        ...hoverStyling
      },
      dataKey: 'estimated_tce_net',
      xAxis: 'date'
    },
    {
      label: 'Market TCE Net Adjusted',
      prefix: ' ',
      type: ['line', 'scatter'],
      style: {
        lineWidth: 3,
        lineColor: '#96bafb',
        scatterBackgroundColor: '#96bafb',
        ...hoverStyling
      },
      dataKey: 'market_tce_adjusted_net'
    }
  ];

  return loading ? null : (
    <ShadowBox className="mt-1 tce-result-graph">
      {tableHasData ? (
        _isEmpty(data) ? (
          <div className="big-vessel-logo d-flex align-items-center justify-content-center fw-medium text-violet fs-22 h-100p">
            No data for the selected vessel.
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-between py-3 ps-3 pe-4 h-100p overflow-hidden">
            <div className="d-flex flex-wrap">
              <GraphLegend textClassName="me-4" title="Actual TCE Net" solid color="primary" />
              <GraphLegend textClassName="me-4" title="Estimated TCE Results" color="primary" />
              <GraphLegend title="Market TCE Net Adjusted" solid color="french-sky-blue" />
            </div>

            <D3Graph
              events={graphEvents}
              plots={plots}
              schema={{ ...timeseriesConfig, xAxis: 'date' }}
              axesConfig={{ y: { ticks: 0 }, x: { ticks: 5 } }}
              style={{ marginLeft: 32 }}
              data={data}
              tooltipOptions={{ showX: true }}
            />
          </div>
        )
      ) : (
        <div className="big-vessel-logo d-flex align-items-center justify-content-center fw-medium text-violet fs-22 h-100p">
          No data for the selected vessel.
        </div>
      )}
    </ShadowBox>
  );
};

export default Graph;
