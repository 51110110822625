import React from 'react';

import _get from 'lodash/get';
import FooterRow from './FooterRow';

const Footer = ({ tceResultsAverages, widget }) => {
  const averages = _get(tceResultsAverages, 'data.averages[0]', {});
  const per_year = _get(tceResultsAverages, 'data.per_year', []);

  return (
    <div className="footer-content overflow-hidden py-0 border-top">
      <FooterRow data={averages} rowName="Averages" isAverage />
      {per_year.map(perYear => (
        <FooterRow data={perYear} rowName={`YTD ${perYear.key}`} />
      ))}
    </div>
  );
};

export default Footer;
