import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'utils/hooks';

import GalleryHeader from './components/GalleryHeader';
import GalleryCenterContainer from './components/GalleryCenterContainer';
import GalleryFooter from './components/GalleryFooter';
import SvgRender from 'common/components/general/SvgRender';

import {
  selectGalleryFiles,
  selectGalleryIsOpen,
  selectedGalleryFile
} from 'common/components/gallery/store/selectors';
import * as galleryActions from 'common/components/gallery/store/actions';

import arrowIcon from 'common/assets/svg/common/arrows/arrow-filters.svg';

const Gallery = () => {
  const files = useSelector(selectGalleryFiles);
  const isOpen = useSelector(selectGalleryIsOpen);
  const selectedFile = useSelector(selectedGalleryFile);

  const [toggleGallery, setSelectedFile] = useActions([
    galleryActions.toggleGallery,
    galleryActions.setSelectedFile
  ]);

  const [isGalleryHovered, setIsGalleryHovered] = useState(false);

  const setIsOpen = value => {
    toggleGallery(value);
  };

  const currentIndex =
    selectedFile && files.length ? files.findIndex(file => file.id === selectedFile.id) : 0;

  useEffect(() => {
    if (files.length) {
      setSelectedFile(files[0]);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  });

  const onClickItem = index => {
    if (files[index]) {
      setSelectedFile(files[index]);
    }
  };

  const onNextClick = () => {
    if (files[currentIndex + 1]) {
      setSelectedFile(files[currentIndex + 1]);
    }
  };

  const onPrevClick = () => {
    if (files[currentIndex - 1]) {
      setSelectedFile(files[currentIndex - 1]);
    }
  };

  const onKeydown = event => {
    if (event) {
      if (event.key === 'Escape') {
        setIsOpen(false);
      } else if (event.keyCode === 37) {
        onPrevClick();
      } else if (event.keyCode === 39) {
        onNextClick();
      }
    }
  };

  return isOpen ? (
    <div className="gallery__background">
      <div
        className="gallery"
        onMouseEnter={() => setIsGalleryHovered(true)}
        onMouseLeave={() => setIsGalleryHovered(false)}
      >
        <GalleryHeader setIsOpen={setIsOpen} file={selectedFile} />

        <div className="gallery__center">
          {files.length ? (
            <div
              onClick={onPrevClick}
              className="gallery__center--arrow gallery__center--arrow-left"
            >
              {currentIndex !== 0 ? (
                <SvgRender src={arrowIcon} style={{ width: 14, height: 16 }} />
              ) : null}
            </div>
          ) : null}

          <GalleryCenterContainer isGalleryHovered={isGalleryHovered} file={selectedFile} />

          {files.length ? (
            <div
              onClick={onNextClick}
              className="gallery__center--arrow gallery__center--arrow-right"
            >
              {currentIndex !== files.length - 1 ? (
                <SvgRender src={arrowIcon} style={{ width: 14, height: 16 }} />
              ) : null}
            </div>
          ) : null}
        </div>

        <GalleryFooter onClickItem={onClickItem} files={files} selectedFile={selectedFile} />
      </div>
    </div>
  ) : null;
};

export default Gallery;
