import React, { useState } from 'react';
import clsx from 'clsx';

import { Button, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';
import SharedByLabel from 'common/components/general/SharedByLabel';
import Form from './form';

import useRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { useDrawer } from 'common/components/drawer';
import { parseQueryParams } from 'utils/urls';

import { goToSavedTable } from './helpers';
import { setTableSearch } from '@/store/tables/actions-ts.ts';
import { updateTableSearch } from 'store/tables/actions';
import {
  selectSearchShares,
  selectSearchSaving,
  selectTableDefaultRequestParams
} from 'store/tables/selectors';
import { updateOnboardSetupFilterPreset } from '@/api/setup-onboard/api.ts';
import arrowBtn from 'assets/svg/common/arrow.svg';
import { useAppDispatch } from '@/store/hooks';

const EditSearch = ({
  setModalOpened,
  tableSearch,
  queryChanged,
  label,
  account,
  isSetupOnboard
}) => {
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const { history } = useRouter();
  const drawer = useDrawer();
  const searchSaving = useSelector(selectSearchSaving);
  const dispatch = useAppDispatch();

  const searchShares = useSelector(selectSearchShares);

  const defaultTableParams = useSelector(selectTableDefaultRequestParams);

  const shares = {
    parties: searchShares?.parties?.map(e => e.id) || [],
    departments: searchShares?.departments?.map(e => e.id) || []
  };

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex align-items-center">
        {queryChanged ? <div className="ms-1 fs-14 text-gray-400">- Edited</div> : null}
        {isSetupOnboard ? null : (
          <SharedByLabel
            shouldShowName={tableSearch.party_id !== account.id}
            name={tableSearch.owner?.full_name}
            sharedWithNumber={tableSearch.shares?.length}
            wrapperClassName="ms-3 ps-3 border-start height-32"
          />
        )}
      </div>
      <div className="d-flex align-items-center ms-1">
        {queryChanged ? (
          <>
            <Button
              className={clsx('ms-2', {
                'fw-light': isSetupOnboard,
                'box-shadow-none': isSetupOnboard
              })}
              color={isSetupOnboard ? 'anti-flash-white-2' : 'grey'}
              type="button"
              disabled={searchSaving}
              onClick={async () => {
                const { searchId, filters, sorting, ...rest } = parseQueryParams(
                  history.location.search
                );

                const params = {
                  ...rest,
                  sorting: !sorting ? null : sorting,
                  filters: !filters ? null : filters,
                  id: searchId
                };

                if (isSetupOnboard) {
                  const res = await updateOnboardSetupFilterPreset({
                    ...params,
                    name: tableSearch.name,
                    entity_type: label
                  });

                  dispatch(setTableSearch(res));
                } else {
                  await dispatch(
                    updateTableSearch({
                      ...params,
                      shares: shares,
                      table_label: label
                    })
                  );
                }
              }}
            >
              Save
            </Button>
            <Dropdown
              direction="down"
              isOpen={dropdownOpened}
              toggle={() => setDropdownOpened(!dropdownOpened)}
              disabled={searchSaving}
            >
              <DropdownToggle
                className={clsx({
                  'cms-2': isSetupOnboard,
                  'cme-4': isSetupOnboard,
                  'cpx-6': isSetupOnboard,
                  'box-shadow-none': isSetupOnboard
                })}
                color={isSetupOnboard ? 'anti-flash-white-2' : 'dropdown-toggler'}
              >
                <SvgRender src={arrowBtn} style={{ width: 10, height: 10 }} />
              </DropdownToggle>
              <DropdownMenu className="ms-2" end={true} strategy="fixed">
                <DropdownItem className="pointer" onClick={() => setModalOpened(true)}>
                  Save as
                </DropdownItem>
                <DropdownItem
                  className="pointer"
                  onClick={() => goToSavedTable(history, defaultTableParams, tableSearch)}
                >
                  Discard changes
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            &nbsp;
          </>
        ) : null}
        <Button
          className={clsx({ 'fw-light': isSetupOnboard, 'box-shadow-none': isSetupOnboard })}
          color={isSetupOnboard ? 'anti-flash-white-2' : 'grey'}
          type="button"
          onClick={() => drawer.toggle()}
        >
          Manage
        </Button>
      </div>

      <Form
        drawer={drawer}
        tableSearch={tableSearch}
        isSetupOnboard={isSetupOnboard}
        label={label}
      />
    </div>
  );
};

export default EditSearch;
