import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import { displayReportDocumentTitle } from '@/views/reports/helpers.js';

const ReportsCrew = React.lazy(() => retry(() => import('@/views/reports/crew/index.jsx')));
const ReportsVoyage = React.lazy(() => retry(() => import('@/views/reports/voyages/index.jsx')));
const ReportsPms = React.lazy(() => retry(() => import('@/views/reports/pms/index.jsx')));
const ReportsEvent = React.lazy(() => retry(() => import('@/views/reports/events/index.jsx')));
const PerformanceReports = React.lazy(() =>
  retry(() => import('@/views/reports/performance/index.jsx'))
);
const ReportsEnvironmental = React.lazy(() =>
  retry(() => import('@/views/reports/environmental/index.jsx'))
);

const PurchasingReports = React.lazy(() =>
  retry(() => import('@/views/reports/purchasing/index.jsx'))
);
const AccountingReports = React.lazy(() =>
  retry(() => import('@/views/reports/accounting/index.tsx'))
);

const reports = [
  {
    path: `${paths.reports_crew}/:report(promotions)/:type(past)`,
    component: ReportsCrew,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.crew.reports.view],
    documentTitle: 'Crew | Past Promotions'
  },
  {
    path: [
      `${paths.reports_crew}/:report(promotions|retention-rate|intertanko-retention-rate|crew-change-logs|evaluations|trainings|training-needs|vaccination-report|newly-hired|onboard-nationalities|overlapping-days|ticketing|not-for-reemployment|on-signer-vessel-agent-statistics|vessel-agent-evaluation-statistics|crew-debriefing|crew-promotions-contracts|crew-promotions-people|wages|crew-promotions-people)?`,
      `${paths.reports_crew}/:report(crew-medical-statistics)?/:tab(list|analytics)?`
    ],
    component: ReportsCrew,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.crew.reports.view],
    documentTitle: (state, { location }) =>
      `Crew ${displayReportDocumentTitle(location.pathname, paths.reports_crew, state, 'crew')}`
  },

  {
    path: paths.reports_voyage,
    component: ReportsVoyage,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.voyages.reports.view],
    documentTitle: 'Voyages Reports'
  },
  {
    path: `${paths.reports_voyage}/:report(cii-fleet-year|ports-and-cargoes|running-cps-redelivery-dates|fixtures|vessel-tce-results|port-documents|stoppages|agents-appointed)?`,
    component: ReportsVoyage,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.voyages.reports.view],
    documentTitle: (state, { location }) =>
      `Voyages ${displayReportDocumentTitle(
        location.pathname,
        paths.reports_voyage,
        state,
        'voyage'
      )}`
  },
  {
    path: paths.reports_pms,
    component: ReportsPms,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.pms.reports.view],
    documentTitle: 'PMS Reports'
  },
  {
    path: `${paths.reports_pms}/:report(overdue-jobs|job-statistics|class-related-components|running-hours-summary|main-engine-piston-and-cylinder-liner-wear-down|tanks-condition-summary|megger-and-vibration-report-for-motors|critical-equipment-performance-test-and-data)`,
    component: ReportsPms,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.pms.reports.view],
    documentTitle: (state, { location }) =>
      `PMS ${displayReportDocumentTitle(location.pathname, paths.reports_pms, state, 'pms')}`
  },
  {
    path: `${paths.reports_event}/:report(attendance-events|vetting-overview|vessel-overall-condition|vetting-statistics|underwater-services|underwater-services-fleet-report|lubricant-oil-analysis|root-cause-analysis-statistics|audits-and-inspections-statistics|risk-assessment-statistics)?`,
    component: ReportsEvent,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.events.reports.view],
    documentTitle: (state, { location }) =>
      `Events ${displayReportDocumentTitle(
        location.pathname,
        paths.reports_event,
        state,
        'events'
      )}`
  },
  {
    path: [
      `${paths.reports_performance}`,
      `${paths.reports_performance}/:report(analytics|ams-dashboard|noon-reports-technical-overview|lubricants-supply-monitoring|lubricants-remaining-days-estimator|fresh-water-generator-performance|fresh-water-generator-performance-fleet|stern-tube-report|captain-reports-bunkers|weather-analysis|daily-fleet-performance-report|lubricants-fleet-consumption)/:vesselID?`,
      `${paths.reports_performance}/:report(analytics)/:vesselID?/:subtab?`,
      `${paths.reports_performance}/:report(analytics)/:vesselID?/:subtab?/:panel?`
    ],
    component: PerformanceReports,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: (state, { location }) =>
      `Performance ${displayReportDocumentTitle(
        location.pathname,
        paths.reports_performance,
        state,
        'performance'
      )}`,
    permissions: [permissions.office.performance.reports.view]
  },
  {
    path: [
      `${paths.reports_environmental}`,
      `${paths.reports_environmental}/:report(cii-reference-lines|vessel-cii-profile|cii-fleet-running-legs|mrv|imo-dcs|port-statement-bunkering-and-bunker-survey|cii-fleet-running-cps)`
    ],
    component: ReportsEnvironmental,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.performance.environmental.view],
    documentTitle: (state, { location }) =>
      `Enviromental ${displayReportDocumentTitle(
        location.pathname,
        paths.reports_environmental,
        state,
        'environmental'
      )}`
  },
  {
    path: [`${paths.reports_environmental}/:report(vessel-cii-profile)/:vesselId`],
    component: ReportsEnvironmental,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.performance.environmental.view],
    documentTitle: `Enviromental | Vessel Cii Profile`
  },
  {
    path: [
      `${paths.reports_purchasing}`,
      `${paths.reports_purchasing}/:report(budget|items-ordered|requested-but-not-delivered)`
    ],
    component: PurchasingReports,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: (state, { location }) =>
      `Purchasing ${displayReportDocumentTitle(location.pathname, paths.reports_purchasing)}`,
    permissions: [permissions.office.purchasing.reports.view]
  },
  {
    path: [
      `${paths.reports_accounting}`,
      `${paths.reports_accounting}/:report(budgeting-report)/:id?`
    ],
    component: AccountingReports,
    documentTitle: (state, { location }) =>
      `Accounting ${displayReportDocumentTitle(location.pathname, paths.reports_accounting)}`,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.accounting.reports.view]
  }
];

export default reports;
