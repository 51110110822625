import { RootState } from '@/store';
import { createSelector } from 'reselect';
import { Supplier, SupplierItem } from '@/common/types/purchasing';

const getKey = (_: RootState, key: string | number) => key;

const selectRequisitionSummaryReducer = (state: RootState) => state.purchasing.requisitionSummary;

export const selectRequisitionSuppliers = (state: RootState) =>
  selectRequisitionSummaryReducer(state).suppliers;

type SelectRequisitionSupplier = Supplier & SupplierItem;

export const selectRequisitionSupplier = createSelector(
  [selectRequisitionSuppliers, getKey],
  (suppliers: SelectRequisitionSupplier[], supplierRequisitionID: string | number) =>
    suppliers.find(s => s.id === supplierRequisitionID)
);

export const selectTotalDiscountedPriceBaseCurrencyEquivalent = (
  state: RootState,
  supplierRequisitionID: number
) =>
  selectRequisitionSupplier(state, supplierRequisitionID)
    ?.total_discounted_price_base_currency_equivalent;

export const selectTotalProposedPriceBaseCurrencyEquivalent = (
  state: RootState,
  supplierRequisitionID: number
) =>
  selectRequisitionSupplier(state, supplierRequisitionID)
    ?.total_proposed_price_base_currency_equivalent;

export const selectTotalApprovedPriceBaseCurrencyEquivalent = (
  state: RootState,
  supplierRequisitionID: number
) =>
  selectRequisitionSupplier(state, supplierRequisitionID)
    ?.total_approved_price_base_currency_equivalent;
