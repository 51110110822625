import React, { Suspense } from 'react';

import { Switch, Redirect } from 'react-router-dom';
import AppAlerts from 'common/components/alerts/AppAlerts';
import PageLoader from 'common/components/general/PageLoader';
import ContentWrapper from 'components/layouts/ContentWrapper';
import RenderRoute from './components';

import NotFound from 'views/NotFound';
import Unauthorized from 'views/Unauthorized';

import authRoutes from './routes/auth';
import dashboardRoutes from './routes/dashboard';
import estimatorRoutes from './routes/estimations';
import personsRoutes from './routes/persons';
import companiesRoutes from './routes/companies';
import crewRoutes from './routes/crew';
import vesselsRoutes from './routes/vessels';
import voyagesRoutes from './routes/voyages';
import marketIndexesRoutes from './routes/market_indexes';
import bunkerPricesRoutes from './routes/bunker_prices';
import captainReports from './routes/captain_reports';
import fleetComparisonRoutes from './routes/comparison';
import jobsRoutes from './routes/jobs';
import pmsRoutes from './routes/pms';
import projectsRoutes from './routes/projects';
import portRoutes from './routes/ports';
import manualsRoutes from './routes/manuals';
import settingsRoutes from './routes/settings';
import theFleetRoutes from './routes/the_fleet';
import purchasingRoutes from './routes/purchasing';
import eventsRoutes from './routes/events';
import reportRoutes from './routes/reports';
import formRoutes from './routes/forms';
import riskAssessmentRoutes from './routes/risk_assessment';
import filesystemRoutes from './routes/filesystem';
import itRoutes from './routes/it';
import mgaRoutes from './routes/mga';
import accountingRoutes from './routes/accounting';
import performanceRoutes from './routes/performance';
import daRoutes from './routes/port_da_cases';
import freightHireRoutes from './routes/freight_hire';

import { ConnectedRouter } from 'connected-react-router';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from 'store/auth/selectors';

import paths from 'routing/routes/_paths';

/* 
Route Props:
- pageTitle: *optional* The <PageTitle> component will be rendered without having to add manually in the component
- documentTitle: *Required* The page document title (<PageDocumentTitle>)
*/

const appRoutes = [
  ...authRoutes,
  ...dashboardRoutes,
  ...estimatorRoutes,
  ...personsRoutes,
  ...companiesRoutes,
  ...crewRoutes,
  ...vesselsRoutes,
  ...voyagesRoutes,
  ...marketIndexesRoutes,
  ...bunkerPricesRoutes,
  ...portRoutes,
  ...captainReports,
  ...jobsRoutes,
  ...pmsRoutes,
  ...settingsRoutes,
  ...fleetComparisonRoutes,
  ...projectsRoutes,
  ...manualsRoutes,
  ...theFleetRoutes,
  ...purchasingRoutes,
  ...eventsRoutes,
  ...reportRoutes,
  ...formRoutes,
  ...itRoutes,
  ...riskAssessmentRoutes,
  ...filesystemRoutes,
  ...mgaRoutes,
  ...accountingRoutes,
  ...performanceRoutes,
  ...daRoutes,
  ...freightHireRoutes
];

const AppRouter = props => {
  const authenticated = useSelector(selectIsAuthenticated);

  return (
    <ConnectedRouter history={props.history}>
      <ContentWrapper routes={appRoutes}>
        <Suspense fallback={<PageLoader isLoading={true} />}>
          <Switch>
            {appRoutes.map((route, i) => (
              <RenderRoute authenticated={authenticated} key={i} strict {...route} />
            ))}

            <RenderRoute
              type="default"
              documentTitle="Unauthorized"
              path={paths.unauthorized}
              exact
              component={Unauthorized}
            />
            <RenderRoute
              type="default"
              documentTitle="Not Found"
              path={paths.not_found}
              exact
              component={NotFound}
            />

            <Redirect to={paths.not_found} />
          </Switch>
        </Suspense>
        <AppAlerts />
      </ContentWrapper>
    </ConnectedRouter>
  );
};

export default AppRouter;
