import { useSelector } from 'react-redux';

import WidgetDefaultCard from 'views/dashboard/components/WidgetDefaultCard';
import paths from 'routing/routes/_paths';

import { selectWidgetStateData } from 'store/dashboards/selectors';

const EventsCardWidget = ({ id }) => {
  const data = useSelector(state => selectWidgetStateData(state, id));

  return <WidgetDefaultCard id={id} count={data.events_count} linkTo={paths.events} />;
};

export default EventsCardWidget;
