import React from 'react';
import retry from '@/common/utils/lazy-retry.js';
import paths from '@/routing/routes/_paths.js';

const TheFleet = React.lazy(() => retry(() => import('@/views/the-fleet/index.jsx')));

const theFleet = [
  {
    path: `/`,
    component: TheFleet,
    app: true,
    appClass: 'the-fleet-wrapper',
    type: 'private',
    exact: true,
    documentTitle: 'The Fleet'
  },
  {
    path: `${paths.position_list}`,
    component: TheFleet,
    app: true,
    appClass: 'the-fleet-wrapper',
    type: 'private',
    exact: true,
    documentTitle: 'Position List'
  },
  {
    path: `${paths.fleet_crew}`,
    component: TheFleet,
    app: true,
    appClass: 'the-fleet-wrapper',
    type: 'private',
    exact: true,
    documentTitle: 'Crew Top 4'
  }
];

export default theFleet;
