import { createSelector } from 'reselect';
import { selectAccountId } from 'store/account/selectors';

const selectPersonsReducer = state => state.persons;

/* Profile Data  */
export const selectPersonProfile = state => selectPersonsReducer(state)?.profile;

export const selectPersonTitle = state => selectPersonProfile(state)?.full_name || '';

export const selectPersonId = state => selectPersonProfile(state)?.id;

export const selectPersonExternalIds = state => selectPersonProfile(state)?.external_ids;
/* --- */

/* Sensitive Info */
export const selectPersonSensitiveInfo = createSelector(
  selectPersonsReducer,
  persons => persons.sensitiveInfo
);

export const selectPersonSensitiveInfoAccessLogs = createSelector(
  selectPersonSensitiveInfo,
  sensitiveInfo => sensitiveInfo.access_logs
);

export const selectPersonSensitiveInfoAccessStatus = createSelector(
  selectPersonSensitiveInfo,
  sensitiveInfo => sensitiveInfo.accessStatus
);

export const selectPersonSensitiveInfoAccessVessels = createSelector(
  selectPersonSensitiveInfo,
  sensitiveInfo => sensitiveInfo.access_vessels
);

export const selectPersonSensitiveInfoIsCrew = createSelector(
  selectPersonSensitiveInfo,
  sensitiveInfo => sensitiveInfo.isCrew
);
/* --- */

/* Access */
export const selectPersonSecurity = state => selectPersonsReducer(state)?.security;

export const selectPersonAccessIsGranted = state => selectPersonProfile(state)?.can_login;

export const selectPersonAccessIsRevoked = state =>
  !!selectPersonSecurity(state)?.access_revoked_at && !selectPersonAccessIsGranted(state);

export const selectPersonLoginCredentials = state => selectPersonSecurity(state)?.login_credentials;

export const selectPersonUsername = state => selectPersonLoginCredentials(state)?.username;

const selectPersonPreConfiguredTwoFactorAuthenticationMethod = state =>
  selectPersonLoginCredentials(state)?.preconfigured_2fa_method; // preconfigured method is set by the administrator

const selectPersonTwoFactorAuthenticationMethod = state =>
  selectPersonSecurity(state)?.two_factor_authentication_method; // the configured 2fa set by the user

export const selectPersonConfiguredTwoFactorAuthenticationMethod = state =>
  selectPersonTwoFactorAuthenticationMethod(state)?.method;

export const selectPersonTwoFactorAuthenticationMethodType = state =>
  selectPersonConfiguredTwoFactorAuthenticationMethod(state) ||
  selectPersonPreConfiguredTwoFactorAuthenticationMethod(state); // when the user has no configured method, we display the preconfigured method as fallback

export const selectPersonTwoFactorAuthenticationMethodDisplayInfo = state =>
  selectPersonTwoFactorAuthenticationMethod(state)?.display_info;

export const selectPersonHasAccess = state => selectPersonAccessIsGranted(state);

export const selectIsCurrentUserProfile = state => selectPersonId(state) === selectAccountId(state); // check if the active person's profile is the same as the logged-in user
/* -- */

/* E-Sign */
export const selectPersonSignature = state => selectPersonsReducer(state)?.signature;
/* -- */
