import PropTypes from 'prop-types';

const VesselName = ({ vesselName }) => {
  return (
    <div className="pe-3 me-3 border-end">
      <div className="bg-white cpy-4 px-1 border-radius-base">
        <div className="fs-10 fw-bold text-primary">{vesselName}</div>
      </div>
    </div>
  );
};

VesselName.propTypes = {
  vesselName: PropTypes.string
};

export default VesselName;
