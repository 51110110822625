import React from 'react';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import paths from '@/routing/routes/_paths.js';

const BunkerPrices = React.lazy(() => retry(() => import('@/views/bunker-prices/index.jsx')));
const BunkerMapView = React.lazy(() =>
  retry(() => import('@/views/bunker-prices/bunker-map/index.jsx'))
);

const bunkerPrices = [
  {
    path: `${paths.bunker_prices}`,
    component: BunkerPrices,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.voyages.bunker_prices.view],
    documentTitle: 'Bunker Prices'
  },
  {
    path: `${paths.bunker_prices}/map`,
    component: BunkerMapView,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.voyages.bunker_prices.view],
    documentTitle: 'Bunker Prices'
  }
];

export default bunkerPrices;
