import React from 'react';
import paths from '@/routing/routes/_paths.js';
import permissions from '@/common/utils/permissions/constants.js';
import retry from '@/common/utils/lazy-retry.js';

const Filesystem = React.lazy(() => retry(() => import('@/views/filesystem/index.jsx')));

const routes = [
  {
    path: `${paths.filesystem}`,
    component: Filesystem,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.files.view],
    documentTitle: 'Files'
  },
  {
    path: `${paths.filesystem}/:id`,
    component: Filesystem,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.files.view],
    documentTitle: 'Files'
  },
  {
    path: `${paths.filesystem}/folder/:id`,
    component: Filesystem,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.files.view],
    documentTitle: 'Files'
  }
];

export default routes;
