import React, { useMemo } from 'react';
import Priority from 'common/components/general/Priority';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import CommunicationsBox from 'common/components/purchasing/requisition/communications';
import NamesInTooltip from 'common/components/general/NamesInTooltip';

import { useSelector } from 'react-redux';
import {
  selectRequisitionDeliveredSuppliers,
  selectRequisitionVessel
} from 'store/purchasing/requisition-summary/selectors';
import { selectActiveRequisition } from 'common/components/purchasing/requisition/store/selectors';
import { selectExternalEmailStatus } from 'store/settings/selectors';
import _get from 'lodash/get';
import moment from 'moment';
import NavigationButtons from 'views/purchasing/requisitions/requisition/summary/NavigationButtons';
import useRouter from 'use-react-router';
import PropTypes from 'prop-types';

const Header = ({ requisitionId, setSummaryRequisitionId }) => {
  const { code, priority, description } = useSelector(selectActiveRequisition);
  const deliveredSuppliers = useSelector(selectRequisitionDeliveredSuppliers);
  const vessel = useSelector(selectRequisitionVessel);

  const externalEmailStatus = useSelector(selectExternalEmailStatus);
  const { history } = useRouter();

  const isApprovalView = useMemo(
    () => history.location.pathname.includes('approval-view'),
    [history.location.pathname]
  );

  return (
    <div className="d-flex align-items-center flex-nowrap text-nowrap">
      <Priority value={priority?.label} />
      <div className="fw-bold fs-16 ps-2 overflow-hidden">
        <TextWithTooltip>{description}</TextWithTooltip>
      </div>
      <div className="fs-16 ps-2 me-3"> / {code}</div>
      {isApprovalView ? (
        <NavigationButtons id={requisitionId} setSummaryRequisitionId={setSummaryRequisitionId} />
      ) : null}
      <div className="height-20 border-end me-3" />
      {externalEmailStatus === 'enabled' ? (
        <>
          <div className="d-inline-flex align-items-center me-3">
            <CommunicationsBox hideEmailForm />
          </div>
        </>
      ) : null}
      {deliveredSuppliers?.length ? (
        <>
          <div className="height-20 border-end me-3" />
          <div className="fs-12 text-violet d-flex align-items-center flex-1">
            Delivered at:
            <NamesInTooltip
              containerClassName="fw-bold ms-1"
              names={deliveredSuppliers?.map(
                ({ delivery_port, delivery_date }) =>
                  `${
                    delivery_port
                      ? `${_get(delivery_port, 'name', '-')}(${_get(delivery_port, 'code', '')}), `
                      : ''
                  }${delivery_date ? moment(delivery_date).format('DD/MM/YYYY') : '-'}`
              )}
            />
          </div>
        </>
      ) : null}
      {vessel ? (
        <div className="ms-auto d-inline-flex rounded-sm bg-lighter-blue px-1 cpy-4 fs-12 text-primary">
          <strong>{vessel?.name}</strong>
          <span className="small-separator bg-primary mx-1"></span>
          <span>Delivered at:</span>
          <strong className="cps-4">
            {vessel?.build_date ? moment(vessel?.build_date).format('DD/MM/YYYY') : '-'}
          </strong>
          <span className="small-separator bg-primary mx-1"></span>
          <span>Last DD at</span>
          <strong className="cps-4">
            {vessel?.last_drydock_event_at
              ? moment(vessel?.last_drydock_event_at).format('DD/MM/YYYY')
              : '-'}
          </strong>
        </div>
      ) : null}
    </div>
  );
};

NavigationButtons.propTypes = {
  setSummaryRequisitionId: PropTypes.func.isRequired,
  requisitionId: PropTypes.number.isRequired
};

export default Header;
