import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_CAPTAIN_REPORT_TEMPLATE'),
  ...asyncTypes('UPDATE_CAPTAIN_REPORT_TEMPLATE'),
  ...asyncTypes('UPDATE_CAPTAIN_REPORT_TEMPLATE_DOCUMENT'),
  ...asyncTypes('DELETE_CAPTAIN_REPORT_TEMPLATE_DOCUMENT'),
  ...asyncTypes('CREATE_CAPTAIN_REPORT_TEMPLATE_DOCUMENT'),
  ...asyncTypes('UPDATE_CAPTAIN_REPORT_TEMPLATE_TABLE'),
  ...asyncTypes('CREATE_CAPTAIN_REPORT_TEMPLATE'),
  ...asyncTypes('DELETE_CAPTAIN_REPORT_TEMPLATE'),
  ...asyncTypes('CLONE_CAPTAIN_REPORT_TEMPLATE'),
  ...asyncTypes('GET_CAPTAIN_REPORT_TEMPLATE_DEFAULT_FIELDS'),
  UPDATE_CAPTAIN_REPORT_TEMPLATE_VALIDATION: 'UPDATE_CAPTAIN_REPORT_TEMPLATE_VALIDATION',
  DELETE_CAPTAIN_REPORT_TEMPLATE_VALIDATION: 'DELETE_CAPTAIN_REPORT_TEMPLATE_VALIDATION',
  CLEAR_CAPTAIN_REPORT_TEMPLATE_VALUES: 'CLEAR_CAPTAIN_REPORT_TEMPLATE_VALUES',
  CHANGE_TEMPLATE_FIELD: 'CHANGE_TEMPLATE_FIELD',
  CHANGE_FIELD_VISIBILITY: 'CHANGE_FIELD_VISIBILITY',
  SET_BUNKERING_FUEL_GRADES: 'SET_BUNKERING_FUEL_GRADES'
};

export default TYPES;
