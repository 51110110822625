import { RootState } from '@/store';
import { strToNumber } from '@/ts-common/utils/numbers';
import { createSelector } from 'reselect';

export const selectOrganizationSettings = (state: RootState) => state.settings;

export const selectSystemCurrency = (state: RootState) =>
  selectOrganizationSettings(state).system_currency;

export const selectSystemCurrencyLabel = (state: RootState) => selectSystemCurrency(state)?.label;

export const selectSystemCurrencySymbol = (state: RootState) => selectSystemCurrency(state)?.symbol;

export const selectExternalEmailStatus = (state: RootState) =>
  selectOrganizationSettings(state).external_email_status;

export const selectPurchasingRequisitionsHighlightCheapest = (state: RootState) =>
  selectOrganizationSettings(state).purchasing_requisitions_highlight_cheapest;

export const selectPurchasingRequisitionsHighlightMostExpensive = (state: RootState) =>
  selectOrganizationSettings(state).purchasing_requisitions_highlight_most_expensive;

export const selectEvaluationsRangeFrom = createSelector(selectOrganizationSettings, state => {
  const from = strToNumber(state.evaluations_range_from);

  if (from !== null) {
    return from;
  }

  return 1;
});

export const selectEvaluationsRangeTo = (state: RootState) =>
  +selectOrganizationSettings(state).evaluations_range_to || 5;

export const selectEvaluationsStep = (state: RootState) =>
  +selectOrganizationSettings(state).evaluations_step || 0.5;

export const selectPmsJobsRequireTotalSystemRunningHoursWhenReportedInTheSystem = (
  state: RootState
) =>
  selectOrganizationSettings(state)
    .pms_jobs_require_total_system_running_hours_when_reported_in_the_system;

const selectPmsAssignSystemAndSubsystemOrAssignmentToGroup = (state: RootState) =>
  selectOrganizationSettings(state).pms_assign_system_and_subsystem_or_assignment_to_group;

export const selectPmsAssignSystemAndSubsystemToGroup = (state: RootState) => {
  const setting = selectPmsAssignSystemAndSubsystemOrAssignmentToGroup(state);

  return setting === 'system' || !setting;
};

export const selectPmsAssignVesselAssignmentToGroup = (state: RootState) =>
  selectPmsAssignSystemAndSubsystemOrAssignmentToGroup(state) === 'system_assignment';

export const selectCrewRotationMultipleExcelExport = (state: RootState) =>
  selectOrganizationSettings(state).crew_rotation_multiple_excel_export;

export const selectCrewTicketingCasesShowPdfExport = (state: RootState) =>
  selectOrganizationSettings(state).crew_ticketing_cases_show_pdf_export;

export const selectItineraryEditScheduledPortsOnboard = (state: RootState) =>
  selectOrganizationSettings(state).itinerary_edit_scheduled_ports_onboard;

export const selectCrewMemberExternalIdKeys = (state: RootState) =>
  selectOrganizationSettings(state).crew_member_external_id_keys;

export const selectCrewProfileDefaultView = (state: RootState) =>
  selectOrganizationSettings(state).crew_profile_default_view;

export const selectExternalEmailType = (state: RootState) =>
  selectOrganizationSettings(state).external_email_type;
