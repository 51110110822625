import { useCallback, useEffect } from 'react';

import link from 'common/assets/svg/jobs/link.svg';
import SvgRender from 'common/components/general/SvgRender';
import { Row, Col, Button } from 'reactstrap';
import Select from 'common/components/form/inputs/Select';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFormState } from 'utils/hooks';
import config from './config';
import Input from 'common/components/form/inputs/Input';
import CircledButton from 'common/components/buttons/CircledButton';
import {
  getPurchasingRequisitionLinkedEmails,
  linkPurchasingRequisitionEmail,
  getEmailSearchLink
} from 'common/components/purchasing/requisition/store/actions';
import {
  selectActiveRequisitionID,
  selectRequisitionLinkedEmails,
  selectSearchingEmailLink
} from 'common/components/purchasing/requisition/store/selectors';
import LinkedEmail from './LinkedEmail';

const LinkEmails = ({ emailStatuses }) => {
  const dispatch = useDispatch();

  const { formState, collectValues } = useForm(config);
  const { fields, selectField } = useFormState(formState);

  const requisitionId = useSelector(selectActiveRequisitionID);
  const linkedEmails = useSelector(selectRequisitionLinkedEmails);
  const searchingEmailLink = useSelector(selectSearchingEmailLink);

  const initializeEmails = useCallback(async () => {
    try {
      await dispatch(getEmailSearchLink({ requisitionId }));
      await dispatch(getPurchasingRequisitionLinkedEmails({ requisitionId }));
    } catch (error) {
      console.error(error);
    }
  }, [requisitionId, dispatch]);

  const handleEmailLink = async () => {
    const values = collectValues();

    if (!values) return;

    try {
      await dispatch(linkPurchasingRequisitionEmail({ requisitionId, ...values }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    initializeEmails();
  }, [initializeEmails]);

  return (
    <div className="px-8 mb-3">
      {searchingEmailLink ? (
        <Row className="justify-content-between align-items-center border-bottom pb-2 mb-3">
          <Col xs={6} className="text-violet fs-12 fw-medium">
            Search all emails related to this case
          </Col>
          <Col xs="auto">
            <Button
              size="sm"
              type="button"
              color="primary"
              className="fs-10 px-2"
              onClick={() => window.open(`${searchingEmailLink}`, '_blank')}
            >
              Search Emails
            </Button>
          </Col>
        </Row>
      ) : null}

      <Row className="align-items-center mb-1">
        <Col xs={4} className="text-violet fs-12 fw-medium">
          Link an email with the case
        </Col>
        <Col>
          <Select
            placeholder="Select status"
            className="mb-0"
            onChange={value => selectField('status_id')(value)}
            isAsync={false}
            options={emailStatuses}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            {...fields.status_id}
          />
        </Col>
        <Col>
          <Input
            className="mb-0"
            placeholder="Email ID"
            onChange={e => selectField('email_id')(e.target.value)}
            {...fields.email_id}
          />
        </Col>
        <Col xs="auto">
          <CircledButton
            type="add"
            style={{ width: 17, height: 17 }}
            svgStyle={{ width: 7, height: 7 }}
            onClick={handleEmailLink}
          />
        </Col>
      </Row>

      {linkedEmails.length ? (
        <>
          <div className="d-flex align-items-center">
            <SvgRender src={link} style={{ width: 12, height: 12 }} />

            <span className="text-primary fs-12 ms-1">
              Linked emails: <strong>{linkedEmails.length}</strong>
            </span>
          </div>

          {linkedEmails.map((linkedEmail, index) => (
            <LinkedEmail key={`linked-email-${index}`} email={linkedEmail} index={index} />
          ))}
        </>
      ) : null}
    </div>
  );
};

export default LinkEmails;
