import { get } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getChartererEvaluation = createAsyncThunk(
  'GET_CHARTERER_EVALUATION',
  async (params, { rejectWithValue }) => {
    try {
      const { evaluationId, ...rest } = params;

      const res = await get(`/charter-party-evaluations/${evaluationId}`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
