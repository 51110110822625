import TYPES from './types';
import asyncFiltersTypes from 'store/async-filters/types';
import { get, put, post, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { updateTableRow, resetTableRowUpdate } from 'store/tables/lists/actions';
import { getAccount } from 'store/account/actions';
import { selectIsCurrentUserProfile } from 'store/persons/selectors';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getPersons = params => dispatch => {
  dispatch({ type: TYPES.GET_PERSONS.START, payload: { params } });

  return get('/parties', params)
    .then(response => {
      dispatch({ type: TYPES.GET_PERSONS.SUCCESS, payload: response.data });
    })
    .catch(error => dispatch({ type: TYPES.GET_PERSONS.ERROR, payload: error }));
};

export const getPerson = params => dispatch => {
  dispatch({ type: TYPES.GET_PERSON.START, payload: { params } });

  return get(`/parties/${params.id}`)
    .then(response => {
      dispatch({ type: TYPES.GET_PERSON.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PERSON.ERROR, payload: error });
      throw error;
    });
};

export const updatePerson = params => (dispatch, getState) => {
  const { id, table, updateStateWithNew, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_PERSON.START, payload: { params } });

  if (table) dispatch(updateTableRow({ data: params, table }));

  return put(`/parties/${id}`, { ...rest })
    .then(response => {
      dispatch({
        type: TYPES.UPDATE_PERSON.SUCCESS,
        payload: { ...response.data, ...updateStateWithNew }
      });
      dispatch({
        type: asyncFiltersTypes.ASYNC_FILTER_VALUE_EDITED,
        payload: { label: 'persons', id }
      });
      if (table)
        dispatch(updateTableRow({ data: { ...response.data, ...updateStateWithNew }, table }));

      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      if (getState().account.id === id) {
        // When updating the logged-in user, refetch his account data
        dispatch(getAccount());
      }

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_PERSON.ERROR, payload: { response: error, params } });

      if (table) dispatch(resetTableRowUpdate({ data: params, table }));
      return error;
    });
};

export const updatePersonDepartment = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_PERSON_DEPARTMENT.START, payload: { params } });

  return put(`/parties/${id}/departments`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.UPDATE_PERSON_DEPARTMENT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.UPDATE_PERSON_DEPARTMENT.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const createPerson = params => dispatch => {
  dispatch({ type: TYPES.CREATE_PERSON.START, payload: { params } });

  return post('/parties', params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_PERSON.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_PERSON.ERROR, payload: error });

      throw error;
    });
};

export const archivePerson = params => dispatch => {
  dispatch({ type: TYPES.ARCHIVE_PERSON.START, payload: { params } });

  return put(`/parties/${params.id}/archive`)
    .then(response => {
      dispatch({ type: TYPES.ARCHIVE_PERSON.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.ARCHIVE_PERSON.ERROR, payload: error }));
};

export const unarchivePerson = params => dispatch => {
  dispatch({ type: TYPES.UNARCHIVE_PERSON.START, payload: { params } });

  return put(`/parties/${params.id}/unarchive`)
    .then(response => {
      dispatch({ type: TYPES.UNARCHIVE_PERSON.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.UNARCHIVE_PERSON.ERROR, payload: error }));
};

export const getPersonDocuments = params => dispatch => {
  dispatch({ type: TYPES.GET_PERSON_DOCUMENTS.START, payload: { params } });

  return get(`/parties/${params.id}/documents`)
    .then(response => {
      dispatch({ type: TYPES.GET_PERSON_DOCUMENTS.SUCCESS, payload: { documents: response.data } });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PERSON_DOCUMENTS.ERROR, payload: error });
      throw error;
    });
};

export const updatePersonDocuments = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_PERSON_DOCUMENTS.START, payload: { params } });

  const { id, updateStateWithNew, ...rest } = params;

  return put(`/parties/${id}/documents`, rest)
    .then(response => {
      dispatch({
        type: TYPES.UPDATE_PERSON_DOCUMENTS.SUCCESS,
        payload: { documents: { ...updateStateWithNew, ...rest } }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_PERSON_DOCUMENTS.ERROR, payload: error });
      throw error;
    });
};

export const setTypeModal = params => dispatch => {
  return dispatch({ type: TYPES.SET_TYPE_MODAL, payload: params });
};

export const resetProfile = () => dispatch => {
  return dispatch({ type: TYPES.RESET_PROFILE });
};

/* Sensitive Info */
export const getSensitiveInfo = params => dispatch => {
  const { id } = params;
  dispatch({ type: TYPES.GET_SENSITIVE_INFO.START });

  return get(`/parties/${id}/sensitive-info`)
    .then(response => {
      dispatch({ type: TYPES.GET_SENSITIVE_INFO.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_SENSITIVE_INFO.ERROR, payload: error });
      throw error;
    });
};
/* -- */

/* Access */
export const getPersonAccess = params => dispatch => {
  const { id } = params;
  dispatch({ type: TYPES.GET_PERSON_ACCESS.START, payload: params });

  return get(`/verification/people/${id}/access`)
    .then(response => {
      dispatch({ type: TYPES.GET_PERSON_ACCESS.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PERSON_ACCESS.ERROR, payload: error });
      throw error;
    });
};

export const editPersonAccess = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.EDIT_PERSON_ACCESS.START });

  return put(`/verification/people/${id}/access`, rest)
    .then(response => {
      dispatch({ type: TYPES.EDIT_PERSON_ACCESS.SUCCESS, payload: params });
      dispatch(
        successHandler({
          title: 'Success!',
          message: params?.two_factor
            ? 'Two-factor authentication was updated!'
            : 'Credentials were updated!'
        })
      );
      dispatch(getPersonAccess({ id }));
      dispatch(getSensitiveInfo({ id }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.EDIT_PERSON_ACCESS.ERROR, payload: error });
      throw error;
    });
};

export const revokePersonAccess = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.REVOKE_PERSON_ACCESS.START });

  return deleteRequest(`/verification/people/${id}/access`, rest)
    .then(response => {
      dispatch({ type: TYPES.REVOKE_PERSON_ACCESS.SUCCESS, payload: params });
      dispatch(successHandler({ title: 'Success!', message: 'User was disconnected' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.REVOKE_PERSON_ACCESS.ERROR, payload: error });
      throw error;
    });
};

export const grantAccessToPerson = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.GRANT_ACCESS_TO_PERSON.START });

  return post(`/verification/people/${id}/access`, rest)
    .then(response => {
      dispatch({ type: TYPES.GRANT_ACCESS_TO_PERSON.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Access granted!' }));
      dispatch(getPerson({ id }));
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GRANT_ACCESS_TO_PERSON.ERROR, payload: error });
      throw error;
    });
};

export const removePersonTwoFactorAuthentication = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.REMOVE_PERSON_TWO_FACTOR_AUTHENTICATION.START });

  return deleteRequest(`/verification/people/${id}/two-factor`, rest)
    .then(response => {
      dispatch({ type: TYPES.REMOVE_PERSON_TWO_FACTOR_AUTHENTICATION.SUCCESS, payload: params });
      dispatch(getPersonAccess({ id }));
      dispatch(getSensitiveInfo({ id }));
      dispatch(
        successHandler({ title: 'Success!', message: 'Two-factor authentication was updated!' })
      );

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.REMOVE_PERSON_TWO_FACTOR_AUTHENTICATION.ERROR, payload: error });
      throw error;
    });
};
/* -- */

/* E-Sign */
export const updatePersonSignature = createAsyncThunk(
  'UPDATE_PERSON_SIGNATURE',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { id, ...rest } = params;
      const res = await put(`/parties/${id}/signature`, rest);

      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getPersonSignature = createAsyncThunk(
  'GET_PERSON_SIGNATURE',
  async (params, { rejectWithValue }) => {
    try {
      const { id } = params;
      const res = await get(`/parties/${id}/signature`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
/* -- */
