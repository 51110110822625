import TYPES from './types';
import TABLE_TYPES from 'common/components/table/store/types';
import asyncFiltersTypes from 'store/async-filters/types';
import { get, put, post, deleteRequest, download } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { updateTableRow, resetTableRowUpdate } from 'store/tables/lists/actions';

export const getVessels = params => dispatch => {
  dispatch({ type: TYPES.GET_VESSELS.START, payload: { params } });

  return get('/vessels', params)
    .then(response => {
      dispatch({ type: TYPES.GET_VESSELS.SUCCESS, payload: response.data });
    })
    .catch(error => dispatch({ type: TYPES.GET_VESSELS.ERROR, payload: error }));
};

export const getVesselProfile = params => dispatch => {
  dispatch({ type: TYPES.GET_VESSEL_PROFILE.START, payload: { params } });
  const { id, ...rest } = params;

  return get(`/vessels/${id}`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.GET_VESSEL_PROFILE.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_VESSEL_PROFILE.ERROR, payload: error });
      throw error;
    });
};

export const setVesselProfile = params => dispatch => {
  dispatch({ type: TYPES.SET_VESSEL_PROFILE, payload: params });
};

export const getVesselConsumptions = params => dispatch => {
  dispatch({ type: TYPES.GET_VESSEL_CONSUMPTIONS.START, payload: { params } });
  const { id, ...rest } = params;

  return get(`/vessels/${id}/consumptions`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.GET_VESSEL_CONSUMPTIONS.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Synced successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_VESSEL_CONSUMPTIONS.ERROR, payload: error });
      throw error;
    });
};

export const getVesselHires = params => dispatch => {
  dispatch({ type: TYPES.GET_VESSEL_HIRES.START });
  const { id, ...rest } = params;

  return get(`/vessels/${id}/hires`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.GET_VESSEL_HIRES.SUCCESS, payload: response.data });
    })
    .catch(error => dispatch({ type: TYPES.GET_VESSEL_HIRES.ERROR, payload: error }));
};

export const updateVesselHires = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_VESSEL_HIRES.START, payload: { params } });
  const { id, isVesselProfile, ...rest } = params;

  return put(`/vessels/${id}/hires`, { ...rest })
    .then(response => {
      dispatch({
        type: TYPES.UPDATE_VESSEL_HIRES.SUCCESS,
        payload: { data: response.data, charterType: params.charter_type, isVesselProfile }
      });
      dispatch(getVesselHires({ id }));
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return 'success';
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_VESSEL_HIRES.ERROR, payload: { response: error, params } });

      return error;
    });
};

export const updateVessel = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_VESSEL.START, payload: { params } });
  dispatch(updateTableRow({ data: params, table: 'vessels' }));

  return put(`/vessels/${id}`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.UPDATE_VESSEL.SUCCESS, payload: response.data });
      dispatch({
        type: asyncFiltersTypes.ASYNC_FILTER_VALUE_EDITED,
        payload: { label: 'vessels', id }
      });
      dispatch(getVesselProfile({ id }));
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));
      dispatch(updateTableRow({ data: response.data, table: 'vessels' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_VESSEL.ERROR, payload: { response: error, params } });
      dispatch(resetTableRowUpdate({ data: params, table: 'vessels' }));

      throw error;
    });
};

export const createVessel = params => dispatch => {
  dispatch({ type: TYPES.CREATE_VESSEL.START, payload: { params } });

  return post(`/vessels`, params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_VESSEL.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Vessel created successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_VESSEL.ERROR, payload: { response: error, params } });

      return error;
    });
};

export const archiveVessel = params => dispatch => {
  dispatch({ type: TYPES.ARCHIVE_VESSEL.START, payload: { params } });

  return put(`/vessels/${params.id}/archive`)
    .then(response => {
      dispatch({ type: TYPES.ARCHIVE_VESSEL.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.ARCHIVE_VESSEL.ERROR, payload: error }));
};

export const unArchiveVessel = params => dispatch => {
  dispatch({ type: TYPES.UNARCHIVE_VESSEL.START, payload: { params } });

  return put(`/vessels/${params.id}/unarchive`)
    .then(response => {
      dispatch({ type: TYPES.UNARCHIVE_VESSEL.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.UNARCHIVE_VESSEL.ERROR, payload: error }));
};

export const getTceChartData = params => dispatch => {
  const { table, type, ...rest } = params;
  dispatch({ type: TYPES.GET_TCE_RESULTS_CHART.START, payload: { params } });
  rest.sorting = {};

  return get(`reports/vessel-tce-results/graph`, { ...rest, type: 'daily' })
    .then(response => {
      dispatch({
        type: TYPES.GET_TCE_RESULTS_CHART.SUCCESS,
        payload: { data: response.data, params }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_TCE_RESULTS_CHART.ERROR, payload: error });
    });
};

export const getTceAverages = params => dispatch => {
  const { table, type, ...rest } = params;
  dispatch({ type: TYPES.GET_TCE_RESULTS_AVERAGES.START, payload: { params } });
  rest.sorting = {};
  rest.type = 'averages';

  return get(`reports/vessel-tce-results`, { ...rest })
    .then(response => {
      dispatch({
        type: TYPES.GET_TCE_RESULTS_AVERAGES.SUCCESS,
        payload: { data: response.data.data, params }
      });

      return response.data.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_TCE_RESULTS_AVERAGES.ERROR, payload: error });
    });
};

export const getTceResults = params => (dispatch, getState) => {
  const state = getState().vessels;
  const { table, ...rest } = params;
  dispatch({ type: TYPES.GET_TCE_RESULTS.START, payload: { params } });
  dispatch({
    type: TABLE_TYPES.GET_TABLE_LIST.START,
    payload: { params, table }
  });

  if (
    (state.tceResultsFilters &&
      (state.tceResultsFilters.market_index_id !== params.market_index_id ||
        state.tceResultsFilters.date_from !== params.date_from ||
        state.tceResultsFilters.date_to !== params.date_to ||
        state.tceResultsFilters.vessel_id !== params.vessel_id)) ||
    !state.tceResultsFilters ||
    state.forceUpdateTceResultsChartData
  ) {
    // Fetches chart data on initial load, when market index or dates change or after a new tce result is created
    dispatch(getTceAverages(params));
    dispatch(getTceChartData(params));
  }

  if (!state.tceResultsFilters || state.tceResultsFilters.vessel_id !== params.vessel_id) {
    dispatch(getVesselProfile({ id: params.vessel_id }));
  }

  return get(`/reports/vessel-tce-results`, rest)
    .then(response => {
      dispatch({ type: TYPES.GET_TCE_RESULTS.SUCCESS, payload: { data: response.data, params } });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_TCE_RESULTS.ERROR, payload: error });
      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.ERROR,
        payload: { error, table }
      });
    });
};

export const updateTceResult = params => (dispatch, getState) => {
  dispatch({ type: TYPES.UPDATE_TCE_RESULT.START, payload: { params } });

  const { vessel_id, tce_result_id, ...rest } = params;

  return put(`/vessels/${vessel_id}/tce-results/${tce_result_id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_TCE_RESULT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      const tceFilters = getState().vessels.tceResultsFilters;
      const tceTableSorting = getState().tables.lists.vessel_tce_results.sorting;

      dispatch(
        getTceResults({
          table: 'vessel_tce_results',
          vessel_id: params.vessel_id,
          ...tceFilters,
          sorting: {
            ...tceTableSorting
          }
        })
      );

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.UPDATE_TCE_RESULT.ERROR, payload: error }));
};

export const createTceResult = params => (dispatch, getState) => {
  dispatch({ type: TYPES.CREATE_TCE_RESULT.START, payload: { params } });

  return post(`/vessels/${params.vessel_id}/tce-results`, params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_TCE_RESULT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'TCE result created successfully' }));

      const tceFilters = getState().vessels.tceResultsFilters;
      const tceTableSorting = getState().tables.lists.vessel_tce_results.sorting;

      dispatch(
        getTceResults({
          table: 'vessel_tce_results',
          vessel_id: params.vessel_id,
          ...tceFilters,
          sorting: {
            ...tceTableSorting
          }
        })
      );

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_TCE_RESULT.ERROR, payload: { response: error, params } });

      return error;
    });
};

export const getPools = params => dispatch => {
  dispatch({ type: TYPES.GET_POOLS.START, payload: { params } });

  return get(`/vessels/${params.vessel_id}/pool`)
    .then(response => {
      dispatch({ type: TYPES.GET_POOLS.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_POOLS.ERROR, payload: error }));
};

export const createPool = params => dispatch => {
  dispatch({ type: TYPES.CREATE_POOL.START, payload: { params } });

  const { vessel_id, ...rest } = params;

  return post(`/vessels/${vessel_id}/pool`, rest)
    .then(response => {
      dispatch({ type: TYPES.CREATE_POOL.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_POOL.ERROR, payload: error });

      throw error;
    });
};

export const updatePool = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_POOL.START, payload: { params } });

  const { vessel_id, pool_id, ...rest } = params;

  return put(`/vessels/${vessel_id}/pool/${pool_id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_POOL.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_POOL.ERROR, payload: error });
      throw error;
    });
};

export const deletePool = params => dispatch => {
  dispatch({ type: TYPES.DELETE_POOL.START, payload: { params } });

  const { vessel_id, pool_id, ...rest } = params;

  return deleteRequest(`/vessels/${vessel_id}/pool/${pool_id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.DELETE_POOL.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_POOL.ERROR, payload: error });
      throw error;
    });
};

export const createPoolPoint = params => dispatch => {
  dispatch({ type: TYPES.CREATE_POOL_POINT.START, payload: { params } });

  const { vessel_id, pool_id, ...rest } = params;

  return post(`/vessels/${vessel_id}/pool/${pool_id}/points`, rest)
    .then(response => {
      dispatch({ type: TYPES.CREATE_POOL_POINT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Saved successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_POOL_POINT.ERROR, payload: error });

      throw error;
    });
};

export const updatePoolPoint = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_POOL_POINT.START, payload: { params } });

  const { vessel_id, pool_id, pool_point_id, ...rest } = params;

  return put(`/vessels/${vessel_id}/pool/${pool_id}/points/${pool_point_id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_POOL_POINT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.UPDATE_POOL_POINT.ERROR, payload: error }));
};

export const deletePoolPoint = params => dispatch => {
  dispatch({ type: TYPES.DELETE_POOL_POINT.START, payload: { params } });

  const { vessel_id, pool_id, pool_point_id, ...rest } = params;

  return deleteRequest(`/vessels/${vessel_id}/pool/${pool_id}/points/${pool_point_id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.DELETE_POOL_POINT.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.DELETE_POOL_POINT.ERROR, payload: error }));
};

export const createPoolMonthlyResult = params => dispatch => {
  dispatch({ type: TYPES.CREATE_POOL_MONTHLY_RESULT.START, payload: { params } });

  const { vessel_id, pool_id, ...rest } = params;

  return post(`/vessels/${vessel_id}/pool/${pool_id}/results`, rest)
    .then(response => {
      dispatch({ type: TYPES.CREATE_POOL_MONTHLY_RESULT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_POOL_MONTHLY_RESULT.ERROR, payload: error });
      throw error;
    });
};

export const updatePoolMonthlyResult = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_POOL_MONTHLY_RESULT.START, payload: { params } });

  const { vessel_id, pool_id, pool_monthly_result_id, ...rest } = params;

  return put(`/vessels/${vessel_id}/pool/${pool_id}/results/${pool_monthly_result_id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_POOL_MONTHLY_RESULT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.UPDATE_POOL_MONTHLY_RESULT.ERROR, payload: error }));
};

export const deletePoolMonthlyResult = params => dispatch => {
  dispatch({ type: TYPES.DELETE_POOL_MONTHLY_RESULT.START, payload: { params } });

  const { vessel_id, pool_id, pool_monthly_result_id, ...rest } = params;

  return deleteRequest(
    `/vessels/${vessel_id}/pool/${pool_id}/results/${pool_monthly_result_id}`,
    rest
  )
    .then(response => {
      dispatch({ type: TYPES.DELETE_POOL_MONTHLY_RESULT.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.DELETE_POOL_MONTHLY_RESULT.ERROR, payload: error }));
};

export const changePoolDrawerStatus = params => dispatch => {
  dispatch({ type: TYPES.CHANGE_POOL_DRAWER_STATUS, payload: params });
};

export const setActivePool = params => dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE_POOL, payload: params });
};

export const toggleCrewForm = () => dispatch => {
  dispatch({ type: TYPES.TOGGLE_CREW_FORM });
};

export const setActiveVesselCrew = crew => dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE_CREW, payload: crew });
};

export const getVesselCrew = params => dispatch => {
  const { table, id, ...rest } = params;
  let apiParams = { ...rest };

  dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.START, payload: { params: rest, table } });

  return get(`/vessels/${id}/crew`, apiParams)
    .then(response => {
      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: { data: response.data, table }
      });
    })
    .catch(error =>
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } })
    );
};

export const createVesselCrew = params => dispatch => {
  dispatch({ type: TYPES.CREATE_VESSEL_CREW.START, payload: { params } });

  return post(`/vessels/${params.vessel_id}/crew`, params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_VESSEL_CREW.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Crew created successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_VESSEL_CREW.ERROR, payload: { response: error, params } });

      return error;
    });
};

export const updateVesselCrew = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_VESSEL_CREW.START, payload: { params } });
  dispatch(updateTableRow({ data: params, table: 'vessel_crew' }));

  return put(`/vessels/${params.vessel_id}/crew/${params.id}`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.UPDATE_VESSEL_CREW.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));
      dispatch(updateTableRow({ data: response.data, table: 'vessel_crew' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_VESSEL_CREW.ERROR, payload: { response: error, params } });
      dispatch(resetTableRowUpdate({ data: params, table: 'vessel_crew' }));

      return error;
    });
};

export const deleteVesselCrew = params => dispatch => {
  dispatch({ type: TYPES.DELETE_VESSEL_CREW.START, payload: { params } });

  return deleteRequest(`/vessels/${params.vessel_id}/crew/${params.id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_VESSEL_CREW.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.DELETE_VESSEL_CREW.ERROR, payload: error }));
};

export const toggleMakerModal = params => dispatch => {
  dispatch({ type: TYPES.CREATE_MAKER_MODAL, payload: params });
};

/* pool points */
export const changePoolPointsDrawrStatus = params => dispatch => {
  dispatch({ type: TYPES.CHANGE_POOL_POINTS_DRAWER_STATUS, payload: params });
};

export const setActivePoolPoint = params => dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE_POOL_POINT, payload: params });
};

export const createPoolPoints = params => dispatch => {
  dispatch({ type: TYPES.CREATE_POOL_POINTS.START, payload: { params } });

  const { id, ...rest } = params;

  return post(`/vessels/${id}/pool-points`, rest)
    .then(response => {
      dispatch({ type: TYPES.CREATE_POOL_POINTS.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Succesfully created' }));
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_POOL_POINTS.ERROR, payload: { response: error, params } });

      throw error;
    });
};

export const updatePoolPoints = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_POOL_POINTS.START, payload: { params } });

  const { vesselId, id, ...rest } = params;

  return put(`/vessels/${vesselId}/pool-points/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_POOL_POINTS.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_POOL_POINTS.ERROR, payload: { response: error, params } });

      throw error;
    });
};

export const deletePoolPoints = params => dispatch => {
  dispatch({ type: TYPES.DELETE_POOL_POINTS.START, payload: { params } });

  const { vesselId, id, ...rest } = params;

  return deleteRequest(`/vessels/${vesselId}/pool-points/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.DELETE_POOL_POINTS.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_POOL_POINTS.ERROR, payload: { response: error, params } });

      throw error;
    });
};

export const getDefaultSources = params => dispatch => {
  const { id } = params;

  dispatch({ type: TYPES.GET_DEFAULT_SOURCES.START, payload: { params } });

  return get(`/vessels/${id}/tags-detailed`)
    .then(response => {
      dispatch({ type: TYPES.GET_DEFAULT_SOURCES.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_DEFAULT_SOURCES.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const updateDefaultSources = (vessel_id, params) => dispatch => {
  dispatch({ type: TYPES.SET_DEFAULT_SOURCES.START, payload: { params } });

  return put(`/vessels/${vessel_id}/default-sources`, params)
    .then(response => {
      dispatch({ type: TYPES.SET_DEFAULT_SOURCES.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Default Source was updated' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.SET_DEFAULT_SOURCES.ERROR, payload: { response: error, params } });

      throw error;
    });
};

export const getLabelParameters = (vessel_id, params) => dispatch => {
  dispatch({ type: TYPES.GET_LABEL_PARAMETERS.START, payload: { params } });

  return get(`/vessels/${vessel_id}/unique-tags`, params)
    .then(response => {
      dispatch({ type: TYPES.GET_LABEL_PARAMETERS.SUCCESS, payload: response });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_LABEL_PARAMETERS.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const updateLabelParameters = (vessel_id, params) => dispatch => {
  dispatch({ type: TYPES.SET_LABEL_PARAMETERS.START, payload: { params } });

  return put(`/vessels/${vessel_id}/tags`, params)
    .then(response => {
      dispatch({
        type: TYPES.SET_LABEL_PARAMETERS.SUCCESS,
        payload: response
      });
      dispatch(successHandler({ title: 'Success!', message: 'Label was updated' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.SET_LABEL_PARAMETERS.ERROR, payload: { response: error, params } });

      throw error;
    });
};

export const getIntegrationSources = (vessel_id, params) => dispatch => {
  dispatch({ type: TYPES.GET_INTEGRATION_SOURCES.START, payload: { params } });

  return get(`/vessels/${vessel_id}/integration-sources`, params)
    .then(response => {
      dispatch({ type: TYPES.GET_INTEGRATION_SOURCES.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_INTEGRATION_SOURCES.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

/* -- */

/* Vessel's captain report */
export const createVesselCaptainReport = params => dispatch => {
  dispatch({ type: TYPES.CREATE_VESSEL_CAPTAIN_REPORT.START, payload: { params } });
  const { id, ...rest } = params;

  return post(`/vessels/${id}/captain-reports`, rest)
    .then(response => {
      dispatch({ type: TYPES.CREATE_VESSEL_CAPTAIN_REPORT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.CREATE_VESSEL_CAPTAIN_REPORT.ERROR, payload: error }));
};
/* -- */

/* Benchmarks */
export const setBenchmarksInputHoverIndex = index => dispatch => {
  dispatch({ type: TYPES.SET_BENCHMARKS_INPUT_HOVER_INDEX, payload: index });
};

export const setEdittingBenchmarkId = id => dispatch => {
  dispatch({ type: TYPES.SET_EDITTING_BENCHMARK_ID, payload: id });
};

export const createVesselBenchmarks = params => dispatch => {
  dispatch({ type: TYPES.CREATE_VESSEL_BENCHMARKS.START, payload: { params } });
  const { id, ...rest } = params;

  return post(`/vessels/${id}/benchmarks`, rest)
    .then(response => {
      dispatch({ type: TYPES.CREATE_VESSEL_BENCHMARKS.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_VESSEL_BENCHMARKS.ERROR, payload: error });
      throw error;
    });
};

export const updateVesselBenchmarks = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_VESSEL_BENCHMARK.START, payload: { params } });
  const { id, benchmarkId, ...rest } = params;

  return put(`/vessels/${id}/benchmarks/${benchmarkId}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_VESSEL_BENCHMARK.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_VESSEL_BENCHMARK.ERROR, payload: error });
      throw error;
    });
};

export const deleteVesselBenchmarks = params => dispatch => {
  dispatch({ type: TYPES.DELETE_VESSEL_BENCHMARK.START, payload: { params } });
  const { id, benchmarkId, ...rest } = params;

  return deleteRequest(`/vessels/${id}/benchmarks/${benchmarkId}`, rest)
    .then(response => {
      dispatch({ type: TYPES.DELETE_VESSEL_BENCHMARK.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_VESSEL_BENCHMARK.ERROR, payload: error });
      throw error;
    });
};

export const getVesselBenchmarks = params => dispatch => {
  dispatch({ type: TYPES.GET_VESSEL_BENCHMARKS.START, payload: { params, isDataLoading: true } });
  const { id, ...rest } = params;

  return get(`/vessels/${id}/benchmarks`, rest)
    .then(response => {
      dispatch({
        type: TYPES.GET_VESSEL_BENCHMARKS.SUCCESS,
        payload: { data: response.data, isDataLoading: false }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_VESSEL_BENCHMARKS.ERROR,
        payload: { error, isDataLoading: false }
      });
      throw error;
    });
};

export const getVesselBenchmarkTypes = params => dispatch => {
  dispatch({
    type: TYPES.GET_VESSEL_BENCHMARK_TYPES.START,
    payload: { params, loading: true }
  });

  return get(`/benchmarks/types`, params)
    .then(response => {
      dispatch({
        type: TYPES.GET_VESSEL_BENCHMARK_TYPES.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_VESSEL_BENCHMARK_TYPES.ERROR,
        payload: { error, loading: false }
      });
      throw error;
    });
};

export const getVesselBenchmarkConditions = params => dispatch => {
  dispatch({
    type: TYPES.GET_VESSEL_BENCHMARK_CONDITIONS.START,
    payload: { params, loading: true }
  });

  return get(`/benchmarks/conditions`, params)
    .then(response => {
      dispatch({
        type: TYPES.GET_VESSEL_BENCHMARK_CONDITIONS.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_VESSEL_BENCHMARK_CONDITIONS.ERROR,
        payload: { error, loading: false }
      });
      throw error;
    });
};

export const getVesselCiiReferenceYearly = params => dispatch => {
  dispatch({
    type: TYPES.GET_VESSEL_CII_REFERENCE_YEARLY.START,
    payload: { params, loading: true }
  });

  const { id, ...rest } = params;

  return get(`/vessels/${id}/cii-reference`, rest)
    .then(response => {
      dispatch({
        type: TYPES.GET_VESSEL_CII_REFERENCE_YEARLY.SUCCESS,
        payload: response?.data
      });

      return response?.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_VESSEL_CII_REFERENCE_YEARLY.ERROR,
        payload: { error, loading: false }
      });
      throw error;
    });
};

export const reorderBenchmarkFormulas = params => dispatch => {
  dispatch({ type: TYPES.REORDER_BENCHMARK_FORMULAS.START, payload: { params } });
  const { vessel_id, benchmark_id, ...rest } = params;

  return put(`/vessels/${vessel_id}/benchmarks/${benchmark_id}/formulas/reorder`, rest)
    .then(res => {
      dispatch({ type: TYPES.REORDER_BENCHMARK_FORMULAS.SUCCESS, payload: res.data });
      dispatch(
        successHandler({
          title: 'Success!',
          message: 'Formulas updated'
        })
      );

      return res.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.REORDER_BENCHMARK_FORMULAS.ERROR,
        payload: error
      });
      dispatch(getVesselBenchmarks({ id: vessel_id, datatype: 'formula' }));
    });
};

export const recalculateFormulas = params => dispatch => {
  dispatch({ type: TYPES.RECALCULATE_FORMULAS.START, payload: { params } });

  return put(`/recalculate-formula`, params)
    .then(res => {
      dispatch({ type: TYPES.RECALCULATE_FORMULAS.SUCCESS, payload: res.data });
      dispatch(
        successHandler({
          title: 'Success!',
          message: res.data?.data
        })
      );

      return res.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.RECALCULATE_FORMULAS.ERROR,
        payload: error
      });
    });
};

export const getFormulaLogs = params => dispatch => {
  const { id, table, ...rest } = params;

  dispatch({ type: TYPES.GET_FORMULA_LOGS.START });
  dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.START, payload: { params, table, ...rest } });

  return get(`/vessels/benchmark-formulas/${id}/executions`, rest)
    .then(response => {
      dispatch({
        type: TYPES.GET_FORMULA_LOGS.SUCCESS,
        payload: response?.data
      });
      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: {
          data: response?.data,
          table
        }
      });

      return response?.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_FORMULA_LOGS.ERROR,
        payload: { error }
      });
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } });
      throw error;
    });
};

export const setBenchmarksLogsDrawerOpen = isOpen => dispatch => {
  dispatch({ type: TYPES.SET_BENCHMARKS_LOGS_DRAWER_OPEN, payload: isOpen });
};

export const setBenchmarksActiveLogsFormula = formula => dispatch => {
  dispatch({ type: TYPES.SET_BENCHMARKS_ACTIVE_LOGS_FORMULA, payload: formula });
};
/* -- */
