import {
  PurchasingRequisitionStatusBase,
  PurchasingRequisitionStatusLabel
} from '@/common/types/purchasing.ts';

const getStatus = (
  statuses: PurchasingRequisitionStatusBase[] | undefined,
  statusToCheck: PurchasingRequisitionStatusLabel
) => statuses?.find(f => f.label === statusToCheck);

export const checkIfActionIsChecked = (
  supplierStatus: PurchasingRequisitionStatusBase,
  statuses: PurchasingRequisitionStatusBase[] | undefined,
  statusToCheck: PurchasingRequisitionStatusLabel
) => {
  const status = getStatus(statuses, statusToCheck);

  return status && supplierStatus.sort_index > status.sort_index;
};

export const checkIfActionIsVisible = (
  supplierStatus: PurchasingRequisitionStatusBase,
  statuses: PurchasingRequisitionStatusBase[] | undefined,
  statusToCheck: PurchasingRequisitionStatusLabel
) => {
  const status = getStatus(statuses, statusToCheck);

  return status && supplierStatus.sort_index >= status.sort_index;
};

export const checkIfIsTheCurrentStatus = (
  supplierStatus: PurchasingRequisitionStatusBase,
  statuses: PurchasingRequisitionStatusBase[] | undefined,
  statusToCheck: PurchasingRequisitionStatusLabel
) => {
  const status = getStatus(statuses, statusToCheck);

  return status && supplierStatus.sort_index === status.sort_index;
};
