import _reduce from 'lodash/reduce';
import TYPES from './types';
import LIST_TYPES from 'common/components/table/store/types';
import FAVORITE_TYPES from 'store/favorites/types';
import lists from './lists/keys';

import { LOCATION_CHANGE } from 'connected-react-router';
import { initFiltersState } from 'common/utils/filters/helpers';
import { initialListState } from 'common/components/table/store/helpers';
import { getDefaultSorting, getDefaultPageSize } from 'store/tables/lists/defaults';

const INITIAL_STATE = {
  lists: _reduce(
    lists,
    (state, key) => {
      const pageSize = getDefaultPageSize(key);
      const sorting = getDefaultSorting(key);

      return {
        ...state,
        [key]: {
          ...initialListState(key, pageSize, sorting)
        }
      };
    },
    {}
  ),
  listsFilters: {},
  listsSearch: {},
  location: { path: '', search: '', action: '' },
  previousLocation: { path: '', search: '', action: '' },
  searchSaving: false,
  searchLoading: false,
  hasSavedTableFilters: false,
  search: null
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_TABLE_SEARCH.START:
      return {
        ...state,
        search: null,
        searchLoading: true
      };

    case TYPES.GET_TABLE_SEARCH.SUCCESS:
      return {
        ...state,
        search: payload,
        searchLoading: false
      };

    case TYPES.UPDATE_TABLE_SEARCH.START:
      return {
        ...state,
        searchSaving: true
      };

    case TYPES.SET_TABLE_SEARCH:
      return {
        ...state,
        search: payload
      };

    case TYPES.GET_TABLE_SEARCH.ERROR:
      return {
        ...state,
        searchSaving: false,
        searchLoading: false
      };

    case TYPES.UPDATE_TABLE_SEARCH.SUCCESS:
      return {
        ...state,
        search: payload,
        searchSaving: false
      };

    case LIST_TYPES.GET_TABLE_LIST.START:
      if (payload) {
        const { sorting } = payload.params;
        let paging = null;

        if (payload.params && payload.params.paging) {
          paging = {
            ...state.lists[payload.table].paging,
            current_page: payload.params.paging.current_page
          };
        }

        return {
          ...state,
          lists: {
            ...state.lists,
            [payload.table]: {
              ...state.lists[payload.table],
              paging,
              sorting:
                sorting && Object.keys(sorting).length > 0
                  ? sorting
                  : getDefaultSorting(payload.table),
              invalid: false,
              pendingRequest: true
            }
          }
        };
      } else {
        return state;
      }

    case LIST_TYPES.GET_TABLE_LIST.SUCCESS:
      if (payload) {
        let paging = null;

        if (payload.data && payload.data.meta) {
          paging = { ...state.lists[payload.table].paging, ...payload.data.meta };
        }

        return {
          ...state,
          lists: {
            ...state.lists,
            [payload.table]: {
              ...state.lists[payload.table],
              data: payload.data.data ? payload.data.data : payload.data,
              totals: payload.data.totals,
              percentages: payload.data.percentages,
              footer_values: payload.data.footer_values,
              year_turn_over: payload.data.year_turn_over,
              columns: payload.data.columns,
              vessel: payload.data.vessel,
              paging,
              invalid: false,
              pendingRequest: false
            }
          }
        };
      } else {
        return state;
      }

    case LIST_TYPES.GET_TABLE_LIST.ERROR:
      return {
        ...state,
        lists: {
          ...state.lists,
          [payload.table]: {
            ...state.lists[payload.table],
            columns: [],
            invalid: true,
            pendingRequest: false
          }
        }
      };

    case LIST_TYPES.SET_TABLE_REQUEST_PARAMS:
      if (payload) {
        return {
          ...state,
          lists: {
            ...state.lists,
            [payload.table]: {
              ...state.lists[payload.table],
              requestParams: payload.params
            }
          }
        };
      } else {
        return state;
      }

    case LIST_TYPES.REMOVE_TABLE_ROW:
      if (state.lists?.[payload?.table]?.data) {
        return {
          ...state,
          lists: {
            ...state.lists,
            [payload.table]: {
              ...state.lists[payload.table],
              data: state.lists[payload.table].data.filter(d => d.id !== payload.data.id)
            }
          }
        };
      }
      return state;

    case LIST_TYPES.UPDATE_TABLE_ROW:
      if (state.lists?.[payload?.table]?.data) {
        return {
          ...state,
          lists: {
            ...state.lists,
            [payload.table]: {
              ...state.lists[payload.table],
              data: state.lists[payload.table].data.map(d =>
                d.id === payload.data.id ? { ...d, ...payload.data } : d
              )
            }
          }
        };
      }

      return state;

    case LIST_TYPES.RESET_TABLE_ROW_UPDATE:
      if (state.lists?.[payload?.table]?.data) {
        return {
          ...state,
          lists: {
            ...state.lists,
            [payload.table]: {
              ...state.lists[payload.table],
              data: state.lists[payload.table].data.map(d => {
                if (d.id !== payload.data.id) return d;

                if (payload.data.active !== undefined) {
                  return {
                    ...d,
                    active: !payload.data.active
                  };
                } else if (payload.data.can_login !== undefined) {
                  return {
                    ...d,
                    can_login: !payload.data.can_login
                  };
                }

                return { ...d, ...payload.data };
              })
            }
          }
        };
      }

      return state;

    case LIST_TYPES.TOGGLE_TABLE_FILTERS:
      return {
        ...state,
        listsFilters: {
          ...state.listsFilters,
          [payload.table]: {
            ...state.listsFilters[payload.table],
            isFiltersOpen: payload.open
          }
        }
      };

    case LIST_TYPES.APLLY_TABLE_FILTERS:
      return {
        ...state,
        listsFilters: {
          ...state.listsFilters,
          [payload.table]: {
            ...state.listsFilters[payload.table],
            shouldRefetchTable:
              payload.shouldRefetchTable === undefined ? true : payload.shouldRefetchTable,
            applied: payload.applied
          }
        }
      };

    case TYPES.SET_HAS_TABLE_SAVED_FILTERS:
      return {
        ...state,
        hasSavedTableFilters: payload
      };

    case LIST_TYPES.SELECT_TABLE_ROW:
      const selectedRows = [...state.lists[payload.table].selectedRows];

      return {
        ...state,
        lists: {
          ...state.lists,
          [payload.table]: {
            ...state.lists[payload.table],
            selectedRows:
              selectedRows.findIndex(r => r.id === payload.row.id) !== -1
                ? selectedRows.filter(id => id.id !== payload.row.id)
                : [...selectedRows, payload.row]
          }
        }
      };

    case LIST_TYPES.SELECT_TABLE_ROWS:
      return {
        ...state,
        lists: {
          ...state.lists,
          [payload.table]: {
            ...state.lists[payload.table],
            selectedRows: payload.rows
          }
        }
      };

    case LIST_TYPES.CLEAR_SELECTED_TABLE_ROWS:
      return {
        ...state,
        lists: {
          ...state.lists,
          [payload.table]: {
            ...state.lists[payload.table],
            selectedRows: []
          }
        }
      };

    case LIST_TYPES.SET_TABLE_HOVERED_COLUMN:
      return {
        ...state,
        lists: {
          ...state.lists,
          [payload.table]: {
            ...state.lists[payload.table],
            hoveredColumn: payload.key
          }
        }
      };

    case FAVORITE_TYPES.TOGGLE_FAVORITE.SUCCESS:
      return {
        ...state,
        search:
          state.search && state.search.item
            ? {
                ...state.search,
                item: {
                  ...state.search.item,
                  favourites_count: state.search.item.favourites_count === 0 ? 1 : 0
                }
              }
            : state.search
      };

    case TYPES.RESET_CURRENT_PAGE:
      if (state.location.path !== state.previousLocation.path) {
        return {
          ...state,
          lists: {
            ...state.lists,
            [payload.label]: {
              ...state.lists[payload.label],
              selectedRows: [],
              hoveredColumn: null,
              paging:
                state.lists[payload.label].paging === null
                  ? null
                  : { ...state.lists[payload.label].paging, current_page: 1 }
            }
          },
          listsFilters: {
            ...state.listsFilters,
            [payload.label]: { filters: initFiltersState({ filters: [] }), isFiltersOpen: false }
          },
          listsSearch: { ...state.listsSearch, [payload.label]: '' }
        };
      }

      return state;

    case TYPES.SET_TABLE_FILTERS:
      return {
        ...state,
        listsFilters: {
          ...state.listsFilters,
          [payload.label]: {
            ...state.listsFilters[payload.label],
            filters: payload.requestParams
              ? initFiltersState(payload.requestParams, payload.columns)
              : payload.filters.map(f => {
                  if (
                    f.operation &&
                    f.operation.value === 'between' &&
                    Array.isArray(f.value) &&
                    f.operation.label === 'between'
                  ) {
                    return {
                      ...f,
                      value: {
                        from: Array.isArray(f.value) && f.value[0] ? f.value[0] : null,
                        to: Array.isArray(f.value) && f.value[1] ? f.value[1] : null
                      }
                    };
                  }

                  return f;
                })
          }
        }
      };

    case TYPES.SET_TABLE_DEFAULT_REQUEST_PARAMS:
      return {
        ...state,
        lists: {
          ...state.lists,
          [payload.label]: {
            ...state.lists[payload.label],
            defaultRequestParams: payload.defaultRequestParams
          }
        }
      };

    case TYPES.RESET_TABLE_DATA:
      return {
        ...state,
        lists: {
          ...state.lists,
          [payload]: {
            ...state.lists[payload],
            data: [],
            totals: {},
            paging:
              state.lists[payload]?.paging === null
                ? null
                : {
                    ...state.lists[payload].paging,
                    current_page: 1,
                    last_page: 1,
                    total: 1
                  }
          }
        }
      };

    case TYPES.RESET_TABLE_COLUMNS:
      return {
        ...state,
        lists: {
          ...state.lists,
          [payload]: {
            ...state.lists[payload],
            columns: []
          }
        }
      };

    case TYPES.RESET_TABLE_SEARCH:
      return {
        ...state,
        search: null
      };

    case LIST_TYPES.SET_TABLE_PAGE_SEARCH:
      return {
        ...state,
        listsSearch: {
          ...state.listsSearch,
          [payload.table]: payload.text !== undefined ? payload.text : ''
        }
      };

    case LIST_TYPES.SET_TABLE_WARNINGS:
      return {
        ...state,
        lists: {
          ...state.lists,
          [payload.table]: { ...state.lists[payload.table], warnings: payload.warnings }
        }
      };

    case LIST_TYPES.SET_TABLE_TOTALS:
      return {
        ...state,
        lists: {
          ...state.lists,
          [payload.table]: {
            ...state.lists[payload.table],
            totals: {
              ...state.lists[payload.table].totals,
              ...payload.totals
            }
          }
        }
      };

    case LOCATION_CHANGE:
      return {
        ...state,
        previousLocation: state.location,
        location: {
          path: payload.location.pathname,
          search: payload.location.search,
          action: payload.action
        },
        search: !payload.location.search.includes('searchId=') ? null : state.search
      };

    default:
      return state;
  }
};

export default reducer;
