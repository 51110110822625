import React from 'react';
// import SvgRender from 'common/components/general/SvgRender';
// import Voyage from 'common/assets/svg/common/voyages.svg';
import VesselRibbon from 'components/vessels/VesselRibbon';
// import PoolIcon from 'components/vessels/PoolIcon';
import NavigationGroupTabs from 'common/components/buttons/NavigationGroupTabs';

const Views = ({ onViewChange, activeView, vessel, reversed, showAllFilters }) => {
  const views = [
    { label: 'D', value: 'daily' },
    { label: 'M', value: 'monthly' }
    // { label: <SvgRender src={Voyage} style={{ width: 16, height: 16 }} />, value: 'voyage' }
  ];

  const labels = [
    { text: 'Voyage', dotColor: 'blue' },
    { text: 'TC', dotColor: 'aqua' }
  ];

  return (
    <div
      className={`d-flex justify-content-between align-items-center ${
        showAllFilters ? 'border-start ps-1 ms-3' : ''
      } ${reversed ? ' flex-row-reverse' : ''}`}
    >
      <NavigationGroupTabs
        tabs={views.map(v => ({
          label: v.label,
          onClick: () => onViewChange(v.value),
          className: `${activeView === v.value ? ' active' : ''}`,
          tooltip: `${v.value.charAt(0).toUpperCase() + v.value.slice(1)} View`,
          size: 'sm'
        }))}
      />

      <div className="d-flex align-items-center">
        {labels.map((label, i) => (
          <div key={i.toString()} className="d-flex justify-content-center align-items-center ms-2">
            <div className={`dot dot-${label.dotColor} me-1`} />
            <div className="text-primary fs-12 lh-1 fw-normal">{label.text}</div>
          </div>
        ))}

        {vessel ? <VesselRibbon vessel={vessel} className="ms-4" /> : null}
      </div>
    </div>
  );
};

export default Views;
