import { createAsyncThunk } from '@reduxjs/toolkit';
import TABLE_TYPES from 'common/components/table/store/types';
import { get } from 'utils/api';
import { parseFixtureData } from './parser';

export const getFixturesReport = createAsyncThunk(
  'GET_FIXTURES_REPORT',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { table } = params;

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.START,
        payload: {
          params,
          table
        }
      });

      const res = await get('/reports/fixtures', params);

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: {
          data: { ...res.data, data: parseFixtureData(res.data.data, params) },
          table
        }
      });

      return { data: res?.data, report_type: res.report_type };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
