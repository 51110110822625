import CollapseBox from 'common/components/collapse/CollapseBox';
import SvgRender from 'common/components/general/SvgRender';
import ShadowBox from 'common/components/general/ShadowBox';
import CircledButton from 'common/components/buttons/CircledButton';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Row, Col } from 'reactstrap';

import { icons } from './helpers';
import widgetsDetails from 'views/dashboard/widgets/widgets-details';
import rearrange from 'common/assets/svg/actions/rearrange.svg';
import arrayMove from 'array-move';

const EmptyState = ({ placement, layout }) => {
  return (
    <div className="h-100p d-flex flex-column align-items-center justify-content-center">
      <SvgRender src={icons[layout]} style={{ width: 40, height: 40 }} className="text-yellow" />
      <div className="mt-2 text-center text-violet fs-12">
        No widgets at the {placement} part.
        <br /> Select widgets from the right part of the sidebar.
      </div>
    </div>
  );
};

const DragHandler = SortableHandle(() => (
  <div className="d-flex align-items-center cursor-pointer">
    <SvgRender src={rearrange} style={{ width: 10, height: 10 }} />
  </div>
));

const SortableItem = SortableElement(({ widget, onRemove }) => {
  const icon = widgetsDetails[widget.label]?.icon;

  return (
    <Row className="align-items-center mb-1 flex-nowrap" noGutters>
      <Col xs="auto">
        <DragHandler />
      </Col>
      <Col className="ps-2 flex-1">
        <ShadowBox
          className="height-32 ps-2 pe-1 d-flex align-items-center text-primary overflow-hidden widget-layout-box__item"
          color="light-1"
          flat
          whiteOnHover
        >
          {icon ? (
            <SvgRender className="me-1 text-primary" src={icon} style={{ width: 18, height: 18 }} />
          ) : null}
          <strong className="fs-14 text-truncate fw-medium">{widget.name || widget.title}</strong>
          <CircledButton
            style={{ width: 16, height: 16 }}
            type="remove"
            className="ms-auto widget-layout-box__item-remove"
            onClick={() => onRemove(widget.index)}
          />
        </ShadowBox>
      </Col>
    </Row>
  );
});

const SortableList = SortableContainer(({ widgets, onRemove }) => {
  return (
    <div className="widget-layout-box__container gray-scrollbar mb-1">
      {widgets.map((widget, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          widget={{ ...widget, index }}
          onRemove={onRemove}
        />
      ))}
    </div>
  );
});

const LayoutBox = ({ layout, placement, header, widgets, onReorder, onRemove }) => {
  const hasWidgets = widgets?.length;

  return (
    <CollapseBox
      header={
        <div className="d-flex align-items-center">
          <strong className="text-primary fs-10">{header}</strong>
          {hasWidgets ? (
            <SvgRender
              src={icons[layout]}
              style={{ width: 16, height: 16 }}
              className="text-yellow ms-auto me-2"
            />
          ) : null}
        </div>
      }
      className={`widget-layout-box d-flex flex-column mb-2 ${!hasWidgets ? 'empty' : ''}`}
      bodyClassName="bg-transparent"
      headerClassName="bg-transparent"
      isDefaultOpened={true}
      hideExpandButton={true}
      expandButton={false}
      innerExpandButton={false}
    >
      {!hasWidgets ? (
        <EmptyState layout={layout} placement={placement} />
      ) : (
        <SortableList
          axis="y"
          helperClass="widget-layout-box--sortable"
          widgets={widgets}
          useDragHandle={true}
          onSortEnd={({ oldIndex, newIndex }) =>
            onReorder(arrayMove(widgets, oldIndex, newIndex), layout)
          }
          onRemove={index => onRemove(index, layout)}
        />
      )}
    </CollapseBox>
  );
};

export default LayoutBox;
