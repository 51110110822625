import moment from 'moment';
import TYPES from './types';

import { generateFormPlanningRowUID } from 'views/settings/forms/planning/helpers';

const INITIAL_STATE = {
  isFetching: false,
  isSaving: false,
  data: [],
  filters: {},
  selectedYear: moment().format('YYYY')
};

const getUpdatedData = (data, form_uuid, payload) => {
  return data.map(formPlanningSetting => {
    if (formPlanningSetting.form_uuid === form_uuid) {
      const updated = { ...formPlanningSetting, ...payload };

      if (payload.dates) {
        updated.dates = payload.dates.map(date =>
          moment(date.date ? date.date : date, 'YYYY-MM-DD')
        );
      }

      return updated;
    }

    return formPlanningSetting;
  });
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SET_FORM_PLANNING_SETTINGS_SELECTED_YEAR:
      return {
        ...state,
        selectedYear: payload
      };

    case TYPES.SET_FORM_PLANNING_SETTINGS_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.label]: payload.value
        }
      };

    case TYPES.SET_FORM_PLANNING_SETTINGS_SORTING:
      return {
        ...state,
        sorting: payload
      };

    case TYPES.UPDATE_FORM_PLANNING_SETTING.START:
      return {
        ...state,
        data: getUpdatedData(state.data, payload.form_uuid, { dates: payload.dates }),
        isSaving: true
      };

    case TYPES.UPDATE_FORM_PLANNING_SETTING.SUCCESS:
      return {
        ...state,
        isSaving: false,
        data: getUpdatedData(state.data, payload.form_uuid, payload)
      };

    case TYPES.UPDATE_FORM_PLANNING_SETTING.ERROR:
      return {
        ...state,
        isSaving: false
      };

    case TYPES.GET_FORM_PLANNING_SETTINGS.START:
      return {
        ...state,
        isFetching: true
      };

    case TYPES.GET_FORM_PLANNING_SETTINGS.SUCCESS:
      const data = payload.data.map(setting => ({
        ...generateFormPlanningRowUID(setting),
        ...setting,
        dates: setting.dates.map(date => moment(date.date, 'YYYY-MM-DD'))
      }));

      return {
        ...state,
        isFetching: false,
        data: data
      };

    case TYPES.GET_FORM_PLANNING_SETTINGS.ERROR:
      return {
        ...state,
        isFetching: false
      };

    default:
      return state;
  }
};

export default reducer;
