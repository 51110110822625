import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('ARCHIVE_CHARTER_PARTY'),
  ...asyncTypes('UNARCHIVE_CHARTER_PARTY'),
  ...asyncTypes('GET_CHARTER_PARTY'),
  ...asyncTypes('UPDATE_CHARTER_PARTY'),
  ...asyncTypes('CREATE_CHARTER_PARTY'),
  ...asyncTypes('DOWNLOAD_CHARTER_PARTY_ATTACHMENTS'),
  ...asyncTypes('GET_ESTIMATED_DELIVERY'),
  ...asyncTypes('GET_OFF_HIRE_EVENTS'),
  ...asyncTypes('CREATE_CHARTER_PARTY_EVALUATION'),
  ...asyncTypes('CREATE_SUBCHARTER_PARTY_EVALUATION'),
  ...asyncTypes('GET_CHARTER_PARTY_EVALUATION'),
  ...asyncTypes('GET_SUBCHARTER_PARTY_EVALUATION'),
  ...asyncTypes('UPDATE_CHARTER_PARTY_EVALUATION'),
  SET_CHARTER_PARTY_TYPE: 'SET_CHARTER_PARTY_TYPE',
  SET_CHARTER_PARTY_EVALUATION_DRAWER: 'SET_CHARTER_PARTY_EVALUATION_DRAWER',
  SET_CHARTER_PARTY_FORM_MODE: 'SET_CHARTER_PARTY_FORM_MODE'
};

export default TYPES;
