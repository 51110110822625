import { createSlice } from '@reduxjs/toolkit';
import { getPartiesSimilarityAction, getCrewDocumentsSimilarityAction } from './actions';
import { PartySimilarityType } from '@/common/types/parties';
import { CrewDocumentySimilarityType } from '@/common/types/crew';

type PartyFieldValuesType = {
  first_name?: string | null;
  middle_name?: string | null;
  last_name?: string | null;
  company_name?: string | null;
  phone?: string | null;
  email?: string | null;
};

type DocumentFieldValuesType = {
  issued_at?: string | null;
  crew_document_type_id?: number | null;
  issue_auths?: string[];
  grade?: string | null;
  periodicity?: string | null;
  code?: string | null;
  expires_at?: string | null;
  attachment_ids?: number[];
  country_id?: number | null;
  category?: string | null;
  comments?: string | null;
  is_archived?: boolean;
  number_of_doses?: number | null;
  manufacturer?: string | null;
  paper_agent?: string | null;
  crew_rank_id?: number | null;
};

export type FieldValuesType =
  | ({ type: 'document' } & DocumentFieldValuesType)
  | ({ type: 'person' | 'company' } & PartyFieldValuesType);

export type SimilaritiesState = {
  data: PartySimilarityType[] | CrewDocumentySimilarityType[];
  fieldValues: FieldValuesType | null;
  isLoading: boolean;
};

const INITIAL_STATE: SimilaritiesState = {
  data: [],
  fieldValues: null,
  isLoading: false
};

const slice = createSlice({
  name: 'similarities',
  initialState: INITIAL_STATE,
  reducers: {
    triggerModalBySetFieldValues: (state, { payload }: { payload: FieldValuesType | null }) => {
      state.fieldValues = payload;

      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getPartiesSimilarityAction.pending, state => {
        state.data = [];
        state.isLoading = true;

        return state;
      })
      .addCase(getPartiesSimilarityAction.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? [];
        state.isLoading = false;

        return state;
      })
      .addCase(getPartiesSimilarityAction.rejected, state => {
        state.data = [];
        state.isLoading = false;

        return state;
      })
      .addCase(getCrewDocumentsSimilarityAction.pending, state => {
        state.data = [];
        state.isLoading = true;

        return state;
      })
      .addCase(getCrewDocumentsSimilarityAction.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.isLoading = false;

        return state;
      })
      .addCase(getCrewDocumentsSimilarityAction.rejected, state => {
        state.data = [];
        state.isLoading = false;

        return state;
      });
  }
});

export const { triggerModalBySetFieldValues } = slice.actions;

export default slice.reducer;
