import { stateHasErrors } from 'common/utils/hooks/useForm/helpers';
import TYPES from './types';

const INITIAL_STATE = {
  tags: [],
  comparisons: [],
  loading: false,
  selectedComparison: null,
  data: null,
  dataLoading: false
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.DELETE_COMPARISON.SUCCESS:
      return {
        ...state,
        comparisons: state.comparisons.filter(el => el.id !== payload.id),
        selectedComparison: state.comparisons.find(el => el.id !== payload.id)?.id
      };
    case TYPES.GET_COMPARISON_DATA.START:
      return {
        ...state,
        dataLoading: true
      };
    case TYPES.GET_COMPARISON_DATA.SUCCESS:
      return {
        ...state,
        data: payload,
        dataLoading: false
      };
    case TYPES.GET_COMPARISON_DATA.ERROR:
      return {
        ...state,
        data: null,
        dataLoading: false
      };
    case TYPES.GET_VESSEL_TAGS.START:
      return {
        ...state,
        tags: []
      };
    case TYPES.GET_VESSEL_TAGS.SUCCESS:
      return {
        ...state,
        tags: payload.map(el => ({ ...el, id: el.label }))
      };

    case TYPES.GET_COMPARISONS.START:
      return {
        ...state,
        loading: true
      };

    case TYPES.GET_COMPARISONS.SUCCESS:
      let newComp =
        state.selectedComparison && !payload.changeSelected
          ? state.selectedComparison
          : payload.data.length
          ? payload.data[0].id
          : null;
      return {
        ...state,
        comparisons: payload.data,
        data: newComp ? state.data : null,
        selectedComparison: newComp,
        loading: false
      };

    case TYPES.GET_COMPARISONS.ERROR:
      return {
        ...state,
        loading: false
      };

    case TYPES.SET_SELECTED_COMPARISON:
      return {
        ...state,
        selectedComparison: payload
      };

    default:
      return state;
  }
};

export default reducer;
