import { Collapse } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import LinkedFindings from 'common/components/link-entities/findings/LinkedFindings';
import {
  selectActiveRequisitionFindings,
  selectCollapsedFindingsIsCollapsed,
  selectCollapsedFindingsMode,
  selectActiveRequisitionID,
  selectShowRemarksAndAttachments
} from 'common/components/purchasing/requisition/store/selectors';
import { unlinkFinding } from 'common/components/purchasing/requisition/store/actions';

const Findings = ({ isSummary }) => {
  const linkedFindings = useSelector(selectActiveRequisitionFindings);
  const isCollapsed = useSelector(selectCollapsedFindingsIsCollapsed);
  const collapsedMode = useSelector(selectCollapsedFindingsMode);
  const showRemarksAndAttachments = useSelector(selectShowRemarksAndAttachments);

  const requisitionId = useSelector(selectActiveRequisitionID);
  const dispatch = useDispatch();

  const onUnlink = async id => {
    if (!id || !requisitionId) return;

    try {
      await dispatch(unlinkFinding({ requisitionId, id })).unwrap();
    } catch (err) {
      console.error(err);
    }
  };

  const mode = isSummary ? 'summary' : 'requisition';

  if (!linkedFindings?.length) return;

  return (
    <Collapse
      className={isSummary ? 'mt-2' : showRemarksAndAttachments ? 'mb-2' : 'mt-3'}
      isOpen={!isCollapsed && collapsedMode === mode}
    >
      <LinkedFindings
        findingsState={linkedFindings}
        isSingleLine={!isSummary}
        onUnlink={onUnlink}
      />
    </Collapse>
  );
};

export default Findings;

Findings.propTypes = {
  isSummary: PropTypes.bool
};
