import React from 'react';
import { Button } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';

const CtaBox = ({ title, icon, firstLine, secondLine, thirdLine, cta, onClick, className }) => {
  return (
    <div className={`cta-box ${className || ''}`}>
      {title ? <div className="cta-box__title">{title}</div> : null}
      <div className="cta-box__main">
        {icon ? (
          <div className="cta-box__icon">
            <SvgRender style={{ width: 56, height: 56 }} src={icon} />
          </div>
        ) : null}
        <div className="cta-box__first-line">{firstLine}</div>
        {secondLine ? <div className="cta-box__second-line">{secondLine}</div> : null}
        <div className="cta-box__seperator"></div>
        {thirdLine ? <div className="cta-box__third-line">{thirdLine}</div> : null}
        <div className="cta-box__button">
          <Button
            color="primary"
            className="fs-12 lh-1 d-inline-flex align-items-center cps-12 cpe-12"
            onClick={onClick}
          >
            {cta}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CtaBox;
