import { get } from 'utils/api';

import TABLE_TYPES from 'common/components/table/store/types';
import { parseMainEngineReportData } from './parser';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getMainEngineReportTableData = createAsyncThunk(
  'GET_VESSEL_CII_PROFILE',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.START,
        payload: {
          params,
          table: 'reports_main_engine_piston_and_cylinder_liner_wear_down'
        }
      });

      const res = await get('reports/main-engine-piston-and-cylinder-liner-wear-down', params);

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: {
          data: { ...res.data, data: parseMainEngineReportData(res.data.data) },
          table: 'reports_main_engine_piston_and_cylinder_liner_wear_down'
        }
      });

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
