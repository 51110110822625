import _get from 'lodash/get';
import PriceZoneGroupHeader from 'views/purchasing/pricelist/form/items/table/PriceZoneGroupHeader';
import PriceZoneGroupDiscount from 'views/purchasing/pricelist/form/items/table/PriceZoneGroupDiscount';

export const parsePurchasingPricelistItems = data => {
  const columns = _get(data, `[0].zones`, []) || []; // Get zones from the first item to generate the dynamic columns

  const dynamicColumns = columns.map((zone, index) => ({
    header: <PriceZoneGroupHeader priceZone={zone.price_zone} />,
    className: `position-relative price-zone index-${(index + 1) % 10}`,
    key: `price_zone_${zone.price_zone_id}`,
    columns: [
      {
        header: 'Price',
        key: 'price',
        minWidth: 96,
        maxWidth: 96,
        priceZoneId: zone.price_zone_id,
        headerClassName: 'cpt-4'
      },
      {
        header: <PriceZoneGroupDiscount priceZone={zone.price_zone} />,
        key: 'discount',
        minWidth: 96,
        maxWidth: 96,
        priceZoneId: zone.price_zone_id,
        headerClassName: 'cpt-4'
      }
    ]
  }));

  const expandedPriceZoneDiscounts = [];

  const { tableData, items } = data.reduce(
    (acc, cur) => {
      const { zones, id, ...rest } = cur;
      const tableItem = { ...rest, zones: [] };

      acc.items[id] = {};

      zones.forEach(zone => {
        tableItem.zones.push(zone.price_zone_id);
        acc.items[id][zone.price_zone_id] = zone;

        if (zone.discount && !expandedPriceZoneDiscounts.includes(zone.price_zone_id)) {
          expandedPriceZoneDiscounts.push(zone.price_zone_id);
        }
      });

      acc.tableData.push({ ...tableItem, id });

      return acc;
    },
    { tableData: [], items: {} }
  );

  return {
    columns: dynamicColumns,
    data: tableData,
    items,
    expandedPriceZoneDiscounts
  };
};
