import { combineReducers } from 'redux';

import vettingStatistics from './vetting-statistics/slice';
import findings from 'common/components/findings/store/slice';
import lubOilAnalysis from './lub-oil-analysis/slice';
import underwaterServicesFleet from 'store/reports/events/underwater-services-fleet/slice';

export default combineReducers({
  vettingStatistics,
  findings,
  lubOilAnalysis,
  underwaterServicesFleet
});
