import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_MGA_ITEMS'),
  ...asyncTypes('GET_MGA_SETTINGS'),
  ...asyncTypes('EDIT_MGA_SETTINGS'),
  SET_CONNECTED_ACCOUNT_FORM: 'SET_CONNECTED_ACCOUNT_FORM'
};

export default TYPES;
