import TYPES from './types';

const INITIAL_STATE = {
  active_review_process: []
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_REVIEW_PROCESS_TEMPLATE.START:
      return {
        ...state
      };

    case TYPES.GET_REVIEW_PROCESS_TEMPLATE.SUCCESS:
      return {
        ...state,
        active_review_process: payload
      };

    case TYPES.GET_REVIEW_PROCESS_TEMPLATE.ERROR:
      return {
        ...state
      };

    default:
      return state;
  }
};

export default reducer;
