import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'reactstrap';
import moment from 'moment';

import Table from './table';
import Graph from './graph';
import Filters from './components/Filters';

const VesselTCE = ({
  dates,
  onDateChange,
  marketIndex,
  indexOptions,
  onMarketIndexSelectChange,
  chartData,
  tceResults,
  view,
  onViewChange,
  fetchTceResults,
  loading,
  profile,
  widths = [5, 7],
  averages,
  hideGraph = false,
  vessel,
  vesselId,
  onVesselChange,
  showAllFilters = true
}) => {
  return (
    <Row className="vessel-tce-results">
      <Col xs={12} className="d-flex align-items-center">
        <Filters
          showAllFilters={showAllFilters}
          dates={dates}
          vessel={vessel}
          onVesselChange={onVesselChange}
          onDateChange={onDateChange}
          marketIndex={marketIndex}
          indexOptions={indexOptions}
          onMarketIndexSelectChange={onMarketIndexSelectChange}
          view={view}
          onViewChange={onViewChange}
          widths={widths}
          dateOptions={[
            {
              label: 'Year to date',
              startDate: moment().startOf('year').hour(0).minute(0),
              endDate: moment()
            },
            {
              label: 'Last Month',
              startDate: moment().subtract(1, 'months').startOf('month'),
              endDate: moment().subtract(1, 'months').endOf('month')
            },
            {
              label: 'Last 30 days',
              startDate: moment().subtract(30, 'days').hour(0).minute(0),
              endDate: moment()
            },
            {
              label: 'Last 3 months',
              startDate: moment().subtract(3, 'months').hour(0).minute(0),
              endDate: moment()
            },
            {
              label: 'Last 12 months',
              startDate: moment().subtract(12, 'months').hour(0).minute(0),
              endDate: moment()
            }
          ]}
        />
      </Col>
      <Col xl={widths[0]} lg={12} md={12} xs={12}>
        {!hideGraph ? (
          <Graph
            dates={dates}
            chartData={chartData}
            tableHasData={tceResults.length ? true : false}
          />
        ) : null}
      </Col>
      <Col xl={widths[1]} lg={12} md={12} xs={12}>
        <Table
          id="tce-results-table"
          view={view}
          fetchTceResults={fetchTceResults}
          tceResults={tceResults}
          tableFilters={{
            dates,
            view,
            marketIndex,
            vessel
          }}
          loading={loading}
          name={profile.name}
          vesselId={vesselId}
          averages={averages}
        />
      </Col>
    </Row>
  );
};

export default VesselTCE;

const Average = PropTypes.shape({
  commission: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  difference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  difference_percent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  estimated_tce_net: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  market_tce_adjusted_net: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  market_tce_net: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tce_net: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
});

VesselTCE.propTypes = {
  dates: PropTypes.shape({
    from: PropTypes.object.isRequired, // Moment Object
    to: PropTypes.object.isRequired // Moment Object
  }),
  onDateChange: PropTypes.func.isRequired,
  marketIndex: PropTypes.number,
  indexOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  onMarketIndexSelectChange: PropTypes.func.isRequired,
  chartData: PropTypes.shape({
    data: PropTypes.shape({
      graph: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string.isRequired,
          estimated_tce_net: PropTypes.string || PropTypes.number,
          market_tce_adjusted_net: PropTypes.string || PropTypes.number,
          tce_net: PropTypes.string || PropTypes.number
        }).isRequired
      )
    }).isRequired
  }).isRequired,
  tceResults: PropTypes.arrayOf(
    PropTypes.shape({
      commission: PropTypes.string || PropTypes.number,
      date_from: PropTypes.string.isRequired,
      date_to: PropTypes.string.isRequired,
      difference: PropTypes.string || PropTypes.number,
      difference_percent: PropTypes.string || PropTypes.number,
      estimated_tce_net: PropTypes.string || PropTypes.number,
      key: PropTypes.string.isRequired,
      key_type: PropTypes.string.isRequired,
      market_tce_adjusted_net: PropTypes.string || PropTypes.number,
      market_tce_gross: PropTypes.string || PropTypes.number,
      market_tce_net: PropTypes.string || PropTypes.number,
      on_pool: PropTypes.bool,
      tce_gross: PropTypes.string || PropTypes.number,
      tce_net: PropTypes.string || PropTypes.number
    })
  ),
  view: PropTypes.oneOf(['monthly', 'daily']),
  vessel: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  onViewChange: PropTypes.func.isRequired,
  fetchTceResults: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  profile: PropTypes.object,
  widths: PropTypes.arrayOf(PropTypes.number),
  averages: PropTypes.shape({
    data: PropTypes.shape({
      averages: PropTypes.arrayOf(Average),
      per_year: PropTypes.arrayOf(Average)
    })
  }),
  useSmallColumns: PropTypes.bool
};
