import PropTypes from 'prop-types';
import ShadowBox from 'common/components/general/ShadowBox';
import Breadcrumbs from 'common/components/tree-layout/components/Breadcrumbs';
import ContentLoader from 'react-content-loader';
import CircledButton from 'common/components/buttons/CircledButton';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  selectBeaconDrawerSearchIsLoading,
  selectBeaconDrawerSearchResults,
  selectBeaconDrawerSearchText
} from 'store/beacon/sidebar/selectors';
import { selectMenuBreadcrumbs } from 'common/components/beacon/store/selectors';
import { highlightTextInTitle } from 'manuals/_helpers';
import { useMemo } from 'react';
import { useActions } from 'utils/hooks';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

import searchSvg from 'common/assets/svg/common/search.svg';
import SvgRender from 'common/components/general/SvgRender';

import { setActivePostIdAction } from 'common/components/beacon/store/slice';
import * as tableActions from 'common/components/table/store/actions';

const Loader = () => {
  return (
    <ContentLoader
      speed={1}
      height="86"
      width="100%"
      preserveAspectRatio="xMidYMid meet"
      backgroundColor="#f8f9fb"
      foregroundColor="#ebebeb"
      title="Loading posts"
    >
      <rect x="0" y="0" rx="4" ry="4" width="100%" height="26" />
      <rect x="0" y="30" rx="4" ry="4" width="100%" height="26" />
      <rect x="0" y="60" rx="4" ry="4" width="100%" height="26" />
    </ContentLoader>
  );
};

const PostBreadcrumbs = ({ menuId }) => {
  const breadcrumbs = useSelector(state => selectMenuBreadcrumbs(state, menuId));

  return (
    <Breadcrumbs
      breadcrumbs={breadcrumbs}
      maxLimit={2}
      render={menu => <span className="fs-13 text-dark fw-normal">{menu.title}</span>}
    />
  );
};

PostBreadcrumbs.propTypes = {
  menuId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

const PostTitle = ({ post }) => {
  const searchValue = useSelector(selectBeaconDrawerSearchText);
  const isHighlighted = useMemo(
    () => !post.title.toLowerCase().includes(searchValue.toLowerCase()),
    [searchValue, post.title]
  );

  return (
    <span css={isHighlighted ? highlightedTitle : null} className="fs-13 text-dark">
      {highlightTextInTitle(post.title, searchValue)}
    </span>
  );
};

PostTitle.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired
};

const EmptyPage = () => {
  return (
    <div className="w-100p h-100p d-flex align-items-center justify-content-center flex-column">
      <SvgRender
        src={searchSvg}
        style={{ width: 80, height: 80, marginTop: '15vh' }}
        className="text-light-gray-2 mb-4"
      />
      <strong className="text-violet fs-18 fw-medium">No results found</strong>
    </div>
  );
};

const SearchResults = () => {
  const isSearching = useSelector(selectBeaconDrawerSearchIsLoading);
  const results = useSelector(selectBeaconDrawerSearchResults);
  const [setActivePostId, setTablePageSearch] = useActions([
    setActivePostIdAction,
    tableActions.setTablePageSearch
  ]);

  const onPostView = id => {
    setActivePostId(id);
    setTablePageSearch({ text: '', table: 'beacon_posts' });
  };

  return (
    <ScrollableContainer className="gray-scrollbar">
      {!isSearching && !results?.length ? (
        <EmptyPage />
      ) : isSearching ? (
        <Loader />
      ) : (
        results.map(post => (
          <ShadowBox
            key={post.id}
            color="light-2 cpy-4 cps-12 cpe-12 cmb-2 min-height-24 d-flex align-items-center position-relative"
            whiteOnHover
            flat
          >
            <Row className="align-items-center w-100p">
              <Col xs={6}>
                <PostTitle post={post} />
              </Col>
              <Col xs={6}>
                <PostBreadcrumbs menuId={post.menu_id} />
              </Col>
            </Row>
            <CircledButton
              type="view"
              className="position-absolute right-0 me-1"
              style={{ width: 18, height: 18 }}
              onClick={() => onPostView(post.id)}
            />
          </ShadowBox>
        ))
      )}
    </ScrollableContainer>
  );
};

const ScrollableContainer = styled.div`
  max-height: 93vh;
  overflow-y: auto;
  margin-left: -${variables.size4};
  padding-left: ${variables.size4};
  margin-top: -${variables.size4};
  padding-top: ${variables.size4};

  .shadow-box-wrap {
    .circled-button.view {
      opacity: 0;
    }

    &:hover {
      .circled-button.view {
        opacity: 1;
      }
    }
  }
`;

const highlightedTitle = css`
  background-color: rgba(254, 221, 0, 0.1);
  padding: ${variables.size2} ${variables.size4};
  border: 1px solid ${variables.butterYellow};
  border-radius: 5px;
  margin-left: -${variables.size8};
`;

export default SearchResults;
