import React from 'react';

const GraphLegend = ({ title, solid, color, textClassName = '' }) => (
  <div className={`d-flex justify-content-start align-items-center w-auto flex-nowrap`}>
    <div className={`circle me-1 ${solid ? 'circle-solid' : 'circle-dashed'} circle-${color}`} />
    <span className={`fw-normal text-nowrap fs-12 text-${color} ${textClassName}`}>{title}</span>
  </div>
);

export default GraphLegend;
