import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import EvaluatorDepartment from 'crew/components/EvaluatorDepartment';
import EvaluationDaysLeft from 'crew/components/EvaluationDaysLeft';
import crewPlanning from 'common/assets/svg/common/crew_planning.svg';
import paths from 'routing/routes/_paths';
import { useSelector } from 'react-redux';
import { selectWidgetState } from 'store/dashboards/selectors';

import _get from 'lodash/get';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

const PendingEvaluations = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));

  return (
    <div>
      <WidgetTitle id={id} linkTo={paths.crew_evaluation_obligations} />
      <WidgetDefaultTable
        id={id}
        columns={[
          {
            header: 'reason',
            key: 'reason',
            type: 'string',
            headerClassName: 'text-violet',
            width: 3
          },
          {
            header: 'evaluator',
            key: 'evaluator',
            type: 'collection',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'evaluatee',
            key: 'evaluatee',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'rank',
            key: 'crew_rank',
            type: 'collection',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'vessel',
            key: 'vessel',
            type: 'string',
            headerClassName: 'text-violet'
          },
          {
            header: 'days left',
            key: 'days_left',
            type: 'date',
            headerClassName: 'text-violet'
          },

          {
            header: 'Actions',
            key: 'actions',
            field: 'actions'
          }
        ]}
        rows={{
          reason: data => {
            return <span>{data.reason.name}</span>;
          },
          evaluator: data => {
            const evaluatorType = _get(data, 'evaluator_type', false);
            const evaluator = _get(data, 'evaluator', '');

            return evaluatorType === 'crew_member' ? (
              <span>{_get(evaluator, 'full_name', '')}</span>
            ) : (
              <EvaluatorDepartment evaluator={evaluator} evaluatorType={evaluatorType} />
            );
          },
          evaluatee: data => <TextWithTooltip>{data.evaluatee.full_name}</TextWithTooltip>,
          crew_rank: data => <TextWithTooltip>{data.crew_rank?.name ?? '-'}</TextWithTooltip>,
          vessel: data => {
            return data.vessel.name;
          },
          days_left: data => <EvaluationDaysLeft data={data} className="ms-1" />,
          actions: data => ({
            options: [
              {
                wrapperClassName: 'bg-primary',
                className: 'text-white',
                label: 'Evaluate Seaman',
                icon: crewPlanning,
                onClick: () =>
                  window.open(
                    `${paths.crew}/${data.evaluatee.id}/evaluation/create?obligation_id=${data.id}`
                  )
              }
            ]
          })
        }}
        label={label}
        state={state}
      />
    </div>
  );
};

export default PendingEvaluations;
