import { createSlice } from '@reduxjs/toolkit';
import { getMainEngineReportTableData } from './actions';
const INITIAL_STATE = {
  data: [],
  isFetching: false
};

const slice = createSlice({
  name: 'mainEngineReport',
  initialState: INITIAL_STATE,

  extraReducers: builder => {
    builder
      .addCase(getMainEngineReportTableData.pending, state => {
        state.isFetching = true;
        return state;
      })

      .addCase(getMainEngineReportTableData.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.data = payload.data;
        return state;
      })

      .addCase(getMainEngineReportTableData.rejected, state => {
        state.isFetching = false;
        return state;
      });
  }
});

export default slice.reducer;
