import { CrewContract, CrewDocumentySimilarityType, CrewSeaman } from '@/common/types/crew';
import { TableRequestParams } from '@/common/types/front-entities/table';
import { get, put } from '@/utils/api';
import { RequestParamsType, TableQueryPaginatedResponse } from '@webthatmatters/orca-table';
import { FinalizedRotation } from '@/common/types/crew.ts';

export type GetCrewDocumentsSimilarityType = {
  id: number;
  issued_at?: string | null;
  crew_document_type_id?: number | null;
  issue_auths?: string[];
  grade?: string | null;
  periodicity?: string | null;
  code?: string | null;
  expires_at?: string | null;
  attachment_ids?: number[];
  country_id?: number | null;
  category?: string | null;
  comments?: string | null;
  is_archived?: boolean;
  number_of_doses?: number | null;
  manufacturer?: string | null;
  paper_agent?: string | null;
  crew_rank_id?: number | null;
};

export const getCrewDocumentsSimilarity = async (params: GetCrewDocumentsSimilarityType) => {
  const { id, ...rest } = params;
  const response = await get<CrewDocumentySimilarityType[]>(
    `/crew/${id}/documents/similarity`,
    rest
  );

  return response.data;
};

export const getCrewSeamen = async (params: RequestParamsType) => {
  const response = await get<TableQueryPaginatedResponse<CrewSeaman>>(`/crew`, params);

  return response.data;
};

export const getCrewProfileContractsList = async (
  params: TableRequestParams & { crewId: number }
) => {
  const { crewId, ...rest } = params;
  return (await get<{ data: CrewContract[] }>(`crew/${crewId}/contracts-list`, rest)).data;
};

export type UpdateBulkCrewContractsParams = {
  id: number | null | undefined;
  contract_from_date: string | null;
  initial_contract_to_date: string | null;
  left_home_date?: string | null;
  arrived_home_date?: string | null;
  sign_off_date_as_per_office?: string | null;
  sign_on_port_id_as_per_office: number | null;
  sign_off_port_id_as_per_office: number | null;
  sign_off_reason_id: number | null;
  crew_member_id: number | null;
  vessel_id: number | null;
  rank_id: number | null;
};

export const updateBulkCrewContracts = async (params: UpdateBulkCrewContractsParams[]) => {
  return (await put<{ data: CrewContract[] }>(`/crew-contracts`, params)).data;
};

export type SwitchBulkCrewRotationsParams = {
  rotation_ids: number[];
  only_check: boolean;
};

export const switchBulkFinalizedCrewContracts = async (params: SwitchBulkCrewRotationsParams) => {
  const response = await put<FinalizedRotation[]>(`/crew-rotation/finalize`, params);
  return response.data;
};
