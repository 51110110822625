import { getLubOilAnalysisReport } from './actions';
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isFetching: false,
  assignments: [],
  months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  events: {} // Events per month -> 0: [], 1: [], ... 11: [] - where key is the month index
};

const slice = createSlice({
  name: 'lubOilAnalysis',
  initialState: INITIAL_STATE,
  extraReducers: builder => {
    builder
      .addCase(getLubOilAnalysisReport.pending, state => {
        state.isFetching = true;

        return state;
      })
      .addCase(getLubOilAnalysisReport.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.assignments = payload?.data?.assignments || [];
        state.events = payload?.data?.events || {};

        return state;
      })
      .addCase(getLubOilAnalysisReport.rejected, state => {
        state.isFetching = false;
        state.assignments = [];
        state.events = {};

        return state;
      });
  }
});

export default slice.reducer;
