import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_MANUALS_CHANGE_REQUESTS'),
  ...asyncTypes('APPROVE_CHANGE_REQUEST'),
  ...asyncTypes('REOPEN_CHANGE_REQUEST'),
  ...asyncTypes('DELETE_CHANGE_REQUEST'),
  ...asyncTypes('BULK_DELETE_CHANGE_REQUESTS'),
  ...asyncTypes('BULK_APPROVE_CHANGE_REQUESTS'),
  ...asyncTypes('CREATE_NEW_VERSION'),
  ...asyncTypes('DELETE_PENDING_FOR_APPROVAL_VERSION'),
  ...asyncTypes('RELEASE_PENDING_FOR_APPROVAL_VERSION'),
  ...asyncTypes('GET_MANUALS_CHANGE_REQUESTS_TOTALS'),
  FILTER_BY_STATUS: 'FILTER_BY_STATUS',
  SET_CHANGE_REQUEST_DELETE_PREVENTION_MODAL: 'SET_CHANGE_REQUEST_DELETE_PREVENTION_MODAL',
  RESET_MANUALS_CHANGE_REQUESTS_TOTALS: 'RESET_MANUALS_CHANGE_REQUESTS_TOTALS',
  SET_NEW_VERSION: 'SET_NEW_VERSION'
};

export default TYPES;
