import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'common/components/general/Spinner';
import styled from '@emotion/styled';
import qrcode from 'common/utils/qrcode';

const QrCode = ({ token }) => {
  const [loading, setLoading] = useState(true);

  const renderQRCode = useCallback(qrToken => {
    setLoading(true);
    document.getElementById('qrcode').innerHTML = '';
    const qrCodeEl = new qrcode('qrcode', { height: 120, width: 120 });
    qrCodeEl.makeCode(qrToken);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (token) {
      // Generate the qrcode and update it every time we get a new one token
      renderQRCode(token);
    }
  }, [token, renderQRCode]);

  return (
    <Container className="d-flex align-items-center justify-content-center p-1">
      <div id="qrcode" />
      {loading && <Spinner />}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 9.5rem;
  height: 9.5rem;
  box-shadow: -2px 12px 40px 0 rgba(55, 55, 55, 0.4);
  background: #fff;
  border-radius: 19px;
  overflow: hidden;

  .spinner-loader {
    position: absolute;
  }
`;

QrCode.propTypes = {
  token: PropTypes.string.isRequired
};

export default QrCode;
