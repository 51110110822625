import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ErrorBoundaryFallback from '../../ErrorBoundaryFallback';

import { useSelector } from 'react-redux';

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_KEY,
  enabledReleaseStages: ['production', 'staging', 'preproduction'],
  plugins: [new BugsnagPluginReact()],
  appVersion: import.meta.env.VITE_APP_VERSION,
  releaseStage: import.meta.env.VITE_RELEASE_STAGE
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const BugsnagHOC = props => {
  const account = useSelector(state => state.account);

  if (account) Bugsnag.setUser(account.id, account.email, account.full_name);

  const Fallback = () => <ErrorBoundaryFallback history={props.history} />;

  return <ErrorBoundary FallbackComponent={Fallback}>{props.children}</ErrorBoundary>;
};

export default BugsnagHOC;
