import { createSlice } from '@reduxjs/toolkit';
import { getNewAnnouncement } from './actions';

const INITIAL_STATE = {
  post: {},
  isLoading: false
};

const slice = createSlice({
  name: 'news-announcements-settings',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getNewAnnouncement.pending, state => {
        state.post = {};
        state.isLoading = true;

        return state;
      })
      .addCase(getNewAnnouncement.fulfilled, (state, { payload }) => {
        state.post = payload;
        state.isLoading = false;

        return state;
      })
      .addCase(getNewAnnouncement.rejected, state => {
        state.post = {};
        state.isLoading = false;

        return state;
      });
  }
});

export default slice.reducer;
