import moment from 'moment';

export const constructTableData = (data = []) => {
  return data.reduce((acc, item, index) => {
    const isFirstVessel = index === 0 || item.vessel_id !== data[index - 1].vessel_id;
    return [
      ...acc,
      ...(isFirstVessel
        ? [
            {
              vessel_name: item?.vessel_name || '',
              vessel_id: item?.vessel_id || null,
              reports: item?.reports || [],
              isFirstVessel
            }
          ]
        : []),
      { ...item }
    ];
  }, []);
};

const initialColumns = [{ key: 'engines', header: '', width: 490 }];

export const constructColumnsFromDate = (from, to) => {
  const columns = [...initialColumns];

  if (from && to) {
    const fromDate = moment(from);
    const endDate = moment(to);

    while (fromDate <= endDate) {
      const key = fromDate.format('YYYY-MM-DD');
      const header = fromDate.format('DD/MM/YYYY');

      columns.push({
        key,
        header,
        type: 'group',
        width: 75,
        columns: [{ key: 'value', type: 'string', header: '', width: 75 }]
      });

      fromDate.add(1, 'day');
    }
  }

  return columns;
};
