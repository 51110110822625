import { getDepartment } from './actions';
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  active: null
};

const slice = createSlice({
  name: 'departments',
  initialState: INITIAL_STATE,
  extraReducers: builder => {
    builder
      .addCase(getDepartment.pending, state => {
        state.active = null;

        return state;
      })
      .addCase(getDepartment.fulfilled, (state, { payload }) => {
        state.active = payload;

        return state;
      });
  }
});

export default slice.reducer;
