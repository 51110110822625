import { getRetentionRate } from 'store/reports/crew/retention-rate/actions';
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  data: [],
  isFetching: false
};

const slice = createSlice({
  name: 'retentionRateReport',
  initialState: INITIAL_STATE,

  extraReducers: builder => {
    builder
      .addCase(getRetentionRate.pending, state => {
        state.isFetching = true;
        return state;
      })

      .addCase(getRetentionRate.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.data = payload.data;
        return state;
      })

      .addCase(getRetentionRate.rejected, state => {
        state.isFetching = false;
        return state;
      });
  }
});

export default slice.reducer;
