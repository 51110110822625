import React from 'react';
import { Button } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';
import send from 'common/assets/svg/actions/send.svg';
import PropTypes from 'prop-types';

const FormSubmitAction = ({
  onCancel,
  onSave,
  canSubmit,
  className,
  buttonClassName = 'height-24 px-1 d-inline-flex align-items-center'
}) => {
  return (
    <div
      className={`input-save-actions d-flex align-items-center justify-content-end  mt-1 ${className}`}
    >
      {onCancel ? (
        <Button color="cancel" onClick={onCancel} className={`${buttonClassName} me-1`}>
          <span className="fw-medium fs-10">CANCEL</span>
        </Button>
      ) : null}

      {onSave ? (
        <Button
          type="button"
          color="primary"
          disabled={!canSubmit}
          onClick={onSave}
          className={buttonClassName}
        >
          <SvgRender className="text-white me-1" src={send} style={{ width: 12, height: 12 }} />
          <span className="fw-medium fs-10">SUBMIT</span>
        </Button>
      ) : null}
    </div>
  );
};

FormSubmitAction.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  canSubmit: PropTypes.bool,
  className: PropTypes.string,
  buttonClassName: PropTypes.string
};

export default FormSubmitAction;
