import { get } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getVettingStatisticsPerChapter = createAsyncThunk(
  'GET_VETTING_STATISTICS_PER_CHAPTER',
  async (params, { rejectWithValue }) => {
    try {
      const perChapter = await get(
        '/reports/vetting-statistics/graph/observations-per-chapter',
        params
      );

      return { perChapter: perChapter.data };
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

export const getVettingStatisticsPerSeverity = createAsyncThunk(
  'GET_VETTING_STATISTICS_PER_SEVERITY',
  async (params, { rejectWithValue }) => {
    try {
      const perSeverity = await get(
        '/reports/vetting-statistics/graph/observations-per-severity',
        params
      );

      return { perSeverity: perSeverity.data };
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

export const getVettingStatisticsPerRiskRating = createAsyncThunk(
  'GET_VETTING_STATISTICS_PER_RISK_RATING',
  async (params, { rejectWithValue }) => {
    try {
      const perRiskRating = await get(
        '/reports/vetting-statistics/graph/observations-per-risk-rating',
        params
      );

      return { perRiskRating: perRiskRating.data };
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

export const getVettingStatisticsBySupt = createAsyncThunk(
  'GET_VETTING_STATISTICS_BY_SUPT',
  async (params, { rejectWithValue }) => {
    try {
      const bySupt = await get('/reports/vetting-statistics/graph/observations-by-supt', params);

      return { bySupt: bySupt.data };
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

export const getVettingStatisticsPerVessel = createAsyncThunk(
  'GET_VETTING_STATISTICS_PER_VESEEL',
  async (params, { rejectWithValue }) => {
    try {
      const perVessel = await get(
        '/reports/vetting-statistics/graph/observations-per-vessel',
        params
      );

      return { perVessel: perVessel.data };
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

export const getVettingStatistics = createAsyncThunk(
  'GET_VETTING_STATISTICS',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      dispatch(getVettingStatisticsPerChapter(params));
      dispatch(getVettingStatisticsPerSeverity(params));
      dispatch(getVettingStatisticsPerRiskRating(params));
      dispatch(getVettingStatisticsBySupt(params));
      dispatch(getVettingStatisticsPerVessel(params));

      const totals = await get('/reports/vetting-statistics', params);

      return { totals: totals.data };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
