import React from 'react';
import retry from '@/common/utils/lazy-retry.js';
import paths from '@/routing/routes/_paths.js';
import permissions from '@/common/utils/permissions/constants.js';

const RiskAssessment = React.lazy(() =>
  retry(() => import('@/views/settings/hsqe/risk-assessment/index.jsx'))
);
const RiskAssessmentOperations = React.lazy(() =>
  retry(() => import('@/views/settings/hsqe/risk-assessment/operations/index.jsx'))
);

const risk_assessment = [
  {
    path: paths.risk_assessment,
    component: RiskAssessment,
    app: true,
    exact: true,
    type: 'private',
    permissions: [permissions.office.settings.hsqe.risk_assessment.view],
    documentTitle: 'Risk Assessment'
  },
  {
    path: `${paths.risk_assessment}/operations`,
    component: RiskAssessmentOperations,
    app: true,
    exact: true,
    type: 'private',
    permissions: [permissions.office.settings.hsqe.risk_assessment.view],
    documentTitle: 'Risk Assessment Operations'
  }
];

export default risk_assessment;
