import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveCategoryLabel,
  selectIsUnreadTabActive,
  selectSummaryTypeUnreadCount
} from 'store/notifications/selectors';
import { css } from '@emotion/react';
import Value from 'components/layouts/sidebar/panel/notifications/components/unread-tab/Value';
import { getNotifications, setIsUnreadTabActive } from 'store/notifications/actions';

const UnreadTab = () => {
  const dispatch = useDispatch();
  const activeLabel = useSelector(selectActiveCategoryLabel);
  const unreadCount = useSelector(state => selectSummaryTypeUnreadCount(state, activeLabel));
  const isUnreadTabActive = useSelector(selectIsUnreadTabActive);

  const bgColor = isUnreadTabActive ? 'bg-dark' : 'bg-light-gray';
  const textColor = isUnreadTabActive ? 'text-white' : 'text-dark';

  const onGetUnreadNotifications = async () => {
    dispatch(setIsUnreadTabActive(true));
    try {
      const params = {
        current_page: 1,
        type_prefix: activeLabel,
        read: false
      };

      await dispatch(getNotifications(params));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div
      css={css`
        box-shadow: ${isUnreadTabActive ? '-2px 4px 8px 0 rgba(0, 0, 0, 0.1)' : 'none'};
        max-height: 1.375rem;
      `}
      onClick={onGetUnreadNotifications}
      className={`${bgColor} ${textColor} text-capitalize d-flex align-items-center cps-12 cpe-4 cpy-2 fs-12 border-radius-5 fw-medium cursor-pointer cme-6`}
    >
      <div className="me-1">Unread</div>
      <Value count={unreadCount} isActive={isUnreadTabActive} />
    </div>
  );
};

export default UnreadTab;
