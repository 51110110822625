import React from 'react';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import paths from '@/routing/routes/_paths.js';

const Invoices = React.lazy(() => retry(() => import('@/views/accounting/invoices/index.tsx')));
const Contracts = React.lazy(() => retry(() => import('@/views/accounting/contracts/index.tsx')));

const routes = [
  {
    path: `${paths.accounting_invoices}/:id?`,
    component: Invoices,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.accounting.invoices.view],
    documentTitle: 'Invoices'
  },
  {
    path: `${paths.accounting_contracts}`,
    component: Contracts,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.accounting.contracts.view],
    documentTitle: 'Contracts'
  }
];
export default routes;
