import TYPES from './types';

export const INITIAL_STATE = {
  wage_settings: [],
  isFetching: false
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_CREW_WAGE_SETTINGS.START:
      return {
        ...state,
        isFetching: true
      };

    case TYPES.GET_CREW_WAGE_SETTINGS.SUCCESS:
      return {
        ...state,
        wage_settings: payload,
        isFetching: false
      };

    case TYPES.GET_CREW_WAGE_SETTINGS.ERROR:
      return {
        ...state,
        isFetching: false
      };

    default:
      return state;
  }
};

export default reducer;
