import React, { useState, useEffect } from 'react';

import { useActions } from 'utils/hooks';
import { useForm } from 'utils/hooks';
import config from './config';

import * as dashboardsActions from 'store/dashboards/actions';

import useRouter from 'use-react-router';
import DashboardForm from './DashboardForm';
import paths from 'routing/routes/_paths';
import PropTypes from 'prop-types';

const CreateForm = ({ onClose, isOpen }) => {
  const { history } = useRouter();

  const [createDashboard] = useActions([dashboardsActions.createDashboard]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { formState, collectValues, hasErrors, handleSubmitError, resetForm } = useForm(config, {});

  const onSubmit = async params => {
    setIsSubmitting(true);

    try {
      const res = await createDashboard(params);

      onClose();
      resetForm();

      history.push(`${paths.dashboard}/${res.id}`);

      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      handleSubmitError(error);
    }
  };

  useEffect(() => {
    if (isOpen) resetForm();
  }, [isOpen]);

  return (
    <DashboardForm
      formState={formState}
      collectValues={collectValues}
      hasErrors={hasErrors}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      isCreateMode
      onClose={onClose}
    />
  );
};

CreateForm.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool
};

export default CreateForm;
