import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import Priority from 'common/components/general/Priority';
import Status from 'common/components/general/Status';
import emptyRibbon from 'common/assets/svg/common/ribbon-line-colored.svg';
import burger from 'common/assets/svg/common/chapter-colored.svg';
import DueDate from 'common/components/jobs/_base/components/DueDate';
import { Link } from 'react-router-dom';
import eyeSvg from 'common/assets/svg/common/eye.svg';

import paths from 'routing/routes/_paths';
import { useSelector } from 'react-redux';
import { selectWidgetState } from 'store/dashboards/selectors';

import TextWithTooltip from 'common/components/general/TextWithTooltip';
import { Row, Col } from 'reactstrap';

const PmsJobs = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));

  return (
    <div>
      <WidgetTitle id={id} linkTo={paths.pms_jobs} />
      <WidgetDefaultTable
        id={id}
        columns={[
          {
            header: 'system',
            key: 'system',
            type: 'string',
            width: 3,
            headerClassName: 'text-violet'
          },
          {
            header: 'Title',
            key: 'title',
            headerClassName: 'text-violet',
            width: 4
          },
          {
            header: 'Status',
            key: 'status_id',
            type: 'collection',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Type',
            key: 'maintenance_job_type_name',
            type: 'string',
            headerClassName: 'text-violet',
            className: 'overflow-hidden'
          },
          {
            header: 'Due Date',
            key: 'due_date',
            type: 'date',
            headerClassName: 'text-violet'
          },
          {
            header: 'Actions',
            key: 'actions',
            field: 'actions'
          }
        ]}
        rows={{
          system: data => (
            <Row className="flex-nowrap">
              <Col>
                <img
                  alt="vessel system"
                  src={data.assignment_parent_id ? emptyRibbon : burger}
                  width={14}
                  height={14}
                />
              </Col>
              <Col xs="10">
                <TextWithTooltip className="d-flex align-items-center">
                  {data.assignment_description}
                </TextWithTooltip>
              </Col>
              <Col>
                <Priority value={data.importance_id} />
              </Col>
            </Row>
          ),
          title: data => (
            <div className="w-100p overflow-hidden">
              <Link to={`${paths.pms_jobs}/${data.id}`} target="_blank">
                <TextWithTooltip>{data.title}</TextWithTooltip>
              </Link>
            </div>
          ),
          status_id: data => <Status value={data.status_id} />,
          due_date: data => (
            <DueDate
              dueDateChanged={data.last_due_date_change}
              showTime={data.due_date_show_time}
              className="fs-10"
              dueDate={data.due_date}
              createdAt={data.created_at}
              status={data.status_id}
            />
          ),
          maintenance_job_type_name: data => (
            <TextWithTooltip className="d-flex align-items-center">
              {data.maintenance_job_type_name || '-'}
            </TextWithTooltip>
          ),
          actions: data => ({
            options: [
              {
                label: 'View',
                icon: eyeSvg,
                onClick: () => window.open(`${paths.pms_jobs}/${data.id}`)
              }
            ]
          })
        }}
        label={label}
        state={state}
      />
    </div>
  );
};

export default PmsJobs;
