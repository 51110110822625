import { useEffect } from 'react';
import TableSearchText from 'common/components/table/TableSearchText';

import { useActions } from 'utils/hooks';

import * as listsOptions from 'store/lists/actions';
import * as tableActions from 'common/components/table/store/actions';

const WidgetsSearch = ({ isOpen }) => {
  const [fetchListOptions, setTablePageSearch] = useActions([
    listsOptions.fetchListOptions,
    tableActions.setTablePageSearch
  ]);

  useEffect(() => {
    if (isOpen) {
      fetchListOptions('widget-types');
      setTablePageSearch({ text: '', table: 'widget-types' });
    }
  }, [isOpen]);

  return (
    <div className="pb-2 mb-2 border-bottom">
      <TableSearchText
        label="widget-types"
        className="w-100p"
        onSearch={search => fetchListOptions('widget-types', search)}
        grayBg
      />
    </div>
  );
};

export default WidgetsSearch;
