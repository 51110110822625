import {
  fetchConvertedVapidKey,
  createSubscription,
  checkIfUserShouldSubscribe,
  showWebpushModal,
  hideWebpushModal,
  showErrorMessage,
  // testNotification,
  toggleModalDisabled
} from './helpers';
import icon from 'assets/svg/common/notifications-empty.svg';

export const ORCA_WEB_NOTIFICATIONS_DISALLOWED = 'orca_web_notifications_disallowed';

export const checkForSubscription = async () => {
  // check if browser supports service workers in general

  //Do nothing if in safari
  if ('safari' in window && 'pushNotification' in window.safari) {
    return 'safari';
  }

  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.ready;

    // check if browser supports push notifications
    if (!registration.pushManager) {
      // Push manager unavailable
      console.warn('Push notifications not supported');
      // showErrorMessage('Push notifications not supported');
      return;
    }

    const existedSubscription = await registration.pushManager.getSubscription();
    if (existedSubscription === null) {
      return null;
    } else return existedSubscription;
  }
};

export const subscribeUser = async () => {
  try {
    toggleModalDisabled();

    const registration = await navigator.serviceWorker.ready;

    const convertedVapidKey = await fetchConvertedVapidKey();

    const newSubscription = await registration.pushManager.subscribe({
      applicationServerKey: convertedVapidKey,
      userVisibleOnly: true
    });

    await createSubscription(newSubscription);
    const options = {
      body: 'Welcome to orca notifications',
      icon: '/android-chrome-512x512.png'
    };
    toggleModalDisabled();
    hideWebpushModal();
    navigator.serviceWorker.ready.then(function (serviceWorker) {
      serviceWorker.showNotification('Succesfully subscribed', options);
    });
  } catch (err) {
    showErrorMessage('Could not subscribe to push notifications');
    hideWebpushModal();
    toggleModalDisabled();
    console.error('An error ocurred during Service Worker registration.', err);
  }
};

export const checkUserWebpushStatus = async checkAccountStatus => {
  try {
    const existedSubscription = await checkForSubscription();

    if (existedSubscription === 'safari') return;

    // check if user has blocked notifications --> maybe handle it somehow
    if (window.Notification.permission === 'denied') return;

    if (!existedSubscription) {
      let text = 'Do you want to receive notifications?';

      if (checkAccountStatus) {
        /*
        check user account to see if he must receive notifications or
        if he has an active subscription
        */
        const userStatus = await checkIfUserShouldSubscribe();
        if (!userStatus.shouldSubscribe) return;
        if (!userStatus.hasSubscription) {
          text = 'Would you like to receive desktop notifications from Orca tools?';
        }
      }

      let params = {
        title: 'Web Notifications',
        text,
        primaryText: 'YES',
        secondaryText: 'NOT NOW',
        primaryAction: subscribeUser,
        secondaryAction: hideWebpushModal,
        icon
      };

      showWebpushModal(params);
    } else {
      // testNotification();
    }
  } catch (err) {
    console.log('error', err);
  }
};
