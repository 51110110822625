import TYPES from './types';

import { get, post, put } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { getSavedItems } from 'store/sidebar/actions';

export const getTableSearch = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.GET_TABLE_SEARCH.START });

  return get(`/tables/${id}`, rest)
    .then(res => {
      dispatch({ type: TYPES.GET_TABLE_SEARCH.SUCCESS, payload: res.data });

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_TABLE_SEARCH.ERROR, payload: error }));
};

export const createTableSearch = params => (dispatch, getState) => {
  dispatch({ type: TYPES.CREATE_TABLE_SEARCH.START });

  return post(`/tables`, params)
    .then(res => {
      dispatch({ type: TYPES.CREATE_TABLE_SEARCH.SUCCESS });
      dispatch(
        successHandler({
          title: 'Success!',
          message: 'Table search created'
        })
      );
      dispatch(
        getSavedItems({ per_page: getState().sidebar.savedItems.paging.per_page, current_page: 1 })
      );

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.CREATE_TABLE_SEARCH.ERROR, payload: error }));
};

export const updateTableSearch = params => (dispatch, getState) => {
  dispatch({ type: TYPES.UPDATE_TABLE_SEARCH.START });

  return put(`/tables/${params.id}`, params)
    .then(res => {
      dispatch({ type: TYPES.UPDATE_TABLE_SEARCH.SUCCESS, payload: res.data });
      dispatch(
        successHandler({
          title: 'Success!',
          message: 'Table search updated'
        })
      );
      dispatch(
        getSavedItems({ per_page: getState().sidebar.savedItems.paging.per_page, current_page: 1 })
      );

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.UPDATE_TABLE_SEARCH.ERROR, payload: error }));
};

export const resetTableData = name => dispatch => {
  return dispatch({ type: TYPES.RESET_TABLE_DATA, payload: name });
};

export const resetTableColumns = name => dispatch => {
  return dispatch({ type: TYPES.RESET_TABLE_COLUMNS, payload: name });
};

export const resetTableSearch = () => dispatch => {
  return dispatch({ type: TYPES.RESET_TABLE_SEARCH });
};

export const resetCurrentPage = params => dispatch => {
  return dispatch({ type: TYPES.RESET_CURRENT_PAGE, payload: params });
};

export const setTableFilters = params => dispatch => {
  return dispatch({ type: TYPES.SET_TABLE_FILTERS, payload: params });
};

export const setTableDefaultRequestParams = params => dispatch => {
  return dispatch({ type: TYPES.SET_TABLE_DEFAULT_REQUEST_PARAMS, payload: params });
};

export const triggerProfileAction = params => dispatch => {
  return dispatch({ type: TYPES.TRIGGER_PROFILE_ACTION, payload: params });
};
