import React from 'react';
import PropTypes from 'prop-types';

import Title from './Title';
import SubMenus from './SubMenus';

const Menu = ({ id, showMenuActions }) => {
  return (
    <div>
      <Title id={id} showMenuActions={showMenuActions} />
      <SubMenus id={id} showMenuActions={showMenuActions} />
    </div>
  );
};

Menu.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showMenuActions: PropTypes.bool
};

export default Menu;
