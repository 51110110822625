import React from 'react';
import CtaBox from 'components/general/CtaBox';

import useRouter from 'use-react-router';

import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import { selectUserDashboards, selectHasFetchedUserDashboards } from 'store/dashboards/selectors';

import dashboardColored from 'common/assets/svg/common/dashboard-colored.svg';
import add from 'assets/svg/actions/add.svg';

import SvgRender from 'common/components/general/SvgRender';
import * as dashboardsActions from 'store/dashboards/actions';

const NoDashboards = () => {
  const [setDashboardForm] = useActions([dashboardsActions.setDashboardForm]);

  const list = useSelector(selectUserDashboards);
  const listFetched = useSelector(selectHasFetchedUserDashboards);

  const { match } = useRouter();

  if (match.params.id || !listFetched) return null;

  return (
    <div className="flex-fill d-flex align-items-center justify-content-center">
      <CtaBox
        firstLine={'Create a new dashboard.'}
        secondLine={!list?.length ? 'You haven’t created any dashboard yet.' : null}
        thirdLine="Go on, customize your own & share it with your teammates"
        cta={
          <>
            <SvgRender style={{ width: 12, height: 12 }} src={add} className="me-1" />
            Create new Dashboard
          </>
        }
        icon={dashboardColored}
        className="position-relative mb-5"
        onClick={() => setDashboardForm({ isOpen: true, active: false })}
      />
    </div>
  );
};

export default NoDashboards;
