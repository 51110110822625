import React, { useState, useEffect, useCallback } from 'react';
import Form from 'common/components/review-process/components/notes/form/index';
import Preview from 'common/components/review-process/components/notes/preview/index';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFormState } from 'utils/hooks';
import { config } from 'common/components/review-process/components/notes/config';
import Loader from 'common/components/tree-layout/components/Loader';
import CircledButton from 'common/components/buttons/CircledButton';
import ShadowBox from 'common/components/general/ShadowBox';
import {
  selectReviewProcessNotes,
  selectReviewProcessId
} from 'common/components/review-process/store/selectors';
import { getReviewProcessNotes } from 'common/components/review-process/store/actions';
import PropTypes from 'prop-types';

const Notes = ({ activeEntityId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [active, setActive] = useState(false);
  const [activeNote, setActiveNote] = useState(null);

  const reviewProcessId = useSelector(state => selectReviewProcessId(state, activeEntityId));
  const reviewProcessNotes = useSelector(selectReviewProcessNotes);

  const { formState, loadValues } = useForm(config, {});
  const { fields, selectField } = useFormState(formState);

  const dispatch = useDispatch();

  const onInit = useCallback(async () => {
    if (reviewProcessId) {
      try {
        setIsLoading(true);

        await dispatch(getReviewProcessNotes({ id: reviewProcessId })).unwrap();

        setIsLoading(false);
      } catch (e) {
        console.error(e);
      }
    }
  }, [reviewProcessId, dispatch]);

  const onAdd = () => {
    setActiveNote(null);
    setShowForm(true);
  };

  const onCancelForm = () => {
    setActiveNote(null);
    setShowForm(false);
    setActive(null);
  };

  useEffect(() => {
    onInit();
  }, [onInit]);

  if (!reviewProcessId) return;

  return (
    <div className="w-100p mb-2 mt-2">
      {!showForm && !activeNote?.id ? (
        <ShadowBox className="py-2 px-2  mb-2" color="light-2" flat>
          <CircledButton
            style={{ width: 20, height: 20 }}
            type="add"
            className="me-1"
            onClick={onAdd}
          />
          <strong className="text-primary">
            {reviewProcessNotes?.length ? 'Add Comment' : 'Start a Conversation'}
          </strong>
        </ShadowBox>
      ) : null}
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {showForm && !active && (reviewProcessId ? !activeNote?.id : !activeNote?.index) ? (
              <>
                <Form
                  fields={fields}
                  selectField={selectField}
                  loadValues={loadValues}
                  setActiveNote={setActiveNote}
                  activeNote={activeNote}
                  reviewProcessId={reviewProcessId}
                  closeForm={onCancelForm}
                />
              </>
            ) : null}
            {reviewProcessNotes?.length ? (
              <div className="mt-1">
                {reviewProcessNotes.map((note, index) => (
                  <Preview
                    key={note.id}
                    index={index}
                    note={note}
                    closeForm={onCancelForm}
                    isLoading={isLoading}
                    activeNote={activeNote}
                    setActiveNote={setActiveNote}
                    setShowForm={setShowForm}
                    reviewProcessId={reviewProcessId}
                    fields={fields}
                    selectField={selectField}
                    loadValues={loadValues}
                  />
                ))}
              </div>
            ) : null}
          </>
        )}
      </>
    </div>
  );
};

Notes.propTypes = {
  activeEntityId: PropTypes.number.isRequired
};

export default Notes;
