import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import SvgRender from 'common/components/general/SvgRender';
import arrow from 'common/assets/svg/common/arrows/arrow-filters.svg';

import getIcon from 'components/layouts/sidebar/panel/notifications/_icons';
import {
  selectActiveCategoryLabel,
  selectIsUnreadTabActive,
  selectSummaryTypeTotals
} from 'store/notifications/selectors';
import {
  setActiveCategoryLabel,
  getNotificationsSummary,
  getNotifications,
  setIsUnreadTabActive
} from 'store/notifications/actions';

import { getSummaryTitlePerType } from '../_helpers';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const Tab = ({ label }) => {
  const dispatch = useDispatch();
  const activeLabel = useSelector(selectActiveCategoryLabel);
  const isUnreadTabActive = useSelector(selectIsUnreadTabActive);
  const isActive = activeLabel === label && !isUnreadTabActive;
  const totals = useSelector(state => selectSummaryTypeTotals(state, label));

  const bgColor = isActive ? 'bg-dark' : 'bg-light-gray';
  const textColor = isActive ? 'text-white' : 'text-dark';

  const onClick = async () => {
    try {
      dispatch(setIsUnreadTabActive(false));
      dispatch(setActiveCategoryLabel(label === 'back' ? 'summary' : label));

      if (label === 'summary' || label === 'back') {
        await dispatch(getNotificationsSummary());
      } else if (label === 'all') {
        await dispatch(getNotifications({ current_page: 1 }));
      } else {
        await dispatch(getNotifications({ current_page: 1, type_prefix: label }));
      }
    } catch (err) {
      console.error(err);
    }
  };
  const title = getSummaryTitlePerType(label);
  const { icon } = getIcon(label);

  return (
    <div
      css={css`
        box-shadow: ${isActive ? '-2px 4px 8px 0 rgba(0, 0, 0, 0.1)' : 'none'};
        max-height: 1.375rem;
      `}
      onClick={onClick}
      className={`${bgColor} ${textColor} text-capitalize d-flex align-items-center cps-12 cpe-4 cpy-2 fs-12 border-radius-5 fw-medium cursor-pointer cme-6`}
    >
      <div className="d-flex align-items-center flex-1 me-1">
        {label === 'back' ? (
          <>
            <SvgRender
              src={arrow}
              className="me-1"
              style={{ width: 12, height: 12, transform: 'rotate(-90deg)' }}
            />
            <div>Back</div>
          </>
        ) : icon ? (
          <SvgRender src={icon} style={{ width: 12, height: 12 }} className="me-1" />
        ) : null}

        {title}
      </div>

      {totals ? (
        <div className="position-relative">
          <div
            css={css`
              background-color: ${isUnreadTabActive
                ? variables.squidInk
                : 'rgba(255, 255, 255, 0.2)'};
              ${isUnreadTabActive ? 'opacity: .1;' : ''}
              min-width: 1.375rem;
              min-height: 1.1875rem;
              max-height: 1.1875rem;
            `}
            className={`d-flex align-items-center justify-content-center fw-bold fs-12 border-radius-3 ${
              isUnreadTabActive ? '' : 'text-white'
            }`}
          ></div>
          <div className="position-absolute top-50 start-50 translate-middle">{totals}</div>
        </div>
      ) : null}
    </div>
  );
};

export default Tab;

Tab.propTypes = {
  label: PropTypes.string.isRequired
};
