import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ForwardingParsedBox,
  ForwardingCaseItem,
  ForwardingCaseBox
} from '@/common/types/purchasing.ts';

export type SelectedForwardingCaseType = {
  selectedForwardingCaseTableItems: ForwardingCaseItem[];
  id: number | null;
  forwardingParsedBoxes: ForwardingParsedBox[];
  boxes: ForwardingCaseBox[];
};

export type ForwardingStateType = {
  selectedForwardingCase: SelectedForwardingCaseType;
};

const INITIAL_STATE: ForwardingStateType = {
  selectedForwardingCase: {
    selectedForwardingCaseTableItems: [],
    id: null,
    forwardingParsedBoxes: [],
    boxes: []
  }
};

const forwarding = createSlice({
  name: 'forwarding',
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedForwardingCase(state, action: PayloadAction<SelectedForwardingCaseType | null>) {
      if (action.payload) {
        state.selectedForwardingCase = action.payload;
      } else {
        state.selectedForwardingCase = {
          selectedForwardingCaseTableItems: [],
          id: null,
          forwardingParsedBoxes: [],
          boxes: []
        };
      }
    }
  }
});

export const { setSelectedForwardingCase } = forwarding.actions;

export default forwarding.reducer;
