import TYPES from './types';
import { get, put, post, deleteRequest } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { successHandler } from 'common/utils/notifications';
import { updateTableRow, resetTableRowUpdate } from 'store/tables/lists/actions';

export const getDepartments = params => dispatch => {
  dispatch({ type: TYPES.GET_DEPARTMENTS.START, payload: { params } });

  return get('/departments', params)
    .then(response => {
      dispatch({ type: TYPES.GET_DEPARTMENTS.SUCCESS, payload: response.data });
    })
    .catch(error => dispatch({ type: TYPES.GET_DEPARTMENTS.ERROR, payload: error }));
};

export const getDepartment = createAsyncThunk(
  'GET_DEPARTMENT',
  async (params, { rejectWithValue }) => {
    try {
      const res = await get(`/departments/${params.id}`, params);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateDepartment = params => dispatch => {
  const { id, ...rest } = params;
  const table = 'departments';
  dispatch({ type: TYPES.UPDATE_DEPARTMENT.START, payload: { params } });
  dispatch(updateTableRow({ data: params, table }));

  return put(`/departments/${id}`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.UPDATE_DEPARTMENT.SUCCESS, payload: response.data });
      dispatch(updateTableRow({ data: response.data, table }));
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_DEPARTMENT.ERROR, payload: { response: error, params } });
      dispatch(resetTableRowUpdate({ data: params, table }));
      return error;
    });
};

export const createDepartment = params => dispatch => {
  dispatch({ type: TYPES.CREATE_DEPARTMENT.START, payload: { params } });

  return post('/departments', params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_DEPARTMENT.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_DEPARTMENT.ERROR, payload: error });
      return error;
    });
};

export const deleteDepartment = params => dispatch => {
  dispatch({ type: TYPES.DELETE_DEPARTMENT.START, payload: { params } });

  return deleteRequest(`/departments/${params.id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_DEPARTMENT.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.DELETE_DEPARTMENT.ERROR, payload: error }));
};
