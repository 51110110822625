import BoxShadowButton from 'common/components/buttons/BoxShadowButton';
import FillAuthenticationCode from 'common/components/authentication/sms/FillAuthenticationCode';
import ResendCode from 'common/components/authentication/sms/ResendCode';
import PropTypes from 'prop-types';
import { useActions } from 'utils/hooks';
import { Form } from 'reactstrap';

import * as authActions from 'store/auth/actions';

const FillCode = ({ code, setCode, onLogin, disabled }) => {
  const [sendTwoFactorOtp] = useActions([authActions.sendTwoFactorOtp]);

  return (
    <Form className="d-flex flex-column align-items-center justify-content-center h-100p">
      <FillAuthenticationCode onCodeChange={setCode} codeError={code?.error} />

      <BoxShadowButton
        color="yellow"
        type="submit"
        className="w-100p py-1 fs-14 mt-2 mt-hd-4 mb-2 mb-hd-3 height-40"
        size="sm"
        onClick={onLogin}
        disabled={disabled}
      >
        Login
      </BoxShadowButton>

      <div className="d-flex align-items-center justify-content-center w-100p">
        <ResendCode interval={60} onResendCode={sendTwoFactorOtp} />
      </div>
    </Form>
  );
};

FillCode.propTypes = {
  code: PropTypes.object,
  setCode: PropTypes.func,
  onLogin: PropTypes.func,
  disabled: PropTypes.bool
};

export default FillCode;
