import { createSlice } from '@reduxjs/toolkit';
import {
  getVettingStatistics,
  getVettingStatisticsPerChapter,
  getVettingStatisticsPerSeverity,
  getVettingStatisticsPerRiskRating,
  getVettingStatisticsBySupt,
  getVettingStatisticsPerVessel
} from './actions';

import {
  parsePerChapterData,
  parsePerSeverityOrRiskData,
  parseVettingBarGraphData,
  suptGraphColor,
  perVesselGraphColor
} from './helpers';

export const INITIAL_STATE = {
  perChapter: {
    data: {},
    isLoading: false
  },

  perSeverity: {
    data: {},
    isLoading: false
  },

  perRiskRating: {
    data: {},
    isLoading: false
  },

  bySupt: {
    data: {},
    isLoading: false
  },

  perVessel: {
    data: {},
    isLoading: false
  },

  totals: {
    data: {},
    isLoading: false
  }
};

const slice = createSlice({
  name: 'performance',
  initialState: INITIAL_STATE,
  extraReducers: {
    [getVettingStatisticsPerChapter.pending]: state => {
      state.perChapter.isLoading = true;

      return state;
    },

    [getVettingStatisticsPerChapter.fulfilled]: (state, { payload }) => {
      state.perChapter.data = parsePerChapterData(payload.perChapter);
      state.perChapter.isLoading = false;

      return state;
    },

    [getVettingStatisticsPerChapter.rejected]: state => {
      state.perChapter.data = {};
      state.perChapter.isLoading = false;

      return state;
    },

    [getVettingStatisticsPerSeverity.pending]: state => {
      state.perSeverity.isLoading = true;

      return state;
    },

    [getVettingStatisticsPerSeverity.fulfilled]: (state, { payload }) => {
      state.perSeverity.data = parsePerSeverityOrRiskData(payload.perSeverity, {
        key: 'severity',
        label: 'Severity'
      });
      state.perSeverity.isLoading = false;

      return state;
    },

    [getVettingStatisticsPerSeverity.rejected]: state => {
      state.perSeverity.data = {};
      state.perSeverity.isLoading = false;

      return state;
    },

    [getVettingStatisticsPerRiskRating.pending]: state => {
      state.perRiskRating.isLoading = true;

      return state;
    },

    [getVettingStatisticsPerRiskRating.fulfilled]: (state, { payload }) => {
      state.perRiskRating.data = parsePerSeverityOrRiskData(payload.perRiskRating, {
        key: 'risk',
        label: 'Risk Rating'
      });
      state.perRiskRating.isLoading = false;

      return state;
    },

    [getVettingStatisticsPerRiskRating.rejected]: state => {
      state.perRiskRating.data = {};
      state.perRiskRating.isLoading = false;

      return state;
    },

    [getVettingStatisticsBySupt.pending]: state => {
      state.bySupt.isLoading = true;

      return state;
    },

    [getVettingStatisticsBySupt.fulfilled]: (state, { payload }) => {
      state.bySupt.data = parseVettingBarGraphData(payload.bySupt, suptGraphColor);
      state.bySupt.isLoading = false;

      return state;
    },

    [getVettingStatisticsBySupt.rejected]: state => {
      state.bySupt.data = {};
      state.bySupt.isLoading = false;

      return state;
    },

    [getVettingStatisticsPerVessel.pending]: state => {
      state.perVessel.isLoading = true;

      return state;
    },

    [getVettingStatisticsPerVessel.fulfilled]: (state, { payload }) => {
      state.perVessel.data = parseVettingBarGraphData(payload.perVessel, perVesselGraphColor);
      state.perVessel.isLoading = false;

      return state;
    },

    [getVettingStatisticsPerVessel.rejected]: state => {
      state.perVessel.data = {};
      state.perVessel.isLoading = false;

      return state;
    },

    [getVettingStatistics.pending]: state => {
      state.totals.isLoading = true;

      return state;
    },
    [getVettingStatistics.fulfilled]: (state, { payload }) => {
      state.totals.data = payload.totals.data;
      state.totals.isLoading = false;

      return state;
    },
    [getVettingStatistics.rejected]: state => {
      state.totals.data = {};
      state.totals.isLoading = false;

      return state;
    }
  }
});

export default slice.reducer;
