import { combineReducers } from 'redux';
import common from './common/reducer';
import itinerary from '@/common/components/voyages/itinerary/store/reducer';
import charterParties from './charter-parties/reducer';

export default combineReducers({
  common,
  itinerary,
  charterParties
});
