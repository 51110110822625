import { get, post, put, download } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import TYPES from './types';

export const getNewAnnouncement = createAsyncThunk(
  'GET_NEW_ANNOUNCEMENT',
  async (params, { rejectWithValue }) => {
    try {
      const { id } = params;

      const res = await get(`/posts/${id}`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createNewOrAnnouncement = createAsyncThunk(
  'CREATE_NEW_OR_ANNOUNCEMENT',
  async (params, { rejectWithValue }) => {
    try {
      const res = await post(`/posts`, params);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateNewOrAnnouncement = createAsyncThunk(
  'UPDATE_NEW_OR_ANNOUNCEMENT',
  async (params, { rejectWithValue }) => {
    try {
      const { post_id, ...rest } = params;

      const res = await put(`/posts/${post_id}`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const archiveNewOrAnnouncement = createAsyncThunk(
  'ARCHIVE_NEW_OR_ANNOUNCEMENT',
  async (params, { rejectWithValue }) => {
    try {
      const { id } = params;

      const res = await put(`/posts/${id}/archive`);

      return res.data?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const unarchiveNewOrAnnouncement = createAsyncThunk(
  'UNARCHIVE_NEW_OR_ANNOUNCEMENT',
  async (params, { rejectWithValue }) => {
    try {
      const { id } = params;

      const res = await put(`/posts/${id}/unarchive`);

      return res.data?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const downloadNewsAnnouncementsAttachments = params => dispatch => {
  dispatch({
    type: TYPES.DOWNLOAD_NEWS_ANNOUNCEMENTS_ATTACHMENTS.START,
    payload: { params }
  });

  const { id } = params;

  return download(`/files/entity`, { id, type: 'post' })
    .then(response => {
      dispatch({
        type: TYPES.DOWNLOAD_NEWS_ANNOUNCEMENTS_ATTACHMENTS.SUCCESS,
        payload: { data: response.data, params }
      });
      return response;
    })
    .catch(error =>
      dispatch({
        type: TYPES.DOWNLOAD_NEWS_ANNOUNCEMENTS_ATTACHMENTS.ERROR,
        payload: { error, params }
      })
    );
};
