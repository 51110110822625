import { useEffect, FC } from 'react';
import { Row, Col } from 'reactstrap';
import { useFormState } from 'utils/hooks';
import { components, SingleValueProps, MultiValueProps, OptionProps } from 'react-select';

import FormList from 'common/components/form/FormList';

import EntityLabel from 'common/components/labels/EntityLabel';
import { DropdownIndicator } from 'common/components/form/inputs/Select';
import CompanySubTypeSelect from '@/views/companies/profile/configs/components/CompanySubTypeSelect.jsx';
import ListSelect from '@/common/components/selectors/ListSelect';
import { FieldsStateType, FormState } from '@/common/types/form';
import { CompanyType } from '@/common/types/enums.ts';
import { REQUIRED_FIELD_MESSAGE } from '@/ts-common/utils/form/validation';

type ListOption = {
  id: string | number;
  name: string;
};

type ItemProps = {
  formState: FormState;
  companyTypesState: FormState[];
};

type ConfigType = {
  company_type_id: CompanyType;
  company_subtype_ids: CompanyType[];
};

type CompanyTypesProps = {
  subStates: (label: string) => FormState<ConfigType>[];
  addSubform: (label: string, params: Record<string, string>) => void;
  removeSubform: (label: string, index: number) => void;
};

type FilterOptionType = { value: number };

const Item: FC<ItemProps> = ({ formState, companyTypesState }) => {
  const { selectField, setFieldError, ...rest } = useFormState(formState);
  const fields: FieldsStateType<ConfigType> = rest.fields;
  const selectedCompanyTypeIds = companyTypesState?.map(
    company_type => company_type?.state?.company_type_id?.value
  );

  const onFieldChange = (selected: { id: number }) => {
    selectField('company_type_id')(selected);
    selectField('company_subtype_ids')(null);
  };

  useEffect(() => {
    setFieldError('company_type_id', !fields?.company_type_id?.value ? REQUIRED_FIELD_MESSAGE : '');
  }, [fields?.company_type_id?.value]);

  return (
    <Row className="d-flex justify-content-center align-items-center">
      <Col xs={6} className="pe-0">
        <ListSelect
          placeholder="Select type"
          components={{ DropdownIndicator, Option, MultiValueLabel, SingleValue }}
          getOptionValue={(option: CompanyType) => option.id}
          getOptionLabel={(option: CompanyType) => option.name}
          filterOption={(e: FilterOptionType) =>
            !selectedCompanyTypeIds?.find(company => company?.id === e.value)
          }
          list="party-company-types"
          isAsync
          onChange={onFieldChange}
          className="mb-0 w-100"
          styled={false}
          {...fields.company_type_id}
        />
      </Col>
      <Col xs={6}>
        <CompanySubTypeSelect
          onChange={selectField('company_subtype_ids')}
          companyTypeId={fields.company_type_id?.value?.id}
          {...fields.company_subtype_ids}
        />
      </Col>
    </Row>
  );
};

const CompanyTypes: FC<CompanyTypesProps> = ({ subStates, addSubform, removeSubform }) => {
  const companyTypes = subStates('company_types');

  useEffect(() => {
    if (companyTypes?.length === 0) {
      addSubform('company_types', {});
    }
  }, [addSubform, companyTypes]);

  return (
    <FormList
      inner
      forms={subStates(`company_types`)}
      className="mt-0 pt-1 w-100p"
      formListclassName="pt-0 mb-1 px-0 outside bg-transparent"
      onAdd={() => addSubform('company_types', {})}
      onRemove={index => removeSubform('company_types', index)}
      addText={'Add more'}
      canRemove={(data: { index: number }) => data.index !== 0}
      sideBin
    >
      {(formState: FormState) => <Item formState={formState} companyTypesState={companyTypes} />}
    </FormList>
  );
};

const SingleValue: FC<SingleValueProps<ListOption>> = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <EntityLabel type="company-type">{props.data.name}</EntityLabel>
  </components.SingleValue>
);

const MultiValueLabel: FC<MultiValueProps<ListOption>> = ({ children, ...props }) => {
  return (
    <components.MultiValueLabel {...props}>
      <EntityLabel type="company-type">{props.data.name}</EntityLabel>
    </components.MultiValueLabel>
  );
};

const Option: FC<OptionProps<ListOption>> = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <EntityLabel type="company-type">{props.data.name}</EntityLabel>
    </components.Option>
  );
};

export default CompanyTypes;
