import { createSelector } from 'reselect';
import { selectTableList } from 'common/components/table/store/selectors';

const getSecondrayKey = (_, __, key) => key;

const selectTableReducer = state => state.tables;

export const selectTableFooterValues = createSelector(
  selectTableList,
  table => table?.footer_values || {}
);

export const selectTableVessel = createSelector(selectTableList, table => table?.vessel || []);

export const selectTableYearTurnOver = createSelector(
  selectTableList,
  table => table?.year_turn_over || {}
);

export const selectTableYear = createSelector(
  selectTableYearTurnOver,
  getSecondrayKey,
  (year, label) => year?.[label] || {}
);

export const selectTableSorting = createSelector(selectTableList, table => table?.sorting || {});

export const selectTablePaging = createSelector(selectTableList, table => table?.paging || {});

export const selectTableDefaultRequestParams = createSelector(
  selectTableList,
  table => table?.defaultTableParams
);

export const selectSearch = createSelector([selectTableReducer], table => table?.search);

export const selectSearchSaving = state => selectTableReducer(state)?.searchSaving;

export const selectSearchLoading = state => selectTableReducer(state)?.searchLoading;

export const selectSearchShares = createSelector([selectSearch], search =>
  search && search.shares ? search.shares : []
);
