import React, { useCallback, useEffect } from 'react';

import { useForm, useFormState } from 'utils/hooks';
import { optionField } from 'common/utils/form/fieldTypes';

import WidgetSettings from 'views/dashboard/components/WidgetSettings';
import { selectWidgetPreferences } from 'store/dashboards/selectors';
import { useSelector } from 'react-redux';

import AsyncSelector from 'common/components/selectors/AsyncSelector';
import { selectListDefaultOptions } from 'store/lists/selectors';

const Preferences = ({ id, onSave, onClose, isSubmitting }) => {
  const { formState, collectValues, resetForm, loadValues } = useForm({
    vessel: optionField()
  });
  const { selectField, fields } = useFormState(formState);

  const preferences = useSelector(state => selectWidgetPreferences(state, id));
  const vessels = useSelector(state => selectListDefaultOptions(state, 'vessels'));

  const handleSubmitForm = async ({ custom_title }) => {
    const values = collectValues();

    if (!values) return;

    const { vessel } = values;

    try {
      await onSave({ custom_title, vessel_id: vessel.id });
      resetForm();
    } catch (e) {
      console.error(e);
    }
  };

  const init = useCallback(() => {
    if (!preferences.vessel_id || !vessels?.length) return;

    loadValues({ vessel: vessels.find(vessel => vessel.id === preferences.vessel_id) });
  }, [preferences, loadValues, vessels]);

  useEffect(() => {
    init();
  }, [id, init]);

  return (
    <WidgetSettings id={id} isSubmitting={isSubmitting} onClose={onClose} onSave={handleSubmitForm}>
      <AsyncSelector
        className={`mb-0`}
        onChange={selectField('vessel')}
        type="vessels"
        name="vessel"
        placeholder="Select vessel"
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        {...fields.vessel}
      />
    </WidgetSettings>
  );
};

export default Preferences;
