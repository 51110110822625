import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import Input from 'common/components/form/inputs/Input';
import ShadowBox from 'common/components/general/ShadowBox';
import AddMore from 'common/components/form/helpers/AddMore';
import DateInput from 'common/components/form/inputs/date';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import Select from 'common/components/form/inputs/Select';
import { useDrawer } from 'common/components/drawer';

import MultipleInfoFields from 'views/persons/drawer/create-company/components/MultipleInfoFields';
import CreateCompanyDrawer from 'views/persons/drawer/create-company';

const MainInfo = ({ fields, selectField, changeField, subStates, addSubform, removeSubform }) => {
  const drawer = useDrawer();

  const emailsState = subStates('emails');
  const contactInfoState = subStates('contact_info');

  return (
    <ShadowBox color="light-1" className="pt-1 px-2 cmb-20" flat>
      <div className="fw-bold fs-12 text-violet border-bottom cpb-4 mb-1">Main Info</div>

      <Row>
        <Col
          xs={3}
          className={`d-flex align-items-center justify-content-end ${
            fields.first_name?.error ? 'mb-2' : 'mb-1'
          }`}
        >
          <div className="form-label fs-12 text-blue mb-0">FIRST NAME</div>
        </Col>

        <Col xs={8} className={`${fields.first_name?.error ? 'mb-2' : 'mb-1'}`}>
          <Input
            placeholder="Add first name"
            className="mb-0"
            onChange={changeField('first_name')}
            {...fields.first_name}
          />
        </Col>

        <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
          <div className="form-label fs-12 text-blue mb-0">MIDDLE NAME</div>
        </Col>

        <Col xs={8} className="mb-1">
          <Input
            placeholder="Add middle name"
            className="mb-0"
            onChange={changeField('middle_name')}
            {...fields.middle_name}
          />
        </Col>

        <Col
          xs={3}
          className={`d-flex align-items-center justify-content-end ${
            fields.last_name?.error ? 'mb-2' : 'mb-1'
          }`}
        >
          <div className="form-label fs-12 text-blue mb-0">LAST NAME</div>
        </Col>

        <Col xs={8} className={`${fields.last_name?.error ? 'mb-2' : 'mb-1'}`}>
          <Input
            placeholder="Add last name"
            className="mb-0"
            onChange={changeField('last_name')}
            {...fields.last_name}
          />
        </Col>

        <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
          <div className="form-label fs-12 text-blue mb-0">COMPANY</div>
        </Col>

        <Col xs={8} className="mb-1">
          <Select
            label=""
            className={`mb-0`}
            placeholder="Select company or add a new one"
            isCreatable
            isAsync
            onCreateOption={() => drawer.open()}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.full_name}
            defaultOptions={() => getInitialAsyncValues('parties', null, null, { type: 'company' })}
            defaultOptionsTriggerChange={drawer.isOpen}
            loadOptions={search => getAsyncOptions(search, 'parties', { type: 'company' })}
            onChange={selected => selectField('company')(selected)}
            {...fields.company}
          />
        </Col>

        <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
          <div className="form-label fs-12 text-blue mb-0">ROLE</div>
        </Col>

        <Col xs={8} className="mb-1">
          <Input
            placeholder="Add role"
            className="mb-0"
            onChange={changeField('position')}
            {...fields.position}
          />
        </Col>

        <Col xs={3} className="d-flex align-items-center justify-content-end mb-3">
          <div className="form-label fs-12 text-blue mb-0">BIRTH DATE</div>
        </Col>

        <Col xs={8} className="mb-3">
          <DateInput
            dataCy="birthdate"
            onChange={selectField('birthday')}
            className="mb-0 me-5"
            {...fields.birthday}
          />
        </Col>

        <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
          <div className="form-label fs-12 text-blue mb-0">EMAILS</div>
        </Col>

        <Col xs={4} className="mb-1 d-flex align-items-center">
          <div className="fs-12 text-primary fw-medium">Primary Email</div>
        </Col>

        <Col xs={4} className="mb-1 ps-0">
          <Input
            placeholder="Add email"
            className="mb-0"
            onChange={changeField('email')}
            {...fields.email}
          />
        </Col>

        <Col xs={{ size: 8, offset: 3 }}>
          {emailsState?.map((state, index) => (
            <MultipleInfoFields
              key={index}
              state={state}
              isEmail
              onRemove={() => removeSubform('emails', index)}
            />
          ))}
        </Col>

        <Col xs={{ size: 8, offset: 3 }} className="mb-2">
          <AddMore label="Add more" onClick={() => addSubform('emails')} variant />
        </Col>

        <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
          <div className="form-label fs-12 text-blue mb-0">CONTACTS</div>
        </Col>

        <Col xs={4} className="mb-1 d-flex align-items-center">
          <div className="fs-12 text-primary fw-medium">Primary Phone</div>
        </Col>

        <Col xs={4} className="mb-1 ps-0">
          <Input
            placeholder="Add number"
            className="mb-0"
            onChange={changeField('phone')}
            {...fields.phone}
          />
        </Col>

        <Col xs={{ size: 8, offset: 3 }}>
          {contactInfoState?.map((state, index) => (
            <MultipleInfoFields
              key={index}
              state={state}
              onRemove={() => removeSubform('contact_info', index)}
            />
          ))}
        </Col>

        <Col xs={{ size: 8, offset: 3 }} className="mb-2">
          <AddMore label="Add more" onClick={() => addSubform('contact_info')} variant />
        </Col>
      </Row>

      <CreateCompanyDrawer drawer={drawer} className="inner-create-company-drawer" />
    </ShadowBox>
  );
};

export default MainInfo;

MainInfo.propTypes = {
  fields: PropTypes.object.isRequired,
  selectField: PropTypes.func.isRequired,
  changeField: PropTypes.func.isRequired,
  subStates: PropTypes.func,
  addSubform: PropTypes.func,
  removeSubform: PropTypes.func
};
