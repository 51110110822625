import TYPES from './types';
import FAVORITE_TYPES from 'store/favorites/types';
import AUTH_TYPES from 'store/auth/types';
import { LOCATION_CHANGE } from 'connected-react-router';

import { initialListState } from 'common/components/table/store/helpers';
import { getDefaultSorting, getDefaultPageSize, loadMore } from 'store/dashboards/helpers';
import _cloneDeep from 'lodash/cloneDeep';

const INITIAL_STATE = {
  list: [],
  listFetched: false,
  isSaving: false,
  isLoadingActiveDashboard: false,
  activeDashboardData: null,
  activeDashboardWidgets: [], // dashboard widget IDs
  widgets: {}, // all widgets data
  dashboardForm: { isOpen: false, active: null },
  dashboardWidgetsForm: { isOpen: false },
  widgetSettingsForm: { isOpen: false, activeID: null },
  widgetPreventionModal: { isOpen: false, activeID: null }
};

const reducer = (state = _cloneDeep(INITIAL_STATE), { type, payload }) => {
  switch (type) {
    case TYPES.CREATE_DASHBOARD.SUCCESS:
    case TYPES.CREATE_DASHBOARD_FROM.SUCCESS:
    case TYPES.CLONE_DASHBOARD.SUCCESS:
      return {
        ...state,
        list: [...state.list, payload.dashboard]
      };

    case TYPES.DELETE_DASHBOARD.SUCCESS:
      return {
        ...state,
        list: state.list.filter(d => d.id !== payload.dashboardId),
        activeDashboardData: null
      };

    case TYPES.EDIT_DASHBOARD.START:
      return {
        ...state,
        isSaving: true
      };

    case TYPES.EDIT_DASHBOARD.ERROR:
      return {
        ...state,
        isSaving: false
      };

    case TYPES.EDIT_DASHBOARD.SUCCESS:
      return {
        ...state,
        isSaving: false,
        list: state.list.map(d =>
          d.id !== payload.dashboard.id ? d : { ...d, ...payload.dashboard }
        ),
        activeDashboardData: {
          ...state.activeDashboardData,
          ...payload.dashboard
        }
      };

    case TYPES.FETCH_ALL_DASHBOARDS.SUCCESS:
      return {
        ...state,
        list: payload.dashboards,
        listFetched: true
      };

    case TYPES.FETCH_DASHBOARD.START:
      if (
        state.activeDashboardData &&
        state.activeDashboardData.id === parseInt(payload.dashboardId)
      ) {
        return {
          ...state,
          isLoadingActiveDashboard: false
        };
      } else {
        return {
          ...state,
          activeDashboardData: null,
          activeDashboardWidgets: [],
          isLoadingActiveDashboard: true
        };
      }

    case TYPES.FETCH_DASHBOARD.SUCCESS:
      if (payload.dashboardId) {
        const { widgets, ...rest } = payload.dashboard;

        const widgetsState = widgets.reduce(
          (acc, widget) => {
            acc.widgets[widget.id] = widget;
            acc.widgets[widget.id].state = {
              ...initialListState('widget', getDefaultPageSize(widget), getDefaultSorting(widget)),
              label: `widget_${widget.id}`
            };

            acc.activeDashboardWidgets.push(widget.id);

            return acc;
          },
          { activeDashboardWidgets: [], widgets: { ...state.widgets } }
        );

        return {
          ...state,
          activeDashboardData: { ...rest },
          ...widgetsState,
          isLoadingActiveDashboard: false
        };
      } else {
        return state;
      }

    case TYPES.FETCH_DASHBOARD.ERROR:
      return {
        ...state,
        isLoadingActiveDashboard: false
      };

    case TYPES.ADD_WIDGET.START:
      return {
        ...state,
        isSaving: true
      };

    case TYPES.ADD_WIDGET.ERROR:
      return {
        ...state,
        isSaving: false
      };

    case TYPES.GET_WIDGET_DATA.START:
    case TYPES.UPDATE_WIDGET_STATE:
      if (payload) {
        const { sorting, search, widgetId } = payload.params;
        const widget = { ...state.widgets[widgetId], loading: true };

        let paging = null;
        if (payload.params && payload.params.paging)
          paging = {
            ...widget.state.paging,
            current_page: payload.params.paging.current_page
          };

        widget.state.paging = paging;

        if (search) {
          widget.state.search = search;
        }

        if (sorting && Object.keys(sorting).length > 0) widget.state.sorting = sorting;

        return {
          ...state,
          widgets: {
            ...state.widgets,
            [widgetId]: { ...widget }
          }
        };
      } else {
        return state;
      }

    case TYPES.GET_WIDGET_DATA.SUCCESS:
      if (payload) {
        const { widgetId } = payload.params;

        return {
          ...state,
          widgets: {
            ...state.widgets,
            [widgetId]: {
              ...state.widgets[widgetId],

              initialized: true,
              loading: false,

              state: {
                ...state.widgets[widgetId].state,

                data:
                  payload.data.data &&
                  loadMore(state.widgets[widgetId].type.label) &&
                  payload.data.meta.current_page > 1
                    ? [...state.widgets[widgetId].state.data, ...payload.data.data]
                    : payload.data.data
                    ? payload.data.data
                    : payload.data,

                paging:
                  payload.data && payload.data.meta
                    ? { ...state.widgets[widgetId].state.paging, ...payload.data.meta }
                    : state.widgets[widgetId].state.paging,
                columns: payload.data?.columns,
                totals: payload.data?.totals,
                percentages: payload.data?.percentages
              }
            }
          }
        };
      } else {
        return state;
      }

    case TYPES.GET_WIDGET_DATA.ERROR:
      if (payload) {
        const { widgetId } = payload.params;
        const widget = { ...state.widgets[widgetId], initialized: true, loading: false };

        return {
          ...state,
          widgets: {
            ...state.widgets,
            [widgetId]: { ...widget }
          }
        };
      } else {
        return state;
      }

    case TYPES.GET_WIDGET_PREFERENCES.SUCCESS:
    case TYPES.UPDATE_WIDGET_PREFERENCES.SUCCESS:
    case TYPES.UPDATE_WIDGET.SUCCESS:
      if (payload?.preferences) {
        const { id, preferences } = payload;

        return {
          ...state,
          widgets: {
            ...state.widgets,
            [id]: { ...state.widgets[id], preferences }
          }
        };
      } else {
        return state;
      }

    case TYPES.SET_DASHBOARD_FORM:
      return {
        ...state,
        dashboardForm: { isOpen: payload.isOpen, active: payload.active }
      };

    case TYPES.SET_DASHBOARD_WIDGETS_FORM:
      return {
        ...state,
        dashboardWidgetsForm: { isOpen: payload.isOpen }
      };

    case TYPES.SET_WIDGET_SETTINGS_FORM:
      return {
        ...state,
        widgetSettingsForm: { isOpen: payload.isOpen, activeID: payload.activeID }
      };

    case FAVORITE_TYPES.TOGGLE_FAVORITE.SUCCESS:
      return {
        ...state,
        activeDashboardData: state.activeDashboardData
          ? state.activeDashboardData.id === payload.favourite_id
            ? {
                ...state.activeDashboardData,
                is_favourite: !state.activeDashboardData.is_favourite
              }
            : state.activeDashboardData
          : null
      };

    case TYPES.SET_WIDGET_PREVENTION_MODAL:
      return {
        ...state,
        widgetPreventionModal: { isOpen: payload.isOpen, activeID: payload.activeID }
      };

    case AUTH_TYPES.LOGOUT_CLEANUP:
      return _cloneDeep(INITIAL_STATE);

    case LOCATION_CHANGE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
