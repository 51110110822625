import { FC, useMemo, useState } from 'react';
import _isArray from 'lodash/isArray';
import { components, OptionProps, SingleValueProps } from 'react-select';

import { TableFilterSelectorProps } from '@/common/types/front-entities/table';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import { getFilterSelectorValues } from '@/common/components/filters/helpers';
import Select from '@/ts-common/components/form/inputs/select';
import { Status } from '@/common/types/enums.ts';
import EntityLabel from 'common/components/labels/EntityLabel';

type ValueProps = {
  name: string;
  label: string;
};

const MgaPeriodStatusesSelector: FC<TableFilterSelectorProps> = ({
  value,
  filter,
  onChange,
  isMulti,
  listParams,
  ...rest
}) => {
  const [selected, setSelected] = useState<Status | Status[] | null>([]);

  useFilterSelector(filter?.value, { list: 'mga-period-statuses' }, { selected, setSelected });

  const memoizedRequestParams = useMemo(
    () => ({
      params: { ...(listParams || {}), list: 'mga-period-statuses' },
      path: 'lists'
    }),
    [listParams]
  );

  return (
    <Select
      placeholder="Select value"
      getOptionValue={option => option.id.toString()}
      getOptionLabel={option => option.name}
      components={{ Option, MultiValueLabel, SingleValue }}
      isAsync
      isMulti={isMulti}
      value={
        value !== undefined
          ? value
          : filter?.value
          ? getFilterSelectorValues(selected, filter.value, isMulti)
          : []
      }
      memoizedRequestParams={memoizedRequestParams}
      onChange={(s: Status | Status[] | null) => {
        setSelected(isMulti && !s ? [] : s);
        onChange(filter?.value !== undefined ? { value: _isArray(s) ? s.map(s => s.id) : s } : s);
      }}
      {...rest}
    />
  );
};

const Value: FC<ValueProps> = ({ label, name }) => {
  return (
    <EntityLabel type="mga" color={label}>
      {name}
    </EntityLabel>
  );
};

const MultiValueLabel = ({ ...props }) => <Value label={props.data.label} name={props.data.name} />;

const SingleValue: FC<SingleValueProps<Status>> = ({ ...props }) => (
  <Value label={props.data.label} name={props.data.name} />
);

const Option: FC<OptionProps<Status>> = ({ ...props }) => (
  <components.Option {...props}>
    <Value label={props.data.label} name={props.data.name} />
  </components.Option>
);

export default MgaPeriodStatusesSelector;
