import React, { useState, useEffect, useCallback } from 'react';

import { checkUserWebpushStatus } from 'web-push/subscription';
import { selectOrganizationInfo } from 'store/organization/selectors';
import { useDispatch, useSelector } from 'react-redux';

import useRouter from 'use-react-router';
// import useIntercom from 'utils/hooks/useIntercom';
import useSockets from 'utils/hooks/useSockets';

import Sidebar from './sidebar';
import ToolBar from './toolbar';
import NotificationModal from 'components/general/NotificationModal';

import { selectIsAuthenticated } from 'store/auth/selectors';
import { selectAccount, selectIsOceanAdmin } from 'store/account/selectors';
import { getAccount, getAccountPermissions, getAccountWebpushConfig } from 'store/account/actions';
import { getSavedItems } from 'store/sidebar/actions';
import { getFavorites } from 'store/favorites/actions';
import { fetchListOptions } from 'store/lists/actions';
import { getJobTemplateRelationTypes } from 'store/jobs-templates/actions';
import { getSettings } from 'store/settings/actions';
import { getOrganizationInfo } from 'store/organization/actions';

const ContentWrapper = ({ children, routes }) => {
  const [appLayout, setAppLayout] = useState(false);

  const authenticated = useSelector(selectIsAuthenticated);
  const account = useSelector(selectAccount);
  const isOceanAdmin = useSelector(selectIsOceanAdmin);
  const favorites = useSelector(state => state.favorites);
  const savedItems = useSelector(state => state.sidebar.savedItems);
  const organization = useSelector(selectOrganizationInfo);
  const dispatch = useDispatch();

  const { location } = useRouter();

  const getWebpushOptions = useCallback(async () => {
    await dispatch(getAccountWebpushConfig());
    checkUserWebpushStatus(true);
  }, [dispatch]);

  // useIntercom();
  useSockets();

  useEffect(() => {
    if (authenticated && !account.id) {
      dispatch(getAccount());
      dispatch(getSavedItems({ current_page: 1, per_page: savedItems.paging.per_page }));
      dispatch(getFavorites({ current_page: 1, per_page: favorites.paging.per_page }));
    } else if (!authenticated && appLayout) {
      setAppLayout(false);
    }
  }, [
    authenticated,
    account,
    appLayout,
    dispatch,
    savedItems?.paging?.per_page,
    favorites?.paging?.per_page
  ]);

  useEffect(() => {
    if (authenticated) {
      const activeRoute = routes.find(route => {
        if (location.pathname === '/') return route.path === '/';

        const parts = location.pathname.split('/');
        return (typeof route.path === 'string' ? [route.path] : route.path).some(path =>
          path.startsWith(`/${parts[1]}${parts[2] ? `/` : ''}`)
        );
      });

      if (activeRoute && activeRoute.app) {
        setAppLayout(true);
      } else {
        setAppLayout(false);
      }
    } else {
      setAppLayout(false);
    }
  }, [authenticated, location.pathname, routes]);

  //Do the initial fetches

  useEffect(() => {
    if (authenticated && account.id) {
      dispatch(getAccount());
      dispatch(getAccountPermissions());
      dispatch(getSettings());
      dispatch(fetchListOptions('units'));
      dispatch(fetchListOptions('job-statuses'));
      dispatch(fetchListOptions('job-importances'));
      dispatch(fetchListOptions('vessels'));
      dispatch(getJobTemplateRelationTypes());
      getWebpushOptions();

      if (!organization?.id) {
        dispatch(getOrganizationInfo());
      }
    }
  }, [authenticated, account.id, organization?.id, dispatch, getWebpushOptions]);

  return (
    <div className="content-wrapper">
      {appLayout && <Sidebar />}
      {children}

      {appLayout && authenticated && account.id ? <ToolBar /> : null}
      <NotificationModal />
    </div>
  );
};

export default ContentWrapper;
