import React, { useState, useEffect, useRef } from 'react';

import Input from 'common/components/form/inputs/Input';
import SvgRender from 'common/components/general/SvgRender';
import { Row, Col } from 'reactstrap';

import { useDebounce } from 'utils/hooks';

import search from 'common/assets/svg/common/search.svg';

const SearchHeader = ({ getSavedItems }) => {
  const inputRef = useRef(null);
  const [textFieldValue, setTextFieldValue] = useState('');
  const debouncedSearchTerm = useDebounce(textFieldValue, 800);

  useEffect(
    () => {
      if (debouncedSearchTerm || debouncedSearchTerm === '') {
        getSavedItems({ search: textFieldValue, current_page: 1 });
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.querySelector('input').focus();
    }
  }, []);

  return (
    <div className="side-search-header ps-2 pb-3 mb-2" id="search-header" ref={inputRef}>
      <Row className="no-guttters ps-1">
        <Col xs={10} className="d-flex align-items-center border-bottom p-0">
          <Input
            type={'text'}
            value={textFieldValue}
            onChange={e => setTextFieldValue(e.target.value)}
            placeholder={'Search Orca'}
            className="search-input"
          />
          <SvgRender src={search} style={{ width: 16, height: 16, color: '#cacaca' }} />
        </Col>
      </Row>
    </div>
  );
};

export default SearchHeader;
