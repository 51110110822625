import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import WidgetActions from './WidgetActions';
import WidgetTitle from './WidgetTitle';
import { numberToStr } from 'common/utils/numbers';
import WidgetLinkTo from './WidgetLinkTo';

const WidgetDefaultCard = ({ id, count, linkTo }) => {
  return (
    <div
      className={`widget-default-card widget-layout-card d-flex justify-content-between flex-column position-relative px-3 cpy-12 w-100p`}
    >
      <Row noGutters>
        <Col className="overflow-hidden mt-1">
          <WidgetTitle
            id={id}
            withActions={false}
            className="text-start"
            titleClassName="fs-14 fw-medium text-primary"
          />
        </Col>
        <Col xs="auto" className="text-end">
          <WidgetActions id={id} />
        </Col>
      </Row>
      <Row noGutters className="flex-direction-column align-items-center cmb-20">
        <Col xs={6} className="fs-20 text-primary">
          <strong>{count ? numberToStr(count) : 0}</strong>
        </Col>
        <Col xs={6} className="text-end">
          <WidgetLinkTo linkTo={linkTo} id={id} size={'md'} />
        </Col>
      </Row>
    </div>
  );
};

WidgetDefaultCard.propTypes = {
  id: PropTypes.number.isRequired, // widget id
  count: PropTypes.number,
  linkTo: PropTypes.string
};

export default WidgetDefaultCard;
