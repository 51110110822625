import React, { useCallback, useEffect, useState } from 'react';

import { useForm, useFormState, useActions } from 'utils/hooks';
import Bookmark from './components/Bookmark';
import config from './config';
import CircledButton from 'common/components/buttons/CircledButton';
import WidgetSettings from 'views/dashboard/components/WidgetSettings';
import { selectWidgetPreferences, selectWidgetStatePaging } from 'store/dashboards/selectors';
import { useSelector } from 'react-redux';
import { getInitialSingleValue } from 'common/utils/lists';
import * as dashboardsActions from 'store/dashboards/actions';
import Spinner from 'common/components/general/Spinner';

const Preferences = ({ id, onSave, onClose, isSubmitting }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { formState, collectValues, loadValues, resetForm } = useForm(config);
  const { addSubform, removeSubform, subStates } = useFormState(formState);

  const bookmarks = subStates('bookmarks');

  const preferences = useSelector(state => selectWidgetPreferences(state, id));
  const paging = useSelector(state => selectWidgetStatePaging(state, id));

  const [getWidgetData] = useActions([dashboardsActions.getWidgetData]);

  const handleSubmitForm = async ({ custom_title }) => {
    const values = collectValues();

    if (!values) return;

    const { bookmarks } = values;

    try {
      await onSave({ preferences: { custom_title }, data: bookmarks, updateWidgetData: true });
      await getWidgetData({
        widgetId: id,
        paging: { per_page: paging.default_per_page, current_page: 1 }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const initWidgetData = useCallback(async (id, title) => {
    setIsLoading(true);

    try {
      const data = await getInitialSingleValue(`/dashboards/widgets/${id}`);

      loadValues({
        custom_title: title,
        bookmarks: data?.length ? data : []
      });

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      resetForm();
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (id) {
      initWidgetData(id);
    }
  }, [id, initWidgetData, preferences?.custom_title]);

  return (
    <WidgetSettings id={id} isSubmitting={isSubmitting} onClose={onClose} onSave={handleSubmitForm}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {bookmarks.map((bookmark, index) => (
            <Bookmark
              key={`bookmark-${index}`}
              bookmark={bookmark}
              index={index}
              removeSubform={removeSubform}
            />
          ))}

          <CircledButton
            style={{ width: 19, height: 19 }}
            className="cms-4 mt-1"
            type="add"
            label="Add more"
            onClick={() => addSubform('bookmarks')}
            svgStyle={{ width: 8, height: 8 }}
          />
        </>
      )}
    </WidgetSettings>
  );
};

export default Preferences;
