import { getDepartmentRole } from './actions';
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  active: null
};

const slice = createSlice({
  name: 'roles',
  initialState: INITIAL_STATE,
  extraReducers: builder => {
    builder
      .addCase(getDepartmentRole.pending, state => {
        state.active = null;

        return state;
      })
      .addCase(getDepartmentRole.fulfilled, (state, { payload }) => {
        state.active = payload;

        return state;
      });
  }
});

export default slice.reducer;
