import { getPersonSignature, updatePersonSignature } from './actions';
import TYPES from './types';
import _orderBy from 'lodash/orderBy';

export const INITIAL_STATE = {
  profile: null,
  profileIsLoading: false,
  typeModal: false,

  security: {},

  sensitiveInfo: {
    access: {},
    access_vessels: [],
    access_logs: [],
    accessStatus: null,
    isCrew: false
  },

  signature: null
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.UPDATE_PERSON.SUCCESS:
      return {
        ...state,
        profile: state.profile ? { ...state.profile, ...payload } : payload
      };

    case TYPES.GET_PERSON.START:
      return {
        ...state,
        profileIsLoading: true
      };

    case TYPES.GET_PERSON.ERROR:
      return {
        ...state,
        profile: null,
        profileIsLoading: false
      };

    case TYPES.GET_PERSON.SUCCESS:
      return {
        ...state,
        profile: payload,
        profileIsLoading: false
      };

    case TYPES.RESET_PROFILE:
      return {
        ...state,
        profile: null,
        typeModal: false,
        signature: null
      };

    case TYPES.UPDATE_PERSON_DEPARTMENT.SUCCESS:
      if (state.profile && payload && state.profile.id === payload.id) {
        return {
          ...state,
          profile: {
            ...state.profile,
            departments: payload.departments ? payload.departments : state.profile.departments
          }
        };
      }

      return state;

    case TYPES.GET_PERSON_DOCUMENTS.SUCCESS:
    case TYPES.UPDATE_PERSON_DOCUMENTS.SUCCESS:
      if (state.profile) {
        return {
          ...state,
          profile: {
            ...state.profile,
            documents: payload.documents
          }
        };
      }

      return state;

    case TYPES.SET_TYPE_MODAL:
      return {
        ...state,
        typeModal: payload
      };

    case TYPES.GET_PERSON_ACCESS.START:
      return {
        ...state,
        security:
          state.profile?.id && parseInt(payload.id) !== state.profile.id ? {} : state.security
      };

    case TYPES.GET_PERSON_ACCESS.SUCCESS:
      return {
        ...state,
        security: payload
      };

    case TYPES.REVOKE_PERSON_ACCESS.SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, can_login: false }
      };

    case TYPES.GET_SENSITIVE_INFO.START: {
      return {
        ...state,
        sensitiveInfo: {
          ...state.sensitiveInfo,
          access: {},
          access_vessels: []
        }
      };
    }

    case TYPES.GET_SENSITIVE_INFO.SUCCESS:
      return {
        ...state,
        sensitiveInfo: {
          ...state.sensitiveInfo,
          access: payload.access || {},
          access_vessels: payload.access_vessels,
          access_logs: _orderBy(payload.access_logs, ['created_at'], 'desc')
        }
      };

    case TYPES.UPDATE_SENSITIVE_INFO:
      return {
        ...state,
        sensitiveInfo: {
          ...state.sensitiveInfo,
          [payload.key]: {
            ...state.sensitiveInfo[payload.key],
            ...payload.value
          }
        }
      };

    case `${updatePersonSignature.fulfilled}`:
    case `${getPersonSignature.fulfilled}`:
      return {
        ...state,
        signature: payload
      };

    default:
      return state;
  }
};

export default reducer;
