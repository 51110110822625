import React, { useEffect, useCallback } from 'react';
import WidgetSettings from 'views/dashboard/components/WidgetSettings';
import Select from 'common/components/form/inputs/Select';
import Checkbox from 'common/components/form/inputs/Checkbox';
import { getAsyncOptions, refetchAsyncOptions } from 'utils/helpers';
import { getInitialSingleValue } from 'common/utils/lists';
import { selectWidgetPreferences } from 'store/dashboards/selectors';
import { useSelector } from 'react-redux';
import widgetsDetails from 'views/dashboard/widgets/widgets-details';

import { Row, Col } from 'reactstrap';
import { useForm, useFormState } from 'utils/hooks';
import { booleanField, optionField } from 'common/utils/form/fieldTypes';

const PersonsPreferences = ({ id, onSave, label, onClose, isSubmitting }) => {
  const { formState, loadValues, collectValues } = useForm(
    { filter: optionField({ required: true }), show_more: booleanField({ initialValue: false }) },
    {}
  );
  const { fields, selectField } = useFormState(formState);

  const preferences = useSelector(state => selectWidgetPreferences(state, id));

  const tableLabels = widgetsDetails?.[label].preferencesComponentProps.tableLabels;
  const allOptionsText = widgetsDetails?.[label].preferencesComponentProps.allOptionsText;

  const handleSubmitForm = async ({ custom_title }) => {
    const values = collectValues();
    if (!values) return;

    const params = {
      custom_title,
      saved_table_id: values.filter.id || null,
      show_more: values.show_more
    };

    onSave(params);
  };

  const getInitialValues = async () => {
    const opts = await refetchAsyncOptions('saved-tables', {
      table_labels: tableLabels
    });

    if (opts) {
      return [{ id: 0, name: allOptionsText }, ...opts];
    } else return [{ id: 0, name: allOptionsText }];
  };

  const init = useCallback(async () => {
    const res = await getInitialSingleValue(`/tables/${preferences?.saved_table_id}`);
    const params = {
      filter: preferences?.saved_table_id
        ? { id: res?.id, name: res?.name }
        : preferences?.saved_table_id !== undefined
        ? { id: 0, name: allOptionsText }
        : null,
      show_more: preferences?.show_more
    };
    loadValues(params);
  }, [allOptionsText, loadValues, preferences?.saved_table_id, preferences?.show_more]);

  useEffect(() => {
    init();
  }, [id, init]);

  return (
    <WidgetSettings id={id} isSubmitting={isSubmitting} onClose={onClose} onSave={handleSubmitForm}>
      <Row className="g-0 mt-3">
        <Col xs={12}>
          <Select
            isAsync
            loadOptions={search =>
              getAsyncOptions(search, 'saved-tables', { table_labels: tableLabels })
            }
            label="filter"
            defaultOptions={getInitialValues}
            placeholder=""
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            onChange={selectField('filter')}
            isClearable={false}
            {...fields.filter}
          />
        </Col>
      </Row>

      <Row className="g-0 fs-12 text-primary">
        <Col xs={12}>
          <Checkbox
            value={fields.show_more.value}
            onChange={() => selectField('show_more')(!fields.show_more.value)}
            label="Show all Persons (up to 100)"
          />
        </Col>
      </Row>
    </WidgetSettings>
  );
};

export default PersonsPreferences;
