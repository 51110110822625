import React from 'react';
import ContentLoader from 'react-content-loader';

const ConsumptionsLoader = () => {
  return (
    <div className=" mt-5">
      <ContentLoader
        viewBox="0 0 300 369"
        backgroundColor="rgba(34,36,42,0.2)"
        foregroundColor="rgba(34,36,42,0.1)"
        title="Loading consumptions..."
      >
        <rect x="9" y="257" rx="4" ry="4" width="138" height="107" />
        <rect x="154" y="257" rx="4" ry="4" width="138" height="107" />
        <rect x="9" y="143" rx="4" ry="4" width="138" height="107" />
        <rect x="154" y="143" rx="4" ry="4" width="138" height="107" />
        <rect x="154" y="27" rx="4" ry="4" width="138" height="107" />
        <rect x="9" y="27" rx="4" ry="4" width="138" height="107" />
      </ContentLoader>
    </div>
  );
};

export default ConsumptionsLoader;
