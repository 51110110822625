import { createSelector } from 'reselect';
import {
  getDescendanceTree,
  getItemBreadcrumbs
} from 'common/components/tree-layout/utils/helpers';
import _pickBy from 'lodash/pickBy';
import { ENITITY_MODEL } from 'components/permissions/policies/constants';
import permissionsConstants from 'common/utils/permissions/constants';

export const selectPathname = state => state?.router?.location?.pathname;

const selectPoliciesReducer = state => state.policies;
const selectPermissions = state => selectPoliciesReducer(state)?.permissions;

const getKey = (_, key) => key;

export const selectIsLoading = state => selectPoliciesReducer(state)?.isLoading;

/* Groups */
export const selectRootGroups = createSelector(
  [selectPermissions, selectPathname],
  (permissions, pathname) => {
    const data = permissions?.rootGroups;

    if (pathname?.includes('core'))
      return data
        ?.filter(
          item =>
            !item?.is_for_vessel && !item?.id?.includes(permissionsConstants.office.settings.view)
        )
        ?.map(i => i?.id);

    if (pathname?.includes('onboard'))
      return data
        ?.filter(
          item =>
            item?.is_for_vessel && !item?.id?.includes(permissionsConstants.office.settings.view)
        )
        ?.map(i => i?.id);

    if (pathname?.includes('settings'))
      return data
        ?.filter(item => item?.id?.includes(`${permissionsConstants.office.settings.view}.`))
        ?.map(i => i?.id);

    return permissions?.rootGroupsIDs;
  }
);

export const selectSubGroups = state => selectPermissions(state)?.subGroups;

export const selectGroupSubGroups = (state, id) => selectSubGroups(state)?.[id];

export const selectGroupHasSubGroups = createSelector(
  selectGroupSubGroups,
  subGroups => subGroups?.length > 0
);

export const selectGroups = state => selectPermissions(state)?.groups;

export const selectGroup = (state, id) => selectGroups(state)?.[id];

export const selectIsRootGroup = createSelector(selectGroup, group => !group?.parent_id);

export const selectIsConfigurableGroup = createSelector(
  selectGroup,
  group => group?.detail_types?.length > 0
);

export const selectGroupConfigurationDetailTypes = (state, id) =>
  selectGroup(state, id)?.detail_types;

export const selectGroupBreadcrumbs = createSelector(selectGroups, getKey, (groups, id) =>
  getItemBreadcrumbs(id, groups)
);

const selectExpandedGroups = state => selectPermissions(state)?.expandedGroups;

export const selectIsGroupExpanded = createSelector(
  selectExpandedGroups,
  getKey,
  (expandedGroups, id) => expandedGroups.includes(id)
);

export const selectAreAllGroupsExpanded = createSelector(
  selectExpandedGroups,
  selectRootGroups,
  (expandedGroups, rootGroups) => expandedGroups.length === rootGroups.length
);

export const selectIsEnabledGroup = createSelector(selectGroup, group => group?.enabled);

export const selectIsEnabledParentGroup = createSelector(
  selectGroup,
  selectGroups,
  selectSubGroups,
  (parentGroup, groups, subGroups) => {
    const groupChildren = getDescendanceTree(parentGroup.id, groups, subGroups);

    return groupChildren.filter(group => group.id !== parentGroup.id).some(group => group.enabled);
  }
);

export const selectSomeSubGroupsAreEnabled = createSelector(
  selectGroup,
  selectGroups,
  selectSubGroups,
  (parentGroup, groups, subGroups) => {
    const groupChildren = getDescendanceTree(parentGroup.id, groups, subGroups);
    const subs = groupChildren.filter(c => c?.parent_id);
    const enabledSubs = subs.filter(s => s?.enabled);

    if (subs?.length) {
      return subs.length > (enabledSubs?.length || 0) && enabledSubs?.length;
    }
  }
);

export const selectGroupHasSetConfiguration = createSelector(selectGroup, group => {
  return Object.keys(_pickBy(group?.details || {}, item => item?.length > 0))?.length > 0;
});

const selectConfiguringGroups = state => selectPermissions(state)?.configuringGroups;

export const selectIsConfiguringGroup = createSelector(
  selectConfiguringGroups,
  getKey,
  (configuringGroups, id) => configuringGroups.includes(id)
);

export const selectIsGroupForOwnProfile = createSelector(selectGroup, group =>
  group?.id?.includes('own_profile.')
);

export const selectAllUsersProfileGroup = createSelector(
  selectGroups,
  getKey,
  (groups, id) =>
    Object.values(
      _pickBy(groups, group => group.id === id.replace('own_profile.', 'all_users.')) || {}
    )?.[0]
);

export const selectHasPermissionsWithSettings = createSelector(selectGroups, groups => {
  const keys = Object.keys(groups);
  const enabledSettingPermissions = keys.filter(
    key =>
      key.includes(permissionsConstants.office.settings.view) &&
      groups[key].enabled &&
      key !== permissionsConstants.office.settings.view
  );

  return enabledSettingPermissions.length > 0;
});
/* -- */

/* Search */
export const selectGroupsTreeSearch = state => selectPermissions(state).treeSearch;
export const selectHasSearch = state => !!selectGroupsTreeSearch(state);

export const selectGroupsInSearch = state => selectPermissions(state).groupsInSearch;

export const isGroupInSearch = createSelector(
  selectGroupsInSearch,
  getKey,
  selectHasSearch,
  selectIsLoading,
  (groupsInSearch, id, hasSearch, isLoading) =>
    !isLoading && hasSearch ? groupsInSearch?.[id] : true
);
/* -- */

/* Active Entity */
export const selectActiveEntityId = state => selectPoliciesReducer(state)?.activeEntityId;

export const selectActiveEntityModel = state => selectPoliciesReducer(state)?.activeEntityModel;

export const selectAssignedModeIsEnabled = createSelector(
  selectActiveEntityModel,
  activeEntityModel => [ENITITY_MODEL.PARTY].includes(activeEntityModel)
);

export const selectPreviewModeIsEnabled = createSelector(
  selectActiveEntityId,
  selectAssignedModeIsEnabled,
  (activeEntityId, assignedModeIsEnabled) =>
    !activeEntityId || assignedModeIsEnabled ? true : false
);

export const selectActiveEntityHasPermissions = createSelector(
  selectActiveEntityId,
  selectRootGroups,
  (activeEntityId, rootGroups) => (activeEntityId && rootGroups?.length > 0 ? true : false)
);
/* -- */
