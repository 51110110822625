import {
  addPurchasingPricelistItem,
  applyPurchasingPricelistPriceZoneDiscount,
  deletePurchasingPricelistItem,
  getPurchasingPricelist,
  getPurchasingPricelistItems,
  updatePurchasingPricelistGlobalDiscount,
  updatePurchasingPricelistItemPriceZone
} from './actions';
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isLoading: false,
  isAutoSaving: false,
  isInitialized: false,
  shouldRefetchTableData: false,
  active: null,
  items: {}, // { item_id: { price_zone_id: { discount, price, ... } } },
  expandedPriceZoneDiscounts: [], // [ price_zone_id ]
  priceZoneDiscounts: {}, // { price_zone_id: value }
  priceZoneForm: { isOpen: false, active: null },
  isPurchasingDrawerOpen: false
};

const slice = createSlice({
  name: 'purchasingPricelist',
  initialState: INITIAL_STATE,
  reducers: {
    resetPricelistState: state => {
      // Reset state
      state.active = null;
      state.isInitialized = false;
      state.isPurchasingDrawerOpen = false;
      state.items = {};
      state.expandedPriceZoneDiscounts = [];
      state.priceZoneDiscounts = {};

      return state;
    },
    setPriceZoneForm: (state, { payload }) => {
      state.priceZoneForm.isOpen = payload.isOpen;
      state.priceZoneForm.active = payload.active;

      return state;
    },
    setIsPurchasingDrawerOpen: (state, { payload }) => {
      state.isPurchasingDrawerOpen = payload;

      return state;
    },
    initializePricelistItems: state => {
      state.isInitialized = true;

      return state;
    },
    setPricelistItems: (state, { payload }) => {
      state.items = payload;

      return state;
    },
    setItemPriceZoneFiedValue: (state, { payload }) => {
      const { itemId, priceZoneId, key, value } = payload;
      state.items[itemId][priceZoneId][key] = value;

      return state;
    },
    setPriceZoneDiscount: (state, { payload }) => {
      const { priceZoneId, value } = payload;
      state.priceZoneDiscounts[priceZoneId] = value;

      return state;
    },
    togglePriceZoneDiscount: (state, { payload }) => {
      const { priceZoneId } = payload;

      state.expandedPriceZoneDiscounts = state.expandedPriceZoneDiscounts.includes(priceZoneId)
        ? state.expandedPriceZoneDiscounts.filter(id => id !== priceZoneId)
        : [...state.expandedPriceZoneDiscounts, priceZoneId];

      return state;
    },
    setExpandedPriceZoneDiscounts: (state, { payload }) => {
      state.expandedPriceZoneDiscounts = payload;

      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getPurchasingPricelist.pending, state => {
        state.isLoading = true;

        return state;
      })
      .addCase(getPurchasingPricelist.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.active = payload;

        return state;
      })
      .addCase(getPurchasingPricelist.rejected, state => {
        state.isLoading = false;

        return state;
      })
      .addCase(updatePurchasingPricelistItemPriceZone.pending, state => {
        state.isAutoSaving = true;

        return state;
      })
      .addCase(updatePurchasingPricelistItemPriceZone.fulfilled, state => {
        state.isAutoSaving = false;

        return state;
      })
      .addCase(updatePurchasingPricelistItemPriceZone.rejected, state => {
        state.isAutoSaving = false;

        return state;
      })
      .addCase(applyPurchasingPricelistPriceZoneDiscount.fulfilled, (state, { payload }) => {
        payload?.items?.forEach(({ item_id, price_zone_id, discount }) => {
          state.items[item_id][price_zone_id].discount = discount;
        });

        return state;
      })
      .addCase(addPurchasingPricelistItem.fulfilled, state => {
        state.shouldRefetchTableData = true;

        return state;
      })
      .addCase(deletePurchasingPricelistItem.fulfilled, state => {
        state.shouldRefetchTableData = true;

        return state;
      })
      .addCase(updatePurchasingPricelistGlobalDiscount.fulfilled, state => {
        state.shouldRefetchTableData = true;

        return state;
      })
      .addCase(getPurchasingPricelistItems.pending, state => {
        state.shouldRefetchTableData = false;

        return state;
      });
  }
});

export const {
  setPriceZoneForm,
  setIsPurchasingDrawerOpen,
  initializePricelistItems,
  setPricelistItems,
  setItemPriceZoneFiedValue,
  setPriceZoneDiscount,
  togglePriceZoneDiscount,
  setExpandedPriceZoneDiscounts,
  resetPricelistState
} = slice.actions;

export default slice.reducer;
