import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import useRouter from 'use-react-router';

import { ToggleButton, ToolsSidebar, useTools } from 'tools';

import * as toolsActions from 'store/tools/actions';
import { selectSystemCurrency } from 'store/settings/selectors';
import { selectActiveDashboardHasSidebar } from 'store/dashboards/selectors';

import settings from './settings';
import paths from 'routing/routes/_paths';

const ToolBar = () => {
  const { isOpen, setIsOpen } = useTools();
  const { location } = useRouter();

  const systemCurrency = useSelector(selectSystemCurrency);
  const activeDashboardHasSidebar =
    useSelector(selectActiveDashboardHasSidebar) && location.pathname.startsWith(paths.dashboard);

  const tools = useSelector(state => state.tools);
  const [getToolsList, getUserTools, updateUserTools] = useActions([
    toolsActions.getToolsList,
    toolsActions.getUserTools,
    toolsActions.updateUserTools
  ]);

  useEffect(() => {
    // if (!tools.fetched || (tools.fetched && !tools.list.length)) {
    getToolsList();
    getUserTools();
    // }
  }, [getToolsList, getUserTools]);

  return (
    <>
      <ToggleButton
        onClick={() => setIsOpen(true)}
        className={activeDashboardHasSidebar ? 'dashboard-sidebar-enabled' : ''}
      />
      <ToolsSidebar
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        close={() => setIsOpen(false)}
        list={tools.list}
        userTools={tools.data}
        updateUserTools={updateUserTools}
        settings={settings}
        systemCurrency={systemCurrency}
      />
    </>
  );
};

export default ToolBar;
