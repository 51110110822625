import TYPES from './types';
import BUNKER_TYPES from './bunkers/types';
import DOCUMENT_TYPES from '@/common/components/vessels/profile/tabs/documents/store/types';
import _cloneDeep from 'lodash/cloneDeep';
import { reorderVesselBenchmarks } from './helpers';

import { LOCATION_CHANGE } from 'connected-react-router';

const DOCUMENTS_INITIAL_STATE = {
  isDataLoading: false,
  drawerOpen: false,
  drawerType: 'create',
  drawerActiveItem: null,
  expandedAllPanels: false,
  types: [], // Top level boxes
  categories: {}, // Inner Tables State
  exludedCategories: [],
  search: ''
};

const INITIAL_STATE = {
  profile: {
    loading: false
  },
  crewFormOpen: false,
  activeCrew: null,
  tceResultsData: [],
  tceResultsFilters: null,
  tceResultsChartData: null,
  forceUpdateTceResultsChartData: false,
  tceResultsAverages: null,
  loading: false,
  makerModal: false,
  bunkers: {
    fetching: false,
    drawerOpen: false,
    modalOpen: false,
    robReseted: false,
    actions: [],
    endRob: {},
    startRob: {},
    totals: {}
  },
  pool: {
    fetching: false,
    poolData: [],
    drawer: { isOpen: false, type: null },
    active: null
  },
  documents: _cloneDeep(DOCUMENTS_INITIAL_STATE),
  ciiReferenceYearly: {},
  defaultSources: {
    data: [],
    fetching: false
  },
  labelParameters: {
    paging: {
      current_page: 0,
      from: null,
      to: null,
      last_page: null,
      path: null,
      total: null,
      per_page: null
    },
    data: [],
    fetching: false
  },
  integrationSources: {
    data: []
  },
  benchmarks: {
    inputHoverIndex: null,
    isDataLoading: false,
    editingBenchmarkId: null,
    data: [],
    conditions: null,
    types: null,
    isLoadingConditionsOrTypes: false,
    isLogsDrawerOpen: false,
    activeLogsFormula: null
  }
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.GET_VESSEL_PROFILE.START:
      if (state.profile.id === payload.params.id) {
        return state;
      } else {
        return {
          ...state,
          tceResultsFilters: null,
          tceResultsChartData: null,
          profile: {
            loading: true
          },
          documents: _cloneDeep(DOCUMENTS_INITIAL_STATE)
        };
      }

    case TYPES.GET_VESSEL_PROFILE.ERROR:
      return {
        ...state,
        tceResultsFilters: null,
        tceResultsChartData: null,
        profile: {
          loading: false
        }
      };

    case TYPES.GET_VESSEL_PROFILE.SUCCESS:
    case TYPES.SET_VESSEL_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: false,
          ...payload,
          hires: state.profile.hires ? [...state.profile.hires] : [],
          market_indexes: payload?.market_indexes?.length
            ? payload.market_indexes.map(i => ({
                ...i,
                is_default: i.pivot.is_default,
                adjustment: i.pivot.adjustment
              }))
            : []
        }
      };

    case TYPES.GET_VESSEL_HIRES.SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          hires: payload
        }
      };

    case TYPES.UPDATE_VESSEL_HIRES.SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          charter_type: payload.isVesselProfile ? state.profile.charter_type : payload.charterType
        }
      };

    case TYPES.SET_ACTIVE_CREW:
      return {
        ...state,
        activeCrew: payload
      };

    case TYPES.TOGGLE_CREW_FORM:
      return {
        ...state,
        crewFormOpen: !state.crewFormOpen
      };

    case TYPES.CREATE_TCE_RESULT.SUCCESS:
      return {
        ...state,
        forceUpdateTceResultsChartData: true
      };

    case TYPES.GET_TCE_RESULTS.START:
      return {
        ...state,
        loading: true,
        tceResultsData: [],
        tceResultsFilters: {
          date_from: payload.params.date_from,
          date_to: payload.params.date_to,
          market_index_id: payload.params.market_index_id,
          type: payload.params.type,
          vessel_id: payload.params.vessel_id
        }
      };

    case TYPES.GET_TCE_RESULTS_CHART.SUCCESS:
      return {
        ...state,
        tceResultsChartData: {
          data: payload.data
        }
      };

    case TYPES.GET_TCE_RESULTS_AVERAGES.SUCCESS:
      return {
        ...state,
        tceResultsAverages: {
          data: payload.data
        }
      };

    case TYPES.GET_TCE_RESULTS.SUCCESS:
      return {
        ...state,
        tceResultsData: payload?.data?.data,
        loading: false,
        forceUpdateTceResultsChartData: false
      };

    case TYPES.CREATE_MAKER_MODAL:
      return {
        ...state,
        makerModal: payload
      };

    case DOCUMENT_TYPES.CHANGE_VESSEL_DOCUMENT_DRAWER_STATUS:
      return {
        ...state,
        documents: {
          ...state.documents,
          drawerOpen: payload.drawerOpen,
          drawerType: payload.drawerType || 'create',
          drawerActiveItem: !payload.drawerActiveItem ? null : { ...payload.drawerActiveItem }
        }
      };

    case `${DOCUMENT_TYPES.GET_VESSEL_DOCUMENTS}/pending`:
      return {
        ...state,
        documents: {
          ...state.documents,
          isDataLoading: true
        }
      };

    case TYPES.GET_VESSEL_CII_REFERENCE_YEARLY.SUCCESS:
      return {
        ...state,
        ciiReferenceYearly: payload
      };

    case `${DOCUMENT_TYPES.GET_VESSEL_DOCUMENTS}/fulfilled`:
      return {
        ...state,
        documents: {
          ...state.documents,
          isDataLoading: false,
          types: payload.map(d => ({
            id: d.id,
            type_name: d.category_name,
            template: d.template,
            categories_count: d.types.length
          })),
          exludedCategories: payload.reduce((acc, category) => {
            const categoryIds = category.types.map(c => c.id);

            return [...acc, ...categoryIds];
          }, []),
          categories: payload.reduce((acc, category) => {
            acc[category.id] = {
              data: category.types.map(type => ({
                ...type,
                category_name: type.type_name,
                reminders: type.reminders
              })),
              paging: null,
              sorting: null
            };

            return acc;
          }, {})
        }
      };

    case `${DOCUMENT_TYPES.GET_VESSEL_DOCUMENTS}/rejected`:
      return {
        ...state,
        documents: {
          ...state.documents,
          isDataLoading: false,
          types: [],
          categories: {}
        }
      };

    case DOCUMENT_TYPES.EXPAND_ALL_VESSEL_DOCUMENT_PANELS:
      return {
        ...state,
        documents: {
          ...state.documents,
          expandedAllPanels: payload
        }
      };

    case DOCUMENT_TYPES.SET_VESSEL_DOCUMENTS_SEARCH:
      return {
        ...state,
        documents: {
          ...state.documents,
          search: payload
        }
      };

    case BUNKER_TYPES.GET_VESSEL_BUNKERS.START:
      return {
        ...state,
        bunkers: {
          ...state.bunkers,
          fetching: true
        }
      };

    case BUNKER_TYPES.GET_VESSEL_BUNKERS.SUCCESS:
      return {
        ...state,
        bunkers: {
          ...state.bunkers,
          fetching: false,
          robReseted: false,
          actions: payload.actions,
          endRob: payload.end_rob,
          startRob: payload.start_rob,
          totals: payload.totals
        }
      };

    case BUNKER_TYPES.GET_VESSEL_BUNKERS.ERROR:
      return {
        ...state,
        bunkers: {
          ...state.bunkers,
          robReseted: false,
          fetching: false
        }
      };

    case BUNKER_TYPES.RESET_BUNKERS.SUCCESS:
      return {
        ...state,
        bunkers: {
          ...state.bunkers,
          robReseted: true
        }
      };

    case BUNKER_TYPES.CHANGE_BUNKER_DRAWER_STATUS:
      return {
        ...state,
        bunkers: {
          ...state.bunkers,
          drawerOpen: payload.open
        }
      };

    case BUNKER_TYPES.CHANGE_BUNKER_MODAL_STATUS:
      return {
        ...state,
        bunkers: {
          ...state.bunkers,
          modalOpen: payload.open
        }
      };

    case BUNKER_TYPES.CHANGE_BUNKERS_CALCULATE_METHOD.SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          rob_calculation: payload.rob_calculation
        }
      };

    case TYPES.GET_POOLS.START:
      return {
        ...state,
        pool: {
          ...state.pool,
          fetching: true
        }
      };

    case TYPES.GET_POOLS.SUCCESS:
      return {
        ...state,
        pool: {
          ...state.pool,
          fetching: false,
          poolData: payload
        }
      };

    case TYPES.GET_POOLS.ERROR:
      return {
        ...state,
        pool: {
          ...state.pool,
          fetching: false
        }
      };

    case TYPES.CREATE_POOL.START:
      return {
        ...state,
        pool: {
          ...state.pool,
          fetching: true
        }
      };

    case TYPES.CREATE_POOL.SUCCESS:
      return {
        ...state,
        pool: {
          ...state.pool,
          fetching: false,
          poolData: [...state.pool.poolData, payload]
        }
      };

    case TYPES.CREATE_POOL.ERROR:
      return {
        ...state,
        pool: {
          ...state.pool,
          fetching: false
        }
      };

    case TYPES.CREATE_POOL_POINT.START:
      return {
        ...state,
        pool: {
          ...state.pool
        }
      };

    case TYPES.CREATE_POOL_POINT.SUCCESS:
      return {
        ...state,
        pool: {
          ...state.pool,
          poolData: state.pool.poolData.map(pool => {
            const isIdEqual = payload.some(p => p.pool_timeline_id === pool.id);

            if (payload?.length) {
              if (isIdEqual) {
                return { ...pool, points: payload };
              } else {
                return pool;
              }
            } else return { ...pool, points: [] };
          })
        }
      };

    case TYPES.CREATE_POOL_POINT.ERROR:
      return {
        ...state,
        pool: {
          ...state.pool
        }
      };

    case TYPES.CREATE_POOL_MONTHLY_RESULT.START:
      return {
        ...state,
        pool: {
          ...state.pool
        }
      };

    case TYPES.CREATE_POOL_MONTHLY_RESULT.SUCCESS:
      return {
        ...state,
        pool: {
          ...state.pool,
          poolData: state.pool.poolData.map(pool => {
            const isIdEqual = payload.some(p => p.pool_timeline_id === pool.id);

            if (payload?.length) {
              if (isIdEqual) {
                return { ...pool, monthly_results: payload };
              } else {
                return pool;
              }
            } else {
              return { ...pool, monthly_results: [] };
            }
          })
        }
      };

    case TYPES.CREATE_POOL_MONTHLY_RESULT.ERROR:
      return {
        ...state,
        pool: {
          ...state.pool
        }
      };

    case TYPES.CHANGE_POOL_DRAWER_STATUS:
      return {
        ...state,
        pool: {
          ...state.pool,
          drawer: { isOpen: payload.isOpen, type: payload.type }
        }
      };

    case TYPES.SET_ACTIVE_POOL:
      return {
        ...state,
        pool: {
          ...state.pool,
          active: payload
        }
      };

    case TYPES.GET_DEFAULT_SOURCES.START:
      return {
        ...state,
        defaultSources: {
          ...state.defaultSources,
          fetching: true
        }
      };

    case TYPES.GET_DEFAULT_SOURCES.SUCCESS:
      return {
        ...state,
        defaultSources: {
          data: payload,
          fetching: false
        }
      };

    case TYPES.GET_DEFAULT_SOURCES.ERROR:
      return {
        ...state,
        defaultSources: {
          ...state.defaultSources,
          fetching: false
        }
      };

    case TYPES.SET_DEFAULT_SOURCES.SUCCESS:
      return {
        ...state,
        defaultSources: {
          data: payload,
          fetching: false
        }
      };

    case TYPES.GET_LABEL_PARAMETERS.START:
      return {
        ...state,
        labelParameters: {
          ...state.labelParameters,
          fetching: true
        }
      };

    case TYPES.GET_LABEL_PARAMETERS.SUCCESS:
      return {
        ...state,
        labelParameters: {
          paging: {
            current_page: payload.current_page,
            from: payload.from,
            to: payload.to,
            last_page: payload.last_page,
            path: payload.path,
            total: payload.total,
            per_page: payload.per_page
          },
          data: payload.data,
          fetching: false
        }
      };

    case TYPES.GET_LABEL_PARAMETERS.ERROR:
      return {
        ...state,
        labelParameters: {
          ...state.labelParameters,
          fetching: false
        }
      };

    case TYPES.SET_LABEL_PARAMETERS.SUCCESS:
      return {
        ...state,
        labelParameters: {
          ...state.labelParameters,
          data: state.labelParameters.data.map(el =>
            payload.data && payload.data.id === el.id
              ? {
                  ...payload.data,
                  source_name: payload.data.source.name,
                  fuel_grade_label: payload.data.fuel_grade
                    ? payload.data.fuel_grade.fuel_grade_label
                    : null
                }
              : el
          ),
          fetching: false
        }
      };

    case TYPES.GET_INTEGRATION_SOURCES.SUCCESS:
      return {
        ...state,
        integrationSources: {
          data: payload
        }
      };

    case TYPES.SET_BENCHMARKS_INPUT_HOVER_INDEX:
      return {
        ...state,
        benchmarks: {
          ...state.benchmarks,
          inputHoverIndex: payload
        }
      };

    case TYPES.GET_VESSEL_BENCHMARKS.START:
    case TYPES.GET_VESSEL_BENCHMARKS.ERROR:
      return {
        ...state,
        benchmarks: {
          ...state.benchmarks,
          isDataLoading: payload.isDataLoading
        }
      };

    case TYPES.GET_VESSEL_BENCHMARKS.SUCCESS:
      return {
        ...state,
        benchmarks: {
          ...state.benchmarks,
          data: payload.data,
          isDataLoading: payload.isDataLoading
        }
      };

    case TYPES.GET_VESSEL_BENCHMARK_TYPES.START:
    case TYPES.GET_VESSEL_BENCHMARK_TYPES.ERROR:
      return {
        ...state,
        benchmarks: {
          ...state.benchmarks,
          isLoadingConditionsOrTypes: payload.loading,
          types: null
        }
      };

    case TYPES.GET_VESSEL_BENCHMARK_TYPES.SUCCESS:
      return {
        ...state,
        benchmarks: {
          ...state.benchmarks,
          isLoadingConditionsOrTypes: false,
          types: payload
        }
      };

    case TYPES.GET_VESSEL_BENCHMARK_CONDITIONS.START:
    case TYPES.GET_VESSEL_BENCHMARK_CONDITIONS.ERROR:
      return {
        ...state,
        benchmarks: {
          ...state.benchmarks,
          isLoadingConditionsOrTypes: payload.loading,
          conditions: null
        }
      };

    case TYPES.GET_VESSEL_BENCHMARK_CONDITIONS.SUCCESS:
      return {
        ...state,
        benchmarks: {
          ...state.benchmarks,
          isLoadingConditionsOrTypes: false,
          conditions: payload
        }
      };

    case TYPES.REORDER_BENCHMARK_FORMULAS.START:
      const updatedBenchmarkData = reorderVesselBenchmarks(state, payload);

      return {
        ...state,
        benchmarks: {
          ...state.benchmarks,
          data: updatedBenchmarkData
        }
      };

    case TYPES.REORDER_BENCHMARK_FORMULAS.SUCCESS:
      return {
        ...state,
        benchmarks: {
          ...state.benchmarks,
          data: state.benchmarks.data.map(d => (d.id === payload.id ? payload : d))
        }
      };

    case TYPES.SET_BENCHMARKS_LOGS_DRAWER_OPEN:
      return {
        ...state,
        benchmarks: {
          ...state.benchmarks,
          isLogsDrawerOpen: payload
        }
      };

    case TYPES.SET_BENCHMARKS_ACTIVE_LOGS_FORMULA:
      return {
        ...state,
        benchmarks: {
          ...state.benchmarks,
          activeLogsFormula: payload
        }
      };

    case TYPES.SET_EDITTING_BENCHMARK_ID:
      return {
        ...state,
        benchmarks: {
          ...state.benchmarks,
          editingBenchmarkId: payload
        }
      };

    case LOCATION_CHANGE:
      return {
        ...state,
        ciiReferenceYearly: {}
      };

    default:
      return state;
  }
};

export default reducer;
