import FillAuthenticationCode from 'common/components/authentication/authenticator/FillAuthenticationCode';
import BoxShadowButton from 'common/components/buttons/BoxShadowButton';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';

const FillCode = ({ code, setCode, onLogin, disabled }) => {
  return (
    <Form className="login-page__totp d-flex flex-column justify-content-center align-items-center h-100p">
      <h1 className="text-center gotham-normal position-absolute">Two-factor authentication</h1>

      <div className="login-page__totp-container">
        <FillAuthenticationCode onCodeChange={setCode} codeError={code?.error} />

        <BoxShadowButton
          color="yellow"
          type="submit"
          className="w-100p py-1 fs-14 mt-2 mt-hd-4 mb-2 mb-hd-3 height-40"
          size="sm"
          onClick={onLogin}
          disabled={disabled}
        >
          Login
        </BoxShadowButton>
      </div>
    </Form>
  );
};

FillCode.propTypes = {
  code: PropTypes.object,
  setCode: PropTypes.func,
  onLogin: PropTypes.func,
  disabled: PropTypes.bool
};

export default FillCode;
