import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';

const EstimationsList = React.lazy(() => retry(() => import('@/views/estimations/index.jsx')));
const Estimator = React.lazy(() => retry(() => import('@/views/estimations/estimator/index.jsx')));

const estimations = [
  {
    path: `${paths.estimations}`,
    component: EstimationsList,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.voyages.estimations.list.view],
    documentTitle: 'Estimations'
  },
  {
    path: `${paths.estimations}/:id`,
    component: Estimator,
    app: true,
    type: 'private',
    exact: true,
    permissions: [
      permissions.office.voyages.estimations.view,
      permissions.office.voyages.estimations.edit
    ],
    documentTitle: 'Estimator'
  }
];

export default estimations;
