import { createSlice } from '@reduxjs/toolkit';
import { getChartererEvaluation } from './actions';

const INITIAL_STATE = {
  evaluation: null,
  isFetching: false
};

const slice = createSlice({
  name: 'charterer-tooltip',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getChartererEvaluation.pending, state => {
        state.evaluation = null;
        state.isFetching = true;

        return state;
      })
      .addCase(getChartererEvaluation.fulfilled, (state, { payload }) => {
        state.evaluation = payload;
        state.isFetching = false;

        return state;
      })
      .addCase(getChartererEvaluation.rejected, state => {
        state.evaluation = null;
        state.isFetching = false;

        return state;
      });
  }
});

export default slice.reducer;
