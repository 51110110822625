export const constructEntities = (entities, entity_id, payload) => {
  const data = {
    id: payload?.id || null,
    description: payload.name || '',
    steps: payload?.steps || [],
    isEnabled: payload?.id ? true : entities[entity_id]?.isEnabled,
    entity_type: payload.entity_type,
    review_process_action: payload.review_process_action,
    is_rejected: payload.is_rejected,
    template_matches_with_entity: payload?.template_matches_with_entity
  };

  return {
    ...entities,
    [entity_id]: data
  };
};
