import { FC } from 'react';
import EntityLabel from 'common/components/labels/EntityLabel';
import CompanySubTypeLabels from '@/views/companies/profile/configs/components/CompanySubTypeLabels.tsx';
import { CompanySubtype, CompanyType } from '@/common/types/companies.ts';

type CompanyTypesLabelProps = {
  types: CompanyType[];
  subTypes?: CompanySubtype[];
};

const CompanyTypesLabel: FC<CompanyTypesLabelProps> = ({ types, subTypes }) => {
  return types.length ? (
    <div className="d-flex flex-column cmt-4 flex-nowrap overflow-hidden">
      {types.map(company_type => {
        const companySubtypes = (subTypes || []).filter(
          e => e.party_company_type_id === company_type.id
        );

        return (
          <div key={company_type.id} className="d-flex cmb-4 align-items-center">
            <EntityLabel type="company-type">{company_type.name}</EntityLabel>

            {companySubtypes.length ? (
              <div className="d-flex align-items-center flex-1 overflow-hidden">
                <div className="cms-2 cme-4 text-violet">|</div>
                <CompanySubTypeLabels subtypes={companySubtypes} maxLimit={1} />
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  ) : null;
};

export default CompanyTypesLabel;
