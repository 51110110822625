import PropTypes from 'prop-types';

export const PurchaseOrder = PropTypes.shape({
  requisition: PropTypes.shape({
    categories: PropTypes.array,
    code: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired,
  supplier: PropTypes.shape({
    full_name: PropTypes.string,
    id: PropTypes.number
  }).isRequired
});

export const RequisitionCategoriesField = PropTypes.shape({
  key: PropTypes.string.isRequired,
  label: PropTypes.string,
  maxWidth: PropTypes.number,
  sort_index: PropTypes.number,
  supplierRequisitionID: PropTypes.string.isRequired,
  supplier_sort_index: PropTypes.number
});

export const StoreGroup = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  parent: PropTypes.shape({ id: PropTypes.string })
});

export const Suppliers = PropTypes.shape({
  id: PropTypes.number.isRequired,
  supplier_email: PropTypes.string,
  supplier_full_name: PropTypes.string.isRequired
});
