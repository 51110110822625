import moment from 'moment';
import { dateToLocal } from 'common/utils/dates';

const ReceivedDate = ({ item }) => {
  return (
    <div className="text-violet">
      {moment().utc().diff(item.last_received_at, 'hours') > 24
        ? moment(item.last_received_at).format('DD MMM YYYY, HH:mm UTC')
        : dateToLocal(item.last_received_at).fromNow(true)}
    </div>
  );
};

export default ReceivedDate;
