import paths from '@/routing/routes/_paths.js';

import Login from '@/views/auth/login/index.jsx';
import Logout from '@/views/auth/Logout.jsx';

const auth = [
  {
    type: 'no_auth',
    exact: true,
    path: paths.login,
    component: Login,
    documentTitle: 'Login'
  },
  {
    path: paths.logout,
    component: Logout,
    type: 'private',
    exact: true,
    documentTitle: 'Logout'
  }
];

export default auth;
