import { selectActiveDashboardWidgetsPerLayout } from 'store/dashboards/selectors';
import { useSelector } from 'react-redux';

import LayoutCardWidget from 'views/dashboard/components/LayoutCardWidget';
import LayoutTableWidget from 'views/dashboard/components/LayoutTableWidget';
import LayoutSidebarWidget from 'views/dashboard/components/LayoutSidebarWidget';
import Widget from './Widget';

const components = {
  card: LayoutCardWidget,
  table: LayoutTableWidget,
  sidebar: LayoutSidebarWidget
};

const Layout = ({ widgetId, layout }) => {
  const Tag = components[layout];

  return (
    <Tag widgetId={widgetId}>
      <Widget id={widgetId} layout={layout} />
    </Tag>
  );
};

const WidgetsLayout = ({ layout }) => {
  const widgets = useSelector(state => selectActiveDashboardWidgetsPerLayout(state, layout));

  return (
    <div
      className={`dashbord-widgets-layout dashbord-widgets-layout--${layout} ${
        layout === 'sidebar' ? 'disable-scrollbars' : ''
      }`}
    >
      {widgets.map(widgetId => (
        <Layout key={widgetId} layout={layout} widgetId={widgetId} />
      ))}
    </div>
  );
};

export default WidgetsLayout;
