import React from 'react';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import SvgRender from 'common/components/general/SvgRender';

import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import * as notificationModalActions from 'store/notification-modal/actions';

const NotificationModal = () => {
  const modal = useSelector(state => state.notificationModal);
  const [hideModal] = useActions([notificationModalActions.hideModal]);

  return (
    <DangerousActionModal
      actionIcon={
        modal.icon ? <SvgRender src={modal.icon} style={{ width: 80, height: 80 }} /> : null
      }
      actionHoverColor="primary"
      transparent
      onAccept={modal.primaryAction}
      onClose={modal.secondaryAction}
      closeModal={hideModal}
      isOpen={modal.isOpen}
      actionText={modal.primaryText}
      cancelText={modal.secondaryText}
      header={modal.title}
      body={modal.text}
    />
  );
};

export default NotificationModal;
