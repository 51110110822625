import _sortBy from 'lodash/sortBy';
import _indexOf from 'lodash/indexOf';
import _get from 'lodash/get';

export const reorderVesselBenchmarks = (state, payload = []) => {
  const params = _get(payload, 'params', []);
  const formulaIds = _get(params, 'formula_ids', []);
  const benchmarkId = _get(params, 'benchmark_id', []);
  const benchmarkData = _get(state, 'benchmarks.data', []);
  const formulaData =
    benchmarkData.find(benchmark => benchmark?.id === benchmarkId)?.vessel_benchmark_formulas || [];
  const sortedFormulaData = _sortBy(formulaData, obj => _indexOf(formulaIds, obj.id));

  return benchmarkData.map(benchmark =>
    benchmark?.id === benchmarkId
      ? { ...benchmark, vessel_benchmark_formulas: sortedFormulaData }
      : benchmark
  );
};
