import { createSelector } from 'reselect';

const getKey = (_, key) => key;

export const selectNotificationReducer = state => state.notifications;
export const selectNotificationsFetching = state =>
  selectNotificationReducer(state)?.isNotificationFetching;

export const selectNotifications = state => selectNotificationReducer(state).data;
export const selectHasFetched = state => selectNotificationReducer(state).hasFetchedNotifications;

export const selectLoadingMore = state => selectNotificationReducer(state).loadingMore;
export const selectCount = state => selectNotificationReducer(state).count;

export const selectActiveCategoryLabel = state =>
  selectNotificationReducer(state).activeCategoryLabel;

export const selectIsUnreadTabActive = state => selectNotificationReducer(state).isUnreadTabActive;

export const selectNotificationsSummary = state => selectNotificationReducer(state).summary;
export const selectNotificationsHasSummary = state =>
  selectNotificationReducer(state)?.summary?.length;

export const selectNotificationsIsSummaryFetching = state =>
  selectNotificationReducer(state)?.isSummaryFetching;

const selectSummaryTypeData = createSelector(selectNotificationsSummary, getKey, (summary, type) =>
  summary?.find(e => e?.type_prefix === type)
);

export const selectSummaryTypeTotals = (state, type) => selectSummaryTypeData(state, type)?.total;
export const selectSummaryTypeUnreadCount = (state, type) =>
  selectSummaryTypeData(state, type)?.unread_count;

export const selectTotalUnreadCount = state =>
  selectNotifications(state)?.filter(notification => !notification.read_at)?.length || 0;

const selectHasTotalUnreadNotifications = createSelector(selectNotificationsSummary, summary =>
  summary.some(item => item.unread_count)
);

export const selectShouldShowMarkAllAsRead = (state, type) => {
  const hasUnreadNotifications = selectHasTotalUnreadNotifications(state);
  const unreadCount = selectSummaryTypeUnreadCount(state, type);

  if (type === 'summary') {
    return false;
  } else if (type === 'all') {
    return hasUnreadNotifications;
  } else {
    return !!unreadCount;
  }
};

export const selectPaging = () =>
  createSelector([selectNotificationReducer], notifs => {
    return {
      last_page: notifs.last_page,
      current_page: notifs.current_page,
      total: notifs.total
    };
  });
