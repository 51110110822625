import { useDispatch } from 'react-redux';
import {
  successHandler,
  errorHandler,
  infoHandler,
  warningHandler
} from 'common/utils/notifications';

const useNotification = type => {
  const dispatch = useDispatch();
  const handler = {
    success: successHandler,
    error: errorHandler,
    info: infoHandler,
    warning: warningHandler
  };

  return options => dispatch(handler[type](options));
};
export default useNotification;
