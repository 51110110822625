import { AccountType } from '@/common/types/account';
import { RootState } from '@/store';

const selectAccountReducer = (state: RootState) => state.account;

export const selectAccount = (state: RootState): AccountType => selectAccountReducer(state);

export const selectAccountId = (state: RootState) => selectAccount(state).id;

export const selectAccountExternalEmail = (state: RootState) =>
  selectAccount(state)?.external_email;

export const selectAccountIsOceanAdmin = (state: RootState) => selectAccount(state).is_ocean_admin;
