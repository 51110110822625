import { createAsyncThunk } from '@reduxjs/toolkit';
import { put, get } from 'utils/api';

export const updateCrewSecurity = createAsyncThunk(
  'UPDATE_CREW_SECURITY',
  async ({ id, ...params }, { rejectWithValue, dispatch }) => {
    try {
      const res = await put(`/crew/${id}/security`, params);

      dispatch(getCrewSecurity({ id }));

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getCrewSecurity = createAsyncThunk(
  'GET_CREW_SECURITY',
  async ({ id, ...params }, { rejectWithValue }) => {
    try {
      const res = await get(`/parties/${id}/sensitive-info`, params);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
