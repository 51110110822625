import { useSelector } from 'react-redux';

import WidgetDefaultCard from 'views/dashboard/components/WidgetDefaultCard';
import paths from 'routing/routes/_paths';
import PropTypes from 'prop-types';

import { selectWidgetStateData } from 'store/dashboards/selectors';

const InvoicesCardWidget = ({ id }) => {
  const data = useSelector(state => selectWidgetStateData(state, id));

  return (
    <WidgetDefaultCard id={id} count={data.invoice_count} linkTo={paths.accounting_invoices} />
  );
};

InvoicesCardWidget.propTypes = {
  id: PropTypes.number
};

export default InvoicesCardWidget;
