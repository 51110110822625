import { css } from '@emotion/react';
import { string, func, bool, oneOf } from 'prop-types';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import { hyphenToCamelCase } from 'common/utils/texts';

const OUTER_CIRCLE_WIDTH = 8;

const Bullet = ({ color, size = 'md', className, onClick = () => {}, showRipple = false }) => {
  const camelCaseColor = hyphenToCamelCase(color);
  const hashColor = variables[camelCaseColor];

  return (
    <div
      className={`d-flex align-items-center justify-content-center position-relative ${
        className || ''
      }`}
      onClick={onClick}
    >
      {showRipple && (
        <div
          css={css`
            position: absolute;
            content: '';
            background-color: ${hashColor};
            border-radius: 50%;
            width: calc(100% + ${OUTER_CIRCLE_WIDTH}px); // Bigger than inner circle
            height: calc(100% + ${OUTER_CIRCLE_WIDTH}px); // Bigger than inner circle
            opacity: 0.15;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          `}
        />
      )}
      <div className={`circle bg-${color} size-${size}`} />
    </div>
  );
};

export default Bullet;

Bullet.propTypes = {
  color: string.isRequired,
  className: string,
  onClick: func,
  showRipple: bool,
  size: oneOf(['sm', 'md'])
};
