import { addCrewSkillType, removeCrewSkillType } from './actions';
import TYPES from './types';

import _get from 'lodash/get';

const INITIAL_STATE = {
  isLoading: false,
  timelineData: [],
  vesselsData: [],
  totalWatchkeepingData: [],
  totalCompanyServiceData: [],
  totalExperienceData: [],
  totalServiceInRankData: [],
  skillTypes: []
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.GET_CREW_RANK_TIMELINE.START:
      return {
        ...state,
        isLoading: true,
        timelineData: []
      };

    case TYPES.GET_CREW_RANK_TIMELINE.ERROR:
      return {
        ...state,
        isLoading: false,
        timelineData: []
      };

    case TYPES.GET_CREW_RANK_TIMELINE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        timelineData: _get(payload, 'rank_timeline') || [],
        vesselsData: _get(payload, 'vessel_experience') || [],
        totalWatchkeepingData: _get(payload, 'total_watchkeeping_service') || [],
        totalCompanyServiceData: _get(payload, 'total_company_service') || [],
        totalExperienceData: _get(payload, 'total_sea_service') || [],
        totalServiceInRankData: _get(payload, 'total_rank_service') || [],
        skillTypes: _get(payload, 'skills', [])
      };

    case `${addCrewSkillType.fulfilled}`:
      return {
        ...state,
        skillTypes: [...state.skillTypes, payload]
      };

    case `${removeCrewSkillType.fulfilled}`:
      return {
        ...state,
        skillTypes: state.skillTypes.filter(skillType => skillType.id !== payload.id)
      };

    default:
      return state;
  }
};

export default reducer;
