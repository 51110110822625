import React from 'react';
import { NumericFormat } from 'react-number-format';

const ToolsNumberInput = ({
  label,
  value,
  name,
  onChange,
  placeholder = 'Value',
  maxLength,
  decimalScale,
  onBlur,
  disabled,
  ...rest
}) => {
  return (
    <div className="tools-number-input">
      {label ? <div className="fs-10 mb-1">{label}</div> : null}
      <NumericFormat
        className="form-control form-field"
        value={value || ''}
        name={name}
        onChange={onChange}
        onBlur={onBlur && onBlur}
        placeholder={placeholder ? placeholder : ''}
        maxLength={maxLength}
        disabled={disabled || false}
        thousandSeparator={','}
        decimalSeparator={'.'}
        allowLeadingZeros={false}
        decimalScale={decimalScale !== undefined ? decimalScale : 2}
        {...rest}
      />
    </div>
  );
};

export default ToolsNumberInput;
