import React, { useCallback, useEffect, useState } from 'react';
import TableContainer from './TableContainer';
import { useSelector } from 'react-redux';
import { useUpdateEffect } from 'utils/hooks';
import { columns } from './config';

const TceTable = ({ tableFilters, fetchTceResults, id, view, name, vesselId, averages = null }) => {
  const tceResultsAverages = useSelector(state => state.vessels.tceResultsAverages);

  const [table, setTable] = useState({
    columns,
    state: { data: [], sorting: {}, paging: {}, totals: {} }
  });

  const fetchTceResultsData = useCallback(async () => {
    try {
      if (vesselId) {
        const res = await fetchTceResults({ vessel_id: vesselId });
        setTable(prev => ({ ...prev, state: { ...prev.state, data: res } }));
      }
    } catch (error) {
      console.error(error);
    }
  }, [fetchTceResults, vesselId]);

  useUpdateEffect(() => {
    fetchTceResultsData();
  }, [tableFilters.view, tableFilters.marketIndex, tableFilters.dates, tableFilters.vessel]);

  useEffect(() => {
    if (!table.state.data?.length) {
      fetchTceResultsData();
    }
  }, [vesselId, fetchTceResultsData, table.state.data?.length]);

  return (
    <div className="position-relative tce-result-table" id={id}>
      <>
        <TableContainer
          tableFilters={tableFilters}
          table={table}
          view={view}
          tceResultsAverages={averages ? averages : tceResultsAverages}
          showEdit={true}
          id={id}
          name={name}
        />
      </>
    </div>
  );
};

export default TceTable;
