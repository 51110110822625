import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';

const Voyages = React.lazy(() => retry(() => import('@/views/voyages/index.jsx')));

const voyages = [
  {
    path: [
      `${paths.voyages}`,
      `${paths.voyages}/:vesselId`,
      `${paths.voyages}/:vesselId/:tab(itinerary)`,
      `${paths.voyages}/:vesselId/:tab(charter-parties)`,
      `${paths.voyages}/:vesselId/:tab(charter-parties)/:mode(edit|create)/:id?`,
      `${paths.voyages}/:vesselId/:tab(hires)`
    ],
    component: Voyages,
    app: true,
    type: 'private',
    exact: true,
    permissions: {
      0: [permissions.office.voyages.itinerary.view],
      1: [permissions.office.voyages.itinerary.view],
      2: [permissions.office.voyages.itinerary.view],
      3: [permissions.office.voyages.charter_parties.view],
      4: [permissions.office.voyages.charter_parties.edit],
      5: [permissions.office.voyages.charter_parties.hires]
    },
    documentTitle: 'Itinerary'
  }
];

export default voyages;
