import TYPES from './types';
import FAVORITE_TYPES from 'store/favorites/types';
import { LOCATION_CHANGE } from 'connected-react-router';

import paths from 'routing/routes/_paths';

const initialFormState = {
  vessels: null,
  estimations: null,
  crew: null,
  fields: []
};

export const INITIAL_STATE = {
  fields: {
    // Select options
    estimations: [],
    vessels: [],
    crew: []
  },
  isComparing: false,
  data: null,
  active: null,
  type: '',
  formIsLoading: false,
  form: { ...initialFormState }, // Form values
  saved: {
    isLoading: false,
    data: [],
    paging: {
      total: 0,
      per_page: 20,
      current_page: 1,
      last_page: 1
    }
  }
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_COMPARISON_FIELDS.SUCCESS:
      return {
        ...state,
        fields: {
          ...state.fields,
          [payload.params.type]: payload.data
        }
      };

    case TYPES.COMPARE_FIELDS.START:
      return {
        ...state,
        data: null,
        form: { ...state.form },
        isComparing: true
      };

    case TYPES.INIT_FORM:
      return {
        ...state,
        form: {
          type: payload.type,
          vessels: payload.vessels,
          estimations: payload.estimations,
          crew: payload.crew,
          fields: payload.fields
        }
      };

    case TYPES.COMPARE_FIELDS.SUCCESS:
      return {
        ...state,
        data: payload.data,
        isComparing: false
      };

    case TYPES.COMPARE_FIELDS.ERROR:
      return {
        ...state,
        isComparing: false
      };

    case TYPES.GET_SAVED_COMPARISONS.START:
      return {
        ...state,
        saved: {
          ...state.saved,
          isLoading: true,
          paging: {
            ...state.saved.paging,
            current_page: payload.params.current_page
          }
        }
      };

    case TYPES.GET_SAVED_COMPARISONS.SUCCESS:
      const { meta, data } = payload;

      return {
        ...state,
        saved: {
          ...state.saved,
          isLoading: false,
          data: state.saved.paging.current_page === 1 ? data : [...state.saved.data, ...data],
          paging: meta
        }
      };

    case TYPES.GET_SAVED_COMPARISONS.ERROR:
      return {
        ...state,
        saved: {
          ...state.saved,
          isLoading: false
        }
      };

    case TYPES.GET_SAVED_COMPARISON.START:
      return {
        ...state,
        active: null
      };

    case TYPES.GET_SAVED_COMPARISON.SUCCESS:
      return {
        ...state,
        active: payload
      };

    case TYPES.GET_SAVED_COMPARISON.ERROR:
      return {
        ...state,
        active: null
      };

    case TYPES.CLEAR_FILTERS:
      return {
        ...state,
        data: null,
        form: { ...initialFormState }
      };

    case TYPES.CLEAR_ACTIVE_SAVED:
      return {
        ...state,
        active: null
      };

    case TYPES.SET_FORM_FIELD_VALUE:
      return {
        ...state,
        form: {
          ...state.form,
          [payload.field]: payload.value
        }
      };

    case TYPES.GET_INITIAL_FORM_VALUES.SUCCESS:
      return {
        ...state,
        formIsLoading: false,
        form: window.location.search.includes('?')
          ? {
              type: payload.type,
              vessels: payload.vessels,
              estimations: payload.estimations,
              crew: payload.crew,
              fields: payload.fields
            }
          : { ...initialFormState }
      };

    case TYPES.GET_INITIAL_FORM_VALUES.START:
      return {
        ...state,
        formIsLoading: true
      };

    case TYPES.GET_INITIAL_FORM_VALUES.ERROR:
      return {
        ...state,
        formIsLoading: true
      };

    case LOCATION_CHANGE:
      if (payload.location.pathname.startsWith(paths.comparison)) {
        const parts = payload.location.pathname.split('/');
        const type = parts[parts.length - 1];

        return {
          ...state,
          type,
          active: payload.location.search.indexOf('searchId') === -1 ? null : state.active,
          data: !payload.location.search
            ? null
            : payload.location.search && payload.location.search.indexOf('fields') === -1
            ? null
            : state.data,
          isComparing: !payload.location.search ? false : state.isComparing,
          form:
            payload.location.search.indexOf('searchId') === -1
              ? { ...initialFormState }
              : state.form
        };
      }
      return state;

    case FAVORITE_TYPES.TOGGLE_FAVORITE.SUCCESS:
      if (state.active && state.active.item && state.active.item.id === payload.favourite_id) {
        return {
          ...state,
          active: {
            ...state.active,
            item: {
              ...state.active.item,
              favourites_count: state.active.item.favourites_count === 0 ? 1 : 0
            }
          }
        };
      }

      return state;

    default:
      return state;
  }
};

export default reducer;
