import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_CREW_TRAINING_TYPE'),
  ...asyncTypes('CREATE_CREW_TRAINING_TYPE'),
  ...asyncTypes('REORDER_CREW_TRAINING_TYPE'),
  ...asyncTypes('UPDATE_CREW_TRAINING_TYPE'),
  ...asyncTypes('UPDATE_CREW_TRAINING_TYPE_SCHEDULE'),
  ...asyncTypes('ARCHIVE_CREW_TRAINING_TYPE'),
  ...asyncTypes('UNARCHIVE_CREW_TRAINING_TYPE'),
  STOP_AUTO_SAVING: 'STOP_AUTO_SAVING',
  RESET_ROW_MONTHS: 'RESET_ROW_MONTHS',
  RESET_CREW_TABLE_DATA: 'RESET_CREW_TABLE_DATA'
};

export default TYPES;
