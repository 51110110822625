import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';

import { selectJobField } from '@/common/components/jobs/_base/store/selectors.js';

const Jobs = React.lazy(() => retry(() => import('@/views/jobs/index.jsx')));

const baseJobsRoute = `${paths.jobs}/:type(regular|flag-extension-dispensation|orca-jobs)`;

const jobs = [
  {
    path: [`${baseJobsRoute}`, `${baseJobsRoute}/:id`, paths.findings, `${paths.findings}/:id`],
    component: Jobs,
    app: true,
    type: 'private',
    exact: true,
    permissions: {
      0: [permissions.office.jobs.list.view],
      1: [permissions.office.jobs.view, permissions.office.jobs.edit],
      2: [permissions.office.jobs.list.findings.view],
      3: [permissions.office.jobs.list.findings.view]
    },
    documentTitle: (state, { location }) =>
      location.pathname.startsWith(paths.findings)
        ? 'Findings'
        : location.pathname === `${paths.regular_jobs}` ||
          location.pathname === `${paths.flag_extensions_dispensations_jobs}` ||
          location.pathname === `${paths.orca_jobs}`
        ? 'Jobs'
        : selectJobField(state, 'document_title')
  }
];

export default jobs;
