import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_COMPARISON_FIELDS'),
  ...asyncTypes('COMPARE_FIELDS'),
  ...asyncTypes('EXPORT_COMPARISON'),
  ...asyncTypes('SAVE_COMPARISON'),
  ...asyncTypes('UPDATE_SAVED_COMPARISON'),
  ...asyncTypes('GET_SAVED_COMPARISONS'),
  ...asyncTypes('GET_SAVED_COMPARISON'),
  ...asyncTypes('GET_INITIAL_FORM_VALUES'),
  CLEAR_FILTERS: 'CLEAR_FILTERS',
  CLEAR_ACTIVE_SAVED: 'CLEAR_ACTIVE_SAVED',
  RESET_FORM_CHANGES: 'RESET_FORM_CHANGES',
  INIT_FORM: 'INIT_FORM',
  COLLECT_FORM_VALUES: 'COLLECT_FORM_VALUES',
  SET_FORM_FIELD_VALUE: 'SET_FORM_FIELD_VALUE'
};

export default TYPES;
