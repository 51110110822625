import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  getRootItemsIDs,
  getSubItems,
  normalizeTreeStructure
} from 'common/components/tree-layout/utils/helpers';
import { FieldTypesType, GroupType } from '@/common/types/report-bulder.ts';

import { removeGroupRecursively, flattenGroupsWithAccounts } from './helpers.ts';
import { getReportSetupAction } from './actions.ts';

export type GroupsType = {
  rootItems: number[];
  subItems: { [key: string]: number[] };
  data: { [key: string]: GroupType };
};

export interface ReportSetupState {
  isFetching: boolean;
  isSubmitting: boolean;
  fields: FieldTypesType[];
  groups: GroupsType;
  id: number | null;
  name: string | null;
}

type GetReportSetupActionParams = {
  id: number;
  name: string;
  field_types: FieldTypesType[];
  groups: GroupType[];
};

const INITIAL_GROUP_DATA = {
  accounts: [],
  depth: null,
  created_at: null,
  id: null,
  name: null,
  parent_id: null,
  sort_index: null
};

const INITIAL_STATE: ReportSetupState = {
  isFetching: false,
  isSubmitting: false,
  fields: [],
  groups: {
    rootItems: [],
    subItems: {},
    data: {}
  },
  id: null,
  name: null
};

type UpdatableKeys = 'name';

const slice = createSlice({
  name: 'report-setup',
  initialState: INITIAL_STATE,
  reducers: {
    onAdd: (state, { payload }) => {
      if (payload?.parent_id) {
        state.groups.subItems = {
          ...state.groups.subItems,
          [payload.parent_id]: [...(state.groups.subItems?.[payload.parent_id] || []), payload.id]
        };
      } else {
        state.groups.rootItems = [...state.groups.rootItems, payload.id];
      }

      state.groups.data = payload?.parent_id
        ? {
            ...state.groups.data,
            [payload.id]: { ...INITIAL_GROUP_DATA, isNew: true, ...payload },
            [payload.parent_id]: {
              ...state.groups.data[payload.parent_id],
              itemsType: payload.type
            }
          }
        : {
            ...state.groups.data,
            [payload.id]: { ...INITIAL_GROUP_DATA, isNew: true, ...payload }
          };

      return state;
    },
    onSubmitting: (state, { payload }) => {
      state.isSubmitting = payload;

      return state;
    },
    onRemove: (state, { payload }) => {
      removeGroupRecursively(state, payload.id);

      return state;
    },
    onGroupFieldUpdate: (state, { payload }) => {
      const { id, key, value } = payload;

      state.groups.data[id] = { ...state.groups.data[id], [key]: value };

      return state;
    },
    onFieldUpdate: (state, { payload }: PayloadAction<{ key: UpdatableKeys; value: string }>) => {
      const { key, value } = payload;

      state[key] = value;

      return state;
    },
    onFieldSelectUpdate: (state, { payload }) => {
      const { id, value } = payload;

      state.groups.data[id] = { ...state.groups.data[id], ...value };

      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(
        `${getReportSetupAction.fulfilled}`,
        (state, { payload }: PayloadAction<GetReportSetupActionParams>) => {
          state.isFetching = false;
          const flattenGroups = flattenGroupsWithAccounts(payload.groups);

          state.groups.rootItems = getRootItemsIDs(flattenGroups);
          state.groups.data = normalizeTreeStructure(flattenGroups);
          state.groups.subItems = getSubItems(flattenGroups);
          state.name = payload.name;
          state.id = payload.id;
          state.fields = payload.field_types;

          return state;
        }
      )
      .addCase(getReportSetupAction.pending, state => {
        state.isFetching = true;

        return state;
      })
      .addCase(getReportSetupAction.rejected, state => {
        state.isFetching = false;

        return state;
      })
      .addCase(LOCATION_CHANGE, () => {
        return INITIAL_STATE;
      });
  }
});

export default slice.reducer;

export const {
  onRemove,
  onAdd,
  onGroupFieldUpdate,
  onFieldSelectUpdate,
  onFieldUpdate,
  onSubmitting
} = slice.actions;
