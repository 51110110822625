import React from 'react';
import { Button } from 'reactstrap';

import add from 'tools/assets/svg/add.svg';
import remove from 'tools/assets/svg/remove.svg';
import check from 'tools/assets/svg/check.svg';
import equals from 'tools/assets/svg/equals.svg';
import x from 'tools/assets/svg/x_dark.svg';

const ButtonIcon = ({ onClick, type, text, className, disabled, ...rest }) => {
  const render = () => {
    switch (type) {
      case 'add':
        return <img src={add} width={10} height={10} alt="add icon" />;
      case 'remove':
        return <img src={remove} width={10} height={10} alt="remove icon" />;
      case 'done':
        return <img src={check} width={12} height={10} alt="check icon" />;
      case 'equals':
        return <img src={equals} width={10} height={10} alt="equals icon" />;
      case 'x':
        return <img src={x} width={10} height={10} alt="x icon" />;
      default:
        return null;
    }
  };

  return (
    <Button
      type="button"
      color="link"
      className={`p-0 toolbar-button toolbar-button--${type} ${className || ''}`}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      <span className="toolbar-button--holder">{render()}</span>
      {text ? <span className="ps-1 toolbar-button--text">{text}</span> : null}
    </Button>
  );
};

export default ButtonIcon;
