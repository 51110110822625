import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';

import SvgRender from 'common/components/general/SvgRender';
import eventIcon from 'common/assets/svg/common/events.svg';

import { selectActiveRequisitionEvents } from 'common/components/purchasing/requisition/store/selectors';

import TextWithTooltip from 'common/components/general/TextWithTooltip';
import paths from 'routing/routes/_paths';

const LinkedWithEventButton = () => {
  const requisitionEvents = useSelector(selectActiveRequisitionEvents);

  return (
    <Button
      color="white"
      className="d-flex align-items-center text-primary fs-12 fw-medium px-1 py-0 cms-4 mt-auto text-nowrap max-width-255 height-18"
      onClick={() => window.open(`${paths.events}/${requisitionEvents?.[0]?.id}`)}
    >
      <SvgRender className="me-1" src={eventIcon} style={{ width: 12, height: 12 }} />
      Linked with:&nbsp;
      <TextWithTooltip
        className="text-violet h-auto"
        innerTooltipClassName="min-width-fit max-width-fit"
      >
        {requisitionEvents?.map((e, i) => (
          <span className="fw-bold">{`${e?.name}${
            i === requisitionEvents?.length - 1 ? '' : ', '
          }`}</span>
        ))}
      </TextWithTooltip>
    </Button>
  );
};

export default LinkedWithEventButton;
