import TYPES from './types';
import { LOCATION_CHANGE } from 'connected-react-router';
import paths from 'routing/routes/_paths';

import {
  getRootItemsIDs,
  getSubItems,
  normalizeTreeStructure,
  getTreeOfSearchedItems,
  getAscendanceTree
} from 'common/components/tree-layout/utils/helpers';
import { parseQueryParams } from 'common/utils/urls';

import _mapValues from 'lodash/mapValues';

const INITIAL_STATE = {
  form: {
    isOpen: false,
    type: '',
    accountID: null,
    parentAccountID: null
  },

  search: '',

  isLoading: false,

  accounts: {},
  rootAccounts: [],
  subAccounts: {},
  accountsInSeach: {},
  accountsLevel: {},

  deleteAccountModal: { isOpen: false, accountID: null }
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.GET_MGA_ACCOUNTS.START:
      return { ...state, isLoading: true };

    case TYPES.GET_MGA_ACCOUNTS.SUCCESS:
      const subAccounts = getSubItems(payload.data);

      if (payload?.params?.search) {
        const accountsInSearch = getTreeOfSearchedItems(
          state.accounts,
          state.subAccounts,
          payload.data
        );

        return {
          ...state,
          isLoading: false,
          accountsInSearch
        };
      }

      const accounts = normalizeTreeStructure(
        payload.data.map((a, i) => ({ ...a, sort_index: i }))
      );

      return {
        ...state,
        isLoading: false,
        rootAccounts: getRootItemsIDs(payload.data),
        subAccounts,
        accounts,
        accountsInSearch: {},
        accountsLevel: _mapValues(accounts, account =>
          account.parent_id ? getAscendanceTree(account.id, accounts)?.length : 1
        )
      };

    case TYPES.GET_MGA_ACCOUNTS.ERROR:
      return { ...state, isLoading: false, rootAccounts: [], subAccounts: {} };

    case TYPES.EDIT_MGA_ACCOUNT.SUCCESS:
      return {
        ...state,
        accounts: { ...state.accounts, [payload.id]: { ...state.accounts[payload.id], ...payload } }
      };

    case TYPES.SET_MGA_ACCOUNT_PREVENTION_MODAL:
      return {
        ...state,
        deleteAccountModal: { isOpen: payload.isOpen, accountID: payload.accountID }
      };

    case TYPES.SET_MGA_ACCOUNT_FORM:
      return {
        ...state,
        form: { ...payload }
      };

    case TYPES.EDIT_MGA_PARENT_ACCOUNT.SUCCESS:
      return {
        ...state,
        accounts: _mapValues(state.accounts, account => ({
          ...account,
          is_parent_of_crew_accounts: account.id === payload.params.account_id ? true : false
        }))
      };

    case TYPES.EDIT_MGA_MASTER_ACCOUNT.SUCCESS:
      return {
        ...state,
        accounts: _mapValues(state.accounts, account => ({
          ...account,
          is_master: account.id === payload.params.account_id ? true : false
        }))
      };

    case LOCATION_CHANGE:
      if (!payload.location.pathname.startsWith(paths.mga_coa_settings)) {
        return {
          ...state,
          search: '',
          form: {
            isOpen: false,
            type: '',
            active: null
          }
        };
      } else {
        return {
          ...state,
          search: payload.location.search?.includes('searchText')
            ? parseQueryParams(payload.location.search)?.searchText
            : ''
        };
      }

    default:
      return state;
  }
};

export default reducer;
