import { Col, Row } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

const WidgetDefaultListItem = ({ item, icon, color, navigateToPath, RightComponent }) => {
  return (
    <div
      className="widget-default-list-item cursor-pointer"
      onClick={() => window.open(navigateToPath(item))}
    >
      <div className="d-flex align-items-center w-100p">
        <div
          className={`widget-default-list-item__icon widget-default-list-item__icon--${color} cpx-12 cpy-10`}
        >
          <SvgRender src={icon} style={{ width: 19, height: 19 }} />
        </div>

        <div className="ms-2 overflow-hidden fs-12 w-100p">
          <Row className="d-flex align-items-center justify-content-between flex-nowrap">
            {item.nameComponent ? (
              item.nameComponent
            ) : (
              <Col className="fw-bold text-primary fs-12 overflow-hidden">
                <TextWithTooltip>{item.name}</TextWithTooltip>
              </Col>
            )}
            {RightComponent ? (
              <Col xs="auto">
                <RightComponent item={item} />
              </Col>
            ) : null}
          </Row>
          <Row className="text-primary">
            <Col xs={12}>
              <TextWithTooltip>{item.description}</TextWithTooltip>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default WidgetDefaultListItem;
