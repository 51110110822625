import { configureStore } from '@reduxjs/toolkit';

import notificationsMiddleware from './notificationsMiddleware';
import resetDynamicTablesMiddleware from './resetDynamicTablesMiddleware';
import authMiddleware from './authMiddleware';
import profileDataMiddleware from './profileDataMiddleware';
import rootReducer from './rootReducer';
import { loadState } from 'store/_helpers';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { history } from './rootReducer';
import { routerMiddleware } from 'connected-react-router';
import logger from 'redux-logger';

const preloadedState = loadState();

const middlewares = [
  notificationsMiddleware,
  resetDynamicTablesMiddleware,
  authMiddleware,
  profileDataMiddleware,
  routerMiddleware(history)
];

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: []
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const configuredStore = (initialState?: object) => {
  return configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => {
      const middleware =
        import.meta.env.MODE === `test`
          ? getDefaultMiddleware({})
          : getDefaultMiddleware({
              serializableCheck: false,
              immutableCheck: false
            }).concat(middlewares);

      if (import.meta.env.MODE !== `test` && import.meta.env.VITE_DEV_REDUX_LOGER == 'true') {
        middleware.push(logger);
      }

      return middleware;
    },
    devTools: import.meta.env.MODE === 'development',
    preloadedState: initialState || preloadedState
  });
};

const store = configuredStore();

export const persistor = import.meta.env.MODE === `test` ? {} : persistStore(store);

// Get the type of our store variable
export type AppStore = typeof store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];

export default store;
