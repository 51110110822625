import TYPES from './types';

const INITIAL_STATE = {
  isOpen: false,
  info: {}
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.TOGGLE_MAP_POPUP:
      return {
        ...state,
        isOpen: !state.isOpen
      };

    case TYPES.SET_MAP_POPUP_INFO:
      return {
        ...state,
        info: payload
      };

    case TYPES.SET_IS_MAP_POPUP_OPEN:
      return {
        ...state,
        isOpen: payload
      };

    default:
      return state;
  }
};

export default reducer;
