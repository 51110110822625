import { createSlice } from '@reduxjs/toolkit';
import { searchBeaconPosts } from 'store/beacon/sidebar/actions';
import { setActivePostIdAction } from 'common/components/beacon/store/slice';
import { logoutCleanup } from 'store/auth/slice';

const INITIAL_STATE = {
  officeView: true,
  drawerIsOpen: false,
  activePostId: null,
  hasSearch: false,
  searchText: '',
  isSearching: false,
  searchResults: []
};

const slice = createSlice({
  name: 'beacon',
  initialState: INITIAL_STATE,
  reducers: {
    toggleBeaconDrawerAction: (state, { payload }) => {
      state.drawerIsOpen = payload;

      if (!payload) {
        state.hasSearch = false;
        state.isSearching = false;
        state.searchText = '';
        state.searchResults = [];
      }

      return state;
    },
    resetBeaconDrawerSearchAction: state => {
      state.hasSearch = false;
      state.isSearching = false;
      state.searchText = '';
      state.searchResults = [];

      return state;
    }
  },
  extraReducers: {
    [searchBeaconPosts.pending]: (state, { meta }) => {
      state.hasSearch = !!meta.arg.search;
      state.searchText = meta.arg.search;
      state.isSearching = true;

      return state;
    },
    [searchBeaconPosts.fulfilled]: (state, { payload }) => {
      state.searchResults = payload;
      state.isSearching = false;

      return state;
    },
    [searchBeaconPosts.rejected]: state => {
      state.searchResults = [];
      state.isSearching = false;

      return state;
    },
    [setActivePostIdAction]: (state, { payload }) => {
      if (state.hasSearch && payload) {
        state.hasSearch = false;
        state.searchText = '';
        state.isSearching = false;
      }

      return state;
    },
    [logoutCleanup]: state => {
      state.hasSearch = false;
      state.searchText = '';
      state.isSearching = false;
      state.drawerIsOpen = false;

      return state;
    }
  }
});

export const { toggleBeaconDrawerAction, resetBeaconDrawerSearchAction } = slice.actions;

export default slice.reducer;
