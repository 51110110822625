import { useSelector } from 'react-redux';
import { selectWidgetTypeLabel } from 'store/dashboards/selectors';

import widgetsDetails from 'views/dashboard/widgets/widgets-details';

const LayoutCardWidget = ({ children, widgetId }) => {
  const label = useSelector(state => selectWidgetTypeLabel(state, widgetId));

  return (
    <div className={`widget-layout-card size-${widgetsDetails[label]?.size || 'sm'}`}>
      {children}
    </div>
  );
};

export default LayoutCardWidget;
