import { useQuery } from '@tanstack/react-query';
import { getExternalEmail } from './api';
import { EXTERNAL_EMAIL } from './query-keys';

export const useGetExternalEmail = ({ enabled }: { enabled: boolean }) => {
  return useQuery({
    queryKey: [EXTERNAL_EMAIL],
    queryFn: () => getExternalEmail(),
    enabled: enabled
  });
};
