import { useRef, useState } from 'react';

import TagsInput from 'react-tagsinput';
import Autosuggest from 'react-autosuggest';
import FormGroupWrap from '@/ts-common/components/form/helpers/FormGroupWrap';
import useOnClickOutside from 'common/utils/hooks/useOnClickOutside';

import { getAsyncOptions } from 'utils/helpers';
import 'react-tagsinput/react-tagsinput.css';
import _debounce from 'lodash/debounce';
import { NOT_ONLY_WHITESPACE_REGEX } from 'common/utils/form/validation';

const Tags = ({
  tags,
  onChange,
  label,
  className,
  disabled,
  type,
  listing = undefined,
  options = undefined,
  suggestionsDisabled = false,
  renderCustomTag = undefined,
  invisible = false,
  placeholder = 'Add a tag',
  validationRegex = NOT_ONLY_WHITESPACE_REGEX
}) => {
  const [suggestions, setSuggestions] = useState([]);

  const loadSuggestions = async search => {
    if (options) return;

    if (listing) {
      const res = await getAsyncOptions(search, listing || 'tags', {
        taggable_type: type,
        exclude_tags: tags
      });

      setSuggestions(res.map(({ text }) => ({ text })));
    } else {
      return setSuggestions([]);
    }
  };

  const debouncedLoadSuggestions = _debounce(loadSuggestions, 500);

  const tagRef = useRef(null);
  const layoutRef = useRef(null);

  const closeOnClickOutside = () => {
    if (tagRef?.current) {
      tagRef.current.blur();
      tagRef.current.clearInput();
    }
  };
  useOnClickOutside(layoutRef, closeOnClickOutside);

  const renderLayout = (tagComponents, inputComponent) => {
    return (
      <div className="react-tagsinput--wrapper">
        {tagComponents}
        {inputComponent}
      </div>
    );
  };

  const autosuggestRenderInput = ({ addTag, ...props }) => {
    const handleOnChange = (e, { newValue, method }) => {
      if (method === 'enter') {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    // const inputValue = (props.value && props.value.trim().toLowerCase()) || '';
    // const inputLength = inputValue.length;

    const onSuggestionsFetchRequested = ({ value }) => {
      debouncedLoadSuggestions(value);
    };

    return (
      <Autosuggest
        ref={props.ref}
        suggestions={options ? options : suggestions}
        shouldRenderSuggestions={value => value && value.trim().length > 0}
        getSuggestionValue={suggestion => suggestion.text}
        renderSuggestion={suggestion => <div>{suggestion.text}</div>}
        inputProps={{
          ...props,
          onChange: handleOnChange,
          onBlur: e => {
            e.preventDefault();
          }
        }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.text);
        }}
        onSuggestionsClearRequested={() => {
          setSuggestions([]);
        }}
        onSuggestionsFetchRequested={
          suggestionsDisabled && typeof onSuggestionsFetchRequested !== 'function'
            ? null
            : onSuggestionsFetchRequested
        }
      />
    );
  };

  const renderTag = props => {
    let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;

    if (renderCustomTag) {
      return renderCustomTag(props, key);
    }

    return (
      <span key={key} {...other}>
        {getTagDisplayValue(tag)}

        {!disabled && (
          <span
            className={classNameRemove}
            onClick={e => {
              e.preventDefault();
              onRemove(key);
            }}
          >
            ×
          </span>
        )}
      </span>
    );
  };

  return (
    <FormGroupWrap
      className={`tags-group ${disabled ? 'disabled' : ''} ${invisible ? 'invisible-input' : ''} ${
        className || ''
      }`}
      label={label}
    >
      <div
        ref={layoutRef}
        onClick={() => (tagRef?.current && !disabled ? tagRef.current.focus() : '')}
      >
        <TagsInput
          value={tags}
          onChange={onChange}
          renderInput={autosuggestRenderInput}
          renderLayout={renderLayout}
          renderTag={renderTag}
          disabled={disabled}
          ref={tagRef}
          validationRegex={validationRegex}
          onlyUnique
          inputProps={{
            placeholder
          }}
        />
      </div>
    </FormGroupWrap>
  );
};

export default Tags;
