import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { useFormState } from 'utils/hooks';
import { Button } from 'reactstrap';
import { getPartyDisplayName } from 'common/utils/labels';
import { selectUserDashboards } from 'store/dashboards/selectors';

import { FormBody } from 'common/components/drawer';
import { FormFooter } from 'common/components/drawer';

import Input from 'common/components/form/inputs/Input';
import Textarea from 'common/components/form/inputs/Textarea';
import Select from 'common/components/form/inputs/Select';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';
import PreventActionButton from 'common/components/buttons/PreventActionButton';

import deleteIcon from 'common/assets/svg/actions/delete.svg';
import PropTypes from 'prop-types';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import { FormState } from 'common/entities/form/FormTypes';

const DashboardForm = ({
  formState,
  collectValues,
  hasErrors,
  onSubmit,
  onDelete,
  isSubmitting,
  isDeleting,
  isCreateMode,
  onClose
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dashboards = useSelector(selectUserDashboards);
  const { fields, changeField, selectField } = useFormState(formState);

  const handleSubmitForm = async () => {
    const values = collectValues();
    if (!values) return;

    const { start_from, shared_parties, shared_departments, ...rest } = values;

    const params = {
      ...rest,
      ...(start_from ? { dashboard_id: start_from } : {}),
      shares: {
        parties: shared_parties.map(s => s.id),
        departments: shared_departments
      }
    };

    onSubmit(params);
  };

  return (
    <>
      <FormBody>
        <Input
          label="Name"
          name="name"
          placeholder="Add name"
          onChange={changeField('title')}
          {...fields.title}
        />
        <Textarea
          label="Description"
          placeholder="Add description"
          name="description"
          onChange={changeField('description')}
          rows={4}
          {...fields.description}
        />

        {isCreateMode && dashboards?.length ? (
          <Select
            options={dashboards.map(d => ({ ...d, value: d.id, label: d.title }))}
            label="START FROM"
            name="start_from"
            placeholder="Start from dashboard"
            onChange={selectField('start_from')}
            {...fields.start_from}
          />
        ) : null}

        <AsyncSelector
          label="SHARED WITH"
          onChange={selectField('shared_parties')}
          type="parties"
          placeholder="Select persons"
          getOptionValue={option => option.id}
          getOptionLabel={option =>
            option.type === 'company' ? option.company_name : getPartyDisplayName(option)
          }
          listParams={{ exclude_current_user: true, can_login: true }}
          isMulti
          {...fields.shared_parties}
        />

        <DepartmentSelector
          label="SHARE WITH DEPARTMENTS"
          placeholder={`Select Departments`}
          value={fields.shared_departments.value}
          onChange={selected => selectField('shared_departments')(selected || [])}
          isMulti={true}
        />
      </FormBody>

      <FormFooter>
        {!isCreateMode ? (
          <div className="me-auto">
            <PreventActionButton
              buttonLabel="Delete"
              buttonColor="link"
              buttonClassName="text-red fw-bold p-0"
              onModalAccept={onDelete}
              onButtonClick={() => setShowDeleteModal(true)}
              closeModal={() => setShowDeleteModal(false)}
              isModalOpen={showDeleteModal}
              disabled={isSubmitting || isDeleting}
              buttonIcon={deleteIcon}
              modalProps={{
                header: 'Delete Dashboard',
                body: `Are you sure you want to delete this dashboard?`,
                actionText: 'DELETE'
              }}
            />
          </div>
        ) : null}

        <Button color="cancel" className="px-0 py-1 me-4" onClick={() => onClose()}>
          CANCEL
        </Button>
        <Button
          onClick={handleSubmitForm}
          disabled={isSubmitting || hasErrors}
          color="primary"
          className="px-4"
        >
          SAVE
        </Button>
      </FormFooter>
    </>
  );
};

DashboardForm.propTypes = {
  formState: FormState,
  collectValues: PropTypes.func,
  hasErrors: PropTypes.bool,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isCreateMode: PropTypes.bool,
  onClose: PropTypes.func
};

export default DashboardForm;
