import parseMarketIndexes from 'views/market-indexes/parseData';
import parseCrewRotations from 'views/crew/planning/parser';
import parsePortCargoes from 'views/reports/voyages/report-port-cargoes/parser';
import parseWeatherAnalysis from 'views/reports/performance/weather-analysis/parser';
import { parseMgaInventoryItems } from 'common/components/mga/store/parser';
import { parseVesselCalendarData } from 'views/events/calendar/vessel-calendar/parser';

export const parse = (data, params, table) => {
  switch (table) {
    case 'market-indexes':
      return parseMarketIndexes(data, params);

    case 'crew_planning':
    case 'crew_planning_history':
      return parseCrewRotations(data, params);

    case 'mga_inventory_items_additions':
    case 'mga_inventory_items_removals':
      return parseMgaInventoryItems(data, params);

    case 'reports_port_cagoes':
      return parsePortCargoes(data, params);
    case 'reports_weather_analysis':
      return parseWeatherAnalysis(data, params);

    case 'events_calendar':
      return parseVesselCalendarData(data, params);

    default:
      return data;
  }
};
