import { GroupType } from '@/common/types/report-bulder.ts';
import _map from 'lodash/map';
import _flatMap from 'lodash/flatMap';
import { budgetingReportBuilderEnums } from '@/common/utils/fixed.tsx';
import { AccountingAccount } from '@/common/types/accounting.ts';
import { ReportSetupState } from '@/store/report-setup/slice.ts';

type IdType = number | string;

type FlattenedItem = {
  id: number | string;
  parent_id: number | null;
  depth: number;
  type?: string;
};

// Recursive function to remove the given id and its children
export const removeGroupRecursively = (state: ReportSetupState, id: IdType): void => {
  // If the item has sub-items, remove them recursively
  if (state.groups.subItems[id]) {
    state.groups.subItems[id].forEach((subId: IdType) => {
      removeGroupRecursively(state, subId); // Recursive call for nested sub-items
    });

    // Delete the sub-item entry after all sub-items are removed
    delete state.groups.subItems[id];
  }

  // Remove the ID from the data if it exists
  if (state.groups.data[id]) {
    delete state.groups.data[id];
  }

  // Remove the ID from rootItems if it exists there
  state.groups.rootItems = state.groups.rootItems.filter((rootId: IdType) => rootId !== id);

  // Remove the ID from any parent subItems entries
  Object.keys(state.groups.subItems).forEach(parentId => {
    state.groups.subItems[parentId] = state.groups.subItems[parentId].filter(
      (subId: IdType) => subId !== id
    );

    // If a parent has no more sub-items, remove its entry
    if (state.groups.subItems[parentId].length === 0) {
      delete state.groups.subItems[parentId];
    }

    // Update the parent's itemsType based on remaining sub-items
    if (state.groups.data[parentId]) {
      state.groups.data[parentId] = {
        ...state.groups.data[parentId],
        itemsType: state.groups.subItems[parentId]?.length
          ? state.groups.data[parentId].itemsType
          : null
      };
    }
  });
};

const constructType = (accounts: AccountingAccount[]) => {
  if (accounts.length > 0) {
    if (accounts[0].is_account) {
      return budgetingReportBuilderEnums.ledger;
    } else {
      return budgetingReportBuilderEnums.coa;
    }
  } else {
    return budgetingReportBuilderEnums.group;
  }
};

const constructGroupsWithTypes = (groups: GroupType[]) => {
  return groups.map(group => {
    const itemsType = constructType(group.accounts);

    return { ...group, itemsType };
  });
};

export const flattenGroupsWithAccounts = (groups: GroupType[]): FlattenedItem[] => {
  const groupsWithTypes = constructGroupsWithTypes(groups);

  return _flatMap(groupsWithTypes, group => [
    // Add the group itself
    group,

    // Map each account to include the updated parent_id and depth
    ..._map(group.accounts, account => ({
      ...account,
      id: `account_${account.id}_${group.id}`,
      type: constructType(group.accounts),
      account_id: account.id,
      parent_id: group.id, // Set the parent_id to the group's id
      depth: group.depth + 1 // Increase the depth by 1,
    }))
  ]);
};

type SubItemType = { [key: string]: number[] };
type DataType = { [key: string]: GroupType };
type ResultType = GroupType[];

export const gatherItems = (
  id: IdType,
  subItems: SubItemType,
  data: DataType,
  result: ResultType
) => {
  const item = data[id];

  if (item) {
    result.push(item); // Add the current item to the result array

    // If the item has sub-items, recursively gather them
    const children = subItems[id];
    if (children) {
      children.forEach((childId: IdType) => gatherItems(childId, subItems, data, result));
    }
  }
};
