import TABLE_TYPES from 'common/components/table/store/types';
import { get } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getWagesReport = createAsyncThunk(
  'GET_WAGES_REPORT',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { table, subType, ...rest } = params;

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.START,
        payload: {
          params,
          table
        }
      });

      const res = await get(`/reports/wages/${subType}`, rest);

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: { data: { ...res.data }, table }
      });

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getWagesTotals = createAsyncThunk(
  'GET_WAGES_REPORT_TOTALS',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await get(`reports/wages/totals`, params);

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
