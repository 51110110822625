import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import { useDispatch, useSelector } from 'react-redux';
import { updatePurchasingRequisition } from '../store/actions';
import { selectActiveRequisitionID } from '../store/selectors';

const TopBarTags = ({ tags }) => {
  const [tempTags, setTempTags] = useState(tags);

  const dispatch = useDispatch();

  const requisitonId = useSelector(selectActiveRequisitionID);

  const onChange = async selectedTags => {
    setTempTags(selectedTags);

    try {
      const res = await dispatch(
        updatePurchasingRequisition({ id: requisitonId, tags: selectedTags.map(tag => tag.id) })
      );

      setTempTags(res.tags);
    } catch (error) {
      setTempTags(tags);
      console.error(error);
    }
  };

  return (
    <div className="bg-light-gray border-radius-7 p-1 mt-1">
      <AsyncSelector
        onChange={onChange}
        type="case-tags"
        isMulti
        placeholder="Select tag(s)"
        className="mb-0"
        label="Tags"
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        size="sm"
        value={tempTags}
      />
    </div>
  );
};

TopBarTags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number.isRequired, name: PropTypes.string.isRequired })
  )
};

export default TopBarTags;
