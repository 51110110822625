import TYPES from './types';
import TABLE_TYPES from 'common/components/table/store/types';
import { get, put, post, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { updateTableRow, resetTableRowUpdate } from 'store/tables/lists/actions';
import _sortBy from 'lodash/sortBy';

export const getJobTemplates = params => dispatch => {
  const { table, ...rest } = params;
  dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.START, payload: { params: rest, table } });

  return get(`/job-templates`, rest)
    .then(response => {
      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: { data: response.data, table }
      });
    })
    .catch(error =>
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } })
    );
};

export const getJobTemplate = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.GET_JOB_TEMPLATE.START, payload: { params } });

  return get(`/job-templates/${id}`, rest)
    .then(response => {
      const template = { ...response.data, labels: _sortBy(response.data.labels, ['sort_index']) };

      template.isFleet = params.fleetTypeId === template.type_id;

      dispatch({ type: TYPES.GET_JOB_TEMPLATE.SUCCESS, payload: template });

      return template;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_JOB_TEMPLATE.ERROR, payload: error });
      throw error;
    });
};

export const updateJobTemplate = params => dispatch => {
  const { id, table, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_JOB_TEMPLATE.START, payload: { params } });
  if (table) dispatch(updateTableRow({ data: params, table }));

  return put(`/job-templates/${id}`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.UPDATE_JOB_TEMPLATE.SUCCESS, payload: response.data });
      if (table) dispatch(updateTableRow({ data: response.data, table }));
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_JOB_TEMPLATE.ERROR, payload: { response: error, params } });
      if (table) dispatch(resetTableRowUpdate({ data: params, table }));
      return error;
    });
};

export const createJobTemplate = params => dispatch => {
  dispatch({ type: TYPES.CREATE_JOB_TEMPLATE.START, payload: { params } });

  return post('/job-templates', params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_JOB_TEMPLATE.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_JOB_TEMPLATE.ERROR, payload: error });
      return error;
    });
};

export const archiveJobTemplate = params => dispatch => {
  dispatch({ type: TYPES.ARCHIVE_JOB_TEMPLATE.START, payload: { params } });

  return put(`/job-templates/${params.id}/archive`)
    .then(response => {
      dispatch({ type: TYPES.ARCHIVE_JOB_TEMPLATE.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Job has been archived' }));

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.ARCHIVE_JOB_TEMPLATE.ERROR, payload: error }));
};

export const unarchiveJobTemplate = params => dispatch => {
  dispatch({ type: TYPES.UNARCHIVE_JOB_TEMPLATE.START, payload: { params } });

  return put(`/job-templates/${params.id}/unarchive`)
    .then(response => {
      dispatch({ type: TYPES.UNARCHIVE_JOB_TEMPLATE.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Job has been archived' }));

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.UNARCHIVE_JOB_TEMPLATE.ERROR, payload: error }));
};

export const updateJobTemplateLabels = params => dispatch => {
  const { templated_id, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_JOB_TEMPLATE_LABELS.START, payload: { params } });

  return put(`/job-templates/${templated_id}/labels`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_JOB_TEMPLATE_LABELS.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Label has been saved' }));

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.UPDATE_JOB_TEMPLATE_LABELS.ERROR, payload: error }));
};

export const toggleTemplateLabel = params => dispatch => {
  const { templateId, labelId, ...rest } = params;

  dispatch({ type: TYPES.TOGGLE_TEMPLATE_LABEL.START });
  return post(`/job-templates/${templateId}/labels/${labelId}/toggle`, rest)
    .then(response => {
      dispatch({ type: TYPES.TOGGLE_TEMPLATE_LABEL.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Label has been saved' }));
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.TOGGLE_TEMPLATE_LABEL.ERROR, payload: error });
      throw error;
    });
};

export const deleteJobTemplateLabel = params => dispatch => {
  const { templated_id, id } = params;
  dispatch({ type: TYPES.DELETE_JOB_TEMPLATE_LABEL.START, payload: { params } });

  return deleteRequest(`/job-templates/${templated_id}/labels/${id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_JOB_TEMPLATE_LABEL.SUCCESS, payload: { id } });
      dispatch(successHandler({ title: 'Success!', message: 'Label has been saved' }));

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.DELETE_JOB_TEMPLATE_LABEL.ERROR, payload: error }));
};

export const getJobTemplateRelationTypes = () => dispatch => {
  dispatch({ type: TYPES.GET_RELATION_TYPES.START });

  return get(`/job-templates/relation-types`)
    .then(response => {
      dispatch({ type: TYPES.GET_RELATION_TYPES.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_RELATION_TYPES.ERROR, payload: error }));
};

export const setEditingLabel = params => dispatch => {
  const { id } = params;

  dispatch({ type: TYPES.SET_EDITING_LABEL, payload: id });
};
