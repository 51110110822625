import { Fragment, FC } from 'react';

import CreateNewSearch from './CreateNewSearch';
import EditSearch from './EditSearch';
import { useAppSelector } from '@/store/hooks';
import { selectAccount } from 'store/account/selectors';
import { OnboardSetupFilter } from '@/common/types/onboard-setup.ts';

interface TableSearch extends OnboardSetupFilter {
  party_id: string;
  table_label: string;
}

type TitleLayoutActionsProps = {
  label: string;
  tableIsInvalid: boolean;
  queryChanged: boolean;
  setModalOpened: () => void;
  isSetupOnboard: boolean;
  savedSearchParam: string;
  tableSearch: TableSearch;
};

const TitleLayoutActions: FC<TitleLayoutActionsProps> = ({
  savedSearchParam,
  tableSearch,
  isSetupOnboard,
  setModalOpened,
  queryChanged,
  label,
  tableIsInvalid
}) => {
  const account = useAppSelector(selectAccount);

  return savedSearchParam ? (
    tableSearch ? (
      <Fragment>
        {tableIsInvalid ? null : (isSetupOnboard && tableSearch.id) ||
          (!isSetupOnboard && tableSearch.party_id === account.id) ? (
          <EditSearch
            setModalOpened={setModalOpened}
            tableSearch={tableSearch}
            queryChanged={queryChanged}
            label={label}
            account={account}
            isSetupOnboard={isSetupOnboard}
          />
        ) : (
          <CreateNewSearch
            setModalOpened={setModalOpened}
            savedSearchParam={savedSearchParam}
            tableSearch={tableSearch}
            queryChanged={queryChanged}
            account={account}
            isSetupOnboard={isSetupOnboard}
          />
        )}
      </Fragment>
    ) : null
  ) : tableIsInvalid ? null : (
    <CreateNewSearch
      isSetupOnboard={isSetupOnboard}
      setModalOpened={setModalOpened}
      savedSearchParam={savedSearchParam}
      tableSearch={tableSearch}
      queryChanged={queryChanged}
      account={account}
    />
  );
};

export default TitleLayoutActions;
