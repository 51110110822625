import React, { useState } from 'react';
import { Button } from 'reactstrap';

import PropTypes from 'prop-types';

import ButtonIcon from '../general/ButtonIcon';
import DragHandler from '../general/DragHandler';

import config from './types/_config';

import gear from '../../assets/svg/gear.svg';

const Tool = ({ tool, removeTool, isEditing, isSidebarOpen, settings, systemCurrency }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [hideSettings, setHideSettings] = useState(false);

  if (!config[tool.label]) return null;

  const data = config[tool.label];
  const ToolComponent = data.component;

  return (
    <div className="toolbar-tool" data-type={tool.label}>
      {isEditing ? <ButtonIcon type="remove" onClick={() => removeTool(tool)} /> : null}
      <div className="toolbar-tool--header">
        <img src={data.icon} width={18} height={18} alt="tool icon" />
        <span className="px-1">{tool.name}</span>
        <div className="toolbar-tool--header-actions ms-auto d-flex my-2 align-items-center">
          {data.settings ? (
            isSettingsOpen || hideSettings ? null : (
              <Button
                type="button"
                className="p-0 mx-1"
                color="link"
                onClick={() => setIsSettingsOpen(true)}
              >
                <img src={gear} width={18} height={18} alt="settings icon" />
              </Button>
            )
          ) : null}
          {isEditing ? <DragHandler /> : null}
        </div>
      </div>
      <div className="toolbar-tool--body">
        <ToolComponent
          tool={tool}
          setHideSettings={setHideSettings}
          isSidebarOpen={isSidebarOpen}
          settings={settings}
          isSettingsOpen={isSettingsOpen}
          setIsSettingsOpen={setIsSettingsOpen}
          systemCurrency={systemCurrency}
        />
      </div>
    </div>
  );
};

export default Tool;

Tool.propTypes = {
  tool: PropTypes.object.isRequired
};
