import _last from 'lodash/last';
import { strToNumber } from 'common/utils/numbers';

import {
  selectVesselVoyageForecastVesselId,
  selectVesselVoyageForecastNewPortState,
  selectVesselVoyageForecastDataFrom,
  selectVesselVoyageForecastCalculatedDataFrom
} from './selectors';
import {
  selectTableListData,
  selectTableListRequestParams
} from 'common/components/table/store/selectors';

export const getVesselVoyageForecastParams = params => (_, getState) => {
  const state = getState();
  const vesselId = selectVesselVoyageForecastVesselId(state);
  const dataFrom = selectVesselVoyageForecastDataFrom(state);
  const calculatedDataFrom = selectVesselVoyageForecastCalculatedDataFrom(state);

  const tableData = selectTableListData(state, 'vessel_voyage_forecast');

  const requestParams = {
    vessel_id: vesselId,
    data_from: dataFrom,
    voyage_forecast_ports: []
  };

  if (requestParams.data_from === 'dynamic_data') {
    // When dynamic_data, send also the vessel cii dates
    const tableRequestParams = selectTableListRequestParams(state, 'reports_vessel_cii_profile');
    const dates = tableRequestParams?.filters?.find(f => f.name === 'dates');
    requestParams.extra = { dates: dates?.value || [] };
  }

  // Add existing ports state
  requestParams.voyage_forecast_ports = tableData.map(
    ({ port_id, condition, is_trip, grade, instructed_speed, distance, days, action }) => ({
      port_id,
      condition,
      is_trip,
      grade,
      instructed_speed: dataFrom !== calculatedDataFrom ? null : strToNumber(instructed_speed),
      distance: strToNumber(distance),
      days: strToNumber(days),
      action
    })
  );

  // Add the new port
  if (params?.is_new) {
    const newPortState = selectVesselVoyageForecastNewPortState(state);

    // if there is previous port, send also the trip between the previous port and the new one
    const previousPort = _last(tableData);

    if (previousPort)
      requestParams.voyage_forecast_ports.push({
        port_id: previousPort?.port_id,
        is_trip: true,
        grade: 'hfo'
      });

    requestParams.voyage_forecast_ports.push({
      port_id: newPortState.port_id,
      condition: newPortState.condition,
      grade: newPortState.grade,
      is_trip: false
    });
  }

  return requestParams;
};

export const parseVesselVoyageForecastPorts = voyageForecastPorts => {
  return voyageForecastPorts.map((data, index) => ({
    ...data,
    distance: strToNumber(data.distance),
    days: strToNumber(data.days),
    id: `${data.port_id}_${index}`
  }));
};

export const recalculateVesselVoyageForecastPortFields = (
  updatedVoyageForecastPorts,
  stateVoyageForecastPorts
) => {
  const updatableFields = [
    { key: 'instructed_speed_min', is_trip: true },
    { key: 'days', is_trip: true },
    { key: 'days_max', is_trip: true },
    { key: 'consumption_rate' },
    { key: 'total_consumption' },
    { key: 'total_consumption_max' },
    { key: 'leg_cii' },
    { key: 'leg_cii_rating' },
    { key: 'voyage_cii' },
    { key: 'voyage_cii_rating' }
  ];

  return stateVoyageForecastPorts.map((data, index) => {
    const updatedVoyagePort = updatedVoyageForecastPorts?.[index];

    if (updatedVoyagePort) {
      const updatedData = { ...data };

      updatableFields.forEach(field => {
        if ((field.is_trip && data.is_trip) || !field.is_trip) {
          updatedData[field.key] = updatedVoyagePort[field.key];
        }
      });

      return updatedData;
    }

    return data;
  });
};
