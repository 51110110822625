import { useState, useMemo } from 'react';
import { download } from 'utils/api';
import { handleFileDownload } from 'common/utils/downloads';

import { useActions, useFormState } from 'utils/hooks';
import { useSelector } from 'react-redux';

import * as requisitionActions from 'common/components/purchasing/requisition/store/actions';

import {
  selectActiveRequisitionID,
  selectActiveRequisitionCode
} from 'common/components/purchasing/requisition/store/selectors';
import { Row, Col } from 'reactstrap';

import CollapsedFields from './EmailFormCollapsedFields';
import SvgRender from 'common/components/general/SvgRender';
import arrowFilters from 'common/assets/svg/common/arrows/arrow-filters.svg';
import Editor from 'common/components/form/inputs/Editor';
import email from 'common/assets/svg/socials/email.svg';
import ExportExcel from 'common/components/general/ExportExcel';
import UploadFiles from '@/ts-common/components/form/inputs/upload-files';
import EmailAttachmentsSync from './EmailAttachmentsSync';
import EmailSupplierExports from './EmailSupplierExports';
import Information from '@/ts-common/components/general/Information';

const EmailForm = ({ formState, externalEmail, emailStatuses }) => {
  const { fields, selectField } = useFormState(formState);

  const [isVisible, setIsVisible] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  const activeID = useSelector(selectActiveRequisitionID);
  const requisitionCode = useSelector(selectActiveRequisitionCode);

  const [getRequisitionRequestPrefix] = useActions([
    requisitionActions.getRequisitionRequestPrefix
  ]);

  const onExcelDownload = async id => {
    if (isDownloading) return;

    const rParams = {
      supplier_id: id
    };

    setIsDownloading(true);

    await handleFileDownload(
      {
        url: `${getRequisitionRequestPrefix()}/${activeID}/rfq/export/excel`,
        filename: `${requisitionCode}_rfq`
      },
      download,
      false,
      {
        requestParams: rParams,
        parsed: true
      }
    );
    setIsDownloading(false);
  };

  const onPdfDownload = async id => {
    if (isDownloading) return;

    setIsDownloading(true);

    await handleFileDownload(
      { url: `purchasing-requisitions/suppliers/${id}/export/pdf` },
      download
    );

    setIsDownloading(false);
  };

  const isQtn = useMemo(
    () => emailStatuses?.find(f => fields.status_id?.value === f?.id)?.label === 'qtn',
    [fields.status_id?.value, emailStatuses]
  );
  const isPo = useMemo(
    () => emailStatuses?.find(f => fields.status_id?.value === f?.id)?.label === 'po',
    [fields.status_id?.value, emailStatuses]
  );

  const fieldTo = Array.isArray(fields.to.value)
    ? fields.to.value.filter(supplier => supplier?.supplier_id)
    : [];

  return (
    <div className="email-form">
      <div
        onClick={() => setIsVisible(!isVisible)}
        className="mb-1 pb-1 border-bottom border-primary cursor-pointer d-flex align-items-center"
      >
        <div className="width-24 height-24 bg-primary text-white rounded-circle d-flex justify-content-center align-items-center me-1">
          <SvgRender
            src={email}
            style={{
              width: 12,
              height: 12
            }}
          />
        </div>
        <strong className="text-primary fs-10 lh-12">AUTO EMAIL</strong>
        <div className="width-16 height-16 text-primary border border-primary rounded-circle d-flex justify-content-center align-items-center ms-auto">
          <SvgRender
            src={arrowFilters}
            className="base-transition"
            style={{
              width: 8,
              height: 8,
              transform: !isVisible ? 'rotate(0deg)' : 'rotate(180deg)'
            }}
          />
        </div>
      </div>

      <CollapsedFields
        emailStatuses={emailStatuses}
        fields={fields}
        selectField={selectField}
        isVisible={isVisible}
      />

      <div
        className={`email-form__group--body cmt-6 cmb-6 ${
          isVisible ? 'collapse-open' : 'collapse-closed'
        }`}
      >
        <Editor
          placeholder="Add some text"
          onChange={html => selectField('body')(html)}
          value={fields.body?.value}
        />
        <div className="email-form__group--body_code text-violet fs-12 fw-medium">
          [{requisitionCode}]
        </div>
      </div>

      <Row className="border-top pt-1 g-0">
        <Col xs={4} className="d-flex flex-nowrap align-items-center pe-1 position-relative">
          {externalEmail ? (
            <Information
              message={
                <div className="text-start">
                  You can upload:
                  <br />
                  Maximum {externalEmail.max_files_size} of attachments
                  <br />
                  Maximum {externalEmail.max_files_count} attachments.
                </div>
              }
              className="ms-n2 me-1 z-index-1 cursor-pointer"
              tooltipClassname="max-width-none"
              svgClassname="text-primary"
              svgStyle={{ width: 12, height: 12 }}
            />
          ) : null}

          {isQtn && !fieldTo.length ? (
            <ExportExcel
              className="me-1"
              showLabel={false}
              onClick={() => onExcelDownload()}
              svgStyle={{ width: 13, height: 13 }}
              disabled={isDownloading}
            />
          ) : null}

          {fieldTo.map(supplier =>
            supplier?.supplier_id ? (
              <EmailSupplierExports
                key={supplier.id}
                supplier={supplier}
                emailStatuses={emailStatuses}
                isQtn={isQtn}
                isPo={isPo}
                isDownloading={isDownloading}
                onExcelDownload={onExcelDownload}
                onPdfDownload={onPdfDownload}
              />
            ) : null
          )}

          <UploadFiles
            group="purchasing.supplier-emails"
            files={fields.attachments.value}
            onChange={selectField('attachments')}
            size="sm"
            className="mb-0"
          />
        </Col>

        <Col xs={4} className="cpe-4">
          <EmailAttachmentsSync
            onChange={files => selectField('synced_office_attachments')(files)}
            attachments={fields.synced_office_attachments.value}
            type="office"
          />
        </Col>
        <Col xs={4} className="cps-4">
          <EmailAttachmentsSync
            onChange={files => selectField('synced_vessel_attachments')(files)}
            attachments={fields.synced_vessel_attachments.value}
            type="vessel"
          />
        </Col>
      </Row>
    </div>
  );
};

export default EmailForm;
