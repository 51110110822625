import moment from 'moment';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import numeral from 'numeral';
import { strToNumber } from 'common/utils/numbers';

export const getLoadingOrDischargingAction = (values, key) => {
  const labels = ['loading', 'discharging'];
  const actions = ((_isArray(values) ? values : _get(values, key)) || []).filter(
    action => action?.type_label && labels.includes(action?.type_label)
  );

  const firstRealAction = actions.find(action => action?.port_statement_action_id);

  return firstRealAction
    ? firstRealAction
    : actions.find(action => !action?.port_statement_action_id);
};

export const getLoadingOrDischargingActionTimeline = action => {
  return _get(action, 'port_statement_details.cargo_operation_timeline');
};

export const getLoadingOrDischargingActionTimelineQuantitySum = timeline => {
  let total = 0;

  const vesselQuantitySum = timeline.reduce((acc, cur) => {
    const sum = numeral(acc);
    return sum.add(strToNumber(cur.cargo_operation_quantity_vessel) || 0);
  }, 0)._value;

  if (!vesselQuantitySum) {
    const shoreQuantitySum = timeline.reduce((acc, cur) => {
      const sum = numeral(acc);
      return sum.add(strToNumber(cur.cargo_operation_quantity_shore) || 0);
    }, 0)._value;

    total = shoreQuantitySum;
  } else {
    total = vesselQuantitySum;
  }

  return total;
};

export const commonSuffixes = {
  knots: ' kt',
  kilowatts: ' kw',
  metricTonsPerDay: ' mt/d',
  metricTons: ' mt',
  beaufort: ' bft',
  meters: ' m',
  percentage: '%',
  miles: ' miles'
};

export const configSettings = {
  liveAtPort: {
    integrationValueKey: 'last_updated.last_updated_value',
    averageTooltipLectical: ({ lastUpdatedAt }) => (
      <div>
        {lastUpdatedAt
          ? `${moment(lastUpdatedAt).format('DD/MM/YYYY HH:mm')} (UTC)`
          : 'Latest value'}
      </div>
    ),
    hideLatestTooltip: true,
    hideTooltipCaptainReportValue: true
  },
  liveAtSea: {
    integrationValueKey: 'last_updated.last_updated_value',
    averageTooltipLectical: ({ lastUpdatedAt }) => (
      <div>
        {lastUpdatedAt
          ? `${moment(lastUpdatedAt).format('DD/MM/YYYY HH:mm')} (UTC)`
          : 'Latest value'}
      </div>
    ),
    hideLatestTooltip: true,
    hideTooltipCaptainReportValue: true
  },
  prevReportAtPort: {
    integrationValueKey: 'value',
    averageTooltipLectical: 'Report to Report Average',
    forceNoonValueText: true
  },
  prevReportAtSea: {
    integrationValueKey: 'value',
    averageTooltipLectical: 'Report to Report Average',
    forceNoonValueText: true
  },
  totalsAtPort: {
    integrationValueKey: 'value',
    tooltipTitlePrefix: 'Port ',
    averageTooltipLectical: 'Port Average',
    forceNoonValueText: true
  },
  totalsAtSea: {
    integrationValueKey: 'value',
    tooltipTitlePrefix: 'Leg ',
    averageTooltipLectical: 'Leg Average',
    forceNoonValueText: true
  }
};
