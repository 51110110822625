import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import notificationsEmpty from 'assets/svg/sidebar/notifications-empty-state.svg';

const EmptyState = () => {
  return (
    <div className="notifications-empty d-flex flex-1 align-items-center justify-content-center">
      <div className="text-violet empty-text">No notifications right now</div>
      <div className="empty-icon">
        <SvgRender src={notificationsEmpty} style={{ width: '100%', height: '100%' }} />
      </div>
    </div>
  );
};

export default EmptyState;
