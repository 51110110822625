import _uniqBy from 'lodash/uniqBy';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { selectListOptionsFromStore } from 'store/lists/selectors';

const StatusLiveActions = ({ values, field }) => {
  const ACTION_TYPES_WITH_HIGH_PRIORITY = ['loading', 'discharging', 'bunkering'];

  const actionTypes = useSelector(state =>
    selectListOptionsFromStore(state, 'itinerary-action-types')
  );

  const getActionTypeName = label => {
    if (!label) return null;
    return actionTypes?.find(type => type?.label === label)?.name;
  };

  const actions = _uniqBy(
    Object.values(_get(values, field.key)).filter(t => t?.type_label),
    'type_label'
  ).map(value => {
    return { name: getActionTypeName(value?.type_label), type_label: value?.type_label };
  });

  const hpActions = actions
    ?.filter(action => ACTION_TYPES_WITH_HIGH_PRIORITY?.includes(action.type_label))
    .map(action => action.name);

  const otherActions = actions
    ?.filter(action => !ACTION_TYPES_WITH_HIGH_PRIORITY?.includes(action.type_label))
    .map(action => action.name);

  const { avoidRender, tooltipID } = useTooltipID('action-types');
  if (avoidRender) return null;

  return (
    <div className={`d-flex w-100p cmb-4 pe-2 ${field.className || ''}`}>
      <span className="fw-bold lh-12 cme-4 cpt-2">{`${field.title}:`}</span>
      <div className="d-flex flex-1 flex-wrap align-items-center pe-2">
        {hpActions?.length ? hpActions?.join(', ') : null}
        {otherActions?.length ? (
          <>
            <strong id={tooltipID} className="ms-1 cursor-pointer">
              +{otherActions?.length}
            </strong>
            <Tooltip fade={false} target={tooltipID} placement="top" hideArrow={true}>
              <span>{otherActions?.join(', ')}</span>
            </Tooltip>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default StatusLiveActions;
