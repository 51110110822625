import TYPES from './types';
import { LOCATION_CHANGE } from 'connected-react-router';
import paths from 'routing/routes/_paths';

const INITIAL_STATE = {
  onSignerFormActive: null,
  alertModal: {
    open: false,
    rotation: null
  },
  onSignerContractModal: {
    open: false,
    rotation: null
  },
  offSignerContractModal: {
    open: false,
    rotation: null
  },
  createEventModal: {
    open: false,
    data: null
  },

  selectedRotation: {}, // used to preview the rotation (on & off signer) tickets

  prevLocation: '',
  preventionModal: {
    open: false,
    message: null
  },
  selectedTab: '',

  enabledBulkSelectRotations: false
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.PREVIEW_ROTATION_TICKETS:
      const { id, off_signer_trip_id, off_signer_id, on_signer_trip_id, on_signer_id } = payload;

      return {
        ...state,
        selectedRotation: {
          id,
          off_signer_trip_id,
          off_signer_id,
          on_signer_trip_id,
          on_signer_id
        }
      };

    case TYPES.RESET_SELECTED_ROTATION:
      return {
        ...state,
        selectedRotation: {}
      };

    case TYPES.TRIGGER_ALERT_MODAL:
      return {
        ...state,
        alertModal: payload
      };

    case TYPES.TRIGGER_ON_SIGNER_CONTRACT_MODAL:
      return {
        ...state,
        onSignerContractModal: payload
      };

    case TYPES.SET_ON_SIGNER_FORM_ACTIVE:
      return {
        ...state,
        onSignerFormActive: payload
      };

    case TYPES.TRIGGER_OFF_SIGNER_CONTRACT_MODAL:
      return {
        ...state,
        offSignerContractModal: payload
      };

    case TYPES.TRIGGER_CREATE_EVENT_MODAL:
      return {
        ...state,
        createEventModal: payload
      };

    case LOCATION_CHANGE:
      if (!payload.location.pathname.startsWith(paths.crew_planning)) {
        return { ...INITIAL_STATE };
      }

      const prevLocation = payload.location.pathname.endsWith('history') ? 'history' : 'rotation';

      if (prevLocation !== state.prevLocation) {
        return {
          ...state,
          prevLocation,
          offerFormsOpened: [], // [ array of rotation ids ]
          offerFormData: {},
          rotationTickets: {},
          tickets: {}
        };
      }

      return {
        ...state,
        prevLocation
      };

    case TYPES.SET_CREW_PLANNING_PREVENTION_MODAL:
      return {
        ...state,
        preventionModal: !payload ? { open: false, message: '' } : payload
      };

    case TYPES.SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: payload
      };

    case TYPES.ENABLE_BULK_SELECT_ROTATIONS:
      return {
        ...state,
        enabledBulkSelectRotations: payload
      };

    default:
      return state;
  }
};

export default reducer;
