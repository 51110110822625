import SetupAuthenticatorApp from 'common/components/authentication/authenticator/SetupAuthenticatorApp';
import BoxShadowButton from 'common/components/buttons/BoxShadowButton';
import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { useActions } from 'utils/hooks';
import { Form } from 'reactstrap';

import * as authActions from 'store/auth/actions';

const Setup = ({ code, setCode, onLogin, disabled }) => {
  const [qrToken, setQrToken] = useState(null);

  const [setupTwoFactorAuthentication] = useActions([authActions.setupTwoFactorAuthentication]);

  const getQrToken = useCallback(async () => {
    try {
      const response = await setupTwoFactorAuthentication({
        method: 'totp',
        config: { action: 'getQR' }
      }).unwrap();

      setQrToken(response?.data?.uri);
    } catch (data) {
      console.error(data);
    }
  }, [setupTwoFactorAuthentication]);

  useEffect(() => {
    getQrToken();
  }, [getQrToken]);

  return (
    <Form className="login-page__totp d-flex flex-column justify-content-center h-100p">
      <h1 className="mb-1 gotham-normal">Set up your authenticator app</h1>

      <SetupAuthenticatorApp onCodeChange={setCode} codeError={code?.error} qrToken={qrToken} />

      <div className="d-flex mt-auto mb-2 mb-hd-5 justify-content-end">
        <BoxShadowButton color="yellow" type="submit" onClick={onLogin} disabled={disabled}>
          Login
        </BoxShadowButton>
      </div>
    </Form>
  );
};

Setup.propTypes = {
  code: PropTypes.object,
  setCode: PropTypes.func,
  onLogin: PropTypes.func,
  disabled: PropTypes.bool
};

export default Setup;
