import { FC, useState } from 'react';
import SvgRender from 'common/components/general/SvgRender';
import Tooltip from 'common/components/general/Tooltip';

import { Button } from 'reactstrap';
import { useActions } from 'utils/hooks';

import star from 'common/assets/svg/common/star.svg';
import starFill from 'common/assets/svg/common/star-solid.svg';

import * as favoritesActions from 'store/favorites/actions';

type FavoriteTableSearchProps = {
  id: string;
  isFavorite?: boolean;
  className?: string;
  favouriteType?: string;
  onClick?: () => void;
};

const FavoriteTableSearch: FC<FavoriteTableSearchProps> = ({
  id,
  isFavorite,
  className,
  favouriteType,
  onClick
}) => {
  const [starClicked, setStarClicked] = useState(false);
  const [toggleFavorite] = useActions([favoritesActions.toggleFavorite]);

  return (
    <>
      <Button
        id={`favorite-button-${id}`}
        color={'link'}
        className={`d-inline-flex align-items-center justify-content-center${
          isFavorite || starClicked ? ' text-yellow ' : ''
        } ${className || ''}`}
        onClick={() => {
          if (onClick) {
            onClick();
            setTimeout(() => {
              toggleFavorite({ favourite_id: id, favourite_type: favouriteType });
            }, 1000);
          } else {
            setStarClicked(true);
            toggleFavorite({ favourite_id: id, favourite_type: favouriteType }).then(() =>
              setStarClicked(false)
            );
          }
        }}
      >
        <SvgRender
          src={isFavorite || starClicked ? starFill : star}
          style={{ height: 18, width: 18 }}
        />
      </Button>

      <Tooltip target={`favorite-button-${id}`}>{`${
        isFavorite ? 'Remove from' : 'Save as'
      } Starred`}</Tooltip>
    </>
  );
};

export default FavoriteTableSearch;
