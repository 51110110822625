import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import PropTypes from 'prop-types';
import paths from 'routing/routes/_paths';
import { useSelector } from 'react-redux';
import { selectWidgetState } from 'store/dashboards/selectors';

import TextWithTooltip from 'common/components/general/TextWithTooltip';

import moment from 'moment';

import Status from 'common/components/general/Status';
import Priority from 'common/components/general/Priority';
import _get from 'lodash/get';
import { Link } from 'react-router-dom';
import DueDate from 'common/components/jobs/_base/components/DueDate';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import JobLink from 'common/components/jobs/maintenance/components/JobLink';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import { downloadAllFilesOfEntity } from 'common/utils/downloads';
import PageLink from 'common/components/general/page-link';
import AuthCheck from 'components/permissions/AuthCheck';
import permissions from 'common/utils/permissions/constants';

const FlagDispenations = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));

  return (
    <div>
      <WidgetTitle id={id} linkTo={paths.flag_extensions_dispensations_jobs} />
      <WidgetDefaultTable
        id={id}
        columns={[
          {
            header: '',
            key: 'importance_id',
            headerClassName: 'text-violet',
            width: 1
          },
          {
            header: 'Title',
            key: 'title',
            type: 'string',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Due Date',
            key: 'due_date',
            type: 'date',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Dispensation Type',
            key: 'flag_extension_and_dispensation_types',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Requested On',
            key: 'requested_on',
            type: 'date',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Status',
            key: 'status_id',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'vessel',
            headerClassName: 'text-violet',
            key: 'vessels'
          }
        ]}
        rows={{
          title: data => (
            <JobLink customTooltipMessage="Opens the Job" history={''} job={data} isRegularJob />
          ),
          requested_on: data => {
            const requestedOn = _get(data, 'flag_extension.requested_on');
            const attachments = _get(data, 'flag_extension.attachments', []);
            const attachmentsCount = _get(data, 'flag_extension.attachments_count', []);

            return (
              <div className="d-flex justify-content-between">
                <div>{requestedOn ? moment(requestedOn).format('DD/MM/YYYY') : '-'}</div>
                <div className="d-flex align-items-center justify-content-end">
                  <DownloadAttachment
                    data={{ attachments_count: attachmentsCount, attachments: attachments }}
                    downloadFiles={() =>
                      downloadAllFilesOfEntity({ id: data?.id, type: 'job_flag_extension' })
                    }
                    hasAttachments={true}
                    canAddAttachments={false}
                    popupPlacement={'left'}
                  />
                </div>
              </div>
            );
          },
          flag_extension_and_dispensation_types: data => {
            const type = _get(data, 'flag_extension.type.name');

            return type ? (
              <TextWithTooltip className="d-flex align-items-center">{type}</TextWithTooltip>
            ) : (
              '-'
            );
          },
          due_date: data => (
            <DueDate
              dueDateChanged={data.last_due_date_change}
              className="fs-12"
              dueDate={data.due_date}
              createdAt={data.created_at}
              status={data.status_id}
            />
          ),
          importance_id: data => (
            <div className="d-flex align-items-center">
              <Priority value={data.importance_id} />{' '}
              <div className="ms-2 fs-10 text-primary fw-medium">
                <TextWithTooltip className="d-flex align-items-center">
                  {data?.orca_id || data.id}
                </TextWithTooltip>
              </div>
            </div>
          ),
          status_id: data => <Status value={data.status_id} />,
          vessels: data => {
            const vessels = data.is_for_vessel && data.vessel ? [data.vessel] : data.vessels;

            return vessels && vessels.length > 0 ? (
              <NamesInTooltip
                target={`job-table-vessel-${data.id}`}
                names={vessels.map((vessel, index) => {
                  const Tag = index > 0 ? Link : PageLink;

                  return (
                    <AuthCheck
                      key={vessel.id}
                      permissions={[permissions.office.vessels.list]}
                      unAuthorizedRender={_get(vessel, 'name', '-')}
                    >
                      <Tag to={`${paths.vessels}/${vessel?.id}`}>{vessel.name}</Tag>
                    </AuthCheck>
                  );
                })}
              />
            ) : (
              '-'
            );
          }
        }}
        label={label}
        state={state}
      />
    </div>
  );
};

FlagDispenations.propTypes = {
  id: PropTypes.number
};

export default FlagDispenations;
