import TYPES from './types';
import { Dispatch } from 'redux';
import { OnboardSetupFilter } from '@/common/types/onboard-setup.ts';

export const setHasSavedTableFilters = (value: boolean) => (dispatch: Dispatch) => {
  return dispatch({ type: TYPES.SET_HAS_TABLE_SAVED_FILTERS, payload: value });
};

export const setTableSearch = (value: OnboardSetupFilter | null) => (dispatch: Dispatch) => {
  return dispatch({ type: TYPES.SET_TABLE_SEARCH, payload: value });
};
