import React, { useState } from 'react';

import SvgRender from 'common/components/general/SvgRender';
import goToPage from 'common/assets/svg/actions/go-to-page.svg';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import PropTypes from 'prop-types';
import ProfileImage from 'common/components/general/ProfileImage';
import ShadowBox from 'common/components/general/ShadowBox';
import _get from 'lodash/get';
import avatar from 'common/assets/svg/avatars/crew.svg';
import { Row, Col } from 'reactstrap';
import phoneIcon from 'common/assets/svg/common/phone.svg';
import email from 'common/assets/svg/socials/email.svg';
import { Department } from 'common/components/general/Departments';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import paths from 'routing/routes/_paths';
import AuthCheck from 'components/permissions/AuthCheck';
import permissions from 'common/utils/permissions/constants';

const PersonBox = ({ item }) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <ShadowBox
      className="cpx-6 cpt-6 cpb-6 cmb-4 w-100p height-78 overflow-hidden"
      color="white"
      flat
      whiteOnHover
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Row xs={12} className="pb-1">
        <Col xs={1}>
          <ProfileImage
            image={_get(item, 'photo.url', null)}
            fallback={avatar}
            imgProps={{ width: 32, height: 32 }}
          />
        </Col>

        <Col xs={7} className="d-flex flex-column ps-2">
          <div className="d-flex fs-12 text-primary">
            <TextWithTooltip className="w-auto">{item.full_name}</TextWithTooltip>

            {isHovering ? (
              <AuthCheck key={item.id} permissions={[permissions.office.contacts.persons.view]}>
                <SvgRender
                  className="text-primary cursor-pointer ms-1 cpt-2"
                  src={goToPage}
                  style={{ width: 12, height: 12 }}
                  onClick={() => window.open(`${paths.persons}/${item?.id}`)}
                />
              </AuthCheck>
            ) : null}
          </div>

          <div className="d-flex fs-12 text-violet">
            {item.department_parties?.[0]?.department_role?.name ? (
              <TextWithTooltip>{item.department_parties[0].department_role.name}</TextWithTooltip>
            ) : (
              '-'
            )}
          </div>
        </Col>

        <Col xs={4} className="d-flex align-items-end justify-content-end">
          {item.departments.length ? <Department value={item.departments?.[0]} /> : null}
        </Col>
      </Row>

      <div className="w-100p border-bottom border-light-gray-2"></div>

      <Row className="cmt-4">
        <Col xs={5} className="d-flex text-violet fs-12 align-items-center">
          <SvgRender className=" me-1" src={phoneIcon} style={{ width: 12, height: 12 }} />
          {item.phone || item.extra_phones?.length ? (
            <NamesInTooltip
              target={`companies-phones-${item.id}`}
              names={[item.phone, ...item.extra_phones.map(phone => phone?.value)]}
            />
          ) : (
            '-'
          )}
        </Col>
        <Col xs={7} className="d-flex text-violet fs-12 align-items-center">
          <SvgRender className="text-violet me-1" src={email} style={{ width: 12, height: 12 }} />
          {item?.email ? <TextWithTooltip>{item.email}</TextWithTooltip> : '-'}
        </Col>
      </Row>
    </ShadowBox>
  );
};

PersonBox.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    full_name: PropTypes.string,
    email: PropTypes.string
  })
};

export default PersonBox;
