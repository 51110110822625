import { createSelector } from 'reselect';

const selectPricelistReducer = state => state.purchasing?.pricelist;
const getKey = (_, key) => key;

export const selectPricelistIsLoading = state => selectPricelistReducer(state)?.isLoading;

export const selectPricelistIsAutoSaving = state => selectPricelistReducer(state)?.isAutoSaving;

export const selectPricelistIsInitialized = state => selectPricelistReducer(state)?.isInitialized;

export const selectShouldRefetchTableData = state =>
  selectPricelistReducer(state)?.shouldRefetchTableData;

export const selectPricelistActiveData = state => selectPricelistReducer(state)?.active;

export const selectPricelistActiveId = state => selectPricelistActiveData(state)?.id;

// Price zones
const selectPricelistPriceZoneForm = state => selectPricelistReducer(state)?.priceZoneForm;

export const selectPricelistPriceZoneFormIsOpen = state =>
  selectPricelistPriceZoneForm(state)?.isOpen;

export const selectPricelistPriceZoneFormActive = state =>
  selectPricelistPriceZoneForm(state)?.active;
// --

// Items
export const selectPricelistisPurchasingDrawerIsOpen = state =>
  selectPricelistReducer(state)?.isPurchasingDrawerOpen;

const selectPricelistItems = state => selectPricelistReducer(state)?.items;

export const selectItemPriceZoneFiedValue = (state, itemId, priceZoneId, fieldKey) =>
  selectPricelistItems(state)?.[itemId]?.[priceZoneId]?.[fieldKey];

export const selectExpandedPriceZoneDiscounts = state =>
  selectPricelistReducer(state)?.expandedPriceZoneDiscounts;

export const selectIsPriceZoneDiscountExpanded = createSelector(
  selectExpandedPriceZoneDiscounts,
  getKey,
  (expandedPriceZoneDiscounts, priceZoneId) => expandedPriceZoneDiscounts.includes(priceZoneId)
);

const selectPriceZoneDiscounts = state => selectPricelistReducer(state)?.priceZoneDiscounts;

export const selectPriceZoneDiscount = (state, priceZoneId) =>
  selectPriceZoneDiscounts(state)?.[priceZoneId];

// --
