import { get } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import TYPES from './types';
import REQUISITION_TYPES from 'common/components/purchasing/requisition/store/types';

export const getRequisitionSummary = createAsyncThunk(
  TYPES.GET_REQUISITION_SUMMARY,
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const res = await get(`/purchasing-requisitions/${params.id}/summary`);
      const { items, suppliers, ...requisition } = res.data;

      if (params.init_state) {
        dispatch({
          type: REQUISITION_TYPES.GET_PURCHASING_REQUISITION.SUCCESS,
          payload: requisition
        });

        dispatch({
          type: REQUISITION_TYPES.GET_PURCHASING_REQUISITION_SUPPLIERS.SUCCESS,
          payload: suppliers.map((e, index) => ({
            ...e,
            supplier_number: index + 1,
            system_currency_label: getState()?.settings?.system_currency?.label
          }))
        });
      }

      dispatch({
        type: REQUISITION_TYPES.GET_PURCHASING_REQUISITION_ITEMS.SUCCESS,
        payload: { data: items, params }
      });

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
