import TYPES from './types';
import MANUAL_TYPES from 'store/manuals/list/types';
import _cloneDeep from 'lodash/cloneDeep';

const INITIAL_STATE = {
  totals: {},
  actionLoading: false,
  listLoading: false,
  deletePreventionModal: { isOpen: false, selected: null },
  currentVersion: null,
  pendingForApprovalVersion: null,
  newVersion: null
};

const manuals = function (state = _cloneDeep(INITIAL_STATE), { type, payload }) {
  switch (type) {
    case TYPES.GET_MANUALS_CHANGE_REQUESTS.START:
      return {
        ...state,
        listLoading: true
      };

    case TYPES.GET_MANUALS_CHANGE_REQUESTS.SUCCESS:
      if (payload) {
        if (state.pendingForApprovalVersion && !payload.params.version_id) {
          return { ...state, listLoading: false };
        }

        const { data, meta } = payload.data;

        return {
          ...state,
          changeRequests: {
            list: data?.map(request => {
              return {
                ...request,
                parents: request.parents ? request.parents : null
              };
            }),
            meta: meta
          },
          listLoading: false
        };
      }

      return state;

    case TYPES.GET_MANUALS_CHANGE_REQUESTS.ERROR:
      return {
        ...state,
        listLoading: false
      };

    case TYPES.GET_MANUALS_CHANGE_REQUESTS_TOTALS.SUCCESS:
      return {
        ...state,
        totals: payload === state.totals ? null : payload
      };

    case TYPES.RESET_MANUALS_CHANGE_REQUESTS_TOTALS:
      return {
        ...state,
        totals: null
      };

    case TYPES.APPROVE_CHANGE_REQUEST.START:
    case TYPES.REOPEN_CHANGE_REQUEST.START:
    case TYPES.DELETE_CHANGE_REQUEST.START:
    case TYPES.BULK_APPROVE_CHANGE_REQUESTS.START:
    case TYPES.BULK_DELETE_CHANGE_REQUESTS.START:
      return {
        ...state,
        actionLoading: true
      };

    case TYPES.REOPEN_CHANGE_REQUEST.SUCCESS:
    case TYPES.REOPEN_CHANGE_REQUEST.ERROR:
    case TYPES.DELETE_CHANGE_REQUEST.ERROR:
    case TYPES.APPROVE_CHANGE_REQUEST.ERROR:
    case TYPES.BULK_APPROVE_CHANGE_REQUESTS.ERROR:
    case TYPES.BULK_DELETE_CHANGE_REQUESTS.ERROR:
      return {
        ...state,
        actionLoading: false
      };

    case TYPES.DELETE_CHANGE_REQUEST.SUCCESS:
    case TYPES.APPROVE_CHANGE_REQUEST.SUCCESS:
      return {
        ...state,
        actionLoading: false
      };

    case TYPES.BULK_APPROVE_CHANGE_REQUESTS.SUCCESS:
    case TYPES.BULK_DELETE_CHANGE_REQUESTS.SUCCESS:
      return {
        ...state,
        actionLoading: false
      };

    case TYPES.SET_CHANGE_REQUEST_DELETE_PREVENTION_MODAL:
      return {
        ...state,
        deletePreventionModal: payload
      };

    case MANUAL_TYPES.GET_MANUAL.SUCCESS:
      return {
        ...state,
        pendingForApprovalVersion: payload.data.versions?.find(
          v => v.status === 'pending_approval'
        ),
        currentVersion: payload.params.version_id
          ? payload.data.versions?.find(v => v.id == payload.params.version_id)
          : payload.data.versions?.find(v => v.status === 'working')
      };

    case TYPES.RELEASE_PENDING_FOR_APPROVAL_VERSION:
      return INITIAL_STATE;

    case TYPES.CREATE_NEW_VERSION.SUCCESS:
      return { ...state, pendingForApprovalVersion: payload };

    case TYPES.DELETE_PENDING_FOR_APPROVAL_VERSION.SUCCESS:
      return { ...state, pendingForApprovalVersion: null };

    case TYPES.SET_NEW_VERSION:
      return { ...state, newVersion: payload };

    default:
      return state;
  }
};

export default manuals;
