import { useState, useEffect, useCallback } from 'react';
import { Button } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import Drawer, { DrawerHeader } from 'common/components/drawer';
import { FormDrawer } from 'common/components/drawer';
import { FormBody } from 'common/components/drawer';
import { FormFooter } from 'common/components/drawer';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import PartiesSimilarityModal from 'common/components/person/components/PartiesSimilarityModal';
import { triggerModalBySetFieldValues } from 'store/similarities/slice';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';
import { useForm, useFormState } from 'utils/hooks';

import config from './config';
import MainInfo from './sections/MainInfo';
import Address from '../common-sections/Address';
import Documents from './sections/Documents';
import { createPerson } from 'store/persons/actions';
import { selectIsLoadingSimilarities } from '@/store/similarities/selectors';

const CreatePersonDrawer = ({ drawer }) => {
  const [isSaving, setIsSaving] = useState(false);
  const { formState, collectValues, resetForm, hasErrors } = useForm(config, {});
  const { fields, selectField, changeField, subStates, removeSubform, addSubform } =
    useFormState(formState);
  const dispatch = useAppDispatch();

  const isLoadingSimilarities = useAppSelector(selectIsLoadingSimilarities);

  const { tooltipID: personDrawerUniqName } = useTooltipID('person-drawer');

  useEffect(() => {
    if (!drawer.isOpen) {
      resetForm();
    }
  }, [drawer.isOpen, resetForm]);

  const onSubmit = () => {
    const values = collectValues();

    if (!values || hasErrors) return;

    dispatch(triggerModalBySetFieldValues(values));
  };

  const onSave = useCallback(
    async fields => {
      try {
        const {
          country,
          company,
          emails,
          passport_attachment,
          seaman_book_attachment,
          contact_info,
          ...rest
        } = fields;

        const parseParams = {
          ...rest,
          company_id: company?.id || null,
          emails: emails?.filter(email => email?.value) || [],
          contact_info: contact_info?.filter(info => info?.value) || [],
          country_id: country?.id || null,
          passport_attachment_id: passport_attachment?.id || null,
          seaman_book_attachment_id: seaman_book_attachment?.id || null
        };

        await dispatch(createPerson(parseParams));
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch]
  );

  const onCreatePerson = useCallback(
    async params => {
      if (!params) return;

      try {
        setIsSaving(true);

        const res = await onSave(params);

        if (!res?.data?.errors) {
          drawer.close();
        }

        setIsSaving(false);
      } catch (err) {
        setIsSaving(false);
      }
    },
    [drawer, onSave]
  );

  return (
    <Drawer size="sm-md" className={`create-person-drawer ${personDrawerUniqName}`} {...drawer}>
      <DrawerHeader>Create new Person</DrawerHeader>
      <FormDrawer>
        <FormBody className="px-4">
          <MainInfo
            fields={fields}
            selectField={selectField}
            changeField={changeField}
            subStates={subStates}
            removeSubform={removeSubform}
            addSubform={addSubform}
          />

          <Address fields={fields} selectField={selectField} changeField={changeField} />

          <Documents fields={fields} selectField={selectField} changeField={changeField} />
        </FormBody>

        <FormFooter>
          <Button
            color="cancel"
            className="px-0 py-1 me-4"
            onClick={drawer.close}
            disabled={isSaving}
          >
            CANCEL
          </Button>
          <Button
            onClick={onSubmit}
            disabled={isSaving || isLoadingSimilarities}
            color="primary"
            className="px-2"
          >
            CREATE
          </Button>
        </FormFooter>
      </FormDrawer>

      {drawer.isOpen ? (
        <PartiesSimilarityModal
          container={`.${personDrawerUniqName}`}
          backdropClassName="blurred-backdrop"
          onSave={onCreatePerson}
          label="person"
        />
      ) : null}
    </Drawer>
  );
};

export default CreatePersonDrawer;

CreatePersonDrawer.propTypes = {
  drawer: DrawerState
};
