import TYPES from './types';

const INITIAL_STATE = {
  fleet: [],
  isFleetLoading: false,
  selectedSituation: null,
  selectedVoyages: {}
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.GET_FLEET_VESSELS.START:
      return {
        ...state,
        isFleetLoading: true
      };

    case TYPES.GET_FLEET_VESSELS.SUCCESS:
      return {
        ...state,
        fleet: payload,
        isFleetLoading: false
      };

    case TYPES.GET_FLEET_VESSELS.ERROR:
      return {
        ...state,
        isFleetLoading: false
      };

    case TYPES.SET_SELECTED_VOYAGE:
      return {
        ...state,
        selectedVoyages: { ...state.selectedVoyages, [payload.vesselId]: payload.voyage }
      };

    case TYPES.SET_SELECTED_SITUATION:
      return {
        ...state,
        selectedSituation: payload
      };

    default:
      return state;
  }
};

export default reducer;
