import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import Input from 'common/components/form/inputs/Input';
import ShadowBox from 'common/components/general/ShadowBox';
import ListSelect from 'common/components/selectors/ListSelect';

const Address = ({ fields, selectField, changeField }) => {
  return (
    <ShadowBox color="light-1" className="pt-1 px-2 cmb-20" flat>
      <div className="fw-bold fs-12 text-violet border-bottom cpb-4 mb-1">Address</div>

      <Row>
        <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
          <div className="form-label fs-12 text-blue mb-0">ADDRESS</div>
        </Col>

        <Col xs={8} className="mb-1">
          <Input
            placeholder="Add address"
            className="mb-0"
            onChange={changeField('address')}
            {...fields.address}
          />
        </Col>

        <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
          <div className="form-label fs-12 text-blue mb-0">CITY</div>
        </Col>

        <Col xs={8} className="mb-1">
          <Input
            placeholder="Add city"
            className="mb-0"
            onChange={changeField('city')}
            {...fields.city}
          />
        </Col>

        <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
          <div className="form-label fs-12 text-blue mb-0">ZIP CODE</div>
        </Col>

        <Col xs={8} className="mb-1">
          <Input
            placeholder="Add zip code"
            className="mb-0"
            onChange={changeField('postal_code')}
            {...fields.postal_code}
          />
        </Col>

        <Col xs={3} className="d-flex align-items-center justify-content-end mb-1">
          <div className="form-label fs-12 text-blue mb-0">COUNTRY</div>
        </Col>

        <Col xs={8} className="mb-1">
          <ListSelect
            placeholder="Select country"
            list={'countries'}
            isMulti={false}
            isAsync
            className="mb-0 w-100p"
            onChange={selectField('country')}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            {...fields.country}
          />
        </Col>

        <Col xs={3} className="d-flex align-items-center justify-content-end mb-2">
          <div className="form-label fs-12 text-blue mb-0">STATE</div>
        </Col>

        <Col xs={8} className="mb-2">
          <Input
            placeholder="Add state"
            className="mb-0"
            onChange={changeField('state')}
            {...fields.state}
          />
        </Col>
      </Row>
    </ShadowBox>
  );
};

export default Address;

Address.propTypes = {
  fields: PropTypes.object.isRequired,
  selectField: PropTypes.func.isRequired,
  changeField: PropTypes.func.isRequired
};
