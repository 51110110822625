import React from 'react';
import { getCurrentVersionLabel } from 'common/utils/labels';

const CurrentVersion = () => {
  return import.meta.env.VITE_APP_VERSION ? (
    <div className="ps-2 text-violet fs-12 fw-light mt-2">
      v. {getCurrentVersionLabel(import.meta.env.VITE_APP_VERSION, 'core-', '')}
    </div>
  ) : null;
};

export default CurrentVersion;
