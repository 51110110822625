import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LOCATION_CHANGE } from 'connected-react-router';

import {
  getRootItemsIDs,
  getSubItems,
  normalizeTreeStructure
} from 'common/components/tree-layout/utils/helpers';
import { FieldTypesType, GroupType } from '@/common/types/report-bulder.ts';

import { flattenGroupsWithAccounts } from '@/store/report-setup/helpers.ts';
import { getBudgetingReportAction } from './actions.ts';

type GroupsType = {
  rootItems: number[];
  subItems: { [key: string]: number[] };
  data: { [key: string]: GroupType };
};

export interface ReportState {
  columns: FieldTypesType[];
  groups: GroupsType;
  isFetching: boolean;
}

type GetReportSetupActionParams = {
  columns: FieldTypesType[];
  data: GroupType[];
};

const INITIAL_STATE: ReportState = {
  columns: [],
  isFetching: false,
  groups: {
    rootItems: [],
    subItems: {},
    data: {}
  }
};

const slice = createSlice({
  name: 'budgeting-report',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getBudgetingReportAction.pending, state => {
        state.isFetching = true;

        return state;
      })
      .addCase(getBudgetingReportAction.rejected, state => {
        state.isFetching = false;

        return state;
      })
      .addCase(
        getBudgetingReportAction.fulfilled,
        (state, { payload }: PayloadAction<GetReportSetupActionParams>) => {
          const flattenGroups = flattenGroupsWithAccounts(payload.data);

          state.groups.rootItems = getRootItemsIDs(flattenGroups);
          state.groups.data = normalizeTreeStructure(flattenGroups);
          state.groups.subItems = getSubItems(flattenGroups);
          state.columns = payload.columns;
          state.isFetching = false;

          return state;
        }
      )
      .addCase(LOCATION_CHANGE, () => {
        return INITIAL_STATE;
      });
  }
});

export default slice.reducer;
