import { css } from '@emotion/react';

import {
  selectMenuBreadcrumbs,
  selectActivePostMenuId
} from 'common/components/beacon/store/selectors';
import { useSelector } from 'react-redux';
import Breadcrumbs from 'common/components/tree-layout/components/Breadcrumbs';

const PostBreadcrumbs = () => {
  const activePostMenuId = useSelector(selectActivePostMenuId);
  const breadcrumbs = useSelector(state =>
    activePostMenuId ? selectMenuBreadcrumbs(state, activePostMenuId) : []
  );

  if (!activePostMenuId) return <span className="fs-16">Beacon.Help Signals</span>;

  return (
    <Breadcrumbs
      css={style}
      activeID={activePostMenuId}
      breadcrumbs={breadcrumbs}
      maxLimit={2}
      render={menu => <span className="fs-16">{menu.title}</span>}
    />
  );
};

export default PostBreadcrumbs;

const style = css`
  .tree-breadcrumbs__preview {
    &.active {
      & > div {
        max-width: 7.75rem;
      }
    }
  }
`;
