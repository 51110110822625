import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_ACCOUNTING_ACCOUNTS'),
  ...asyncTypes('CREATE_ACCOUNTING_ACCOUNT'),
  ...asyncTypes('EDIT_ACCOUNTING_ACCOUNT'),
  ...asyncTypes('DELETE_ACCOUNTING_ACCOUNT'),
  SET_ACCOUNTING_ACCOUNT_FORM: 'SET_ACCOUNTING_ACCOUNT_FORM',
  SET_ACCOUNTING_ACCOUNT_PREVENTION_MODAL: 'SET_ACCOUNTING_ACCOUNT_PREVENTION_MODAL'
};

export default TYPES;
