import { combineReducers } from 'redux';
import profile from 'crew/store/profile/reducer';
import documents from 'crew/store/documents/reducer';
import documentTypes from './document-types/reducer';
import contracts from '../../crew/store/contracts/reducer';
import planning from './planning/reducer';
import evaluations from 'crew/store/evaluations/index';
import training from 'crew/store/training/reducer';
import skills from './skills/reducer';
import ranks from './ranks/reducer';
import wages from './wages/reducer';
import payroll from './payroll/reducer';
import medical from 'crew/store/medical/reducer';
import security from './security/slice';

export default combineReducers({
  profile,
  documents,
  documentTypes,
  contracts,
  planning,
  evaluations,
  training,
  skills,
  ranks,
  wages,
  payroll,
  medical,
  security
});
