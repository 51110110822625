import PropTypes from 'prop-types';

export const Store = PropTypes.shape({
  id: PropTypes.number,
  description: PropTypes.string,
  code: PropTypes.string,
  ihm_related: PropTypes.bool,
  color_hex_code: PropTypes.string,
  color_name: PropTypes.string
});

export const Field = PropTypes.shape({
  supplier_sort_index: PropTypes.number.isRequired,
  supplierRequisitionID: PropTypes.string.isRequired
});
