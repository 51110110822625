import CodeDigitsInput from 'common/components/form/inputs/CodeDigitsInput';
import SvgRender from 'common/components/general/SvgRender';
import PropTypes from 'prop-types';
import sms from 'common/assets/svg/common/sms.svg';

const FillAuthenticationCode = ({ onCodeChange, codeError }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <SvgRender src={sms} style={{ width: 48, height: 48 }} />
      <div className="fs-16 fs-hd-20 fw-medium text-dark mb-5 mt-4">Authentication code</div>
      <CodeDigitsInput digits={6} onChange={onCodeChange} error={codeError} />
      <div className="fs-14 fs-hd-16 fw-medium text-violet text-center mt-6">
        We just sent you a message via SMS with
        <br /> your authentication code. Enter the code in
        <br /> the form above to verify your identity.
      </div>
    </div>
  );
};

FillAuthenticationCode.propTypes = {
  onCodeChange: PropTypes.func,
  onClose: PropTypes.func,
  codeError: PropTypes.string
};

export default FillAuthenticationCode;
