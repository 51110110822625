import React from 'react';
import { useSelector } from 'react-redux';
import useRouter from 'use-react-router';

import { useActions } from 'utils/hooks';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import { selectWidgetPreventionModal, selectWidgetTitle } from 'store/dashboards/selectors';

import * as dashboardActions from 'store/dashboards/actions';

const WidgetPreventionModal = () => {
  const { match } = useRouter();

  const [setWidgetPreventionModal, deleteWidget, fetchDashboard, setWidgetSettingsForm] =
    useActions([
      dashboardActions.setWidgetPreventionModal,
      dashboardActions.deleteWidget,
      dashboardActions.fetchDashboard,
      dashboardActions.setWidgetSettingsForm
    ]);

  const modal = useSelector(selectWidgetPreventionModal);
  const title = useSelector(state => selectWidgetTitle(state, modal.activeID));

  const onDelete = async () => {
    try {
      await deleteWidget({ widgetId: modal.activeID });

      await fetchDashboard(match.params.id);
      await setWidgetSettingsForm({ isOpen: false, activeID: null });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <DangerousActionModal
      transparent
      action={'delete'}
      onAccept={onDelete}
      closeModal={() => setWidgetPreventionModal({ isOpen: false, activeID: null })}
      isOpen={modal.isOpen}
      actionText={'REMOVE'}
      header={'Remove Widget'}
      body={`Are you sure you want to Remove ${title}?`}
    />
  );
};

export default WidgetPreventionModal;
