export const parseVesselCalendarData = data => {
  return data.map(event => ({
    ...event,
    id: event.id,
    title: event.name,
    from: event.started_at,
    to: event.ended_at || event.started_at,
    bgClassName: `gantt-bg-${event?.type?.color}`,
    colorClassName: `gantt-color-${event?.type?.color}`
  }));
};
