import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectListOptionsFromStore } from 'store/lists/selectors';

import Select from 'common/components/form/inputs/Select';

const CompanySubTypeSelect = ({ value, onChange, companyTypeId }) => {
  const company_types = useSelector(state =>
    selectListOptionsFromStore(state, 'party-company-types')
  );

  const [companySubTypes, setCompanySubTypes] = useState([]);

  useEffect(() => {
    const filteredSubTypes = company_types.filter(type => type.id === companyTypeId);

    if (filteredSubTypes.length) {
      setCompanySubTypes(filteredSubTypes[0].subtypes);
    }
  }, [company_types, companySubTypes.length, companyTypeId]);

  if (!companySubTypes?.length) return null;

  return (
    <Select
      placeholder="Select sub-type"
      isMulti
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      value={value}
      options={companySubTypes}
      onChange={onChange}
      className="mb-0"
    />
  );
};

export default CompanySubTypeSelect;
