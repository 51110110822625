import React from 'react';
import { useSelector } from 'react-redux';
import { selectCount } from 'store/notifications/selectors';

const NotificationBadge = () => {
  const count = useSelector(selectCount);
  const getText = () => {
    if (count === 0 || count === '0') {
      return null;
    } else if (count <= 9) {
      return count;
    } else {
      return '9+';
    }
  };

  return (
    <div className="navbar-notifications-badge">
      <div className={`navbar-notifications-badge--box ${count > 0 ? 'filled' : ''}`}>
        <div className={`navbar-notifications-badge--box-bullet ${count > 0 ? 'filled' : ''}`} />
      </div>

      <div
        data-testid="nav-notification-badge"
        className="navbar-notifications-badge--count d-flex align-items-center justify-content-center fw-bold"
      >
        {getText()}
      </div>
    </div>
  );
};

export default NotificationBadge;
