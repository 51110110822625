import { put } from '@/api';
import { ReviewProcessAction } from '@/common/types/review-process';
import { TriggeredSystemTask } from '@/types/system-tasks';

export type ReplaceReviewProcessParams = {
  id: number;
};

export const replaceReviewProcess = async (params: ReplaceReviewProcessParams) => {
  const { id } = params;
  return (await put(`/review-processes/${id}/replace`)).data;
};

export const approveReviewProcess = async (params: { id: number }) => {
  return (await put(`/review-processes/${params.id}/approve`)).data;
};

export const rejectReviewProcess = async (params: { id: number }) => {
  return (await put(`/review-processes/${params.id}/reject`)).data;
};

export const bulkApproveReviewProcessSteps = async (params: {
  review_process_ids: number[];
  review_process_action: ReviewProcessAction;
}) => {
  const response = await put<TriggeredSystemTask>(`/review-processes/bulk-approve`, params);

  return response.data;
};
