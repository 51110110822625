import React, { useCallback, useMemo } from 'react';
import useRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';

import TextWithTooltip from 'common/components/general/TextWithTooltip';
import SvgRender from 'common/components/general/SvgRender';
import Bullet from 'common/components/general/Bullet';

import { selectActiveCategoryLabel } from 'store/notifications/selectors';
import { markNotificationAsRead, reduceUnreadCountbyType } from 'store/notifications/actions';
import { togglePanel } from 'store/sidebar/actions';

import getIcon from '../_icons';
import { getNotificationTimestamp, getNotificationEntityUrl, getTypeAlias } from '../_helpers';

const Notification = ({ notification, type }) => {
  const { history } = useRouter();
  const dispatch = useDispatch();
  const activeLabel = useSelector(selectActiveCategoryLabel);

  const createMarkup = useCallback(data => ({ __html: data }), []);

  const onClick = async () => {
    dispatch(togglePanel());

    const url = getNotificationEntityUrl(type, notification);
    if (url) history.push(url);
  };

  const onReadNotification = async e => {
    e.stopPropagation();
    const notification_ids = [notification.id];
    try {
      await dispatch(markNotificationAsRead({ notification_ids }));
      dispatch(reduceUnreadCountbyType(activeLabel));
    } catch (err) {
      console.error(err);
    }
  };

  const isUnread = _get(notification, 'read_at', null) === null;
  const timestamp = useMemo(
    () => getNotificationTimestamp(notification?.created_at, notification?.type),
    [notification?.created_at, notification?.type]
  );

  const { icon, svgClassName } = getIcon(type);

  return (
    <div
      className={`single-notification position-relative d-flex align-items-center ${
        isUnread ? 'single-notification--unread' : ''
      }`}
      onClick={onClick}
    >
      <div className="d-flex flex-column w-100p pe-4">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center flex-1">
            <SvgRender
              className={`me-1 text-violet ${svgClassName}`}
              src={icon}
              style={{ width: 12, height: 12 }}
            />

            <div className="text-violet fw-medium fs-10">{timestamp}</div>

            <div className="d-flex align-items-center fw-medium text-violet fs-10">
              <span className="cmx-4">|</span>
              {getTypeAlias(type) || ''}
            </div>
          </div>

          {isUnread ? <Bullet onClick={onReadNotification} color="royal-blue" showRipple /> : null}
        </div>

        <div className="single-notification--title overflow-hidden cmt-6">
          <TextWithTooltip>
            <span
              dangerouslySetInnerHTML={createMarkup(_get(notification, 'data.title', null))}
            ></span>
          </TextWithTooltip>
        </div>

        <div
          className="fs-12 fw-light text-truncate flex-1 cmt-4"
          dangerouslySetInnerHTML={createMarkup(_get(notification, 'data.body', null))}
        ></div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default Notification;
