import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import {
  selectWidgetStateData,
  selectWidgetStatePaging,
  selectActiveDashboardHasSidebar
} from 'store/dashboards/selectors';
import PersonBox from 'views/dashboard/widgets/types/persons/PersonBox';
import { getWidgetData, updateWidgetState } from 'store/dashboards/actions';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button } from 'reactstrap';
import TableSearchText from 'common/components/table/TableSearchText';
import { useDispatch } from 'react-redux';

const Persons = ({ id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const paging = useSelector(state => selectWidgetStatePaging(state, id));
  const data = useSelector(state => selectWidgetStateData(state, id));

  const hasSidebar = useSelector(selectActiveDashboardHasSidebar);

  const className = 'bg-white border-radius-5';

  const handlePageChange = async () => {
    try {
      setIsLoading(true);
      dispatch(
        updateWidgetState(
          {
            paging: {
              current_page:
                paging.current_page < paging.last_page
                  ? paging.current_page + 1
                  : paging.current_page
            }
          },
          id
        )
      );
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const onSearch = async text => {
    try {
      setIsLoading(true);
      dispatch(getWidgetData({ widgetId: id, paging: paging, search: text || undefined }));
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="w-100p">
      <WidgetTitle id={id} component={<TableSearchText label={'persons'} onSearch={onSearch} />} />

      <Row className="flex-1 pt-2">
        {data?.map(i => (
          <Col key={i} xs={hasSidebar ? 4 : 3} className="pe-0">
            <PersonBox key={i.id} item={i} className={className} />
          </Col>
        ))}
      </Row>

      <div className="d-flex justify-content-center mt-2 ms-auto">
        <Button
          onClick={handlePageChange}
          color="white"
          className="px-1 box-shadow-none"
          size="sm"
          disabled={paging.current_page === paging.last_page || isLoading}
        >
          <div className="fw-normal">
            Show <span className="fw-bold">12</span> more
          </div>
        </Button>
      </div>
    </div>
  );
};

Persons.propTypes = {
  id: PropTypes.number
};

export default Persons;
