import React from 'react';

const BinaryTabSwitch = ({ option1, option2, value, onChange, className, label, labelIcon }) => {
  const onOptionClick = option => {
    onChange(option);
  };

  return (
    <div className={`tool-tab-switch ${className ? className : ''}`}>
      <div className="d-flex align-items-center fs-10 mb-1">
        {labelIcon ? labelIcon : null}
        <span>{label}</span>
      </div>
      <div className="options-container d-flex align-items-center px-1 fw-bold">
        <div
          onClick={() => onOptionClick(option1)}
          className={`option px-1 d-flex align-items-center justify-content-center ${
            value === option1 ? 'option-selected' : ''
          }`}
        >
          {option1}
        </div>
        <div
          onClick={() => onOptionClick(option2)}
          className={`option px-1 d-flex align-items-center justify-content-center ${
            value === option2 ? 'option-selected' : ''
          }`}
        >
          {option2}
        </div>
      </div>
    </div>
  );
};

export default BinaryTabSwitch;
