import { overrideMatrixData, formatTrainingsTableData } from 'utils/crew/settings';

import TYPES from './types';

const INITIAL_STATE = {
  data: [],
  fetching: false,
  isSaving: false,
  is_onboard: 'onboard'
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_CREW_TRAINING_TYPE.START:
      return {
        ...state,
        fetching: true
      };
    case TYPES.GET_CREW_TRAINING_TYPE.SUCCESS:
      return {
        ...state,
        data: payload.data?.length
          ? formatTrainingsTableData({ data: payload.data })
          : formatTrainingsTableData({ data: payload }),
        fetching: false
      };
    case TYPES.GET_CREW_TRAINING_TYPE.ERROR:
      return {
        ...state,
        data: [],
        fetching: false
      };
    case TYPES.UPDATE_CREW_TRAINING_TYPE_SCHEDULE.START:
      const { months, ...rest } = payload.params;

      return {
        ...state,
        isSaving: true,
        data: overrideMatrixData({ data: state.data, id: payload.params.id, newData: rest })
      };
    case TYPES.UPDATE_CREW_TRAINING_TYPE_SCHEDULE.SUCCESS:
      return {
        ...state,
        isSaving: false,
        data: overrideMatrixData({
          data: state.data,
          id: payload.id,
          newData: { months: payload.training_schedule.months }
        })
      };
    case TYPES.UPDATE_CREW_TRAINING_TYPE_SCHEDULE.ERROR:
      return {
        ...state,
        data: [...state.data],
        isSaving: false
      };
    case TYPES.RESET_ROW_MONTHS:
      return {
        ...state,
        data: overrideMatrixData({
          data: state.data,
          id: payload.id,
          newData: {
            months: {
              april: false,
              august: false,
              december: false,
              february: false,
              january: false,
              july: false,
              june: false,
              march: false,
              may: false,
              november: false,
              october: false,
              september: false
            }
          }
        })
      };

    case TYPES.REORDER_CREW_TRAINING_TYPE.START:
      return {
        ...state,
        data: payload.newData
      };

    case TYPES.STOP_AUTO_SAVING:
      return {
        ...state,
        isSaving: false
      };

    case TYPES.RESET_CREW_TABLE_DATA:
      return {
        ...state,
        data: []
      };

    default:
      return state;
  }
};

export default reducer;
