import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('CREATE_MANUAL'),
  ...asyncTypes('EDIT_MANUAL'),
  ...asyncTypes('GET_MANUAL'),
  ...asyncTypes('GET_MANUAL_CHAPTERS'),
  ...asyncTypes('CREATE_NEW_CHAPTER'),
  ...asyncTypes('RENAME_CHAPTER'),
  ...asyncTypes('DELETE_CHAPTER'),
  ...asyncTypes('REARRANGE_CHAPTER'),
  ...asyncTypes('MOVE_CHAPTER'),
  ...asyncTypes('CHANGE_CHAPTER_VESSELS'),
  ...asyncTypes('GET_CHAPTER_POST'),
  ...asyncTypes('CREATE_CHAPTER_POST'),
  ...asyncTypes('EDIT_CHAPTER_POST'),
  ...asyncTypes('COMPARE_AT_VERSION_CHAPTER_POST'),
  ...asyncTypes('COMPARE_MANUAL_VERSIONS'),
  ...asyncTypes('SEARCH_IN_MANUAL_CHAPTERS'),
  ...asyncTypes('ACKNOWLEDGE_MANUAL_VERSION'),
  ...asyncTypes('GET_MANUAL_VERSION_ACKNOWLEDGEMENTS'),
  ...asyncTypes('DOWNLOAD_MANUAL_UPLOADED_FILES'),
  ...asyncTypes('ARCHIVE_MANUAL'),
  ...asyncTypes('UNARCHIVE_MANUAL'),
  ...asyncTypes('DOWNLOAD_MANUAL_ATTACHMENTS'),
  SHOW_MANUAL_MODAL_FORM: 'SHOW_MANUAL_MODAL_FORM',
  SET_MANUAL_MODAL_FORM_ACTIVE: 'SET_MANUAL_MODAL_FORM_ACTIVE'
};

export default TYPES;
