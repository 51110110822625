import { displayInstructedValue } from 'captain-reports/templates/components/helpers';
import { getTimeValue } from 'captain-reports/templates/inputs/TimeInput';
import {
  validateSpeedOG,
  validateConsumptions
} from 'views/the-fleet/components/position-list/card/components/body/info/detailed-info/fields-list/configs/validations';
import { columns } from './tableConfig';
import { numberToStr } from 'common/utils/numbers';
import _get from 'lodash/get';
import VesselCiiReferenceYearlyTooltip from 'common/components/general/VesselCiiReferenceYearlyTooltip';

const formattedFieldKeys = [
  'average_instructed_speed',
  'average_instructed_consumption',
  'steaming_time',
  'total_consumption_rate',
  'total_consumption',
  'speed_og',
  'distance_run',
  'cii'
];

const getTotalFieldDisplayView = (fieldKey, totalsKey, totals) => {
  const value = totals[totalsKey][fieldKey];
  let display = null;
  let validationColor = null;

  if (fieldKey === 'average_instructed_speed') {
    const { instructed_speed_minimum_allowance } = totals[totalsKey];
    const average_instructed_speed = value;

    display = displayInstructedValue(
      average_instructed_speed,
      instructed_speed_minimum_allowance,
      null,
      2
    );
  } else if (fieldKey === 'average_instructed_consumption') {
    const { instructed_consumption_maximum_allowance } = totals[totalsKey];
    const average_instructed_consumption = value;

    display = displayInstructedValue(
      average_instructed_consumption,
      instructed_consumption_maximum_allowance,
      null,
      2
    );
  } else if (fieldKey === 'steaming_time') {
    display = getTimeValue(+value);
  } else if (fieldKey === 'speed_og' && totalsKey !== 'averages') {
    validationColor = validateSpeedOG(
      value,
      totals[totalsKey],
      'instructed_speed_minimum_allowance'
    );

    display = <span className={validationColor}>{value}</span>;
  } else if (fieldKey === 'total_consumption_rate' && totalsKey !== 'averages') {
    validationColor = validateConsumptions(
      value,
      totals[totalsKey],
      'instructed_consumption_maximum_allowance'
    );

    display = <span className={validationColor}>{value}</span>;
  } else if (fieldKey === 'cii') {
    const rating = _get(totals[totalsKey], 'rating');
    const boundaries = _get(totals[totalsKey], 'reference.boundaries', []);
    const year = _get(totals[totalsKey], 'reference.year');
    const refenceCii = _get(totals[totalsKey], 'reference.cii');

    display = (
      <VesselCiiReferenceYearlyTooltip
        className="w-100p justify-content-end"
        boundaries={boundaries}
        year={year}
        cii={refenceCii}
        placement="top"
        hideArrow={true}
        renderComponent={() => (
          <div className="d-flex align-items-center">
            <strong className={`cii-rating-${rating}`}>{rating}</strong>
            {rating ? <div className="cms-4 cme-4">-</div> : ''}
            <strong>{value === 0 ? 0 : numberToStr(value, 2, 2)}</strong>
          </div>
        )}
      />
    );
  } else if (fieldKey === 'total_consumption' && totalsKey === 'good_weather_averages') {
    validationColor = validateConsumptions(
      value,
      totals[totalsKey],
      'instructed_consumption_maximum_allowance'
    );
  }

  return { validationColor, display };
};

const parseTotals = totals => {
  let values = {};

  Object.keys(totals).forEach(totalsKey => {
    values[totalsKey] = { ...totals[totalsKey] };

    formattedFieldKeys.forEach(fieldKey => {
      const { display, validationColor } = getTotalFieldDisplayView(fieldKey, totalsKey, totals);

      values[totalsKey][`${fieldKey}_display`] = display;
      values[totalsKey][`${fieldKey}_validation_color`] = validationColor;
    });
  });

  return values;
};

const parseColumns = totals => {
  return columns.map(column => {
    const formattedColumn = { ...column };

    if (formattedFieldKeys.includes(column.key)) {
      const secondKeyValidationColor = column.second_footer_total_key.endsWith('_display')
        ? column.second_footer_total_key.replace('_display', '_validation_color')
        : `${column.second_footer_total_key}_validation_color`;

      formattedColumn.second_footer_total_label_color = _get(totals, secondKeyValidationColor);

      const thirdKeyValidationColor = column.third_footer_total_key.endsWith('_display')
        ? column.third_footer_total_key.replace('_display', '_validation_color')
        : `${column.third_footer_total_key}_validation_color`;

      formattedColumn.third_footer_total_label_color = _get(totals, thirdKeyValidationColor);
    }

    return formattedColumn;
  });
};

const parseWeatherAnalysis = data => {
  const {
    results,
    averages,
    bad_weather_averages,
    bad_weather_totals,
    good_weather_averages,
    good_weather_totals,
    totals,
    total_days
  } = data?.data || {};

  const formattedTotals = parseTotals({
    totals,
    averages,
    bad_weather_averages,
    bad_weather_totals,
    good_weather_averages,
    good_weather_totals
  });

  const formattedColumns = parseColumns(formattedTotals);

  return {
    data: results,
    totals: {
      ...formattedTotals,
      total_days
    },
    columns: formattedColumns
  };
};

export default parseWeatherAnalysis;
