import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_PAYROLL_ACCOUNT_BALANCES'),
  ...asyncTypes('GET_PAYROLL_PERIODS'),
  ...asyncTypes('GET_CREW_MGA_ACCOUNT'),
  ...asyncTypes('GET_CREW_BANK_ACCOUNTS'),
  ...asyncTypes('UPDATE_BANK_ACCOUNT'),
  ...asyncTypes('CREATE_BANK_ACCOUNT'),
  SET_CURRENT_PERIOD: 'SET_CURRENT_PERIOD',
  SET_CURRENT_CONTRACT: 'SET_CURRENT_CONTRACT'
};

export default TYPES;
