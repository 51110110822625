import { createSelector } from 'reselect';

const selectSockets = state => state.sockets;

export const selectChannelPrefix = createSelector(
  [selectSockets],
  sockets => sockets.channelPrefix
);

export const selectNotificationChannel = createSelector(
  [selectSockets],
  sockets => sockets.notificationChannel
);
