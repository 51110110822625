import SendSms from './SendSms';
import FillCode from './FillCode';
import PropTypes from 'prop-types';
import { useState } from 'react';

const Sms2Fa = ({ ...props }) => {
  const [otpIsRequested, setOtpIsRequested] = useState(false);

  return (
    <div className="login-page__sms d-flex flex-column justify-content-center align-items-center h-100p">
      <h1 className="text-center gotham-normal position-absolute">Two-factor authentication</h1>

      <div className="login-page__sms-container">
        {otpIsRequested ? (
          <FillCode {...props} />
        ) : (
          <SendSms onSendCallback={() => setOtpIsRequested(true)} />
        )}
      </div>
    </div>
  );
};

Sms2Fa.propTypes = {
  code: PropTypes.object,
  setCode: PropTypes.func,
  onLogin: PropTypes.func,
  disabled: PropTypes.bool
};

export default Sms2Fa;
