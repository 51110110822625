import { LOCATION_CHANGE } from 'connected-react-router';
import TYPES from './types';

const INITIAL_STATE = {
  profile: null,
  employees: [],
  agentHires: [],
  account: null,
  agentLoading: false,
  accountLoading: false
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_COMPANY.START:
      return {
        ...state
      };

    case TYPES.GET_COMPANY.ERROR:
      return {
        profile: null
      };

    case TYPES.GET_COMPANY.SUCCESS:
      return {
        ...state,
        profile: payload
      };

    case TYPES.UPDATE_COMPANY.SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, ...payload }
      };

    case TYPES.RESET_PROFILE:
      return {
        ...state,
        profile: null,
        agentHires: [],
        employees: [],
        agentLoading: false
      };

    case TYPES.GET_AGENT_HIRES.START:
      return {
        ...state,
        agentHires: [],
        agentLoading: true
      };
    case TYPES.GET_AGENT_HIRES.ERROR:
      return {
        ...state,
        agentHires: []
      };

    case TYPES.GET_AGENT_HIRES.SUCCESS:
      return {
        ...state,
        agentHires: payload,
        agentLoading: false
      };

    case TYPES.GET_COMPANY_EMPLOYEES.START:
    case TYPES.GET_COMPANY_EMPLOYEES.ERROR:
      return {
        ...state,
        employees: []
      };

    case TYPES.GET_COMPANY_EMPLOYEES.SUCCESS:
      return {
        ...state,
        employees: payload
      };

    case TYPES.CREATE_AGENT_HIRE.SUCCESS:
      return {
        ...state,
        agentHires: [...state.agentHires, payload]
      };

    case TYPES.UPDATE_PERSON.SUCCESS:
      return {
        ...state,
        profile: state.profile ? { ...state.profile, ...payload } : payload
      };

    case TYPES.UPDATE_AGENT_HIRE.SUCCESS:
      return {
        ...state,
        agentHires: state.agentHires.map(hire => (hire.id === payload.id ? payload : hire))
      };

    case TYPES.DELETE_AGENT_HIRE.SUCCESS:
      return {
        ...state,
        agentHires: state.agentHires.filter(hire => hire.id !== payload.id)
      };

    case TYPES.CREATE_CONNECTED_COMPANIES.SUCCESS:
      return {
        ...state,
        connectedCompanies: payload.connected_companies
      };

    case TYPES.GET_CONNECTED_COMPANIES.START:
      return {
        ...state,
        // connectedCompanies: [],
        connectedCompaniesLoading: true
      };
    case TYPES.GET_CONNECTED_COMPANIES.ERROR:
      return {
        ...state,
        connectedCompanies: [],
        connectedCompaniesLoading: false
      };

    case TYPES.GET_CONNECTED_COMPANIES.SUCCESS:
      return {
        ...state,
        connectedCompanies: payload,
        connectedCompaniesLoading: false
      };

    case TYPES.GET_COMPANY_ACCOUNT.START:
      return {
        ...state,
        accountLoading: true,
        account: null
      };

    case TYPES.GET_COMPANY_ACCOUNT.SUCCESS:
      return {
        ...state,
        accountLoading: false,
        account: payload?.data
      };

    case TYPES.GET_COMPANY_ACCOUNT.ERROR:
      return {
        ...state,
        accountLoading: false
      };

    case TYPES.UPDATE_COMPANY_ACCOUNT.SUCCESS:
      return {
        ...state,
        account: payload
      };

    case LOCATION_CHANGE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
