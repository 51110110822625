import React from 'react';

import {
  selectWidgetPreferences,
  selectWidgetStateData,
  selectWidgetStatePaging
} from 'store/dashboards/selectors';
import { useSelector } from 'react-redux';
import Preferences from './Preferences';
import WidgetActions from 'views/dashboard/components/WidgetActions';
import { Col, Row } from 'reactstrap';
import BookmarkLine from './components/BookmarkLine';
import PaginationNavigation from 'common/components/table/main/PaginationNavigation';
import { useActions } from 'utils/hooks';
import * as dashboardsActions from 'store/dashboards/actions';

const BookmarksCardWidget = ({ id }) => {
  const bookmarksData = useSelector(state => selectWidgetStateData(state, id));
  const preferences = useSelector(state => selectWidgetPreferences(state, id));
  const paging = useSelector(state => selectWidgetStatePaging(state, id));

  const [updateWidgetState] = useActions([dashboardsActions.updateWidgetState]);

  const handlePageChange = async ({ page }) => {
    updateWidgetState({ paging: { ...paging, current_page: page } }, id);
  };

  return (
    <div className="d-flex flex-column widget-default-card widget-layout-card position-relative cpy-12 px-3 w-100p">
      <WidgetActions className="me-2" id={id} />

      <span className="fs-14 fw-medium text-primary">
        {preferences?.custom_title || 'Bookmarks'}
      </span>

      {bookmarksData.length ? (
        <Row className="flex-1 pt-2">
          <Col xs={6}>
            {bookmarksData.map((bookmark, index) =>
              index < paging.per_page / 2 ? <BookmarkLine bookmark={bookmark} /> : null
            )}
          </Col>

          <Col xs={6}>
            {bookmarksData.map((bookmark, index) =>
              index >= paging.per_page / 2 ? <BookmarkLine bookmark={bookmark} /> : null
            )}
          </Col>
        </Row>
      ) : null}

      <div className="mt-auto ms-auto">
        <PaginationNavigation paging={paging} customTableFetch={handlePageChange} />
      </div>
    </div>
  );
};

export const BookmarksPreferences = Preferences;

export default BookmarksCardWidget;
