import React from 'react';
import ToggleFavourite from 'components/general/ToggleFavourite';
import PageTitle from 'common/components/general/PageTitle';
import BoxShadowButton from 'common/components/buttons/BoxShadowButton';
import { Button } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectActiveDashboard,
  selectActiveDashboardHasWidgets,
  selectActiveDashboardIsShared
} from 'store/dashboards/selectors';

import widgets from 'common/assets/svg/common/widgets.svg';

import * as favoritesActions from 'store/favorites/actions';
import * as dashboardsActions from 'store/dashboards/actions';

const DashboardActions = () => {
  const activeDashboard = useSelector(selectActiveDashboard);
  const hasWidgets = useSelector(selectActiveDashboardHasWidgets);
  const isDashboardShared = useSelector(selectActiveDashboardIsShared);

  const [toggleFavorite, setDashboardForm, setDashboardWidgetsForm] = useActions([
    favoritesActions.toggleFavorite,
    dashboardsActions.setDashboardForm,
    dashboardsActions.setDashboardWidgetsForm
  ]);

  if (!activeDashboard) return null;

  return (
    <div className="dashbord-page__actions d-flex align-items-center">
      <PageTitle
        className="mb-0"
        title={activeDashboard?.title || 'Dashboard'}
        render={() => (
          <div className="d-flex align-items-center">
            <h1 className="fw-bold fs-24 mt-0 mb-0">{activeDashboard?.title || 'Dashboard'}</h1>
            {activeDashboard?.ownerName ? (
              <div className="height-18 fs-10 text-primary d-flex align-items-center ms-3 shared-dashboard-label">
                Created by&nbsp;<strong>{activeDashboard.ownerName}</strong>
              </div>
            ) : null}
          </div>
        )}
      />

      <div className="ms-auto d-flex align-items-center">
        <ToggleFavourite
          isFavourite={activeDashboard?.is_favourite}
          toggle={() =>
            toggleFavorite({ favourite_id: activeDashboard.id, favourite_type: 'dashboard' })
          }
        />

        {!isDashboardShared && hasWidgets ? (
          <Button
            color="primary"
            className="cps-12 cpe-12 ms-3"
            onClick={() => setDashboardWidgetsForm({ isOpen: true })}
          >
            <SvgRender style={{ width: 12, height: 12 }} src={widgets} className="me-1" />
            Widgets
          </Button>
        ) : null}

        {!isDashboardShared ? (
          <>
            <div className="border-start ms-3 ps-3 py-2"></div>
            <BoxShadowButton
              size="sm"
              className="border-0 text-primary"
              onClick={() => setDashboardForm({ isOpen: true, active: activeDashboard })}
            >
              Manage
            </BoxShadowButton>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default DashboardActions;
