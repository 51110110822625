import React from 'react';
import { components } from 'react-select';
import { UncontrolledTooltip } from 'reactstrap';
import info from 'common/assets/svg/common/info.svg';
import SvgRender from 'common/components/general/SvgRender';
import { numberToStr } from 'common/utils/numbers';

const TooltipContext = ({ vessel }) => {
  if (!vessel) return null;
  const cargoBaleCapacity = +vessel.cargo_bale_capacity || '-';
  const cargoGrainCapacity = +vessel.cargo_grain_capacity || '-';
  const draftSummer = +vessel.draft_summer || '-';
  const dwtSummer = +vessel.dwt_summer || '-';
  const freshWaterAllowance = +vessel.fresh_water_allowance || '-';
  const lightShip = +vessel.light_ship || '-';
  const lightShipDraft = +vessel.lightship_draft || '-';
  const sswTpc = +vessel.ssw_tpc || '-';

  return (
    <div className="d-flex flex-column align-items-start">
      <div className="fs-12 mb-1">
        Summer DWT: <span className="fw-bold">{numberToStr(dwtSummer)} mt</span>
      </div>
      <div className="fs-12 mb-1">
        Summer Draft: <span className="fw-bold">{numberToStr(draftSummer)} m</span>
      </div>
      <div className="fs-12 mb-1">
        T.P.C on SSW: <span className="fw-bold">{numberToStr(sswTpc)} mt/cm</span>
      </div>
      <div className="fs-12 mb-1" style={{ textAlign: 'left' }}>
        Fresh water allowance:
        <br />
        <span className="fw-bold">{numberToStr(freshWaterAllowance)} mm</span>
      </div>
      <div className="fs-12 mb-1" style={{ textAlign: 'left' }}>
        Holds capacity grain: <br />
        <span className="fw-bold ">
          {numberToStr(cargoGrainCapacity)}
          <span className="ms-1">
            m<sup>3</sup>
          </span>
        </span>
      </div>
      <div className="fs-12 mb-1" style={{ textAlign: 'left' }}>
        Holds capacity Bale: <br />
        <span className="fw-bold">
          {numberToStr(cargoBaleCapacity)}
          <span className="ms-1">
            m<sup>3</sup>
          </span>
        </span>
      </div>
      <div className="fs-12 mb-1">
        LightShip: <span className="fw-bold">{numberToStr(lightShip)} mt</span>
      </div>
      <div className="fs-12 mb-1">
        LightShip draft: <span className="fw-bold">{numberToStr(lightShipDraft)} m</span>
      </div>
    </div>
  );
};

const ValueContainer = ({ children, ...props }) => {
  let vessel = {};
  let value = props.getValue();
  if (value.length > 0) {
    vessel = value[0];
  }

  return (
    <div className="d-flex align-items-center w-100p">
      {props.hasValue ? (
        <div
          className="cursor-pointer me-1 ms-1 text-yellow tool-select-icon"
          id="tooltip-dwt-vessel-select"
        >
          <SvgRender src={info} style={{ width: 14, height: 14 }} />
          <UncontrolledTooltip target="tooltip-dwt-vessel-select">
            <TooltipContext vessel={vessel} />
          </UncontrolledTooltip>
        </div>
      ) : null}

      <components.ValueContainer {...props}>{children}</components.ValueContainer>
    </div>
  );
};

export default { ValueContainer };
