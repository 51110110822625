import { Button } from 'reactstrap';
import { useAppSelector } from '@/store/hooks';
import { selectActiveRequisition } from 'common/components/purchasing/requisition/store/selectors';
import { Attachment } from '@/ts-common/types/files';
import { FC } from 'react';

import ShadowBox from 'common/components/general/ShadowBox';
import SvgRender from 'common/components/general/SvgRender';
import UploadFiles from '@/ts-common/components/form/inputs/upload-files';
import vessels from 'common/assets/svg/common/vessels.svg';
import company from 'common/assets/svg/common/company.svg';
import sync from 'common/assets/svg/actions/sync.svg';

type EmailAttachmentsSyncProps = {
  attachments: Attachment[];
  onChange: (attachments: Attachment[]) => void;
  type: 'office' | 'vessel';
};

const EmailAttachmentsSync: FC<EmailAttachmentsSyncProps> = ({ attachments, onChange, type }) => {
  const activeRequisition = useAppSelector(selectActiveRequisition);
  const hasAttachments = attachments.length > 0;

  const onSync = () => {
    if (type === 'vessel') {
      onChange(activeRequisition?.vessel_attachments || []);
    } else {
      onChange(activeRequisition?.attachments || []);
    }
  };

  const onRemoveAttachment = (attachment: Attachment) => {
    onChange(attachments.filter(a => a.id !== attachment.id));
  };

  return (
    <ShadowBox
      className="d-flex justify-content-between align-items-center cpt-6 cpb-6 px-1 min-h-40 h-100"
      flat
    >
      {hasAttachments ? (
        <div className="d-flex flex-wrap">
          {attachments.map(attachment => (
            <UploadFiles
              key={attachment.id}
              files={attachment}
              size="xs"
              singleUpload
              className="cme-4 cmy-2"
              onChange={() => onRemoveAttachment(attachment)}
            />
          ))}
        </div>
      ) : (
        <Button
          color="white"
          type="button"
          onClick={onSync}
          className="px-1 border-radius-3 shadow-none height-24 d-flex align-items-center"
          style={{ top: 8, right: 8 }}
          disabled={
            type === 'vessel'
              ? !activeRequisition?.vessel_attachments?.length
              : !activeRequisition?.attachments?.length
          }
        >
          <div className="d-flex align-items-center text-primary">
            <SvgRender src={sync} className="me-1" style={{ width: 14, height: 14 }} />
            <span className="fs-10 text-primary">
              Sync {type === 'vessel' ? 'Vessel`s' : 'Office`s'} Attachments
            </span>
          </div>
        </Button>
      )}
      <div className={`${hasAttachments ? 'border-start ps-1' : ''}`}>
        <SvgRender
          src={type === 'vessel' ? vessels : company}
          style={{ width: 14, height: 14 }}
          className="text-violet"
        />
      </div>
    </ShadowBox>
  );
};

export default EmailAttachmentsSync;
