import React, { useCallback, useEffect, useState } from 'react';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';

import * as vesselActions from 'store/vessels/actions';
import _get from 'lodash/get';
import moment from 'moment';
import PageLoader from 'common/components/general/PageLoader';
import VesselTCE from './VesselTCE';
import { selectListDefaultOptions } from 'store/lists/selectors';

const TceResults = ({ showAllFilters, ...rest }) => {
  const profile = useSelector(state => state.vessels.profile);
  const tceResultsFilters = useSelector(state => state.vessels.tceResultsFilters);
  const chartData = useSelector(state => state.vessels.tceResultsChartData);
  const loading = useSelector(state => state.vessels.loading);
  const vessels = useSelector(state => selectListDefaultOptions(state, 'vessels'));

  const [tceResults, setTceResults] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const [dates, setDates] = useState({
    from:
      tceResultsFilters && tceResultsFilters.date_from
        ? moment(tceResultsFilters.date_from)
        : moment().subtract(12, 'month').startOf('month'),
    to:
      tceResultsFilters && tceResultsFilters.date_to ? moment(tceResultsFilters.date_to) : moment()
  });

  const [view, setView] = useState(_get(tceResultsFilters, 'type', 'monthly'));
  const [marketIndex, setMarketIndex] = useState(() => {
    let defaultIndex = profile.market_indexes
      ? profile.market_indexes.find(index => index.is_default)
      : null;
    if (!defaultIndex) {
      defaultIndex = _get(profile, 'market_indexes[0]', null);
    }

    return _get(defaultIndex, 'id', null);
  });

  const [vessel, setVessel] = useState(vessels?.[0]);

  const [getTceResults] = useActions([vesselActions.getTceResults]);

  const indexOptions = profile.market_indexes
    ? profile.market_indexes.map(el => ({ id: el.id, name: el.name }))
    : [];

  const getRequestParams = useCallback(
    (name, value) => {
      const params = {
        vessel_id: vessel?.id,
        market_index_id: marketIndex || undefined,
        date_from: dates.from.format('YYYY-MM-DD'),
        date_to: dates.to.format('YYYY-MM-DD'),
        type: view
      };

      if (name && value) {
        params[name] = value;
      }

      return params;
    },
    [dates, vessel?.id, marketIndex, view]
  );

  const fetchTceResults = useCallback(
    async params => {
      try {
        setIsFetching(true);
        const { sorting, table } = params;
        const requestParams = { ...getRequestParams(), sorting, table };

        const res = await getTceResults(requestParams);
        setTceResults(res.data);
        setIsFetching(false);
        return res.data;
      } catch (e) {
        console.error(e);
        setIsFetching(false);
      }
    },
    [getTceResults, getRequestParams]
  );

  const onViewChange = value => {
    setView(value);
  };

  const onMarketIndexSelectChange = value => {
    setMarketIndex(value);
  };

  const onVesselChange = value => {
    setVessel(value);
    setMarketIndex(null);
  };

  const onDateChange = (date, type) => {
    setDates(dates => ({
      ...dates,
      [type === 'date_from' ? 'from' : 'to']: date
    }));
  };

  useEffect(() => {
    setVessel(vessels?.[0]);
  }, [vessels]);

  return (
    <>
      <VesselTCE
        dates={dates}
        onDateChange={onDateChange}
        marketIndex={marketIndex}
        indexOptions={indexOptions}
        onMarketIndexSelectChange={onMarketIndexSelectChange}
        chartData={chartData}
        tceResults={tceResults}
        view={view}
        onViewChange={onViewChange}
        fetchTceResults={fetchTceResults}
        loading={loading}
        profile={profile}
        vessel={vessel}
        vesselId={profile?.id || vessel?.id}
        onVesselChange={onVesselChange}
        showAllFilters={showAllFilters}
        {...rest}
      />

      <PageLoader isLoading={isFetching} />
    </>
  );
};

export default TceResults;
