import { useSelector } from 'react-redux';

import WidgetDefaultCard from 'views/dashboard/components/WidgetDefaultCard';
import paths from 'routing/routes/_paths';
import PropTypes from 'prop-types';

import { selectWidgetStateData } from 'store/dashboards/selectors';

const FlagDispensationsCardWidget = ({ id }) => {
  const data = useSelector(state => selectWidgetStateData(state, id));

  return (
    <WidgetDefaultCard
      id={id}
      count={data.jobs_count}
      linkTo={paths.flag_extensions_dispensations_jobs}
    />
  );
};

FlagDispensationsCardWidget.propTypes = {
  id: PropTypes.number
};

export default FlagDispensationsCardWidget;
