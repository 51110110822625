import Image from './thumbnails/Image';
import UnknownType from './thumbnails/UnknownType';
import { FC } from 'react';
import { Attachment } from '@/ts-common/types/files';
import { getFileType } from '@/ts-common/utils/files';

const getComponentType = (type: string): FC<{ file: Attachment }> => {
  switch (type) {
    case 'image':
      return Image;
    default:
      return UnknownType;
  }
};

type GalleryFooterProps = {
  files: Attachment[];
  selectedFile: Attachment;
  onClickItem: (index: number) => void;
};

const GalleryFooter: FC<GalleryFooterProps> = ({ files, selectedFile, onClickItem }) => {
  const leftOffset = files.findIndex(file => file.id === selectedFile.id) * (64 + 12); // Index * Width + Margin

  return (
    <div style={{ transform: `translateX(-${leftOffset}px)` }} className="gallery__footer">
      {files.map((file, i) => (
        <GalleryFooterItem
          file={file}
          key={i.toString()}
          isActive={file.id === selectedFile.id}
          onClickItem={onClickItem}
          index={i}
        />
      ))}
    </div>
  );
};

type GalleryFooterItemProps = {
  file: Attachment;
  isActive: boolean;
  onClickItem: (index: number) => void;
  index: number;
};

const GalleryFooterItem: FC<GalleryFooterItemProps> = ({ file, isActive, onClickItem, index }) => {
  const fileType = getFileType(file.mime_type, file.extension);
  const FilePreview = getComponentType(fileType.type);

  return (
    <div
      onClick={() => onClickItem(index)}
      className={`gallery__footer--item ${isActive ? 'gallery__footer--item-active' : ''}`}
    >
      <FilePreview file={file} />
    </div>
  );
};

export default GalleryFooter;
