import TYPES from './types';

const INITIAL_STATE = {
  data: [],
  oldData: [],
  fetching: false,
  hasError: false,
  active: null
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_CREW_RANKS.START:
      return {
        ...state,
        fetching: true,
        hasError: false
      };

    case TYPES.GET_CREW_RANKS.SUCCESS:
      return {
        ...state,
        data: payload,
        fetching: false,
        hasError: false
      };

    case TYPES.GET_CREW_RANKS.ERROR:
      return {
        ...state,
        data: [],
        fetching: false,
        hasError: true
      };

    case TYPES.REORDER_CREW_RANK.START:
      return {
        ...state,
        data: payload.newData,
        oldData: state.data
      };

    case TYPES.REORDER_CREW_RANK.SUCCESS:
      return {
        ...state,
        oldData: []
      };

    case TYPES.REORDER_CREW_RANK.ERROR:
      return {
        ...state,
        data: state.oldData,
        oldData: []
      };

    case `${TYPES.GET_CREW_RANK}/pending`:
      return {
        ...state,
        active: null
      };

    case `${TYPES.GET_CREW_RANK}/fulfilled`:
      return {
        ...state,
        active: payload
      };

    default:
      return state;
  }
};

export default reducer;
