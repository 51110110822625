import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import Priority from 'common/components/general/Priority';
import Status from 'common/components/general/Status';
import Departments from 'common/components/general/Departments';
import FormFileIcon from 'common/components/forms/_components/FormFileIcon';
import eyeSvg from 'common/assets/svg/common/eye.svg';

import paths from 'routing/routes/_paths';
import { useSelector } from 'react-redux';
import { selectWidgetState } from 'store/dashboards/selectors';

import moment from 'moment';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import { Col, Row } from 'reactstrap';
import ActionableRemarksLabel from 'common/components/forms/_components/ActionableRemarksLabel';
import PendingSignatureRequirementsLabel from 'common/components/forms/_components/PendingSignatureRequirementsLabel';

const Forms = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));

  return (
    <div>
      <WidgetTitle id={id} linkTo={paths.forms} />
      <WidgetDefaultTable
        id={id}
        columns={[
          {
            header: 'Title',
            key: 'name',
            width: 5,
            headerClassName: 'text-violet'
          },

          {
            header: 'Department',
            key: 'department',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Vessel',
            key: 'vessel',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Status',
            key: 'status_id',
            type: 'collection',
            headerClassName: 'text-violet',
            minWidth: 217,
            maxWidth: 217
          },
          {
            header: 'Form Date',
            key: 'form_date',
            type: 'date',
            headerClassName: 'text-violet'
          },
          {
            header: 'Actions',
            key: 'actions',
            field: 'actions'
          }
        ]}
        rows={{
          name: data => {
            const {
              review_remarks,
              review_remarks_actionable,
              for_correction_remarks,
              for_correction_remarks_actionable
            } = data;

            return (
              <Row>
                <Col className="d-flex align-items-center justify-content-between max-width-72 min-width-72">
                  {data.form?.importance_id ? (
                    <Priority
                      className="d-flex align-items-center"
                      value={data.form.importance_id}
                    />
                  ) : (
                    <div />
                  )}
                  <strong className="fs-10"> {data.id}</strong>
                </Col>
                <Col xs={8} className="d-flex align-items-center">
                  <Row className="w-100p flex-nowrap" noGutters>
                    <Col xs="auto" className="pe-2">
                      <TextWithTooltip className="d-flex align-items-center">
                        {data.form?.name}
                      </TextWithTooltip>
                    </Col>
                    <Col xs="auto">
                      <ActionableRemarksLabel
                        reviewRemarks={review_remarks}
                        reviewRemarksActionable={review_remarks_actionable}
                        forCorrectionRemarks={for_correction_remarks}
                        forCorrectionRemarksActionable={for_correction_remarks_actionable}
                      />

                      <ActionableRemarksLabel
                        reviewRemarks={review_remarks}
                        reviewRemarksActionable={review_remarks_actionable}
                        forCorrectionRemarks={for_correction_remarks}
                        forCorrectionRemarksActionable={for_correction_remarks_actionable}
                        isForRegularRemarks={true}
                      />
                    </Col>
                    <Col xs="auto">
                      <PendingSignatureRequirementsLabel
                        count={data.pending_signature_requirements_count}
                        formSubmissionId={data?.id}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={1} className="d-flex justify-content-center ms-auto">
                  <FormFileIcon
                    className="ms-auto"
                    formType={data?.form?.type}
                    id={data?.id}
                    file={data?.file}
                  />
                </Col>
              </Row>
            );
          },
          department: data => <Departments values={[data.form?.department]} />,
          vessel: data => <div>{data?.vessel?.name}</div>,
          status_id: data => <Status value={data.status} />,
          form_date: data => moment(data.submitted_at, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          actions: data => ({
            options: [
              {
                label: 'View',
                icon: eyeSvg,
                onClick: () => window.open(`${paths.forms}/${data.id}`)
              }
            ]
          })
        }}
        label={label}
        state={state}
      />
    </div>
  );
};

export default Forms;
