import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_VESSEL_ITINERARIES'),
  ...asyncTypes('GET_VESSEL_CREW'),
  ...asyncTypes('GET_VESSEL_SUPERINTENDENTS'),
  ...asyncTypes('GET_VESSEL_VOYAGE_INFO'),
  ...asyncTypes('GET_VESSEL_VOYAGE_CONSUMPTIONS'),
  ...asyncTypes('GET_VESSEL_VOYAGE_LEG_TOTALS'),
  ...asyncTypes('GET_VESSEL_CHARTER_PARTY'),
  ...asyncTypes('GET_VESSEL_LIVE_WEATHER'),
  ...asyncTypes('GET_VESSEL_PARTIES'),
  TOGGLE_GOOD_WEATHE_DAYS_PER_VOYAGE: 'TOGGLE_GOOD_WEATHE_DAYS_PER_VOYAGE',
  SET_VESSEL_ITINERARIES_NAVIGATION: 'SET_VESSEL_ITINERARIES_NAVIGATION',
  SET_VESSEL_VISIBILITY: 'SET_VESSEL_VISIBILITY'
};

export default TYPES;
