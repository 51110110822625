import React from 'react';
import DarkContainer from 'components/layouts/DarkContainer';
import Credentials from './credentials';
import TwoFactorAuthentication from './2fa';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { selectAuthenticationMethod } from 'store/auth/selectors';

const Login = () => {
  const method = useSelector(selectAuthenticationMethod);

  return (
    <DarkContainer>
      <SwitchTransition>
        <CSSTransition
          key={method}
          addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
          classNames="login-fade"
        >
          <div className="login-page">
            {!method ? <Credentials /> : <TwoFactorAuthentication method={method} />}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </DarkContainer>
  );
};

export default Login;
