import React, { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import _isNumber from 'lodash/isNumber';

import DragHandler from 'tools/components/general/DragHandler';
import arrow from 'tools/assets/svg/arrow_right.svg';

const CurrencyPreview = ({
  systemCurrency,
  currentCurrency,
  baseCurrencyAmount,
  setBaseCurrencyAmount
}) => {
  const [amount, setAmount] = useState(
    systemCurrency?.label === currentCurrency?.currency_label ? 1 : ''
  );
  const systemCurrencyLabel = systemCurrency?.label.toLowerCase();
  const currentCurrencyLabel = currentCurrency?.currency_label?.toLowerCase();

  useEffect(() => {
    if (currentCurrency && currentCurrencyLabel === systemCurrencyLabel) {
      setAmount(1);
    }
  }, [currentCurrency, systemCurrencyLabel]);

  useEffect(() => {
    if (currentCurrency && amount !== '') {
      const num = _isNumber(amount) ? amount : Number(amount.replace(/[^\d\.]/g, ''));
      setBaseCurrencyAmount(num / Number(currentCurrency.rate));
    } else {
      setBaseCurrencyAmount(null);
    }
  }, [amount]);

  useEffect(() => {
    if (baseCurrencyAmount !== null) {
      setAmount(baseCurrencyAmount * Number(currentCurrency.rate));
    }
  }, [baseCurrencyAmount]);

  return (
    <div className={`forex-rates-currency`}>
      {currentCurrencyLabel !== systemCurrencyLabel ? (
        <DragHandler />
      ) : (
        <div className="empty-spacer"></div>
      )}

      <div className="forex-rates-currency--image ps-1">
        <img
          alt={currentCurrencyLabel}
          src={`https://orca-prod.s3-eu-west-1.amazonaws.com/public/currencies/${currentCurrencyLabel}.svg`}
          width={32}
          height={32}
          className={!currentCurrencyLabel ? 'd-none' : ''}
        ></img>
        <div className="forex-rates-currency--image-name">{currentCurrency.name}</div>
      </div>

      <div className="forex-rates-currency--info flex-fill overflow-hidden">
        <div className="forex-rates-currency--info-label">
          <strong>{currentCurrency.label}</strong>
        </div>
      </div>

      {currentCurrencyLabel === systemCurrencyLabel ? (
        <div className="forex-rates-currency--arrow mx-1">
          <img alt={'arrow'} src={arrow} width={20} height={20}></img>
        </div>
      ) : null}

      <div className="forex-rates-currency--amount ps-1 ms-1 d-flex align-items-center justify-content-end">
        <span>{currentCurrency.symbol}</span>
        <NumericFormat
          className="form-control form-field ms-1"
          value={amount}
          name={'amount'}
          onChange={e => setAmount(e.target.value)}
          placeholder={'Amount'}
          thousandSeparator={','}
          decimalSeparator={'.'}
          allowLeadingZeros={false}
          decimalScale={2}
        />
      </div>
    </div>
  );
};

export default CurrencyPreview;
