import React from 'react';
import ButtonIcon from 'tools/components/general/ButtonIcon';

const EmptyState = ({ setEditing }) => {
  return (
    <div className="tool-empty-state">
      <h1>You haven’t added any tool yet.</h1>
      <ButtonIcon type="add" text="Add Tool" onClick={() => setEditing(true)} />

      <div className="tool-empty-state--bg">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M23.952 8.278l-.012.019v.007l-.016.018-11.67 12.832a.317.317 0 01-.218.12h-.044a.321.321 0 01-.246-.122L.084 8.326l-.009-.01V8.31L.062 8.3A.321.321 0 010 8.142v-.037-.036a.324.324 0 01.031-.1v-.011l.009-.015.001.007.006-.01L3.16 2.881a.307.307 0 01.048-.061h.011l.011-.02.019-.014a.238.238 0 01.03-.018.316.316 0 01.138-.039h17.161a.313.313 0 01.152.046h.007l.021.016.009.008h.005a.307.307 0 01.048.061l3.112 5.057a.318.318 0 01.032.067.279.279 0 01.01.039v.028a.3.3 0 010 .06.319.319 0 01-.038.127V8.251zm-7.37.154H7.417L12 20.067zm6.359 0h-5.662L12.95 19.423zM11.049 19.423L6.72 8.432H1.058zM23.1 7.784l-2.554-4.142-2.869 4.142zM3.453 3.641L.9 7.784h5.422zM12 3.507L7.722 7.784h8.555zm-.783-.134h-7.16L6.986 7.6zm8.726 0h-7.16l4.23 4.23z"
            fill="#ffffff1A"
          />
        </svg>
      </div>
    </div>
  );
};

export default EmptyState;
