import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'utils/api';

export const getWidgetVesselProfile = createAsyncThunk(
  'GET_WIDGET_VESSEL_PROFILE',
  async ({ id, ...rest }, { rejectWithValue }) => {
    try {
      const res = await get(`/vessels/${id}`, rest);
      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getWidgetVesselTceResults = createAsyncThunk(
  'GET_WIDGET_VESSEL_TCE_RESULTS',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const res = await get(`reports/vessel-tce-results`, params);
      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getWidgetVesselTceResultsGraph = createAsyncThunk(
  'GET_WIDGET_VESSEL_TCE_RESULTS_GRAPH',
  async (params, { rejectWithValue }) => {
    try {
      const res = await get(`reports/vessel-tce-results/graph`, params);
      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
