import { RootState } from '@/store';
import { createSelector } from 'reselect';
import { Scale } from '@/common/types/enums';
import {
  selectEvaluationsRangeFrom,
  selectEvaluationsRangeTo,
  selectEvaluationsStep
} from '../settings/selectors';
import _isNumber from 'lodash/isNumber';

export const selectListReducer = (state: RootState) => state.lists;
export const getKey = (_: any, key: number | string) => key;

const selectListDefaults = createSelector(selectListReducer, lists => lists?._defaults);

export const selectListDefaultOptions = createSelector(
  selectListDefaults,
  getKey,
  (defaults, listKey) => defaults?.[listKey]?.options || []
);

export const selectMemoizedList = createSelector(
  selectListReducer,
  getKey,
  (lists, listKey) => lists?.[listKey]
);

export const selectListOptionsFromStore = createSelector(
  selectMemoizedList,
  selectListDefaultOptions,
  (list, defaults) => {
    if (!list?.options?.length && defaults?.length && !list?.requestParams?.search) return defaults;

    return list?.options;
  }
);

/* Scales Selectors */
export const selectScales = (state: RootState) => {
  const scales: Scale[] = selectListOptionsFromStore(state, 'scales');

  return scales;
};

export const selectDefaultScale = (state: RootState) => {
  const scales: Scale[] = selectScales(state);

  return scales?.[0];
};

export const selectDefaultScaleLabels = (state: RootState) => {
  const scale: Scale = selectDefaultScale(state);

  return scale?.label || [];
};

export const selectScaleLabel = createSelector(
  selectDefaultScaleLabels,
  getKey,
  (labels, value) => {
    const scale = _isNumber(value) ? labels[Math.ceil(value) - 1] : null;

    return scale;
  }
);

export const selectShouldShowDefaultScaleLabel = (state: RootState) => {
  const defaultScale = selectDefaultScale(state);
  const orgEvaluationsRangeFrom = selectEvaluationsRangeFrom(state);
  const orgEvaluationsRangeTo = selectEvaluationsRangeTo(state);
  const orgEvaluationsStep = selectEvaluationsStep(state);

  return defaultScale?.min === orgEvaluationsRangeFrom &&
    defaultScale?.max === orgEvaluationsRangeTo &&
    defaultScale?.step === orgEvaluationsStep
    ? true
    : false;
};
/* -- */
