import TYPES from './types';
import { LOCATION_CHANGE } from 'connected-react-router';

import _cloneDeep from 'lodash/cloneDeep';

const INITIAL_STATE = {
  documentSettings: [],
  documentReminders: [],
  documentsSorting: 'asc',

  categoryDocuments: {}, // $type1: [], $type2: [], ...
  categoryDocumentsPaging: { current_page: 1, per_page: 40 }, // limit category documents

  categoryFilter: { type: 'stcw', name: 'Stcw' },
  documentsFilter: [],
  ranksFilter: [],

  isFetchingSettings: false,
  isFetchingReminders: false,
  isSavingDocument: false
};

const reducer = (state = _cloneDeep(INITIAL_STATE), { type, payload }) => {
  switch (type) {
    case TYPES.SET_CREW_DOCUMENTS_MATRIX_CATEGORY_PAGINATION_CURRENT_PAGE:
      return {
        ...state,
        categoryDocumentsPaging: {
          ...state.categoryDocumentsPaging,
          current_page: payload
        }
      };

    case TYPES.SET_CREW_DOCUMENTS_MATRIX_CATEGORY_DOCUMENTS:
      return {
        ...state,
        categoryDocuments: {
          ...state.categoryDocuments,
          [payload.category]: payload.documents
        }
      };

    case TYPES.SET_CREW_DOCUMENTS_MATRIX_CATEGORY_FILTER:
      return {
        ...state,
        categoryFilter: payload
      };

    case TYPES.SET_CREW_DOCUMENTS_MATRIX_RANKS_FILTER:
      return {
        ...state,
        ranksFilter: payload
      };

    case TYPES.SET_CREW_DOCUMENTS_MATRIX_DOCUMENTS_FILTER:
      return {
        ...state,
        documentsFilter: payload
      };

    case TYPES.SET_CREW_DOCUMENTS_MATRIX_SORTING:
      return {
        ...state,
        documentsSorting: payload
      };

    case TYPES.GET_CREW_DOCUMENT_TYPES_SETTINGS.START:
      return {
        ...state,
        documentSettings: [],
        isFetchingSettings: true
      };

    case TYPES.GET_CREW_DOCUMENT_TYPES_SETTINGS.ERROR:
      return {
        ...state,
        documentSettings: [],
        isFetchingSettings: false
      };

    case TYPES.GET_CREW_DOCUMENT_TYPES_SETTINGS.SUCCESS:
      return {
        ...state,
        documentSettings: payload,
        isFetchingSettings: false
      };

    case TYPES.GET_CREW_DOCUMENT_TYPES_REMINDERS.START:
      return {
        ...state,
        documentReminders: [],
        isFetchingReminders: true
      };

    case TYPES.GET_CREW_DOCUMENT_TYPES_REMINDERS.ERROR:
      return {
        ...state,
        documentReminders: [],
        isFetchingReminders: false
      };

    case TYPES.GET_CREW_DOCUMENT_TYPES_REMINDERS.SUCCESS:
      return {
        ...state,
        documentReminders: payload,
        isFetchingReminders: false
      };

    case TYPES.UPDATE_CREW_DOCUMENT_REMINDER.SUCCESS:
      return {
        ...state,

        isSavingReminder: false,
        documentReminders: state.documentReminders.find(
          reminder => reminder.crew_document_type_id === payload.crew_document_type_id
        )
          ? state.documentReminders.map(reminder =>
              reminder.crew_document_type_id === payload.crew_document_type_id ? payload : reminder
            )
          : [...state.documentReminders, payload]
      };

    case TYPES.UPDATE_CREW_DOCUMENT_TYPE_SETTINGS.START:
      return {
        ...state,
        isSavingDocument: true
      };

    case TYPES.UPDATE_CREW_DOCUMENT_TYPE_SETTINGS.ERROR:
      return {
        ...state,
        isSavingDocument: false
      };

    case TYPES.UPDATE_CREW_DOCUMENT_TYPE_SETTINGS.SUCCESS:
      return {
        ...state,
        isSavingDocument: false,
        documentSettings: state.documentSettings.find(
          doc =>
            doc.crew_rank_id === payload.crew_rank_id &&
            doc.crew_document_id === payload.crew_document_id
        )
          ? state.documentSettings.map(doc =>
              doc.crew_rank_id === payload.crew_rank_id &&
              doc.crew_document_id === payload.crew_document_id
                ? payload
                : doc
            )
          : [...state.documentSettings, payload]
      };

    case LOCATION_CHANGE:
      return _cloneDeep(INITIAL_STATE);

    default:
      return state;
  }
};

export default reducer;
