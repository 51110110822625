import _get from 'lodash/get';

export const getIntervalMonth = ({ row, column }) => {
  if (
    row.interval === 'every_three_months' ||
    row.interval === 'every_six_months' ||
    row.interval === 'annually'
  ) {
    return { starting_month: column.id };
  }

  if (row.interval === 'custom') {
    let newMonths = [];

    const oldMonths = Object.values(row.months)
      .map((e, index) => (e ? index : null))
      .filter(e => e !== null);

    const findMonth = oldMonths.find(e => e === column.id);

    if (findMonth || findMonth === 0) {
      newMonths = [...oldMonths.filter(e => e !== column.id)];
    } else {
      newMonths = [...oldMonths, column.id];
    }

    return { months: newMonths };
  }
};

export const getCrewMatrixTableRows = data => {
  return data?.length
    ? data.map(row => ({
        title: _get(row, 'name'),
        remarks: _get(row, 'remarks'),
        months: _get(row, 'months'),
        interval: _get(row, 'interval'),
        id: _get(row, 'id')
      }))
    : [];
};

export const getCrewMatrixTableColumns = data => {
  var allMonths = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ];

  const months = data?.length ? data.map(e => Object.keys(e.months)).slice(0, 1)[0] : [];

  return months
    .sort((a, b) => allMonths.indexOf(a) - allMonths.indexOf(b))
    .map((month, index) => ({
      title: month.slice(0, 3),
      label: month,
      id: index
    }));
};

export const overrideMatrixData = ({ data, id, newData }) => {
  return data.find(drill => drill.id === id)
    ? data.map(drill => (drill.id === id ? { ...drill, ...newData } : drill))
    : [...data];
};

export const formatTrainingsTableData = ({ data }) => {
  return data?.length
    ? data?.map(item => ({
        ...item,
        months: item.training_schedule?.months || [],
        interval: item.training_schedule?.interval,
        remarks: item.training_schedule?.remarks
      }))
    : [];
};
