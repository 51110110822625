export const columns = [
  {
    key: 'name',
    type: 'string',
    closedWidth: 130,
    openWidth: 350,
    header: 'Vessel',
    sticky: true,
    text_align: 'left'
  },
  {
    key: 'total',
    type: 'number',
    closedWidth: 88,
    openWidth: 125,
    header: 'PMS OPEN JOBS',
    sticky: true,
    total_key: 'total'
  },
  {
    key: 'overdue',
    type: 'number',
    closedWidth: 100,
    openWidth: 125,
    header: 'PMS OVERDUE JOBS',
    sticky: true,
    total_key: 'overdue'
  },
  {
    key: 'overdue_percentage',
    type: 'double',
    closedWidth: 368,
    openWidth: 600,
    header: 'PMS OVERDUE JOBS PERCENTAGE',
    sticky: true,
    subLabel: 'Overdue Jobs Limit <= 5%',
    total_key: 'overdue_percentage',
    text_align: 'right'
  },
  {
    key: 'critical_overdue',
    type: 'number',
    closedWidth: 99,
    openWidth: 100,
    header: 'ON CRITICAL SYSTEMS',
    sticky: true,
    total_key: 'critical_overdue'
  },
  {
    key: 'becoming_due',
    type: 'number',
    closedWidth: 150,
    openWidth: 178,
    header: 'PMS BECOMING DUE',
    sticky: true,
    subLabel: 'Less than 20% time left',
    total_key: 'becoming_due'
  },
  {
    key: 'unplanned',
    type: 'number',
    closedWidth: 160,
    openWidth: 100,
    header: 'UNPLANNED MAINTENANCE OPEN JOBS',
    sticky: true,
    total_key: 'unplanned'
  },
  {
    key: 'unplanned_percentage',
    type: 'number',
    closedWidth: 170,
    openWidth: 132,
    header: 'UNPLANNED MAINTENANCE OPEN JOBS % OF TOTAL',
    sticky: true,
    total_key: 'unplanned_percentage'
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions'
  }
];
