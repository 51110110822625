import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import useRouter from 'use-react-router';

import { constructPageTitle } from 'common/utils/pages';

const useGAnalytics = ({ location, documentTitle }) => {
  const path = location.pathname + location.search;
  const { match } = useRouter();

  const title = useSelector(state =>
    typeof documentTitle === 'function' ? documentTitle(state, { location, match }) : documentTitle
  );

  const isInitialized = ReactGA?.isInitialized;

  useEffect(() => {
    if (import.meta.env.VITE_RELEASE_STAGE === 'production' && !isInitialized) {
      ReactGA.initialize(import.meta.env.VITE_GA_KEY);
    }
  }, [isInitialized]);

  useEffect(() => {
    if (isInitialized) {
      ReactGA.send({ hitType: 'pageview', page: path, title: constructPageTitle(title) });
    }
  }, [isInitialized, path, title]);

  return null;
};

export default useGAnalytics;
