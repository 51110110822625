import TYPES from './types';
import { get } from 'utils/api';

export const getSocketInfo = params => async dispatch => {
  try {
    dispatch({ type: TYPES.GET_SOCKET_INFO.START });

    const response = await get('/account/realtime', params);
    const { data } = response;
    const payload = data;

    dispatch({ type: TYPES.GET_SOCKET_INFO.SUCCESS, payload });
    return payload;
  } catch (error) {
    dispatch({ type: TYPES.GET_SOCKET_INFO.ERROR, payload: error });
  }
};
