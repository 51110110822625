import { createSelector } from 'reselect';

const selectCaptainReportsTemplatesReducer = state => state.captainReportsTemplates;
const selectCaptainReportsTemplateVisibleFields = state =>
  state.captainReportsTemplates.templateVisibleFields;

const getKey = (_, key) => key;
const getArr = (_, arr) => arr;

export const selectFieldVisibility = createSelector(
  selectCaptainReportsTemplateVisibleFields,
  getKey,
  (templateVisibleFields, key) => {
    return templateVisibleFields[key];
  }
);

export const areGroupFieldsHidden = createSelector(
  selectCaptainReportsTemplateVisibleFields,
  getArr,
  (templateVisibleFields, fields = []) => {
    return fields.every(f => !templateVisibleFields[f.key]);
  }
);

export const selectTemplateName = createSelector(
  selectCaptainReportsTemplatesReducer,
  captainReportsTemplates => captainReportsTemplates.templateName
);

export const selectTemplateGroup = createSelector(
  selectCaptainReportsTemplatesReducer,
  captainReportsTemplates => captainReportsTemplates.templateGroup
);

export const selectTemplateType = createSelector(
  selectCaptainReportsTemplatesReducer,
  captainReportsTemplates => captainReportsTemplates.templateType
);

export const selectTemplateId = createSelector(
  selectCaptainReportsTemplatesReducer,
  captainReportsTemplates => captainReportsTemplates.templateId
);

export const isTemplateLoading = createSelector(
  selectCaptainReportsTemplatesReducer,
  captainReportsTemplates => captainReportsTemplates.isLoading
);

export const selectBunkeringFuelGrades = createSelector(
  selectCaptainReportsTemplatesReducer,
  captainReportsTemplates => captainReportsTemplates.bunkeringFuelGrades
);

export const selectTemplateValidations = createSelector(
  selectCaptainReportsTemplatesReducer,
  captainReportsTemplates => captainReportsTemplates.templateFieldValidations
);

export const selectTemplateValidation = key =>
  createSelector(selectTemplateValidations, validations => validations[key]);
