import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('CREATE_CREW_WAGE'),
  ...asyncTypes('GET_CREW_WAGE_SETTINGS'),
  ...asyncTypes('UPDATE_CREW_WAGE_SETTINGS'),
  ...asyncTypes('UPDATE_CREW_WAGE'),
  ...asyncTypes('CLONE_CREW_WAGE'),
  ...asyncTypes('DELETE_CREW_WAGE')
};

export default TYPES;
