import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import NumberInput from 'common/components/form/inputs/NumberInput';
import _debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { selectItemPriceZoneFiedValue } from 'store/purchasing/pricelist/selectors';
import { setItemPriceZoneFiedValue } from 'store/purchasing/pricelist/slice';
import { updatePurchasingPricelistItemPriceZone } from 'store/purchasing/pricelist/actions';

const PriceZoneItemInput = ({ itemId, column, rowIndex }) => {
  const value = useSelector(state =>
    selectItemPriceZoneFiedValue(state, itemId, column?.priceZoneId, column?.key)
  );
  const dispatch = useDispatch();

  const autoSaveItem = useCallback(
    params => dispatch(updatePurchasingPricelistItemPriceZone(params)),
    [dispatch]
  );
  const onAutosave = useMemo(() => _debounce(autoSaveItem, 1200), [autoSaveItem]);

  const onChange = e => {
    const params = {
      itemId,
      priceZoneId: column?.priceZoneId,
      key: column?.key
    };

    dispatch(setItemPriceZoneFiedValue({ ...params, value: e.target.value }));
    onAutosave(params);
  };

  return (
    <NumberInput
      key={`${rowIndex}_${itemId}`}
      value={value}
      className="mb-0"
      innerClassName="text-end"
      onChange={onChange}
      allowNegative={false}
      isAllowed={value =>
        column?.key === 'discount' ? !value?.floatValue || value?.floatValue <= 100 : true
      }
    />
  );
};

PriceZoneItemInput.propTypes = {
  itemId: PropTypes.number.isRequired,
  column: PropTypes.shape({
    key: PropTypes.oneOf(['discount', 'price']).isRequired,
    priceZoneId: PropTypes.number.isRequired
  })
};

export default PriceZoneItemInput;
