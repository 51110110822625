import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, _isEqual);

const selectDashboardReducer = state => state.dashboards;
const getKey = (_, key) => key;

// Forms
export const selectDashboardForm = createSelector(
  selectDashboardReducer,
  reducer => reducer.dashboardForm
);

export const selectDashboardWidgetsForm = createSelector(
  selectDashboardReducer,
  reducer => reducer.dashboardWidgetsForm
);

export const selectWidgetSettingsForm = createSelector(
  selectDashboardReducer,
  reducer => reducer.widgetSettingsForm
);

// User dashboards
export const selectUserDashboards = createSelector(selectDashboardReducer, reducer => reducer.list);

export const selectHasFetchedUserDashboards = createSelector(
  selectDashboardReducer,
  reducer => reducer.listFetched
);

// Widgets
export const selectWidgets = createSelector(selectDashboardReducer, reducer => reducer.widgets);

export const selectWidget = createSelector(selectWidgets, getKey, (widgets, id) => widgets[id]);

export const selectWidgetType = createSelector(selectWidget, widget => widget?.type);

export const selectWidgetTypeLabel = createSelector(
  selectWidgetType,
  widgetType => widgetType?.label
);

export const selectWidgetTypeLayout = createSelector(
  selectWidgetType,
  widgetType => widgetType?.layout
);

export const selectWidgetPreferences = createDeepEqualSelector(
  selectWidget,
  widget => widget?.preferences
);

export const selectWidgetTitle = createSelector(selectWidget, widget => widget?.title);

export const selectWidgetCustomTitle = createSelector(
  selectWidget,
  widget => _get(widget, 'preferences.custom_title', '') || ''
);

// Widget State
export const selectWidgetState = createSelector(selectWidget, widget => widget?.state);
export const selectWidgetStateData = createSelector(selectWidget, widget => widget?.state?.data);
export const selectWidgetStatePaging = createSelector(selectWidget, widget => widget?.state.paging);
export const selectWidgetStatePagingTotal = createSelector(
  selectWidgetStatePaging,
  paging => paging?.total
);
export const selectWidgetStateSorting = createSelector(selectWidgetState, state => state?.sorting);
export const selectIsWidgetStateInitialized = createSelector(
  selectWidget,
  widget => widget?.initialized
);
export const selectIsWidgetStateLoading = createSelector(selectWidget, widget => widget?.loading);

// Active dashboard
export const selectIsLoadingActiveDashboard = createSelector(
  selectDashboardReducer,
  reducer => reducer.isLoadingActiveDashboard
);

export const selectActiveDashboard = createSelector(
  selectDashboardReducer,
  reducer => reducer.activeDashboardData
);

export const selectActiveDashboardTitle = createSelector(
  selectActiveDashboard,
  activeDashboard => activeDashboard?.title
);

export const selectActiveDashboardIsShared = createSelector(
  selectActiveDashboard,
  activeDashboard => !activeDashboard?.is_owned
);

export const selectActiveDashboardWidgets = createSelector(
  selectDashboardReducer,
  reducer => reducer.activeDashboardWidgets
);

export const selectActiveDashboardHasWidgets = createSelector(
  selectActiveDashboardWidgets,
  activeDashboardWidgets => activeDashboardWidgets?.length !== 0
);

export const selectActiveDashboardWidgetsPerLayout = createSelector(
  selectActiveDashboardWidgets,
  getKey,
  selectWidgets,
  (activeDashboardWidgets, layout, widgets) =>
    activeDashboardWidgets.filter(id => widgets[id].layout === layout)
);

export const selectActiveDashboardHasSidebar = createSelector(
  selectActiveDashboardWidgets,
  selectWidgets,
  (activeDashboardWidgets, widgets) =>
    activeDashboardWidgets.filter(id => widgets[id].layout === 'sidebar')?.length !== 0
);

export const selectActiveDashboardFormWidgets = createSelector(
  selectActiveDashboardWidgets,
  selectWidgets,
  (activeDashboardWidgets, widgets) => {
    const getWidgets = layout =>
      activeDashboardWidgets
        .filter(id => widgets[id].layout === layout)
        .map((id, i) => ({ ...widgets[id], sort_index: i, label: widgets[id].type?.label }));

    return {
      card: getWidgets('card'),
      table: getWidgets('table'),
      sidebar: getWidgets('sidebar')
    };
  }
);

export const countActiveDashboardWidgetsPerType = createSelector(
  selectActiveDashboardWidgets,
  getKey,
  selectWidgets,
  (activeDashboardWidgets, label, widgets) =>
    activeDashboardWidgets.filter(id => widgets[id].type?.label === label)?.length
);

// Widget prevention modal
export const selectWidgetPreventionModal = createSelector(
  selectDashboardReducer,
  reducer => reducer.widgetPreventionModal
);
