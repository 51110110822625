import tableTypes from 'store/tables/types';
import personTypes from 'store/persons/types';
import companyTypes from 'store/companies/types';
import estimatorTypes from 'views/estimations/estimator/state/types';

const profileDataMiddleware = store => next => action => {
  if (action.type === tableTypes.TRIGGER_PROFILE_ACTION) {
    switch (action.payload.table) {
      case 'persons':
      case 'persons_can_login':
      case 'crew_can_login':
      case 'crew':
        store.dispatch({
          type: personTypes.GET_PERSON.SUCCESS,
          payload: action.payload.profile
        });
        break;

      case 'companies':
        store.dispatch({
          type: companyTypes.GET_COMPANY.SUCCESS,
          payload: action.payload.profile
        });
        break;

      case 'estimations':
        store.dispatch({
          type: estimatorTypes.FETCH.SUCCESS,
          payload: { data: { ...action.payload.profile, ports: [], cps: [], trips: [] } }
        });
        break;

      default:
        break;
    }
  }
  next(action);
};

export default profileDataMiddleware;
