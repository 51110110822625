import Table from 'common/components/table';
import Form from './form';
import CircledButton from '@/ts-common/components/buttons/CircledButton';
import { useAppSelector } from '@/store/hooks';

const TableView = ({
  services,
  onSaveUnderwaterService,
  table = undefined,
  state,
  columns,
  rows,
  drawer,
  active = undefined,
  setActive = undefined,
  formState,
  isTemplate,
  isSubmitting = false
}) => {
  const isOnBoard = useAppSelector(state => state.isOnBoard);

  return (
    <>
      {!!services?.length && (
        <Table
          hideTopPagination
          hideTableFilters
          hideTableSearch
          loader
          getRowClassName={() => 'align-items-center'}
          rows={rows}
          state={state}
          columns={columns}
          {...(table ? table : {})}
        />
      )}
      {!isOnBoard && (
        <CircledButton
          data-cy="underwater-service-add-more"
          type="add"
          className="text-primary fw-bold fs-12 mt-1"
          label="Add Service"
          onClick={() => {
            if (setActive) {
              setActive(null);
            }
            drawer.open();
          }}
          disabled={drawer.isOpen}
          svgStyle={{ width: 8, height: 8 }}
        />
      )}
      <Form
        drawer={drawer}
        active={active}
        onSave={onSaveUnderwaterService}
        formState={formState}
        isTemplate={isTemplate}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default TableView;
