import TYPES from './types';

const INITIAL_STATE = {
  fuels: []
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.FETCH.START:
      return startLoading(state, payload);
    case TYPES.FETCH.SUCCESS:
      return updateList(state, payload);
    case TYPES.FETCH.ERROR:
      return stopLoading(state, payload);
    case TYPES.FETCH_BUNKERING_PRICES_FUELS.SUCCESS:
      return {
        ...state,
        fuels: payload
      };

    default:
      return state;
  }
};
export default reducer;

const updateList = (state, { portId, data }) => ({
  ...state,
  [portId]: { isLoading: false, ...data }
});

const startLoading = (state, { portId }) => ({
  ...state,
  [portId]: { ...state[portId], isLoading: true }
});

const stopLoading = (state, { portId }) => ({
  ...state,
  [portId]: { ...state[portId], isLoading: false }
});
