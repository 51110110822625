import { createSlice } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';

const INITIAL_STATE = {
  checklist: []
};

// This state shouldn't have been created since we already have one that we use both in templates and the events.
const slice = createSlice({
  name: 'eventTemplates',
  initialState: INITIAL_STATE,
  reducers: {
    setEventTemplateChecklist: (state, { payload }) => {
      state.checklist = payload;

      return state;
    },
    [LOCATION_CHANGE]: state => {
      state.notes = null;
      state.checklist = [];

      return state;
    }
  }
});

export const { setEventTemplateChecklist } = slice.actions;

export default slice.reducer;
