import { useState, useEffect } from 'react';
import TicketsDrawer from 'common/components/tickets/drawer';
import ModuleWrapper from '../components/ModuleWrapper';
import CircledButton from 'common/components/buttons/CircledButton';
import ParticipantForm from './ParticipantForm';
import Participant from './participant';

import { useDispatch, useSelector } from 'react-redux';
import { selectEventId } from 'events/store/events/selectors';
import Loader from './Loader';
import GoToTicketingCaseButton from './GoToTicketingCaseButton';
import CopyParticipants from './CopyParticipants';
import { getParticipants } from 'events/store/event-modules/participants/actions';
import {
  selectParticipantsAddActionIsLocked,
  selectParticipantsData,
  selectParticipantsLoading
} from 'events/store/event-modules/participants/selectors';

const Participants = () => {
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [activeEditId, setActiveEditId] = useState(null);
  const [isHovering, setIsHovering] = useState(false);

  const dispatch = useDispatch();

  const isOnBoard = useSelector(state => state.isOnBoard);
  const participants = useSelector(selectParticipantsData);
  const loading = useSelector(selectParticipantsLoading);
  const eventId = useSelector(selectEventId);
  const isLocked = useSelector(selectParticipantsAddActionIsLocked);

  useEffect(() => {
    if (eventId) {
      dispatch(getParticipants({ id: eventId }));
    }
  }, [dispatch, eventId]);

  return (
    <ModuleWrapper
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className="event-participants position-relative"
      titleClassName={participants?.length ? 'cmb-4' : ''}
      type="participants"
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {participants.map((participant, index) => (
            <Participant
              index={index}
              setActiveEditId={setActiveEditId}
              setIsEditing={setIsEditing}
              activeEditId={activeEditId}
              key={participant.id || index}
              participant={participant}
            />
          ))}
          {isAdding ? <ParticipantForm type="add" onClose={() => setIsAdding(false)} /> : null}

          {!isLocked && (
            <CircledButton
              type="add"
              className={`text-primary fw-bold fs-12 edit-circled-btn `}
              label="Add more"
              data-cy="participants-add-more"
              svgStyle={{ width: 8, height: 8 }}
              disabled={isEditing}
              onClick={() => setIsAdding(true)}
            />
          )}
          <GoToTicketingCaseButton />
          {isHovering && <CopyParticipants participants={participants} />}
        </>
      )}

      {!isOnBoard && <TicketsDrawer />}
    </ModuleWrapper>
  );
};

export default Participants;
