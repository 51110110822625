import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_ACCOUNT'),
  ...asyncTypes('GET_ACCOUNT_PERMISSIONS'),
  ...asyncTypes('GET_ACCOUNT_WEBPUSH_CONFIG'),
  ...asyncTypes('UPDATE_ACCOUNT'),
  ...asyncTypes('UPDATE_LAST_DASHBOARD_ID'),

  // Notification Preferences
  ...asyncTypes('GET_NOTIFICATION_PREFERENCES'),
  ...asyncTypes('GET_PARTY_NOTIFICATION_PREFERENCES')
};

export default TYPES;
