import store from 'store';
import { getVapidKey, subscibeUser } from 'store/web-push/actions';
import { errorHandler } from 'common/utils/notifications';
import { showModal, hideModal, togleDisabled } from 'store/notification-modal/actions';
import { get } from 'utils/api';
import _get from 'lodash/get';
import Cookie from 'utils/cookie';
import { ORCA_WEB_NOTIFICATIONS_DISALLOWED } from './subscription';
import { parseBoolean } from 'common/utils/parsing';

/*
Actions
*/
const getKey = () => store.dispatch(getVapidKey());
const subscribe = params => store.dispatch(subscibeUser(params));

export const showWebpushModal = params => {
  const existingDisallowingCookie = Cookie.get(ORCA_WEB_NOTIFICATIONS_DISALLOWED);

  if (existingDisallowingCookie && parseBoolean(existingDisallowingCookie) === true) return;

  store.dispatch(showModal(params));
};

export const hideWebpushModal = () => {
  const existingDisallowingCookie = Cookie.get(ORCA_WEB_NOTIFICATIONS_DISALLOWED);

  if (
    existingDisallowingCookie === null ||
    (existingDisallowingCookie && parseBoolean(existingDisallowingCookie) === false)
  ) {
    Cookie.set(ORCA_WEB_NOTIFICATIONS_DISALLOWED, true, 3 * 30);
  }
  store.dispatch(hideModal());
};

export const toggleModalDisabled = () => store.dispatch(togleDisabled());
export const showErrorMessage = message =>
  store.dispatch(errorHandler({ title: 'Error', message }));

export const fetchConvertedVapidKey = async () => {
  const res = await getKey();
  const convertedKey = urlBase64ToUint8Array(res.vapid);
  return convertedKey;
};

export const createSubscription = async subscription => {
  const parsedSubscription = JSON.parse(JSON.stringify(subscription));

  const params = {
    endpoint: parsedSubscription.endpoint,
    keys: parsedSubscription.keys
  };

  await subscribe(params);
};

export function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const checkIfUserShouldSubscribe = async () => {
  const state = store.getState();
  let webpush = _get(state, 'account.webpush', {});
  let shouldSubscribe = _get(webpush, 'shouldSubscribe', false);
  let subscriptions = _get(webpush, 'subscriptions', []);

  return {
    shouldSubscribe,
    hasSubscription: subscriptions.length > 0
  };
};

export const testNotification = () => {
  return get('/dev/webpush/trigger');
};
