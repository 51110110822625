import { get } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import TABLE_TYPES from 'common/components/table/store/types';

import { constructTableData, constructColumnsFromDate } from './parser';

export const getNoonReportsTechnicalOverview = createAsyncThunk(
  'GET_NOON_REPORTS_TECHNICAL_OVERVIEW',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { table, filters } = params;
      const dateFilter = filters.find(e => e.name === 'timestamp')?.value;
      const [from, to] = dateFilter;

      const columns = constructColumnsFromDate(from, to);

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.START,
        payload: {
          params,
          filters,
          table,
          columns,
          data: []
        }
      });

      const res = await get('/reports/noon-reports-technical-overview', params);

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: {
          data: { ...res?.data, data: constructTableData(res?.data?.data || []), columns },
          table
        }
      });

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
