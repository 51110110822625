import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import styled from '@emotion/styled';

import { useForm, useFormState } from 'utils/hooks';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';

import { optionField } from 'common/utils/form/fieldTypes';
import FormSaveActions from 'common/components/form/FormSaveActions';
import CircledButton from 'common/components/buttons/CircledButton';
import selectDataFromState from 'common/utils/hooks/useForm/selectDataFromState';
import EventAuditsSelector from 'common/components/selectors/EventAuditsSelector';
import EventAuditReasonsSelector from 'common/components/selectors/EventAuditReasonsSelector';

import ShadowBox from 'common/components/general/ShadowBox';
import ListSelect from 'common/components/selectors/ListSelect';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

import {
  createEventAudit,
  deleteEventAudit,
  getEventAudits,
  updateEventAudit
} from 'events/store/event-modules/audits/actions';
import {
  selectAreAuditActionsLocked,
  selectAuditsDataLength
} from 'events/store/event-modules/audits/selectors';
import CreateNewCompanyDrawer from 'views/persons/drawer/create-company';
import CreateNewPersonDrawer from 'views/persons/drawer/create-person';
import { useDrawer } from 'common/components/drawer';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { selectIsTemplate, selectEventId } from '@/events/store/events/selectors';
import { addOrEditAudit, removeAudit } from '@/events/store/event-modules/audits/slice';
import { clsx } from 'clsx';

const config = {
  audit: optionField({ required: true }),
  auditor: optionField(),
  company_auditor_party: optionField(),
  reason: optionField()
};

const AuditForm = ({ setActive, drawer, data, setIsAdding, index }) => {
  const [previewMode, setPreviewMode] = useState(!!data);
  const [isHovering, setIsHovering] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const createCompanyDrawer = useDrawer();
  const createPersonDrawer = useDrawer();

  const dispatch = useAppDispatch();
  const { formState, collectValues, loadValues, handleSubmitError } = useForm(config, {});
  const { fields, selectField } = useFormState(formState);

  const eventId = useAppSelector(selectEventId);
  const areAuditActionsLocked = useAppSelector(selectAreAuditActionsLocked);
  const isTemplate = useAppSelector(selectIsTemplate);
  const auditsDataLength = useAppSelector(selectAuditsDataLength);

  const auditorType = fields?.audit?.value?.auditor_type;
  const showTemplateView = isTemplate || !eventId;

  const onSave = async () => {
    const values = collectValues();
    if (!values) return;

    if (showTemplateView) {
      dispatch(
        addOrEditAudit({
          ...values,
          index: index ?? auditsDataLength
        })
      );
      setPreviewMode(true);
      setIsAdding(false);
      return;
    }

    try {
      setIsSubmitting(true);
      const { audit, auditor, reason, company_auditor_party } = values;

      const params = {
        event_id: eventId,
        event_audit_id: audit?.id,
        auditor_id: auditor?.id || null,
        event_reason_id: reason?.id || null,
        company_auditor_party_id: company_auditor_party?.id || null
      };

      if (data?.id) {
        await dispatch(updateEventAudit({ ...params, audit_id: data?.id })).unwrap();
      } else await dispatch(createEventAudit(params)).unwrap();

      await dispatch(getEventAudits({ event_id: eventId })).unwrap();
      setIsSubmitting(false);
      setPreviewMode(true);
      setIsAdding(false);
    } catch (error) {
      handleSubmitError(error);
      setIsSubmitting(false);
    }
  };

  const onDelete = async () => {
    if (showTemplateView) {
      dispatch(removeAudit(index));
      return;
    }

    try {
      await dispatch(deleteEventAudit({ event_id: eventId, audit_id: data?.id })).unwrap();
      await dispatch(getEventAudits({ event_id: eventId })).unwrap();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const initForm = () => {
      const initialValues = selectDataFromState(data, config);

      loadValues({
        ...initialValues
      });
    };

    initForm();
  }, [data, loadValues]);

  const listParams = useMemo(() => {
    if (auditorType === 'crew') {
      return {
        rank_ids: fields.audit?.value?.auditor_types?.map(auditor => auditor.auditor_type_id)
      };
    } else if (auditorType === 'company') {
      return {
        company_type_ids: fields.audit?.value?.auditor_types?.map(
          auditor => auditor.auditor_type_id
        )
      };
    } else if (auditorType === 'person') return;
  }, [auditorType, fields.audit?.value?.auditor_types]);

  return (
    <div className="cmt-4">
      <ShadowBoxContainer className={clsx(showTemplateView && 'mb-2')}>
        <ShadowBox
          className={`d-flex align-items-center ps-3 py-2 position-relative ${
            previewMode ? 'min-height-40 max-height-40' : 'h-auto'
          }`}
          color={previewMode ? null : 'light-4'}
          flat={previewMode ? false : true}
          flatOnHover={previewMode ? true : false}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {previewMode ? (
            <div className="d-flex align-items-center pe-4 w-100p">
              <div className="flex-1 text-violet fw-medium fs-14">
                Audit / Inspection: <span className="text-primary">{data?.audit?.name}</span>
                {data?.auditor?.full_name ? (
                  <>
                    <span className="mx-1">|</span>
                    {data?.auditor?.type === 'company'
                      ? 'Auditing / Inspecting Company'
                      : 'Auditor / Inspector'}
                    : <span className="text-primary">{data?.auditor?.full_name}</span>{' '}
                  </>
                ) : null}
                {data?.auditor?.type === 'company' && data?.company_auditor_party_id ? (
                  <>
                    <span className="mx-1">|</span>Auditor / Inspector:{' '}
                    <span className="text-primary">{data?.company_auditor_party?.full_name}</span>{' '}
                  </>
                ) : null}
                {data?.reason?.name ? (
                  <>
                    <span className="mx-1">|</span>Reason:{' '}
                    <span className="text-primary">{data.reason.name}</span>{' '}
                  </>
                ) : null}
              </div>
            </div>
          ) : (
            <Layout>
              <Row className="w-100p">
                <Col>
                  <EventAuditsSelector
                    placeholder="Select type"
                    label="Audit / Inspections"
                    onChange={value => {
                      selectField('audit')(value);
                      selectField('auditor')(null);
                    }}
                    value={[fields.audit.value]}
                    {...fields.audit}
                  />
                </Col>
                <Col>
                  {auditorType === 'crew' ? (
                    <AsyncSelector
                      onChange={val => selectField('auditor')(val)}
                      type="crew-members"
                      label="Auditor / Inspector"
                      placeholder="Select seaman"
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.full_name}
                      isClearable={true}
                      defaultOptionsTriggerChange={fields.audit?.value?.id || previewMode}
                      defaultOptions={() =>
                        getInitialAsyncValues('crew-members', null, null, {
                          ...listParams
                        })
                      }
                      loadOptions={search =>
                        getAsyncOptions(search, 'crew-members', {
                          ...listParams
                        })
                      }
                      {...fields.auditor}
                    />
                  ) : (
                    <ListSelect
                      list="parties"
                      defaultOptionsTriggerChange={fields.audit?.value?.id || previewMode}
                      params={{ type: auditorType, ...listParams }}
                      isAsync={true}
                      isClearable={true}
                      label={
                        auditorType === 'company'
                          ? 'Auditing / Inspecting Company'
                          : 'Auditor / Inspector'
                      }
                      isCreatable
                      onCreateOption={() => {
                        auditorType === 'company'
                          ? createCompanyDrawer.open()
                          : createPersonDrawer.open();
                      }}
                      onChange={value => {
                        selectField('auditor')(value);
                        auditorType === 'company' && selectField('company_auditor_party')(null);
                      }}
                      placeholder={auditorType === 'company' ? 'Select company' : 'Select person'}
                      className="mb-0"
                      {...fields.auditor}
                    />
                  )}
                </Col>
                {auditorType === 'company' ? (
                  <Col>
                    <AsyncSelector
                      onChange={val => selectField('company_auditor_party')(val)}
                      type="parties"
                      label="Auditor / Inspector"
                      placeholder="Select person"
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.full_name}
                      isClearable={true}
                      isCreatable
                      onCreateOption={() => createPersonDrawer.open()}
                      defaultOptions={() =>
                        getInitialAsyncValues('parties', null, null, {
                          type: 'person',
                          company_ids: [fields.auditor.value?.id]
                        })
                      }
                      loadOptions={search =>
                        getAsyncOptions(search, 'parties', {
                          type: 'person',
                          company_ids: [fields.auditor.value?.id]
                        })
                      }
                      defaultOptionsTriggerChange={fields.auditor.value?.id || previewMode}
                      {...fields.company_auditor_party}
                    />
                  </Col>
                ) : null}
                <Col>
                  <EventAuditReasonsSelector
                    label="Reason"
                    autoFocus={false}
                    isClearable={true}
                    onChange={value => selectField('reason')(value)}
                    value={[fields.reason.value]}
                    {...fields.reason}
                  />
                </Col>
              </Row>
            </Layout>
          )}

          {!areAuditActionsLocked && (
            <FormSaveActions
              isDisabled={isSubmitting}
              isHovering={isHovering}
              mode={previewMode ? 'view' : 'edit'}
              onCancel={data?.id ? () => setPreviewMode(true) : () => setIsAdding(false)}
              onEdit={() => setPreviewMode(false)}
              onDelete={() => setShowDeleteModal(true)}
              onSave={onSave}
              style={{ right: -12, top: 5 }}
              deleteButtonProps={{ type: 'remove' }}
            />
          )}
        </ShadowBox>
      </ShadowBoxContainer>

      {!data?.id && !areAuditActionsLocked && !showTemplateView && (
        <CircledButton
          type="add"
          className="text-primary fw-bold fs-12 mt-2"
          label="Add finding"
          disabled={!data?.id}
          svgStyle={{ width: 8, height: 8 }}
          onClick={() => {
            setActive(null);
            drawer.open();
          }}
          style={{ width: 20, height: 20 }}
        />
      )}

      <DangerousActionModal
        transparent
        action="Delete"
        onAccept={onDelete}
        closeModal={() => setShowDeleteModal(false)}
        isOpen={showDeleteModal}
        actionText="Delete"
        header="Delete Audit"
        body="Are you sure you want to delete this audit?"
      />

      <CreateNewCompanyDrawer drawer={createCompanyDrawer} />
      <CreateNewPersonDrawer drawer={createPersonDrawer} />
    </div>
  );
};

const Layout = styled.div`
  width: 95%;
`;

const ShadowBoxContainer = styled.div`
  max-height: 4.625rem;
`;

AuditForm.propTypes = {
  drawer: DrawerState,
  data: PropTypes.shape({
    id: PropTypes.number
  }),
  setActive: PropTypes.func,
  setIsAdding: PropTypes.func
};

export default AuditForm;
