import TYPES from './types';

const INITIAL_STATE = {
  isOpen: false,
  title: '',
  disabled: false,
  text: '',
  primaryText: '',
  secondaryText: '',
  primaryAction: () => {},
  secondaryAction: () => {}
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SHOW_MODAL:
      return {
        ...state,
        isOpen: true,
        ...payload
      };

    case TYPES.HIDE_MODAL:
      return {
        ...state,
        isOpen: false
      };

    case TYPES.TOGLE_DISABLE:
      return {
        ...state,
        disabled: !state.disabled
      };

    default:
      return state;
  }
};

export default reducer;
