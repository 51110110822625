import { useSelector } from 'react-redux';

import WidgetDefaultCard from 'views/dashboard/components/WidgetDefaultCard';
import paths from 'routing/routes/_paths';

import { selectWidgetStateData } from 'store/dashboards/selectors';
import PropTypes from 'prop-types';

const PurchasingApprovalsCardWidget = ({ id }) => {
  const data = useSelector(state => selectWidgetStateData(state, id));

  return (
    <WidgetDefaultCard
      id={id}
      count={data.approvals_count}
      linkTo={paths.purchasing_requisitions_approval}
    />
  );
};

PurchasingApprovalsCardWidget.propTypes = {
  id: PropTypes.number
};

export default PurchasingApprovalsCardWidget;
