import { numberToStr, strToNumber } from 'common/utils/numbers';

import TYPES from './types';
import {
  configureStatisticsGraphData,
  configureStatisticsTopFourGraphData
} from 'store/reports/crew/helpers';
import _sumBy from 'lodash/sumBy';

const INITIAL_STATE = {
  data: {}
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_REPORT_ON_SIGNER_EVALUATION_STATISTICS_DATA.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          totals: configureStatisticsGraphData(
            payload.map(t => ({
              ...t,
              value: strToNumber(numberToStr(t.average_rating, 1)),
              legendValue: (
                <div className="fs-14 text-nowrap">
                  <strong>{numberToStr(t.average_rating, 1)}</strong> /{' '}
                  <span className="fw-normal">{t.total_evaluations}</span>
                </div>
              )
            })),
            _sumBy(payload, 'total_evaluations')
          )
        }
      };

    case TYPES.GET_REPORT_ON_SIGNER_EVALUATION_STATISTICS_DATA_TOP_FOUR.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          topFour: configureStatisticsTopFourGraphData(
            payload.map(t => ({
              ...t,
              value: strToNumber(numberToStr(t.average_rating, 1)),
              legendValue: (
                <div className="fs-14 text-nowrap">
                  <strong>{numberToStr(t.average_rating, 1)}</strong> /{' '}
                  <span className="fw-normal">{t.total_evaluations}</span>
                </div>
              )
            })),
            _sumBy(payload, 'total_evaluations')
          )
        }
      };

    default:
      return state;
  }
};

export default reducer;
