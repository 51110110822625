import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_CREW_DRILLS'),
  ...asyncTypes('CREATE_CREW_DRILLS'),
  ...asyncTypes('REORDER_CREW_DRILLS'),
  ...asyncTypes('UPDATE_CREW_DRILLS'),
  ...asyncTypes('UPDATE_CREW_DRILLS_SCHEDULE'),
  ...asyncTypes('ARCHIVE_CREW_DRILLS'),
  ...asyncTypes('UNARCHIVE_CREW_DRILLS'),
  STOP_AUTO_SAVING: 'STOP_AUTO_SAVING',
  RESET_ROW_MONTHS: 'RESET_ROW_MONTHS',
  RESET_CREW_TABLE_DATA: 'RESET_CREW_TABLE_DATA'
};

export default TYPES;
