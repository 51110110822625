import paths from '@/routing/routes/_paths';
import { LOCATION_CHANGE as LOCATION_CHANGE_ONBOARD } from 'connected-react-router';

export const INITIAL_STATE = false;

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case LOCATION_CHANGE_ONBOARD: {
      if (payload.location.pathname.startsWith(paths.setup_onboard_filters)) {
        return true;
      } else {
        return INITIAL_STATE;
      }
    }

    default:
      return state;
  }
};

export default reducer;
