import TYPES from './types';

export const INITIAL_STATE = {
  isSaving: false,
  data: []
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.UPDATE_LUBRICANT_DAYS_ESTIMATOR.START:
      return {
        ...state,
        isSaving: true
      };
    case TYPES.UPDATE_LUBRICANT_DAYS_ESTIMATOR.SUCCESS:
      return {
        ...state,
        isSaving: false
      };
    case TYPES.UPDATE_LUBRICANT_DAYS_ESTIMATOR.ERROR:
      return {
        ...state,
        isSaving: false
      };

    default:
      return state;
  }
};

export default reducer;
