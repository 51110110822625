import { dateField, optionField, stringField } from 'common/utils/form/fieldTypes';

const config = {
  underwater_service_type: optionField({ required: true }),
  location: stringField(),
  date: dateField({ required: true }),
  company: optionField(),
  divers: optionField()
};

export default config;
