import { asyncTypes } from 'store/_helpers';

const TYPES = {
  LOGIN: 'LOGIN',
  LOGIN_WITH_TWO_FACTOR: 'LOGIN_WITH_TWO_FACTOR',
  LOGOUT: 'LOGOUT',
  LOGOUT_CLEANUP: 'auth/logoutCleanup',
  AUTHORIZE_LOGIN: 'auth/authorizeLogin',

  SETUP_TWO_FACTOR_AUTHENTICATION: 'SETUP_TWO_FACTOR_AUTHENTICATION',
  SEND_TWO_FACTOR_OTP: 'SEND_TWO_FACTOR_OTP',

  ...asyncTypes('REFRESH_TOKEN')
};

export default TYPES;
