import PropTypes from 'prop-types';
import Spinner from 'common/components/general/Spinner';
import WidgetTitle from 'views/dashboard/components/WidgetTitle';

const LoadingWidgetData = ({ layout, id }) => {
  return (
    <div className="widgets-loading-data-container">
      {layout !== 'card' ? <WidgetTitle id={id} withActions={false} /> : null}
      <div
        className={`widgets-loading-data widgets-loading-data--${layout} d-flex align-items-center justify-content-center`}
      >
        <Spinner />
      </div>
    </div>
  );
};

LoadingWidgetData.propTypes = {
  id: PropTypes.number.isRequired, // widget id
  layout: PropTypes.string.isRequired // widget id
};

export default LoadingWidgetData;
