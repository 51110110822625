import { stringifyObj } from 'utils/urls';

export const goToSavedTable = (history, tableParams, tableSearch) => {
  let search = null;
  if (tableParams) {
    search = Object.keys(tableParams).reduce((acc, key) => {
      if (key !== 'paging' && tableParams[key]) {
        acc[key] = tableSearch[key];
      }

      return acc;
    }, {});
  }

  history.push({
    pathname: history.location.pathname,
    search: `?${stringifyObj(
      search || {
        filters: tableSearch.filters || [],
        visible: tableSearch.visible,
        sorting: tableSearch.sorting
      }
    )}&searchId=${tableSearch.id}`
  });
};
