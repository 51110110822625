import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_FORM_PLANNING_SETTINGS'),
  ...asyncTypes('CREATE_FORM_PLANNING_SETTING'),
  ...asyncTypes('UPDATE_FORM_PLANNING_SETTING'),
  ...asyncTypes('DELETE_FORM_PLANNING_SETTING'),
  SET_FORM_PLANNING_SETTINGS_FILTERS: 'SET_FORM_PLANNING_SETTINGS_FILTERS',
  SET_FORM_PLANNING_SETTINGS_SELECTED_YEAR: 'SET_FORM_PLANNING_SETTINGS_SELECTED_YEAR'
};

export default TYPES;
