import { selectIsSummaryViewActive } from 'common/components/purchasing/requisition/store/selectors';
import { createSelector } from 'reselect';

// We use the selectors that we have in purchasing/requisition to get the suppliers data,
// in every fetch of the summary we are updating the state of active requisition too. NO NEED TO CREATE SUMMARY SLECTOR

const selectRequisitionSummaryReducer = state => state.purchasing?.requisitionSummary;
const getKey = (_, key) => key;

export const selectRequisitionId = state => selectRequisitionSummaryReducer(state)?.id;

export const selectRequisitionIsLoading = state =>
  selectIsSummaryViewActive(state) && !selectRequisitionId(state);

export const selectRequisitionVessel = state => selectRequisitionSummaryReducer(state)?.vessel;

export const selectRequisitionItems = state => selectRequisitionSummaryReducer(state)?.items;

export const selectRequisitionRemarks = state => selectRequisitionSummaryReducer(state)?.remarks;

export const selectRequisitionAttachments = state =>
  selectRequisitionSummaryReducer(state)?.attachments || [];

export const selectRequisitionSuppliers = state =>
  selectRequisitionSummaryReducer(state)?.suppliers;

export const selectRequisitionDeliveredSuppliers = createSelector(
  selectRequisitionSuppliers,
  suppliers =>
    suppliers?.filter(
      s => s.delivery_date && (s.status?.label === 'comp' || s.status?.label === 'ev')
    )
);

export const selectRequisitionSupplier = createSelector(
  selectRequisitionSuppliers,
  getKey,
  (suppliers, supplierRequisitionID) => suppliers?.find(s => s.id === supplierRequisitionID)
);

export const selectActiveRequisitionSupplierIds = state =>
  selectRequisitionSummaryReducer(state)?.activeRequisitionSuppliers;

export const selectHasSelectedRequisitionSuppliers = state =>
  !!selectActiveRequisitionSupplierIds(state)?.length;

export const selectActiveSupplierIndex = createSelector(
  [selectRequisitionSuppliers, getKey],
  (suppliers, supplierId) => suppliers.findIndex(supplier => supplier.id === supplierId)
);

export const getSupplierTypeByPrice = supplier => {
  const approved = `total_approved_price`;
  const proposed = `total_proposed_price`;
  return supplier?.[approved] ? 'approved' : supplier?.[proposed] ? 'proposed' : null;
};

export const getSupplierPrice = (state, id, isEquivalent) => {
  const supplier = selectRequisitionSupplier(state, id);
  const baseKey = getSupplierTypeByPrice(supplier) || 'discounted';
  const priceKey = `total_${baseKey}_price${isEquivalent ? '_base_currency_equivalent' : ''}`;

  return supplier?.[priceKey] || null;
};

export const getSupplierTooltipLabel = (state, id) => {
  const approved = `total_approved_price`;
  const proposed = `total_proposed_price`;
  const discounted = `total_discounted_price`;

  const supplier = selectRequisitionSupplier(state, id);

  return supplier[approved]
    ? 'Approved'
    : supplier[proposed]
    ? 'Proposed'
    : supplier[discounted]
    ? 'Quoted'
    : null;
};

//

//items selectors

export const selectActiveRequisitionSupplierTotalPriceColumnLabel = (
  state,
  supplierRequisitionID
) => {
  const supplier = selectRequisitionSupplier(state, supplierRequisitionID);
  const type = getSupplierTypeByPrice(supplier);

  if (type === 'approved') return 'Total\nApproved';
  if (type === 'proposed') return 'Total\nProposed';

  return 'Total\nQuoted';
};

export const selectActiveRequisitionSupplierQuantityColumnLabel = (
  state,
  supplierRequisitionID
) => {
  const supplier = selectRequisitionSupplier(state, supplierRequisitionID);
  const type = getSupplierTypeByPrice(supplier);

  if (type === 'approved') return 'APPROVED\nQTY';
  if (type === 'proposed') return 'PROPOSED\nQTY';

  return 'QUOTED\nQTY';
};
