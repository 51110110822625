import _sortBy from 'lodash/sortBy';

export const parseBeaconMenusWithPosts = menus => {
  const items = [];

  menus.forEach(menu => {
    const { posts } = menu;

    items.push(menu);

    if (posts?.length) {
      _sortBy(posts, [post => post.title]).forEach(({ id, menu_id, ...post }) => {
        items.push({ ...post, id: `${menu_id}_${id}`, post_id: id, parent_id: menu_id });
      });
    }
  });

  return items;
};
