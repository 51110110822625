import React, { useEffect, useCallback } from 'react';
import DashboardActions from 'views/dashboard/actions';
import NoDashboards from 'views/dashboard/components/NoDashboards';
import Widgets from 'views/dashboard/widgets';
import PageLoader from 'common/components/general/PageLoader';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import useRouter from 'use-react-router';

import {
  selectHasFetchedUserDashboards,
  selectIsLoadingActiveDashboard,
  selectActiveDashboardHasSidebar,
  selectUserDashboards
} from 'store/dashboards/selectors';

import { fetchDashboard, fetchDashboards } from 'store/dashboards/actions';
import { useDispatch } from 'react-redux';
import { strToNumber } from 'common/utils/numbers';

const Dashboard = () => {
  const dispatch = useDispatch();
  const listFetched = useSelector(selectHasFetchedUserDashboards);
  const isLoadingActiveDashboard = useSelector(selectIsLoadingActiveDashboard);
  const hasSidebar = useSelector(selectActiveDashboardHasSidebar);
  const list = useSelector(selectUserDashboards);

  const { match } = useRouter();

  const fetchData = useCallback(async () => {
    if (!listFetched) dispatch(await fetchDashboards());

    if (match.params.id && list.find(d => d.id === strToNumber(match?.params?.id))) {
      dispatch(await fetchDashboard(match.params.id));
    }
  }, [dispatch, list, listFetched, match.params.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={`dashboard-page ${hasSidebar ? 'sidebar-enabled' : ''}`}>
      <PageLoader isLoading={!listFetched || isLoadingActiveDashboard} />

      <DashboardActions />
      <Widgets />
      <NoDashboards />

      {/* <AddWidgets
        activeDashboard={activeDashboard}
        isOpen={createWidgetIsOpen}
        close={() => setCreateWidgetIsOpen(false)}
      /> */}

      {/* 
      {activeDashboard && (
        <WidgetsContainer
          activeDashboard={activeDashboard}
          toggleCreateWidget={() => setCreateWidgetIsOpen(!createWidgetIsOpen)}
          widgetsTypes={widgetsTypes.options}
        />
      )} */}
      {/* <NoDashboards dashboards={dashboards} /> */}
      {/* <DashboardNotFound dashboards={dashboards} activeDashboard={activeDashboard} /> */}
      {/* <AddWidgets
        activeDashboard={activeDashboard}
        isOpen={createWidgetIsOpen}
        close={() => setCreateWidgetIsOpen(false)}
      />

      <PageSaving isSaving={dashboards.isSaving} /> */}
    </div>
  );
};

export default Dashboard;
