const parsePortCargoes = data => {
  const { results, ...totals } = data?.data || {};

  return {
    data: results,
    totals
  };
};

export default parsePortCargoes;
