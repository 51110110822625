const GRAPH_COLORS = {
  1: '#01d9a4',
  2: '#fedd00',
  3: '#fe5f55',
  4: '#e28413',
  5: '#7084d3',
  other: '#c0d3d5'
};

const MAX_COLORED = 5;

const getColor = color => GRAPH_COLORS[color];

const transformKey = key => {
  return key.replace(/[^a-z0-9]/gi, '').toLowerCase();
};

export const configureNationalitiesData = data => {
  const { nationalities, vessels, top_4 } = data;

  //info for table
  const nationalityIds = [];
  const nationalityInfo = {};
  const vesselNationalityTotals = {};
  const vesselIds = [];
  const vesselInfo = {};

  //info for graph nationalities
  let totalCrew = 0;
  const percentagesPerNationality = [];
  let paletteColors = {};
  const legends = [];
  let otherNationalitiesSum = 0;

  //info for graph top-4
  let totalTop4 = 0;
  let otherTop4Grouped = 0;

  nationalities.forEach((n, i) => {
    nationalityIds.push(n.id);
    nationalityInfo[n.id] = {
      name: n.name,
      total: n.total,
      color: i < MAX_COLORED ? i + 1 : 'other'
    };
    legends.push({
      name: n.name,
      color: i < MAX_COLORED ? i + 1 : 'other',
      label: `nationality-${n.id}`,
      nationalityId: n.id,
      value: n.total
    });
    totalCrew += n.total;
  });

  nationalities.forEach((n, i) => {
    if (i < MAX_COLORED) {
      percentagesPerNationality.push({
        label: `nationality-${n.id}`,
        percentage: (n.total * 100) / totalCrew
      });
    } else {
      otherNationalitiesSum += n.total;
    }
  });

  legends.forEach(
    l => (paletteColors[l.label] = { color: getColor(nationalityInfo[l.nationalityId].color) })
  );

  paletteColors['nationality-other'] = { color: GRAPH_COLORS.other };

  percentagesPerNationality.push({
    label: 'nationality-other',
    percentage: (otherNationalitiesSum * 100) / totalCrew
  });

  legends.push({
    name: 'OTHER',
    label: `nationality-other`,
    hidden: true
  });

  vessels.forEach(v => {
    vesselIds.push(v.id);
    vesselInfo[v.id] = { name: v.name, total: v.total };

    vesselNationalityTotals[v.id] = v.nationalities.reduce((prev, current) => {
      return { ...prev, [current.id]: current.total };
    }, {});
  });

  top_4.forEach((t, i) => {
    totalTop4 += t.total;

    if (i > MAX_COLORED - 1) {
      otherTop4Grouped += t.total;
    }
  });

  const top4GraphData = top_4?.reduce(
    (prev, current) => ({
      ...prev,
      legends: [
        ...prev.legends,
        {
          name: current.name,
          color: legends.find(l => l.nationalityId === current.id).color,
          label: `nationality-${current.id}`,
          value: current.total
        }
      ],
      paletteColors: {
        ...prev.paletteColors,
        [`nationality-${current.id}`]: {
          color: getColor(legends.find(l => l.nationalityId === current.id).color)
        }
      },
      percentages:
        legends.findIndex(l => l.nationalityId === current.id) < MAX_COLORED
          ? [
              ...prev.percentages,
              { label: `nationality-${current.id}`, percentage: (current.total * 100) / totalTop4 }
            ]
          : [...prev.percentages]
    }),
    { legends: [], paletteColors: {}, percentages: [] }
  );

  top4GraphData.paletteColors = {
    ...top4GraphData.paletteColors,
    'top-4-other': { color: GRAPH_COLORS.other }
  };

  top4GraphData.percentages.push({
    label: 'top-4-other',
    percentage: (otherTop4Grouped * 100) / totalTop4
  });

  top4GraphData.legends.push({
    name: 'OTHER',
    label: 'top-4-other',
    hidden: true
  });

  const res = {
    nationalityIds,
    nationalityInfo,
    vesselIds,
    vesselInfo,
    vesselNationalityTotals,
    nationalityGraphData: { totalCrew, percentagesPerNationality, paletteColors, legends },
    top4GraphData: { ...top4GraphData, totalTop4: totalTop4 }
  };

  return res;
};

export const configureStatisticsGraphData = (responseData, fixedTotal) => {
  let graphTotal = 0;
  let paletteColors = {};
  let restSum = 0;
  const info = {};
  const percentages = [];
  const legends = [];

  responseData
    .sort((a, b) => b.value - a.value)
    .forEach((n, i) => {
      info[transformKey(n.name)] = {
        name: n.name,
        total: n.value,
        color: i < MAX_COLORED ? i + 1 : 'other'
      };
      legends.push({
        name: n.name,
        color: i < MAX_COLORED ? i + 1 : 'other',
        label: `${transformKey(n.name)}`,
        id: transformKey(n.name),
        value: n.legendValue || n.value
      });
      graphTotal += n.value;
    });

  responseData.forEach((n, i) => {
    if (i < MAX_COLORED) {
      percentages.push({
        label: `${transformKey(n.name)}`,
        percentage: (n.value * 100) / graphTotal
      });
    } else {
      restSum += n.value;
    }
  });

  legends.forEach(l => (paletteColors[l.label] = { color: getColor(info[l.id].color) }));

  paletteColors['other'] = { color: GRAPH_COLORS.other };

  percentages.push({
    label: 'other',
    percentage: (restSum * 100) / graphTotal
  });

  legends.push({
    name: 'OTHER',
    label: `other`,
    hidden: true
  });

  const res = {
    graphData: { graphTotal: fixedTotal || graphTotal, percentages, paletteColors, legends }
  };

  return res;
};

export const configureStatisticsTopFourGraphData = (responseData, fixedTotal) => {
  let othersSum = 0;
  let totalTop4 = 0;
  let otherTop4Grouped = 0;
  let total = 0;
  let paletteColors = {};
  const ids = [];
  const info = {};
  const percentages = [];
  const legends = [];

  responseData
    .sort((a, b) => b.value - a.value)
    .forEach((n, i) => {
      ids.push(transformKey(n.name));
      info[transformKey(n.name)] = {
        name: n.name,
        total: n.value,
        color: i < MAX_COLORED ? i + 1 : 'other'
      };
      legends.push({
        name: n.name,
        color: i < MAX_COLORED ? i + 1 : 'other',
        label: `${transformKey(n.name)}`,
        nationalityId: transformKey(n.name),
        value: n.legendValue || n.value
      });
      total += n.value;
    });

  responseData.forEach((n, i) => {
    if (i < MAX_COLORED) {
      percentages.push({
        label: `${transformKey(n.name)}`,
        percentage: (n.value * 100) / total
      });
    } else {
      othersSum += n.value;
    }
  });

  legends.forEach(l => (paletteColors[l.label] = { color: getColor(info[l.nationalityId].color) }));

  paletteColors['other'] = { color: GRAPH_COLORS.other };

  percentages.push({
    label: 'other',
    percentage: (othersSum * 100) / total
  });

  legends.push({
    name: 'OTHER',
    label: `other`,
    hidden: true
  });

  responseData.forEach((t, i) => {
    totalTop4 += t.value;

    if (i > MAX_COLORED - 1) {
      otherTop4Grouped += t.value;
    }
  });

  const top4GraphData = responseData?.reduce(
    (prev, current) => ({
      ...prev,
      legends: [
        ...prev.legends,
        {
          name: current.name,
          color: legends.find(l => l.nationalityId === transformKey(current.name)).color,
          label: `${transformKey(current.name)}`,
          value: current.legendValue || current.value
        }
      ],
      paletteColors: {
        ...prev.paletteColors,
        [`${transformKey(current.name)}`]: {
          color: getColor(legends.find(l => l.nationalityId === transformKey(current.name)).color)
        }
      },
      percentages:
        legends.findIndex(l => l.nationalityId === transformKey(current.name)) < MAX_COLORED
          ? [
              ...prev.percentages,
              {
                label: `${transformKey(current.name)}`,
                percentage: (current.value * 100) / totalTop4
              }
            ]
          : [...prev.percentages]
    }),
    { legends: [], paletteColors: {}, percentages: [] }
  );

  top4GraphData.paletteColors = {
    ...top4GraphData.paletteColors,
    'top-4-other': { color: GRAPH_COLORS.other }
  };

  top4GraphData.percentages.push({
    label: 'top-4-other',
    percentage: (otherTop4Grouped * 100) / totalTop4
  });

  top4GraphData.legends.push({
    name: 'OTHER',
    label: 'top-4-other',
    hidden: true
  });

  const res = {
    top4GraphData: { ...top4GraphData, totalTop4: fixedTotal || totalTop4 }
  };

  return res;
};
