import React from 'react';
import { Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import NoAuthRoute from './NoAuthRoute';
import PublicRoute from './PublicRoute';
import PageDocumentTitle from 'common/components/general/PageDocumentTitle';
import Beacon from 'components/layouts/sidebar/beacon';

import useGAnalytics from 'utils/hooks/useGAnalytics';

const components = {
  private: PrivateRoute,
  no_auth: NoAuthRoute,
  public: PublicRoute,
  default: Route
};

const RenderRoute = props => {
  const TagName = components[props.type || 'public'];
  useGAnalytics(props);

  return (
    <>
      <TagName {...props} />
      <PageDocumentTitle {...props} />
      {props.authenticated && props.app && <Beacon {...props} />}
    </>
  );
};
export default RenderRoute;
