import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectEventId, selectIsTemplate } from 'events/store/events/selectors';
import { getUnderwaterServicesInfo } from 'events/store/event-modules/underwater-services/actions';

import ModuleWrapper from '../components/ModuleWrapper';
import Loader from './Loader';
import UnderwaterServicesWrapper from './UnderwaterServicesWrapper';
import UnderwaterServicesTemplateWrapper from './UnderwaterServicesTemplateWrapper';

const UnderwaterServicesModule = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const eventId = useSelector(selectEventId);
  const isTemplate = useSelector(selectIsTemplate);

  const shouldShowTemplateView = !eventId || isTemplate;

  // todo: move useEffect to non-template wrapper (because it's not related to template modules)
  useEffect(() => {
    if (eventId && !isTemplate) {
      const initModule = async () => {
        setIsLoading(true);

        await dispatch(getUnderwaterServicesInfo({ event_id: eventId }));

        setIsLoading(false);
      };

      initModule();
    }
  }, [eventId, isTemplate, dispatch]);

  return (
    <ModuleWrapper type="underwater_services">
      {isLoading ? (
        <Loader />
      ) : shouldShowTemplateView ? (
        <UnderwaterServicesTemplateWrapper />
      ) : (
        <UnderwaterServicesWrapper />
      )}
    </ModuleWrapper>
  );
};

export default UnderwaterServicesModule;
