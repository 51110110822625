import React from 'react';

import _get from 'lodash/get';
import moment from 'moment';

import { dateToUtc } from 'common/utils/dates';

const LastUpdated = ({ values, field }) => {
  const fieldValue = _get(values, field.key);
  const automatedLastUpdatedAt = dateToUtc(_get(fieldValue, 'last_updated.last_updated_at'));

  return automatedLastUpdatedAt ? (
    <span className="d-block fs-10 ms-1 lh-10 mt-1 text-violet">
      Last Update: {moment(automatedLastUpdatedAt).format('DD/MM/YYYY HH:mm')} UTC
    </span>
  ) : null;
};

export default LastUpdated;
