import NamesInTooltip from '@/common/components/general/NamesInTooltip';
import EntityLabel from '@/common/components/labels/EntityLabel';
import { CompanySubtype } from '@/common/types/companies';
import { FC } from 'react';

type CompanySubTypeLabelsProps = {
  subtypes: CompanySubtype[] | null;
  maxLimit?: number;
};

const CompanySubTypeLabels: FC<CompanySubTypeLabelsProps> = ({ subtypes, maxLimit = 1 }) => {
  return (
    <NamesInTooltip
      names={subtypes?.map((c, index: number) => (
        <EntityLabel key={index} className="border-0" type="company-type">
          {c.name}
        </EntityLabel>
      ))}
      maxLimit={maxLimit}
      showThreeDots={false}
      customSeparator={' '}
      customIndicator={(hiddenNamesCount, tooltipID) => (
        <EntityLabel className="border-0" type="company-type" id={tooltipID}>
          +{hiddenNamesCount}
        </EntityLabel>
      )}
    />
  );
};

export default CompanySubTypeLabels;
