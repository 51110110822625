import { useSelector } from 'react-redux';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import paths from 'routing/routes/_paths';
import { selectWidgetState } from 'store/dashboards/selectors';

import moment from 'moment';
import PropTypes from 'prop-types';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import { Col, Row } from 'reactstrap';
import { downloadVesselDocumentAttachments } from '@/common/components/vessels/profile/tabs/documents/store/actions';

const CrewDocuments = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));

  return (
    <div>
      <WidgetTitle id={id} linkTo={paths.crew_document_manager} />
      <WidgetDefaultTable
        id={id}
        columns={[
          {
            header: 'Name',
            key: 'title',
            type: 'string',
            headerClassName: 'text-violet',
            width: 3
          },
          {
            header: 'Seaman',
            key: 'seaman',
            type: 'string',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Category',
            key: 'category',
            type: 'string',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Rank',
            key: 'rank',
            type: 'string',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Issue',
            key: 'issue_date',
            type: 'string',
            headerClassName: 'text-violet'
          },
          {
            header: 'Expiration',
            key: 'expiration_date',
            type: 'string',
            headerClassName: 'text-violet'
          }
        ]}
        rows={{
          title: data => {
            const title = data?.type?.name;

            return title ? <TextWithTooltip className="h-auto">{title}</TextWithTooltip> : '';
          },
          seaman: data => {
            const seamanName = data?.crew_member?.full_name;

            return seamanName ? (
              <TextWithTooltip className="h-auto">{seamanName}</TextWithTooltip>
            ) : (
              ''
            );
          },
          category: data => {
            const categoryName = data?.type?.category;

            return categoryName ? (
              <TextWithTooltip className="h-auto">{categoryName}</TextWithTooltip>
            ) : (
              ''
            );
          },
          rank: data => {
            const rankName = data?.crew_member?.rank?.name;

            return rankName ? <TextWithTooltip className="h-auto">{rankName}</TextWithTooltip> : '';
          },
          issue_date: data =>
            data?.issued_at ? moment(data.issued_at).format('DD/MM/YYYY') : null,
          expiration_date: data => (
            <Row className="d-flex align-items-center">
              <Col xs={9}>
                <div>{data?.expires_at ? moment(data.expires_at).format('DD/MM/YYYY') : '-'}</div>
              </Col>
              <Col xs={3}>
                <DownloadAttachment
                  downloadFileName={`${data?.type?.name}-attachments`}
                  data={data}
                  downloadFiles={() => downloadVesselDocumentAttachments({ id: data.id })}
                  hasAttachments={(data.attachments?.length || 0) > 0}
                />
              </Col>
            </Row>
          )
        }}
        label={label}
        state={state}
      />
    </div>
  );
};

CrewDocuments.propTypes = {
  id: PropTypes.number.isRequired
};

export default CrewDocuments;
