import { ItineraryPort } from '@/common/types/itineraries';

type ItineraryPair = {
  leftId: number;
  rightId: number | null;
};

export const getItineraryPortPair = (
  itineraries: ItineraryPort[],
  beforeId: number,
  afterId: number
): ItineraryPair => {
  let leftId;
  let rightId;

  if (itineraries?.length > 1) {
    // Itineraries are limited by a limit key (e.g. 3) in the request params
    // When requesting next ports the left (L), right (R), and the one after right (X) itineraries appear as so in the itinerary response: [X, R, L] or [R, L] (when 2 ports only)
    // When requesting previous ports the left (L), right (R), and the one after left (X) itineraries appear as so in the itinerary response: [R, L, X]
    if (afterId) {
      rightId = itineraries[itineraries.length - 2]?.id;
      leftId = itineraries[itineraries.length - 1]?.id;
    } else {
      rightId = itineraries[0]?.id;
      leftId = itineraries[1]?.id;
    }
  } else {
    rightId = beforeId || null;
    leftId = itineraries[0]?.id;
  }

  return { leftId, rightId };
};
