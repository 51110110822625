import { FORM_FIELD_TYPES } from 'common/components/forms/digital/constants';
import { getFormFieldParams, getFieldTypeLabel } from 'common/components/forms/digital/state-utils';

import _pickBy from 'lodash/pickBy';
import _mapKeys from 'lodash/mapKeys';
import _get from 'lodash/get';

export const generateFormStateObject = ({ field }, type, sort_index) => {
  switch (type) {
    case FORM_FIELD_TYPES.dropdown.label:
      return {
        field,
        sort_index,
        help_text: '',
        required: false,
        form_field_params: {
          options: new Array(FORM_FIELD_TYPES.dropdown.initialOptions).fill(
            generateOptionStateObject(type)
          )
        }
      };
    case FORM_FIELD_TYPES.radio.label:
      return {
        field,
        sort_index,
        help_text: '',
        required: false,
        form_field_params: {
          options: new Array(FORM_FIELD_TYPES.radio.initialOptions).fill(
            generateOptionStateObject(type)
          )
        }
      };

    case FORM_FIELD_TYPES.radio_with_details.label:
      return {
        field,
        sort_index,
        help_text: '',
        required: false,
        form_field_params: {
          options: new Array(FORM_FIELD_TYPES.radio_with_details.initialOptions).fill(
            generateOptionStateObject(type)
          )
        }
      };

    case FORM_FIELD_TYPES.dropdown_with_details.label:
      return {
        field,
        sort_index,
        help_text: '',
        required: false,
        form_field_params: {
          options: new Array(FORM_FIELD_TYPES.dropdown_with_details.initialOptions).fill(
            generateOptionStateObject(type)
          )
        }
      };

    case FORM_FIELD_TYPES.table_with_fixed_rows.label:
      return {
        field,
        sort_index,
        help_text: '',
        required: false,
        form_field_params: {
          table_title: '',
          columns: [],
          fields: {},
          rows: []
        }
      };

    case FORM_FIELD_TYPES.table_with_dynamic_rows.label:
      return {
        field,
        sort_index,
        help_text: '',
        required: false,
        form_field_params: {
          table_title: '',
          max_row_count: 1,
          columns: [{ column_index: 0 }],
          fields: {},
          rows: [0]
        }
      };

    case FORM_FIELD_TYPES.manual_chapter.label:
      return {
        field,
        sort_index,
        help_text: '',
        required: false,
        form_field_params: { manual_id: null, chapter_uid: null }
      };

    case FORM_FIELD_TYPES.crew_participants.label:
      return {
        field,
        sort_index,
        help_text: '',
        required: false,
        form_field_params: { notes_label: '' }
      };

    case FORM_FIELD_TYPES.inventory_survey_rob.label:
      return {
        field,
        sort_index,
        help_text: '',
        required: false,
        form_field_params: { item_type: null, item_id: null }
      };

    case FORM_FIELD_TYPES.dropdown_multiple_select.label:
      return {
        field,
        sort_index,
        help_text: '',
        required: false,
        form_field_params: {
          options: new Array(FORM_FIELD_TYPES.dropdown.initialOptions).fill('')
        }
      };

    case FORM_FIELD_TYPES.form_instructions_image.label:
      return {
        field,
        sort_index,
        help_text: '',
        required: false,
        form_field_params: { file_id: null, width: null }
      };

    case FORM_FIELD_TYPES.root_cause_analysis.label:
      return {
        field,
        sort_index,
        help_text: '',
        required: false,
        form_field_params: { root_cause_category_id: null }
      };

    case FORM_FIELD_TYPES.vessel_system_attribute.label:
      return {
        field,
        sort_index,
        help_text: '',
        required: false,
        form_field_params: {
          vessel_system_attribute_group_id: null,
          vessel_system_attribute_id: null
        }
      };

    default:
      return { field, sort_index, help_text: '', required: false };
  }
};

export const generateOptionStateObject = type => {
  switch (type) {
    case FORM_FIELD_TYPES.radio_with_details.label:
    case FORM_FIELD_TYPES.dropdown_with_details.label:
      return {
        option: null,
        additional_info: false
      };

    default:
      return null;
  }
};

export const updateFormFieldParams = (formField, updatedParams) => {
  const formFieldParams = getFormFieldParams(formField) || {};

  return {
    form_field_params: { ...formFieldParams, ...updatedParams }
  };
};

/* Table Helpers */

export const createRowCellState = (formField, rowIndex) => {
  const fieldTypeLabel = getFieldTypeLabel(formField);

  return generateFormStateObject(formField, fieldTypeLabel, rowIndex);
};

export const updateSameRowsFields = (field, { fields, rows, columnIndex }) => {
  const updatedFields = { ...fields };

  rows.forEach(rowIndex => {
    updatedFields[`${rowIndex}_${columnIndex}`] = createRowCellState({ field }, rowIndex);
  });

  return updatedFields;
};

export const updateSameRowsFieldsSettings = (updatedField, { fields, rows, columnIndex }) => {
  const updatedFields = { ...fields };
  const {
    form_field_params,
    required,
    help_text,
    rules,
    formula,
    is_calculated,
    formula_variables,
    decimal_digit_precision
  } = updatedField;

  rows.forEach(rowIndex => {
    updatedFields[`${rowIndex}_${columnIndex}`] = {
      ...updatedFields[`${rowIndex}_${columnIndex}`],
      form_field_params,
      required,
      help_text,
      rules,
      formula,
      formula_variables,
      is_calculated,
      decimal_digit_precision
    };
  });

  return updatedFields;
};

export const updateSameRowsFieldsInColumn = ({ rowIndex, fields, columns }) => {
  const updatedFields = { ...fields };

  columns.forEach(column => {
    if (column.has_same_fields) {
      const columnIndex = column.column_index;
      const firstRowField = fields[`0_${columnIndex}`];

      if (firstRowField) {
        updatedFields[`${rowIndex}_${columnIndex}`] = createRowCellState(firstRowField, rowIndex);
      }
    }
  });

  return updatedFields;
};

export const removeColumnFromTable = (columnIndex, { fields, columns }) => {
  const updatedColumns = columns
    .filter(column => column.column_index !== columnIndex)
    .map((column, index) => ({ ...column, column_index: index }));

  const updatedFields = _mapKeys(
    _pickBy(fields, (_, key) => key.split('_')[1] !== columnIndex),
    (_, key) => {
      const indexes = key.split('_');
      const fieldRowIndex = parseInt(indexes[0]);
      const fieldColumnIndex = parseInt(indexes[1]);

      if (fieldColumnIndex > columnIndex) {
        return `${fieldRowIndex}_${fieldColumnIndex - 1}`;
      } else {
        return key;
      }
    }
  );

  return {
    columns: updatedColumns,
    fields: updatedFields
  };
};

export const removeRowFromTable = (rowIndex, { fields, rows }) => {
  const updatedRows = rows.filter(index => index !== rowIndex).map((_, index) => index);

  const updatedFields = _mapKeys(
    _pickBy(fields, (_, key) => !key.startsWith(rowIndex)),
    (_, key) => {
      const indexes = key.split('_');
      const fieldRowIndex = parseInt(indexes[0]);
      const fieldColumnIndex = parseInt(indexes[1]);

      if (fieldRowIndex > rowIndex) {
        return `${fieldRowIndex - 1}_${fieldColumnIndex}`;
      } else {
        return key;
      }
    }
  );

  return {
    rows: updatedRows,
    fields: updatedFields
  };
};

const constructReorderKey = (isRow, isIncrement, fieldRowIndex, fieldColumnIndex) => {
  if (isRow) {
    if (isIncrement) {
      return `${fieldRowIndex + 1}_${fieldColumnIndex}`;
    } else {
      return `${fieldRowIndex - 1}_${fieldColumnIndex}`;
    }
  } else {
    if (isIncrement) {
      return `${fieldRowIndex}_${fieldColumnIndex + 1}`;
    } else {
      return `${fieldRowIndex}_${fieldColumnIndex - 1}`;
    }
  }
};

export const reorderTableRowAndColumnFields = (fields, { newIndex, oldIndex }, isRow) => {
  const reorderedFields = {};

  if (newIndex === oldIndex) return fields;

  Object.keys(fields).forEach(key => {
    const indexes = key.split('_');
    const fieldRowIndex = parseInt(indexes[0]);
    const fieldColumnIndex = parseInt(indexes[1]);

    const comparisonIndex = isRow ? fieldRowIndex : fieldColumnIndex;
    const currentField = { ...fields[`${fieldRowIndex}_${fieldColumnIndex}`] };

    //the logic bellow applies for column reordering so I just added an extra parameter 'isRow' to handle both in the same function

    if (comparisonIndex === oldIndex) {
      //this is the field that we are moving so we put it to our new index
      reorderedFields[
        `${isRow ? newIndex : fieldRowIndex}_${isRow ? fieldColumnIndex : newIndex}`
      ] = currentField;
    } else if (newIndex === comparisonIndex) {
      //this is the field that was replace by the moved one, we move it upwards or downwards depending
      //on where the moved field came from (above, below)
      if (oldIndex <= comparisonIndex) {
        reorderedFields[constructReorderKey(isRow, false, fieldRowIndex, fieldColumnIndex)] =
          currentField;
        //reorderedFields[`${fieldRowIndex - 1}_${fieldColumnIndex}`] = currentField;
      } else {
        reorderedFields[constructReorderKey(isRow, true, fieldRowIndex, fieldColumnIndex)] =
          currentField;
      }
    } else if (newIndex <= comparisonIndex) {
      // here we are handling the fields below the moved field
      //if it came from below we move them all upwards or we do nothing
      if (oldIndex >= comparisonIndex) {
        reorderedFields[constructReorderKey(isRow, true, fieldRowIndex, fieldColumnIndex)] =
          currentField;
      } else {
        reorderedFields[`${fieldRowIndex}_${fieldColumnIndex}`] = currentField;
      }
    } else if (newIndex >= comparisonIndex) {
      // here we are handling the fields above the moved field
      //if it came from above we move them all downwards or we do nothing
      if (oldIndex <= comparisonIndex) {
        reorderedFields[constructReorderKey(isRow, false, fieldRowIndex, fieldColumnIndex)] =
          currentField;
      } else {
        reorderedFields[`${fieldRowIndex}_${fieldColumnIndex}`] = currentField;
      }
    } else {
      reorderedFields[`${fieldRowIndex}_${fieldColumnIndex}`] = currentField;
    }
  });

  return reorderedFields;
};

export const parseFormSetupErrors = (errors, fields) => {
  let parsedErrors = {};

  if (!errors) return {};

  Object.keys(errors).forEach(err => {
    const errorParts = err.split('.');
    if (err.includes('.fields.')) {
      //that means that the error is inside a table field
      parsedErrors = {
        ...parsedErrors,
        [errorParts[1]]: {
          message: errors[err][0],
          sort_index: fields[errorParts[1]].form_field_params.fields[errorParts[4]].sort_index,
          column_index: fields[errorParts[1]].form_field_params.fields[errorParts[4]].column_index
        }
      };
    } else {
      parsedErrors = { ...parsedErrors, [errorParts[1]]: { message: errors[err][0] } };
    }
  });

  return parsedErrors;
};

const getFormFieldParamFields = outer => {
  const paramFields = outer.form_field_params.fields;
  const fieldsAreArray = Array.isArray(paramFields);

  const fields = fieldsAreArray
    ? paramFields.map(inner => ({
        ...inner,
        id: null
      }))
    : Object.keys(paramFields).map(key => {
        const field = paramFields[key];
        return { ...field, id: null };
      });

  return {
    ...outer.form_field_params,
    fields
  };
};

export const parseAssignment = res => {
  const vessels = _get(res, 'vessels', []);

  if (!res.is_for_vessel) return { assignment: 'office', vessels };
  if (!vessels.length && res.is_for_vessel) return { assignment: 'all-vessels', vessels };
  if (vessels.length && res.is_for_vessel) return { assignment: 'assigned-vessels', vessels };
};

export const convertAssignmentToReqParams = (assignment, assignedVessels) => {
  const isForAllVessels = assignment === 'all-vessels';
  const isForSelectedVessels = assignment === 'assigned-vessels';

  const params = { is_for_vessel: false };

  if (isForAllVessels || isForSelectedVessels) {
    params.is_for_vessel = true;
    params.vessel_ids = assignedVessels.map(vessel => vessel.id);
  }

  return params;
};

export const parseSignersSettingsReqParams = (settings, form) => {
  return {
    crew_rank_ids: (settings?.crew_ranks || []).map(({ id }) => id),
    department_ids: (settings?.departments || []).map(({ id }) => id),
    department_role_ids: (settings?.department_roles || []).map(({ id }) => id),
    status_progresses: settings?.status_progresses || [],
    uid: form?.uid
  };
};
