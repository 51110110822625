import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useActions } from 'utils/hooks';
import { useForm, useFormState } from 'utils/hooks';
import { optionField } from 'common/utils/form/fieldTypes';

import * as dashboardsActions from 'store/dashboards/actions';

import WidgetSettings from 'views/dashboard/components/WidgetSettings';
import VesselOrVesselGroupSelector from 'components/selectors/VesselOrVesselGroupSelector';

const Preferences = ({ id, onSave, onClose, isSubmitting }) => {
  const { formState, collectValues, loadValues } = useForm({ vessels: optionField() });

  const { fields, selectField } = useFormState(formState);

  const [getWidgetPreferences] = useActions([dashboardsActions.getWidgetPreferences]);

  const init = async () => {
    const preferences = await getWidgetPreferences({ widgetId: id });
    const { vessel_groups, vessels, ...rest } = preferences;

    loadValues({
      vessels: [
        ...vessels.map(v => ({ ...v, value: { ...v, type: 'vessel' } })),
        ...vessel_groups.map(v => ({ ...v, value: { ...v, type: 'vessel_group' } }))
      ],
      ...rest
    });
  };

  const handleSubmitForm = async ({ custom_title }) => {
    const values = collectValues();
    if (!values) return;

    const { vessels } = values;

    const vessel_ids = vessels.filter(v => v.value.type === 'vessel');
    const vessel_group_ids = vessels.filter(v => v.value.type === 'vessel_group');

    const params = {
      custom_title,
      vessel_ids: vessel_ids?.length ? vessel_ids.map(v => v.id) : null,
      vessel_group_ids: vessel_group_ids?.length ? vessel_group_ids.map(v => v.id) : null
    };

    onSave(params);
  };

  useEffect(() => {
    init();
  }, [id]);

  return (
    <WidgetSettings id={id} isSubmitting={isSubmitting} onClose={onClose} onSave={handleSubmitForm}>
      <Row>
        <Col xs={12} className="cmt-6">
          <VesselOrVesselGroupSelector
            onChange={sel => selectField('vessels')(sel)}
            label="Vessels"
            {...fields.vessels}
          />
        </Col>
      </Row>
    </WidgetSettings>
  );
};

export default Preferences;
