import React from 'react';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import paths from '@/routing/routes/_paths.js';

const ITDashboard = React.lazy(() => retry(() => import('@/views/it/dashboard/index.jsx')));

const itDashboard = [
  {
    path: `${paths.it_dashboard}`,
    component: ITDashboard,
    app: true,
    type: 'private',
    exact: true,
    pageTitle: 'IT Dashboard',
    documentTitle: 'IT Dashboard',
    permissions: [permissions.office.it_dashboard.view]
  }
  // {
  //   path: `${paths.it_dashboard}/files`,
  //   component: ITDashboard,
  //   app: true,
  //   type: 'private',
  //   exact: true,
  //   pageTitle: 'IT Dashboard - Files'
  // }
];

export default itDashboard;
