import React, { useState, useEffect } from 'react';
import ConsumptionsList from './ConsumptionsList';
import Dots from './Dots';
import ships from 'tools/assets/svg/ships.svg';
import gear_blue from 'tools/assets/svg/gear_blue.svg';
import { Button } from 'reactstrap';

const Consumptions = ({ consumptions, setHideSettings, setIsSettingsOpen, typeColorMapping }) => {
  const consumptionKeys = Object.keys(consumptions).map((el, i) => {
    if (typeColorMapping[consumptions[el][0].type_label]) {
      return {
        name: el,
        color: typeColorMapping[consumptions[el][0].type_label]
      };
    } else {
      return {
        name: el,
        color: 'color_5'
      };
    }
  });
  const [active, setActive] = useState(consumptionKeys[0]);

  useEffect(() => {
    if (consumptionKeys.length === 0) {
      setHideSettings(true);
    } else setHideSettings(false);
  }, [consumptionKeys.length]);

  return (
    <div className="vessel-consumptions-view ">
      {consumptionKeys.length > 0 ? (
        <>
          <ConsumptionsList
            color={active.color}
            name={active.name}
            consumption={consumptions[active.name]}
          />
          <Dots active={active} setActive={setActive} consumptionKeys={consumptionKeys} />
        </>
      ) : (
        <div className="h-100p d-flex justify-content-center align-items-center empty-state">
          <img src={ships} height={138} width={110} alt="ship" />
          <Button
            type="button"
            className="p-0 mx-1 empty-state-button"
            onClick={() => setIsSettingsOpen(true)}
          >
            <img src={gear_blue} width={18} height={18} alt="settings icon" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Consumptions;
