import { stringField, optionField } from 'common/utils/form/fieldTypes';

const config = {
  title: stringField({ required: true }),
  description: stringField(),
  start_from: optionField(),
  shared_parties: optionField({ initialValue: [] }),
  shared_departments: optionField({ initialValue: [] })
};

export default config;
