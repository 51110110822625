import React, { useState } from 'react';
import { useActions } from 'utils/hooks';
import SvgRender from 'common/components/general/SvgRender';

import * as sidebarActions from 'store/sidebar/actions';
import { useSelector } from 'react-redux';

import useRouter from 'use-react-router';

import MenuItem from '../menu/MenuItem';
import CurrentVersion from 'components/layouts/sidebar/panel/components/CurrentVersion';

import arrow from 'assets/svg/common/arrow.svg';
import _sortBy from 'lodash/sortBy';

const Menu = () => {
  const { location } = useRouter();
  const toggleSubmenu = item => setActive(item);
  const [togglePanel] = useActions([sidebarActions.togglePanel]);
  const settings = useSelector(state => state.sidebar.settings);

  const getActive = () => {
    return settings.items.find(
      item => item.submenu && item.submenu.some(s => location.pathname.startsWith(s.path))
    );
  };

  const sortByProperty = (array, property) => _sortBy(array, property);
  const sortedMenuItems = sortByProperty(settings.items, 'name');

  const [active, setActive] = useState(getActive());

  const sortedSubmenuItems = sortByProperty(active ? active.submenu : [], 'name');

  return (
    <div className={`sidebar-menu d-flex flex-column h-100p ${active ? ' submenu-open' : ''}`}>
      <div className="ps-2 text-uppercase">{active ? active.name : 'ORCA.SETTINGS'}</div>
      <div className={`sidebar-menu--inner flex-1 mt-4`}>
        {!active ? (
          <div className="sidebar-menu--sub-inner">
            <div
              className="sidebar-menu--sub-back mb-1 d-inline-flex align-items-center"
              onClick={() => togglePanel('menu')}
            >
              <SvgRender src={arrow} style={{ width: 10, height: 10 }} />
              Back to main menu
            </div>
          </div>
        ) : null}
        <div className="sidebar-menu--section sidebar-menu--top gray-scrollbar overflow-y pt-1">
          {sortedMenuItems.map((item, index) => (
            <MenuItem key={index} item={item} onItemClick={toggleSubmenu} />
          ))}
        </div>

        <div className="sidebar-menu--section sidebar-menu--sub gray-scrollbar overflow-y">
          {active ? (
            <div className="sidebar-menu--sub-inner">
              <div
                className="sidebar-menu--sub-back mb-2 d-inline-flex align-items-center"
                onClick={() => toggleSubmenu(null)}
              >
                <SvgRender src={arrow} style={{ width: 10, height: 10 }} />
                Back to settings
              </div>
              {sortedSubmenuItems.map((item, index) => (
                <MenuItem key={index} item={item} sub />
              ))}
            </div>
          ) : null}
        </div>
      </div>
      <CurrentVersion />
    </div>
  );
};

export default Menu;
