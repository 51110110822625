import { FC, ReactNode } from 'react';
import NavigationGroupTabs, {
  NavigationGroupTab
} from 'common/components/buttons/NavigationGroupTabs';
import PageTitle from 'common/components/general/PageTitle';

type PageTitleWithTabsProps = {
  title?: string | ReactNode;
  subTitle?: string | ReactNode;
  titleComponent?: string | ReactNode;
  tabs: NavigationGroupTab[];
  tabsPosition?: 'right' | 'left';
  className?: string;
  contentWrapperClassName?: string;
};

const PageTitleWithTabs: FC<PageTitleWithTabsProps> = ({
  title,
  subTitle,
  titleComponent,
  tabs,
  tabsPosition,
  className = '',
  contentWrapperClassName = ''
}) => {
  return (
    <PageTitle
      className={`mb-0 d-flex ${className}`}
      title={title}
      render={() => (
        <div
          className={`d-flex align-items-center ${
            tabsPosition === 'right' ? 'flex-row-reverse' : ''
          } ${contentWrapperClassName}`}
        >
          {tabs.length ? <NavigationGroupTabs tabs={tabs} /> : null}
          {titleComponent ? (
            titleComponent
          ) : (
            <div
              className={
                tabs.length
                  ? `${
                      tabsPosition === 'right' ? 'me-3 pe-3 border-end' : 'ms-3 ps-3 border-start'
                    }`
                  : ''
              }
            >
              {title}{' '}
              {subTitle ? (
                <>
                  / <strong className="text-capitalize">{subTitle}</strong>
                </>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      )}
    />
  );
};

export default PageTitleWithTabs;
