import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import { selectActiveDashboardTitle } from 'store/dashboards/selectors';
import Dashboard from '@/views/dashboard';

const dashboard = [
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.dashboard}`,
    component: Dashboard,
    permissions: [permissions.office.dashboard.view],
    documentTitle: 'Dashboard'
  },
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.dashboard}/:id`,
    component: Dashboard,
    permissions: [permissions.office.dashboard.view],
    documentTitle: selectActiveDashboardTitle
  }
];

export default dashboard;
