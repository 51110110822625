import React from 'react';
import emptyStateIcon from 'assets/svg/contacts/birthdays-grey.svg';
import SvgRender from 'common/components/general/SvgRender';

const EmptyState = ({ label }) => {
  return (
    <div className="widget-birthday__empty-state">
      <SvgRender
        src={emptyStateIcon}
        className="widget-birthday__empty-state-icon"
        style={{ height: 224, width: 275 }}
      />
      <strong className="widget-birthday__empty-state-title pt-5">{label}</strong>
    </div>
  );
};

export default EmptyState;
