import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import PropTypes from 'prop-types';
import paths from 'routing/routes/_paths';
import { useSelector } from 'react-redux';
import { selectWidgetState } from 'store/dashboards/selectors';

const SparePartsInventoryDueDates = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));

  return (
    <div>
      <WidgetTitle id={id} linkTo={paths.pms_inventory} />
      <WidgetDefaultTable
        id={id}
        columns={[
          {
            header: 'VESSEL',
            type: 'string',
            key: 'vessel.name',
            truncate: true,
            headerClassName: 'text-violet',
            width: 7
          },
          {
            header: 'LAST SURVEY',
            key: 'last_survey_timestamp',
            type: 'date',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'SURVEY DUE DATE',
            key: 'due_date',
            type: 'date',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'DAYS LEFT',
            key: 'days_left',
            type: 'string',
            headerClassName: 'text-violet'
          }
        ]}
        rows={{
          days_left: data => (
            <span
              className={`${
                data.is_overdue ? 'text-danger' : data.becoming_overdue ? 'text-warning' : ''
              }`}
            >
              {data.days_left}
            </span>
          )
        }}
        label={label}
        state={state}
      />
    </div>
  );
};

SparePartsInventoryDueDates.propTypes = {
  id: PropTypes.number
};

export default SparePartsInventoryDueDates;
