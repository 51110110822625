import { ItineraryPort } from '@/common/types/itineraries';
import { get, post, put } from '..';
import { PortDaCaseAgent } from '@/common/types/port-da-cases';
import { PagingMeta } from '@/ts-common/types/table-query';
import { FirstArrivalReport } from '@/common/types/captain-reports';

type GetItineraryScheduledPortsType = {
  vessel_id: number;
  charter_party_id?: number;
};

export const getItineraryScheduledPorts = async (params: GetItineraryScheduledPortsType) => {
  const { vessel_id, ...rest } = params;

  return (await get<ItineraryPort[]>(`/vessels/${vessel_id}/itinerary-ports/scheduled`, rest)).data;
};

type GetItineraryRealPortsType = {
  vessel_id: number;
  charter_party_id?: number;
  paging?: { current_page: number; per_page: number };
};

export const getItineraryRealPorts = async (params: GetItineraryRealPortsType) => {
  const { vessel_id, ...rest } = params;

  return (
    await get<{ data: ItineraryPort[]; meta: PagingMeta }>(
      `/vessels/${vessel_id}/itinerary-ports/real`,
      rest
    )
  ).data;
};

type AppointPortDaCaseAgentParamsType = {
  id: number;
  agent: PortDaCaseAgent;
};

export const appointItineraryPortAgent = async (params: AppointPortDaCaseAgentParamsType) => {
  const { id, agent } = params;

  return (
    await post<null>(`/itinerary-ports/${id}/agents`, {
      party_id: agent.agent_id,
      type_id: agent.agent_type_id
    })
  ).data;
};

type ReorderItineraryScheduledPortsType = {
  vessel_id: number;
  itinerary_port_ids: string[];
};

export const reorderItineraryScheduledPorts = async (
  params: ReorderItineraryScheduledPortsType
) => {
  const { vessel_id, ...rest } = params;

  return (await put<ItineraryPort[]>(`/vessels/${vessel_id}/itinerary-ports`, rest)).data;
};

export const getItineraryFirstScheduledPort = async () => {
  return (await get<ItineraryPort>(`/itinerary-ports/first-scheduled`)).data;
};

export const getItineraryPortFirstArrivalReport = async () => {
  return (await get<FirstArrivalReport>(`/itinerary-ports/first-arrival-report`)).data;
};
