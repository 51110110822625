import { useCallback, useEffect } from 'react';
import useRouter from 'use-react-router';
import { Button } from 'reactstrap';

import Table, { useTable } from '@/common/components/table';
import TABLE_TYPES from 'common/components/table/store/types';
import { TableRequestParams } from '@/common/types/front-entities/table';
import PageTitle from 'common/components/general/PageTitle';
import PageActions from '@/components/layouts/page/table/PageActions';

import { getPeriodsTable } from '@/api/mga/api.ts';
import TextWithTooltip from '@/common/components/general/TextWithTooltip';
import { PeriodTableType } from '@/common/types/mga.ts';
import { useAppDispatch } from '@/store/hooks';
import paths from '@/routing/routes/_paths';
import EntityLabel from '@/common/components/labels/EntityLabel';
import SetupForexRatesForm from '@/common/components/mga/components/actions/setup-forex-rate-form/index.tsx';
import { useDrawer } from 'common/components/drawer';
import { getMgaCurrencies } from '@/common/components/mga/store/actions-ts';
import PeriodForm from '@/common/components/mga/components/period/form';
import VesselSelector from 'common/components/selectors/VesselSelector';
import MgaPeriodStatusesSelector from '@/common/components/selectors/MgaPeriodStatusesSelector';
import { TableRequestPaging } from '@/common/types/front-entities/table.ts';

const MgaList = () => {
  const dispatch = useAppDispatch();
  const { history } = useRouter();
  const setupForexRatesDrawer = useDrawer();

  const fetchMgaCurrencies = useCallback(async () => {
    try {
      await dispatch(getMgaCurrencies()).unwrap();
    } catch (err) {
      console.error(err);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchMgaCurrencies();
  }, [fetchMgaCurrencies]);

  const fetchTable = useCallback(
    async (params: TableRequestParams) => {
      const { table } = params;

      try {
        dispatch({
          type: TABLE_TYPES.GET_TABLE_LIST.START,
          payload: {
            params,
            table,
            data: []
          }
        });

        const parsedParams = {
          ...params,
          paging: { ...params.paging, per_page: 50 } as TableRequestPaging
        };

        const res = await getPeriodsTable(parsedParams);

        dispatch({
          type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
          payload: { data: res, table }
        });
      } catch (error) {
        dispatch({
          type: TABLE_TYPES.GET_TABLE_LIST.ERROR,
          payload: { error, table }
        });
      }
    },
    [dispatch]
  );

  const table = useTable({
    label: 'mga_periods_table',
    requestTableListFrom: fetchTable,
    columns: [
      {
        header: 'Vessel',
        key: 'vessel_id',
        sortingKey: 'vessel',
        type: 'collection',
        component: VesselSelector,
        sort: true,
        canFilter: true,
        componentRest: { isMulti: true },
        minWidth: 278,
        maxWidth: 278
      },
      {
        header: 'From',
        key: 'started_at',
        sort: true,
        className: 'text-primary',
        canFilter: true,
        type: 'date',
        minWidth: 241,
        maxWidth: 241
      },
      {
        header: 'To',
        key: 'ended_at',
        sort: true,
        className: 'text-primary',
        canFilter: true,
        type: 'date',
        minWidth: 286,
        maxWidth: 286
      },
      {
        header: 'Status',
        key: 'status_id',
        sortingKey: 'status',
        sort: true,
        canFilter: true,
        type: 'collection',
        component: MgaPeriodStatusesSelector,
        componentRest: { isMulti: true },
        minWidth: 241,
        maxWidth: 241
      }
    ]
  });

  return (
    <div>
      <PageTitle
        className="mb-7 cpb-4"
        title="MGA"
        render={() => (
          <div className="d-flex align-items-center">
            <div className="flex-1">MGA</div>

            <PageActions
              className="me-7 pe-4 border-end"
              actions={[
                {
                  customElement: () => (
                    <Button
                      onClick={() => history.push(`${paths.mga}/overview`)}
                      type="button"
                      className="fw-medium cme-12"
                      color="white"
                      size="md"
                    >
                      Create MGA Period
                    </Button>
                  )
                },
                {
                  customElement: () => (
                    <Button
                      onClick={setupForexRatesDrawer.open}
                      type="button"
                      className="fw-medium"
                      color="white"
                      size="md"
                    >
                      Setup Forex Rates
                    </Button>
                  )
                }
              ]}
            />
          </div>
        )}
      />

      <Table
        loader
        rows={{
          vessel_id: (data: PeriodTableType) => (
            <TextWithTooltip
              className="h-auto cursor-pointer"
              onClick={() => history.replace(`${paths.mga}/overview/${data.vessel.id}/${data.id}`)}
            >
              {data.vessel.name}
            </TextWithTooltip>
          ),

          status_id: (data: PeriodTableType) => (
            <div className="d-flex align-items-center">
              <EntityLabel type="mga" color={data.status.label} className="cmb-2 border-0">
                {data.status.name}
              </EntityLabel>
            </div>
          )
        }}
        {...table}
      />

      <SetupForexRatesForm drawer={setupForexRatesDrawer} />
      <PeriodForm />
    </div>
  );
};

export default MgaList;
