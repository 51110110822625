import React from 'react';

import { FormBody } from 'common/components/drawer';
import Input from 'common/components/form/inputs/Input';
import Textarea from 'common/components/form/inputs/Textarea';

import { getPartyDisplayName } from 'common/utils/labels';
import { useFormState } from 'utils/hooks';
import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';
import AsyncSelector from 'common/components/selectors/AsyncSelector';

const SearchFormBody = ({ isDirty, formState, isSetupOnboard }) => {
  const { fields, changeField, selectField } = useFormState(formState);

  return (
    <FormBody isDirty={isDirty}>
      <Input label="NAME" name="name" onChange={changeField('name')} {...fields.name} />
      <Textarea
        label="Description"
        placeholder=""
        name="description"
        onChange={changeField('description')}
        {...fields.description}
      />
      {isSetupOnboard ? null : (
        <AsyncSelector
          label="SHARED WITH"
          getOptionLabel={option =>
            option.type === 'company' ? option.company_name : getPartyDisplayName(option)
          }
          type="parties"
          listParams={{ exclude_current_user: true, can_login: true }}
          placeholder="Select contacts"
          value={fields.shared_parties.value}
          name="shared_parties"
          onChange={selected => selectField('shared_parties')(selected)}
          isMulti
          isAsync
        />
      )}

      {isSetupOnboard ? null : (
        <DepartmentSelector
          label="SHARE WITH DEPARTMENTS"
          placeholder={`Select departments`}
          value={fields.shared_departments.value || []}
          onChange={selected => selectField('shared_departments')(selected || [])}
          invisible={false}
          white={false}
          showMore={true}
          id={`main-department-select-dashboard`}
          isMulti={true}
        />
      )}
    </FormBody>
  );
};

export default SearchFormBody;
