import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'utils/api';

export const getDebriefingReport = createAsyncThunk(
  'GET_DEBRIEFING_REPORT',
  async (params, { rejectWithValue }) => {
    try {
      const res = await get('/reports/crew-debriefing', params);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getDebriefingMonthly = createAsyncThunk(
  'GET_DEBRIEFING_MONTHLY',
  async (params, { rejectWithValue }) => {
    try {
      const res = await get('/reports/crew-debriefing/graph/monthly-avg', params);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getDebriefingRejoin = createAsyncThunk(
  'GET_DEBRIEFING_REJOIN',
  async (params, { rejectWithValue }) => {
    try {
      const res = await get('/reports/crew-debriefing/graph/rejoin', params);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
