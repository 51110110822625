import { createSlice } from '@reduxjs/toolkit';
import { getUnderwaterServicesFleetReportData } from './actions';

export const INITIAL_STATE = {
  data: [],
  isFetching: false
};

const slice = createSlice({
  name: 'underwaterServicesFleet',
  initialState: INITIAL_STATE,

  extraReducers: builder => {
    builder
      .addCase(getUnderwaterServicesFleetReportData.pending, state => {
        state.isFetching = true;
        return state;
      })

      .addCase(getUnderwaterServicesFleetReportData.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.data = payload.data;
        return state;
      })

      .addCase(getUnderwaterServicesFleetReportData.rejected, state => {
        state.isFetching = false;
        return state;
      });
  }
});

export default slice.reducer;
