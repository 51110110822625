import TYPES from './types';

export const INITIAL_STATE = {
  fleet_events: { gantt: [], isFetching: false }
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_FLEET_EVENTS.START:
      return {
        ...state,
        fleet_events: {
          ...state.fleet_events,
          isFetching: true
        }
      };
    case TYPES.GET_FLEET_EVENTS.SUCCESS:
      const data = {
        graphType: 'subtask-gantt',
        data: (payload || []).map(outer => {
          return {
            ...outer,
            task: outer.name,
            type: outer.name,

            subtasks:
              outer?.events?.map(inner => {
                return {
                  departments: inner.departments,
                  id: inner.id,
                  startTime: inner.started_at_date,
                  endTime: inner.ended_at_date,
                  hasCssColor: true,
                  progress: inner.progress,
                  task: inner.name,
                  type: inner?.type?.name,
                  color: inner?.type?.color
                };
              }) ?? []
          };
        })
      };

      return {
        ...state,
        fleet_events: { gantt: data, isFetching: false }
      };

    case TYPES.GET_FLEET_EVENTS.ERROR:
      return {
        ...state,
        fleet_events: {
          ...state.fleet_events,
          isFetching: false
        }
      };

    default:
      return state;
  }
};

export default reducer;
