import TYPES from './types';
import lists from './lists';
import { makeEmptyList } from 'common/utils/lists';

const listsState = makeEmptyList ? makeEmptyList(lists) : {};

const INITIAL_STATE = {
  _defaults: { ...(makeEmptyList ? makeEmptyList(lists) : {}) },
  ...listsState
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.FETCH_OPTIONS.START:
      return setList(state, payload.list, { isFetching: true, requestParams: payload.params });
    case TYPES.FETCH_OPTIONS.SUCCESS:
      return setList(state, payload.list, { isFetching: false, options: payload.options });
    case TYPES.FETCH_OPTIONS.ERROR:
      return setList(state, payload.list, { isFetching: false });

    case TYPES.SET_DEFAULT_LIST:
      const { list, ...rest } = payload;

      return {
        ...state,
        _defaults: {
          ...state._defaults,
          [list]: {
            ...state._defaults[list],
            ...rest
          }
        }
      };

    default:
      return state;
  }
};
export default reducer;

const setList = (state, list, value) => ({
  ...state,
  [list]: {
    ...state[list],
    ...value
  }
});
