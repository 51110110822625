import TYPES from './types';
import { LOCATION_CHANGE } from 'connected-react-router';
import paths from 'routing/routes/_paths';

const INITIAL_STATE = {
  account: {
    balances: null
  },
  periods: [],
  account: null,
  currentPeriod: null,
  currentContract: null
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.GET_PAYROLL_ACCOUNT_BALANCES.SUCCESS:
      return {
        ...state,
        account: { ...state.account, balances: payload }
      };

    case TYPES.GET_PAYROLL_PERIODS.SUCCESS:
      return { ...state, periods: payload };

    case TYPES.SET_CURRENT_PERIOD:
      return { ...state, currentPeriod: payload };

    case TYPES.SET_CURRENT_CONTRACT:
      return { ...state, currentContract: payload };

    case TYPES.GET_CREW_MGA_ACCOUNT.SUCCESS:
      return { ...state, account: payload };

    case LOCATION_CHANGE:
      if (!payload.pathname?.includes('payroll')) {
        return {
          ...INITIAL_STATE
        };
      }

    default:
      return state;
  }
};
export default reducer;
