import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants';

const Da = React.lazy(() => retry(() => import('@/views/port-da-cases/index.tsx')));
const DaCase = React.lazy(() => retry(() => import('@/views/port-da-cases/case/index.tsx')));

const portDaCases = [
  {
    path: `${paths.port_da_cases}`,
    component: Da,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'DA',
    permissions: [permissions.office.voyages.da.view]
  },
  {
    path: `${paths.port_da_cases}/:id`,
    component: DaCase,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'DA',
    permissions: [permissions.office.voyages.da.view]
  }
];

export default portDaCases;
