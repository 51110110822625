import React, { useState, useCallback, useMemo, useEffect } from 'react';
import Drawer, { DrawerHeader } from 'common/components/drawer';
import LayoutBox from './LayoutBox';
import WidgetsSearch from './WidgetsSeach';
import WidgetsList from './WidgetsList';

import { FormDrawer, FormBody, FormFooter } from 'common/components/drawer';
import { Row, Col, Button } from 'reactstrap';
import {
  selectDashboardWidgetsForm,
  selectActiveDashboard,
  selectActiveDashboardHasWidgets,
  selectActiveDashboardFormWidgets
} from 'store/dashboards/selectors';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import { useForm, useFormState } from 'utils/hooks';

import config from './config';
import widgetsDetails from 'views/dashboard/widgets/widgets-details';

import * as dashboardsActions from 'store/dashboards/actions';

const Form = () => {
  const activeDashboard = useSelector(selectActiveDashboard);
  const hasWidgets = useSelector(selectActiveDashboardHasWidgets);
  const activeDashboardFormWidgets = useSelector(selectActiveDashboardFormWidgets);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [setDashboardWidgetsForm, updateDashboardWidgets] = useActions([
    dashboardsActions.setDashboardWidgetsForm,
    dashboardsActions.updateDashboardWidgets
  ]);
  const { isOpen } = useSelector(selectDashboardWidgetsForm);

  const { formState, collectValues, loadValues, resetForm } = useForm(config);
  const { fields, selectField } = useFormState(formState);

  const layouts = useMemo(
    () => [
      {
        layout: 'card',
        placement: 'top',
        header: 'TOP PART CARDS',
        fieldsKey: 'card'
      },
      {
        layout: 'table',
        placement: 'bottom',
        header: 'BOTTOM PART TABLES',
        fieldsKey: 'table'
      },
      {
        layout: 'sidebar',
        placement: 'right',
        header: 'RIGHT PART SIDEBAR',
        fieldsKey: 'sidebar'
      }
    ],
    []
  );

  useEffect(() => {
    if (isOpen) {
      if (hasWidgets) {
        loadValues(activeDashboardFormWidgets);
      } else {
        resetForm();
      }
    }
  }, [isOpen]);

  const onClose = useCallback(() => setDashboardWidgetsForm({ isOpen: false }), []);

  const getLayoutFields = layout => fields[layout].value;

  const addWidget = widgetType => {
    const layout = widgetType.layout;
    const layoutFields = getLayoutFields(layout);
    const { id, label, ...rest } = widgetType;
    const preferences = widgetsDetails[label]?.defaults?.preferences;

    selectField(layout)([
      ...layoutFields,
      { type_id: id, sort_index: layoutFields.length, label, preferences, ...rest }
    ]);
  };

  const removeWidget = (widgetIndex, layout) =>
    selectField(layout)(getLayoutFields(layout).filter((_, i) => i !== widgetIndex));

  const reorderWidgets = (widgets, layout) => selectField(layout)(widgets);

  const getWidgetParams = (widget, index) => {
    const params = {
      id: widget.id,
      title: widget.name,
      type_id: widget.type_id,
      sort_index: index,
      layout: widget.layout,
      preferences: widget.preferences
    };

    return params;
  };

  const onSubmit = async () => {
    const values = collectValues();

    if (!values) return;

    try {
      setIsSubmitting(true);

      const { card, table, sidebar } = values;

      const params = { id: activeDashboard.id, widgets: [] };

      params.widgets = [...card, ...table, ...sidebar].map((widget, index) => {
        return getWidgetParams(widget, index);
      });

      await updateDashboardWidgets(params);

      onClose();

      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  return (
    <Drawer isOpen={isOpen} close={onClose} size="md">
      <DrawerHeader>Widgets</DrawerHeader>

      <FormDrawer>
        <FormBody className="pt-0 px-4">
          <Row className="h-100p">
            <Col xs={7} className="pe-3 border-end d-flex flex-column h-100p overflow-hidden pt-2">
              {layouts.map(({ fieldsKey, ...layout }) => (
                <LayoutBox
                  key={fieldsKey}
                  widgets={fields[fieldsKey].value}
                  onRemove={removeWidget}
                  onReorder={reorderWidgets}
                  {...layout}
                />
              ))}
            </Col>
            <Col xs={5} className="ps-3 d-flex flex-column h-100p overflow-hidden pt-2">
              <WidgetsSearch isOpen={isOpen} />
              <WidgetsList onAdd={addWidget} />
            </Col>
          </Row>
        </FormBody>
        <FormFooter>
          <Button
            color="cancel"
            className="px-0 py-1 me-4"
            onClick={onClose}
            disabled={isSubmitting}
          >
            CANCEL
          </Button>
          <Button onClick={onSubmit} disabled={isSubmitting} color="primary" className="px-4">
            SAVE
          </Button>
        </FormFooter>
      </FormDrawer>
    </Drawer>
  );
};

export default Form;
