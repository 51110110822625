import { hiddenField, optionField, stringField } from 'common/utils/form/fieldTypes';

const config = {
  id: hiddenField(),
  name: stringField({ required: true }),
  description: stringField(),
  shared_parties: optionField(),
  shared_departments: optionField()
  // favorite: booleanField()
};

export default config;
