import TYPES from './types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { post } from 'utils/api';
import { REFRESH_TOKEN_REQUEST_URL, defaultHeaders } from '@/api';
import _get from 'lodash/get';
import { errorHandler } from 'common/utils/notifications';
import { logoutCleanup, authorizeLogin } from 'store/auth/slice';
import { selectAuthenticationToken, selectIsAuthenticated } from 'store/auth/selectors';

const unauthorizedErrorHandler = (error, message) => dispatch => {
  if (error && error.status === 401 && _get(error, 'data.code', '') === 'unauthenticated') {
    dispatch(
      errorHandler({
        title: 'Uh oh!',
        message: message || 'You are not authorized to perform the requested action.'
      })
    );
  }
};

const getTwoFactorAuthenticationHeaders = state => {
  const isAuthenticated = selectIsAuthenticated(state);
  const token = selectAuthenticationToken(state);

  if (isAuthenticated) return null;

  const headers = { ...defaultHeaders, Authorization: `Bearer ${token}` };

  return headers;
};

export const login = createAsyncThunk(
  TYPES.LOGIN,
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const res = await post('/verification/login', params, false);

      // when 2fa is not required, authorize login
      if (res?.data?.access_token) {
        dispatch(authorizeLogin(res?.data));
      }

      return res.data;
    } catch (error) {
      dispatch(unauthorizedErrorHandler(error, 'Invalid credentials'));
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(TYPES.LOGOUT, async (_, { rejectWithValue, dispatch }) => {
  try {
    const res = await post('/verification/logout');

    dispatch(logoutCleanup());
    return res.data;
  } catch (error) {
    dispatch(logoutCleanup());
    return rejectWithValue(error);
  }
});

export const refreshTokens = () => {
  return dispatch => {
    dispatch({ type: TYPES.REFRESH_TOKEN.START });

    return post(`${REFRESH_TOKEN_REQUEST_URL}`, {
      refresh_token: window.localStorage.getItem('refreshToken')
    })
      .then(res => {
        dispatch({ type: TYPES.REFRESH_TOKEN.SUCCESS, payload: res.data });

        return res.data;
      })
      .catch(err => {
        dispatch({ type: TYPES.REFRESH_TOKEN.ERROR });

        return err;
      });
  };
};

/* Two-factor Authentication */
export const sendTwoFactorOtp = createAsyncThunk(
  TYPES.SEND_TWO_FACTOR_OTP,
  async (_, { rejectWithValue, getState }) => {
    try {
      const res = await post(
        `/verification/two-factor/otp`,
        null,
        true,
        getTwoFactorAuthenticationHeaders(getState())
      );

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setupTwoFactorAuthentication = createAsyncThunk(
  TYPES.SETUP_TWO_FACTOR_AUTHENTICATION,
  async (params, { rejectWithValue, getState }) => {
    try {
      const { id, ...rest } = params;

      const res = await post(
        `/verification/two-factor/setup`,
        rest,
        true,
        getTwoFactorAuthenticationHeaders(getState())
      );

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loginWithTwoFactor = createAsyncThunk(
  TYPES.LOGIN_WITH_TWO_FACTOR,
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const res = await post(
        '/verification/two-factor',
        params,
        true,
        getTwoFactorAuthenticationHeaders(getState())
      );

      dispatch(authorizeLogin(res?.data));

      return res.data;
    } catch (error) {
      dispatch(unauthorizedErrorHandler(error, 'Invalid code'));
      return rejectWithValue(error);
    }
  }
);
/* -- */
