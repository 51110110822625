import Preferences from './Preferences';
import WidgetDefaultListSidebar from 'views/dashboard/components/WidgetDefaultListSidebar';
import WidgetDefaultListItem from 'views/dashboard/components/WidgetDefaultListItem';
import ReceivedDate from './ReceivedDate';
import { getProperties } from './_helpers';
import avatar from 'common/assets/svg/avatars/crew.svg';
import ProfileImagesPreview from 'common/components/general/ProfileImagesPreview';
import ColoredLabel from 'common/components/labels/ColoredLabel';

const UnReadMessagesWidget = ({ id }) => {
  return (
    <WidgetDefaultListSidebar
      id={id}
      renderListItem={(item, index) => {
        const properties = getProperties(item);

        return (
          <WidgetDefaultListItem
            key={index}
            icon={properties.icon}
            color="purple"
            item={{
              ...item,
              nameComponent: (
                <div className="ps-1 d-flex align-items-center">
                  <strong className="text-primary fs-12">{item.count} new</strong>
                  {item.mention_count > 0 && (
                    <ColoredLabel
                      color="boysenberry-shadow"
                      className="text-moody-blue fw-500 border-radius-3 cms-6 cpx-4"
                      text={`${item.mention_count}@`}
                    />
                  )}
                  {item?.parties?.length > 0 && (
                    <ProfileImagesPreview
                      wrapperClassName="cms-12"
                      parties={item.parties}
                      fallback={avatar}
                      slots={4}
                    />
                  )}
                </div>
              ),
              description: properties.description
            }}
            navigateToPath={() => properties.path}
            RightComponent={ReceivedDate}
          />
        );
      }}
      withColoredIcon
    />
  );
};
export const UnreadMessagesPreferences = Preferences;

export default UnReadMessagesWidget;
