import { selectIsCreate, selectIsTemplate } from '@/events/store/events/selectors';

const selectParticipants = state => state.eventModules.participants;

const selectIsOnboard = state => state.isOnBoard;

export const selectParticipantsData = state => selectParticipants(state).data;
export const selectParticipantsDataLength = state => selectParticipantsData(state).length;
export const selectParticipantsLoading = state => selectParticipants(state).loading;
export const selectParticipantsAddActionIsLocked = state => {
  const isOnBoard = selectIsOnboard(state);
  const isCreate = selectIsCreate(state);
  const isTemplate = selectIsTemplate(state);

  if (isTemplate || isCreate) return false;
  if (isOnBoard) return true;

  return false;
};
