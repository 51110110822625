import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import { selectComparyProfileName } from '@/store/companies/selectors.js';

const Companies = React.lazy(() => retry(() => import('@/views/companies/index.jsx')));
const CompanyProfile = React.lazy(() => retry(() => import('@/views/companies/profile/index.jsx')));

const companies = [
  {
    path: `${paths.companies}`,
    component: Companies,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.contacts.companies.view],
    documentTitle: 'Companies'
  },
  {
    path: `${paths.companies}/create`,
    component: CompanyProfile,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.contacts.companies.create],
    documentTitle: 'Create Compary'
  },
  {
    path: `${paths.companies}/:id`,
    component: CompanyProfile,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.contacts.companies.profile.view],
    documentTitle: selectComparyProfileName
  }
];

export default companies;
