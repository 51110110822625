import {
  booleanField,
  dateField,
  hiddenField,
  numberField,
  optionField,
  stringField
} from 'common/utils/form/fieldTypes';

export const BUDGET_TYPE = {
  FOR_ALL_VESSELS: 'is_for_all_vessels',
  FOR_ALL_VESSELS_AND_COMPANIES: 'is_for_all_vessels_and_companies',
  FOR_SPECIFIC_VESSEL: 'is_for_specific_vessel',
  FOR_SPECIFIC_COMPANY: 'is_for_specific_company'
};

export const defaultConfig = {
  budget_scenario: optionField(),
  period_type: stringField({ required: true }),
  period_year: stringField(),
  period_start: dateField(),
  period_end: dateField(),
  amounts: {
    budgetable: stringField({ initialValue: null, required: true }),
    is_for_all_companies: booleanField({ initialValue: false }),
    is_for_all_vessels: booleanField({ initialValue: true }),
    vessel: optionField(),
    company: optionField(),
    radio_type: hiddenField({ initialValue: BUDGET_TYPE.FOR_ALL_VESSELS }),
    amount: numberField({ required: true })
  },
  remarks: stringField()
};
