import React, { Component } from 'react';

class CalculatorDisplay extends Component {
  state = {
    scale: 1
  };

  render() {
    const { value } = this.props;

    const language = navigator.language || 'en-US';
    let formattedValue = parseFloat(value).toLocaleString(language, {
      useGrouping: true,
      maximumFractionDigits: 6
    });

    // Add back missing .0 in e.g. 12.0
    const match = value.match(/\.\d*?(0*)$/);

    if (match) formattedValue += /[1-9]/.test(match[0]) ? match[1] : match[0];

    return (
      <div className="calculator-display">
        <input
          id="calculator_input"
          type="text"
          name="tool_display"
          value={formattedValue}
          className="form-control"
          onFocus={() => this.props.setFocused(true)}
          onBlur={() => this.props.setFocused(false)}
          onChange={e => {
            const val = e.target.value.charAt(e.target.value.length - 1);

            if (!isNaN(val)) {
              this.props.handleChange(val);
            }
          }}
        />
      </div>
    );
  }
}

export default CalculatorDisplay;
