import React from 'react';

import { Row, Col } from 'reactstrap';
import { formatNumber } from 'common/utils/numbers';
import _get from 'lodash/get';
import CircledButton from 'common/components/buttons/CircledButton';

const Difference = ({ data, setActiveResult, view, showEdit }) => {
  return (
    <Row
      className={`g-0 h-100p w-100p d-flex align-items-center me-0 justify-content-end p-0 position-relative`}
    >
      <Col xs={7} className="pe-1 text-nowrap d-flex justify-content-end">
        {data.difference ? (
          <div
            className={+_get(data, 'difference_percent', 0) < 0 ? 'text-coral' : 'text-turquoise'}
          >
            {' '}
            {formatNumber(data.difference)}
          </div>
        ) : null}
      </Col>
      <Col xs={5} className="text-nowrap">
        <div
          className={`d-flex justify-content-end ${
            +_get(data, 'difference_percent', 0) < 0 ? 'text-coral' : 'text-turquoise'
          }`}
        >
          {Math.abs(+_get(data, 'difference_percent', 0)) < 100 ? +data.difference_percent : 100}%
        </div>
      </Col>
      {view === 'voyage' && showEdit && (
        <CircledButton
          onClick={() => setActiveResult(data)}
          svgStyle={{ width: 11, height: 11 }}
          className="tce-result-edit text-white"
          type="edit"
        />
      )}
    </Row>
  );
};

export default Difference;
