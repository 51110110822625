import forex_rates from 'tools/assets/svg/tools/forex_rates.svg';
import calculator from 'tools/assets/svg/tools/calculator.svg';
import ballast from 'tools/assets/svg/tools/ballast.svg';
import consumption from 'tools/assets/svg/tools/consumption.svg';
import Calculator from './calculator';
import ForexRates from './forex-rates';
import Consumptions from './consumptions';
import DraftDwt from './draft-dwt';

const config = {
  calculator: {
    icon: calculator,
    component: Calculator
  },
  forex_rates: {
    icon: forex_rates,
    settings: true,
    component: ForexRates
  },
  vessel_consumptions: {
    icon: consumption,
    settings: true,
    component: Consumptions
  },
  dwt_draft_calculator: {
    icon: ballast,
    component: DraftDwt
  }
};

export default config;
