import { useAppSelector } from '@/store/hooks';
import {
  selectCategoryItems,
  selectIsPMSCategory
} from '@/common/components/purchasing/requisition/store/selectors-ts.ts';
import Item from './Item';
import Header from './Header';
import PropTypes from 'prop-types';

const Items = ({ categoryID }) => {
  const allCategoryItems = useAppSelector(state => selectCategoryItems(state, categoryID));
  const isPmsCategory = useAppSelector(state => selectIsPMSCategory(state, categoryID));

  return (
    <div className="mt-2 mb-3">
      <Header isPms={isPmsCategory} />
      {allCategoryItems?.map((id, index) => (
        <Item key={id} itemID={id} index={index} categoryID={categoryID} />
      ))}
    </div>
  );
};

Items.propTypes = {
  categoryID: PropTypes.number,
  isPms: PropTypes.bool
};

export default Items;
